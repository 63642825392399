const Plane = {
    "ShipmentId":null,
    "BookingNumber": "",
    "ShipmentNumber": "",
    "ATD": "",
    "ATA": "",
    "HBL_HAWB": "",
    "MBL_AWB": "",
    "Voyage_FlightNumber": "",
    "StatusId": "",
    "Status": "",
    "ShimentDate": "",
    "BookingDate": "",
    "CargoReadyDate": "",
    "Origin": "",
    "OriginName": "",
    "OriginLongitude": "",
    "OriginLatitude": "",
    "Destination": "",
    "DestinationName": "",
    "DestinationLongitude": "",
    "DestinationLatitude": "",
    "FinalDeliveryDesc": "",
    "ETD": "",
    "ETA": "",
    "TransportModeId": null,
    "TransportMode": "",
    "FreightTermId": null,
    "FreightTerm": "",
    "IncotermCode": "",
    "Incoterm": "",
    "ThreePLId": null,
    "ThreePL": "",
    "ThreePLBookingRef": null,
    "CarrierCode": "",
    "CarrierName": "",
    "VesselName": "",
    "SupplierCode": "",
    "Supplier": "",
    "SupplierAddress": "",
    "SupplierAddressDetails": "",
    "SupplierContactId": null,
    "SupplierContact": "",
    "SupplierEmail": "",
    "ConsigneeCode": "",
    "Consignee": "",
    "ConsigneeAddress": "",
    "ConsigneeAddressDetails": null,
    "ConsigneeContactId": null,
    "ConsigneeContact": "",
    "ConsigneeEmail": "",
    "DeliveryCode": "",
    "Delivery": "",
    "DeliveryAddress": " ",
    "DeliveryAddressDetails": "",
    "DeliveryContactId": null,
    "DeliveryContact": "",
    "DeliveryEmail": "",
    "POs": ""
}

export default Plane;