import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Input, Row, Col, Card, CardBody } from 'reactstrap';


// DateTimePicker
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';


import { PurchaseForm, PurchaseModel, Data } from './models/PurchaseModel';

import { FilterHelper } from './helpers/HelperFilters';

import DataTable from './DataTable';
import Graphics from './Graphics';
import IMAGENPURCH from '../cm-purchase/imagen64/file';

// import Data from './json'
class Purchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModelData: PurchaseModel,
      form: '',
      dataCost: Data.data,
      data: Data.data,
      columns: [
        {
          label: "PO Number", key: "poNumber"
        }, {
          label: "PO Date", key: "poDate"
        }, {
          label: "Suppplier", key: "supplier"
        }, {
          label: "Company", key: "company"
        }, {
          label: "Buyer", key: "buyer"
        }, {
          label: "Division", key: "division"
        }, {
          label: "Cruise", key: "cruise"
        }, {
          label: "Project Code", key: "projectCode"
        }, {
          label: "Account Number", key: "accountNumber"
        }, {
          label: "Amount", key: "amount"
        }, {
          label: "Currency", key: "currency"
        }, {
          label: "Invoice Number", key: "invoiceNumber"
        }, {
          label: "Invoice Amount", key: "invoiceAmount"
        }
      ]
    };
  }



  /*=============================================
  =            Handle Change Input Data         =
  =============================================*/

  OnChangeInputData = id => evt => {
    let { ModelData, dataCost } = this.state;

    if (id === 'dateStart' || id === 'dateEnd') {
      try {
        ModelData['date'][id] = evt.format('DD-MM-YYYY');
      } catch (ex) {
        ModelData['date'][id] = '';
      }
    } else {
      let id = evt.target.id;
      let value = evt.target.value;
      ModelData[id] = value;
    }

    this.setState({ ModelData }, () => {

      let { poNumber, supplier, cruise, buyer, division, company, date } = this.state.ModelData;
      if (supplier || cruise || buyer || division || company || poNumber || date.dateStart || date.dateEnd) {
        this.setState({ data: FilterHelper(ModelData, dataCost) }, () => {
          this.formCreate();
        });
      } else {
        this.setState({ data: Data.data }, () => {
          this.formCreate();
        });
      }
    });
  };


  /*=============================================
  =            Handle change date            =
  =============================================*/

  handleChangeDate = id => event => {
    let { ModelData } = this.state;
    try {
      ModelData['date'][id] = event.format('DD-MM-YYYY');
      this.setState({ ModelData }, () => {
        this.formCreate();
      });
    } catch (ex) {
    }
  }

  /*=============================================
  =            Function for formCreate          =
  =============================================*/
  formCreate = () => {
    let { ModelData } = this.state;
    let form = PurchaseForm.form.map((e, key) => {
      if (e.type === 'Input')
        return (
          <Col md={6} xl={6} key={key}>
            <Input
              type={e.type}
              name={e.name}
              id={e.id}
              placeholder={e.placeholder}
              onChange={this.OnChangeInputData(e.id)}
              value={ModelData[e.id]}
              className={e.class}
              maxLength={e.maxlenght}
            />
          </Col>
        );
      else if (e.type === 'Date')
        return (
          <Col md={6} xl={6} key={key}>
            <center><label className="color" style={{ fontWeight: 'bold' }}>{e.label}</label></center>

            <Datetime type={e.type} name={e.name} id={e.id} onChange={this.OnChangeInputData(e.id)} value={ModelData['date'][e.id]} className={e.class}></Datetime>
          </Col>
        );
      else return '';

    });

    this.setState({ form });
  };


  /*=============================================
  =            Life cycle components            =
  =============================================*/

  componentDidMount = () => {
    this.formCreate();
  };

  /*=====  End of Life cycle components  ======*/


  render() {
    let { form } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas icon-chart fa-1x mr-2"></em>
            Cost Management
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            <Col md={12} xl={12}>
              <Row>
                <Col md={12} xl={12}>
                  <div className="row">
                    <img className="imggggg" alt="img-purchase" src={IMAGENPURCH} />
                    <span className="Invoicing" >Purchasing</span>
                    <br></br><br></br><br></br>
                  </div>
                  <Row>

                    <Col md={8} xl={8}>
                      <Graphics data={this.state.data}></Graphics>
                    </Col>

                    <Col md={4} xl={4}>
                      <center><h4 className="mb-3 py-2">Filters</h4></center>
                      <Row>
                        {form}
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col md={12} xl={12}>
                  <br></br><br></br><br></br>
                  <DataTable columns={this.state.columns} data={this.state.data}></DataTable>

                </Col>
              </Row>
            </Col>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default Purchase;