import React, { Component } from 'react';
import restService from '../../../../../../services/restService';
import Select from 'react-select';
import {Services} from './../../../Services';
import Axios from 'axios';
// const rest = new restService();

class Deliveries extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deliveries: [],
            deliveriesArray: [],
            selectedOption: '',
            deliverieSelected: {},
            contactSelected: {},
            contacts: [],
            found: null,
            foundContact: null,
            isLoaded: false,
            errors : {},
            styleDelivery : {},
        }
    }


    componentDidMount = () => {
        let { isLoaded,styleDelivery } = this.state
        if (!isLoaded) {
            this.setState({
                selected: this.props.selected,
                errors : this.props.errors
            }, () => {
                if(this.state.errors){
                    if(Object.keys(this.state.errors).length > 0){
                        styleDelivery = { color : this.state.errors['DeliveryCode'] || this.state.errors['DeliveryContactId']? '#e57373' : '#616161'}
                    }
                    this.setState({
                        styleDelivery
                    },()=>{
                        this.getAllDeliveries()
                    })
                }
                else{
                    this.getAllDeliveries()
                } 
            })
        }
        else this.getAllDeliveries()
    }

    componentWillReceiveProps = (nextProps) => {
        let { isLoaded ,styleDelivery} = this.state
        if (!isLoaded) {
            this.setState({
                selected: nextProps.selected,
                errors : nextProps.errors
            }, () => {
                if(this.state.errors){
                    if(Object.keys(this.state.errors).length > 0){
                        styleDelivery = { color : this.state.errors['DeliveryCode'] || this.state.errors['DeliveryContactId']? '#e57373' : '#616161'}
                    }
                    this.setState({
                        styleDelivery
                    },()=>{
                        this.getAllDeliveries()
                    })
                }
                else{
                    this.getAllDeliveries()
                }     
            })
        }
    }

    getAllDeliveries = () => {
      Axios.get(`${Services.DIRECTORY.path}delivery/all`).then( data => {
        this.setState({ deliveries: data.data.data }, () => this.buildArraydeliveries());
      }).catch( error => {
      });
    }

    buildArraydeliveries = () => {
        const { deliveries } = this.state
        let array = []
        deliveries.forEach((e) => array.push({ value: e.DeliveryCode, label: e.Name }))
        this.setState({ deliveriesArray: array }, () => {
            this.setFirstOrderValues()
        })
    }

    setFirstOrderValues = () => {
        let { selected, deliveriesArray, found } = this.state
        if (selected && selected.length > 0 && !found) {
            let Code = selected[0].DeliveryCode
            let found = deliveriesArray.find((element) => element.value === Code)
            if (found) this.handleChangeSelect(found)
            this.setState({
                found
            })
        }
    }



    handleChangeSelect = (selectedOption) => this.setState({ selectedOption }, () => {
        if (selectedOption) this.buildConsigneeDetail(selectedOption.value)
        else this.setState({ deliverieSelected: {} }, this.props.sendProps({
            stateField: 'deliveries',
            id: ''
        }))
    });
    buildConsigneeDetail = (id) => {
        const { deliveries } = this.state
        let index = deliveries.findIndex(x => x.DeliveryCode === id)
        this.setState({ deliverieSelected: deliveries[index] }, () => this.props.sendProps({ stateField: 'deliveries', id: this.state.deliverieSelected.DeliveryCode }))
        this.getContacts(deliveries[index].DeliveryCode)
    }

    getContacts = (id) => {
      Axios.get(`${Services.DIRECTORY.path}/delivery/search/${id}`).then( data => {
        this.setState({
            contacts: data.data.data.contacts
        }, () => {
            this.setFirstOrderValuesContacts()
        })        
      }).catch( error => {
      });
    }



    setFirstOrderValuesContacts = () => {
        let { selected, contacts, foundContact } = this.state
        if (selected && selected.length > 0 && !foundContact) {
            let Code = selected[0].DeliveryContactId
            let foundContact = contacts.find((element) => element.value === Code)
            if (foundContact) this.handleChangeSelectContact(foundContact)
            this.setState({
                foundContact
            })
        }
    }

    handleChangeSelectContact = contactSelected => {
        if (contactSelected)
            this.setState({ contactSelected }, () => {
                this.props.sendProps({ stateField: 'deliveryContact', id: contactSelected.value })
            })
        else this.setState({ contactSelected: {} }, () => {
            this.props.sendProps({ stateField: 'deliveryContact', id: '' })
        })
    }

    render() {
        const { deliveriesArray, selectedOption, deliverieSelected, contacts, contactSelected } = this.state
        const value = selectedOption && selectedOption.value;
        const value2 = contactSelected && contactSelected.value

        return (
            <div className="row" >
                <div className="col-md-12">
                    <legend id="Delivery" style={this.state.styleDelivery}><em className="fas fa-truck fa-1x mr-2"></em>Ship to *</legend>
                    <Select
                        id="DeliveryCode"
                        name="select-name"
                        value={value}
                        onChange={this.handleChangeSelect}
                        placeholder='Select a Delivery'
                        options={deliveriesArray}
                    />
                    <hr />
                    <Select
                        id="DeliveryContactId"
                        required
                        name="select-contact"
                        value={value2}
                        onChange={this.handleChangeSelectContact}
                        placeholder={'Select a contact'}
                        options={contacts}
                    />
                    <hr />
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-user mr-2"></em>
                                <span>{deliverieSelected.Name} </span>
                            </div>

                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-map-marker-alt mr-2"></em>
                                <span>{deliverieSelected.Address} </span>
                            </div>

                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-info-circle mr-2"></em>
                                <span>{deliverieSelected.AddressDetails} </span>
                            </div>

                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-address-book mr-2"></em>
                                <span>{contactSelected.details} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Deliveries