import React from 'react';
import propTypes from 'prop-types';

// L Imports
import ContentWrapper from '../../../template/Layout/ContentWrapper';

/**
 * Capacity Set Up
 */

function CapacitySetUp(){

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div className="row">
          <em className="fas fa-broadcast-tower fa-1x mr-2"></em>
            Control Tower
        </div>
      </div>      
    </ContentWrapper>
  );
}

CapacitySetUp.propTypes = {

};

CapacitySetUp.defaultProps = {

};

export default CapacitySetUp;