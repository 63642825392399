import React, { Component, Suspense } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { Menu, Segment, Sidebar, Dropdown } from "semantic-ui-react";
import { ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import {
  message,
  Skeleton,
  DatePicker,
  Button as ButtonAnt,
  Switch,
} from "antd";
import Fade from "react-reveal/Fade";
import { connect } from "react-redux";
import { Services } from "./Services";
import axios from "axios";
import _ from "lodash";
import { IconChat, IconNewComment } from "./components/img";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import Loader from "./../../Global/Loader";
import FormFiltros from "./model/form";
import "./styles.scss";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";

import "./styles.scss";
import CountCard from "./components/CountCard";
import {FullLoader} from '../../Components/Loader';
import Chart from "react-google-charts";
import DataTable from "./components/DataTable";
import { ThemeProvider, createTheme } from "@mui/material";
import { getCommentsResponse, getMentionsResponse } from "./mocks/comments";
import { ChartsResponse, catalogsResponse, poListResponse } from "./mocks/po";
const IMGOrdenes =
  "https://magiclog.blob.core.windows.net/europartnerspic/Icono_Ordenes-de-compra.svg";
const priorityShipmentURL =
  "https://magiclog.blob.core.windows.net/europartnerspic/important_shipment.png";
const Comments = React.lazy(() => import("../../Components/Comments"));

const colors = [
  "#0045F3",
  "#0C55F6",
  "#175FF9",
  "#2369FC",
  "#2D73FF",
  "#3A7DFF",
  "#4697FF",
  "#52A1FF",
  "#5DABFF",
  "#69B5FF",
  "#75BFFF",
  "#80C9FF"
];


const dateNow = new Date();

const { RangePicker } = DatePicker;

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          backgroundColor: "#fff",
        },
        track: {
          opacity: 0.2,
          backgroundColor: "#9d9d9c",
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.7,
            backgroundColor: "#5d9cec",
          },
        },
      },
    },
  },
});

class OrderListCommponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameXlsx: `Purchase order list - ${
        dateNow.getFullYear() +
        "" +
        (dateNow.getMonth() + 1).toString().padStart(2, "0") +
        "" +
        dateNow.getDate().toString().padStart(2, "0")
      }`,
      isMovil: false,
      loadercomments: false,
      viewModalComments: false,
      commentsData: [],
      users: [],
      rolId: this.props._rolId,
      userId: this.props._user,
      scrollLine: false,
      orders: [],
      data: [],
      advancedObj: [],
      catalogCombo: [],
      advancedSearch: {},
      clientesSelect: this.props.clients
        ? JSON.stringify(this.props.clients)
        : "[0]",
      activePage: 1,
      limit: 10,
      numberPage: 1,
      filtros: "",
      filterOpen: false,
      url: "",
      loading: true,
      columns: [
        {
          label: "",
          id: "commentView",
          key: "commentViewAction",
          minWidth: 43,
          maxWidth: 43,
          noclick: true,
          custom: (value) => (
            <Col md={12} xl={12}>
              <Row>
                <Col md={8}>
                  <React.Fragment>
                    <div
                      title="Comments"
                      className="container_icon_comment"
                      onClick={() => {
                        this.setState(
                          {
                            loadercomments: true,
                            viewModalComments: true,
                          },
                          () => this.getCommnets(value)
                        );
                      }}
                    >
                      {value.NewComments ? (
                        <IconNewComment></IconNewComment>
                      ) : (
                        <IconChat></IconChat>
                      )}
                    </div>
                  </React.Fragment>
                </Col>
              </Row>
            </Col>
          ),
        },
        {
          label: "",
          key: "",
          CollapseTable: true,
          flatColor: true,
          keydatavalidate: "Status",
          minWidth: 50,
          maxWidth: 50,
        },
        {
          label: "",
          key: "TransportMode",
          minWidth: 30,
          maxWidth: 30,
          custom: (value) => {
            return value.PriorityPO === '1' && (
              <div>
                <img
                  alt="priority_po"
                  src={priorityShipmentURL}
                  width="15px"
                  height="15px"
                  title="Priority purchase order"
                />
              </div>
            );
          },
        },
        {
          label: "PO No.",
          key: "PONumber",
          minWidth: 150,
          maxWidth: 200,
          custom: (value) => (
            <Col md={12} xl={12}>
              <Row>
                <Col md={12}>
                  <Link
                    className="link-euro"
                    to={"/management/" + value.POId}
                  >
                    {value.PONumber}
                  </Link>
                </Col>
              </Row>
            </Col>
          ),
        },
        {
          label: "Status",
          key: "StatusPo",
          minWidth: 150,
          maxWidth: 200,
          custom: (value) => (
            <span class="badge badge-euro">{value.StatusPo}</span>
          ),
        },
        { label: "Company", key: "CustomerName", minWidth: 200 },
        { label: "Shipper", key: "SupplierName", minWidth: 200 },
        {
          label: "Consignee",
          key: "ConsigneeName",
          minWidth: 200,
        },
        {
          label: "Transport mode",
          key: "TransportMode",
          minWidth: 180,
        },
        {
          label: "Required at destination",
          key: "requireddestinationdate",
          minWidth: 180,
        },
        { label: "Creation date", key: "createdate", minWidth: 150 },
      ],

      subTable: [
        { label: "Line No.", key: "LineID", minWidth: 120 },
        { label: "Item No.", key: "ItemNo", minWidth: 120 },
        {
          label: "Description",
          key: "Description",
          minWidth: 250,
          limitString: 50,
        },
        {
          label: "Status",
          key: "Status",
          minWidth: 150,
          maxWidth: 200,
          custom: (value) => (
            <span class="badge badge-euro">{value.Status}</span>
          ),
        },

        {
          label: "QTY ordered",
          key: "QTYOrdered",
          minWidth: 120,
        },
        {
          label: "QTY confirmed",
          key: "QTYConfirmed",
          minWidth: 120,
        },
        {
          label: "Requested cargo ready",
          key: "RequiredCagoReady",
          minWidth: 150,
        },
        {
          label: "Promise cargo ready",
          key: "PromiseCargoReady",
          minWidth: 200,
        },
        {
          label: "Actual cargo ready",
          key: "ActualCargoReady",
          minWidth: 150,
        },
      ],
      searchOnInit: false,
      searchText: "",
      POId: 0,
      openComments: false,
      handleResCom: "unset",
      isSearchComments: true,
      tableLoader: false,

      totalCardsInfo: [],
      totalCardsConf: [
        {
          id: 1,
          key: "Tipo",
          keyTotal: "Total",
          color: "#2B3B57",
          iconBackOpacity: 0,
          classCard: "col-3",
          icon: <em className="icon--menu_porderon"></em>,
          title: "Total",
          titleClass: "title-count-card",
          totalClass: "total-count-card",
          propertiesIcon:
            "icon-count-card col-3 d-flex align-items-center bg-fourth rounded-left2",
          propertiesTitle: "col-9 bg-fourth-end rounded-right2 endPosition",
        },
        {
          id: 2,
          key: "Tipo",
          keyTotal: "Total",
          color: "#2B3B57",
          iconBackOpacity: 0,
          classCard: "col-3",
          titleClass: "title-count-card",
          totalClass: "total-count-card",
          propertiesIcon:
            "icon-count-card col-3 d-flex align-items-center bg-fourth rounded-left2",
          icon: <em className="icon--ok-circled"></em>,
          title: "At time to be confirmed",
          propertiesTitle: "col-9 bg-fourth-end rounded-right2 endPosition",
        },
        {
          id: 3,
          key: "Tipo",
          keyTotal: "Total",
          color: "#2B3B57",
          iconBackOpacity: 0,
          titleClass: "title-count-card",
          totalClass: "total-count-card",
          classCard: "col-3",
          propertiesIcon:
            "icon-count-card col-3 d-flex align-items-center bg-fourth rounded-left2",
          icon: <em className="icon-clock "></em>,
          title: "With delay to be confirmed",
          propertiesTitle: "col-9 bg-fourth-end rounded-right2 endPosition",
        },
        {
          id: 4,
          key: "Tipo",
          keyTotal: "Total",
          color: "#0561FC",
          iconBackOpacity: 0,
          classCard: "col-3",
          titleClass: "title-count-card",
          totalClass: "total-count-card",
          propertiesIcon:
            "icon-count-card col-3 d-flex align-items-center bg-fourth rounded-left2",
          icon: <em className="icon--attention-alt"></em>,
          title: "Priority",
          propertiesTitle: "col-9 bg-fourth-end rounded-right2 endPosition",
        },
      ],
      totalStatus: [],
      creationTendency: [],
      maxTendencia: 0,
      orderHeaders: [
        "PO No.",
        "Status",
        "Company",
        "Shipper",
        "Consignee",
        "Transport mode",
        "Required at destination",
        "Creation date",
        "Line",
        "Item",
        "Description",
        "Status",
        "QTY ordered",
        "QTY confirmed",
        "UOM",
        "Ready charge request",
        "Promise charge request",
        "Real charge request",
      ],
      orderKeys: [
        "PONumber",
        "StatusPo",
        "CustomerName",
        "SupplierName",
        "ConsigneeName",
        "TransportMode",
        "RequiredDestinationDate",
        "createdate",
        "LineID",
        "ItemNo",
        "Description",
        "Status",
        "QTYOrdered",
        "QTYConfirmed",
        "UOMCode",
        "RequiredCagoReady",
        "PromiseCargoReady",
        "ActualCargoReady",
      ],
      priorityChecked: false,
      extraFilter: "",
    };
  }

  componentDidMount = async () => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    this.setState({
      isMovil: isMobileDevice,
    });

    await this.createFilter();
    await this.getOrdersList();
    await this.getGraphicInfo();
    await this.getCatalogs();
    this.GenerateExtraFilter();
  };

  getCommnets = async(item, NewComments) => {
    try {
      let { CustomerId } = item;
      // let moduleId = 10;
      // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // const response = await axios
      //   .get(
      //     `${Services.ORDEN_SERV.path}orderComents/get-comment?rowid=${item.POId}&moduleid=${moduleId}&general=false&zona=${timezone}&lang=${this.props.lng}`
      //   )
      // let comentario = response.data.Comment;
      let comentario = getCommentsResponse.Comment;
      this.getUserMenciones(comentario, CustomerId, item.POId, item);

      if (NewComments) {
        message.success(NewComments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getUserMenciones = async(comentario, CustomerId, ShipmentId, item) => {
    try {
      // let { userId } = this.state;
      // const response = await axios
      //   .get(
      //     `${Services.ORDEN_SERV.path}orderComents/getUserMension?CustomerId=${CustomerId}&user=${userId}`
      //   )
      // const data = response.data.data;
      const data = getMentionsResponse;
      this.setState({
        commentsData: comentario,
        users: data,
        rowid: ShipmentId,
        itemCommenSelect: item,
        loadercomments: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  newMessage = async (data) => {
    // let { userId, rowid, itemCommenSelect } = this.state;
    // data.Rowid = rowid;
    // data.UserId = userId;
    // data.ModuleId = 10;

    // console.log("data", data);

    // if (data.Mentions.length > 0) {
    //   let uniq = [...new Set(data.Mentions)];
    //   data.Mentions = uniq;
    // }

    // axios
    //   .post(`${Services.ORDEN_SERV.path}orderComents/add-comment`, data)
    //   .then(() => {
    //     this.getCommnets(
    //       itemCommenSelect,
    //       "Comment successfully added."
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  deleteMessage = async (idDelete) => {
    // let { itemCommenSelect } = this.state;

    // axios
    //   .delete(
    //     `${Services.ORDEN_SERV.path}orderComents/delete-comment/${idDelete}`
    //   )
    //   .then(() => {
    //     this.getCommnets(
    //       itemCommenSelect,
    //       "Comment successfully deleted."
    //     );
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  setOrderSearch = (_name, value) => {
    this.setState(
      {
        search: value,
        activeInvoicePage: 1,
        numberPageInvoice: 1,
        numberPage: 1,
      },
      () => {
        this.getOrdersList();
      }
    );
  };
  createFilter = async () => {
    let { advancedSearch } = this.state;
    advancedSearch = FormFiltros.map((filtro) => {
      return {
        id: filtro.id,
        options: filtro.options,
        isDate: filtro.type === "Datetime",
        UseIN: filtro.type === "Select",
        value: "",
        type: filtro.type,
        label: filtro.label,
        name: filtro.name,
        class: filtro.class,
        style: filtro.style,
      };
    });
    this.setState({ advancedSearch });
  };

  sendMessage = (text, type) => {
    Swal.fire({
      icon: type,
      confirmButtonColor: "#2b3b57",
      confirmButtonText: "Ok",
      html: `<div class="text-center">
      ${text}
      </div>`,
    });
  };

  resetAdvance = () => {
    let { advancedSearch, priorityChecked } = this.state;
    advancedSearch.map((advData) => {
      if (advData.type === "Datetime") {
        advData.value = "";
        advData.valueD = "";
      } else if (advData.id === "PriorityPO") {
        advData.value = priorityChecked ? 1 : 0;
      } else {
        advData.value = [];
      }
      return advData;
    });
    this.setState({ advancedSearch }, () => {
      this.getOrdersList();
      this.generateFilters();
    });
  };
  generateFilters = () => {
    let { catalogCombo, advancedSearch } = this.state;
    let { t } = this.props;
    let Inputs = [];

    Inputs.push(
      advancedSearch.map((e) => {
        if (e.type === "Select")
          return (
            <div style={{ marginTop: "15px" }} className={e.class} key={e.id}>
              <Fade left opposite>
                {" "}
                <span id={e.id} style={e.style}>
                  <label>
                    {e.label}
                    {e.required === true ? (
                      <span style={{ color: "#ff0000" }}>*</span>
                    ) : (
                      ""
                    )}
                  </label>
                </span>
              </Fade>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Dropdown
                  className="NewClass"
                  onChange={this.handleChangeSelect(e.id)}
                  id={e.id}
                  options={catalogCombo[e.options]}
                  clearable
                  search
                  selection
                  fluid
                  value={e.value}
                  multiple
                  allowAdditions
                />
              </div>
            </div>
          );
        else if (e.type === "Datetime") {
          return (
            <div style={{ marginTop: "15px" }} className={e.class} key={e.id}>
              <Fade left opposite>
                <span id={e.label} style={e.style}>
                  {" "}
                  <label>
                    {e.label} {e.required === true ? <span>*</span> : ""}{" "}
                  </label>
                </span>
              </Fade>
              <RangePicker
                onChange={this.handleChangeDate(e.id)}
                size="middle"
                className="col-md-12"
                value={e.valueD}
                placeholder={["Start date", "End date"]}
              />
              <Fade left opposite>
                <label style={e.style}>{e.error}</label>
              </Fade>
            </div>
          );
        }
      })
    );
    let NewInput = (
      <div className="row">
        <div className="col-12">
          <em
            class="fa-2x mr-2 float-right fas fa-eraser"
            style={{
              color: "#2B3B57",
              marginTop: "7px",
              cursor: "pointer",
              textAlign: "end",
            }}
            title="Remove filters"
            onClick={this.resetAdvance}
          />
        </div>
        {Inputs}
      </div>
    );
    this.setState({
      filtros: NewInput,
    });
  };

  handleChangeSelect = (selected) => (_evt, data) => {
    let { advancedSearch } = this.state;
    if (data.value) {
      advancedSearch.map((search) => {
        if (search.id === selected) {
          search.value = data.value;
        }
        return search;
      });
    } else {
      advancedSearch.map((search) => {
        if (search.id === selected) {
          search.value = "";
        }
        return search;
      });
    }
    this.setState({ advancedSearch, numberPage: 1 }, () => {
      this.generateFilters();
      this.getOrdersList();
    });
  };

  handleChangeDate = (name) => (event) => {
    let { advancedSearch } = this.state;

    try {
      advancedSearch.map((search) => {
        if (search.id === name) {
          let init = event ? event[0].format("YYYY-MM-DD") : "";
          let end = event ? event[1].format("YYYY-MM-DD") : "";
          search.value = { init, end };
          search.valueD = event ? [event[0], event[1]] : "";
          search.isDate = true;
        }
        return search;
      });
      this.setState({ advancedSearch, numberPage: 1 }, () => {
        this.generateFilters();
        this.getOrdersList();
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  getCatalogs = async () => {
    let { catalogCombo } = this.state;
    let data = {
      client: this.state.clientesSelect,
      userId: this.state.userId,
      lang: this.props.lng,
    };
    try {
      // let success = await axios.post(
      //   `${Services.PURCHASE_ORDER.path}purchaseOrder/filterCatalog`,
      //   data
      // );
      // catalogCombo = success.data.data;
      catalogCombo = catalogsResponse;
      this.setState(
        {
          catalogCombo,
          loading: false,
        },
        () => {
          this.generateFilters();
        }
      );
    } catch (error) {
      console.warn(error);
    }
  };

  GenerateExtraFilter = () => {
    let { extraFilter, priorityChecked } = this.state;
    extraFilter = (
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <img
          src={priorityShipmentURL}
          title="Filter priority PO"
          width="15px"
          alt="priority_po"
          height="15px"
        />
        <ThemeProvider theme={{ ...theme }}>
          <Switch
            className="form-input-switch"
            checked={priorityChecked}
            onChange={this.handlePriorityChange}
            style={{ marginLeft: "1em" }}
          ></Switch>
        </ThemeProvider>
      </div>
    );
    this.setState({ extraFilter });
  };

  handlePriorityChange = (event) => {
    let { advancedSearch, priorityChecked } = this.state;
    console.log("event", event);
    priorityChecked = event;
    let obj = advancedSearch.find((adv) => {
      return adv.id === "PriorityPO";
    });
    if (!obj) {
      advancedSearch.push({ id: "PriorityPO", val: "", UseIN: false });
    }
    advancedSearch.map((adv) => {
      if (adv.id === "PriorityPO") {
        if (event) {
          adv.value = 1;
        } else {
          adv.value = 0;
        }
      }
      return adv;
    });

    this.setState({ advancedSearch, priorityChecked }, () => {
      this.GenerateExtraFilter();
      this.getOrdersList();
    });
  };

  /*=============================================
  =            Get Order List                   =
  =============================================*/

  getGraphicInfo = async () => {
    let { clientesSelect, userId } = this.state;
    try {
      // let params = { customers: clientesSelect, userId, lang: this.props.lng };
      // let respGraph = await axios.post(
      //   `${Services.PURCHASE_ORDER.path}purchaseOrder/graphicList`,
      //   params
      // );

      // const { creationTendency, totalStatus, totalInfo } = respGraph.data;
      const { creationTendency, totalStatus, totalInfo } = ChartsResponse;

      this.setState(
        {
          totalCardsInfo: totalInfo,
          totalStatus,
        },
        () => {
          this.obtieneTendenciaCreacion(creationTendency);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  obtieneTendenciaCreacion = async (data) => {
    let { tendenciaData, maxTendencia } = this.state;

    tendenciaData = [["x", "Total PO"]];
    data[0].data.forEach((mes) => {
      tendenciaData.push([mes[0], parseInt(mes[1])]);
      if (parseInt(mes[1]) > maxTendencia) {
        maxTendencia = mes.total;
      }
    });
    this.setState({ creationTendency: tendenciaData, maxTendencia });
  };

  obtieneEstatusOrdenes = (data) => {
    var estatus = [];
    var colorInicial = "#0045F3";
    data.forEach((value) => {
      estatus.push({
        nombre: value.estatus,
        total: parseInt(value.total),
        color: colorInicial,
        extra: "",
      });
      colorInicial = this.colores(colorInicial);
    });
    let EstatusTendencia = [
      [
        "Element",
        "Total",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ],
    ];
    try {
      estatus.forEach((value) => {
        const element = value;
        EstatusTendencia.push([
          element.nombre,
          element.total,
          element.color,
          element.extra,
        ]);
      });
      return EstatusTendencia;
    } catch (error) {
      return EstatusTendencia;
    }
  };

  hex = (c) => {
    var s = "0123456789abcdef";
    var i = parseInt(c);
    if (i === 0 || isNaN(c)) return "00";
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
  };
  convertToHex = (rgb) => {
    return this.hex(rgb[0]) + this.hex(rgb[1]) + this.hex(rgb[2]);
  };
  trim = (s) => {
    return s.charAt(0) === "#" ? s.substring(1, 7) : s;
  };

  convertToRGB = (hex) => {
    var color = [];
    color[0] = parseInt(this.trim(hex).substring(0, 2), 16);
    color[1] = parseInt(this.trim(hex).substring(2, 4), 16);
    color[2] = parseInt(this.trim(hex).substring(4, 6), 16);
    return color;
  };
  colores = (startHex) => {
    var start =
      this.convertToRGB(startHex); /* The beginning of your gradient */
    var end = this.convertToRGB("#FFFFFF"); /* The end of your gradient */

    var alpha = 0.7; /* Alpha blending amount */
    var c = [];
    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] =
      start[2] * alpha +
      (1 - alpha) * end[2]; /* Set the background color of this element */
    return "#" + this.convertToHex(c);
  };

  getOrdersList = async () => {
    let sendData = {};
    if (this.state.advancedObj && this.state.advancedObj.length > 0) {
      this.state.advancedObj.map((obj) => {
        if (obj.UseIN) {
          let data = [];
          if (obj.value && obj.value.length > 0) {
            obj.value.forEach((d) => {
              data.push(d.split("_")[0]);
            });
          }
          obj.value = _.cloneDeep(data);
        }
        return obj;
      });
    }
    sendData = {
      client: this.state.clientesSelect,
      pag: this.state.numberPage,
      limit: this.state.limit,
      EsDTA: 0,
      search: this.state.search,
      query: { allData: false, noGroup: false },
      advanced: this.state.advancedSearch,

      lang: this.props.lng,
      userId: this.state.userId,
    };
    this.setState({
      url: encodeURI(
        `${Services.PURCHASE_ORDER.path}purchaseOrder/listPurchaseOrders?search=${this.state.search}&allData=true`,
        {
          lang: this.props.lng,
          userId: this.state.userId,
        }
      ),
      paramsxml: sendData,
    });

    try {
      // let success = await axios.post(
      //   `${Services.PURCHASE_ORDER.path}purchaseOrder/listPurchaseOrders`,
      //   sendData
      // );
      // let data = success.data.data.map((e) => {
      let data = poListResponse.map((e) => {
        let TraspormodeId = 1;
        e.TraspormodeId = TraspormodeId;
        e.QTYOrderedFormatted = Number(e.QTYOrdered).toLocaleString("En-us");
        return e;
      });

      this.setState({
        // totalPages: success.data.totalPage,
        // totalPagesExt: success.data.totalPage,
        totalPages: 10,
        totalPagesExt: 10,
        data: data,
        dataAux: data,
        dataOut: data,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      console.warn(error);
    }
  };

  generateOrderExcel = async() => {
    try {
      this.setState({
        excelInvoiceLoading: true,
      });
  
      // let body = {
      //   client: this.state.clientesSelect,
      //   pag: this.state.numberPage,
      //   limit: this.state.limit,
      //   EsDTA: 0,
      //   search: this.state.search,
      //   query: {
      //     allData: true,
      //     noGroup: true,
      //   },
      //   advanced: this.state.advancedSearch,
  
      //   lang: this.props.lng,
      //   userId: this.state.userId,
      // };
      // const success = await axios.post(
      //     `${Services.PURCHASE_ORDER.path}purchaseOrder/listPurchaseOrders`,
      //     body
      // )
      // let data = success.data.data;
      let data = poListResponse;
      if (data && data.length > 0) {
        data.map((d) => {
          d.TotalAmount = parseFloat(d.TotalAmount);
          return d;
        });
      }
      let titleDownload = this.state.nameXlsx;
      let info = {
        config: {},
      };

      let options = {
        headers: this.state.orderHeaders,
        keys: this.state.orderKeys,
        image: {
          name: "magicdome",
        },
        sheetName: "Orders",
      };
      if (!data || data.length === 0) {
        throw new Error("No hay datos para exportar");
      }
      
      const successExcel = await axios
        // .post(`${Services.REPORT.path}/report/dynamic/xlsx`, {
        .post('https://servicereportapi.azurewebsites.net/report/dynamic/xlsx', {
          info,
          data,
          options,
        })
      if (successExcel.data.base64) {
        const fileName = titleDownload + ".xlsx";
        const linkSource = `data:application/xlsx;base64,${successExcel.data.base64}`;
        saveAs(linkSource, fileName);
      } else throw new Error("Error al generar el excel");
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        excelInvoiceLoading: false,
      });
    }
  };
  resetTable = () => {
    this.setState({ isSearchComments: false, handleResCom: "unset" }, () => {
      this.getOrdersList();
    });
  };

  changePage = (_e, { activePageOrder }) => {
    this.setState(
      {
        numberPage: activePageOrder,
      },
      () => {
        this.getOrdersList();
      }
    );
  };

  /*=============================================
  =            Selected Order                   =
  =============================================*/

  selectedOrder = (order) => {
    this.setState({
      POId: order.POId,
      PONumber: order.PONumber,
      openComments: true,
      handleResCom: "set",
      isSearchComments: true,
    });
  };

  handleClose = () => {
    this.setState({ openComments: false, isSearchComments: false });
  };

  setAdvanced = () => {
    let { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen }, () => {});
  };

  getTotalChartsData = () => {
    let { totalStatus } = this.state;
    const data = totalStatus.map((item, index) => {
      let obj = {};
      obj[item.estatus] = item.total;
      obj.id = item.estatus;
      if (colors[index]) {
        obj.color = colors[index];
      } else {
        obj.color = "#0045F3";
      }
      return obj;
    });
    return data.reverse();
  };

  getTotalChartsKeys = () => {
    let { totalStatus } = this.state;
    const keys = totalStatus.map((item) => item.estatus);
    return keys;
  };

  getTotalChartsColors = () => {
    let { totalStatus } = this.state;
    let colorInicial = "#0045F3";
    const colors = totalStatus.map((_item) => {
      let color = colorInicial;
      colorInicial = this.colores(colorInicial);
      return color;
    });
    return colors;
  };
  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    const {
      viewModalComments,
      loadercomments,
      commentsData,
      itemCommenSelect,
      users,
      rolId,
      userId,
      scrollLine,
      data,
      columns,
      loading,
      filtros,
      isMovil,
      totalCardsConf,
      totalCardsInfo,
      creationTendency,
      maxTendencia,
    } = this.state;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <legend className="legendEuro">
            <img
              alt="orders"
              style={{ width: "28px", margin: "-6px 6px 1px" }}
              src={IMGOrdenes} />
            Management
          </legend>
        </div>

        <CountCard config={totalCardsConf} data={totalCardsInfo} />
        <div className="row">
          <div className="col-xs-12 col-md-6 .col-lg-6 ">
            <div className="card myCard equalsWidth">
              <div className="card-header">
                <div className="card-title" style={{ fontWeight: "bold" }}>
                  Total PO by status
                </div>
              </div>
              <div className="card-body" style={{ overflow: "hidden" }}>
                {loading ? (
                  <FullLoader />
                ) : (
                  <ResponsiveBar
                    data={this.getTotalChartsData()}
                    keys={this.getTotalChartsKeys()}
                    tooltip={(item) => {
                      return (
                        <BasicTooltip
                          id={item.data.id}
                          value={item.data[item.data.id]}
                          color={item.data.color}
                          enableChip
                        />
                      );
                    }}
                    margin={{ top: 20, right: 40, bottom: 50, left: 130 }}
                    padding={0.15}
                    layout="horizontal"
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={colors}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    theme={{
                      axis: {
                        fontSize: "14px",
                        tickColor: "#eee",
                        ticks: {
                          line: {
                            stroke: "#555555",
                          },
                          text: {
                            fill: "#535353",
                          },
                        },
                        legend: {
                          text: {
                            fill: "#aaaaaa",
                          },
                        },
                      },
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 25,
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    enableLabel={false}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 .col-lg-6 ">
            <div className="card myCard equalsWidth">
              <div className="card-header">
                <div className="card-title" style={{ fontWeight: "bold" }}>
                  Tendency total PO by creation date
                </div>
              </div>
              <div className="card-body" style={{ overflow: "hidden" }}>
                {loading ? (
                  <FullLoader />
                ) : (
                  <div id="chartDashboard">
                    <Chart
                      style={{
                        width: "100%",
                      }}
                      className={"valueTable"}
                      chartType="LineChart"
                      loader={<div>Loading Chart</div>}
                      data={creationTendency}
                      options={{
                        width: "100%",
                        height: "300px",
                        curveType: "function",
                        legend: {
                          position: "none",
                        },
                        tooltip: { isHtml: true },
                        vAxis: {
                          minValue: 0,
                          viewWindow: { min: 0, max: maxTendencia + 1 },
                        },

                        pointSize: 5,
                        series: {
                          0: {
                            color: "#2B3B57",

                            lineWidth: 2,
                          },
                        },
                      }}
                      rootProps={{ "data-testid": "1" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Card className="myCard">
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Row>
                <Col md={12} xl={12}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Sidebar.Pushable as={Segment}>
                      <Sidebar
                        as={Menu}
                        animation="overlay"
                        icon="labeled"
                        style={{
                          maxHeight: "84%!important",
                          width: isMovil
                            ? window.innerWidth - window.innerWidth * 0.09
                            : 400,
                        }}
                        direction="right"
                        vertical
                        id="sidebarComents"
                        visible={viewModalComments}
                      >
                        <div className=" col-12 close_container">
                          <div className="header_comments">
                            {itemCommenSelect
                              ? itemCommenSelect.RefCustomer
                              : ""}
                          </div>
                          <div
                            className="close_icon2"
                            onClick={() => {
                              this.setState({ viewModalComments: false });
                            }}
                          >
                            <ButtonAnt
                              size="small"
                              type="primary"
                              shape="circle"
                            >
                              X
                            </ButtonAnt>
                          </div>
                        </div>
                        <div style={{ height: "3em" }} className=" col-12">
                          {" "}
                        </div>

                        {loadercomments ? (
                          <Skeleton active paragraph={{ rows: 17 }} />
                        ) : (
                          <div className="col-12">
                            <Comments data={commentsData} catalogs={users} />
                          </div>
                        )}
                      </Sidebar>

                      <Sidebar.Pusher>
                        <Segment basic>
                          <DataTable
                            downloadExcel={this.generateOrderExcel}
                            excelLoading={this.state.excelInvoiceLoading}
                            labelSelected={""}
                            search={this.state.search}
                            filtrosPanel={filtros}
                            startSubTable={1}
                            paddingSubtable="3%"
                            colspanSubTable={8}
                            showExcel={true}
                            panel={true}
                            data={data}
                            columnsTable={columns}
                            subTable={this.state.subTable}
                            handleSearch={this.setOrderSearch}
                            activePage={this.state.numberPage}
                            pages={this.state.totalPages}
                            OnPageChange={this.changePage}
                            sumaryInfo={this.state.sumaryInfo}
                            ExtraFilter={this.state.extraFilter} />
                        </Segment>
                      </Sidebar.Pusher>
                    </Sidebar.Pushable>
                  </Suspense>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
  lang: state.header.lang,
});
const mapDispatchToProps = () => ({});
export default (
  connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderListCommponent))
);
