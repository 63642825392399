import React, { Component } from 'react';
import { saveAs } from 'file-saver';
import Axios from 'axios';
class EUGround extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { label: 'ID', key: 'ID' },
        { label: 'Description', isLarge: true, key: 'Description' },
        { label: 'UnitCost', key: 'UnitCost' },
        { label: 'Currency', key: 'Currency' },
        { label: 'UnitOfMeasure', key: 'UnitOfMeasure' },
        { label: 'Group', key: 'Group' }
      ],
      aux: {},
      data: [],
      xlsx: <em className="fa-2x fas fa-file-excel mr-2"
        style={{ color: '#66bb6a', cursor: 'pointer' }}
        onClick={this.XLSX} />
    };
  }


  componentDidMount = () => {
    this.setState({
      aux: this.props.aux
    }, () => this.buildData());
  }


  componentWillReceiveProps = (nextProps) => {
    this.setState({
      aux: nextProps.aux
    }, () => this.buildData());
  }


  buildData = () => {
    let { aux } = this.state;
    let Keys = Object.keys(aux);
    let data = [];
    Keys.map((e) => {
      aux[e].forEach((item) => {
        data.push(item);
      });
    });
    this.setState({
      data
    });
  }


  XLSX = () => {
    this.setState(
      {
        xlsx: (
          <div className="ball-clip-rotate">
            <div></div>
          </div>
        ),
      },
      () => {
        let { columns, data } = this.state;
        let headers = [];
        let keys = [];
        columns.map((e) => {
          headers.push(e.label);
          keys.push(e.key);
          return e;
        });
        let obj = {
          data: data,
          options: {
            headers: headers,
            keys: keys,
            image: {
              name: 'magicdome',
            },
          },
        };
        if (headers.length > 0) {
          Axios.post(
            'https://servicereportapi.azurewebsites.net/report/dynamic/xlsx',
            obj
          )
            .then((success) => {
              if (success.data.base64) {
                //const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';

                let fileName;
                this.props.nameXlsx
                  ? (fileName = this.props.nameXlsx)
                  : (fileName =
                    Math.floor(Math.random() * 900000) +
                    100000 +
                    Math.floor(Date.now() / 1000) +
                    '.xlsx');

                const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
                saveAs(linkSource, fileName);
              } else console.warn('Error downloading file', success);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#66bb6a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            })
            .catch((error) => {
              console.error('errorr', error);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#66bb6a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            });
        }
      }
    );
  };
  render() {
    return (
      <>
        {this.state.xlsx}
      </>

    );
  }
}

export default EUGround;


