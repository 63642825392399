export const Services = {
   "Get_storageOffice": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_storageIncome": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Storage_Despachos_ForId": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Location_Almace": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Post_Data_InventaryDetail": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_CatalogStorage": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Storage_Income_ForId": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Events_General": {
      "path": "https://roldanapiaevents.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Charts_IncomeOffice": {
      "path": "https://roldanapialmacen.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Storage_Income_Doc": {
      "path": "https://roldanapidocument.azurewebsites.net",
      "method": "",
      "descrp": ""
   }
}