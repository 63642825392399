import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';

let data = {
  labels: [
                   
  ],
  datasets: [{
      data: [],
      backgroundColor: [
          '#7266ba',
          '#fad732',
          '#23b7e5',
          '#fad732'
      ],
      hoverBackgroundColor: [
          '#7266ba',
          '#fad732',
          '#23b7e5',
          '#fad732'
      ]
  }]
}

const options = {
  legend: {
    display: true
  }
}



/*=============================================
=          ByStatusComponent                  =
=============================================*/

function ByStatusComponent(props){
  let total = [];
  total = props.totalsByStatus;

  data.labels = []
  data.datasets[0].data= []
  total.map((e)=>{
      data.labels.push(e.name)
      data.datasets[0].data.push( e.total)
  });

  return (
    <Card className="myCard">
      <CardBody>
        <div className="row">
          <div className="col-12">
            <center><h4 className="mb-3 py-2">Total Movements By Status</h4></center>
          </div>

          <div className="col-12">
            <center>
              <DoughnutChart data={data} options={options}/>
            </center>
          </div>
        </div>
      </CardBody>
    </Card>   
  );
}

export default ByStatusComponent;


