import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import POLines from './polines';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Errors from '../../../../../template/Errors';
import Tables from '../../../../../template/Table';
import swal from 'sweetalert';
import restService from '../../../../../../services/restService';

import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const rest = new restService();

class FormExtended extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            SelectedIsLoaded: false,
            columns: [
                { label: 'Line Number', key: '', index: true },
                { label: 'Item Number', key: 'ItemNumber' },
                { label: 'Item Description', key: 'ItemDescription' },
                { label: 'Qty Ordered', key: 'QtyOrdered' },
                { label: 'Unit Measure', key: 'UnitMeasure' },
                { label: 'Origin Country Id', key: 'OriginCountryId' },
                {
                    label: 'Actions', key: '',
                    custom: (value) => {
                        return (
                            <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value)}>
                                <Icon className="icon" style={{ fontSize: 20 }}>
                                    remove
                                </Icon>
                            </Button>
                        )
                    }
                }
            ],
            POLineComponent: '',
            error: {
                open: false,
                message: '',
                type: 'error'
            }
        };
    }

    setItems = (item) => {
        this.setState({
            items: [...this.state.items, item]
        })
    }

    deleteLine = index => event => {
        let { PONumber } = this.props
        let { items } = this.state
        swal("Delete", 'Item ' + index.ItemNumber, "warning").then(() => {
            rest.EXEC({
                _function: 'RemoveOneItemFromOrder', params: '', data: {
                    ItemNumber: index.ItemNumber,
                    PONumber: PONumber
                }
            }, fetch).then(success => {
                this.removeLine(index, items)
            }).catch(error => {
                
            })
        })
    }


    removeLine = (index, items) => {
        items.splice(index, 1)
        this.setState({
            error: {
                message: 'Line Item Removed',
                open: true,
                type: 'warning'
            }
        })
    }


    componentDidMount = () => {
        let { selected } = this.props
        let { SelectedIsLoaded, items } = this.state
        if (Array.isArray(selected) && selected.length > 0 && Array.isArray(selected[0].items) && selected[0].items.length > 0 && !SelectedIsLoaded && items.length === 0) {
            this.setState({
                items: this.parseItems(selected[0].items),
                SelectedIsLoaded: true,
            })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        let { selected } = nextProps
        let { SelectedIsLoaded, items } = this.state
        if (Array.isArray(selected) && selected.length > 0 && Array.isArray(selected[0].items) && selected[0].items.length > 0 && !SelectedIsLoaded && items.length === 0) {
            this.setState({
                items: this.parseItems(selected[0].items),
                SelectedIsLoaded: true,
            })
        }
    }

    parseItems = (items) => {
        return items.map((e) => {
            return ({
                ItemDescription: e.ItemDescription,
                ItemNumber: e.ItemNumber,
                OriginCountryId: e.CountryOrigin,
                QtyOrdered: e.QtyOrdered,
                UnitMeasure: e.UnitsMeasure
            })
        })
    }

    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
        let { items } = this.state
        let { PONumber } = this.props
        return (
            <ExpansionPanel elevation={0} defaultExpanded >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <legend ><em className="fas fa-stream fa-1x mr-2"></em>Line Items</legend>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-12">
                            <POLines refreshItems={this.setItems} PONumber={PONumber} ></POLines>
                        </div>
                        <div className="col-md-12">
                            <hr></hr>
                        </div>
                        <div className="col-md-12">
                            <Tables data={items} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
                        </div>
                        <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}
export default FormExtended;
