const mapJson = {
  "dataMap": [
   {
      "id":"7310630",
      "BookingNumber":"BO7310630",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"NORWEGIAN CRUISE LINES",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "details":null,
         "contact":"NORWEGIAN CRUISE LINES",
         "email":null
      },
      "Supplier":{
         "name":null,
         "address":null,
         "details":null,
         "contact":null,
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"FREEPORT SHIP SERVICES",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"FREEPORT SHIP SERVICES",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"OCEANIA INSIGNA DRY DOCK",
         "name":"OCEANIA INSIGNA DRY DOCK"
      }
   },
   {
      "id":"7309796",
      "BookingNumber":"BO7309796",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"~NORWEGIAN CRUISE LINE",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "details":null,
         "contact":"~NORWEGIAN CRUISE LINE",
         "email":null
      },
      "Supplier":{
         "name":"HF ITALY SRL",
         "address":"",
         "details":null,
         "contact":"HF ITALY SRL",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"FREEPORT SHIP SERVICES",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"FREEPORT SHIP SERVICES",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"OCEANIA INSIGNA DRY DOCK",
         "name":"OCEANIA INSIGNA DRY DOCK"
      }
   },
   {
      "id":"7307574",
      "BookingNumber":"BO7307574",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"NORWEGIAN CRUISE LINES",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "details":null,
         "contact":"NORWEGIAN CRUISE LINES",
         "email":null
      },
      "Supplier":{
         "name":"TECHNOGYM S.P.A",
         "address":"",
         "details":null,
         "contact":"TECHNOGYM S.P.A",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"MASTER MV NORWEGIAN JEWEL",
         "address":"47 CHANGI SOUTH AVENUE 2 # 04-01",
         "pickupDate":"",
         "contact":"MASTER MV NORWEGIAN JEWEL",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"NCL JEWEL DRY DOCK",
         "name":"NCL JEWEL DRY DOCK"
      }
   },
   {
      "id":"7313354",
      "BookingNumber":"BO7313354",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"TRANSGROUP GLOBAL LOGISTICS-SEATTLE",
         "address":"18850 8TH AVE SOUTH - SUITE 100",
         "pickupDate":"",
         "details":null,
         "contact":"TRANSGROUP GLOBAL LOGISTICS-SEATTLE",
         "email":null
      },
      "Supplier":{
         "name":"DANSK WILTON A/S",
         "address":"",
         "details":null,
         "contact":"DANSK WILTON A/S",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"MASTER MV NCL SKY",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"MASTER MV NCL SKY",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"NCL SKY  DRY DOCK",
         "name":"NCL SKY  DRY DOCK"
      }
   },
   {
      "id":"7314617",
      "BookingNumber":"BO7314617",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"NORWEGIAN CRUISE LINES",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "details":null,
         "contact":"NORWEGIAN CRUISE LINES",
         "email":null
      },
      "Supplier":{
         "name":"TIROLO SRL",
         "address":"BAGART 8A",
         "details":null,
         "contact":"TIROLO SRL",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"FREEPORT SHIP SERVICES",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"FREEPORT SHIP SERVICES",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"NCL SKY DRY DOCK",
         "name":"NCL SKY DRY DOCK"
      }
   },
   {
      "id":"7311740",
      "BookingNumber":"BO7311740",
      "From":{
         "Location":{
            "country":"NLRTM",
            "Latitude":"51.9279514",
            "Longitude":"4.4201956"
         }
      },
      "To":{
         "Location":{
            "country":"AUSYD",
            "Latitude":"-33.9117",
            "Longitude":"151.1832"
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"MSC LINE"
      },
      "Consignee":{
         "name":"TRANSGROUP GLOBAL LOGISTICS-SEATTLE",
         "address":"18850 8TH AVE SOUTH - SUITE 100",
         "pickupDate":"",
         "details":null,
         "contact":"TRANSGROUP GLOBAL LOGISTICS-SEATTLE",
         "email":null
      },
      "Supplier":{
         "name":"TRIANGLE LOGISTICS BV",
         "address":"RISSENER STR 106",
         "details":null,
         "contact":"TRIANGLE LOGISTICS BV",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"NCL VESSELS ON BEHALF OF TRANSGROUP",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "contact":"NCL VESSELS ON BEHALF OF TRANSGROUP",
         "email":null
      },
      "Status":{
         "label":"Booked",
         "id":42
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"2018-11-14",
      "ETA":"2018-12-29",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":"MSC LINE",
         "VesselOrFlight":"MADRID MAERSK",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":"NLRTM",
         "Destination":"AUSYD",
         "FinalDestination":"SYDNEY",
         "ETD":"2018-11-14",
         "ETA":"2018-12-29",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"NCL JEWEL DRY DOCK",
         "name":"NCL JEWEL DRY DOCK"
      }
   },
   {
      "id":"7311477",
      "BookingNumber":"BO7311477",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"NORWEGIAN CRUISE LINES",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "details":null,
         "contact":"NORWEGIAN CRUISE LINES",
         "email":null
      },
      "Supplier":{
         "name":"DE WAVE SRL",
         "address":"VIA 4 NOVEMBRE 5/G",
         "details":null,
         "contact":"DE WAVE SRL",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"MASTER MV OCEANIA INSIGNIA",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"MASTER MV OCEANIA INSIGNIA",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"OCEANIA INSIGNA DRY DOCK",
         "name":"OCEANIA INSIGNA DRY DOCK"
      }
   },
   {
      "id":"7314028",
      "BookingNumber":"BO7314028",
      "From":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "To":{
         "Location":{
            "country":null,
            "Latitude":"",
            "Longitude":""
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"."
      },
      "Consignee":{
         "name":"NORWEGIAN CRUISE LINES",
         "address":"7665 CORPORATE CENTER DRIVE",
         "pickupDate":"",
         "details":null,
         "contact":"NORWEGIAN CRUISE LINES",
         "email":null
      },
      "Supplier":{
         "name":"SOMEC SPA",
         "address":"TERRE PLEIN DE MOUREPIANE - PORTE 4",
         "details":null,
         "contact":"SOMEC SPA",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"FREEPORT SHIP SERVICES",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"FREEPORT SHIP SERVICES",
         "email":null
      },
      "Status":{
         "label":"Order Confirmed",
         "id":41
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":".",
         "VesselOrFlight":".",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":null,
         "Destination":null,
         "FinalDestination":".",
         "ETD":"",
         "ETA":"",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"NCL SKY DRY DOCK",
         "name":"NCL SKY DRY DOCK"
      }
   },
   {
      "id":"7314198",
      "BookingNumber":"BO7314198",
      "From":{
         "Location":{
            "country":"NLRTM",
            "Latitude":"51.9279514",
            "Longitude":"4.4201956"
         }
      },
      "To":{
         "Location":{
            "country":"BSFPO",
            "Latitude":"26.5286",
            "Longitude":"-78.7632"
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"MSC LINE"
      },
      "Consignee":{
         "name":"FREEPORT SHIP SERVICES",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "details":null,
         "contact":"FREEPORT SHIP SERVICES",
         "email":null
      },
      "Supplier":{
         "name":"MEIKO USA, INC.",
         "address":"ENGLERSTRASSE, 3",
         "details":null,
         "contact":"MEIKO USA, INC.",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"MASTER M/V NORWEGIAN SKY C/O",
         "address":"19A PEEL STREET",
         "pickupDate":"",
         "contact":"MASTER M/V NORWEGIAN SKY C/O",
         "email":null
      },
      "Status":{
         "label":"Booked",
         "id":42
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"2018-12-23",
      "ETA":"2019-01-10",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":"MSC LINE",
         "VesselOrFlight":"MSC ANISHA R",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":"NLRTM",
         "Destination":"BSFPO",
         "FinalDestination":"FREEPORT",
         "ETD":"2018-12-23",
         "ETA":"2019-01-10",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"NCL SKY  DRY DOCK",
         "name":"NCL SKY  DRY DOCK"
      }
   },
   {
      "id":"7307131",
      "BookingNumber":"BO7307131",
      "From":{
         "Location":{
            "country":"SEGOT",
            "Latitude":"57.70716",
            "Longitude":"11.96679"
         }
      },
      "To":{
         "Location":{
            "country":"SGSIN",
            "Latitude":"1.3667",
            "Longitude":"103.8"
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"MSC LINE"
      },
      "Consignee":{
         "name":"TRANSGROUP GLOBAL LOGISTICS-SEATTLE",
         "address":"18850 8TH AVE SOUTH - SUITE 100",
         "pickupDate":"",
         "details":null,
         "contact":"TRANSGROUP GLOBAL LOGISTICS-SEATTLE",
         "email":null
      },
      "Supplier":{
         "name":"UPSMEIER POWER SYSTEM AG",
         "address":"",
         "details":null,
         "contact":"UPSMEIER POWER SYSTEM AG",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"MASTER MV NORWEGIAN JEWEL",
         "address":"47 CHANGI SOUTH AVENUE 2 # 04-01",
         "pickupDate":"",
         "contact":"MASTER MV NORWEGIAN JEWEL",
         "email":null
      },
      "Status":{
         "label":"Booked",
         "id":42
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":null,
      "ETD":"2018-09-05",
      "ETA":"2018-10-04",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":"MSC LINE",
         "VesselOrFlight":"MARCHEN MAERSK",
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"",
      "ATD":"",
      "Route":{
         "Origin":"SEGOT",
         "Destination":"SGSIN",
         "FinalDestination":"SINGAPORE",
         "ETD":"2018-09-05",
         "ETA":"2018-10-04",
         "ATD":"",
         "ATA":""
      },
      "Project":{
         "id":"N JEWEL DRY DOCK",
         "name":"N JEWEL DRY DOCK"
      }
   },
   {
      "id":"7327341",
      "BookingNumber":"BO7327341",
      "From":{
         "Location":{
            "country":"ITPMO",
            "Latitude":"38.1343925",
            "Longitude":"13.359079"
         }
      },
      "To":{
         "Location":{
            "country":"USNYC",
            "Latitude":"40.70757",
            "Longitude":"-74.0012"
         }
      },
      "Shipping":{
         "carrier":null
      },
      "Shipper":{
         "name":"MSC LINE"
      },
      "Consignee":{
         "name":"ELENTENY IMPORTS",
         "address":"N/A",
         "pickupDate":"",
         "details":null,
         "contact":"N/A",
         "email":null
      },
      "Supplier":{
         "name":"ALCESTI",
         "address":"N/A",
         "details":null,
         "contact":"N/A",
         "email":null,
         "pickupDate":""
      },
      "Delivery":{
         "name":"ELENTENY IMPORTS",
         "address":"N/A",
         "pickupDate":"",
         "contact":"N/A",
         "email":null
      },
      "Status":{
         "label":"Arrived",
         "id":44
      },
      "Action":"",
      "TransportMode":{
         "id":1,
         "name":"FCL"
      },
      "trackingStatus":[

      ],
      "PONumber":"PO50103, PO50053",
      "ETD":"2019-07-08",
      "ETA":"2019-08-04",
      "LastUpdate":{
         "porcent":"60",
         "date":"08/12/18"
      },
      "Carrier":{
         "CarrierName":"MSC LINE",
         "VesselOrFlight":null,
         "Latitude":"",
         "Longitude":""
      },
      "Cruise":{
         "CruiseName":""
      },
      "CargoReady":"",
      "ATA":"2019-08-04",
      "ATD":"2019-07-08",
      "Route":{
         "Origin":"ITPMO",
         "Destination":"USNYC",
         "FinalDestination":"EDISON (NJ)",
         "ETD":"2019-07-08",
         "ETA":"2019-08-04",
         "ATD":"2019-07-08",
         "ATA":"2019-08-04"
      },
      "Project":{
         "id":null,
         "name":null
      }
   }
],
"dataTable": [
   {
      "id":"7310630",
      "BookingNumber":"2322121",
      "Almacen":"Despacho",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "icon":{
         "icon":"fa-2x mr-2 fas fa-exclamation-triangle",
         "iconstyle":{
            "color":"red",
            "marginLeft":"11px",
            "marginTop":""
         }
      },
      "To":"ECMEC",
      "Trafico":"IMPO",
      "tiempo":"Devuelto a Tiempo",
      "time":100,
      "estado":"Arribo",
      "T":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "pickupDate":"",
      "TraspormodeId":1,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"asasa",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "ETA":87,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H6785",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":100
   },
   {
      "id":"73106301",
      "BookingNumber":"DO ID",
      "Almacen":"Ingreso",
      "labelId":"HBL / HAWB",
      "From":"CNBUN",
      "icon":{
         "icon":"fa-2x mr-2 fas fa-exclamation-triangle",
         "iconstyle":{
            "color":"#ffef44",
            "marginLeft":"11px",
            "marginTop":""
         }
      },
      "logo":13,
      "To":"ECMEC",
      "Trafico":"EXPO",
      "tiempo":"Por vencer",
      "time":17,
      "estado":"Arribo",
      "status":"En Almacen",
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "Progress":89,
      "ReferenciaCliente":"H4585",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan"
   },
   {
      "id":"7310632",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNBUN",
      "logo":14,
      "To":"ECMEC",
      "Trafico":"EXPO",
      "estado":"Arribo",
      "tiempo":"Vencido",
      "time":16,
      "status":" Liberado",
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H6721",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":100
   },
   {
      "id":"7310633",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "logo":14,
      "To":"ITGOA",
      "Trafico":"AAAA",
      "estado":"Zarpo",
      "tiempo":"En Tiempo",
      "time":15,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H09875",
      "CargoReady":"",
      "Route":"",
      "Project":"Roldan",
      "Progress":89
   },
   {
      "id":"7310634",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "logo":13,
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"En Tiempo",
      "time":18,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"7743843",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "logo":14,
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"En Tiempo",
      "time":16,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"7465343",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "logo":14,
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":17,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"736483e",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":17,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"098439",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":16,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"642938",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":15,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"982734",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":15,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"874658",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":15,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"826348",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":16,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "ETD":"",
      "ETA":"",
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"310634",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":17,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"7310634",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":17,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"73103434",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNZOS",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Zarpo",
      "tiempo":"Por vencer",
      "time":15,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H0985",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":49
   },
   {
      "id":"7310635",
      "BookingNumber":"DO ID",
      "labelId":"HBL / HAWB",
      "From":"CNBUN",
      "To":"ITGOA",
      "Trafico":"IMPO",
      "estado":"Arribo",
      "tiempo":"Por vencer",
      "time":16,
      "Consignee":"NORWEGIAN CRUISE LINES",
      "address":"7665 CORPORATE CENTER DRIVE",
      "TraspormodeId":1,
      "details":null,
      "contact":"NORWEGIAN CRUISE LINES",
      "Provedor":"Hershey",
      "Delivery":"FREEPORT SHIP SERVICES",
      "Consignatario":"",
      "Status":"Action",
      "trackingStatus":"hola",
      "PONumber":null,
      "LastUpdate":"",
      "Carrier":"",
      "ReferenciaCliente":"H1953",
      "CargoReady":"",
      "ETD":"08-07-2009",
      "ETA":"08-07-2009",
      "Route":"",
      "Project":"Roldan",
      "Progress":10
   }
 ],
  'cardResumen' : [
   {
     label: 'Proveedor',
     data: [
       {
         value: '',
         key: 'Proveedor',
         label: 'Nombre:',
         bold: false,
         info: false
       },
       {
         value: '',
         key: 'Consignatario',
         primary: true,
         label: 'Consignatorio',
         bold: true,
         info: false
       },
       {
         value: '',
         key: 'Consignatario',
         label: 'Nombre',
         bold: false,
         info: false
       },
       {
         value: '',
         key: '',
         label: 'Entrega',
         bold: true,
         info: false
       },
       {
         value: '',
         key: '',
         label: 'Nombre',
         bold: false
       }]
   },
   {
     label: 'Ruta',
     data: [
       {
         value: '',
         key: 'RecoleccionEntrega',
         label: 'Recolección / Entrega',
         bold: false
       },
       {
         value: '',
         key: 'DestinoFinal',
         label: 'Destino Final',
         bold: false
       },
       {
         value: '',
         key: 'POL',
         label: 'POL',
         bold: false
       },
       {
         value: '',
         key: 'POD',
         label: 'POD',
         bold: false
       },
       {
         value: '',
         key: 'ETD',
         label: 'ETD',
         bold: false
       },
       {
         value: '',
         key: 'ATD',
         label: 'ATD',
         bold: false
       },
       {
         value: '',
         key: 'ETA',
         label: 'ETA',
         bold: false
       },
       {
         value: '',
         key: 'ATA',
         label: 'ATA',
         bold: false
       }]
   },
   {
     label: 'General',
     data: [
       {
         value: '',
         key: 'Proyecto',
         label: 'Proyecto',
         bold: false,
         info: true,
         tooltip: 'Hola mundo'
       },
       {
         value: '',
         key: 'RefCliente',
         label: 'Referencia del cliente',
         primary: false,
         otro: false
       },
       {
         value: '',
         key: 'AgenteAduanal',
         label: 'Agente Aduanal',
         primary: false,
         otro: false
       },
       {
         value: '',
         key: 'Carrier',
         label: 'Carrier',
         primary: false,
         otro: false
       },
       {
         value: '',
         key: 'CoLoader',
         label: 'CO-Loader',
         primary: false,
         otro: true,
       },
       {
         value: '',
         key: 'NoMotonaveVuelo',
         label: 'No. Motonave/ Vuelo',
         primary: false,
         otro: true,
       },
       {
         value: '',
         key: 'Incoterm',
         label: 'Incoterms',
         primary: false,
         otro: true,
       },
       {
         value: '',
         key: 'HBL_HAWB',
         label: 'HBL/ HAWB',
         primary: false,
         otro: true,
       },
       {
         value: '',
         key: 'MBL_MAWB',
         label: 'MBL/ AWB',
         primary: false,
         otro: true,
       },

     ]
   },
   {
     label: 'Detalles de Carga',
     data: [
       {
         value: '',
         key: 'peso',
         label: 'Peso Total',
         primary: false,
         otro: false
       },
       {
         value: '',
         key: 'Volumen',
         label: 'Volumen Total',
         primary: false,
         otro: false
       },
       {
         value: '',
         key: 'Piezas',
         label: 'Piezas Totales',
         primary: false,
         otro: false
       },
       {
         value: '',
         key: 'Dimensiones',
         label: 'Dimensiones',
         primary: false,
         otro: true,
       },
       {
         value: '',
         key: 'DGR',
         label: 'DGR',
         primary: false,
         otro: true,
       },
       {
         value: '',
         key: 'IMO',
         label: 'IMO',
         primary: false,
         otro: true,
       }]
   }
 ]
}

export default mapJson
