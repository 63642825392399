import React from "react";
import Fade from "react-reveal/Fade";
import { TextArea } from "semantic-ui-react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Autocomplete, TextField } from "@mui/material";
import { Switch } from "antd";
import { es, enUS } from "date-fns/locale";
import { format, parse } from "date-fns";
import { Link } from "react-router-dom";

export const Label = ({ value, required, error }) => (
  <label
    className="label-euro bold"
    style={{ color: required && error ? "red" : "#2b3b57" }}
  >
    {value} {required && <RequiredSpan />}
  </label>
);

export const Select = ({
  field,
  error,
  value,
  options,
  handleChange,
  disableClearable = false,
}) => (
  <>
    <Fade left opposite>
      <span style={field.style}>
        <Label value={field.label} required={field.required} error={error} />
      </span>
    </Fade>
    <Autocomplete
      closeText={false}
      openText={false}
      disableClearable={disableClearable}
      className="input-euro"
      id={field.id}
      style={{ marginTop: "3px" }}
      size="small"
      ListboxProps={{
        sx: { fontSize: 12 },
      }}
      sx={{
        "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
          fontSize: 12,
        },
      }}
      options={options}
      filterOptions={(options, event) => {
        let filteredOptions = options.filter((opt) => {
          return (
            event.inputValue === "" ||
            opt.label.toLowerCase().includes(event.inputValue.toLowerCase())
          );
        });
        return filteredOptions;
      }}
      onChange={(ev, value) => {
        let valueText = ""
        if (value && value.value) {
          valueText = value.value;
        }
        handleChange(field.id, valueText || "");
      }}
      value={value}
      renderInput={(params) => <TextField {...params} variant="standard" />}
    />
    <Fade left opposite>
      <label style={field.style}>{field.error}</label>
    </Fade>
  </>
);

export const RequiredSpan = () => <span style={{ color: "#ff0000" }}>*</span>;

export const Input = ({
  field,
  values,
  handleChange,
  handleCheckbox,
  handleOpen,
  findCatalog,
  findInCatalog,
  lng,
  isOpen,
}) => {
  const error = !Boolean(values[field.id]) && field.required;
  if (
    field.type === "Label" ||
    field.type === "LabelRef" ||
    field.type === "Redirect"
  ) {
    let url = "";
    if (field.type === "Redirect" && field.link && values[field.linkId]) {
      url = field.link;
      if (field.linkId) {
        url = url.replace("{id}", values[field.linkId]);
      }
    }
    return (
      <>
        <Fade left opposite>
          <span className="label-euro bold" style={field.style}>
            <label>
              {field.label} {field.required && <RequiredSpan />}
            </label>
          </span>
        </Fade>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            {field.type === "Redirect" ? (
              <Link className="link-euro" to={url}>
                {values[field.id]}
              </Link>
            ) : values[field.id] && values[field.id] !== "" ? (
              values[field.id]
            ) : (
              "-"
            )}
          </span>
        </div>
      </>
    );
  } else if (field.type === "Input") {
    return (
      <>
        <Fade left opposite>
          <span style={field.style}>
            <Label
              value={field.label}
              required={field.required}
              error={error}
            />
          </span>
        </Fade>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            // label={field.placeHolder}
            color={error ? "error" : "primary"}
            error={error}
            variant="standard"
            type={field.isNumber ? "number" : "string"}
            name={field.id}
            value={values[field.id] || ""}
            maxLength={field.maxLength}
            minLength={field.minLength}
            InputProps={{
              classes: {
                input: "input-euro",
              },
            }}
            onKeyPress={(event) => {
              const inputMode = field.isNumber ? "numeric" : "text";
              if (inputMode === "numeric" && !/[0-9.]/.test(event.key)) {
                event.preventDefault(); // Evita caracteres no numéricos en campos de tipo número
              }
            }}
            onChange={(event) => {
              let inputValue = event.target.value;
              const maxLength = field.maxLength; // Obtener la propiedad maxLength de field
              if (field.isNumber) {
                if (inputValue === "-1") inputValue = "0";
                inputValue = inputValue.replace(/[-e]/gi, "");
              }
              if (maxLength && inputValue.length > maxLength) {
                // Si existe maxLength y la longitud del valor supera el límite
                // Recortar el valor para que tenga la longitud máxima permitida
                const trimmedValue = inputValue.slice(0, maxLength);
                handleChange(field.id, trimmedValue);
              } else {
                // Si no se supera el límite, simplemente llama a handleChange con el valor actual
                handleChange(field.id, inputValue);
              }
            }}
            max={field.max}
            key={field.id}
          />
        </div>
      </>
    );
  } else if (field.type === "TextArea") {
    return (
      <>
        <Fade left opposite>
          <span id={field.id} style={field.style}>
            <Label
              value={field.label}
              required={field.required}
              error={error}
            />
          </span>
        </Fade>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextArea
            className="input-euro"
            required={field.required}
            onFocus={(e) => {
              field.target.setAttribute("autocomplete", "none");
            }}
            type={field.datatype}
            placeholder={field.placeHolder}
            name={field.id}
            key={field.id}
            value={values[field.id]}
            style={{ width: "100%" }}
            onChange={(event) => handleChange(field.id, event.target.value)}
          />
        </div>
      </>
    );
  } else if (field.type === "Checkbox") {
    return (
      <>
        <Fade left opposite>
          {" "}
          <span id={field.id} style={field.style} className="columnSwitch">
            <label>
              {field.label} {field.required && <RequiredSpan />}
            </label>
            <Switch
              className="form-input-switch widthAuto"
              checked={Number(values[field.id] || 0)}
              onChange={() => handleCheckbox(field.id)}
            />
          </span>
        </Fade>
      </>
    );
  } else if (field.type === "Select") {
    return (
      <Select
        field={field}
        error={error}
        options={findCatalog(field.catalogId)}
        value={findInCatalog(values, field.id, field.catalogId)}
        handleChange={handleChange}
        values={values}
      />
    );
  } else if (field.type === "Date") {
    let valueDate = values[field.id] || null;
    if (valueDate && !(valueDate instanceof Date)) {
      valueDate = format(
        parse(valueDate, "dd-MM-yyyy", new Date()),
        "MM-dd-yyyy"
      );
    }
    return (
      <>
        <Fade left opposite>
          <span style={field.style}>
            <Label
              value={field.label}
              required={field.required}
              error={error}
            />
          </span>
        </Fade>
        <LocalizationProvider
          adapterLocale={lng === "US" ? enUS : es}
          dateAdapter={AdapterDateFns}
          formats={{ dayOfMonth: "dd", month: "MM", year: "yyyy" }}
        >
          <DatePicker
            className="input-euro date-picker-po-items"
            placeholder={field.label}
            OpenPickerButtonProps={{ style: { display: "none" } }}
            open={Boolean(isOpen[field.id])}
            onOpen={() => handleOpen(field.id, true)}
            onClose={() => handleOpen(field.id, false)}
            id={field.id}
            options={[]}
            renderInput={(params) => (
              <TextField
                InputProps={{
                  classes: {
                    input: "input-euro",
                  },
                }}
                onClick={(e) => handleOpen(field.id, true)}
                variant="standard"
                {...params}
              />
            )}
            // value={valueDate}
            onChange={(value) => {
              // let date = moment(value)
              // console.log('date????', date, value)
              // if (!date.isValid()) {
              //   handleChange(field.id, '');
              //   return;
              // }
              handleChange(field.id, value);
            }}
          />
        </LocalizationProvider>
        <Fade left opposite>
          <label style={field.style}>{field.error}</label>
        </Fade>
      </>
    );
  }
  return null;
};
