import { 
  SET_NEW_ORDERS,
  SET_NEW_MESSAGES,
  SET_CUSTOMER_COMMENTS,
  SET_INTERGLOBO_COMMENTS,
  SET_ORDERS_AND_ATTENTION
} from '../actions/types';

/**
 * JSON parser
 */
function getAttention(){
  if (localStorage.getItem('_attention_and_new_')){
    let item = JSON.parse(localStorage.getItem('_attention_and_new_'));
    return {
      new_orders: item.new_orders,
      attention: item.requires_attention
    };
  } else {
    return {attention: 0, new_orders: 0};
  }
}
/**
 * Initial State
 */
const initialState = {
  new_orders: localStorage.getItem('_total_attention_') ? localStorage.getItem('_total_attention_'): 0 ,
  new_comments: localStorage.getItem('_new_messages_') ? localStorage.getItem('_new_messages_'): 0,
  new_customer_comments: localStorage.getItem('_customer_comments_') ? localStorage.getItem('_customer_comments_'): 0,
  new_interglobo_comments: localStorage.getItem('_interglobo_comments_') ? localStorage.getItem('_interglobo_comments_'): 0,
  attention_and_new: getAttention(),
};

/**
 * Reducer Function
 */
export default (state = initialState, action) => {
  switch (action.type) {
  case SET_NEW_ORDERS:
    return {
      ...state,
      new_orders: action.payload
    };

  case SET_NEW_MESSAGES:
    return {
      ...state,
      new_comments: action.payload
    };
  
  case SET_INTERGLOBO_COMMENTS:
    return {
      ...state,
      new_interglobo_comments: action.payload
    };    

  case SET_CUSTOMER_COMMENTS:
    return {
      ...state,
      new_customer_comments: action.payload
    }; 
  case SET_ORDERS_AND_ATTENTION:

    return {
      ...state,
      attention_and_new:
      {
        new_orders: action.payload.new_orders,
        attention: action.payload.requires_attention
      }};


  default:
    return state;
  }
};