import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Search } from "semantic-ui-react";
import { Grid } from "@mui/material";
import { Switch } from "antd";

import Loader from "../../Global/Loader";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import DetailModal from "./components/detailModal";
import { useEntities } from "./hooks";
import Swal from "sweetalert2";
import "./styles.scss";
import Table from "./components/table";

const AssociationOfEntities = ({ lang, t, _rolId: rolId }) => {
  const {
    filterEntities,
    loading,
    handleFilterEntities,
    getEntities,
  } = useEntities();
  const [entity, setEntity] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [associated, setAssociated] = useState(false);

  const handleSearchChange = (_e, { value }) => {
    setSearch(value);
    handleFilterEntities(value, associated);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleClickRow = (value) => {
    setEntity(value);
    toggleModal();
  };

  const handleAssociatedChange = () => {
    setAssociated(!associated);
    handleFilterEntities(search, !associated);
  };

  const handleAssociate = async (associate) => {
    setOpenModal(false);
    Swal.fire({
      icon: associate ? "success" : "error",
      confirmButtonColor: "#2b3b57",
      confirmButtonText: "Ok",
      html: `<div class="text-center">
        The entity has been associated successfully
      </div>`,
    });
    if (associate) {
      await getEntities(search, associated);
    }
  };

  const columns = [
    {
      label: "",
      key: "association",
      dataIndex: "association",
      width: 50,
      render: (_text, record) => {
        let dotClass = "bg-red";
        if (record && record.EntityRelationshipId) {
          dotClass = "bg-green";
        }
        return (
        <div className="text-center">
          <span
            className={`dot ${dotClass}`}
          />
        </div>
      )},
    },
    {
      title: "Company",
      key: "CustomerEP", //
      dataIndex: "CustomerEP",
    },
    {
      title: "Client entity name",
      key: "CustomerEntityName",
      dataIndex: "CustomerEntityName",
      width: 180,
    },
    {
      title: "Entity type",
      key: "RecordType",
      dataIndex: "RecordType",
      width: 130,
    },
    {
      title: "EP entity name",
      key: "CustomerEPName",
      dataIndex: "CustomerEPName",
    },
    {
      title: "EP entity address",
      key: "CustomerEPAddress",
      dataIndex: "CustomerEPAddress",
    },
  ];

  if (loading) return <Loader />;
  return (
    <ContentWrapper>
      <div className="content-heading">
        <legend className="legendEuro">
          <em
            className="icon-icono_entidades"
            style={{
              color: "#2b3b57",
              fontSize: "25px",
            }} />
          Entity association
        </legend>
      </div>
      <Card>
        <CardBody>
          <Grid container gap="1rem" alignItems="center" rowGap={'1rem'}>
            <Grid item xs={4}>
              <Search
                className="marginLeftMinus"
                input={{ icon: "search", iconPosition: "left", fluid: true }}
                placeholder="Search"
                value={search}
                open={false}
                name="search"
                onSearchChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="row" style={{ gap: "1rem" }}>
                <Switch
                  className="form-input-switch"
                  checked={associated}
                  onChange={handleAssociatedChange}
                />
                <label className="label-euro bold">
                  No associated
                </label>
              </div>
            </Grid>
          </Grid>
          <Table
            columns={columns}
            data={filterEntities}
            loading={loading}
            handleClickRow={handleClickRow}
            handleSearch={handleFilterEntities}
            showSearch={false}
            placeholderSearch="Search"
            pageSize={10}
          />
        </CardBody>
      </Card>
      {entity && (
        <DetailModal
          open={openModal}
          toggle={toggleModal}
          entity={entity}
          handleAssociate={handleAssociate}
          t={t}
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  lang: state.header.lang,
});
const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssociationOfEntities));
