import React, { Component } from 'react';
import restService from '../../../../../../services/restService';
import FormBuildData from './models'
import Fade from 'react-reveal/Fade';
import { Input } from 'reactstrap';
import { Button } from 'reactstrap';
import Datetime from 'react-datetime';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const rest = new restService();

class NewShipment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            BookingSelected: {},
            FormBuildData: FormBuildData,
            Shipment : {
                ActualTimeArrival: "",
                ActualTimeDeparture: "",
                BookingNumber: "",
                HBL_HAWB: "",
                MBL_AWB: "",
                ShipmentNumber: "",
                Voyage_FlightNumber: "",
            },
            Inputs : ''
        };
    }

    componentDidMount = () => {
        this.setState({
            BookingSelected: this.props.Booking
        },()=>{
            this.buildMainData()
        })
    }

    buildMainData = () =>{
        let {BookingSelected,Shipment} = this.state
        Shipment.BookingId = BookingSelected.BookingId
        Shipment.ShipmentNumber = 'S-'+BookingSelected.BookingNumber
        Shipment.ActualTimeArrival = BookingSelected.ETA
        Shipment.ActualTimeDeparture = BookingSelected.ETD
        Shipment.Voyage_FlightNumber = BookingSelected.Voyage

        this.setState({
            Shipment
        },()=>{
            this.buildForm()
        })

    }

    handleChange = name => event => {
        let {Shipment} = this.state
        Shipment[name] = event.target.value
        this.setState({ Shipment }, () => {
            this.buildForm()
        })
    }
    
    handleChangeDate = name => event => {
        let {Shipment} = this.state
        try {
            Shipment[name] = event.format("YYYY-MM-DD")
            this.setState({ Shipment }, () => {
                this.buildForm()
            })
        }
        catch (ex) {
            Shipment[name] = ""
            this.setState({ Shipment })
        }
    }

    buildForm = () => {
        const { FormBuildData, Shipment } = this.state
        let Inputs = FormBuildData.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite> <span id={e.label} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ""}</label></span></Fade>
                    <Input
                        id={e.id}
                        required
                        type={e.datatype}
                        placeholder={e.placeHolder}
                        name={e.id}
                        value={Shipment[e.id]}
                        maxlength={e.maxlength}
                        minlength={e.minlength}
                        onChange={this.handleChange(e.id)}
                        max={e.max} />
                    <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
                </div>
            )
            else if (e.type === "Datetime") return (
                <div className={e.class} key={e.id} >
                    <Fade left opposite>
                        <span id={e.label} style={e.style}> <label  >{e.label} {e.required === true ? <span>*</span> : ""} </label></span></Fade>
                    <Datetime
                        required
                        type={e.datatype}
                        dateFormat={'YYYY MM DD'}
                        inputProps={{ className: 'form-control', id: e.id }}
                        value={Shipment[e.id]}
                        onChange={this.handleChangeDate(e.id)} />
                        <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
                </div>
            )
            else return ''
        })
        this.setState({
            Inputs
        })
    }
    

    CreateShipment = value=> evt =>{
        evt.preventDefault()
        let {Shipment} = this.state
        rest.EXEC({
            _function : 'CreateShipment',
            params : '',
            data : Shipment
        },fetch).then(success=>{
            this.props.callback(success)
        }).catch(error=>{
            this.props.onError(error)
        })

    }
    render() {
        return (
            <>
                <div className ="row">
                    {this.state.Inputs}
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-8 col-sm-12"></div>
                    <div className="col-md-3 col-lg-1 col-sm-6">
                        <Button variant="contained" color="primary" className="button" onClick={this.CreateShipment()} size="lg">
                            Save and complete
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}
export default NewShipment;