import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Map, Marker, GoogleApiWrapper, InfoWindow, Polyline } from 'google-maps-react';
// import { Typography } from '@material-ui/core'
import { Button, Progress, Card, CardBody } from 'reactstrap'
import createHistory from 'history/createBrowserHistory';
import { withRouter} from 'react-router-dom';

import cruise from '../../../../../../resources/icons/cruise.png';
import { selectedPlace } from '../../models';

import './styles.scss';

if (!window.AppHistory) {
    window.AppHistory = createHistory();
}

const status_ = {
    1: <em className="fa-2x fas fa-ship mr-2"></em>,
    2: <em className="fa-2x fas fa-ship mr-2"></em>,
    3: <em className="fa-2x fas fa-plane mr-2"></em>,
    4: <em className="fa-2x fas fa-plane mr-2"></em>,
    5: <em className="fa-2x fas fa-plane mr-2"></em>,
    6: <em className="fa-2x fas fa-plane mr-2"></em>,
    7: <em className="fa-2x fas fa-plane mr-2"></em>,
}

const icons_ = {
    1: 'https://fsposeidon.blob.core.windows.net/files/cruise-poseidon.png',
    2: 'https://fsposeidon.blob.core.windows.net/files/cruise-poseidon.png',
    3: 'https://fsposeidon.blob.core.windows.net/files/airplane (1).png',
    4: 'https://fsposeidon.blob.core.windows.net/files/airplane (1).png',
    5: 'https://fsposeidon.blob.core.windows.net/files/airplane (1).png',
    6: 'https://fsposeidon.blob.core.windows.net/files/airplane (1).png',
    7: 'https://fsposeidon.blob.core.windows.net/files/airplane (1).png',
}

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: selectedPlace,
            tags: '',
            myProps: this.props,
            data: [],
            toggleDrawer: this.props.toggleDrawer,
            polyline: this.props.polyline ? this.props.polyline: [],
            openInfoWindow: false
        };
    }

    
    componentDidMount = () => {
        this.setState({
            data : this.props.data
        },()=>{
            this.buildTags();
        })   
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            data : props.data
        },()=>{
            this.setState({polyline: props.polyline}, ()=> this.buildTags());
        })
    }
    

    onMarkerClick = (props, marker, e) => {
      let {openInfoWindow} = this.state;
        if (openInfoWindow){
          this.setState({
            selectedPlace: props.data,
            activeMarker: marker,
            showingInfoWindow: true,
            polyline: [
              {lat: parseFloat(props.data.From.Location.Latitude), lng: parseFloat(props.data.From.Location.Longitude)},
              {lat: parseFloat(props.data.To.Location.Latitude), lng: parseFloat(props.data.To.Location.Longitude)}
            ]
          });
        } else
          this.state.toggleDrawer(props.data);
    }


    buildTags = () => {
        let { data } = this.state
        let tags = []
        let key = 0;
        data.map((e) => {
            if(e.From && e.From.Location && e.From.Location.Longitude && e.To && e.To.Location && e.To.Location.Longitude){
                if (e.From) {
                    key++
                    tags.push(<Marker
                        key={`${key}from`}
                        onClick={this.onMarkerClick}
                        title={'FROM'}
                        name={e.From.name}
                        data={e}
                        position={{ lat: e.From.Location.Latitude, lng: e.From.Location.Longitude }}
                        icon={{
                            url: icons_[e.TransportMode.id],
                            anchor: new window.google.maps.Point(32, 32),
                            scaledSize: new window.google.maps.Size(32, 32)
                        }}
                    />)
                }
                if (e.To) {
                    key++
                    tags.push(<Marker
                        key={`${key}to`}
                        onClick={this.onMarkerClick}
                        title={'TO'}
                        name={e.To.name}
                        data={e}
                        position={{ lat: e.To.Location.Latitude, lng: e.To.Location.Longitude }}
                        icon={{
                            url: icons_[e.TransportMode.id],
                            anchor: new window.google.maps.Point(32, 32),
                            scaledSize: new window.google.maps.Size(32, 32)
                        }}
                    />)
                }
            }
            return e;
        });
        this.setState({tags});
    }
    
    redirectFromBooking(id){
      this.props.history.push('/tracking/'+id);
    }

    onInfoWindowOpen(props, e) {
      let { selectedPlace } = this.state
      const button = (
        <Card>
        <CardBody>
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-hover mb-mails">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <div className="mb-mail-meta">
                                                    {status_[selectedPlace.TransportMode ? selectedPlace.TransportMode.id : 1]}
                                                    <span> {selectedPlace.Status ? selectedPlace.Status.label : ''} </span>
                                                    <br/>
                                                    {/* <Link to={'/tracking/' + selectedPlace.id} style={{ textDecoration: 'none' }}> */}
                                                    <span className='link_to' style={{ fontWeight: 'bold', fontSize: '10px' }} onClick={ () => this.redirectFromBooking(selectedPlace.id)}>
                                                      {selectedPlace.BookingNumber}
                                                    </span>
                                                    {/* </Link> */}
                                                    -
                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}> {selectedPlace.id} </span>
                                                    <br />
                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{selectedPlace.PONumber}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                          <div className="d-flex">
                                              <em style={{ color: '#0097dc', paddingTop: '5%' }} className="fas fa-warehouse mr-3"></em>
                                              <div className="mb-mail-meta" >
                                                  <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                      Cargo Ready: {selectedPlace.CargoReady}
                                                  </span>
                                              </div>
                                          </div>
                                        </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="d-flex">
                                            <em style={{ color: '#0097dc', paddingTop: '8%' }} className="fas fa-truck-moving mr-3"></em>
                                            <div className="mb-mail-meta">
                                                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                    {selectedPlace.Supplier ? selectedPlace.Supplier.name : ''}
                                                </span>
                                                <br />
                                                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                    {selectedPlace.Supplier ? selectedPlace.Supplier.address : ''}
                                                </span>
                                                <br />
                                                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                    {selectedPlace.Supplier ? selectedPlace.Supplier.pickupDate : ''}
                                                </span>
                                            </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="d-flex">
                                            <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-down"></em>
                                            <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-down mr-3"></em>
                                            <div className="mb-mail-meta">
                                                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                    {`ETD: ${selectedPlace.ETD}`}
                                                </span>
                                                <br />
                                                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                    {`ATD: ${selectedPlace.ATD}`}
                                                </span>
                                            </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="d-flex">
                                          <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-up"></em>
                                          <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-up mr-3"></em>
                                          <div className="mb-mail-meta">
                                              <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                  {`ETA: ${selectedPlace.ETA}`}
                                              </span>
                                              <br></br>
                                              <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                  {`ATA: ${selectedPlace.ATA}`}
                                              </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-truck mr-2"></em>
                                                <div className="mb-mail-meta">

                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.Delivery ? selectedPlace.Delivery.name : ''}
                                                    </span>
                                                    <br></br>

                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.Delivery ? selectedPlace.Delivery.address : ''}
                                                    </span>
                                                    <br></br>

                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.Delivery ? selectedPlace.Delivery.pickupDate : ''}
                                                    </span>
                                                    <br></br>

                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        Actual Delivery:
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                  </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-md-12">
                            <table className="table table-hover mb-mails">
                                <tbody>
                                    <tr>
                                        <td >
                                            <div className="d-flex">
                                                <div className="mb-mail-meta">
                                                        {<span style={{ color: 'green' }}>On Time</span>}
                                                    <div className="mb-mail-subject">
                                                        <Progress color={'success'} value={selectedPlace.Progress} />
                                                    </div>

                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {/* {`Last Update: ${selectedPlace.LastUpdate ? selectedPlace.LastUpdate.date : ''}`} */}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <div className="mb-mail-meta">
                                                    <em className="fas fa-map-marker-alt mr-2"></em>
                                                </div>
                                                <div className="mb-mail-meta">
                                                    <div className="mb-mail-from">
                                                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>From</span>
                                                    </div>
                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.From && selectedPlace.From.Location && selectedPlace.From.Location.country ? selectedPlace.From.Location.country : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <div className="mb-mail-meta">
                                                    <em className="fas fa-map-marker-alt mr-2"></em>
                                                </div>
                                                <div className="mb-mail-meta">
                                                    <div className="mb-mail-from">
                                                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                        To
                                                        </span>
                                                    </div>
                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.To && selectedPlace.To.Location && selectedPlace.To.Location.country ? selectedPlace.To.Location.country : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <div className="mb-mail-meta">
                                                    <em className="icon-compass mr-2" style={{paddingTop: '10%' }}></em>
                                                </div>
                                                <div className="mb-mail-meta">
                                                <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.Carrier ? selectedPlace.Carrier.VesselOrFlight : ''}
                                                        </span>
                                                    <br></br>
                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.Carrier ? selectedPlace.Carrier.Latitude : ''}
                                                        </span>
                                                    <br></br>
                                                    <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                        {selectedPlace.Carrier ? selectedPlace.Carrier.Longitude : ''}
                                                        </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <div className="mb-mail-meta">
                                                    <img src={cruise} width="19" height="19" className="mr-2" ></img>
                                                </div>
                                                <div className="mb-mail-meta" style={{ paddingTop: '7%' }}>
                                                    <div className="mb-mail-from">
                                                        <span>{selectedPlace.Project ? selectedPlace.Project.name : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
              </div>


        </CardBody>
        <center>
            {/* <Button color="primary" className="btn-oval" onClick={this.detail(dataDrawer.id)}>View Details</Button> */}
            <Button color="primary" className="btn-oval" onClick={this.detail(selectedPlace.id)}>View Details</Button>
        </center>
    </Card>        
      )
      // const button = (
      //     <Container>
      //         <Row>
      //             <div className="col-6">
      //                 <div className="row">
      //                     <div className="col-md-12">
      //                         <table className="table table-hover mb-mails">
      //                             <tbody>
      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             {status_[selectedPlace.TransportMode.id]}
      //                                             <div className="mb-mail-meta" style={{ paddingTop: '4%' }}>
      //                                                 <Typography variant="h6" variant="subtitle2" gutterBottom >
      //                                                     {selectedPlace.Status.label}
      //                                                 </Typography>
      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>
      //                                 <tr>

      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <div className="mb-mail-meta">
      //                                             <Typography variant="subtitle1" gutterBottom >
      //                                                     {selectedPlace.BookingNumber}
      //                                                 </Typography>
      //                                                 <Typography variant="subtitle1" gutterBottom >
      //                                                     {selectedPlace.id}
      //                                                 </Typography>
                                                     
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.PONumber}
      //                                                 </Typography>
      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-warehouse mr-2"></em>
      //                                             <div className="mb-mail-meta" >
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     Cargo Ready: {selectedPlace.CargoReady}
      //                                                 </Typography>
      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-truck-moving mr-2"></em>
      //                                             <div className="mb-mail-meta">
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Shipper.name}
      //                                                 </Typography>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Shipper.address}
      //                                                 </Typography>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Shipper.pickupDate}
      //                                                 </Typography>

      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>
      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-long-arrow-alt-down"></em>
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-long-arrow-alt-down mr-3"></em>
      //                                             <div className="mb-mail-meta">

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {`ETD: ${selectedPlace.ETD}`}
      //                                                 </Typography>

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {`ATD: ${selectedPlace.ATD}`}
      //                                                 </Typography>

      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>

      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-long-arrow-alt-up"></em>
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-long-arrow-alt-up mr-3"></em>
      //                                             <div className="mb-mail-meta">
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {`ETA: ${selectedPlace.ETA}`}
      //                                                 </Typography>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {`ATA: ${selectedPlace.ATA}`}
      //                                                 </Typography>

      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>

      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <em style={{ color: '#0097dc' }} className="fas fa-truck mr-2"></em>
      //                                             <div className="mb-mail-meta">

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Delivery.name}
      //                                                 </Typography>

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Delivery.address}
      //                                                 </Typography>

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Delivery.pickupDate}
      //                                                 </Typography>

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     Actual Delivery:
      //                                             </Typography>
      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>
      //                             </tbody>
      //                         </table>
      //                     </div>
      //                 </div>
      //             </div>
      //             <div className="col-6">
      //                 <div className="row">
      //                     <div className="col-md-12">
      //                         <table className="table table-hover mb-mails">
      //                             <tbody>
      //                                 <tr>
      //                                     <td >
      //                                         <div className="d-flex">
      //                                             <div className="mb-mail-meta" style={{ width: '150px' }}>
      //                                                 <Typography variant="h6" variant="subtitle2" gutterBottom>
      //                                                     {selectedPlace.LastUpdate.porcent < 50 ? (selectedPlace.LastUpdate.exception ?  <span style={{ color: 'green' }}>On Time</span> :  <span style={{ color: 'red' }}>Delayed</span>) : <span style={{ color: 'green' }}>On Time</span>}
      //                                                 </Typography>
      //                                                 <div className="mb-mail-subject">
      //                                                     <Progress color={selectedPlace.LastUpdate.porcent < 50 ? (selectedPlace.LastUpdate.exception ? 'success' :  'danger') : 'success'} value={selectedPlace.LastUpdate.porcent} />
      //                                                 </div>

      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {`Last Update:${selectedPlace.LastUpdate.date}`}
      //                                                 </Typography>
      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>

      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <div className="mb-mail-meta">
      //                                                 <em className="fas fa-map-marker-alt mr-2"></em>
      //                                             </div>
      //                                             <div className="mb-mail-meta">
      //                                                 <div className="mb-mail-from">From</div>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.From.Location.country}
      //                                                 </Typography>
      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>


      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <div className="mb-mail-meta">
      //                                                 <em className="fas fa-map-marker-alt mr-2"></em>
      //                                             </div>
      //                                             <div className="mb-mail-meta">
      //                                                 <div className="mb-mail-from">To</div>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.To.Location.country}
      //                                                 </Typography>


      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>

      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <div className="mb-mail-meta">
      //                                                 <em className="icon-compass mr-2"></em>
      //                                             </div>
      //                                             <div className="mb-mail-meta">
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Carrier.VesselOrFlight}
      //                                                 </Typography>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Carrier.Latitude}
      //                                                 </Typography>
      //                                                 <Typography variant="caption" gutterBottom >
      //                                                     {selectedPlace.Carrier.Longitude}
      //                                                 </Typography>

      //                                             </div>
      //                                         </div>
      //                                     </td>
      //                                 </tr>

      //                                 <tr>
      //                                     <td>
      //                                         <div className="d-flex">
      //                                             <div className="mb-mail-meta">
      //                                                 <img src={cruise} width="19" height="19" className="mr-2"></img>
      //                                             </div>
      //                                             <div className="mb-mail-meta">
      //                                                 <div className="mb-mail-from">{selectedPlace.Cruise.CruiseName}</div>
      //                                             </div> 
      //                                         </div>
      //                                     </td>
      //                                 </tr>
      //                             </tbody>
      //                         </table>
      //                     </div>
      //                 </div>
      //             </div>
      //         </Row>
      //         <center>
      //           <Button color="primary" className="btn-oval" onClick={this.detail(selectedPlace.id)}>View Details</Button>
      //         </center>
      //     </Container>
      // );

      ReactDOM.render(
          React.Children.only(button),
          document.getElementById("iwc")
      );
  } 
  detail = id => event => {
    this.props.history.push('/tracking/'+id);
  } 


    render() {
        let { polyline } = this.state;
        if (!this.props.google) {
            return <div>Loading...</div>;
        }
        return (
            <div>
                <div
                    style={{ position: "relative", height: "500px"}}>
                    <Map 
                    //   styles={[
                    //     {elementType: 'geometry', stylers: [{color: '#B1BCBE'}]},
                    //     {elementType: 'labels.text.stroke', stylers: [{color: '#B1BCBE'}]},
                    //     {elementType: 'labels.text.fill', stylers: [{color: '#0a0000'}]},
                    //     {
                    //       featureType: 'administrative.locality',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#d59563'}]
                    //     },
                    //     {
                    //       featureType: 'poi',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#d59563'}]
                    //     },
                    //     {
                    //       featureType: 'poi.park',
                    //       elementType: 'geometry',
                    //       stylers: [{color: '#263c3f'}]
                    //     },
                    //     {
                    //       featureType: 'poi.park',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#6b9a76'}]
                    //     },
                    //     {
                    //       featureType: 'road',
                    //       elementType: 'geometry',
                    //       stylers: [{color: '#38414e'}]
                    //     },
                    //     {
                    //       featureType: 'road',
                    //       elementType: 'geometry.stroke',
                    //       stylers: [{color: '#212a37'}]
                    //     },
                    //     {
                    //       featureType: 'road',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#9ca5b3'}]
                    //     },
                    //     {
                    //       featureType: 'road.highway',
                    //       elementType: 'geometry',
                    //       stylers: [{color: '#746855'}]
                    //     },
                    //     {
                    //       featureType: 'road.highway',
                    //       elementType: 'geometry.stroke',
                    //       stylers: [{color: '#1f2835'}]
                    //     },
                    //     {
                    //       featureType: 'road.highway',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#f3d19c'}]
                    //     },
                    //     {
                    //       featureType: 'transit',
                    //       elementType: 'geometry',
                    //       stylers: [{color: '#2f3948'}]
                    //     },
                    //     {
                    //       featureType: 'transit.station',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#d59563'}]
                    //     },
                    //     {
                    //       featureType: 'water',
                    //       elementType: 'geometry',
                    //       stylers: [{color: '#42AFEE'}]
                    //     },
                    //     {
                    //       featureType: 'water',
                    //       elementType: 'labels.text.fill',
                    //       stylers: [{color: '#515c6d'}]
                    //     },
                    //     {
                    //       featureType: 'water',
                    //       elementType: 'labels.text.stroke',
                    //       stylers: [{color: '#42AFEE'}]
                    //     }
                    //   ]}
                      google={this.props.google} zoom={2} initialCenter={{
                        lat: 33.028859,
                        lng: -44.5060691
                      }}
                      onIdle={ (mapProps, map, clickEvent) => { 
                        if (window.innerHeight == window.screen.height)
                            this.setState({openInfoWindow: true});
                        else 
                            this.setState({openInfoWindow: false});
                      }}
                      >
                      <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}
                            onOpen={e => {
                                this.onInfoWindowOpen(this.props, e);
                            }}>
                            <div id="iwc" />
                      </InfoWindow>
                        {this.state.tags}
                        <Polyline
                        path={polyline}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2} />
                    </Map>
                </div>
            </div>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: "AIzaSyAl_10ZKSqnrviAV-KSQ6c8i4Sa_gEhEmA",
    v: "3.30"
})(withRouter(MapContainer));
