import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';

import Table from '../../../../../template/Table';
import RestService from '../../../../../../services/restService';
import { Services } from '../../../Services'

const Rest = new RestService();
class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {

      id: '',
      data : [],
      columns: [
        { label: 'Document Name', key: 'Name' },
        { label: 'Document Type', key: 'Type' },
        { label: 'Document Category', key: 'Category' },
        { label: 'Creation Date', key: 'CreationDate' },
        { label: 'Updated By', key: 'CreateBy' },
        { label: 'Download', key: '', custom: (value) => {
          return (
            <a className="fas fa-download fa-2x " href={value.URL}></a>
          );
        }
        },
        {
          label: 'View', key: '', custom: (value) => {
            return (
              <a className="fas fa-search fa-2x " onClick={() => window.open(`${Services.ORDER.path}order/document/view/pdf/?url=` + value.URL)}></a>
            );
          }
        },
      ],


    };
  }


  componentDidMount = () => {
    this.setState({
      id: this.props.id
    }, () => {
      this.getComments();
    });


  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      id: nextProps.id
    }, () => {
      this.getComments();
    });

  }


  getComments = () => {
    let { id } = this.state;
    Rest.EXEC({
      _function: 'GetFilesByRowId',
      params: id
    }, fetch).then(success => {
      if (success.data) {
        this.setState({
          data: success.data,
        });
      }
    });
  }

  render() {
    return (
      <Card>
        <CardBody>
          <h4 className="mb-3 py-2">&nbsp;&nbsp;Booking Documents</h4>
          <div className="row">
            <div className="col-1">
              <em className="fas fa-book-open fa-4x mb-3 py-5"></em>
            </div>
            <div className="col-11">
              <Table
                columns={this.state.columns}
                data={this.state.data}
                itemsByPage={10}
                options={{
                  downloads: {
                    pdf: false,
                    xlsx: false
                  },
                  pagination: false,
                  search: false,
                }}
              />              </div>
            <div className="col-2">
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default Document;


