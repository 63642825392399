import React from 'react';
import propTypes from 'prop-types';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { CardBody, Col, Row } from 'reactstrap';
import { Services } from '../../../Services'
import NewComment from './../NewComment/';
import Axios from 'axios';
// import {TrashIcon} from './../../config';
import DelComment from './../DeleteComment/';
import './styles.scss';
import io from 'socket.io-client';

let socket;
/*=============================================
=            Comments Component               =
=============================================*/
class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: this.props.comments,
      POId: this.props.POId ? this.props.POId : 0,
      isSearchComments: this.props.isSearchComments ? true : false
    };

  }


  componentWillReceiveProps = (newProps) => {
    this.setState({ comments: newProps.comments })
  }

  render() {
    const { comments } = this.state;
    return (
      <>
        <Col md={12} xl={12} className='comments_containers'>
          <CardBody className='body_card'>
            <Timeline lineColor={'#fff'} className='time-line'>
              {
                comments.map((e) => {
                  return (
                    <TimelineItem
                      key={e.CommentId}
                      dateText={`${e.Date}`}
                      dateInnerStyle={{ background: `${e.Color}`, fontWeight: 'bold', fontSize: '11px' }}
                    >
                      <Col className='_card_comments' md={12}>
                        <Row>
                          <div className="col-12">
                            <span className='comment_type'>{e.ComType}</span>
                            {
                              e.NewComment ?
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" className={'svg-inline--fa fa-circle fa-w-16 text-success new-comment'} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                </svg> : ''
                            }
                          </div>
                          <div className="col-12">
                            <span className='comment_content'>{e.Comment}</span>
                          </div>
                          <Col md={12} className="footer_comments">
                            <div className="user_footer">
                              {/* <TrashIcon/> */}
                              {
                                // this.props._rolId === '1' && e.UserId+'' === this.props._user ?
                                e.UserId + '' === this.props._user ?
                                  <DelComment _user={this.props._user} POId={this.state.POId} CommentId={e.CommentId} resetComments={this.getComments} />
                                  : ''
                              }
                              {e.User ? e.User : ''}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </TimelineItem>
                  );
                })
              }
            </Timeline>
          </CardBody>
        </Col>
        <Col md={12} className='new_comment_box'>
          <NewComment POId={this.state.POId} _customer={this.props._customer} _user={this.props._user} _rolId={this.props._rolId} pushComments={this.props.pushComments} onchenge={this.props.onchenge} value={this.props.value} />
        </Col>
      </>
    );
  }
}

Comments.propTypes = {
  POId: propTypes.number,
  _customer: propTypes.string,
  _user: propTypes.string,
  _rolId: propTypes.string,
  isSearchComments: propTypes.bool,
  resetTable: propTypes.func
};

Comments.defaultProps = {
  resetTable: () => { },
  _customer: ''
};


export default Comments;