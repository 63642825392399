export const TemplateOrderService = {
  titulo: "Final arrival",
  id: "resumeOrder",
  edit: [
    {
      type: "Label",
      label: "Company",
      class: "col-md-3 input-form",
      placeHolder: "company",
      name: "company",
      id: "CustomerName",
    },
    {
      type: "Label",
      label: "Creation date",
      class: "col-md-3 input-form",
      placeHolder: "creationDate",
      name: "creationDate",
      id: "CreateDate",
    },
    {
      type: "Date",
      label: "Required at destination",
      format: "DD-MM-YYYY",
      name: "destinationDate",
      class: "col-md-3 input-form",
      id: "RequiredDestinationDate",
      placeHolder: "required_at_destination",
      required: true,
    },
    {
      type: "Date",
      label: "Requested cargo ready",
      format: "DD-MM-YYYY",
      name: "destinationDate",
      class: "col-md-3 input-form",
      id: "RequiredCargoReadyDate",
      placeHolder: "Requested cargo ready",
      required: true,
    },
    {
      type: "Select",
      label: "Incoterm",
      class: "col-md-3 input-form",
      placeHolder: "Incoterm",
      name: "incoterm",
      catalogId: "incotermCatalog",
      id: "Incoterms",
    },
    {
      type: "Input",
      label: "Product type",
      class: "col-md-3 input-form",
      placeHolder: "Product type",
      name: "productType",
      id: "ProductType",
    },
    {
      type: "Input",
      label: "PO division",
      class: "col-md-3 input-form",
      placeHolder: "PO division",
      name: "poDivision",
      id: "Division",
    },
    {
      type: "Input",
      label: "Origin country",
      class: "col-md-3 input-form",
      placeHolder: "Origin country",
      name: "originCountry",
      id: "POL",
      maxLength: 20,
      required: true,
    },
    {
      type: "Select",
      label: "Transport mode",
      class: "col-md-3 input-form",
      placeHolder: "Transport mode",
      name: "transportMode",
      catalogId: "transportModeCatalog",
      id: "TransportMode",
      required: true,
    },
    {
      type: "Input",
      label: "Final destination",
      class: "col-md-3 input-form",
      placeHolder: "Final destination",
      name: "destinationCountry",
      maxLength: 20,
      id: "POD",
    },
    {
      type: "Input",
      label: "Project",
      class: "col-md-3 input-form",
      placeHolder: "Project",
      name: "project",
      id: "Project",
    },
    {
      type: "Input",
      label: "WMS reference",
      class: "col-md-3 input-form",
      placeHolder: "WMS reference",
      name: "wmsReference",
      id: "WMSReference",
    },
    {
      type: "Input",
      label: "Additional reference",
      class: "col-md-3 input-form",
      placeHolder: "Additional reference",
      name: "clientReference",
      id: "CustomerReference",
    },
    {
      type: "Input",
      label: "Buyer",
      class: "col-md-3 input-form",
      placeHolder: "Buyer",
      name: "buyer",
      id: "Buyer",
    },
    {
      type: "Checkbox",
      label: "Priority order",
      class: "col-md-3 input-form",
      placeHolder: "Priority order",
      name: "priorityPO",
      id: "PriorityPO",
    },
  ],
  previewForm: [
    {
      type: "Label",
      label: "Company",
      class: "col-md-3 input-form",
      placeHolder: "company",
      name: "company",
      id: "CustomerName",
    },
    {
      type: "Label",
      label: "Creation date",
      class: "col-md-3 input-form",
      placeHolder: "creationDate",
      name: "creationDate",
      id: "CreateDate",
    },
    {
      type: "Label",
      label: "Required at destination",
      format: "DD-MM-YYYY",
      name: "destinationDate",
      class: "col-md-3 input-form",
      id: "RequiredDestinationDate",
    },
    {
      type: "Label",
      label: "Requested cargo ready",
      name: "uploadRequest",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      id: "RequiredCargoReadyDate",
    },
    {
      type: "Label",
      label: "Incoterm",
      class: "col-md-3 input-form",
      placeHolder: "incoterm",
      name: "incoterm",
      id: "Incoterms",
    },
    {
      type: "Label",
      options: "productType",
      label: "Product type",
      name: "productType",
      class: "col-md-3 input-form",
      id: "ProductType",
    },
    {
      type: "Label",
      label: "PO division",
      class: "col-md-3 input-form",
      placeHolder: "poDivision",
      name: "poDivision",
      id: "Division",
    },
    {
      type: "Label",
      options: "originCountry",
      label: "Origin country",
      name: "originCountry",
      class: "col-md-3 input-form",
      id: "POL",
    },
    {
      type: "Label",
      label: "Transport mode",
      class: "col-md-3 input-form",
      placeHolder: "transportType",
      name: "transportType",
      id: "TransportMode",
    },
    {
      type: "Label",
      label: "Final destination",
      class: "col-md-3 input-form",
      placeHolder: "destinationCountry",
      name: "destinationCountry",
      id: "POD",
    },
    {
      type: "Label",
      label: "Project",
      class: "col-md-3 input-form",
      placeHolder: "project",
      name: "project",
      id: "Project",
    },
    {
      type: "Label",
      label: "WMS reference",
      name: "wmsReference",
      class: "col-md-3 input-form",
      id: "WMSReference",
    },
    {
      type: "Label",
      label: "Additional reference",
      name: "clientReference",
      class: "col-md-3 input-form",
      id: "CustomerReference",
    },
    {
      type: "Label",
      label: "Buyer",
      name: "buyer",
      class: "col-md-3 input-form",
      id: "Buyer",
    },
    {
      type: "Label",
      label: "Priority order",
      name: "priorityPO",
      class: "col-md-3 input-form",
      id: "PriorityPO",
      boolean: true,
    },
  ],
};

export const TemplatePODetail = {
  titulo: "Purchase order detail",
  id: "detailOrder",
  edit: [
    {
      type: "Label",
      label: "Total QTY ordered",
      class: "col-md-3 input-form",
      placeHolder: "Total QTY ordered",
      name: "qtyOrdered",
      id: "TotalQTYOrdered",
      isNumber: true,
    },
    {
      type: "Label",
      label: "Total QTY confirmed",
      class: "col-md-3 input-form",
      placeHolder: "Total QTY confirmed",
      name: "qtyConfirmed",
      id: "TotalQTYConfirmed",
      isNumber: true,
    },
    {
      type: "Select",
      label: "Total QTY UOM",
      class: "col-md-3 input-form",
      placeHolder: "Total QTY UOM",
      name: "totalQtyUOM",
      catalogId: "uomCatalogQ",
      id: "TotalQTYUOM",
      required: true,
      catalogFilter: {
        key: 'Type',
        value: 'Q',
      },
    },
    {
      type: "Input",
      label: "Total PO value",
      class: "col-md-3 input-form",
      placeHolder: "Total PO value",
      name: "totalPO",
      id: "OrderValue",
      isNumber: true,
    },
    {
      type: "Input",
      label: "Currency",
      class: "col-md-3 input-form",
      placeHolder: "Currency",
      name: "currency",
      id: "OrderCurrency",
    },
    {
      type: "Label",
      label: "Total weight",
      class: "col-md-3 input-form",
      placeHolder: "Total weight",
      name: "totalWeight",
      id: "GrossWeight",
      // required: true,
      isNumber: true,
    },
    {
      type: "Select",
      label: "Total weight UOM",
      class: "col-md-3 input-form",
      placeHolder: "Total weight UOM",
      name: "totalWeightUOM",
      catalogId: "uomCatalogW",
      id: "GrossWeightUOM",
    },
    {
      type: "Label",
      label: "Net total weight",
      class: "col-md-3 input-form",
      placeHolder: "Net total weight",
      name: "netTotalWeight",
      id: "NetWeight",
      isNumber: true,
    },
    {
      type: "Select",
      label: "Net total weight UOM",
      class: "col-md-3 input-form",
      placeHolder: "Net total weight UOM",
      name: "netTotalWeightUOM",
      catalogId: "uomCatalogW",
      id: "NetWeightUOM",
      catalogFilter: {
        key: 'Type',
        value: 'W',
      },
    },
    {
      type: "Input",
      label: "No. pallets",
      class: "col-md-3 input-form",
      placeHolder: "No. pallets",
      name: "pallets",
      id: "NoPallets",
      isNumber: true,
    },
    {
      type: "Input",
      label: "Pallets type",
      class: "col-md-3 input-form",
      placeHolder: "Pallets type",
      name: "palletsType",
      id: "PalletsType",
    },
    {
      type: "Input",
      label: "PO description",
      class: "col-md-6 input-form",
      placeHolder: "PO description",
      name: "poDescription",
      id: "Description",
    },
    {
      type: "Input",
      label: "Special PO instructions / Additional PO terms",
      class: "col-md-6 input-form",
      placeHolder: "Special PO instructions / Additional PO terms",
      name: "instructions",
      id: "SpecialInstructions",
    },
    {
      type: "Input",
      label: "Pickup address",
      class: "col-md-6 input-form",
      placeHolder: "Pickup address",
      name: "address",
      id: "PickupAddress",
    },
    {
      type: "Input",
      label: "Delivery address",
      class: "col-md-6 input-form",
      placeHolder: "Delivery address",
      name: "deliveryAddressPO",
      id: "DeliveryAddressPO",
    },
  ],
  previewForm: [
    {
      type: "Label",
      label: "Total QTY ordered",
      class: "col-md-3 input-form",
      placeHolder: "qtyOrdered",
      name: "qtyOrdered",
      id: "TotalQTYOrdered",
    },
    {
      type: "Label",
      label: "Total QTY confirmed",
      name: "qtyConfirmed",
      class: "col-md-3 input-form",
      id: "TotalQTYConfirmed",
    },
    {
      type: "Label",
      label: "Total QTY UOM",
      name: "totalQtyUOM",
      class: "col-md-3 input-form",
      id: "TotalQTYUOMDescription",
    },
    {
      type: "Label",
      label: "Total PO value",
      name: "totalPO",
      class: "col-md-3 input-form",
      id: "OrderValue",
    },
    {
      type: "Label",
      label: "Currency",
      name: "currency",
      class: "col-md-3 input-form",
      id: "OrderCurrency",
    },
    {
      type: "Label",
      label: "Total weight",
      name: "totalWeight",
      class: "col-md-3 input-form",
      id: "GrossWeight",
    },
    {
      type: "Label",
      label: "Total weight UOM",
      name: "totalWeightUOM",
      class: "col-md-3 input-form",
      id: "GrossWeightUOMDescription",
    },
    {
      type: "Label",
      label: "Net total weight",
      name: "netTotalWeight",
      class: "col-md-3 input-form",
      id: "NetWeight",
    },
    {
      type: "Label",
      label: "Net total weight UOM",
      name: "netTotalWeightUOM",
      class: "col-md-3 input-form",
      id: "NetWeightUOMDescription",
    },
    {
      type: "Label",
      label: "No. pallets",
      name: "pallets",
      class: "col-md-3 input-form",
      id: "NoPallets",
    },
    {
      type: "Label",
      label: "Pallets type",
      name: "palletsType",
      class: "col-md-3 input-form",
      id: "PalletsType",
    },
    {
      type: "Label",
      label: "PO description",
      name: "poDescription",
      class: "col-md-6 input-form",
      id: "Description",
    },
    {
      type: "Label",
      label: "Special PO instructions / Additional PO terms",
      name: "instructions",
      class: "col-md-6 input-form",
      id: "SpecialInstructions",
    },
    {
      type: "Label",
      label: "Pickup address",
      name: "address",
      class: "col-md-6 input-form",
      id: "PickupAddress",
    },
    {
      type: "Label",
      label: "Delivery address",
      name: "deliveryAddressPO",
      class: "col-md-6 input-form",
      id: "DeliveryAddressPO",
    },
  ],
};

export const ItemDetailResumeForm = {
  titulo: "",
  id: "itemDetailResumeForm",
  edit: [
    {
      type: "Label",
      label: "PO items ID",
      class: "col-md-3 input-form",
      placeHolder: "PO items ID",
      name: "POItemsId",
      id: "POItemsId",
      required: true,
    },
    {
      type: "Input",
      label: "QTY ordered",
      class: "col-md-3 input-form",
      placeHolder: "QTY ordered",
      name: "qtyOrdered",
      id: "QTYOrdered",
      isNumber: true,
      required: true,
    },
    {
      type: "Input",
      label: "QTY confirmed",
      class: "col-md-3 input-form",
      placeHolder: "QTY confirmed",
      name: "qtyConfirmed",
      isNumber: true,
      id: "QTYConfirmed",
    },
    {
      type: "Select",
      label: "UOM",
      class: "col-md-3 input-form",
      placeHolder: "UOM",
      name: "uom",
      id: "UOMCode",
      catalogId: "uomCatalogQ",
      required: true,
    },
    {
      type: "Input",
      label: "Box volume",
      class: "col-md-3 input-form",
      placeHolder: "Box volume",
      name: "boxVolume",
      id: "CaseVolume",
      isNumber: true,
    },
    {
      type: "Input",
      label: "Box description",
      class: "col-md-3 input-form",
      placeHolder: "Box description",
      name: "boxDescription",
      id: "CaseDescription",
    },
    {
      type: "Input",
      label: "Seller code",
      class: "col-md-3 input-form",
      placeHolder: "Seller code",
      name: "sellerCode",
      id: "VendorCode",
    },
    {
      type: "Input",
      label: "HS code",
      class: "col-md-3 input-form",
      placeHolder: "HS code",
      name: "hsCode",
      id: "HSCode",
    },
    {
      type: "Input", // falta info
      label: "Invoice number",
      class: "col-md-3 input-form",
      placeHolder: "Invoice number",
      name: "bill",
      id: "Invoice",
    },
    {
      type: "Checkbox",
      label: "Priority item?",
      class: "col-md-3 input-form",
      placeHolder: "Priority item?",
      name: "priorityItem",
      id: "PriorityItem",
    },
    {
      type: "Input",
      label: "Item value",
      class: "col-md-3 input-form",
      placeHolder: "Item value",
      name: "itemValue",
      id: "Value",
      isNumber: true,
    },
    {
      type: "Input",
      label: "Currency",
      class: "col-md-3 input-form",
      placeHolder: "Currency",
      name: "currency",
      id: "Currency",
    },
    {
      type: "Input",
      label: "Gross weight",
      class: "col-md-3 input-form",
      placeHolder: "Gross weight",
      name: "grossWeight",
      id: "GrossWeight",
      isNumber: true,
    },
    {
      type: "Select",
      label: "Gross weight UOM",
      class: "col-md-3 input-form",
      placeHolder: "Gross weight UOM",
      name: "grossWeightUOM",
      catalogId: "uomCatalogW",
      id: "GrossWeightUOM",
    },
    {
      type: "Input",
      label: "Net weight",
      class: "col-md-3 input-form",
      placeHolder: "Net weight",
      name: "netWeight",
      id: "NetWeight",
      isNumber: true,
    },
    {
      type: "Select",
      label: "Net weight UOM",
      class: "col-md-3 input-form",
      placeHolder: "Net weight UOM",
      name: "netWeightUOM",
      catalogId: "uomCatalogW",
      id: "NetWeightUOM",
    },
    // {
    //   type: "Input",
    //   label: "resume.itemsCollapse.resume.collection",
    //   class: "col-md-3 input-form",
    //   placeHolder: "resume.itemsCollapse.resume.collection",
    //   name: "collection",
    //   id: "Collection",
    // },
    // {
    //   type: "Input",
    //   label: "resume.itemsCollapse.resume.style",
    //   class: "col-md-3 input-form",
    //   placeHolder: "resume.itemsCollapse.resume.style",
    //   name: "style",
    //   id: "Style",
    // },
    // {
    //   type: "Input",
    //   label: "resume.itemsCollapse.resume.color",
    //   class: "col-md-3 input-form",
    //   placeHolder: "resume.itemsCollapse.resume.color",
    //   name: "color",
    //   id: "Color",
    // },
    {
      type: "Input",
      label: "Size",
      class: "col-md-3 input-form",
      placeHolder: "Size",
      name: "size",
      id: "Size",
    },
    {
      type: "Redirect",
      label: "Shipment No.",
      class: "col-md-3 input-form",
      placeHolder: "Shipment No.",
      name: "ShipmentNumber",
      id: "ShipmentNumber",
      link: "/shipmentsDetails/{id}",
      linkId: "ShipmentId",
    },
  ],
  previewForm: [
    {
      type: "Label",
      label: "PO items ID",
      class: "col-md-3 input-form",
      name: "POItemsId",
      id: "POItemsId",
    },
    {
      type: "Label",
      label: "QTY ordered",
      name: "qtyOrdered",
      class: "col-md-3 input-form",
      id: "QTYOrdered",
    },
    {
      type: "Label",
      label: "QTY confirmed",
      name: "qtyConfirmed",
      class: "col-md-3 input-form",
      id: "QTYConfirmed",
    },
    {
      type: "Label",
      label: "UOM",
      class: "col-md-3 input-form",
      name: "uom",
      id: "ItemUOMDescription",
    },
    {
      type: "Label",
      label: "Box volume",
      name: "boxVolume",
      class: "col-md-3 input-form",
      id: "CaseVolume",
    },
    {
      type: "Label",
      label: "Box description",
      class: "col-md-3 input-form",
      name: "boxDescription",
      id: "CaseDescription",
    },
    {
      type: "Label",
      label: "Seller code",
      name: "sellerCode",
      class: "col-md-3 input-form",
      id: "VendorCode",
    },
    {
      type: "Label",
      label: "HS code",
      class: "col-md-3 input-form",
      name: "hsCode",
      id: "HSCode",
    },
    {
      type: "Label",
      label: "Invoice number",
      class: "col-md-3 input-form",
      name: "bill",
      id: "Invoice", //no existe
    },
    {
      type: "Label",
      label: "Priority item?",
      class: "col-md-3 input-form",
      name: "priorityItem",
      id: "PriorityItem",
      boolean: true,
    },
    {
      type: "Label",
      label: "Item value",
      name: "itemValue",
      class: "col-md-3 input-form",
      id: "Value",
    },
    {
      type: "Label",
      label: "Currency",
      name: "currency",
      class: "col-md-3 input-form",
      id: "Currency",
    },
    {
      type: "Label",
      label: "Gross weight",
      name: "grossWeight",
      class: "col-md-3 input-form",
      id: "GrossWeight",
    },
    {
      type: "Label",
      label: "Gross weight UOM",
      name: "grossWeightUOM",
      class: "col-md-3 input-form",
      id: "ItemGrossWeightUOMDescription",
    },
    {
      type: "Label",
      label: "Net weight",
      name: "netWeight",
      class: "col-md-3 input-form",
      id: "NetWeight",
    },
    {
      type: "Label",
      label: "Net weight UOM",
      name: "netWeightUOM",
      class: "col-md-3 input-form",
      id: "ItemNetWeightUOMDescription",
    },
    // {
    //   type: "Label",
    //   label: "resume.itemsCollapse.resume.collection",
    //   name: "collection",
    //   class: "col-md-3 input-form",
    //   id: "Collection",
    // },
    // {
    //   type: "Label",
    //   label: "resume.itemsCollapse.resume.style",
    //   name: "style",
    //   class: "col-md-3 input-form",
    //   id: "Style",
    // },
    // {
    //   type: "Label",
    //   label: "resume.itemsCollapse.resume.color",
    //   name: "color",
    //   class: "col-md-3 input-form",
    //   id: "Color",
    // },
    {
      type: "Label",
      label: "Size",
      name: "size",
      class: "col-md-3 input-form",
      id: "Size",
    },
    {
      type: "Redirect",
      label: "Shipment No.",
      class: "col-md-3 input-form",
      placeHolder: "Shipment No.",
      name: "ShipmentNumber",
      id: "ShipmentNumber",
      link: "/shipmentsDetails/{id}",
      linkId: "ShipmentId",
    },
  ],
};

export const ItemDetailResumeFormDates = {
  titulo: "Factory requested",
  id: "itemDetailResumeFormDates",
  edit: [
    {
      type: "Date",
      label: "Factory requested",
      format: "DD-MM-YYYY",
      name: "factoryRequested",
      class: "col-md-3 input-form",
      placeHolder: "Factory requested",
      id: "RequestedExWorks",
    },
    {
      type: "Date",
      label: "Requested cargo ready",
      format: "DD-MM-YYYY",
      name: "factoryRequested",
      class: "col-md-3 input-form",
      placeHolder: "Requested cargo ready",
      id: "RequiredCagoReady",
    },
    {
      type: "Date",
      label: "Promise cargo ready",
      format: "DD-MM-YYYY",
      name: "promiseUploadRequest",
      class: "col-md-3 input-form",
      placeHolder: "Promise cargo ready",
      id: "PromiseCargoReady",
    },
    {
      type: "Date",
      label: "Actual cargo ready",
      format: "DD-MM-YYYY",
      name: "realUploadRequest",
      class: "col-md-3 input-form",
      placeHolder: "Actual cargo ready",
      id: "ActualCargoReady",
    },
    {
      type: "Date",
      label: "Estimated pickup",
      format: "DD-MM-YYYY",
      name: "estimatePickup",
      class: "col-md-3 input-form",
      placeHolder: "Estimated pickup",
      id: "EstimatedPickedUp",
    },
    {
      type: "Date",
      label: "Actual pickup",
      format: "DD-MM-YYYY",
      name: "RealPickup",
      class: "col-md-3 input-form",
      placeHolder: "Actual pickup",
      id: "ActualCargoPickup",
    },
    {
      type: "Date",
      label: "Actual arrival at warehouse",
      format: "DD-MM-YYYY",
      name: "realArrived",
      class: "col-md-3 input-form",
      placeHolder: "Actual arrival at warehouse",
      id: "ActualArrivalConsolWH",
    },
    {
      type: "Date",
      label: "Port/Airport departure",
      format: "DD-MM-YYYY",
      name: "portDeparture",
      class: "col-md-3 input-form",
      placeHolder: "Port/Airport departure",
      id: "DateDeparturePortAirport",
    },
    {
      type: "Date",
      label: "Final arrival",
      format: "DD-MM-YYYY",
      name: "finalDate",
      class: "col-md-3 input-form",
      placeHolder: "Final arrival",
      id: "FinalDestination",
    },
  ],
  previewForm: [
    {
      type: "Label",
      label: "Factory requested",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "factoryRequested",
      id: "RequestedExWorks",
    },
    {
      type: "Label",
      label: "Requested cargo ready",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "uploadRequest",
      id: "RequiredCagoReady",
    },
    {
      type: "Label",
      label: "Promise cargo ready",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "promiseUploadRequest",
      id: "PromiseCargoReady",
    },
    {
      type: "Label",
      label: "Actual cargo ready",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "realUploadRequest",
      id: "ActualCargoReady",
    },
    {
      type: "Label",
      label: "Estimated pickup",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "estimatePickup",
      id: "EstimatedPickedUp",
    },
    {
      type: "Label",
      label: "Actual pickup",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "RealPickup",
      id: "ActualCargoPickup",
    },
    {
      type: "Label",
      label: "Actual arrival at warehouse",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "realArrived",
      id: "ActualArrivalConsolWH",
    },
    {
      type: "Label",
      label: "Port/Airport departure",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "portDeparture",
      id: "DateDeparturePortAirport",
    },
    {
      type: "Label",
      label: "Final arrival",
      format: "DD-MM-YYYY",
      class: "col-md-3 input-form",
      name: "finalDate",
      id: "FinalDestination",
    },
  ],
};
