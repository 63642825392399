// by: JAB -
export default class Searcher {

  static defaultConfig() {
    return {
      fieldsToFilter: []
    };
  }
  static validateConfig(config) {
    if (!config || !Array.isArray(config.fieldsToFilter)) {
      Searcher.throwError('fieldToFilter: must to be Array of string, example: [filed1, field2] or []');
    }

    return config;
  }

  /**
   * 
   * @param {*} data - Array of elements
   * @param {*} text - Text to search
   */
  static filterData(data = [], text = '', config) {
    let dataFiltered = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const textToCompare = Searcher.formattingType(element, config.fieldsToFilter);
      if (Searcher.includeString(textToCompare, text)) {
        dataFiltered.push(element);
      }
    }
    return dataFiltered;
  }
  /**
   * Array  of elements
   * @param {*} data 
   */
  static isArray(data) {
    if (Array.isArray(data)) {
      return true;
    } else {
      return false;
    }
  }
  static throwError(message) {
    throw new Error(`${message}`);
  }
  static formatText(text) {
    return `${text}`;
  }
  /**
   * Element inside the array
   * @param {*} element - array element
   * @param {*} fieldsAllowed - Validate only if data tipe is equals to obj
   */
  static formattingType(element, fieldsAllowed = []) {
    if (typeof element === 'object') {
      let entri = '';
      for (var [key, value] of Object.entries(element)) {
        if (fieldsAllowed.indexOf(key) !== -1) {
          entri += ` ${key}:${value}`.toLowerCase();
        }
        // else console.log('im, outside')
      }
      return entri;
    } else if (typeof element === 'string') {
      return element.toLowerCase();
    } else if (typeof element === 'number') {
      return `${element}`;
    } else {
      return element;
    }
  }
  /**
   * Verify if text to compare includes the text to search
   * @param {*} a - Text to compare
   * @param {*} b - Text to search
   */
  static includeString(a, b) {
    // console.log('text A', a, 'text B:', a.includes(b));
    if (a.includes(b)) {
      return true;
    }
    return false;
  }

  search(data = [], text = '', config = {}) {
    if (!Searcher.isArray(data)) Searcher.throwError();
    const configSearch = config ? Searcher.validateConfig(config) : Searcher.defaultConfig();
    if (data.length > 0) {
      return Searcher.filterData(data, text.toLowerCase(), configSearch);
    }
    return data;
  }
}