import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import Form from '../../../../../../models/FormData'
import restService from '../../../../../../services/restService';
import Config from '../../models/Config'
import { Card, CardBody } from 'reactstrap'

const rest = new restService();
class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CarrierDetails: '',
            Form: Form,
            selects: Config.selectsAdditional,
            carrier: {
                CarrierAirCode: '',
                CarrierOceanCode: '',
                VesselName: '',
                Voyage: '',
                CarrierBookingNumber: '',
                CoLoaderBookingNumber: '',
                EstimatedTimeDeparture: '',
                EstimatedTimeArrival: '',
                DocumentCuttOff: '',
                CargoCutOff: ''
            },
        }
    }

    componentDidMount = () => {
        let CarriedDetails = this.state.Form.CarriedDetails[0]
        if (this.props.TransportModeId && this.props.TransportModeId == 1 || this.props.TransportModeId == 2) {
            CarriedDetails['id'] = 'CarrierOceanCode'
            CarriedDetails['name'] = 'CarrierOceanCode'
            CarriedDetails['options'] = 'CARRIERS-OCEAN'

        }
        else {
            CarriedDetails['id'] = 'CarrierAirCode'
            CarriedDetails['name'] = 'CarrierAirCode'
            CarriedDetails['options'] = 'CARRIERS-AIR'
        }
        this.setState({
            Form
        }, () => this.getAllCatalogs())
    }

    getAllCatalogs = () =>
        rest.EXEC({ _function: 'GetAllCatalogsBooking', params: '' }, fetch).then(data => {
            this.setState({ selects: data.data }, () => {
                this.buildFormContainers()
            })
        }).catch(error => {

        })
    componentWillReceiveProps = (nextProps) => {
        let CarriedDetails = this.state.Form.CarriedDetails[0]
        if (nextProps.TransportModeId && nextProps.TransportModeId == 1 || nextProps.TransportModeId == 2) {
            CarriedDetails['id'] = 'CarrierOceanCode'
            CarriedDetails['name'] = 'CarrierOceanCode'
            CarriedDetails['options'] = 'CARRIERS-OCEAN'

        }
        else {
            CarriedDetails['id'] = 'CarrierAirCode'
            CarriedDetails['name'] = 'CarrierAirCode'
            CarriedDetails['options'] = 'CARRIERS-AIR'
        }
        this.setState({
            Form
        }, () => {
            this.buildFormContainers()
        })
    }


    handleChange = name => event => {
        let carrier = this.state.carrier
        carrier[name] = event.target.value
        this.setState({ carrier }, () => {
            this.props.sendProps(carrier)
            this.buildFormContainers()
        })
    }
    handleChangeSelect = name => event => {
        let carrier = this.state.carrier
        if (event && event.value) {
            carrier[name] = event.value
            this.setState({ carrier }, () => {
                this.props.sendProps(carrier)
                this.buildFormContainers()
            })
        } else {
            carrier[name] = ""
            this.setState({ carrier }, () => {
                this.props.sendProps(carrier)
                this.buildFormContainers()
            })
        }

    }

    handleChangeDate = name => event => {
        let carrier = this.state.carrier
        try {
            carrier[name] = event.format("YYYY-MM-DD")
            this.setState({ carrier }, () => {
                this.props.sendProps(carrier)
                this.buildFormContainers()
            })
        }
        catch (ex) {
        }
    }

    handleChangeTime = name => event => {
        try {
            let carrier = this.state.carrier
            carrier[name] = event.format('YYYY-MM-DDThh:mmTZD')
            this.setState({ carrier }, () => {
                this.props.sendProps(carrier)
            })
        }
        catch (ex) {
        }
    }
    buildFormContainers = () => {
        const { Form, carrier, selects } = this.state
        let CarrierDetails = Form.CarriedDetails.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <span id={e.label}><label>{e.label} {e.required == true ? <span>*</span> : ""}</label></span>
                    <Input
                        id={e.label}
                        required
                        type="text"
                        placeholder={e.placeHolder}
                        value={carrier[e.id]}
                        name={e.id}
                        onChange={this.handleChange(e.id)}
                    />
                    <label>{carrier[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>
            )
            else if (e.type === "Select") return (
                <div className={e.class} key={e.id}><label >{e.label}</label>
                    <Select
                        id={e.label}

                        name={e.name}
                        value={carrier[e.id]}
                        onChange={this.handleChangeSelect(e.id)} options={selects[e.options]}
                    />
                    <label>{carrier[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>)

            else if (e.type === "Time") return (
                <div className={e.class} key={e.id}>
                    <span id={e.label}><label>{e.label} {e.required == true ? <span>*</span> : ""}</label></span>
                    <Datetime
                        type="date"
                        timeFormat={true}
                        value={carrier[e.id]}
                        onChange={this.handleChangeTime(e.id)} />
                    <label>{carrier[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>)
            else if (e.type === "Datetime") return (
                <div className={e.class} key={e.id}>
                    <span id={e.label}><label>{e.label} {e.required == true ? <span>*</span> : ""}</label></span>
                    <Datetime
                        required
                        type="date"
                        dateFormat={'YYYY MM DD'}
                        inputProps={{ className: 'form-control' }}
                        value={carrier[e.id]}
                        onChange={this.handleChangeDate(e.id)} />
                    <label>{carrier[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>
            )
        })
        this.setState({ CarrierDetails })
    }
    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
        const { CarrierDetails } = this.state
        return (
            <Card className="myCard">
                <CardBody>
                    <ExpansionPanel elevation={0} defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <legend><em className="fas fa-ship fa-1x mr-2"></em>Carrier Details</legend>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row" style={{ width: '100%' }}>
                                {CarrierDetails}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </CardBody>
            </Card>
        );
    }
}
export default List;