import React, { Component } from 'react'
import { Card, CardBody,Row, Col  } from 'reactstrap'
import { Pie as PieChart } from 'react-chartjs-2';
import { _chart1, _chart2, _chart3 } from './ChartChartJS.setup';
import FlotChart from './Float';
import {ChartBarStacked,dos} from './charts.config';
import IMAGENSPEND from "./file"

class Spend extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            _chart1 : _chart1,
            chart1 : ''
        }
    }

    componentDidMount = () => {
        this.setState({
            data: this.props.data
        },()=>{
            this.buildChart1()
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        },()=>{
            this.buildChart1()
        })
    }


    buildChart1 = ()=>{
        let {data,_chart1} = this.state
        let log = 0
        let purch = 0
        data.forEach((e)=>{
            log+=parseInt(e.logisticCost)
            purch += parseInt(e.poAmount)
        })
        _chart1.data.datasets[0].data =  [log,purch]
        this.setState({
            _chart1,
            chart1 : ''
        },()=>{
            this.setState({
                chart1 : <PieChart data={_chart1.data} options={_chart1.options} width={650} height={350} />
            })
        })
    }


    render() {
        return (
            <Card>
                <CardBody>
                <div className="row"> 
                    <img className="imgggggg1" src={IMAGENSPEND} />                    
                    <span ><h4 className="mb-3 py-2">Spend Performance</h4></span>
                </div>


                <Row>
                <Col lg={4}>
                                <div className="text-center py-5">
                                    {this.state.chart1}
                                </div>
                                </Col>


                             <Col lg={4}>
                            <div className="text-center py-1">
                            <FlotChart options={ChartBarStacked.options} data={ChartBarStacked.data} className="flot-chart"  width={350} height={350}/>
                            </div>
                            </Col>


                         <Col lg={4}>
                        <div className="text-center py-1">
                        <FlotChart options={dos.options} data={dos.data} className="flot-chart"  width={350} height={350}/>
                        </div>
                        </Col>

                </Row>
                </CardBody>
            </Card>
        )
    }
}

export default Spend