import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import Filters from './components/filters'
import Comments from './components/comment'
import Results from './components/results'
import RestService from '../../../../../services/restService'

const rest = new RestService()

class EUGround extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters : '',
            results : [],
        };
    }


    componentDidMount = () => {
        this.getResults()
    }


    componentWillReceiveProps = (nextProps) => {
        this.getResults()
    }

    getResults = ()=>{
        rest.EXEC({
            _function: 'GetOceanFreightResults',
            params: this.state.filters
        }, fetch).then(success => {
            if (success.data) {
                this.setState({
                    results: success.data
                })
            }
        })
    }

    buildQuery = (filters)=>{
        let FiltersQuery = ''
        let keys = Object.keys(filters)
        keys.map((e)=>{
            if(filters[e]) FiltersQuery+= e+'='+filters[e]+'&'
        })
        this.setState({
            filters : FiltersQuery
        },()=>{
            this.getResults()
        })
    }

    render() {

        return (
            <Card className="myCard">
                <CardBody>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <Filters sendQuery={this.buildQuery}></Filters>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <Comments></Comments>
                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <Results results = {this.state.results}></Results>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default EUGround;


