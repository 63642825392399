import React from 'react';
import propTypes from 'prop-types';
import './styles.scss';

/**
 * Card Component
 */
function Card(props){
  return (
    <div className={`mcard shadow col-12 bg-white rounded ${props.classes}`}>
      <div className={'row'}>
        {props.children}
      </div>
    </div>
  );
}

Card.propTypes = {
  classes: propTypes.string
};

Card.defaultProps = {
  classes: 'p-3 mb-3'
};

export default Card;