import React from 'react';
import Data from '../../../Data/Config';
import { Card, CardBody} from 'reactstrap';


/*=============================================
=            Header Component                 =
=============================================*/

function HeaderComponent(props) {
  const data = props.data;
  let rows = Data.configHeader.map((e) => {
    return (
      <div className={e.className} style={{ paddingTop: '1%' }}>
        <div className="row">
          <div className="col-12">
            <span style={{ color: '#9e9e9e', fontWeight: 'bold' }}  >
              {e.label}
            </span>
          </div>
          <div className="col-12">
            <span>{data[e.id] ? data[e.id] : ''}</span>
          </div>
        </div>
      </div>
    );
  });  
  return (
    <Card>
      <CardBody>
        <h4 className="mb-3 py-2"><em className="fas fa-shopping-basket mr-2"/>Purchase Order Detail</h4>
        <div className="row">
          {rows}
        </div>
      </CardBody>
    </Card>      
  );
}
export default HeaderComponent;