import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const opStatusMenu = [
  {
    label: ['How can i change my password?', 'tipoError'],
    value: 'currentLabel',
    trigger: 'genericSolution'
  },
  {
    label: ["I can't find my operation", 'tipoError'],
    value: 'currentLabel',
    trigger: 'genericSolution'
  },
  {
    label: ["I can't login to the mobile app", 'tipoError'],
    value: 'currentLabel',
    trigger: 'genericSolution'
  },
];

function RoldanProblemMenu(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <ChatbotButtons
        buttons={opStatusMenu}
        botSteps={props}
        size={'col-md-12'}
      />
    </div>
  );
}

export default RoldanProblemMenu;
