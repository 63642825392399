import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import weeklyData from '../../../../models/weekly';

const Scheduler = React.lazy(() => import('./scheduler'));

class WeeklyPlanning extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: weeklyData,
      activePage:1,
      limit:10,
      numberPage: 1,
      totalPages: 1,
      search: '',
      dataAux: [],
      dataOut:[],
      columns: [
        { label: 'Booking No', key: 'BookingNumber', custom: (value) => (
          <div className="row" style={{marginLeft: 0}}>{false
            ? <div style={{marginTop: -3, marginRight: 10}}><em className="fa-2x fas fa-arrow-alt-circle-left" style={{ color: '#57b65b' }}></em></div>
            : <div style={{marginTop: -3, marginRight: 10}}><em className="fa-2x fas fa-arrow-alt-circle-right" style={{ color: '#2f80e7' }}></em></div>}<Link to={'/weekly-planning/' + value.BookingNumber}>{value.BookingNumber}</Link></div>) },
        { label: 'Supplier', key: 'Supplier' },
        { label: 'Consignee', key: 'Consignee' },
        { label: 'Freight Forwarder', key: 'FreightForwarder' },
        { label: 'No. of Containers', key: 'ContainerNumber' },
        { label: 'Origin', key: 'Origin' },
        { label: 'Destination', key: 'Destination' },
        { label: 'ETD', key: 'ETD' },
        { label: 'ETA', key: 'ETA' },
        { label: 'POs', key: 'POs' }
      ],
      schedulerColumns: [],
      schedulerPortsDeparture: [
        {
          label: 'VNHPH',
          value: 'VNHPH',
          capacity: 15,
          type: 'Departure'
        },
        {
          label: 'BRVIX',
          value: 'BRVIX',
          capacity: 30,
          type: 'Departure'
        },
        {
          label: 'ITSPE',
          value: 'ITSPE',
          capacity: 15,
          type: 'Departure'
        },
        {
          label: 'ESVLC',
          value: 'ESVLC',
          capacity: 20,
          type: 'Departure'
        },
        {
          label: 'ITGOA',
          value: 'ITGOA',
          capacity: 10,
          type: 'Departure'
        },
        {
          label: 'ESVGO',
          value: 'ESVGO',
          capacity: 20,
          type: 'Departure'
        },
        {
          label: 'PTLIS',
          value: 'PTLIS',
          capacity: 30,
          type: 'Departure'
        },
        {
          label: 'INBOM',
          value: 'INBOM',
          capacity: 20,
          type: 'Departure'
        }
      ],
      schedulerPortsArrival: [
        {
          label: 'USBOS',
          value: 'USBOS',
          capacity: 15,
          type: 'Arrival'
        },
        {
          label: 'USSEA',
          value: 'USSEA',
          capacity: 15,
          type: 'Arrival'
        },
        {
          label: 'USPDX',
          value: 'USPDX',
          capacity: 20,
          type: 'Arrival'
        },
        {
          label: 'USLGB',
          value: 'USLGB',
          capacity: 15,
          type: 'Arrival'
        },
        {
          label: 'USHOU',
          value: 'USHOU',
          capacity: 20,
          type: 'Arrival'
        },
        {
          label: 'USOAK',
          value: 'USOAK',
          capacity: 10,
          type: 'Arrival'
        },
        {
          label: 'USNYC',
          value: 'USNYC',
          capacity: 20,
          type: 'Arrival'
        },
        {
          label: 'USTIW',
          value: 'USTIW',
          capacity: 20,
          type: 'Arrival'
        },
        {
          label: 'USBAL',
          value: 'USBAL',
          capacity: 20,
          type: 'Arrival'
        },
        {
          label: 'USLAX',
          value: 'USLAX',
          capacity: 20,
          type: 'Arrival'
        }
      ],
      filters: [],
      activeColumn: '',
      activePort: ''
    };
  }



  customTransport = (value) => (
    value.TransportModeId === 1
      ? <em className="fa-2x fas fa-arrow-alt-circle-left" style={{ color: '#57b65b' }}></em>
      : <em className="fa-2x fas fa-arrow-alt-circle-right" style={{ color: '#2f80e7' }}></em>
  )

  render() {
    let { activeColumn, activePort, columns, data, filters, schedulerColumns, schedulerPortsArrival, schedulerPortsDeparture } = this.state;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas fa-broadcast-tower fa-1x mr-2"></em>
            Control Tower
          </div>
        </div>
        <Tab
          menu={{ secondary: true,className:'myWrapped'  }}
          menuPosition='right'
          panes={[
            {menuItem: 'Arrival', render: () =><Suspense fallback={<div>Loading...</div>}><Scheduler type="1" data={data} columns={columns} filters={filters} activeColumn={activeColumn} activePort={activePort} schedulerColumns={schedulerColumns} schedulerPorts={schedulerPortsArrival} /></Suspense> },
            {menuItem: 'Departure', render: () =><Suspense fallback={<div>Loading...</div>}><Scheduler type="2" data={data} columns={columns} filters={filters} activeColumn={activeColumn} activePort={activePort} schedulerColumns={schedulerColumns} schedulerPorts={schedulerPortsDeparture} /></Suspense> }
          ]}/>
      </ContentWrapper>
    );
  }
}
export default withRouter(WeeklyPlanning);
