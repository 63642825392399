import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import Cards from './components/cards';
import { Label } from 'reactstrap';
import {Services} from './Services';
import Authentication from '../../../services/Authentication.js';
import axios from 'axios';
const auth = new Authentication();

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      path: ''
    };
  }
  
  componentDidMount = () => {
    this.setState({ path: this.props.match.params.id}, () => this.getQuickSearch());
  };

  componentWillReceiveProps = nextProps => {
    this.setState({ path: nextProps.match.params.id}, () => this.getQuickSearch());
  };

  getQuickSearch = async() => {
    try {
      const id  = auth.getAuthentication('customerSelect');
      const { path } = this.state;
      let timeStart; 
      let timeEnd;
      timeStart = Date.now();
      
      const response = await axios.get(`${Services.ORDER.path}order/search/one?q=${path}&id=${id}`);
      this.setState({ data: response.data.data, sizeRes: response.data.sizeSearch });
  
      timeEnd = Date.now();
      const timeValue = (timeEnd - timeStart) / 1000;
      this.setState({timeValue});
    }catch(error){
      console.error('getQuickSearch:', error);
    }
  };

  render() {
    const { timeValue, data, path , sizeRes} = this.state;
    return (
      <ContentWrapper>
        <Label>Total Results: {sizeRes} ({timeValue} seconds) for  <b> "{ path }" </b></Label>
        <Cards  data={data} />
      </ContentWrapper>
    );
  }
}
export default withRouter(QuickSearch);
