const TYPES ={
"AE":"Aerosol",
"AM":"Ampoule non-p",
"AP":"Ampoule protec",
"AT":"Atomiser",
"BG":"Bag",
"BL":"Bale compress",
"BN":"Bale non-comp",
"BF":"Balloon non-pro",
"BP":"Balloon protect",
"BR":"Bar",
"BA":"Barrel",
"BZ":"Bars in bundle",
"BK":"Basket",
"CB":"Beer crate",
"BI":"Bin",
"BD":"Board",
"BY":"Board in bundle",
"BB":"Bobbin",
"BT":"Bolt",
"BS":"Bottle non-prote",
"BO":"Bottle non-prote",
"BV":"Bottle protected",
"BQ":"Bottle protected",
"BC":"Bottlecrate bott",
"BX":"Box",
"BJ":"Bucket",
"VG":"Bulk gas",
"VQ":"Bulk liquefied",
"VL":"Bulk liquid",
"VY":"Bulk solid fine",
"VR":"Bulk solid gran",
"VO":"Bulk solid large",
"BH":"Bunch",
"BE":"Bundle",
"BU":"Butt",
"CG":"Cage",
"CX":"Can cylindrical",
"CA":"Can rectangular",
"CI":"Canister",
"CZ":"Canvas",
"CO":"Carboy non-protected",
"CP":"Carboy protected",
"CT":"Carton",
"CV":"Cover",
"CR":"Crate",
"CE":"Creel",
"CU":"Cup",
"CY":"Cylinder",
"DJ":"Demijohn non-protected",
"DP":"Demijohn protected",
"DR":"Drum",
"EN":"Envelope",
"FP":"Filmpack",
"FI":"Firkin",
"FL":"Flask",
"FO":"Footlocker",
"FR":"Frame",
"FD":"Framed crate",
"FC":"Fruit crate",
"GB":"Gas bottle",
"GI":"Girder",
"GZ":"Girdens in bundle",
"HR":"Hamper",
"HG":"Hogshead",
"IN":"Ingot",
"IZ":"Ingots in bundle",
"JR":"Jar",
"JY":"Jerrican cylindrical",
"JC":"Jerrican rectangular",
"JG":"Jug",
"JT":"Jutebag",
"KG":"Keg",
"LG":"Log",
"LZ":"Logs in bundle",
"MT":"Mat",
"MX":"Match box",
"MC":"Milk crate",
"MB":"Multiply bag",
"MS":"Multiwall sack",
"NS":"Nest",
"NT":"Net",
"PK":"Package",
"PA":"Packet",
"PL":"Pail",
"PC":"Parcel",
"PI":"Pipe",
"PO":"Pouch",
"RT":"Rednet",
"RL":"Reel",
"RG":"Ring",
"RD":"Rod",
"RZ":"Rods in bundle",
"RO":"Roll",
"SH":"Sachet",
"SA":"Sack",
"SE":"Sea-chest",
"SC":"Shallow crate",
"ST":"Sheet",
"SM":"Sheetmetal",
"SZ":"Sheets in bundle",
"SW":"shrinkwrapped",
"SK":"Skeleton case",
"SL":"Slipsheet",
"SD":"Spindle",
"SU":"Suitcase",
"TY":"Tank cylindrical",
"TK":"Tank rectangular",
"TC":"Tea chest",
"TN":"Tin",
"PU":"Trau",
"TP":"Tray pack",
"TR":"Trunk",
"TS":"Truss",
"TB":"Tub",
"TU":"Tube",
"TD":"Tube collapsible",
"TZ":"Tubes in bundle",
"TO":"Tun",
"NE":"Unpacked or unp",
"VP":"Vacuum-packed",
"VA":"Vat",
"VI":"Vial",
"WB":"Wicvkerbottle",
"CS":"Case",
"CK":"Cask",
"CH":"Chest",
"CC":"Churn",
"CF":"Coffer",
"CJ":"Coffin",
"CL":"Coil",
"PZ":"Pipes in bundle",
"PH":"Pitcher",
"PN":"Plank",
"PX":"Planks in bundle",
"PG":"Plate",
"PY":"Plates in bundle",
"PT":"Pot",
}

export default TYPES