import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from 'reactstrap';
import Form from '../../../../../../models/FormData'
import restService from '../../../../../../services/restService';
import Config from '../../models/Config'

import { Card, CardBody } from 'reactstrap'

const rest = new restService();
class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CarrierDetails: '',
            Form: Form,
            selects: Config.selectsAdditional,
            comments: {
                Comments: '',
                InternalComments: ''
            }
        }
    }
    componentDidMount = () => {
        this.getAllCatalogs()
    }

    getAllCatalogs = () =>
        rest.EXEC({ _function: 'GetAllCatalogsBooking', params: '' }, fetch).then(data => {
            this.setState({ selects: data.data }, () => {
                this.buildFormContainers()
            })
        }).catch(error => {

        })


    handleChange = name => event => {
        let comments = this.state.comments
        comments[name] = event.target.value
        this.setState({ comments }, () => {
            this.props.sendProps(comments)
            this.buildFormContainers()
        })
    }

    buildFormContainers = () => {
        const { Form, comments, selects } = this.state
        let CarrierDetails = Form.AdditioanalComments.map((e, i) => {
            if (e.type === 'textarea') return (
                <div className="col-md-6" key={e.id}>
                    <label >{e.label}</label>
                    <Input
                        required
                        type="textarea"
                        name={e.id}
                        id={e.id}
                        value={comments[e.id]}
                        onChange={this.handleChange(e.id)}
                    >
                    </Input>
                </div>
            )
        })
        this.setState({ CarrierDetails })
    }
    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
        const { CarrierDetails } = this.state
        return (
            <Card className="myCard">
                <CardBody>
                    <ExpansionPanel elevation={0} defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <legend><em className="fas fa-comments fa-1x mr-2"></em>Additional Comments</legend>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row" style={{ width: '100%' }}>
                                {CarrierDetails}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </CardBody>
            </Card>

        );
    }
}
export default List;