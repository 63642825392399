import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { IconButton, ListItemText, Grid, Tooltip } from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  MoreVert,
  Edit,
  Done,
  Clear,
  Sort,
} from "@mui/icons-material";
import { Dropdown, Modal, message } from "antd";
import { ListItemParcedModal } from "./listItemParcedModal";

const ListItemsTitle = ({
  item,
  index,
  handleExpand,
  handleEditItem,
  handleUpdateStatus,
  t,
  parcedPOItem,
  rolId,
}) => {
  const [open, setOpen] = useState(false);
  const { confirm } = Modal;

  const toggle = () => {
    setOpen(!open);
  };

  const handleEdit = () => {
    handleExpand(index, true);
    handleEditItem(index, !item.edit);
  };

  const handleOptions = (status) => {
    let title = "Are you sure you want to authorize the product changes?";
    if (status === 49) {
      title = "Are you sure you want to reject the product?";
    } else if (status === 50) {
      title = "Are you sure you want to cancel the product?";
    }
    confirm({
      title: <hr className="hr-popover"></hr>,
      icon: false,
      cancelText: "No",
      okText: "Yes",
      content: (
        <div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <p class="seacargo-pop-icon">
              <em class="fas fa-exclamation-triangle"></em>
            </p>
            <span>{title}</span>
          </div>
        </div>
      ),
      onOk: async () => {
        try {
          await handleUpdateStatus(item, status); //accepted
          message.success("Purchase order item successfully updated.");
        } catch (err) {
          console.log("error en update status", err);
          message.error("Error updating purchase order item.");
        }
        // handleUpdateStatus(item.POItemsId, 47); //accepted
        // handleUpdateStatus(item.POItemsId, 49); //rejected
        // handleUpdateStatus(item.POItemsId, 50); //canceled
      },
      onCancel() {},
    });
  };

  const items = [
    {
      key: "1",
      label: <ListItemText>Edit</ListItemText>,
      icon: <Edit />,
    },
    {
      key: "2",
      label: <ListItemText>Accept</ListItemText>,
      icon: <Done />,
    },
    {
      key: "3",
      label: <ListItemText>Reject</ListItemText>,
      icon: <Clear fontSize="small" />,
    },
    {
      key: "4",
      label: <ListItemText>Cancel</ListItemText>,
      icon: <Clear fontSize="small" />,
    },
    {
      key: "5",
      label: <ListItemText>Parced</ListItemText>,
      icon: <Sort />,
    },
  ];

  const handleDropdown = (e) => {
    switch (e.key) {
      case "1":
        handleEdit();
        break;
      case "2":
        handleOptions(48);
        break;
      case "3":
        handleOptions(49);
        break;
      case "4":
        handleOptions(51);
        break;
      case "5":
        setOpen(true);
        break;
      default:
        break;
    }
  };

  const filterMenu = () => {
    let menu = [];
    menu.push(items[0]);
    if (item.StatusId === 47) {
      menu.push(items[1]);
      menu.push(items[2]);
      menu.push(items[3]);
      if (item.QTYOrdered > item.QTYConfirmed && item.QTYConfirmed > 0) {
        menu.push(items[4]);
      }
    }
    return menu;
  };

  const update = async () => {
    try {
      await parcedPOItem(item.POItemsId);
      message.success("Purchase order item successfully partiallyized");
    } catch (err) {
      console.log("error en parced", err);
      message.error("Error while partiallyizing the purchase order item");
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 40) {
      return (
        <Tooltip
          placement="bottom"
          title={
            <label
              className="label-euro normal">
              {description}
            </label>
          }
        >
          <span className="label-euro normal">
            {description.substring(0, 37) + "..."}
          </span>
        </Tooltip>
      );
    }
    return <span className="label-euro normal">{description}</span>;
  };

  return (
    <>
      <ListItemParcedModal
        open={open}
        toggle={toggle}
        t={t}
        update={update}
        item={item}
      />
      <Grid container spacing={0} alignItems="center">
        <Grid xs={1} container direction='row'>
          <IconButton onClick={() => handleExpand(index)}>
            {item.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <IconButton>
            <Dropdown
              menu={{
                items: filterMenu(),
                onClick: handleDropdown,
              }}
              trigger={["click"]}
              placement="bottom"
            >
              <MoreVert />
            </Dropdown>
          </IconButton>
        </Grid>
        <Grid xs={2}>
          <label className="label-euro bold">
            Line No.:{" "}
            <span className="label-euro normal">{item.LineNo}</span>
          </label>
        </Grid>
        <Grid xs={2}>
          <label className="label-euro bold">
              Item No.:{" "}
            <span className="label-euro normal">{item.ItemNo}</span>
          </label>
        </Grid>
        <Grid xs={2} paddingLeft="8px" paddingRight="8px">
          <label className="label-euro bold">
            Description:{" "}
            {truncateDescription(item.Description)}
          </label>
        </Grid>
        <Grid container xs={3}>
          <Grid xs={6}>
            <label className="label-euro bold">
              QTY ordered:{" "}
              <span className="label-euro normal">{item.QTYOrdered}</span>
            </label>
          </Grid>
          <Grid xs={6}>
            <label className="label-euro bold">
              QTY confirmed:{" "}
              <span className="label-euro normal">{item.QTYConfirmed}</span>
            </label>
          </Grid>
        </Grid>
        <Grid xs={2}>
          <span class="badge badge-euro">{item.Status}</span>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(ListItemsTitle);
