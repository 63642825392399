import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap'
import { Button } from 'reactstrap'
import Tables from '../../../../../template/Table';
import Icon from '@material-ui/core/Icon';

class listCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { label: 'NO', key: '', index: true },
        { label: 'Name', key: 'Name' },
        { label: 'last Name', key: 'LastName' },
        { label: 'Position', key: 'Position' },
        { label: 'Telephone', key: 'Telephone' },
        { label: 'Email', key: 'Email' },
        { label: 'Actions', key: '', custom: (value) => this.custom1(value) }
      ],
      items: [
      ]
    };
  }

  custom1 = (value) => (
    <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value)}>
      <Icon className="icon" style={{ fontSize: 20 }}>
        remove
            </Icon>
    </Button>
  )

  componentDidMount = () => {
    if (this.props) {
      this.setState({
        items: this.props.item
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps) {
      this.setState({
        items: nextProps.item
      })
    }
  }

  deleteLine = index => event => {
    let deleteValue = index
    let itemNew = this.state.items.filter(e => {
      if (e.Name != deleteValue.Name && e.Email != deleteValue.Email) {
        return e
      }
    })
    this.props.DeleteContact(itemNew)
  }
  render() {
    let { items } = this.state
    return (
      <Card>
        <Tables data={items} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
      </Card>
    );
  }
}

export default listCustomer;