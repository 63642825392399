/**
 * Menu Handler
 * -------------
 * Roles:
 * 1- Admin
 * 2- Interglobo Edit
 * 3- Customer Edit
 * 4- Interglobo Read
 * 5- Customer Read
 * 6- Interglobo SU
 */
export const MenuHandler = {
  // admin
  1: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Control Tower",
      icon: "icon--menu_controltoweron",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Weekly Planning",
          path: "/weekly-planning",
        },
        {
          name: "Calendar View",
          path: "/calendar-view",
        },
        // {
        //   name: 'Capacity Set Up',
        //   path: '/capacity-set-up'
        // }
      ],
    },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Create",
          path: "/order-create",
        },
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },
      ],
    },
    {
      name: "Vendor Management",
      icon: "icon--po_suppliericon",
      path: "/vendor-management",
    },
    {
      name: "Quote a Service",
      icon: "icon--po_orderdetails",
      path: "/quote-service",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },

    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      translate: "sidebar.nav.Tracking",
      submenu: [
        {
          name: "International",
          path: "/shipment/summary",
        },
        {
          name: "Customs",
          path: "/customs",
        },
        {
          name: "Warehousing",
          path: "/storage",
        },
        {
          name: "Inventory Details",
          path: "/inventaryDetail",
        },
      ],
    },

    {
      name: "Container Management",
      // icon: 'fas fa-chart-line',
      icon: "icon--dashboard_container",
      path: "/Container",
    },

    {
      name: "Warehousing",
      icon: "icon--menu_warehousingon",
      path: "/warehousing",
      // label: { value: 30, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: " Cost Management",
      icon: "icon--menu_costmanagementoff_1",
      translate: "sidebar.nav.COST",
      submenu: [
        {
          name: "Rates",
          path: "/rates",
        },
        {
          name: "Summary",
          path: "/cost-summary",
        },
        {
          name: "Invoicing",
          path: "/cost-management",
        },
        {
          name: "Purchasing",
          path: "/cost-purchasing",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
    // {
    //   name: 'Customer Service',
    //   icon: 'icon--menu_customerserviceoff',
    //   path: '/customer-service',
    //   translate: 'sidebar.nav.DASHBOARD'
    // },
    {
      name: "Customer Service",
      icon: "fas fa-globe",
      submenu: [
        {
          name: "Help Desk",
          path: "/Service-Desk",
        },
      ],
    },
    {
      name: "Admin",
      icon: "icon--menu_adminoff",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Customer Management",
          path: "/admin",
        },
        {
          name: "Alerts & Notifications",
          path: "/alerts",
        },
        {
          name: "Run Procedures",
          path: "/procedures",
        },
        {
          name: "Statistics",
          path: "/statistics",
        },
        {
          name: "Styles",
          path: "/templates",
        },
      ],
    },
  ],
  2: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    // {
    //   name: 'Warehousing',
    //   icon: 'icon--menu_warehousingon',
    //   path: '/warehousing',
    //   // label: { value: 30, color: 'success' },
    //   translate: 'sidebar.nav.DASHBOARD'
    // },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Create",
          path: "/order-create",
        },
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },

        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
  ],
  3: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },
        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
  ],
  4: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    // {
    //   name: 'Warehousing',
    //   icon: 'icon--menu_warehousingon',
    //   path: '/warehousing',
    //   // label: { value: 30, color: 'success' },
    //   translate: 'sidebar.nav.DASHBOARD'
    // },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Create",
          path: "/order-create",
        },
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },

        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
  ],
  5: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },
        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
  ],
  6: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    // {
    //   name: 'Warehousing',
    //   icon: 'icon--menu_warehousingon',
    //   path: '/warehousing',
    //   // label: { value: 30, color: 'success' },
    //   translate: 'sidebar.nav.DASHBOARD'
    // },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Create",
          path: "/order-create",
        },
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Multiple Update",
          path: "/multiple-update",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },

        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "Create",
          path: "/booking-create",
        },
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
    {
      name: "Admin",
      icon: "icon--menu_adminoff",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "Run Procedures",
          path: "/procedures",
        },
      ],
    },
  ],
  7: [
    {
      heading: " ",
      translate: "sidebar.heading.HEADER",
    },
    {
      name: "My Dashboard",
      icon: "icon--menu_dashboardoff",
      path: "/dashboard",
      // label: { value: 0, color: 'success' },
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "PO Management",
      icon: "icon--menu_porderon",
      translate: "sidebar.nav.ORDERS",
      submenu: [
        {
          name: "Visibility",
          path: "/order-list",
        },
        {
          name: "Management",
          path: "/management",
        },
        {
          name: "Entity Asociation",
          path: "/entity-association",
        },
        // {
        //   name: 'Pending Activities',
        //   path: '/pending-activities'
        // },
      ],
    },
    {
      name: "Quote a Service",
      icon: "icon--po_orderdetails",
      path: "/quote-service",
      translate: "sidebar.nav.DASHBOARD",
    },
    {
      name: "Bookings",
      icon: "icon--menu_bokingoff",
      translate: "sidebar.nav.BOOKING",
      submenu: [
        {
          name: "List",
          path: "/booking-list",
        },
        // {
        //   name : 'bookin-confirmation',
        //   path : '/booking-confirmation/DEMO-Boook-Air-9'
        // }
      ],
    },
    {
      name: "Tracking",
      icon: "icon--menu_trackingoff",
      path: "/shipment/summary",
      translate: "sidebar.nav.SHIPMENT",
    },
    {
      name: "Reporting & Analytics",
      icon: "icon--menu_analyticson",
      translate: "sidebar.nav.DASHBOARD",
      submenu: [
        {
          name: "My Reports",
          path: "/reporting",
        },
        {
          name: "Visual Analytics",
          path: "/visual-analytics",
        },
      ],
    },
    {
      name: " Cost Management",
      icon: "icon--menu_costmanagementoff_1",
      translate: "sidebar.nav.COST",
      submenu: [
        // {
        //   name: 'Rates',
        //   path: '/rates'
        // },
        // {
        //   name: 'Summary',
        //   path: '/cost-summary'
        // },
        {
          name: "Invoicing",
          path: "/cost-management",
        },
        // {
        //   name : 'Purchasing',
        //   path : '/cost-purchasing'
        // }
      ],
    },
    {
      name: "RSS Feeds",
      icon: "icon-feed",
      path: "/rss-feeds",
    },
  ],
};

export const NameRole = {
  1: "Admin",
  2: "Interglobo Edit",
  3: "Customer Edit",
  4: "Interglobo Read",
  5: "Customer Read",
  6: "Interglobo SU",
  7: "Customer Invoice",
};
