import React, { Component } from 'react';

import Table from '../../../../../../template/Table'


class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainColumns : [
                { label : 'Service Type', colspan : '6'},
                { label : '', colspan : '1'},
                { label : 'Service Definition', colspan : '8'},
                { label : 'Base Rates + BAF per container', colspan : '3'},
                { label : 'Detention and Demurrage Rates', colspan : '6'},
                { label : 'Additional surcharges per container (the following surcharges will only be applied when applicable; Norwegian Cruise Lines Holdings  will not accept any surcharges not explicitly stated below or that have already been bundled into the base rate, any blanks below indicate that surcharge is already built into the base rate)', colspan : '32'}
            ],
            columns: [
                { label : 'ID', key : 'ID'},
                { label:'Move Type' , key: 'MoveType' },
                { label:'Current Port Load' , key: 'CurrentPortLoad' },
                { label:'Destination Port' , key: 'DestinationPort' },
                { label:'Equip Type' , key: 'EquipType' },
                { label:'Equip Size' , key: 'EquipSize' },
                { label:'Info' , key: 'Info' },
                { label:'Transit Time' , key: 'TransitTime' },
                { label:'List All Stops On Routing' , key: 'ListAllStopsOnRouting' },
                { label:'Trans Ship Direct' , key: 'TransShipDirect' },
                { label:'Primary Carrier' , key: 'PrimaryCarrier' },
                { label:'List All Trans Ship Locations' , key: 'ListAllTransShipLocations' },
                { label:'List Companies With Vessel' , key: 'ListCompaniesWithVessel' },
                { label:'If POL Alternate Port' , key: 'IfPOLAlternatePort' },
                { label:'If POD Alternate Port' , key: 'IfPODAlternatePort' },
                { label:'Currency' , key: 'Currency' },
                { label:'BaseRate' , key: 'BaseRate' },
                { label:'Bunker Adjustment Factor' , key: 'BunkerAdjustmentFactor' },
                { label:'Origin Detention Demurrage Free Days', isLarge:true , key: 'OriginDetentionDemurrageFreeDays' },
                { label:'Cost Per Additional Detention Day', isLarge:true, key: 'CostPerAdditionalDetentionDay' },
                { label:'Origin Currency' , key: 'OriginCurrency' },
                { label:'Destination Detention Demurrage Free Days', isLarge:true , key: 'DestinationDetentionDemurrageFreeDays' },
                { label:'Cost Per Additional Day' , key: 'CostPerAdditionalDay' },
                { label:'Destination Currency' , key: 'DestinationCurrency' },
                { label:'OTHC' , key: 'OTHC' },
                { label:'OTHC Currency' , key: 'OTHCCurrency' },
                { label:'DTHC' , key: 'DTHC' },
                { label:'DTHC Currency' , key: 'DTHCCurrency' },
                { label:'Currency For All Other Surcharges', isLarge:true , key: 'CurrencyForAllOtherSurcharges' },
                { label:'ADR Surcharge' , key: 'ADRSurcharge' },
                { label:'AMS Surcharge' , key: 'AMSSurcharge' },
                { label:'Security Surcharge' , key: 'SecuritySurcharge' },
                { label:'IMO Hazmat Dangerous Goods Surcharge', isLarge:true , key: 'IMOHazmatDangerousGoodsSurcharge' },
                { label:'Low Sulfur Surcharge' , key: 'LowSulfurSurcharge' },
                { label:'Verified Gross Mass' , key: 'VerifiedGrossMass' },
                { label:'Documentation Fees Origin Destination', isLarge:true, key: 'DocumentationFeesOriginDestination' },
                { label:'BOL Creation' , key: 'BOLCreation' },
                { label:'Heavy Weight Surcharge' , key: 'HeavyWeightSurcharge' },
                { label:'ISF Document Preparation Fee', isLarge:true , key: 'ISFDocumentPreparationFee' },
                { label:'ISPS SecuritySurcharge' , key: 'ISPS SecuritySurcharge' },
                { label:'Value Added Tax' , key: 'ValueAddedTax' },
                { label:'Shipper Export Declaration' , key: 'ShipperExportDeclaration' },
                { label:'Panama Canal Transit Fee' , key: 'PanamaCanalTransitFee' },
                { label:'Aden Gulf Surcharge' , key: 'AdenGulfSurcharge' },
                { label:'Customs Declaration' , key: 'CustomsDeclaration' },
                { label:'Customs Inspection Charges', isLarge:true, key: 'CustomsInspectionCharges' },
                { label:'Customs Validation Origin Port', isLarge:true , key: 'CustomsValidationOriginPort' },
                { label:'Currency Adjustment Factor' , key: 'CurrencyAdjustmentFactor' },
                { label:'Emergency Fuel Surcharge' , key: 'EmergencyFuelSurcharge' },
                { label:'Euro Advance Manifest' , key: 'EuroAdvanceManifest' },
                { label:'Inspection Charge' , key: 'InspectionCharge' },
                { label:'Container ReleaseFee' , key: 'ContainerReleaseFee' },
                { label:'Container SealingFees' , key: 'ContainerSealingFees' },
                { label:'Equipment Maintnenace Fee' , key: 'EquipmentMaintnenaceFee' },
                { label:'Equipmen Cleaning Fees' , key: 'EquipmenCleaningFees' },
                { label:'All Other Surcharges' , key: 'AllOtherSurcharges' },
            ],
            data: []
        };
    }


    componentDidMount = () => {
        this.setState({
            data : this.props.results
        })
    }


    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data : nextProps.results
        })
    }



    render() {

        return (
            <div className="row">
                <div className="col-12">
                <Table
                    columns={this.state.columns}
                    data={this.state.data}
                    mainColumns = {this.state.mainColumns}
                    bordered = {true}
                    type = {'GENERIC'}

                    options={{
                        large:true,
                        // pagination:true,
                        downloads: {
                            pdf: false,
                            xlsx: true
                        }
                    }}
                />
                </div>
            </div>
        );
    }
}

export default Results;


