import React, { useEffect, useCallback, useState } from 'react';
import { Col, Label, Row, Badge } from 'reactstrap';
import { EN, ES } from '../../Etiquetas/Labels';
import { FormEnglish, FormEsp } from '../../Form';
import ControlledAccordions from './Components/Accordion';
import { ModelCreateTicket } from '../CreateTicket/model';
import { Link, useParams } from "react-router-dom";
import ModalCloseTicket from './Components/closeticket';
// import Swal from 'sweetalert2/dist/sweetalert2.js';
// import 'sweetalert2/src/sweetalert2.scss';
import Axios from "axios";
import ContentWrapper from '../../Components/ContenWraper/ContentWrapper'

// import { ModelDetailTicket } from './model';
import Loader from '../../loaders';
import { Services } from '../../Services';


const ColorEstatus = {
  1: '#2ca8ff',
  2: '#2ca8ff',
  3: '#2ca8ff',
  4: '#2ca8ff',
  5: '#2ca8ff',
  6: '#0561fccc',
  7: '#0060a8'
}

const colorsButtons = {
  1: { Create: 'primary', Cancel: 'primary' },
  // 2: { Create: 'primary', Cancel: 'danger' },
  2: { Cancel: 'danger', CancelYes: 'primary', CancelNo: 'danger', UploadDoc: 'primary', CancelDoc: 'danger' },
  3: { Create: 'primary', Cancel: 'warning' },
  4: { Create: 'success', Cancel: 'warning' },
  5: { Create: 'success', Cancel: 'warning' },
  6: { Cancel: 'primary', CancelYes: 'primary', CancelNo: 'secondary', UploadDoc: 'primary', CancelDoc: 'secondary' },
  7: { Cancel: 'primary', CancelYes: 'primary', CancelNo: 'secondary', UploadDoc: 'primary', CancelDoc: 'secondary' },
};

function TicketDetailsComponent(props) {

  const { id } = useParams();
  const [modelDetail] = React.useState({ ...ModelCreateTicket() });
  const [Labels, setLabels] = useState(EN);
  const [Form, setForm] = React.useState(FormEnglish);
  const [validate, setValidate] = React.useState(false);
  const [Ticket, setTicket] = useState([{ TicketNumber: '', CreatedBy: '', Subjet: '', Status: '', Type: '', CreationDate: '', CloseDate: '' },]);
  // const [idRason, setIdRason] = useState(0);
  const [closed, setClosed] = useState(false);
  const [ActiveLoader, setActiveLoader] = React.useState(false)
  const [idProject, setIdProject] = useState(0);
  const [newComment, setNewComment] = React.useState('');
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [tz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)


  const GetTicketId = useCallback(async (idProject) => {
    try {
      let body = {
        idProject: idProject,
        timezone: tz
      }
      let Ticket = await Axios.post(`${Services.TICKETS.path}Ticket/ticketid/${id}`, { timezone: tz });
      let resultCatalogos = await Axios.post(`${Services.TICKETS.path}catalogos/catalogos/`, body);
      resultCatalogos.data.data.dataCatReasonsCloses.map((e) => {
        modelDetail.Catalogs.ReasonsCloses.push({ 'key': e.ReasonCloseId, 'value': e.ReasonCloseId, 'text': e.ReasonClose });
        return e;
      });
      if (Ticket.data.data[0].CatStatusId === 3 || Ticket.data.data[0].CatStatusId === 4) {
        setClosed(true);
      } else {
        setClosed(false);
      }
      setTicket(Ticket.data.data);
      setActiveLoader(true)
    } catch (error) {
      console.log('The Error-->', error);
    }
  }, [idProject, tz, id]);

  // const ClosedTicket = (evt, data, type) => {
  //   let TicketNumber = Ticket[0].TicketNumber
  //   Axios.post(`${Services.Tickets.path}Ticket/closedticket/${id}`, { Rason: idRason })
  //     .then((resp) => {
  //       GetTicketId();
  //       Swal.fire({
  //         icon: 'success',
  //         title: Labels.alerts.closed_ticket.header,
  //         text: Labels.alerts.closed_ticket.message + ' ' + `#${TicketNumber}`,
  //         // footer: '<a href>Why do I have this issue?</a>'
  //       });
  //     })
  //     .catch((error) => {
  //       console.warn(error);
  //     });
  // };

  function CancelTicket() {
    let body = {
      comm: newComment,
      ByHelpDesk: 0,
      ByUser: 1
    }
    Axios.put(`${Services.TICKETS.path}Ticket/cancelticket/${id}`, body)
      .then((response) => {
        GetTicketId()
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  function handleChange(evt, data) {
    setNewComment(data.value);
    if (data.value) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
    // let value = data.value;
    // setIdRason(value);
  }

  // const getProjects = useCallback(async (idProject) => {
  //   setIdProject(idProject)
  //   try {
  //     let Projets = await Axios.get(`${Services.TICKETS.path}project/getproject/${idProject}`);
  //     // console.log('this i the project--->', Projets.data.data[0].LanguageCode);
  //     setLabels(Projets.data.data[0].LanguageCode === 'ES' ? ES : EN)
  //     setForm(Projets.data.data[0].LanguageCode === 'ES' ? FormEsp : FormEnglish)
  //     setValidate(Projets.data.data[0].LanguageCode === 'EN' ? true : false)
  //     GetTicketId(idProject);
  //   } catch (error) {
  //     console.log('The Error-->', error);
  //   }
  // }, []);

  useEffect(() => {
    setIdProject(props.ProjectId)
    // setLanguaje(props.lng === 'EN' ? 'Ingles' : 'Español')
    setLabels(props.lng === 'EN' ? EN : ES)
    setForm(props.lng === 'EN' ? FormEnglish : FormEsp)
    setValidate(props.lng === 'EN' ? true : false)
    // setLabels(props._language === 'Ingles' ? EN : ES)
    // setForm(props._language === 'Ingles' ? FormEnglish : FormEsp)
    // setValidate(props._language === 'Ingles' ? true : false)
    GetTicketId(props.ProjectId);
  }, [props]);

  return (
    // <div style={{ background: '#f5f5f5' }}>

    <ContentWrapper>
      <Loader hidden={ActiveLoader} projectId={props.ProjectId} />
      <div className="content-heading">
        <div className={props.style ? props.style.titleModule : ''}>
          <i className={Labels.header.icon} />
          <b>{Labels.details.title}</b>
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-8 col-md-9 col-lg-10'>
          <div className='row'>
            <div className='col-xs-12 col-md-4 col-lg-3'>
              <Link to={'/Service-Desk'} style={{ textDecoration: 'none' }}>
                <Label style={{ color: ColorEstatus[props.ProjectId] }} className='back-button-sd'>
                  <i className='fas fa-chevron-left' />
                  {`Ticket ${Ticket[0].TicketNumber}`}
                </Label>
              </Link>
              <Badge style={{ width: '100%', background: ColorEstatus[props.ProjectId], fontSize: '1rem', padding: '0.3em' }} pill>{Ticket[0].Status}</Badge>
            </div>
            <div className='col-xs-12 col-md-8 col-lg-9'>
              <div >
                <span className='title-ticket'>{` ${Ticket[0].Subjet}`}</span>
              </div>
              <div>
                <Label>
                  {closed ? `${Labels.details.lbl_close} ` : `${Labels.details.last_update} `}
                  {`${Ticket[0].updatedate} `}
                </Label>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xs-4 col-md-3 col-lg-2'>
          <ModalCloseTicket
            Ticket={Ticket[0]}
            catalogos={modelDetail.Catalogs}
            labels={Labels}
            CancelTicket={CancelTicket}
            onChange={handleChange}
            idProject={props.ProjectId}
            disabledButton={disabledButton}
            colorsButtons={colorsButtons[props.ProjectId]}
          />
          {/* <Button color='red' floated='right'>
              {Labels.details.btn_close_ticket}
            </Button> */}
        </div>
      </div>
      <ControlledAccordions dataTikets={Ticket[0]} id={id} Closed={closed} Labels={Labels} Form={Form} validate={validate} GetTicketId={GetTicketId} colorsButtons={colorsButtons[props.ProjectId]} class={props.style} />
    </ContentWrapper>
    // </div>

  );
}

export default TicketDetailsComponent;