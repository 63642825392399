import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConsigneeDetails from '../../../../Order/order-create/components/Consignees'
import DeliveryDetails from '../../../../Order/order-create/components/Deliveries'
import SpplierDetails from '../../../../Order/order-create/components/Suppliers'
import Config from '../../models/Config'

import { Card, CardBody } from 'reactstrap'

const map = Config.map
class Booking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ConsigneeDetails: {

            },
            selected: []
        };
    }
    componentDidMount = () => {
        this.setState({
            selected: this.props.selected
        })
    }
    componentWillReceiveProps = (nextProps) => {
        this.setState({
            selected: nextProps.selected
        })
    }

    recieveProps = (object) => this.setState({ [object.stateField]: object.data }, () => this.setFieldToOrder(object['stateField'], object['id']))

    setFieldToOrder = (key, value) => {
        let { ConsigneeDetails } = this.state
        ConsigneeDetails[map[key] ? map[key] : key] = value
        this.setState({ ConsigneeDetails }, () => {
            this.props.sendProps(ConsigneeDetails)
        })
    }

    render() {
        const { selected } = this.state
        return (
            <Card className="myCard">
                <CardBody clas>
                    <ExpansionPanel elevation={0} defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-md-4">
                                    <SpplierDetails sendProps={this.recieveProps} selected={selected}></SpplierDetails>
                                </div>
                                <div className="col-md-4">
                                    <ConsigneeDetails sendProps={this.recieveProps} selected={selected}></ConsigneeDetails>
                                </div>
                                <div className="col-md-4">
                                    <DeliveryDetails sendProps={this.recieveProps} selected={selected}></DeliveryDetails>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </CardBody>
            </Card>
        );
    }
}
export default Booking;