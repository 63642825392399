import React from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';


/*=============================================
=            Card Toptals Conponent           =
=============================================*/

function CardTotals(props){
  return (
    <Col xl={props.xl} md={props.md} sm={props.sm}>
      {/* <Card className="myCard"> */}
        {/* <CardBody> */}
          <div className="shadow card flex-row align-items-center align-items-stretch border-0">
            {props.loading ? 
              <Skeleton height={80} width="30%"/>
              :
              <div className={props.propertiesIcon}>
                {props.image}
              </div>

            }
            {props.loading ? 
              <Skeleton height={80} width="70%" className="margin_left"/>:
              <div className={props.propertiesTitle}>
                <div style={{fontSize:'13px'}} className="">{props.title}</div>
                <div style={{fontSize:'56px',fontWeight:'bold',marginBottom:'-10px'}} className="h2 mt-0">{props.total}</div>
              </div>
            }
          </div>
        {/* </CardBody> */}
      {/* </Card> */}
    </Col>    
  );
}

// Especifica los valores por defecto de props:
CardTotals.defaultProps = {
  xl: 3,
  md: 6,
  sm: 12
};

CardTotals.propTypes = {
  xl: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  propertiesIcon: PropTypes.string,
  image: PropTypes.element,
  propertiesTitle: PropTypes.string,
  total: PropTypes.number,
  title: PropTypes.string
};

export default CardTotals;