import React, {Component} from 'react';
import Datetime from 'react-datetime';
import Select from 'react-virtualized-select';
import { Col, Row, Input, Label } from 'reactstrap';
import { Icon, Grid } from 'semantic-ui-react';
import filterIcon from '../../../../resources/filter.png';
import FormModel from '../models/models';

export class BookingForm extends Component  {

  state = {
    order:  Object.keys(this.props.orderExist).length === 0 ? {} : this.props.orderExist,
    valueNames:[],
  }

  handleChange = selected => event => {
    let { order } = this.state;
    if (event && event.value) {
      order[selected] = event.value;
      this.props.onResults(order);
      this.setState({ order });
    } else {
      order[selected] = '';
      this.setState({ order });
    }
  };

  handeInputData = selected => event => {
    let { order } = this.state;
    if (event.target.value) {
      order[selected] = event.target.value;
      this.props.onResults(order);
      this.setState({ order });
    } else {
      order[selected] = '';
      this.setState({ order });
    }
  };

  handleChangeDates = name => event => {
    let order = this.state.order;
    let valueNames = this.state.valueNames;
    try {
      order[name] = event.format('YYYY-MM-DD');
      valueNames[name] = name;
      this.props.onResults(order);
      this.setState({ order, valueNames }, () => {
        this.validateInputTime();
      });
    } catch (ex) {
      order[name] = '';
      this.setState({ order });
    }


    /* ======================================= validacion de inputs BOOKINGS ========================================= */
    if (!order['cargoReadyEnd'] && this.state.val === false) {this.setState({ id: 'cargoReadyEnd', val: true, isValidDate: false });}
    if (!order['aprovedEnd'] && this.state.val2 === false) {this.setState({ id2: 'aprovedEnd', val2: true, isValidDate2: false });}
    if (!order['bookingEtaEnd'] && this.state.val3 === false) {this.setState({ id3: 'bookingEtaEnd', val3: true, isValidDate3: false });}
    if (!order['bookingEnd'] && this.state.val4 === false) {this.setState({ id4: 'bookingEnd', val4: true, isValidDate4: false });}
    if (!order['bookingRequestedEnd'] && this.state.val5 === false) {this.setState({id5: 'bookingRequestedEnd',val5: true,isValidDate5: false});}
    if (!order['bookingEtdEnd'] && this.state.val6 === false) {this.setState({ id6: 'bookingEtdEnd', val6: true, isValidDate6: false });}

    if (!order['cargoReadyStart'] &&order['cargoReadyEnd'] &&this.state.val === false) {this.setState({ id1: 'cargoReadyEnd', val: true, isValidDate: false });}
    if (!order['aprovedStart'] &&order['aprovedEnd'] &&this.state.val2 === false) {this.setState({ id2: 'aprovedEnd', val2: true, isValidDate2: false });}
    if (!order['bookingEtaStart'] &&order['bookingEtaEnd'] &&this.state.val3 === false) {this.setState({ id3: 'bookingEtaEnd', val3: true, isValidDate3: false });}
    if (!order['bookingStart'] &&order['bookingEnd'] &&this.state.val4 === false) {this.setState({ id4: 'bookingEnd', val4: true, isValidDate4: false });}
    if (!order['bookingRequestedStart'] &&order['bookingRequestedEnd'] &&this.state.val5 === false) {this.setState({id5: 'bookingRequestedEnd',val5: true,isValidDate5: false});}
    if (!order['bookingEtdStart'] && order['bookingEtdEnd'] && this.state.val6 === false) {this.setState({ id6: 'bookingEtdEnd', val6: true, isValidDate6: false });}

  };

  validateInputTime = event => {
    const {order } = this.state;

    FormModel[1]._subSectionContent.map( h => {

      h.input.map(j => {
        /* ================================= BOOKINGS VALIDATE DATE ===============================================*/
        if (order['cargoReadyStart']) {
          if (j.id === 'cargoReadyEnd' && order['cargoReadyEnd']) {
            const validCurrentTime =
              order['cargoReadyEnd'] >= order['cargoReadyStart'];
            this.setState({
              id: 'cargoReadyEnd',
              val: validCurrentTime,
              isValidDate: !validCurrentTime
            });
          }
        }
        if (order['aprovedStart']) {
          if (j.id === 'aprovedEnd' && order['aprovedEnd']) {
            const validCurrentTime =
              order['aprovedEnd'] >= order['aprovedStart'];
            this.setState({
              id2: 'aprovedEnd',
              val2: validCurrentTime,
              isValidDate2: !validCurrentTime
            });
          }
        }
        if (order['bookingEtaStart']) {
          if (j.id === 'bookingEtaEnd' && order['bookingEtaEnd']) {
            const validCurrentTime =
              order['bookingEtaEnd'] >= order['bookingEtaStart'];
            this.setState({
              id3: 'bookingEtaEnd',
              val3: validCurrentTime,
              isValidDate3: !validCurrentTime
            });
          }
        }
        if (order['bookingStart']) {
          if (j.id === 'bookingEnd' && order['bookingEnd']) {
            const validCurrentTime =
              order['bookingEnd'] >= order['bookingStart'];
            this.setState({
              id4: 'bookingEnd',
              val4: validCurrentTime,
              isValidDate4: !validCurrentTime
            });
          }
        }
        if (order['bookingRequestedStart']) {
          if (j.id === 'bookingRequestedEnd' && order['bookingRequestedEnd']) {
            const validCurrentTime =
              order['bookingRequestedEnd'] >= order['bookingRequestedStart'];
            this.setState({
              id5: 'bookingRequestedEnd',
              val5: validCurrentTime,
              isValidDate5: !validCurrentTime
            });
          }
        }
        if (order['bookingEtdStart']) {
          if (j.id === 'bookingEtdEnd' && order['bookingEtdEnd']) {
            const validCurrentTime =
              order['bookingEtdEnd'] >= order['bookingEtdStart'];
            this.setState({
              id6: 'bookingEtdEnd',
              val6: validCurrentTime,
              isValidDate6: !validCurrentTime
            });
          }
        }
        return j;
      });
      return h;
    });
  };

  removeFilters = () => {
    this.props.onResults(this.state.order = {});
    this.setState({ order: {}, 
      id: false, id2: false, id3: false, id4: false, id5: false, id6: false, 
      isValidDate: false, isValidDate2: false, isValidDate3: false, isValidDate4: false, isValidDate5: false, isValidDate36: false
    });
  };
  
  render(){

    const { selects } = this.props;
    const bookingLayout = FormModel[1];
    const { order } = this.state;

    return (
      <Grid celled="internally">
        <Grid.Row>
          <Grid.Column width={11}>
            <Col className="col-md-12" key='booking-information'>
              <center>
                <h4 className="mb-3 py-2">{bookingLayout._section[0].label}</h4>
              </center>
            </Col>
  
            <Grid.Row>
              {bookingLayout._sectionContent.map((e, i) => {
                if (e.type === 'Select')
                  return (
                    <div className={e.class} key={i}>
                      <Label>{e.label}</Label>
                      <Select
                        id={e.name}
                        type={e.type}
                        name={e.name}
                        placeholder=""
                        value={order[e.name]}
                        onChange={this.handleChange(e.name)}
                        options={selects[e.options] }
                      />
                      <Label />
                    </div>
                  );
                else if (e.type === 'Input')
                  return (
                    <div className={e.class} key={i}>
                      <Label>{e.label}</Label>
                      <Input
                        id={e.name}
                        name={e.name}
                        value={order[e.name] ? order[e.name] : ''}
                        onChange={this.handeInputData(e.name)}
                      />
                      <Label />
                    </div>
                  );
                else return '';
              })}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={5}>
            <Grid.Row>
              <Col className="col-md-1" />
              <Col className="col-md-19" key='po-information'>
                <center>
                  <h4 className="mb-3 py-2">{bookingLayout._section[2].label}</h4>
                </center>
              </Col>
  
              <Col className="col-md-1" key='remove-filter'>
                <Icon.Group size="large" className="alignright">
                  <img
                    alt="filter"
                    className="ui filter body"
                    src={filterIcon}
                    onClick={this.removeFilters}
                  />
                  <Icon corner name="cancel" onClick={this.removeFilters} />
                </Icon.Group>
              </Col>
            </Grid.Row>
  
            <Grid.Row>
              {bookingLayout._sectionContent.map((e, i) => {
                if (e.type === 'poInformationSelect')
                  return (
                    <div className={e.class} key={i}>
                      <Label>{e.label}</Label>
                      <Select
                        id={e.name}
                        type={e.section}
                        name={e.name}
                        placeholder=""
                        value={order[e.name]}
                        onChange={this.handleChange(e.name)}
                        options={selects[e.options]}
                      />
                      <Label />
                    </div>
                  );
                else if (e.type === 'poInformationInput')
                  return (
                    <div className={e.class} key={i}>
                      <Label>{e.label}</Label>
                      <Input
                        id={e.name}
                        name={e.name}
                        value={order[e.name] ? order[e.name] : ''}
                        onChange={this.handeInputData(e.name)}
                      />
                      <Label />
                    </div>
                  );
                else return '';
              })}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
              <Col className={bookingLayout._subSection[0].class} key={'un'}>
                <center>
                  <h4 className="mb-3 py-2">{bookingLayout._subSection[0].label}</h4>
                </center>
              </Col>
            </Grid.Row>
  
            <Grid.Row>
              {bookingLayout._subSectionContent.map((e, i) => {
                if (e.type === 'Datetime') {
                  return (
                    <Col className={'col-md-4'} key={i}>
                      <Label
                        style={{ display: 'block', textAlign: 'center' }}
                        id={i}
                      >
                        {e.label}
                      </Label>
                      <Row>
                        {e.input.map((h, j) => {
                          if (e.type === 'Datetime')
                            return (
                              <Col xs={h.size} key={j}>
                                <Datetime
                                  id={h.id}
                                  type={e.type}
                                  name={h.id}
                                  value={order[h.id]}
                                  dateFormat={'YYYY-MM-DD'}
                                  onChange={this.handleChangeDates(h.id)}
                                />
                                <span
                                  id={h.id}
                                  style={{ color: 'rgb(229, 115, 115)' }}
                                >
                                  <Label>
                                    {this.state.id === h.id
                                      ? this.state.val
                                        ? ''
                                        : 'invalid range'
                                      : '' || this.state.id2 === h.id
                                        ? this.state.val2
                                          ? ''
                                          : 'invalid range'
                                        : '' || this.state.id3 === h.id
                                          ? this.state.val3
                                            ? ''
                                            : 'invalid range'
                                          : '' || this.state.id4 === h.id
                                            ? this.state.val4
                                              ? ''
                                              : 'invalid range'
                                            : '' || this.state.id5 === h.id
                                              ? this.state.val5
                                                ? ''
                                                : 'invalid range'
                                              : '' || this.state.id6 === h.id
                                                ? this.state.val6
                                                  ? ''
                                                  : 'invalid range'
                                                : ''}
                                  </Label>
                                </span>
                              </Col>
                            );
                          else return '';
                        })}
                      </Row>
                    </Col>
                  );
                } else return '';
              })}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};
