import React from 'react';
import propTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

function TableHeader({ columns, sortKey, selectItems, handleSort, direction, style }) {
  let _newColumns = [];
  columns.forEach((column, index) => {
    if (column.sort) {
      if (column.key === sortKey) {
        _newColumns.push(
          direction === 'desc' ?
            <th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index} onClick={() => handleSort(column.key, 'asc')} ><Fade left opposite ><em className="icon-arrow-down mr-2"></em></Fade>{column.label}</th>
            : <th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index} onClick={() => handleSort(column.key, 'desc')}><Fade left opposite ><em className="icon-arrow-up mr-2"></em></Fade>{column.label}</th>
        );
      } else {
        _newColumns.push(<th className={column.fixed ? 'col-fixed' : ''} key={index} id={column.idTh ? column.idTh : index} onClick={() => handleSort(column.key, 'asc')} ><Fade left opposite ><em className="icon-arrow-down mr-2"></em></Fade>{column.label}</th>);
      }
    } else {
      _newColumns.push(
        <th className={style ? style.tagSubTitle : ''} key={index} id={column.idTh ? column.idTh : index}> <div className='thead-div' style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} >{column.label}</div></th>
      );
    }
  });

  return (
    <React.Fragment>
      {
        selectItems ?
          <th> </th> :
          ''
      }
      {_newColumns}
    </React.Fragment>
  );
}

TableHeader.propTypes = {
  columns: propTypes.array,
  sortKey: propTypes.string,
  direction: propTypes.oneOf(['desc', 'asc']),
  selectItems: propTypes.bool,
  handleSort: propTypes.func
};
TableHeader.defaultProps = {
  columns: [],
  sortKey: '',
  direction: 'desc',
  selectItems: false,
  handleSort: () => { }
};
export default TableHeader;