import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { Card, CardBody } from "reactstrap";
import Loader from "./../../../../../Global/Loader";
import { withRouter } from "react-router-dom";
import ContainerWrapper from "../../../../../template/Layout/ContentWrapper";
import "./../../styles/styles.scss";
import CustomEntities from "../CustomEntities";
import { TemplateOrderService, TemplatePODetail } from "../../../template";
import ListItem from "./components/listItem";
import moment from "moment";
import FormCard from "../Forms";
import { message } from "antd";

class poSumaryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.formData,
      isMobil: false,
      purchaseOrderexpanded: false,
      data: this.props.formData,
      steps: this.props.steps,
      isEditMode: false,
      purchaseOrderId: this.props.match.params.id || -1,
      createFormsData: [],
      generalInfo: [],
      poInfo: {},
      editablePo: {},
      info: this.props.info || {},
      unEditedInfo: {},
      selectCatalog: [],
      tablaClientes: "",
      pickupAddressInput: true,
      deliverAddressInput: true,
      embarqueInfo: [],
      dateOpen: [],
      changeValues: false,
      loadingInfo: true,
      loadingTmpInfo: false,
      panelEmbarque: true,
      detailExpanded: true,
      resumeExpanded: true,
      poItemsExpanded: true,
      entityValidForm: false,
      infoValidForm: false,
      detailValidForm: false,
      containerColumns: [
        {
          label: "Container No.",
          key: "ContainerNo",
          minWidth: 310,
          maxWidth: 310,
        },
        {
          label: "Seal",
          key: "Seal",
          minWidth: 310,
          maxWidth: 310,
        },
        {
          label: "Container type",
          key: "ContainerType",
          minWidth: 310,
          maxWidth: 310,
        },
      ],
      search: "",
    };
  }

  componentDidMount = async () => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    this.setState({
      isMobil: isMobileDevice,
    });

    this.setState({
      loadingInfo: false,
      poInfo: this.props.poInfo || {},
      poInfoBeforeUpdate: this.props.poInfo || {},
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.poInfo !== prevProps.poInfo) {
      this.setState({
        poInfo: this.props.poInfo || {},
        loadingInfo: false,
      });
    }
  }

  editPurchaseOrder = () => {
    this.setState({ isEditMode: true });
  };
  cancelEdit = () => {
    this.setState({ isEditMode: false });
  };

  putPurchaseOrder = () => {
    // validar si los campos requeridos estan llenos
    let { poInfo, editablePo, changeValues } = this.state;
    let update = { ...poInfo, ...editablePo };
    let isValid = true;
    TemplateOrderService.edit.forEach((field) => {
      if (field.required && !update[field.id]) {
        isValid = false;
      }
    });
    TemplatePODetail.edit.forEach((field) => {
      if (field.required && !update[field.id]) {
        isValid = false;
      }
    });
    if (
      update.ConsigneeId &&
      (!update.ConsigneeContact || !update.ConsigneeAddress)
    ) {
      isValid = false;
    }
    if (
      update.SupplierId &&
      (!update.SupplierContact || !update.SupplierAddress)
    ) {
      isValid = false;
    }
    if (
      update.DeliveryId &&
      (!update.DeliveryContact || !update.DeliveryAddress)
    ) {
      isValid = false;
    }

    if (!isValid) {
      if (changeValues) {
        message.error("Please add all the mandatory fields");
        this.setState({ changeValues: false });
      }
      return;
    }
    this.setState({ isEditMode: false });
    this.props.actualiza(update);
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.props.updateFormData({
      ...this.props.formData,
      [name]: value,
    });
  };

  panelChange = (id) => (evt, expanded) => {
    if (id === "items") {
      this.setState({ poItemsExpanded: expanded });
    } else if (id === "resume") {
      this.setState({ resumeExpanded: expanded });
    } else if (id === "detail") {
      this.setState({ detailExpanded: expanded });
    } else {
      let { createFormsData, generalInfo } = this.state;
      createFormsData.forEach((form) => {
        if (form.id === id) {
          form.expanded = expanded;
        }
      });
      generalInfo.forEach((form) => {
        if (form.id === id) {
          form.expanded = expanded;
        }
      });
      this.setState({ createFormsData });
    }
  };

  updateEntityInfo = (id, value) => {
    let { editablePo } = this.state;
    editablePo[id] = value;
    this.setState({ editablePo, changeValues: true });
  };

  setOrderSearch = (name, value) => {
    this.setState(
      {
        search: value,
        activeInvoicePage: 1,
        numberPageInvoice: 1,
      },
      () => {
        // this.getOrdersList();
      }
    );
  };

  formatDate = (value, format) => {
    if (!format) return value;
    const parsedDate = moment(value, format);
    if (parsedDate.isValid()) {
      return parsedDate.format(format);
    } else {
      return value;
    }
  };

  render() {
    const { loadingInfo, poInfo, isMobil, isEditMode } = this.state;

    if (loadingInfo) {
      return <Loader />;
    }

    return (
      <ContainerWrapper>
        {(isEditMode ? (
          <div className="row col-12">
            <div className="col-6"></div>
            <div
              className="col-6"
              style={{ marginTop: isMobil ? "" : "-70px" }}
            >
              <button
                className="btn btn-primary float-right"
                style={{ marginBottom: "5px" }}
                onClick={() => this.cancelEdit()}
              >
                Update
              </button>
              <button
                style={{ marginBottom: "5px", marginRight: "10px" }}
                className="btn btn-secondary float-right"
                onClick={() => {
                  this.cancelEdit();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="row col-12">
            <div className="col-6"></div>
            <div
              className="col-6"
              style={{ marginTop: isMobil ? "" : "-70px" }}
            >
              <button
                className="btn btn-primary float-right"
                style={{ marginBottom: "5px" }}
                onClick={() => this.editPurchaseOrder()}
              >
                Edit
              </button>
            </div>
          </div>
        ))}
        <div>
          <Card className="myCard">
            <CardBody>
              {!isMobil ? (
                <div className="row">
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      paddingLeft: "4%",
                    }}
                    className="col-xs-4 col-sm-2 col-lg-2 col-md-2"
                  >
                    <h4 className="label-euro">{poInfo.PONumber}</h4>
                  </div>
                  <div className="col-xs-3 col-sm-1 col-lg-2">
                    <span>{poInfo.POInternalNumber}</span>
                  </div>
                  <div className="col-xs-5 col-sm-3 col-lg-3">
                    <span class="badge badge-euro">{poInfo.Status}</span>
                  </div>
                  {/* // !if -- data.ParentPOId ?  */}
                  <div
                    className="col-xs-12 col-sm-6 col-lg-5 divEnd "
                    style={{ alignItems: "center", paddingRight: "2em" }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginLeft: "1em",
                      }}
                      className=""
                    >
                      <h5 className="date_header label-euro" style={{margin: 0}}>
                        <em className="label_title bold">
                          Update date:{" "}
                        </em>
                      </h5>
                      <span
                        className="label-euro"
                        style={{ marginLeft: "5px" }}
                      >
                        {poInfo.UpdateDate ? poInfo.UpdateDate : ""}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginLeft: "1em",
                      }}
                      className=""
                    >
                      <h5 className="date_header label-euro" style={{margin: 0}}>
                        <em className="label_title bold">
                          User:{" "}
                        </em>
                      </h5>
                      <span
                        className="label-euro"
                        style={{ marginLeft: "5px" }}
                      >
                        {poInfo.UserUpdate || ""}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-xs-12 row">
                  {/* <div className="col-xs-12 row" > */}
                  <div
                    style={{ display: "inline-flex", alignItems: "center" }}
                    className="col-xs-6 "
                  >
                    <h4 className="label-euro">{poInfo.PONumber}</h4>
                  </div>
                  <div
                    className="col-xs-6"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <span class="badge badge-euro">{poInfo.Status}</span>
                  </div>
                  {/* </div> */}
                  {/* <div className="col-xs-12 row" style={{ display: "inline-flex", alignItems: "center" }}  > */}
                  <div
                    className="col-xs-6"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <h5 className="date_header label-euro">
                      <em className="label_title">
                        {" "}
                        Update date:{" "}
                      </em>
                    </h5>
                  </div>
                  <div
                    className="col-xs-6"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <b className="label-euro">
                      {poInfo.UpdateDate ? poInfo.UpdateDate : ""}
                    </b>
                  </div>
                  {/* </div> */}
                  {/* <div className="col-xs-12 row" style={{ display: "inline-flex", alignItems: "center" }}  > */}
                  <div
                    className="col-xs-6"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <h5 className="date_header label-euro">
                      <em className="label_title"> User: </em>
                    </h5>
                  </div>
                  <div
                    className="col-xs-6"
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <b className="label-euro">
                      {poInfo.userUpdate ? poInfo.userUpdate : ""}
                    </b>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
          <Card className="myCard">
            <CardBody>
              <CustomEntities
                updateEntityInfo={this.updateEntityInfo}
                isEditMode={this.state.isEditMode}
                poInfo={poInfo}
              />
            </CardBody>
          </Card>

          {this.state.generalInfo.map((general) => {
            return (
              <Card className="myCard">
                <ExpansionPanel
                  elevation={0}
                  defaultExpanded
                  onChange={this.panelChange(general.id)}
                >
                  <ExpansionPanelSummary
                    expandIcon={
                      general.expanded ? <ExpandLess /> : <ExpandMore />
                    }
                  >
                    <div
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <div style={{ marginRight: "27px" }}></div>
                      <h4 className="title-forms">
                        {" "}
                        {general.titulo}
                      </h4>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <CardBody>
                      <div
                        className="col-12"
                        style={{ marginTop: "-2em", marginLeft: "-3px" }}
                      >
                        {
                          <div>
                            <div className="row">{general.form}</div>
                          </div>
                        }
                      </div>
                    </CardBody>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Card>
            );
          })}
          <Card className="myCard">
            <ExpansionPanel
              elevation={0}
              defaultExpanded
              onChange={this.panelChange("resume")}
            >
              <ExpansionPanelSummary
                expandIcon={
                  this.state.resumeExpanded ? <ExpandLess /> : <ExpandMore />
                }
              >
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <div className="card-header">
                    <div className="card-title" style={{ fontWeight: "bold" }}>
                      Purchase order summary
                    </div>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <CardBody>
                  <div
                    className="col-12"
                    style={{ marginTop: "-2em", marginLeft: "-3px" }}
                  >
                    <div>
                      <div className="row">
                        <FormCard
                          fields={TemplateOrderService}
                          edit={isEditMode}
                          values={poInfo}
                          updateEntityInfo={this.updateEntityInfo}
                          lang={this.props.lang}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Card>
          <Card className="myCard">
            <ExpansionPanel
              elevation={0}
              defaultExpanded
              onChange={this.panelChange("detail")}
            >
              <ExpansionPanelSummary
                expandIcon={
                  this.state.detailExpanded ? <ExpandLess /> : <ExpandMore />
                }
              >
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <div className="card-header">
                    <div className="card-title" style={{ fontWeight: "bold" }}>
                      {TemplatePODetail.titulo}
                    </div>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <CardBody>
                  <div
                    className="col-12"
                    style={{ marginTop: "-2em", marginLeft: "-3px" }}
                  >
                    <div>
                      <div className="row">
                        <FormCard
                          edit={isEditMode}
                          fields={TemplatePODetail}
                          values={poInfo}
                          lang={this.props.lang}
                          updateEntityInfo={this.updateEntityInfo}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Card>
          <Card className="myCard">
            <ExpansionPanel
              elevation={0}
              defaultExpanded
              onChange={this.panelChange("items")}
            >
              <ExpansionPanelSummary
                expandIcon={
                  this.state.poItemsExpanded ? <ExpandLess /> : <ExpandMore />
                }
              >
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <div className="card-header">
                    <div className="card-title" style={{ fontWeight: "bold" }}>
                      Purchase order items
                    </div>
                  </div>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="col-12">
                  <ListItem
                    poId={this.state.purchaseOrderId}
                    user={this.props.userid}
                    updateEntityInfo={this.updateEntityInfo}
                    getPO={this.props.getPO}
                    rolId={this.props.rolId}
                    poStatus={poInfo.StatusId}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Card>
        </div>
      </ContainerWrapper>
    );
  }
}

export default withRouter(poSumaryDetail);
