import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap';

import FlotChart from './Float';
import { _chart1, _chart2, _chart3 } from './SetUp';


class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            chart1: '',
            _chart1: _chart1,
            _chart2: _chart2,
            _chart3: _chart3,
            chart2: '',
            chart3: ''
        }
    }
    componentDidMount = () => {
        let { data } = this.props
        this.setState({
            data
        }, () => {
            this.buildChart1();
            this.buildChart2();
            this.buildChart3();
        })
    }

    buildChart1 = () => {
        let { data, _chart1 } = this.state;
        let _data = []
        let months = {
            1: { val: 0, label: 'Jan' },
            2: { val: 0, label: 'Feb' },
            3: { val: 0, label: 'Mar' },
            4: { val: 0, label: 'Apr' },
            5: { val: 0, label: 'May' },
            6: { val: 0, label: 'Jun' },
            7: { val: 0, label: 'Jul' },
            8: { val: 0, label: 'Aug' },
            9: { val: 0, label: 'Sep' },
            10: { val: 0, label: 'Oct' },
            11: { val: 0, label: 'Nov' },
            12: { val: 0, label: 'Dec' },
        }


        data.map(function (e) {
            let date = e.poDate.split('/')[1] + '/' + e.poDate.split('/')[0] + '/' + e.poDate.split('/')[2]
            let month = new Date(date).getMonth() + 1
            let found = months[month]
            if (found) {
                months[month].val += parseInt(e.amount)
            }
        });

        

        Object.keys(months).forEach((e) => {
            let array = []
            array.push(months[e].label)
            array.push(months[e].val)
            _data.push(array)
        })

        _chart1.data[0].data = _data
        this.setState({
            _chart1,
            chart1: ''
        }, () => {
            this.setState({
                chart1: <FlotChart options={_chart1.options} data={_chart1.data} className="flot-chart" height="250px" />
            })
        })
    }

    buildChart2 = () => {
        let { data, _chart2 } = this.state
        let json = {}
        data.forEach((e) => {
            if (!json[e.buyer])
                json[e.buyer] = { val: 0, label: e.buyer }
        })
        data.forEach((e) => {
            let found = json[e.buyer]
            if (found) {
                json[e.buyer].val += 1
            }
        })
        let _data = []
        Object.keys(json).forEach((e) => {
            let array = []
            array.push(json[e].label)
            array.push(json[e].val)
            _data.push(array)
        })

        _data.sort(function (a, b) {
            if (a[1] < b[1]) {
                return 1;
            }
            if (a[1] > b[1]) {
                return -1;
            }
            return 0;
        });

        if (_data.length > 5)
            _data = _data.slice(0, 5)
        _chart2.data[0].data = []
        _chart2.data[0].data = _data
        this.setState({
            _chart2,
            chart2: ''
        }, () => {
            this.setState({
                chart2: <FlotChart options={_chart2.options} data={_chart2.data} className="flot-chart" height="250px" />
            })
        })
    }

    buildChart3 = () => {
        let { data, _chart3 } = this.state
        let json = {}
        data.forEach((e) => {
            if (!json[e.supplier])
                json[e.supplier] = { val: 0, label: e.supplier }
        })
        data.forEach((e) => {
            let found = json[e.supplier]
            if (found) {
                json[e.supplier].val += parseInt(e.amount)
            }
        })
        let _data = []

        Object.keys(json).forEach((e) => {
            let array = []
            array.push(json[e].label)
            array.push(json[e].val)
            _data.push(array)
        })

        _data.sort(function (a, b) {
            if (a[1] < b[1]) {
                return 1;
            }
            if (a[1] > b[1]) {
                return -1;
            }
            return 0;
        });

        if (_data.length > 5)
            _data = _data.slice(0, 5)
        _chart3.data[0].data = []
        _chart3.data[0].data = _data
        this.setState({
            _chart3,
            chart3: ''
        }, () => {
            this.setState({
                chart3: <FlotChart options={_chart3.options} data={_chart3.data} className="flot-chart" height="250px" />
            })
        })
    }


    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        }, () => {
            this.buildChart1();
            this.buildChart2();
            this.buildChart3();
        })
    }
    render() {
        return (
            <>


                <Container fluid>
                    <Row>
                        <Col lg={4}>
                            <center><h4 className="mb-3 py-2">PO´s Amount</h4></center>
                            <center>{this.state.chart1}</center>
                        </Col>
                        <Col lg={4}>
                            <center><h4 className="mb-3 py-2">Top Buyers (PO´s)</h4></center>
                            <center>{this.state.chart2}</center>
                        </Col>
                        <Col lg={4}>
                            <div >
                                <div className="row">
                                    <div className="col-12">
                                        <center><h4 className="mb-3 py-2">Top Suppliers</h4></center>
                                    </div>
                                    <div className="col-12">
                                        <center>{this.state.chart3}</center>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default DataTable;