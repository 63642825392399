const _MAXLENGHT = 15;

export const SummaryData = {
  data: [
    {
      "cruise": "NCL Insigna",
      "period": "Oct 2018",
      "year": 2018,
      "month": "October",
      "poAmount": "674281",
      "logisticCost": "269712",
      "total": "943993",
      "budget": "1038392",
      "derivation": "94399",
      "percentage": "9",
      "dryDrockProgress": "100",
      "division": "1",
      "country": "Italy"
    },
    {
      "cruise": "NCL Jewel",
      "period": "Sep 2018",
      "year": 2017,
      "month": "September",
      "poAmount": "802928",
      "logisticCost": "281024",
      "total": "1083953",
      "budget": "1029755",
      "derivation": "54197",
      "percentage": "5",
      "dryDrockProgress": "100",
      "division": "2",
      "country": "Italy"
    },
    {
      "cruise": "NCL Sky",
      "period": "Dec 2018",
      "year": 2018,
      "month": "December",
      "poAmount": "709609",
      "logisticCost": "177402",
      "total": "887011",
      "budget": "1046672",
      "derivation": "159661",
      "percentage": "-15",
      "dryDrockProgress": "90",
      "division": "3",
      "country": "Italy"
    }
  ]
};

export const SummaryModel = {
  year: '',
  month: '',
  division: '',
  cruise: '',
  country: ''
};

export const SummaryForm = {
  form: [
    {
      type: 'Input',
      label:'Year',
      id: 'year',
      name: 'year',
      class: '_inputFilters',
      placeholder: 'Year',
      maxlenght: _MAXLENGHT      
    },{
      type: 'Combo',
      label:'Month',
      id: 'month',
      name: 'month',
      class: '_inputFilters',
      placeholder: 'Month',
      data: [
        {value: 'january', label: 'January'}, 
        {value: 'february', label: 'February'}, 
        {value: 'march', label: 'March'}, 
        {value: 'april', label: 'April'}, 
        {value: 'may', label: 'May'}, 
        {value: 'june', label: 'June'}, 
        {value: 'july', label: 'July'}, 
        {value: 'august', label: 'August'}, 
        {value: 'september', label: 'September'}, 
        {value: 'october', label:'October'}, 
        {value: 'november', label: 'November'}, 
        {value: 'december', label: 'December'}],
      maxlenght: _MAXLENGHT
    },{
      type: 'Input',
      label:'Division',
      id: 'division',
      name: 'division',
      class: '_inputFilters',
      placeholder: 'Division',
      maxlenght: _MAXLENGHT      
    },{
      type: 'Input',
      label:'Cruise',
      id: 'cruise',
      name: 'cruise',
      class: '_inputFilters',
      placeholder: 'Cruise',
      maxlenght: _MAXLENGHT      
    },{
      type: 'Input',
      label:'Country',
      id: 'country',
      name: 'country',
      class: '_inputFilters',
      placeholder: 'Country',
      maxlenght: _MAXLENGHT      
    }
  ]
};