import React from 'react';
import { Pagination } from 'semantic-ui-react';
// import { Pagination } from 'antd';
function TablePagination({ activePage, onPageChange, totalPages }) {
    // console.log("🚀 ~ file: index.js:5 ~ TablePagination ~ activePage, onPageChange, totalPages:", activePage, onPageChange, totalPages)
    return (
        <React.Fragment>
            <Pagination
                // firstItem={null}
                // lastItem={null}
                activePage={activePage}
                onPageChange={onPageChange}
                totalPages={totalPages}
                size='mini'
            />

            {/* <Pagination
                // defaultCurrent={1}
                className='ant-pagination'
                current={activePage}
                onChange={(page, pageSize) => { onPageChange(page); }}
                total={totalPages}
                defaultPageSize={1}
                responsive={true}
                style={{ borderColor: 'red', border: 'red' }}
            /> */}
        </React.Fragment>

    );
}

export default TablePagination;
