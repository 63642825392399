export const poByStatus = [
    [
        "Element", "Total", { role: "style" }, { sourceColumn: 0, role: "annotation", type: "string", calc: "stringify", },
    ],
    ["Pending to confirm", 8.94, "#01579b", null],
    ["Confirmed", 10.49, "#0277bd", null],
    ["Booked", 19.3, "#0288d1", null],
    ["Shipped", 21.45, "#039be5", null],
    ["Arrived", 21.45, "#03a9f4", null],
    ["Delivered", 21.45, "#29b6f6", null],
    ["Cancelled", 21.45, "#4fc3f7", null],
]

// export const posByCreationDate = [
//     ["Total", "PO"],
//     ['Jan', Math.floor(Math.random() * 600)],
//     ['Feb', Math.floor(Math.random() * 600)],
//     ['Mar', Math.floor(Math.random() * 600)],
//     ['Apr', Math.floor(Math.random() * 600)],
//     ['May', Math.floor(Math.random() * 600)],
//     ['Jun', Math.floor(Math.random() * 600)],
//     ['Jul', Math.floor(Math.random() * 600)],
//     ['Aug', Math.floor(Math.random() * 600)],
//     ['Sep', Math.floor(Math.random() * 600)],
//     ['Oct', Math.floor(Math.random() * 600)],
//     ['Nov', Math.floor(Math.random() * 600)],
//     ['Dec', Math.floor(Math.random() * 600)],
// ]

export const posByCreationDate = () => {
    return ([
        ["Total", "PO"],
        ['Jan', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Feb', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Mar', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Apr', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['May', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Jun', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Jul', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Aug', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Sep', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Oct', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Nov', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
        ['Dec', Math.floor(Math.random() * Math.floor(Math.random() * 500))],
    ])
}

export const listPO =
    [
        {
            "POId": 10752,
            "PONumber": "LWX-136263",
            "POCreateDate": "2022-05-12",
            "StatusId": 8,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS335",
            "SupplierName": "Castellani",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-09-07",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2024-01-09",
            "TotalQtyOrdered": "750",
            "RequiresAttention": "0",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1524,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10752,
                    "Date": "2020-05-19:5:08 PM",
                    "Comment": "Winery confirmed ready date and order quantity; pick up already scheduled starting from tomorrow. ",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,228,163,62,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1517,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10752,
                    "Date": "2020-05-19:9:30 AM",
                    "Comment": "First contact made at info@agricolamarino.it. Waiting information and response",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,228,163,62,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                }
            ]
        },
        {
            "POId": 10761,
            "PONumber": "LWX-136824",
            "POCreateDate": "2022-06-28",
            "StatusId": 11,
            "StatusDesc": "Pending to confirm",
            "SupplierCode": "SUPWPS335",
            "SupplierName": "Castellani",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-09-29",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2023-07-14",
            "TotalQtyOrdered": "1625",
            "RequiresAttention": "1",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1881,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-06-11:12:50 PM",
                    "Comment": " Barbara has just confirmed to me too that they are ready as of the 12th.",
                    "User": "Helen Park",
                    "Color": "#1D5E98",
                    "UserId": 228,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",228,83,62,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1880,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-06-11:12:50 PM",
                    "Comment": " Barbara has just confirmed to me too that they are ready as of the 12th.",
                    "User": "Helen Park",
                    "Color": "#1D5E98",
                    "UserId": 228,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",228,83,62,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1882,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-06-11:12:50 PM",
                    "Comment": " Barbara has just confirmed to me too that they are ready as of the 12th.",
                    "User": "Helen Park",
                    "Color": "#1D5E98",
                    "UserId": 228,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",228,83,62,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1856,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-06-10:12:01 PM",
                    "Comment": "Not responsive, awaiting information ",
                    "User": "Helen Park",
                    "Color": "#1D5E98",
                    "UserId": 228,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",228,62,83,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1872,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-06-10:11:36 AM",
                    "Comment": "Ljiubica: Barbara De Rham has just confirmed they are ready as of the 12th. ",
                    "User": "Livia Raineri",
                    "Color": "#1D5E98",
                    "UserId": 83,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",83,228,62,",
                    "RolId": 6,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1866,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 4,
                    "ComType": "Customer Comment",
                    "RowId": 10761,
                    "Date": "2020-06-10:10:26 AM",
                    "Comment": "Per Barbara de Rham Tori <barbara@enotecaderham.it> - Ready on Friday.",
                    "User": "Gregory Prime",
                    "Color": "#00B050",
                    "UserId": 90,
                    "CommentUserType": "Customer",
                    "ReadingConfirmation": ",90,228,62,83,",
                    "RolId": 3,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1611,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-05-28:9:17 AM",
                    "Comment": "Than you Gregory, duly noted. We'll contact De Rham again around week 23",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,62,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1609,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-05-27:3:33 PM",
                    "Comment": "This order should be ready 10 June",
                    "User": "Gregory Prime",
                    "Color": "#00B050",
                    "UserId": 90,
                    "CommentUserType": "Customer",
                    "ReadingConfirmation": ",90,84,83,62,228,",
                    "RolId": 3,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1574,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-05-25:5:52 PM",
                    "Comment": "Another contact made, awaiting response and details ",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,62,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1550,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-05-22:12:18 PM",
                    "Comment": "Contacted again today. They hadn't responded yet.",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,62,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1528,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10761,
                    "Date": "2020-05-20:1:03 PM",
                    "Comment": "First contact made",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,83,62,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                }
            ]
        },
        {
            "POId": 10762,
            "PONumber": "LWX-138051",
            "POCreateDate": "2022-10-03",
            "StatusId": 9,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS335",
            "SupplierName": "Castellani",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-12-01",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2023-07-14",
            "TotalQtyOrdered": "495",
            "RequiresAttention": "1",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1547,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10762,
                    "Date": "2020-05-21:2:35 PM",
                    "Comment": "Pickup scheduled starting from today",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,62,90,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1535,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10762,
                    "Date": "2020-05-20:3:25 PM",
                    "Comment": "First contact made - Elysa; waiting information",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,62,83,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                }
            ]
        },
        {
            "POId": 10753,
            "PONumber": "LWX-136264",
            "POCreateDate": "2022-05-12",
            "StatusId": 8,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS332",
            "SupplierName": "Medici Ermete & Figli srl",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-09-07",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2024-01-24",
            "TotalQtyOrdered": "350",
            "RequiresAttention": "0",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1548,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10753,
                    "Date": "2020-05-21:3:47 PM",
                    "Comment": "Winery confirmed the ready date starting from May 26th, next week they will give us some addiotional information",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": true
                },
                {
                    "CommentId": 1529,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10753,
                    "Date": "2020-05-20:1:15 PM",
                    "Comment": "First contact made with Monte Caro",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,83,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": true
                }
            ]
        },
        {
            "POId": 10760,
            "PONumber": "LWX-136825",
            "POCreateDate": "2022-06-28",
            "StatusId": 8,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS332",
            "SupplierName": "Medici Ermete & Figli srl",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-09-29",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2023-06-06",
            "TotalQtyOrdered": "350",
            "RequiresAttention": "0",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1569,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10760,
                    "Date": "2020-05-25:11:27 AM",
                    "Comment": "Order ready and pickup scheduled",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,228,171,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": true
                },
                {
                    "CommentId": 1551,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10760,
                    "Date": "2020-05-22:12:22 PM",
                    "Comment": "Contacted again, not responsive. Awaiting information and details",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,228,171,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": true
                },
                {
                    "CommentId": 1531,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10760,
                    "Date": "2020-05-20:1:41 PM",
                    "Comment": "First contact made",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,83,62,228,171,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                }
            ]
        },
        {
            "POId": 10759,
            "PONumber": "LWX-138052",
            "POCreateDate": "2022-10-03",
            "StatusId": 8,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS1916",
            "SupplierName": "Vite Colte s.p.a. / Terre da Vino SPA",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-12-01",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2022-10-16",
            "TotalQtyOrdered": "432",
            "RequiresAttention": "1",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1578,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10759,
                    "Date": "2020-05-26:10:54 AM",
                    "Comment": "Winery has been contacted and they confirmed readiness and goods details, pickup already scheduled",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,62,83,90,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1564,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10759,
                    "Date": "2020-05-22:10:08 AM",
                    "Comment": "WINERIES IS NOT REACHABLE THROUGH THIS EMAIL , TRYING PHONE CALLS.",
                    "User": "Livia Raineri",
                    "Color": "#1D5E98",
                    "UserId": 83,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",83,62,84,90,228,",
                    "RolId": 6,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1537,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10759,
                    "Date": "2020-05-20:3:42 PM",
                    "Comment": "Best contact found ‎trinchero@trinchero.it",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,62,83,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1532,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10759,
                    "Date": "2020-05-20:2:44 PM",
                    "Comment": "Can we have some email contact, please?\nThnak you in advance. ",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,62,83,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1540,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 4,
                    "ComType": "Customer Comment",
                    "RowId": 10759,
                    "Date": "2020-05-20:12:44 PM",
                    "Comment": "Confirming trinchero@trinchero.it is the best contact.",
                    "User": "Gregory Prime",
                    "Color": "#00B050",
                    "UserId": 90,
                    "CommentUserType": "Customer",
                    "ReadingConfirmation": ",90,62,84,83,228,",
                    "RolId": 3,
                    "CustomerId": 82,
                    "NewComment": false
                }
            ]
        },
        {
            "POId": 10755,
            "PONumber": "LWX-136265",
            "POCreateDate": "2022-05-12",
            "StatusId": 8,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS489",
            "SupplierName": "Pasqua Vigneti e Cantine Spa",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2022-09-07",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2023-06-07",
            "TotalQtyOrdered": "408",
            "RequiresAttention": "1",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": []
        },
        {
            "POId": 10757,
            "PONumber": "LWX-138050",
            "POCreateDate": "2022-10-03",
            "StatusId": 4,
            "StatusDesc": "Confirmed",
            "SupplierCode": "SUPWPS489",
            "SupplierName": "Pasqua Vigneti e Cantine Spa",
            "ConsigneeCode": "CONWPS25",
            "ConsigneeName": "Latitude Wines Inc",
            "RequiredDestination": "2023-06-13",
            "CommentsLastCall": "",
            "ActualCargoReadyDate": "2023-06-04",
            "TotalQtyOrdered": "360",
            "RequiresAttention": "0",
            "Total": "8",
            "Page": 1,
            "flatRequiere": false,
            "flatSearch": "",
            "flatAdvanced": [],
            "Comments": [
                {
                    "CommentId": 1731,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10757,
                    "Date": "2020-06-03:11:17 AM",
                    "Comment": "Gregory, Ca Liptra confirmed today that everything it's ok and they will be ready starting frrom tomorrow June 4th. P/u scheduled",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": true
                },
                {
                    "CommentId": 1726,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 4,
                    "ComType": "Customer Comment",
                    "RowId": 10757,
                    "Date": "2020-06-02:3:19 PM",
                    "Comment": "Can you try contacting again and let us know if there is a payment issue? ",
                    "User": "Gregory Prime",
                    "Color": "#00B050",
                    "UserId": 90,
                    "CommentUserType": "Customer",
                    "ReadingConfirmation": ",90,83,62,84,228,",
                    "RolId": 3,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1570,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10757,
                    "Date": "2020-05-25:12:09 PM",
                    "Comment": "Please note that Ca Liptra confirmed that they're ready but they're waiting payments from customer. ",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,62,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1552,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10757,
                    "Date": "2020-05-22:12:24 PM",
                    "Comment": "Contacted again today, awaiting details and response",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,83,90,62,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                },
                {
                    "CommentId": 1534,
                    "ModuleId": 1,
                    "Module": "Purchase Order",
                    "ComTypeId": 1,
                    "ComType": "Supplier Follow Up",
                    "RowId": 10757,
                    "Date": "2020-05-20:3:02 PM",
                    "Comment": "First contact made ",
                    "User": "Riccardo Francalanci",
                    "Color": "#1D5E98",
                    "UserId": 84,
                    "CommentUserType": "Interglobo",
                    "ReadingConfirmation": ",84,90,62,83,228,",
                    "RolId": 2,
                    "CustomerId": 82,
                    "NewComment": false
                }
            ]
        }
    ]

export const catalogs = {
    "StatusDesc": [
        {
            "key": "11",
            "text": "Pending to confirm",
            "value": "11"
        },
        {
            "key": "4, 8, 9",
            "text": "Confirmed",
            "value": "4, 8, 9"
        }
    ],
    "ConsigneeName": [
        {
            "key": "CONWPS25",
            "text": "Latitude Wines Inc",
            "value": "CONWPS25"
        }
    ],
    "SupplierName": [
        {
            "key": "SUPWPS335",
            "text": "Castellani",
            "value": "SUPWPS335"
        },
        {
            "key": "SUPWPS332",
            "text": "Medici Ermete & Figli srl",
            "value": "SUPWPS332"
        },
        {
            "key": "SUPWPS1916",
            "text": "Vite Colte s.p.a. / Terre da Vino SPA",
            "value": "SUPWPS1916"
        },
        {
            "key": "SUPWPS489",
            "text": "Pasqua Vigneti e Cantine Spa",
            "value": "SUPWPS489"
        }
    ]
}

export const poId = {
    "POId": 10752,
    "PONumber": "LWX-136263",
    "POCreateDate": "2022-05-12",
    "LastUpdateDate": "2024-01-16",
    "StatusId": 8,
    "StatusDesc": "Confirmed",
    "SupplierCode": "SUPWPS335",
    "SupplierName": "Castellani",
    "SupplierAddress": "Via del Popolo 90E Frazione Santa Lucia",
    "SupplierAddressDetails": "Pontedera, Italy, ",
    "SupplierContactId": 5173,
    "SupplierContact": "N/A",
    "SupplierContactDetails": null,
    "SupplierContactEmail": null,
    "ConsigneeCode": "CONWPS25",
    "ConsigneeName": "Latitude Wines Inc",
    "ConsigneeAddress": "551 Hartz Avenue Suite B",
    "ConsigneeAddressDetails": "Danville, CA, 94526, United States, 925-362-9751",
    "ConsigneeContactId": 59,
    "ConsigneeContact": "N/A",
    "ConsigneeContactDetails": null,
    "ConsigneeContactEmail": null,
    "DeliveryCode": "DELWPS60",
    "DeliveryName": "Trader Joe's",
    "DeliveryAddress": "WCD Stockton 990 2121 Boeing Way",
    "DeliveryAddressDetails": "Stockton, California, United States, ",
    "DeliveryContactId": 429,
    "DeliveryContact": "N/A",
    "DeliveryContactDetails": null,
    "DeliveryContactEmail": null,
    "RequiredDestination": "2022-09-07",
    "CommentsLastCall": "",
    "ActualCargoReadyDate": "2024-01-24",
    "ActualCargoPickup": "",
    "TotalQtyOrdered": "750",
    "NoPallets": null,
    "Spaces": null,
    "GrossWeight": "12001",
    "GrossWeightUnit": "kg",
    "NetWeight": "11775",
    "NetWeightUnit": "kg",
    "EquipmentType": "40' Dry",
    "Temperature": null,
    "CodIncoterm": "EXW",
    "Incoterm": "Ex Works",
    "AdditionalPOTerms": null,
    "RequiresAttention": "0",
    "CustomerId": 195,
    "ActualArrivalConsolWH": "",
    "EntryCreatedDate": "",
    "PromiseCargoReadyDate": "",
    "items": [
        {
            "POId": 10752,
            "LineNumber": 1,
            "ItemNumber": "97432",
            "ItemDescription": "Grifone Primitivo Puglia IGT Red",
            "QtyOrdered": "750.00000000",
            "QtyReceived": null,
            "QtyBooked": null,
            "QtyShipped": null,
            "QtyToReceive": null,
            "QtyConfirmed": "750",
            "UnitMeasureCode": "CAS",
            "UnitMeasure": "Case",
            "OriginCountryId": null,
            "CountryOrigin": null,
            "Comments": null,
            "CaseDescription": "12x750ML",
            "CaseQuantity": null,
            "CaseVolume": "750ML",
            "ItemValue": null,
            "ItemCurrencyId": null,
            "ItemCurrency": null,
            "Collection": null,
            "Style": null,
            "Color": null,
            "Size": null,
            "VendorCode": null,
            "QtyRemainig": "750.00000000",
            "ProductGroup": null,
            "NetWeightItem": null,
            "Urgent": "0"
        }
    ],
    "Comments": [
        {
            "CommentId": 1524,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "ComTypeId": 1,
            "ComType": "Supplier Follow Up",
            "RowId": 10752,
            "Date": "2020-05-19:5:08 PM",
            "Comment": "Winery confirmed ready date and order quantity; pick up already scheduled starting from tomorrow. ",
            "User": "Riccardo Francalanci",
            "Color": "#1D5E98",
            "UserId": 84,
            "CommentUserType": "Interglobo",
            "ReadingConfirmation": ",84,83,90,228,163,62,",
            "RolId": 2,
            "CustomerId": 82,
            "NewComment": false
        },
        {
            "CommentId": 1517,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "ComTypeId": 1,
            "ComType": "Supplier Follow Up",
            "RowId": 10752,
            "Date": "2020-05-19:9:30 AM",
            "Comment": "First contact made at info@agricolamarino.it. Waiting information and response",
            "User": "Riccardo Francalanci",
            "Color": "#1D5E98",
            "UserId": 84,
            "CommentUserType": "Interglobo",
            "ReadingConfirmation": ",84,83,90,228,163,62,",
            "RolId": 2,
            "CustomerId": 82,
            "NewComment": false
        }
    ],
    "Documents": {
        "0": {
            "DocumentId": 853,
            "ModuleId": 1,
            "DocCategoryId": 2,
            "RowId": 7576,
            "Module": "Purchase Order",
            "Name": "test",
            "Type": ".png",
            "Category": "Commercial Invoice",
            "CreationDate": "2020-05-05",
            "CreateBy": "API",
            "URL": "https://fsposeidon.blob.core.windows.net/files/7820329237659565-29textIcon.png"
        }
    }
}

export const steps = [
    {
        "name": "Order Confirmed",
        "date": "2020-06-05",
        "completed": true,
        "EventDescriptionId": 4
    },
    {
        "name": "Cargo Ready",
        "date": "2020-02-01",
        "completed": true,
        "EventDescriptionId": 6
    },
    {
        "name": "Cargo Picked-Up / Received",
        "date": "2020-06-14",
        "completed": true,
        "EventDescriptionId": 7
    },
    {
        "name": "Booking Approved",
        "date": "2020-07-01",
        "completed": true,
        "EventDescriptionId": 33
    },
    {
        "name": "Departed",
        "date": "",
        "completed": false,
        "EventDescriptionId": 62
    },
    {
        "name": "Arrived",
        "date": "",
        "completed": false,
        "EventDescriptionId": 91
    },
    {
        "name": "Arrival at Destination",
        "date": "",
        "completed": false,
        "EventDescriptionId": 125
    }
]

export const events = {
    "1": [
        {
            "EventId": 66488,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 1,
            "EventType": "Purchase Order",
            "EventDescriptionId": 1,
            "EventDescription": "Order Creation",
            "InTimeLine": "0",
            "EventDate": "2020-06-05",
            "CategoryResponsableId": 1,
            "CategoryResponsable": "Customer",
            "Status": null,
            "Name": "System",
            "Location": null,
            "UpdateDate": "2020-06-05"
        },
        {
            "EventId": 66489,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 1,
            "EventType": "Purchase Order",
            "EventDescriptionId": 4,
            "EventDescription": "Order Confirmation",
            "InTimeLine": "1",
            "EventDate": "2020-06-05",
            "CategoryResponsableId": 2,
            "CategoryResponsable": "Supplier",
            "Status": null,
            "Name": "System",
            "Location": null,
            "UpdateDate": "2020-06-05"
        },
        {
            "EventId": 407152,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 1,
            "EventType": "Purchase Order",
            "EventDescriptionId": 5,
            "EventDescription": "Promised Cargo Ready",
            "InTimeLine": "0",
            "EventDate": "2020-04-01",
            "CategoryResponsableId": 2,
            "CategoryResponsable": "Supplier",
            "Status": null,
            "Name": "System",
            "Location": null,
            "UpdateDate": "2023-03-28"
        },
        {
            "EventId": 407151,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 1,
            "EventType": "Purchase Order",
            "EventDescriptionId": 6,
            "EventDescription": "Cargo Ready",
            "InTimeLine": "1",
            "EventDate": "2020-02-01",
            "CategoryResponsableId": 2,
            "CategoryResponsable": "Supplier",
            "Status": null,
            "Name": "System",
            "Location": null,
            "UpdateDate": "2023-03-28"
        },
        {
            "EventId": 407150,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 1,
            "EventType": "Purchase Order",
            "EventDescriptionId": 7,
            "EventDescription": "Cargo Picked-Up/Received",
            "InTimeLine": "1",
            "EventDate": "2020-06-14",
            "CategoryResponsableId": 6,
            "CategoryResponsable": "3PL",
            "Status": null,
            "Name": "System",
            "Location": null,
            "UpdateDate": "2023-03-28"
        },
        {
            "EventId": 407149,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 1,
            "EventType": "Purchase Order",
            "EventDescriptionId": 9,
            "EventDescription": "Actual Arrival at Consol WH",
            "InTimeLine": "0",
            "EventDate": "2023-03-28",
            "CategoryResponsableId": 6,
            "CategoryResponsable": "3PL",
            "Status": null,
            "Name": "System",
            "Location": null,
            "UpdateDate": "2023-03-28"
        }
    ],
    "2": [
        {
            "EventId": 82552,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 2,
            "EventType": "Booking",
            "EventDescriptionId": 32,
            "EventDescription": "Order Booked",
            "InTimeLine": "1",
            "EventDate": "2020-07-01",
            "CategoryResponsableId": 1,
            "CategoryResponsable": "Customer",
            "Status": "On Time",
            "Name": "System",
            "Location": "Mexico",
            "UpdateDate": "2020-07-01"
        },
        {
            "EventId": 82553,
            "RowId": 11687,
            "ModuleId": 1,
            "Module": "Purchase Order",
            "EventTypeId": 2,
            "EventType": "Booking",
            "EventDescriptionId": 33,
            "EventDescription": "Booking Approval",
            "InTimeLine": "0",
            "EventDate": "2020-07-01",
            "CategoryResponsableId": 1,
            "CategoryResponsable": "Customer",
            "Status": "On Time",
            "Name": "System",
            "Location": "Mexico",
            "UpdateDate": "2020-07-01"
        }
    ],
    "3": [],
    "4": [],
    "5": []

}
