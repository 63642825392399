export const poDetailResponse = {
  POId: 406,
  POInternalNumber: "PO-2024-00023",
  PONumber: "PO-FADB-00045",
  StatusId: 43,
  Status: "Parcialmente recolectada ",
  SupplierId: 73,
  SupplierCode: "SUP0039",
  SupplierName: "TEST SHIPPER",
  SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
  CustomerReference: "6852",
  ConsigneeId: 74,
  ConsigneeCode: "CON003",
  ConsigneeName: "TEST CONSIGNEE",
  ConsigneeAddress: "123 Street",
  DeliveryId: 58,
  DeliveryCode: "DEL0120",
  DeliveryName: "Delivery Name",
  DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
  Incoterms: null,
  ProductType: "Juguete",
  Division: "Consumo",
  TransportModeId: null,
  TransportMode: null,
  POL: "Mexico",
  POD: null,
  Project: null,
  WMSReference: null,
  Buyer: "Roberto Martinez",
  TotalQTYOrdered: 90,
  TotalQTYConfirmed: 16,
  TotalQTYUOM: "Unit",
  OrderValue: "60.52",
  OrderCurrency: "USD",
  GrossWeight: "47.21",
  GrossWeightUOM: "Kg",
  NetWeight: "45.30",
  NetWeightUOM: "Kg",
  NoPallets: null,
  PalletsType: null,
  Description: null,
  SpecialInstructions: null,
  AdditionalTerms: null,
  ContainerReference: null,
  PickupAddress:
    "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
  CreateDate: "19-01-2024",
  UpdateDate: "26-01-2024",
  RequiredCargoReadyDate: "15-10-2023",
  RequiredDestinationDate: "15-10-2023",
  PriorityPO: "0",
  CustomerId: 134,
  CustomerName: "CLIENTE DE PRUEBA",
  SupplierNameEuro: "TEST SHIPPER",
  SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
  ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
  ConsigneeAddressEuro: "123 Main St, CP: 64820",
  DeliveryNameEuro: null,
  DeliveryAddressEuro: null,
  SupplierContact: "Supplier Contact Name ",
  ConsigneeContact: "Consignee Contact Name ",
  DeliveryContact: "Delivery Contact Name",
  RequiresAttention: null,
  UserUpdateId: 1,
  UserUpdate: "test test",
  DeliveryAddressPO: "calle viva esperanza 234 chile, providencia chile",
  POCreationDate: "2023-10-21T00:00:00.000Z",
  GrossWeightUOMDescription: "Kilogramos",
  NetWeightUOMDescription: "Kilogramos",
  TotalQTYUOMDescription: "Unidades",
};

export const poDetailCatalogsResponse = {
  incoterms: [
    {
      value: "CFR",
      label: "Cost and Freight",
    },
    {
      value: "CIF",
      label: "Cost, Insurance and Freight",
    },
    {
      value: "CIP",
      label: "Carriage and Insurance Paid To",
    },
    {
      value: "CPT",
      label: "Carriage Paid To",
    },
    {
      value: "DAP",
      label: "Delivered At Place",
    },
    {
      value: "DAT",
      label: "Delivered At Terminal",
    },
    {
      value: "DDP",
      label: "Delivered Duty Paid",
    },
    {
      value: "EXW",
      label: "Ex Works",
    },
    {
      value: "FAS",
      label: "Free Alongside Ship",
    },
    {
      value: "FCA",
      label: "Free Carrier",
    },
    {
      value: "FOB",
      label: "Free On Board",
    },
    {
      value: "DPU",
      label: "Delivery Place Unloaded",
    },
  ],
  uom: [
    {
      value: "Boxes",
      label: "Cajas",
      Type: "Q",
    },
    {
      value: "UN",
      label: "Unidades",
      Type: "Q",
    },
    {
      value: "PZ",
      label: "Piezas",
      Type: "Q",
    },
    {
      value: "CM",
      label: "Centimetros",
      Type: "D",
    },
    {
      value: "M2",
      label: "Metros Cuadrados",
      Type: "O",
    },
    {
      value: "GR",
      label: "Gramos",
      Type: "W",
    },
    {
      value: "TON",
      label: "Toneladas",
      Type: "W",
    },
    {
      value: "lbs",
      label: "Libras",
      Type: "W",
    },
    {
      value: "KG",
      label: "Kilogramos",
      Type: "W",
    },
    {
      value: "OZ",
      label: "Onza",
      Type: "W",
    },
    {
      value: "MG",
      label: "Miligramos",
      Type: "W",
    },
    {
      value: "M3",
      label: "Metro cubico",
      Type: "V",
    },
    {
      value: "Cont",
      label: "Contenedores",
      Type: "Q",
    },
    {
      value: "Cylnd",
      label: "Cilindros",
      Type: "Q",
    },
    {
      value: "Other",
      label: "Otros",
      Type: "Q",
    },
    {
      value: "Packa",
      label: "Paquetes",
      Type: "Q",
    },
    {
      value: "Rolls",
      label: "Rollos",
      Type: "Q",
    },
    {
      value: "Spool",
      label: "Carretes",
      Type: "Q",
    },
    {
      value: "Truck",
      label: "Camiones",
      Type: "Q",
    },
    {
      value: "CubiF",
      label: "Pie Cubico",
      Type: "V",
    },
    {
      value: "CubiL",
      label: "Pulgada Cubica",
      Type: "V",
    },
    {
      value: "CubiC",
      label: "Centimetros cubicos",
      Type: "V",
    },
    {
      value: "Gllns",
      label: "Galones",
      Type: "V",
    },
    {
      value: "Liter",
      label: "Litros",
      Type: "V",
    },
    {
      value: "M",
      label: "Metros",
      Type: "D",
    },
    {
      value: "WorkH",
      label: "Horas",
      Type: "T",
    },
    {
      value: "Bags",
      label: "Bolsas",
      Type: "Q",
    },
    {
      value: "Brrls",
      label: "Barriles",
      Type: "Q",
    },
    {
      value: "IN",
      label: "Pulgadas",
      Type: "D",
    },
    {
      value: "FT",
      label: "Pies",
      Type: "D",
    },
    {
      value: "MS",
      label: "Milisegundo",
      Type: "O",
    },
    {
      value: "CPU",
      label: "CPU",
      Type: "O",
    },
    {
      value: "Core",
      label: "Core",
      Type: "O",
    },
    {
      value: "Pllts",
      label: "Pallets",
      Type: "Q",
    },
    {
      value: "PCK",
      label: "Empaques",
      Type: "Q",
    },
  ],
  transportModes: [
    {
      value: 3,
      label: "Road",
    },
    {
      value: 1,
      label: "Sea",
    },
    {
      value: 2,
      label: "Air",
    },
  ],
  uomQ: [
    {
      value: "Boxes",
      label: "Cajas",
      Type: "Q",
    },
    {
      value: "UN",
      label: "Unidades",
      Type: "Q",
    },
    {
      value: "PZ",
      label: "Piezas",
      Type: "Q",
    },
    {
      value: "Cont",
      label: "Contenedores",
      Type: "Q",
    },
    {
      value: "Cylnd",
      label: "Cilindros",
      Type: "Q",
    },
    {
      value: "Other",
      label: "Otros",
      Type: "Q",
    },
    {
      value: "Packa",
      label: "Paquetes",
      Type: "Q",
    },
    {
      value: "Rolls",
      label: "Rollos",
      Type: "Q",
    },
    {
      value: "Spool",
      label: "Carretes",
      Type: "Q",
    },
    {
      value: "Truck",
      label: "Camiones",
      Type: "Q",
    },
    {
      value: "Bags",
      label: "Bolsas",
      Type: "Q",
    },
    {
      value: "Brrls",
      label: "Barriles",
      Type: "Q",
    },
    {
      value: "Pllts",
      label: "Pallets",
      Type: "Q",
    },
    {
      value: "PCK",
      label: "Empaques",
      Type: "Q",
    },
  ],
  uomW: [
    {
      value: "GR",
      label: "Gramos",
      Type: "W",
    },
    {
      value: "TON",
      label: "Toneladas",
      Type: "W",
    },
    {
      value: "lbs",
      label: "Libras",
      Type: "W",
    },
    {
      value: "KG",
      label: "Kilogramos",
      Type: "W",
    },
    {
      value: "OZ",
      label: "Onza",
      Type: "W",
    },
    {
      value: "MG",
      label: "Miligramos",
      Type: "W",
    },
  ],
  uomD: [
    {
      value: "CM",
      label: "Centimetros",
      Type: "D",
    },
    {
      value: "M",
      label: "Metros",
      Type: "D",
    },
    {
      value: "IN",
      label: "Pulgadas",
      Type: "D",
    },
    {
      value: "FT",
      label: "Pies",
      Type: "D",
    },
  ],
  uomT: [
    {
      value: "WorkH",
      label: "Horas",
      Type: "T",
    },
  ],
  uomO: [
    {
      value: "M2",
      label: "Metros Cuadrados",
      Type: "O",
    },
    {
      value: "MS",
      label: "Milisegundo",
      Type: "O",
    },
    {
      value: "CPU",
      label: "CPU",
      Type: "O",
    },
    {
      value: "Core",
      label: "Core",
      Type: "O",
    },
  ],
  uomV: [
    {
      value: "M3",
      label: "Metro cubico",
      Type: "V",
    },
    {
      value: "CubiF",
      label: "Pie Cubico",
      Type: "V",
    },
    {
      value: "CubiL",
      label: "Pulgada Cubica",
      Type: "V",
    },
    {
      value: "CubiC",
      label: "Centimetros cubicos",
      Type: "V",
    },
    {
      value: "Gllns",
      label: "Galones",
      Type: "V",
    },
    {
      value: "Liter",
      label: "Litros",
      Type: "V",
    },
  ],
};

export const poDetailItemsResponse = [
  {
    POItemsId: 798,
    POId: 406,
    LineNo: "001",
    LineID: "1",
    StatusId: 48,
    Status: "Confirmado",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 16,
    QTYConfirmed: 16,
    UOMCode: "PZ",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "lbs",
    NetWeight: "29",
    NetWeightUOM: "lbs",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "19-01-2024",
    UpdateDate: "19-01-2024",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "15-10-2023",
    ActualCargoReady: "",
    EstimatedPickedUp: "",
    ActualCargoPickup: "",
    RequestedExWorks: "15-10-2023",
    ActualArrivalConsolWH: "",
    FinalDestination: "",
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: "",
    POInternalNumber: "PO-2024-00023",
    PONumber: "PO-FADB-00045",
    StatusIdPo: 43,
    StatusPo: "Parcialmente recolectada ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: 16,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "47.21",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "45.30",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "19-01-2024",
    UpdateDatePO: "26-01-2024",
    RequiredCargoReadyDate: "15-10-2023",
    RequiredDestinationDate: "15-10-2023",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Libras",
    ItemNetWeightUOMDescription: "Libras",
    ItemUOMDescription: "Piezas",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
  },
  {
    POItemsId: 800,
    POId: 406,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 14,
    QTYConfirmed: null,
    UOMCode: "PZ",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "lbs",
    NetWeight: "29",
    NetWeightUOM: "lbs",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "26-01-2024",
    UpdateDate: "26-01-2024",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: "",
    ActualCargoPickup: "",
    RequestedExWorks: "15-10-2023",
    ActualArrivalConsolWH: "",
    FinalDestination: "",
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: "",
    POInternalNumber: "PO-2024-00023",
    PONumber: "PO-FADB-00045",
    StatusIdPo: 43,
    StatusPo: "Parcialmente recolectada ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: 16,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "47.21",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "45.30",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "19-01-2024",
    UpdateDatePO: "26-01-2024",
    RequiredCargoReadyDate: "15-10-2023",
    RequiredDestinationDate: "15-10-2023",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Libras",
    ItemNetWeightUOMDescription: "Libras",
    ItemUOMDescription: "Piezas",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
  },
  {
    POItemsId: 799,
    POId: 406,
    LineNo: "002",
    LineID: "2",
    StatusId: 54,
    Status: "Recolección/ recepción",
    ItemNo: "ITEM2538",
    Description: "Juguetes de plastico",
    QTYOrdered: 60,
    QTYConfirmed: null,
    UOMCode: "Bags",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "20",
    GrossWeightUOM: "KG",
    NetWeight: "19",
    NetWeightUOM: "KG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "19-01-2024",
    UpdateDate: "19-01-2024",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "25-01-2024",
    ActualCargoReady: "",
    EstimatedPickedUp: "",
    ActualCargoPickup: "01-12-2023",
    RequestedExWorks: "15-10-2023",
    ActualArrivalConsolWH: "",
    FinalDestination: "",
    PriorityItem: "0",
    HSCode: "HS2584",
    DateDeparturePortAirport: "",
    POInternalNumber: "PO-2024-00023",
    PONumber: "PO-FADB-00045",
    StatusIdPo: 43,
    StatusPo: "Parcialmente recolectada ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: 16,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "47.21",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "45.30",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "19-01-2024",
    UpdateDatePO: "26-01-2024",
    RequiredCargoReadyDate: "15-10-2023",
    RequiredDestinationDate: "15-10-2023",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Kilogramos",
    ItemNetWeightUOMDescription: "Kilogramos",
    ItemUOMDescription: "Bolsas",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
  },
];

export const poDetailItemEventsResponse = [
  {
    label: "Creado",
    value: "19-01-2024",
    id: 48,
    completed: true,
    order: 1,
    idStep: "step1",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Confirmado",
    value: "26-01-2024",
    id: 53,
    completed: true,
    order: 6,
    idStep: "step6",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Carga lista",
    value: null,
    id: 55,
    completed: false,
    order: 8,
    idStep: "step8",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Planeado",
    value: null,
    id: 56,
    completed: false,
    order: 9,
    idStep: "step9",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Recolección",
    value: null,
    id: 59,
    completed: false,
    order: 11,
    idStep: "step11",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Reservación",
    value: null,
    id: 60,
    completed: false,
    order: 12,
    idStep: "step12",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Salida",
    value: null,
    id: 61,
    completed: false,
    order: 13,
    idStep: "step13",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Llegada",
    value: null,
    id: 62,
    completed: false,
    order: 14,
    idStep: "step14",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
  {
    label: "Entrega",
    value: null,
    id: 64,
    completed: false,
    order: 16,
    idStep: "step16",
    middleStep: "",
    classValue: "subTitleCard",
    classTittle: "statusStepTittle",
  },
];

export const poDetailItemEventsDetailResponse = [
  {
    EventId: 22212,
    RowId: 798,
    ModuleId: 11,
    Module: "POITem",
    EventDescriptionId: 49,
    EventDescription: "Fecha solicitud carga lista",
    EventTypeId: 1,
    EventType: "Planeación",
    InTimeLine: "0",
    EventDate: "15-10-2023",
    CategoryResponsableId: 2,
    CategoryResponsable: "Cliente",
    Origin: "API",
    CreateDate: "19-01-2024",
  },
  {
    EventId: 22211,
    RowId: 798,
    ModuleId: 11,
    Module: "POITem",
    EventDescriptionId: 48,
    EventDescription: "Fecha de creación",
    EventTypeId: 1,
    EventType: "Planeación",
    InTimeLine: "1",
    EventDate: "19-01-2024",
    CategoryResponsableId: 2,
    CategoryResponsable: "Cliente",
    Origin: "API",
    CreateDate: "19-01-2024",
  },
  {
    EventId: 22213,
    RowId: 798,
    ModuleId: 11,
    Module: "POITem",
    EventDescriptionId: 50,
    EventDescription: "Fecha de solicitud de cambio",
    EventTypeId: 2,
    EventType: "Producción",
    InTimeLine: "0",
    EventDate: "15-10-2023",
    CategoryResponsableId: 3,
    CategoryResponsable: "Proveedor",
    Origin: "ePoint",
    CreateDate: "19-01-2024",
  },
  {
    EventId: 22265,
    RowId: 798,
    ModuleId: 11,
    Module: "POITem",
    EventDescriptionId: 66,
    EventDescription: "Fecha promesa carga lista ",
    EventTypeId: 2,
    EventType: "Producción",
    InTimeLine: "0",
    EventDate: "15-10-2023",
    CategoryResponsableId: 3,
    CategoryResponsable: "Proveedor",
    Origin: "ePoint",
    CreateDate: "23-01-2024",
  },
  {
    EventId: 22266,
    RowId: 798,
    ModuleId: 11,
    Module: "POITem",
    EventDescriptionId: 53,
    EventDescription: "Fecha de confirmación del Item",
    EventTypeId: 2,
    EventType: "Producción",
    InTimeLine: "1",
    EventDate: "26-01-2024",
    CategoryResponsableId: 3,
    CategoryResponsable: "Proveedor",
    Origin: "ePoint",
    CreateDate: "23-01-2024",
  },
];

export const DocumentsResponse = [
  {
    DocumentId: 119,
    ModuleId: 10,
    Module: "PO",
    DocCategoryId: 21,
    RowId: 406,
    Name: "Documento",
    Type: ".jpeg",
    DocumentCategoryName: "Purchase Order.Documento",
    Description: "Purchase Order",
    CreationDateDocument: "2024-01-31",
    URL:
      "https://magiclog.blob.core.windows.net/europartnersfile/9858663281981657-apagalo.jpeg",
    DeletedDate: null,
    Deleted: "0",
    UserId: 1,
    UserName: "test test",
  },
];

export const DocumentsCategoriesResponse = [
  {
    value: 21,
    key: 21,
    text: "Orden de compra",
  },
  {
    value: 22,
    key: 22,
    text: "Lista de empaque",
  },
  {
    value: 23,
    key: 23,
    text: "Mercancías peligrosas",
  },
  {
    value: 24,
    key: 24,
    text: "Factura comercial",
  },
  {
    value: 25,
    key: 25,
    text: "Otros",
  },
];
