import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from 'reactstrap';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import Errors from '../../../../../template/Errors'
import Icon from '@material-ui/core/Icon';
import Form from '../../../../../../models/FormData'
import restService from '../../../../../../services/restService';
import Tables from '../../../../../template/Table'
import Config from '../../models/Config'
import { Card, CardBody } from 'reactstrap'


const ContainterTypes = {
    "20DC": "20 Dry Cargo ",
    "40DC": "40 Dry Cargo ",
    "40HC": "40 High Cube",
    "45HC": "45 High Cube",
    "20OT": "20 Open Top",
    "40OT": "20 Open Top",
    "20RF": "20 Reefer",
    "40RF": "40 Reefer",
    "20FL": "20 Flat Rack",
    "40FL": "40 Flat Rack"
}

const rest = new restService();
class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CreateBookingFormData: '',
            columns: [
                { label: 'Count Of', key: 'BookingContainerId' },
                {
                    label: 'Container Type	', key: 'ContainerTypeCode', custom: (value) => {
                        return ContainterTypes[value.ContainerTypeCode]
                    }
                },
                { label: 'Commodity', key: 'CommodityName' },
                { label: 'Container Grade	', key: 'ContainerGrade' },
                { label: 'Empty Equipment Pickup Location	', key: 'EmptyPickupLocationName' },
                { label: 'Actions', key: '', custom: (value) => this.custom1(value) }
            ],
            Form: Form,
            selects: Config.selectsAdditional,
            containersLines: '',
            CreateContainer: '',
            container: {
                BookingContainerId: '',
                ContainerTypeCode: '',
                CommodityId: '',
                ContainerGrade: '',
                EmptyPickupLocationId: ''
            },
            error: { open: false, message: '', type: 'error' },
            containers: [],
            containerLines: ''
        }
    }
    custom1 = (value) => (
        <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value)}>
            <Icon className="icon" style={{ fontSize: 20 }}>
                remove
                </Icon>
        </Button>
    )

    componentDidMount = () => this.getAllCatalogs()

    getAllCatalogs = () =>
        rest.EXEC({ _function: 'GetAllCatalogsBooking', params: '' }, fetch).then(data => {
            this.setState({ selects: data.data }, () => {
                this.buildFormContainers()
            })
        }).catch(error => {

        })


    handleChange = name => event => {
        let container = this.state.container
        container[name] = event.target.value
        this.setState({ container }, () => this.buildFormContainers())
    }
    handleChangeSelect = name => event => {
        let container = this.state.container
        if (event && event.value) {
            container[name] = event.value
            this.setState({ container }, () => { this.buildFormContainers() })
        }
        else {
            container[name] = ""
            this.setState({ container }, () => { this.buildFormContainers() })
        }

    }
    buildFormContainers = () => {
        const { Form, container, selects } = this.state
        let CreateContainer = Form.CreateContainer.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <span id="containersOpackages"><label>{e.label} {e.required === true ? <span>*</span> : ""}</label></span>
                    <Input
                        required
                        type={e.datatype}
                        placeholder={e.placeHolder}
                        value={container[e.id]}
                        name={e.id}
                        onChange={this.handleChange(e.id)}
                        max={e.max}
                    />
                </div>
            )
            else if (e.type === "Select") return (
                <div className={e.class} key={e.id}>
                    <span > <label >{e.label} {e.required === true ? <span>*</span> : ""}</label></span>
                    <Select
                        id={e.label}
                        name={e.name}
                        value={container[e.id]}
                        onChange={this.handleChangeSelect(e.id)} options={selects[e.options]}
                    />
                </div>)
            else return ''
        })
        this.setState({ CreateContainer })
    }

    deleteLine = index => event => {
        let { containers, container } = this.state
        containers.splice(index, 1)
        container.BookingContainerId = container.BookingContainerId - 1
        this.setState({
            containers,
            container
        }, () => {
            this.props.sendProps(containers)
            this.buildFormContainers()
            this.setState({
                error: {
                    message: 'Document Removed',
                    open: true,
                    type: 'warning'
                }
            })
        })
    }


    addLine = () => {
        let { container, containers, selects } = this.state
        let COMMODITY = selects.COMMODITIES.find(element => element.value === container.CommodityId)
        let EMPTY_PICKUP_LOCATION = selects['EMPTY-PICKUP-LOCATIONS'].find(element => element.value === container.EmptyPickupLocationId)
        container.CommodityName = COMMODITY ? COMMODITY.label : ''
        container.EmptyPickupLocationName = EMPTY_PICKUP_LOCATION ? EMPTY_PICKUP_LOCATION.label : ''
        let keys = ['BookingContainerId', 'ContainerTypeCode']
        let errors = ''
        keys.forEach((e) => {
            if (!container[e]) errors += e + ', '
        })
        if (errors.length > 0)
            this.setState({
                error: {
                    message: 'Some fileds are required to add new document: ( ' + errors + ' )',
                    open: true,
                    type: 'warning'
                }
            })
        else {
            this.setState({
                container: {
                    BookingContainerId: '',
                    ContainerTypeCode: '',
                    CommodityId: '',
                    CommodityName: '',
                    ContainerGrade: '',
                    EmptyPickupLocationId: ''
                }
            }, () => {
                containers.push(container)
                this.setState({
                    containers,
                }, () => {
                    this.props.sendProps(containers)
                    this.buildFormContainers()
                    this.setState({
                        error: {
                            message: 'New Line Item Added',
                            open: true,
                            type: 'success'
                        }
                    })
                })
            })
        }
    }


    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
        const { CreateContainer } = this.state
        const { containers } = this.state
        return (
            <Card className="myCard">
                <CardBody>
                    <ExpansionPanel elevation={0} defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <legend><em className="fas fa-box fa-1x mr-2"></em>Containers</legend>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row" style={{ width: '100%' }}>
                                <div className="col-md-12">
                                    <div className="row">
                                        {CreateContainer}
                                        <div className="col-md-1" style={{ paddingTop: '25px' }}>
                                            <Button variant="fab" mini color="primary" aria-label="Add" onClick={this.addLine}>
                                                <AddIcon />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr></hr>
                                </div>
                                <div className="col-md-12">
                                    <Tables data={containers} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
                                </div>
                            </div>
                            <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </CardBody>
            </Card>

        );
    }
}
export default List;