import React, { Component } from 'react';
import restService from '../../../../services/restService';
import ContentWrapper from '../../../template/Layout/ContentWrapper'
import Table from '../../../template/Table'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router-dom';
import {Card,CardBody} from 'reactstrap'


const rest = new restService();



class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            columns: [
                { label: 'PO No', key: 'PONumber', custom: (value) => this.custom2(value) },
                { label: 'Status', key: 'StatusDesc', custom: (value) => this.custom1(value) },
                { label: 'Supplier', key: 'SupplierName' },
                { label: 'Cruise', key: 'Cruise' },
                { label: 'PO Division', key: 'PODivision' },
                { label: 'PO Creation Date', key: 'POCreateDate' },
                { label: 'Requested Ex Works Date', key: 'RequestedExWorksDate' },
                { label: 'Required at Destination', key: 'RequiredDestination' },
                { label: 'Total Qty Requested', key: 'TotalQtyOrdered' },
                { label: 'Qty Confirmed', key: 'TotalQtyConfirmed' },
                { label: 'Transport', key: 'TransportMode' },
                { label: 'Cargo Ready Date', key: 'PromiseCargoReadyDate' }
            ]
        };
    }
    
    custom1 = (value) => {
        let buttons = {
            1: <Button className="btn-oval" color="warning" onClick={() => this.props.history.push('/order-edit/' + value.POId)}>Complete the order</Button>,
            2: <Button className="btn-oval" color="primary" onClick={() => this.props.history.push('/po-confirmation/' + value.POId)}>Pending to Confirm</Button>,
            3: <Button className="btn-oval" color="success" onClick={() => this.props.history.push('/cargo-readiness-confirmation/' + value.POId)}>Cargo Readiness Confirmation</Button>
        }
        return buttons[value.StatusId] ? buttons[value.StatusId] : value.StatusDesc
    }
    custom2 = (value) => <Link to={"/po-detail-view/" + value.POId}>{value.PONumber}</Link>

    componentDidMount = () => this.getOrdersList()

    getOrdersList = () => rest.EXEC({ _function: 'GetOrdersList', params: '?type=cargoReady' }, fetch)
        .then(success => this.setState({ data: success.data }))
        .catch(error => { })

    render() {
        const { data, columns } = this.state
        return (
            <ContentWrapper>
                <div>
                    <div className="content-heading">
                        <div className="row">
                            <em className="fas fa-th-list fa-1x mr-2"></em>
                            Pending Activities
                        </div>
                    </div>
                </div>
                <Card className="myCard">
                    <CardBody>
                    <Table
                        columns={columns}
                        data={data}
                        itemsByPage={10}
                        options={
                            {
                                pagination: true,
                                search: true,
                                downloads: {
                                    xlsx: false,
                                    pdf: false
                                },
                                selectItems: false
                            }
                        }
                        onSelect={array => {  }}
                    />
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}
export default withRouter(FormExtended);