import { useEffect, useState } from 'react';
// import EntitiesRepository from '../../../../repositories/EntitiesRepository';
import { entitiesResponse } from './mocks';

export const useEntities = () => {
  const [entities, setEntities] = useState([]);
  const [filterEntities, setFilterEntities] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEntities = async (value, associated) => {
    setLoading(true);
    try {
      // const { data } = await EntitiesRepository.getEntities();
      const data = entitiesResponse;
      setEntities(data);
      setFilterEntities(data);
      if (value || associated) {
        handleFilterEntities(value, associated, data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterEntities = (value, associated, data) => {
    const normalizedValue = normalizeString(value);
    const entitiesToFilter = data || entities;
    const filteredEntities = entitiesToFilter.filter((entity) => {
      const values = Object.values(entity).map((val) => normalizeString(val));
      const regex = new RegExp(normalizeString(normalizedValue), "gi");
      return values.some((val) => {
        if (associated) {
          return regex.test(normalizeString(val)) && !entity.EntityRelationshipId;
        }
        return regex.test(normalizeString(val));
      });
    });
    setFilterEntities(filteredEntities);
  }

  const normalizeString = (str = '') => {
    let value = str;
    if (!value) value = "";
    if (typeof value !== "string") {
      value = value.toString();
    }
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    getEntities();
  }, []);

  return { entities, loading, getEntities, handleFilterEntities, filterEntities };
}
