import React from 'react';
import propTypes from 'prop-types';
import Button from '@material-ui/core/Button';

/**
 * Download Component
 * @param {*} props 
 */
function Download(props) {
  let [download, setDownload] = React.useState(false);

  const DownloadFile = React.useCallback(async () => {
    try {
      setDownload(true);

      if (props.action === 'download') {
        let file_path = props.file
        var a = document.createElement('A');
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

      } else if (props.action === 'visualization') {
        setDownload(false);
        // window.open(`https://fsposeidon.blob.core.windows.net/magicvision-docs/${props.file}`);
        window.open(`${props.file}`);
      }
      setDownload(false);
    } catch (error) {
      console.error('the error ==>', error);
      setDownload(false);
    }
  }, [props]);

  return (
    <Button style={{ color: '#1e90ff' }} disabled={download} onClick={DownloadFile}>
      {
        props.action === 'download' ?
          <i className={download ? `big spinner loading icon` : `fas fa-cloud-download-alt fa-2x dataIcon`} /> :
          <i className={download ? `big spinner loading icon` : `fas fa-search fa-2x`} />
      }
    </Button>
  );
}

Download.propTypes = {
  action: propTypes.string,
  name: propTypes.string,
  file: propTypes.string
};

Download.defaultProps = {
  action: '',
  name: '',
  file: '',

};

export default Download;