import React from 'react';
import {Dropdown, Button} from 'semantic-ui-react';
import {IMAGE} from './../img';
import './styles.scss';

const ImgAttention = () => (
  <em className="_format_text">Orders that require attention</em>
);

const Icono = () => (
  <img className="_imgdropdown" height="20px" width="20px" src={IMAGE} alt='img_drop'/>
);

const SingleText = (props) => {
  return (
    <em className="_format_text">{props.text}</em>
  );
};

const TextFormat = () => (
  <em className='_new'>New! </em>
);

const options = [
  { key: 'allOrders', icon: '', text: <SingleText text={'All Orders'}/>, value: 'allOrders' },
  { key: 'newOrders', icon: <TextFormat/>, text: <SingleText text={'New Orders'}/>, value: 'newOrders' },
  { key: 'ordersAttention', icon: <Icono/>, text:<ImgAttention/>, value: 'ordersAttention' },
  { key: 'newCusComment', icon: '', text: <SingleText text={'Orders with new Customer comments'}/>, value: 'newCusComment' },
  { key: 'newIntComment', icon: '', text: <SingleText text={'Orders with new 3PL comments'}/>, value: 'newIntComment' }
];


/*=============================================
=            Quick view comments              =
=============================================*/

function QuickViewComponent(props){

  function textChanger(evt, data){
    props.attentionOrders(data.value);
  }

  return (
    <Button.Group color="green" size="tiny">
      <Button>{props.text}</Button>
      <Dropdown
        className='button icon'
        floating
        options={options}
        onChange={(evt, data) => {textChanger(evt, data);}}
        trigger={<></>}
      />
    </Button.Group>
  );
}
export default QuickViewComponent;