import React, { Component } from 'react';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import ReactDOM from 'react-dom';
import {  Card, CardBody, Progress } from 'reactstrap';
import { Map, Marker, InfoWindow, GoogleApiWrapper, Polyline } from 'google-maps-react';
import { Divider } from '@material-ui/core';
import createHistory from 'history/createBrowserHistory';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './styles/style.scss';

const status_ = {
  1: <em className="fa-1x fas fa-ship mr-2"></em>,
  2: <em className="fa-1x fas fa-ship mr-2"></em>,
  3: <em className="fa-1x fas fa-plane mr-2"></em>,
  4: <em className="fa-1x fas fa-plane mr-2"></em>,
  5: <em className="fa-1x fas fa-plane mr-2"></em>,
  6: <em className="fa-1x fas fa-plane mr-2"></em>,
  7: <em className="fa-1x fas fa-plane mr-2"></em>,
  8: <em className="fa-1x fas fa-building mr-2"></em>
};

if (!window.AppHistory) {
  window.AppHistory = createHistory();
}

class InfoWindowEx extends Component {
  constructor(props){
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement('div');
  }

  componentDidUpdate = (prevProps) =>{
    if (this.props.children !== prevProps.children) {
      ReactDOM.render(
        React.Children.only(this.props.children),
        this.contentElement
      );
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMarker: {},
      dataRecieve: props.dataDrawer,
      tags: '',
      dataOptions: props.dataOptions ? props.dataOptions : {},
      markerInfo: {},
      polylineDefault: '',
      polyline: props.polyline ? props.polyline: [],
      marker: props.marker,
      type: props.type,
      showingInfoWindow: false,
      showDrawerInfo: false,
      portsGreen: 0,
      portsYellow: 0,
      portsRed: 0,
      colorSelected: [0,1,2]
    };
    this.buttonRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      marker : this.props.marker
    },()=>{
      this.buildTags();
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      marker : nextProps.marker
    },()=>{
      this.setState({polyline: nextProps.polyline},()=>this.buildTags());
    });
    this.setState({
      dataRecieve: nextProps.dataDrawer,
      dataOptions: nextProps.dataOptions
    }, () =>{
      let sendData = {
        data: nextProps.dataDrawer
      };
      this.onMarkerClick(sendData, null, null);
    });
  }

  onMarkerClick = (props, marker, e) => {

    switch (this.state.type) {
    case 'info':
      // console.log('dgfhgj',props,marker,e);

      this.setState({showingInfoWindow: false}, ()=>{this.setState({showingInfoWindow: true, activeMarker: props.data, markerInfo: marker});});
      break;
    case 'drawer':
      this.setState({showDrawerInfo: false}, ()=>{this.setState({showDrawerInfo: true, activeMarker: props.info});});
      break;
    case 'polyline':
      if (props.data){
        if(props.data.From.Location && props.data.To.Location){
          let polyline = [
            {lat: parseFloat(props.data.From.Location.Latitude), lng: parseFloat(props.data.From.Location.Longitude)},
            {lat: parseFloat(props.data.To.Location.Latitude), lng: parseFloat(props.data.To.Location.Longitude)}
          ];
          this.setState({polyline: polyline});
        }
      }
      break;
    default:

    }
  }

  onInfoWindowClose = () =>{
    this.setState({
      showingInfoWindow: false
    });
  }

  buildTags = () => {
    let { colorSelected, marker, type } = this.state;
    let tags = [];
    let portsGreen = 0;
    let portsRed = 0;
    let portsYellow = 0;
    let polylineDefault = [];
    marker.map((e) => {
      let findColor = false
      colorSelected.map(c => {
        if(e.icon){
          if(e.icon.url === 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' && c === 0){
            findColor = true;
          }
          else if(e.icon.url === 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png' && c === 1){
            findColor = true;
          }
          else if(e.icon.url === 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' && c === 2){
            findColor = true;
          }
        }
        else{
          findColor = true
        }
      })
      if(e.icon){
        if(e.icon.url === 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png'){
          portsGreen++;
        }
        else if(e.icon.url === 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png'){
          portsRed++;
        }
        else{
          portsYellow++;
        }
      }
      if(findColor){
        tags.push(
          <Marker
            key={e.key}
            onClick={this.onMarkerClick}
            title={e.title}
            name={e.name}
            info={e}
            data={e.data}
            position={e.position}
            icon={{
              url: e.icon.url,
              anchor: new window.google.maps.Point(32, 32),
              scaledSize: new window.google.maps.Size(32, 32)
            }}
          />
        );
      }
      return e;
    });
    var lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: 1,
      scale: 4
    };
    if(type === 'polyline'){
      polylineDefault.push(
        <Polyline
          key={Math.random()}
          path={this.props.data ? this.props.data : []}
          icons={[{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px'
          }]}
          strokeOpacity={0}/>
      );
    }
    this.setState({tags, polylineDefault, portsRed, portsGreen, portsYellow});
  }

  redirectTo = (event) =>{
    this.props.history.push(this.state.dataOptions.config.link);
  }

  clickPort = (value) =>{
    if(this.state.colorSelected.length === 1 && this.state.colorSelected[0] === value){
      this.setState({colorSelected: [0,1,2]}, () => {this.buildTags()})
    }
    else{
      this.setState({colorSelected: [value]}, () => {this.buildTags()})
    }
  }

  render() {
    // console.log("this.props..---->>",this.props);

    let { activeMarker, markerInfo, showDrawerInfo, showingInfoWindow, polyline, polylineDefault, portsRed, portsGreen, portsYellow } = this.state;
    let dataDrawer = activeMarker;
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    const info = [
      {
        data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#00D8FF' }}></em>),
        img: true,
        title: 'Aduana',
        value: activeMarker ? activeMarker.Aduana : ''
      },
      {
        data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#00D8FF' }}></em>),
        img: false,
        title: 'Total Importaciones',
        value: activeMarker ? activeMarker.TotalImpo : ''
      },
      {
        data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-right" style={{ color: '#D6D6D6' }}></em>),
        img: false,
        title: 'Total DTA´s',
        value: activeMarker ? activeMarker.TotalDTA : ''
      },
      {
        data: (<em className="fa-2x mr-2 fas fa-arrow-alt-circle-left" style={{ color: '#874B89' }}></em>),
        img: false,
        title: 'Total Exportaciones',
        value: activeMarker ? activeMarker.TotalExpo : ''
      }
    ];

    var dataOptions = this.state.dataOptions ? this.state.dataOptions.data : '';
    // console.log('actvie',markerInfo);
    // console.log('actvie',dataOptions);

    if(dataOptions && markerInfo){
      dataOptions.map(e =>{
        if(markerInfo.info){
          e.value = markerInfo.info[e.key];
        }
        return e;
      });
    }
    // console.log('dataOptions',dataOptions);
    let dataInfo = this.state.dataOptions ? (this.state.dataOptions.data ? this.state.dataOptions.data : info) : info;
    let haveButton = this.state.dataOptions ? (this.state.dataOptions.config ? this.state.dataOptions.config.button : false ) : false;

    return (
      <>
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation='overlay'
            direction='left'
            icon='labeled'
            inverted
            vertical
            visible={true}
            width='thin'
          >
            <Card style={{marginTop: 85, marginLeft: 25}}>
              <CardBody>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row" style={{marginLeft: 0, marginTop: 5, cursor: 'pointer'}} onClick={() =>{
                      this.clickPort(0)
                    }}>
                      <img src="https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png" width={25} />
                      <span>{portsGreen} ports </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row" style={{marginLeft: 0, marginTop: 5, cursor: 'pointer'}} onClick={() =>{
                      this.clickPort(1)
                    }}>
                      <img src="https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png" width={25} />
                      <span>{portsYellow} ports </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row" style={{marginLeft: 0, marginTop: 5, cursor: 'pointer'}} onClick={() =>{
                      this.clickPort(2)
                    }}>
                      <img src="https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png" width={25} />
                      <span>{portsRed} ports </span>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Sidebar>
          <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            vertical
            visible={showDrawerInfo}
            width='wide'
            direction={'right'}
          >
            <Menu.Item as='a' onClick={() => {this.setState({showDrawerInfo: false});}}>
              Close
            </Menu.Item>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            {status_[8]}
                            <span>
                           
                              {
                                // dataDrawer.Status ? dataDrawer.Status.label : ''
                                dataDrawer ? dataDrawer.id : ''
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="d-flex">
                          <span style={{ fontWeight: 'bold' }}>
                            Containers
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{height: 50}}/>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <em style={{ color: '#2f80e7', paddingTop: '2%' }} className="fas fa-arrow-alt-circle-left mr-3"></em>
                          <div className="mb-mail-meta" >
                            <span style={{ fontWeight: 'bold' }}>
                              Total Arrivals
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{dataDrawer ? dataDrawer.totalArrival : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{height: 50}}/>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            <span style={{ fontWeight: 'bold' }}>
                              Capacity
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{dataDrawer ? dataDrawer.capacityArrival : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{height:1, background: 'grey', margin: 15, width: '100%'}} />
                  <div style={{height: 50}}/>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <em style={{ color: '#57b65b', paddingTop: '2%' }} className="fas fa-arrow-alt-circle-right mr-3"></em>
                          <div className="mb-mail-meta" >
                            <span style={{ fontWeight: 'bold' }}>
                              Total Departures
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{dataDrawer ? dataDrawer.totalDeparture : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{height: 50}}/>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <div className="mb-mail-meta" >
                            <span style={{ fontWeight: 'bold' }}>
                              Capacity
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{dataDrawer ? dataDrawer.capacityDeparture : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{height:1, background: 'grey', margin: 15, width: '100%'}} />
                  <div style={{height: 50}}/>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="d-flex">
                          <em style={{ color: '#9e9e9e', paddingTop: '2%' }} className="fas fa-calendar-alt mr-3"></em>
                          <div className="mb-mail-meta" >
                            <span style={{ fontWeight: 'bold' }}>
                              Booking
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex">
                          <div className="mb-mail-meta">
                            <div className="mb-mail-from">
                              <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{dataDrawer ? dataDrawer.bookings : ''}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
              <div className="col-12" style={{textAlign: 'center'}}>
                <button onClick={() => {this.setState({showDrawerInfo: false});
                // this.props.history.push('/weekly-planning/' + activeMarker.BookingNumber);
                }} style={{fontSize:'14px', margin: 10, borderRadius: 25, width: 124, height: 32, backgroundColor: '#5d9cec', color: 'white'}}>View Details</button>
              </div>
            </Card>
          </Sidebar>
          <Sidebar.Pusher>
            <Segment>
              <div style={{ position: 'relative', height: this.props.maxHeight ? this.props.maxHeight : '500px'}}>
                <Map google={this.props.google} zoom={this.props.zoom ? this.props.zoom :2}
                  initialCenter={this.props.position ? this.props.position : {
                    lat: 4.0000000,
                    lng: -72.0000000
                  }}>
                  {this.state.tags}
                  <InfoWindowEx visible={showingInfoWindow} onClose={this.onInfoWindowClose} marker={markerInfo}>
                    <div style={{minWidth: 250}}>
                      {
                        dataInfo.map((f, index) =>{
                          return (
                            <div key={index} className="row" style={{marginTop: index === 0 ? 0 : 10}}>
                              <div className="col-1">
                                {f.img ? <img alt="aduana" src={f.data} width={26} height={26}/> : f.data}
                              </div>
                              <div className="col-6" style={{marginLeft: 5, fontWeight: 'bold', marginTop: (index === 0 || index === dataInfo.length -1 ) ? 2 : 0}}>
                                {f.title}
                              </div>
                              <div className="col-4" style={{textAlign: 'center'}}>
                                {f.value}
                              </div>
                            </div>
                          );
                        })
                      }
                      {/* {
                        haveButton ? (
                          <div className="col-12" style={{textAlign: 'center', margin: 20}}>
                            <button ref={this.buttonRef} onClick={() =>{
                              this.redirectTo(this)}} style={{background: '#2196F3',color: 'white', width: '60%', borderRadius: 50, height: 30, marginRight: 20}}>
                              {this.state.dataOptions.config.textButton}
                            </button>
                          </div>
                        ) : (<></>)
                      } */}
                    </div>
                  </InfoWindowEx>
                  {polylineDefault}
                  <Polyline
                    path={polyline}
                    strokeColor="#0000FF"
                    strokeOpacity={0.8}
                    strokeWeight={2}/>
                </Map>
              </div>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAl_10ZKSqnrviAV-KSQ6c8i4Sa_gEhEmA',
  v: '3.30'
})(withRouter(MapContainer));
