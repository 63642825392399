import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Axios from 'axios';

// ? Imports
import {columns} from './config';
import Table from '../../../../template/Table';
import {Services} from '../../Services';
import Authentication from '../../../../../services/Authentication.js';
import Loader from './../../../../Global/TableLoader';
import './styles.scss';

const auth = new Authentication();

/**
 *  Shipment Procedures Component
 * @param {*} props 
 */
function ShipmentProcedures(props){
  let [loading, setLoading] = React.useState(true);
  let [data, setData] = React.useState([]);

  /**
   * Get Shipment PO List
   */
  const getShipmentList = React.useCallback( async () => {
    try{
      let id  = auth.getAuthentication('customerSelect');
      let success = await Axios.get(`${Services.ORDER.path}/order/search/procedures?type=Shipments&id=${id}`);
      // this.setState({ data: success.data.data, loading: false}); 
      setData(success.data.data);
      setLoading(false);
    }catch(error){
      console.error('getSipment -->', error);
      setLoading(false);
    }
  }, []);

  /**
   * Remove Shipment
   */
  const {_user} = props;
  const rmShipmentModal = React.useCallback(async(Shipment) => {
    try{
      // let url = 'http://localhost:3001';
      // await Axios.get(`${url}/shipment/procedure?shipmentno=${Shipment.ShipmentNumber}&user=${_user}`);
      await Axios.get(`${Services.SHIPMENTS.path}shipment/procedure?shipmentno=${Shipment.ShipmentNumber}&user=${_user}`);
      getShipmentList();
    }catch(error){
      console.error('the error Shipment PO ==>', error);
    }
  }, [_user, getShipmentList]);



  React.useEffect(() => {
    getShipmentList();
  }, [getShipmentList]);

  return (
    <React.Fragment>
      <Card>
        <CardBody className="myCard">
          {
            loading ? <Loader/>
              :
              <Table
                columns={columns(getShipmentList, props._customer, rmShipmentModal, props._user)}
                data={data}
                itemsByPage={10}
                options={{
                  pagination: true,
                  search: true,
                }}
                cellType={'thin-rows'}
                fixed
              />
          }
        </CardBody>
      </Card>
    </React.Fragment>    
  );
};

export default ShipmentProcedures;