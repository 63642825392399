import React, { Component } from 'react';
import ContentWrapper from '../../../../../template/Layout/ContentWrapper';
import { Row } from 'reactstrap';
import Comments from '../../../../../Components/Comments';
import Documents from '../../CustomImports/Import-detail-view/Documents';
import Summary from './Summary';
import Events from './Events';
import { withRouter } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import axios from 'axios';
import { Services } from '../../../Services';
import dummy from '../../../dummy'

class DtaDetail extends Component {
  state = {
    allSteps: [],
    id: this.props.match.params.id,
    commentsData: [
      {
        ComType: 'Tipo 1',
        ComTypeId: 1,
        Comment: 'Comentario de prueba',
        CommentId: 73,
        Date: '2019-06-20',
        Module: 'Comentario',
        ModuleId: 1,
        RowId: 861,
        UpdateBy: 'API',
      },
      {
        ComType: 'Tipo 2',
        ComTypeId: 1,
        Comment: 'Comentario de prueba',
        CommentId: 73,
        Date: '2019-07-02',
        Module: 'Comentario',
        ModuleId: 1,
        RowId: 861,
        UpdateBy: 'API',
      },
    ],
    catalogsData: [
      {
        ComTypeId: 1,
        ModuleId: 1,
        ComType: 'Supplier Follow Up',
        value: 1,
        label: 'Supplier Follow Up',
      },
      {
        ComTypeId: 2,
        ModuleId: 1,
        ComType: 'Order Quality',
        value: 2,
        label: 'Order Quality',
      },
      {
        ComTypeId: 3,
        ModuleId: 1,
        ComType: 'Order Quantities',
        value: 3,
        label: 'Order Quantities',
      },
      {
        ComTypeId: 10,
        ModuleId: 1,
        ComType: 'Other',
        value: 10,
        label: 'Other',
      },
    ],
    steps: [
      {
        label: 'Instrucción Cliente AA',
        value: '',
        id: 3,
        completed: false,
        order: 1,
        middleStep: '',
      },
      {
        label: 'ATA (Manifiesto)',
        value: '',
        id: 5,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Aceptación Solicitud DTA',
        value: '',
        id: 19,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Autorización Inspección',
        value: '',
        id: 20,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: '',
      },
      {
        label: 'Salida DTA',
        value: '',
        id: 21,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: '',
      },
      {
        label: 'Finalización DTA',
        value: '',
        id: 22,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: '',
      },
      {
        label: 'Radicación de Factura',
        value: '',
        id: 16,
        completed: false,
        order: 7,
        idStep: 'step7',
        middleStep: '',
      },
    ],
  };

  componentDidMount = () => {
    this.getEvents();
    this.getDataForId();
  };

  getDataForId = () => {
    // axios
    //   .get(
    //     `${Services.Get_Traceability_Imports_ForId.path}/customs/dataCustomImports/${this.state.id}`
    //   )
    //   .then((success) => {
      let success = dummy.infoDetailDtas;
        let commentsData = [];
        let data = {
          ComType: '',
          ComTypeId: 1,
          Comment: success.data.infoImport[0].Comentarios,
          CommentId: '',
          Date: success.data.infoImport[0].Comentarios
            ? success.data.infoImport[0].ETA
            : success.data.infoImport[0].ETA,
          Module: 'Comentario',
          ModuleId: 1,
          RowId: '',
          UpdateBy: 'API',
        };
        commentsData.push(data);
        let Info = [];
        Info.push(success.data.infoImport[0]);
        this.setState({
          commentsData,
          HBL_HAWB: success.data.HBL_HAWB
            ? success.data.infoImport[0].HBL_HAWB
            : '',
          Estatus: success.data.infoImport
            ? success.data.infoImport[0].Estatus
            : '',
          DocTransporte: success.data.infoImport
            ? success.data.infoImport[0].DocTransporte
            : '',
          doImportImpo: success.data.infoImport
            ? success.data.infoImport[0].DOAduanaImpo
            : '',
          cliente: success.data.infoImport ? Info[0].Cliente : '',
          NIT: success.data.infoImport ? Info[0].NIT : '',
          Grupo: success.data.infoImport ? Info[0].Grupo : '',
          FechaActualizacion: success.data.infoImport
            ? Info[0].FechaActualizacion
            : '',
        });
      // })
      // .catch((error) => {
      //   console.error(error);
      // });
  };

  getEvents = () => {
    const { steps, id } = this.state;
    let moduleId = 1;
    // let idContainer = 1816;
    // axios
      // .get(`${Services.Get_Events_General.path}/events/all/${moduleId}/${id}`)
      // .then((response) => {
      let response = dummy.eventosDtas;

        let update = false;
        for (let index = response.steps.length; index >= 0; index--) {
          const element = response.steps[index];
          if (element && element.completed) update = true;
          if (update) element.completed = true;
        }
        var newSteps = steps;
        let dataEvents = response.data;
        var findNext = false;
        newSteps.map((f) => {
          dataEvents.map((e) => {
            if (e.EventDescriptionId === f.id) {
              findNext = true;
              f.value = e.EventDate;
              if (e.InTimeLine === '1') {
                f.completed = true;
              } else {
                findNext = false;
              }
            } else {
              findNext = false;
            }
            if (findNext) {
              f.completed = true;
            }
            return e;
          });
          return f;
        });
        newSteps.sort(function(a, b) {
          return b.order - a.order;
        });
        let findTrue = false;
        newSteps.map((e) => {
          if (e.completed) {
            findTrue = true;
          }
          if (findTrue) {
            e.completed = true;
          }
          return e;
        });
        newSteps.sort(function(a, b) {
          return a.order - b.order;
        });
        this.setState({ steps: newSteps, allSteps: dataEvents }, () => {});
      // })
      // .catch((error) => {});
  };

  render() {
    const { commentsData, catalogsData } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <Row>
            <legend>
              <img
                style={{ marginTop: '-7px' }}
                alt=""
                src="https://fsposeidon.blob.core.windows.net/magicdome/wareMap.png"
                height="40"
                width="40"
              ></img>
              Detalle Aduana DTA
            </legend>
          </Row>
        </div>
        <Tab
          menu={{ secondary: true, className: 'myWrapped' }}
          menuPosition="right"
          panes={[
            {
              menuItem: 'Resumen',
              render: () => (
                <Summary
                  FechaActualizacion={this.state.FechaActualizacion}
                  cliente={this.state.cliente}
                  NIT={this.state.NIT}
                  Grupo={this.state.Grupo}
                  doImportImpo={this.state.doImportImpo}
                  HBL_HAWB={this.state.HBL_HAWB}
                  DocTransporte={this.state.DocTransporte}
                  Estatus={this.state.Estatus}
                  steps={this.state.steps}
                />
              ),
            },
            {
              menuItem: 'Eventos',
              render: () => (
                <Events
                  cliente={this.state.cliente}
                  NIT={this.state.NIT}
                  Grupo={this.state.Grupo}
                  doImportImpo={this.state.doImportImpo}
                  HBL_HAWB={this.state.HBL_HAWB}
                  DocTransporte={this.state.DocTransporte}
                  Estatus={this.state.Estatus}
                  steps={this.state.steps}
                  allSteps={this.state.allSteps}
                />
              ),
            },
            {
              menuItem: 'Comentarios',
              render: () => (
                <Comments data={commentsData} catalogs={catalogsData} />
              ),
            },
            {
              menuItem: 'Documentos',
              render: () => (
                <Documents rolId={12} shipment="" shipmentDataPlane="" />
              ),
            },
          ]}
        />
      </ContentWrapper>
    );
  }
}

export default withRouter(DtaDetail);
