import React from "react";
import FormatDate from "./utils/dateFormat";
import { Link } from "react-router-dom";

export default ({ fields, values }) => {
  const booleanValue = (value) =>
    value ? "Yes" : "No";
  return fields.map((field, index) => {
    let url = "";
    if (field.type === "Redirect" && field.link && values[field.linkId]) {
      url = field.link;
      if (field.linkId) {
        url = url.replace("{id}", values[field.linkId]);
      }
    }
    return (
      <div
        className={field.class}
        style={{ paddingTop: "1%" }}
        key={`${field.id}-${index}-preview`}
      >
        {
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-6 input-form">
              <span className="label-euro bold">{field.label}</span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6 ">
              <span className="label-euro">
                {field.type === "Redirect" ? (
                  <Link className="link-euro" to={url}>
                    {values[field.id]}
                  </Link>
                ) : field.boolean ? (
                  booleanValue(Number(values[field.id]))
                ) : (
                  FormatDate(values[field.id], field.format)
                )}
              </span>
            </div>
          </div>
        }
      </div>
    );
  });
};
