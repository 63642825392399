// CHART BAR STACKED
// -----------------------------------
export const ChartBarStacked = {
  data: [{
    'label': 'Engines',
    'color': '#51bff2',
    'data': [
      ['Jan', 0],
      ['Feb', 0],
      ['Mar', 0],
      ['Apr', 0], 
      ['May', ],
      ['Jun', ],
      ['Jul', ], 
      ['Aug', 165706],
      ['Sep', 0],
      ['Oct', 0],
      ['Nov', 0],
      ['Dec', 0]
    ]
  }, {
    'label': 'Furniture',
    'color': '#4a8ef1',
    'data': [
      ['Jan', 0],
      ['Feb', 0],
      ['Mar', 0],
      ['Apr', 0],
      ['May', 0],
      ['Jun', 0],
      ['Jul', 0],
      ['Aug', 64751],
      ['Sep', 50061],
      ['Oct', 36023],
      ['Nov', 0],
      ['Dec', 32081]
    ]
  }, {
    'label': 'Marketing',
    'color': '#f0693a',
    'data': [
      ['Jan', 0],
      ['Feb', 0],
      ['Mar', 0],
      ['Apr', 0],
      ['May', 0],
      ['Jun', 0],
      ['Jul', 0],
      ['Aug', 0],
      ['Sep', 304111],
      ['Oct', 109151],
      ['Nov', 15957],
      ['Dec', 437917]
    ]
  }, {
    'label': 'Spare Parts',
    'color': '#7266ba',
    'data': [
      ['Jan', 0],
      ['Feb', 0],
      ['Mar', 0],
      ['Apr', 0],
      ['May', 0],
      ['Jun', 0],
      ['Jul', 0],
      ['Aug', 0],
      ['Sep', 86115],
      ['Oct', 323100],
      ['Nov', 0],
      ['Dec', 57192]
    ]
  
  }],

  options: {
    series: {
      stack: true,
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      // position: 'right' or 'left'
      tickColor: '#eee'
    },
    shadowSize: 0
  }

};




export const dos = {
  data: [{
    'label': 'Ocean Freight',
    'color': '#4a8ef1',
    'data': [
      ['Jan', 69],
      ['Feb', 135],
      ['Mar', 14],
      ['Apr', 100],
      ['May', 100],
      ['Jun', 62],
      ['Jul', 115],
      ['Aug', 22],
      ['Sep', 104],
      ['Oct', 132],
      ['Nov', 72],
      ['Dec', 61]
    ]
  }, {
    'label': 'Overcost ',
    'color': 'red',
    'data': [
      ['Jan', 29],
      ['Feb', 36],
      ['Mar', 47],
      ['Apr', 21],
      ['May', 5],
      ['Jun', 49],
      ['Jul', 37],
      ['Aug', 44],
      ['Sep', 28],
      ['Oct', 9],
      ['Nov', 12],
      ['Dec', 35]
    ]
  }, {
    'label': 'Inland',
    'color': '#7266ba',
    'data': [
      ['Jan', 22],
      ['Feb', 6],
      ['Mar', 42],
      ['Apr', 14],
      ['May', 54],
      ['Jun', 9],
      ['Jul', 74],
      ['Aug', 14],
      ['Sep', 25],
      ['Oct', 78],
      ['Nov', 7],
      ['Dec', 78]
    ]
  }, {
    'label': 'Air Freight', 
    'color': '#c8ccd1 ',
    'data': [
      ['Jan', 74],
      ['Feb', 100],
      ['Mar', 50],
      ['Apr', 74],
      ['May', 52],
      ['Jun', 67],
      ['Jul', 96],
      ['Aug', 23],
      ['Sep', 90],
      ['Oct', 42],
      ['Nov', 78],
      ['Dec', 3]
    ]
  },
  {
    'label': 'Customs',
    'color': '#66bb6a',
    'data': [
      ['Jan', 74],
      ['Feb', 1],
      ['Mar', 5],
      ['Apr', 74],
      ['May', 52],
      ['Jun', 7],
      ['Jul', 96],
      ['Aug', 23],
      ['Sep', 7],
      ['Oct', 42],
      ['Nov', 78],
      ['Dec', 3]
    ]
  }],

  options: {
    series: {
      stack: true,
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      // position: 'right' or 'left'
      tickColor: '#eee'
    },
    shadowSize: 0
  }

};
