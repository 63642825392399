import React from 'react';

function TableHeader({ columns }) {
	let _newColumns = [];
	columns.forEach((column, index) => {
		_newColumns.push(
			<th className='headerTable' key={index} id={index}>
				{column.label}
			</th>
		);
	});

	return (
		<React.Fragment>
			<thead>
				<tr>
					{_newColumns}
				</tr>
			</thead>
		</React.Fragment>

	);
}

export default TableHeader;
