import React from "react";
import propTypes from "prop-types";
import { Item, Icon, Label } from "semantic-ui-react";
import { Typography, Image } from "antd";
import "./styles.scss";
import moment from "moment";
import fallback from "./R.png";

moment.locale("es");
const { Paragraph } = Typography;

/**
 * Date Formatter function
 * @param {*} date - Value date from rss items
 */
const DateFormatter = (date) => {
  if (date) {
    date = date.trim();
    let DATE = moment(new Date(date))
      .startOf("hour")
      .fromNow();
    return DATE;
  }
  return "";
};

// TYPE: rss, dashbord
/**
 * Item Feed for dashboard
 * @param {*} param0
 */
function ItemFeed({ feed, type }) {
  return (
    <div className={`container-${type}`}>
      {type === "rss" ? (
        <Label as="a" color="black" ribbon>
          {moment(new Date(feed.date_string))
            .format("DD MMMM YYYY ")
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))}
        </Label>
      ) : (
        ""
      )}
      <Item.Group>
        {feed.grouped.map((item, key) => {
          return (
            <Item
              key={key}
              // className={`item-${type}`}
            >
              <Item.Image
                className={`container-${type}__image`}
                size={type === "rss" ? "small" : "tiny"}
              >
                <Image
                  width={type === "rss" ? 120 : 80}
                  height={type === "rss" ? 80 : 80}
                  src={
                    item.Preview ==
                    "https://fsposeidon.blob.core.windows.net/roldan/R.jpg"
                      ? "https://fsposeidon.blob.core.windows.net/magicdomefile/logoMD.png"
                      : item.Preview
                  }
                  fallback={fallback}
                />
              </Item.Image>
              <Item.Content className={`container-${type}__content`}>
                <Item.Header className={`container-${type}__header`}>
                  <a
                    href={item.Link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`container-${type}__title`}
                  >
                    <Paragraph ellipsis={{ rows: 3 }}>{item.Title}</Paragraph>
                  </a>
                </Item.Header>
                {/* <Item.Description> */}
                {/* <div className='item-desc-container' dangerouslySetInnerHTML={{__html: item.description}}>
                    </div> */}
                {/* </Item.Description> */}
                <Item.Extra
                  style={{ margin: "-1.5rem 0 0" }}
                  className={`container-${type}__extra`}
                >
                  <div className="item-extra-info">
                    <div>
                      <Icon color="grey" name="clock outline" />{" "}
                      {DateFormatter(item.Pubdate)}
                    </div>
                    <div
                      class="d-inline-block text-truncate"
                      style={{ maxWidth: "170px" }}
                    >
                      {item.Source}
                    </div>
                  </div>
                </Item.Extra>
              </Item.Content>
            </Item>
          );
        })}
      </Item.Group>
    </div>
  );
}

ItemFeed.propTypes = {
  type: propTypes.string,
};
ItemFeed.defaultProps = {
  type: "rss",
};

export default ItemFeed;
