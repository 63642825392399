import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import DialogComponent from './../../../../Global/Dialog';

export const columns = (deletePO, duplicatePO) => [
  {
    key: 'PONumber',
    label: 'PO No',
    minWidth: 60,
    align: 'center',
    fixed: true,
    custom: (value) => (
      <Col md={12} xl={12}>
        <Row>
          <Col md={12}>
            <Link to={'/po-detail-view/' + value.POId}>{value.PONumber}</Link>
          </Col>
        </Row>
      </Col>              
    )
  },
  {
    key: 'StatusDesc',
    label: 'Status',
    minWidth: 60,
    align: 'center',
    custom: (value) => ( <Button className={value.StatusId === 13 ? 'btn-oval _gray_button': 'btn-oval'} color={'primary'} onClick={() =>{}}>{value.StatusDesc}</Button> )
  },
  {
    key: 'SupplierName',
    label: 'Supplier',
    minWidth: 120,
    align: 'center'
  },
  {
    key: 'ConsigneeName',
    label: 'Consignee',
    minWidth: 80,
    align: 'center'
  },
  {
    key: 'DeliveryName',
    label: 'Ship to',
    minWidth: 80,
    align: 'center'
  },
  {
    key: 'DistributorPO',
    label: 'Distributor PO',
    minWidth: 80,
    align: 'center'
  },
  {
    key: '',
    label: ' ',
    minWidth: 40,
    align: 'center',
    custom: (value) => (
      <DialogComponent
        disabled={false}
        color={'primary'}
        icon={'control_point_duplicate'}
        actionText={'Duplicate'}
        title='Duplicate'
        message='Are you sure do you want to duplicate the PO? If you continue the PO will be duplicated'
        cancelText='Cancel'
        agreeText='Yes, I´m sure'
        agree={()=> duplicatePO(value)}
      />              
    )
  },
  {
    key: '',
    label: ' ',
    minWidth: 40,
    align: 'center',
    custom: (value) => (
      <DialogComponent
        disabled={false}
        color={'secondary'}
        icon={'delete_outline'}
        actionText={'Delete'}
        title='Delete'
        message='Are you sure do you want to delete the PO? If you continue the PO will be deleted'
        cancelText='Cancel'
        agreeText='Yes, I´m sure'
        agree={()=> deletePO(value)}
      />             
    )
  }
];
