import React, { Component } from 'react';
import Table from '../../../../../template/Table';
import ContentWrapper from '../../../../../template/Layout/ContentWrapper';
import { Tab } from 'semantic-ui-react';
import containerImage from '../../../../../../resources/container.png';
import {withRouter} from 'react-router-dom';
import ContainerSummaryDetail from './summary';
import ContainerEventsDetail from './events';
import axios from 'axios';
import { Services } from '../../../Services';


class ContainerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      container: {},
      allSteps: [],
      steps: [
        {
          label: 'Empty Returned',
          value: '',
          id: 126,
          completed: false,
          order: 1
        },
        {
          label: 'Delivered',
          value: '',
          id: 125,
          completed: false,
          order: 2
        },
        {
          label: 'Customs Released',
          value: '',
          id: 98,
          completed: false,
          order: 3
        },
        {
          label: 'Discharged',
          value: '',
          id: 122,
          completed: false,
          order: 4
        },
        {
          label: 'Arrived',
          value: '',
          id: 91,
          completed: false,
          order: 5
        },
        {
          label: 'Departed',
          value: '',
          id: 62,
          completed: false,
          order: 6
        },
        {
          label: 'Container Loading',
          value: '',
          id: 121,
          completed: false,
          order: 7
        }
      ]
    };
  }

  componentDidMount = () => {
    this.getContainer();
  }

  getContainer = async() => {
    let idContainer = this.props.match.params.id;
    let moduleId = 4;

    let container = await axios.get(`${Services.BOOKING.path}containers-booking/container/${idContainer}`);
    let containerRes = container.data && container.data.data ? container.data.data: {};

    let events = await axios.get(`${Services.BOOKING.path}containers-booking/containerEventsTimeline/${moduleId}/${containerRes.BookingContainerId}`);
    let eventsRes = events.data && events.data.data ? events.data.data: [];

    var newSteps = this.state.steps;
    let dataEvents = eventsRes;
    var findNext = false;

    newSteps.map((f) => {
      dataEvents.map((e) => {
        if(findNext){
          f.completed = true;
        }
        if(e.EventDescriptionId === f.id){
          findNext = true;
          f.value = e.EventDate;
          if(e.InTimeLine === '1'){
            f.completed = true;
          }
        }
      });
    });
    this.setState({ container: containerRes, steps: newSteps, allSteps: dataEvents });
  }


  render() {
    let {container, steps, allSteps} = this.state;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <legend><img src={containerImage} width="26" height="26" className="mr-2" ></img><label>Container Details</label></legend>
          </div>
        </div>
        <Tab
          menu={{ secondary: true,className:'myWrapped'  }}
          menuPosition='right'
          panes={[
            {menuItem: 'Summary', render: () => <ContainerSummaryDetail container={container} steps={steps}/>},
            {menuItem: 'Events', render: () => <ContainerEventsDetail container={container} steps={steps} allSteps={allSteps} />},
          ]}/>
      </ContentWrapper>
    );
  }
}

export default withRouter(ContainerDetail);
