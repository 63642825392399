import React, {Component} from 'react';
// import {Card,CardBody} from 'reactstrap';
import BookingModel from '../../../../models/bookingModel';
import Card from './../../../Global/Card/';

const DatosEstaticos = [
  {
    label: 'Booking Summary',
    data: [
      {
        value: '',
        key: 'BookingNo',
        label: 'Booking No.',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'Supplier',
        label: 'Supplier',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'Consignee',
        label: 'Consignee',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'Delivery',
        label: 'Delivery',
        bold: false,
        info: false
      }
    ]
  },
  {
    label: 'Carrier Details',
    data: [
      {
        value: '',
        key: 'Carrier',
        label: 'Carrier',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'Vessel',
        label: 'Vessel',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'Voyage',
        label: 'Voyage',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'FreightForwarder',
        label: '3PL',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'FreightForwarderReference',
        label: '3PL Reference',
        bold: false,
        info: false
      }
    ]
  },
  {
    label: 'Route',
    data: [
      {
        value: '',
        key: 'Origin',
        label: 'Origin',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'Destination',
        label: 'Destination',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'DocumentCutOff',
        label: 'Document Cut-off',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'CargoCutOff',
        label: 'Cargo Cut-off',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'ETD',
        label: 'ETD',
        bold: false,
        info: false
      },
      {
        value: '',
        key: 'ETA',
        label: 'ETA',
        bold: false,
        info: false
      }
    ]
  }
];

class MainInfo extends Component{
  state = {
    containerInfo: DatosEstaticos,
    id: this.props.id
  }

  componentDidMount = () => {
    let { id, containerInfo } = this.state;
    let data = BookingModel[1];
    if(id === 'BO000001'){
      data = BookingModel[0];
    }
    containerInfo.map(e =>{
      e.data.map(f => {
        f.value = data[f.key];
        return f;
      });
      return e;
    });
    this.setState({containerInfo: containerInfo});
  }

  render(){
    return(
      <Card classes={'col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3 p-3'}>
        {
          this.state.containerInfo.map((e, index) =>(
            <React.Fragment key={index}>
              <div className="col-12">
                <span className="mr-3" style={{fontWeight:'bold', color:'black'}}>{e.label}</span>
              </div>
              {
                e.data.map((ee , index1) => (
                  <React.Fragment>
                    <div key={index1} className="col-6">
                      <span style={{fontWeight:'bold',fontSize:'12px'}}>{ee.label}</span>
                    </div>
                    <div className="col-6" style={{textAlign: 'right'}}>
                      <span style={{fontSize:'12px'}}>{ee.value}</span>
                    </div>
                  </React.Fragment>
                ))
              }
              <div className="col-12" style={{height: 20}} />
            </React.Fragment>
          ))
        }
      </Card>
    );
  }
}

export default MainInfo;
