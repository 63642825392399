import React, { Component, Suspense } from 'react';
// import restService from '../../../../services/restService';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import Axios from 'axios';
import { Services } from './../Services';
import { withRouter } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Loader from './../../../Global/Loader/';
import './styles/main.scss';
const Summary = React.lazy(() => import('./components/Summary'));
const Document = React.lazy(() => import('./components/Documents'));
const Comments = React.lazy(() => import('./components/Comments'));
const Events = React.lazy(() => import('./components/Events'));
const SplitedOrders = React.lazy(() => import('./components/SplittedOrders'));

class FormExtended extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      list: [],
      deliveriesArray: [],
      PONumber: '',
      fullLoader: false,
      tabIndex: 0
    };
  }

  /*=============================================
    =            Build Deliveries function        =
    =============================================*/

  buildArraydeliveries = () => {
    const { list } = this.state;
    let array = [];
    list.forEach((e) => array.push({ value: e.PONumber, label: e.PONumber }));
    this.setState({ deliveriesArray: array });
  }


  /*=============================================
  =            Get Order List Function          =
  =============================================*/

  getOrdersList = async () => {
    try {
      // const url = 'https://poseidonorder-dev.azurewebsites.net';
      // let resp = await Axios.get(`${url}/`);
      let resp = await Axios.get(`${Services.ORDER.path}order/search/all`);
      if (resp.data && resp.data.success)
        this.setState({ list: resp.data.data }, () => this.buildArraydeliveries());
    } catch (error) {
      console.error('getOrders:(error)');
    }

  }

  /*=============================================
  =            Life cycle component             =
  =============================================*/

  componentDidMount = () => {
    this.getOneOrder();
    this.getOrdersList();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getOneOrder(this.props.location.pathname.split("/")[2]);
    }
  }
  /*=====  End of Life cycle component  ======*/


  calculateTotals = async (items) => {
    // console.log("receive the items ===>", items);
    let totals = { 'QtyConfirmed': 0, 'QtyOrdered': 0, 'QtyReceived': 0, 'NetWeightItem': 0 };
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.QtyConfirmed && parseInt(element.QtyConfirmed)) {
        totals['QtyConfirmed'] += parseInt(element.QtyConfirmed);
      }
      if (element.QtyOrdered && parseInt(element.QtyOrdered)) {
        totals['QtyOrdered'] += parseInt(element.QtyOrdered);
      }
      if (element.QtyReceived && parseInt(element.QtyReceived)) {
        totals['QtyReceived'] += parseInt(element.QtyReceived);
      }
      if (element.NetWeightItem && parseInt(element.NetWeightItem)) {
        totals['NetWeightItem'] += parseInt(element.NetWeightItem);
      }
    }
    return totals;
  }
  /*=============================================
  =            GET order data                   =
  =============================================*/

  getOneOrder = async (param) => {

    try {
      // const url = 'https://poseidonorder-dev.azurewebsites.net';
      // let url = 'http://localhost:3004';
      // let resp = await Axios.get(`${url}/order/${param ? param: this.props.match.params.id}?type=shipment`);
      let resp = await Axios.get(`${Services.ORDER.path}order/${param ? param : this.props.match.params.id}?type=shipment`);
      // console.log('this is the response ===>', resp.data);
      if (resp.data.data) {
        if (resp.data.data.items.length > 0) {
          // let dataVal = resp.data.data;
          let totals = await this.calculateTotals(resp.data.data.items);
          let NetWeightVal = 0;
          if (totals.NetWeightItem > 0) {
            NetWeightVal = totals.NetWeightItem;
          } else {
            NetWeightVal = parseInt(resp.data.data.NetWeight) ? resp.data.data.NetWeight : 0;
          }

          // console.log("the totals", totals);
          // let qtyUpdated = await Axios.post('http://localhost:3004/po-line/update/po/qty', {
          let qtyUpdated = await Axios.post(`${Services.ORDER.path}po-line/update/po/qty`, {
            ordered: totals.QtyOrdered,
            confirmed: totals.QtyConfirmed,
            received: totals.QtyReceived,
            netweight: NetWeightVal,
            POId: this.props.match.params.id
          });
          resp.data.data.TotalQtyOrdered = qtyUpdated.data.results.TotalQtyOrdered;
          resp.data.data.TotalQtyReceived = qtyUpdated.data.results.TotalQtyReceived;
          resp.data.data.TotalQtyConfirmed = qtyUpdated.data.results.TotalQtyConfirmed;
          resp.data.data.NetWeight = qtyUpdated.data.results.NetWeight;
        }
        this.setState({
          tabIndex: (typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined') ? this.props.location.state.tabIndex : 0,
          data: resp.data.data,
          PONumber: resp.data.data.PONumber,
          POId: resp.data.data.POId,
          fullLoader: true
        });

      } else {
        // console.log('I´m inside 2');
        this.setState({ fullLoader: true });
      }
    } catch (err) {
      console.error('The error getOrder', err);
      this.setState({ fullLoader: true });
    }
  }
  handleOnTabChange = (evt, data) => {
    // console.log('inside tab Index');
    this.setState({ tabIndex: data.activeIndex });
  }

  setTabIndex = (index) => {
    this.setState({ tabIndex: index });
  }

  render() {
    let { data, fullLoader, tabIndex } = this.state;
    let panes_aux = [
      { menuItem: 'Summary', render: () => <Suspense fallback={<div>Loading...</div>}> <Summary redirTab={this.setTabIndex} data={data} id={this.state.POId} _user={this.props._user} getAllInfo={this.getOneOrder}></Summary></Suspense> },
      { menuItem: 'Events', render: () => <Suspense fallback={<div>Loading...</div>}><Events rolId={this.props._rolId} data={data} id={this.state.POId}></Events> </Suspense> },
      { menuItem: 'Comments', render: () => <Suspense fallback={<div>Loading...</div>}><Comments data={data} id={this.state.POId} _user={this.props._user} _customer={this.props._customer} updateInfo={this.getOneOrder}></Comments></Suspense> },
      { menuItem: 'Documents', render: () => <Suspense fallback={<div>Loading...</div>}><Document rolId={this.props._rolId} data={data} id={this.state.POId} _user={this.props._user}></Document></Suspense> }
    ];

    if (data && data.IsSplitOrder === '1') {
      panes_aux.push({ menuItem: 'Splitted Orders', render: () => <Suspense fallback={<div>Loading...</div>}><SplitedOrders redirTab={this.setTabIndex} data={data} rolId={this.props._rolId} id={this.state.POId} _user={this.props._user} reset={this.getOneOrder}></SplitedOrders></Suspense> });
    }

    return (
      <ContentWrapper>
        <Loader hidden={fullLoader} />
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="icon--po_titleicon"></em>
                Purchase Order View Details
              </div>
          </div>
        </div>
        <Tab
          menu={{ secondary: true, pointing: true, className: 'myWrapped' }}
          activeIndex={tabIndex}
          onTabChange={this.handleOnTabChange}
          menuPosition='right'
          panes={panes_aux} />
      </ContentWrapper>
    );
  }
}
export default withRouter(FormExtended);