import lod from 'lodash';


/*=============================================
=            Format Values Function           =
=============================================*/

function formatValues (array, value, key) {
  let otherArray = [];
  lod.forEach(array, (val, ind) => {
    if (typeof val[key] === 'number' ? val[key] === parseInt(value) : val[key].toLowerCase().indexOf(value) !== -1)
      otherArray.push(val);
  });
  
  return otherArray;
}


/*=============================================
=            Filter Helper Function           =
=============================================*/

export const FilterHelper = (criterials, data) => {
  let search = {};
  let keys = ['year', 'month', 'division', 'cruise', 'country'];
  let newArray = [];
  for (let i= keys.length; i >= 0; i--) {
    if (criterials[keys[i]]) 
      search[keys[i]] = criterials[keys[i]].toLowerCase();
  }

  lod.forEach(keys, (value, index) => {
    let dataAux = '';
    if (search[value]) {
      if (value === 'year') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }
      if(value == 'month') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value == 'division') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value == 'cruise') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value == 'country') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }
    }
  });

  return newArray;

};
