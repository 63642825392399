import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import FormCard from "../../Forms";
import StepperEvent from "../../../../../../Components/StepperEvent";
import {
  ItemDetailResumeForm,
  ItemDetailResumeFormDates,
} from "../../../../template";

const ItemSummary = ({
  item,
  handleEditItem,
  index,
  update,
  handleSendErrorMessage,
  isEdit,
  lng,
}) => {
  const [poItem, setPoItem] = useState(item);
  const [changeValue, setChangeValue] = useState(true);
  const [formDetailFields, setFormDetailFields] = useState({...ItemDetailResumeForm});
  const [formDetailDatesFields, setFormDetailDatesFields] = useState({...ItemDetailResumeFormDates});

  useEffect(() => {
    setPoItem(item);
    validateEditableFields();
  }, [item]);

  const updateEntityInfo = (id, value) => {
    let newPoItem = { ...poItem };
    newPoItem[id] = value;
    setPoItem(newPoItem);
    setChangeValue(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateEditableFields = () => {
    const noEditableFields = [
      'QTYOrdered',
      // 'QTYConfirmed',
      'NetWeight',
      'RequestedExWorks',
      'RequiredCagoReady',
    ]
    let newFormDetailFields = { ...ItemDetailResumeForm };
    let newFormDetailDatesFields = { ...ItemDetailResumeFormDates };
    newFormDetailFields.edit = [];
    newFormDetailDatesFields.edit = [];
    ItemDetailResumeForm.edit.forEach((field) => {
      const copyField = { ...field };
      if (item.StatusId > 47 && noEditableFields.includes(field.id)) {
        copyField.type = 'Label';
      }
      newFormDetailFields.edit.push(copyField);
    });
    ItemDetailResumeFormDates.edit.forEach((field) => {
      const copyField = { ...field };
      if (item.StatusId > 47 && noEditableFields.includes(field.id)) {
        copyField.type = 'Label';
      }
      newFormDetailDatesFields.edit.push(copyField);
    });
    setFormDetailFields(newFormDetailFields);
    setFormDetailDatesFields(newFormDetailDatesFields);
  };

  const updateItem = async () => {
    let isValid = true;
    ItemDetailResumeForm.edit.forEach((field) => {
      if (field.required && !poItem[field.id]) {
        isValid = false;
      }
    });
    ItemDetailResumeFormDates.edit.forEach((field) => {
      if (field.required && !poItem[field.id]) {
        isValid = false;
      }
    });
    if (!isValid) {
      if (changeValue) {
        setChangeValue(false);
        handleSendErrorMessage();
        return;
      }
      return;
    }
    await update(poItem);
    handleEditItem(index);
  };

  const handleEdit = () => {
    handleEditItem(index, !Boolean(poItem.edit));
  };

  return (
    <div className="row">
      {isEdit && (
        <Row className="col-12">
          <Col xs={12} md={12} lg={12}>
            <button
              className="btn btn-primary float-right"
              style={{ marginBottom: "5px" }}
              onClick={updateItem}
            >
              Update
            </button>
            <button
              style={{ marginBottom: "5px", marginRight: "10px" }}
              className="btn btn-secondary float-right"
              onClick={handleEdit}
            >
              Cancel
            </button>
          </Col>
        </Row>
      )}
      <div className="col-12">
        <StepperEvent steps={poItem.Steps} />
      </div>
      <div className="col-12 row item-resume-form">
        <FormCard
          fields={formDetailFields}
          values={poItem}
          edit={isEdit}
          updateEntityInfo={updateEntityInfo}
          lng={lng}
        />
      </div>
      <div className="col-12 item-resume-date-title" style={{ paddingLeft: 0 }}>
        <div className="card-header">
          <div className="card-title" style={{ fontWeight: "bold" }}>
            {ItemDetailResumeFormDates.titulo}
          </div>
        </div>
      </div>
      <div className="col-12 row">
        <FormCard
          fields={formDetailDatesFields}
          values={poItem}
          edit={isEdit}
          updateEntityInfo={updateEntityInfo}
          lng={lng}
        />
      </div>
    </div>
  );
};

export default withRouter(ItemSummary);
