import React, { Component, Suspense } from 'react';
import StepperEvent from '../../../../../Components/StepperEvent';
import Table from '../../../../../Components/Table';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Services } from '../../../Services';
import dummy from '../../../dummy'
import axios from 'axios';
import './styles.css';

const CardInfo = React.lazy(() => import('../../../../../Components/Cards'));

class Summary extends Component {
  state = {
    FechaActualizacion: '',
    doImportImpo: '',
    DocTransporte: '',
    HBL_HAWB: '',
    id: this.props.match.params.id,
    data: [],
    steps: [
      {
        label: 'Instrucción Cliente AA',
        value: '',
        id: 3,
        completed: false,
        order: 1,
        middleStep: '',
      },
      {
        label: 'ATA (Manifiesto)',
        value: '',
        id: 5,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Aceptación Solicitud DTA',
        value: '',
        id: 19,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: '',
      },
      {
        label: 'Autorización Inspección',
        value: '',
        id: 20,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: '',
      },
      {
        label: 'Salida DTA',
        value: '',
        id: 21,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: '',
      },
      {
        label: 'Finalización DTA',
        value: '',
        id: 22,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: '',
      },
      {
        label: 'Radicación de Factura',
        value: '',
        id: 16,
        completed: false,
        order: 7,
        idStep: 'step7',
        middleStep: '',
      },
    ],
    columns: [
      {
        label: 'Número de Remisión',
        key: 'NoRemision',
        idTh: 'firstTh',
        idTd: 'firstTd',
      },
      { label: 'Transportador', key: 'Transportador' },
      { label: 'Cantidad de Piezas', key: 'CantidadPiezas' },
      { label: 'Tipo de Embalaje', key: 'TipoEmbalaje' },
      { label: 'Dirección de Entrega', key: 'DireccionEntrega' },
      { label: 'Ciudad de Entrega', key: 'CiudadEntrega' },
    ],
    CardIndoHeaders: {
      title: 'Aduana DTA',
      subTitle: '',
      Icon: 'fa-3x mr-1 fas fa-arrow-alt-circle-right',
      style: { color: '#A7A8A9' },
    },
    Info: [
      {
        Referencia: 'ID8374',
        Aduana: 'Cruise 87',
        Proyecto: 'Roldan',
        Totales: 'KJH7687',
        Iniciales: '$2000,000',
        Anticipadas: '',
        Ordinarias: '',
        Temporales: '',
        TotalIVA: '$200,000',
        TotalAraceles: '$200,000',
        TotalTributos: '$400,000',
      },
    ],
    config: [
      {
        title: 'No. de Referencia del Cliente',
        xls: true,
        id: 'RefCliente',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'Proyecto ',
        xls: true,
        id: 'Proyecto',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Aduana',
        xls: true,
        id: 'Aduana',
        className: 'col-md-3 col-lg-1 col-sm-6',
      },
      {
        title: 'Trasportador Internacional',
        xls: true,
        id: 'TransportadorInternacional',
        className: 'col-md-3 col-lg-3 col-sm-6',
      },
      {
        title: 'No. Autorización DTA',
        xls: true,
        id: 'NoAutorizacionDTA',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
      {
        title: 'FOB',
        xls: true,
        id: 'TotalFOB',
        className: 'col-md-3 col-lg-2 col-sm-6',
      },
    ],
  };
  componentDidMount = () => {
    this.getDataForId();
    this.setState({
      steps: this.props.steps,
      HBL_HAWB: this.props.HBL_HAWB,
      DocTransporte: this.props.DocTransporte,
      doImportImpo: this.props.doImportImpo,
      Estatus: this.props.Estatus,
      cliente: this.props.cliente,
      NIT: this.props.NIT,
      Grupo: this.props.Grupo,
      FechaActualizacion: this.props.FechaActualizacion,
    });
  };
  componentWillReceiveProps = (nextProps) => {
    this.getDataForId();
    this.setState({
      steps: nextProps.steps,
      HBL_HAWB: nextProps.HBL_HAWB,
      DocTransporte: nextProps.DocTransporte,
      doImportImpo: nextProps.doImportImpo,
      Estatus: nextProps.Estatus,
      cliente: nextProps.cliente,
      NIT: nextProps.NIT,
      Grupo: nextProps.Grupo,
      FechaActualizacion: nextProps.FechaActualizacion,
    });
  };

  getDataForId = () => {
    // axios
    //   .get(
    //     `${Services.Get_Traceability_Imports_ForId.path}/customs/dataCustomImports/${this.state.id}`,
    //     { params: { position: 'importDta' } }
    //   )
    //   .then((success) => {
      let success = dummy.infoDetailDtas;

        success.data.infoImport.map((e) => {
          e.TotalFOB = `${ e.TotalFOB}`;
          return e;
        });

        let Info = [];
        Info.push(success.data.infoImport[0]);

        this.setState({
          Info,
          data: success.data.Remisiones,
          doImportImpo: success.data.infoImport
            ? success.data.infoImport[0].DOAduanaImpo
            : '',
        });
      // })
      // .catch((error) => {
      //   console.warn(error);
      // });
  };

  toggleDrawerMap = (data) => (evt) => {
    // this.setState({ visible: true, dataDrawer: data });
  };

  render() {
    let { data, columns, cliente, NIT, Grupo, FechaActualizacion } = this.state;
    let title = 'Remisión';
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <div className="row" style={{ marginLeft: 15 }}>
              <em
                style={{ color: 'rgb(167, 168, 169)' }}
                className="fa-3x mr-2 fas fa-arrow-alt-circle-right"
              ></em>
              <Col md={12} xl={11}>
                <Row>
                  <Col md={4} lg={6} sm={12}>
                    <strong style={{ color: '' }}>
                      {cliente} ({NIT}) / {Grupo}
                    </strong>
                    <br />
                    <strong style={{ color: '#0099ff' }}>
                      {this.state.doImportImpo}
                    </strong>
                    <br />
                    {this.state.DocTransporte}
                  </Col>
                  <Col md={8} xl={6}>
                    <div
                      style={{ textAlign: 'right' }}
                      className="date_content"
                    >
                      <strong style={{ fontSize: '13px' }}>
                        {' '}
                        <i>Última Actualización:</i> {FechaActualizacion}
                      </strong>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
            <div className="col-12" /* style={{background: 'pink'}} */>
              <div className="row">
                <div className="col-4" /* style={{background:'pink'}} */>
                  {this.state.Estatus}
                </div>
              </div>
            </div>
            <div className="col-12">
              <StepperEvent steps={this.state.steps} />
              <br />
              {/* <br/> */}
            </div>
          </CardBody>
        </Card>
        <Suspense fallback={<div>Loading...</div>}>
          {' '}
          <CardInfo
            toggleDrawer={this.toggleDrawerMap}
            CardIndoHeaders={this.state.CardIndoHeaders}
            config={this.state.config}
            data={this.state.Info}
            cardInfon={true}
          ></CardInfo>
        </Suspense>{' '}
        <br />
        {/* <br/> */}
        <Card className="myCard">
          <CardBody>
            <div className="row">
              <div className="col-12">
                <legend>{title}</legend>
              </div>
              <div className="col-12">
                <Table
                  columns={columns}
                  data={data}
                  options={{
                    large: false,
                    downloads: {
                      pdf: false,
                      xlsx: false,
                    },
                  }}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default withRouter(Summary);
