import React from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, Progress} from 'reactstrap';
import Popover from '../../../../../Global/Popup';
// import Popover from '../popover';
import './styles/cards.scss';

const status_ = {
  1: <em style={{color:'#42ADEC'}} className=" fa-2x icon--shipment_transport02 mr-2 "></em>,
  2: <em style={{color:'#42ADEC'}} className="fa-2x icon--shipment_transport03 mr-2"></em>,
  3: <em style={{color:'#42ADEC'}} className="fa-2x icon--shipment_transport03 mr-2"></em>,
  4: <em style={{color:'#42ADEC'}} className="fa-2x icon--shipment_transport03 mr-2"></em>,
  5: <em style={{color:'#42ADEC'}} className="fa-2x icon--shipment_transport03 mr-2"></em>,
  6: <em style={{color:'#42ADEC'}} className="fa-2x icon--shipment_transport03 mr-2"></em>,
  7: <em style={{color:'#42ADEC'}} className="fa-2x icon--shipment_transport03 mr-2"></em>
};

function CardComponent(props){
  let { element } = props;
  return (
    // <div className="col-12">
    <Card className="_clickCard myCard" id={element.id}>
      <CardBody>
        <div className="row">
          <div  className="col-md-1 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject">
                {status_[element.TransportMode.id]} 
                <Link to={'/tracking/' + element.id} style={{ textDecoration: 'none' }}>
                  <span style={{ fontSize:'12.5px', color: '#0097dc', 'fontWeight': 'bold' }}>{element.id}</span>
                </Link>
                {/* -<span style={{fontSize:"11px"}}>{element.BookingNumber}</span> */}
              </div>
              <div className="mb-mail-from">
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                  {element.Status.label}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-2 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject"><center>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Supplier
                </span>
              </center></div>
              <div className="mb-mail-from"><center>
                <span style={{ fontSize: '12px' }}>
                  {element.Supplier.name}
                </span>
              </center>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject"><center>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Consignee
                </span>
              </center></div>
              <div className="mb-mail-from"><center>
                <span style={{ fontSize: '12px' }}>
                  {element.Consignee.name ? element.Consignee.name : ''}
                </span>
              </center>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject"><center>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Project
                </span>
              </center></div>
              <div className="mb-mail-from"><center>
                <span style={{ fontSize: '12px' }}>
                  {element.Project.name ? element.Project.name : ''}
                </span>
              </center>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject">
                <center>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                      PO Number
                  </span>
                </center>
              </div>
              <div className="mb-mail-from">
                <center>
                  <span style={{ fontSize: '12px' }}>
                    {/* {element.PONumber} */}
                    {/* <Popover info={element.PONumber}/> */}
                    <Popover title={'POs'} content={element.PONumber}>
                      {element.PONumber}
                    </Popover>

                  </span>
                </center>
              </div>
            </div>
          </div>

          <div className="col-md-2 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject">
                <center>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        Containers
                  </span>
                </center>
              </div>
              <div className="mb-mail-from">
                <center>
                  <span style={{ fontSize: '12px' }}>
                    {/* {element.ContainersNum} */}
                    <Popover title={'Containers'} content={element.ContainersNum}>
                      {element.ContainersNum}
                    </Popover>                    
                  </span>
                </center>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject">
                <center>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    <em className="fas fa-map-marker-alt mr-2"></em>
                    <span>From </span>
                  </span>
                </center>
              </div>
              <div className="mb-mail-from"><center>

                <span style={{ fontSize: '12px' }}>
                  {element.From.Location.country}
                </span>
              </center></div>
            </div>
          </div>
          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject">
                <center>
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    <em className="fas fa-map-marker-alt mr-2"></em>
                    <span>To </span>
                  </span>
                </center>
              </div>
              <div className="mb-mail-from">
                <center>
                  <span style={{ fontSize: '12px' }}>
                    {element.To.Location.country}
                  </span>
                </center>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject"><center>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    ETD
                </span>
              </center></div>
              <div className="mb-mail-from">
                <center>
                  <span style={{ fontSize: '12px' }}>
                    {element.ETD}
                  </span>
                </center>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-1 col-sm-6">
            <div className="mb-mail-meta">
              <div className="mb-mail-subject"><center>
                <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    ETA
                </span>
              </center></div>
              <div className="mb-mail-from"><center>
                <span style={{ fontSize: '12px' }}>
                  {element.ETA}
                </span></center></div>
            </div>
          </div>
          <div className="col-md-12 col-lg-1 col-sm-6">
            <div className="d-flex">
              <div className="mb-mail-meta" style={{ width: '100%' }}>
                <div className="mb-mail-subject">
                  <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {element.LastUpdate.porcent < 50 ? (element.LastUpdate.exception ? <span style={{ color: 'green' }}>On Time</span> : <span style={{ color: 'red' }}>Delayed</span>) : <span style={{ color: 'green' }}>On Time</span>}
                  </span>
                </div>
                <div className="mb-mail-subject">
                  <Progress color={element.LastUpdate.porcent < 50 ? (element.LastUpdate.exception ? 'success' : 'danger') : 'success'} value={element.Progress } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
    // </div>
  );
};

export default CardComponent;