import React, { Component } from 'react';
import ContentWrapper from '../../../../template/Layout/ContentWrapper';
import { Table, Card, CardBody } from 'reactstrap';
import Details from './Details';
import checked from '../../../../../resources/checked.png'

class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: {},
        };
    };

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            order: nextProps.order
        })
    }

    componentDidMount = () => {
        this.setState({
            order: this.props.order
        })
    }
    render() {
        const { order } = this.props;
        return (
            <Card style={{ height: '800px' }}>
                <CardBody >
                    <div className="row">
                        <div className="col-12">
                            <center><span>{`PO ${order.orderDetail.PONumber} was confirmed`}</span></center>
                        </div>
                        <div className="col-12">
                            <hr></hr>
                        </div>
                        <div className="col-12" style={{paddingTop:'10%'}}>
                            <center><img src={checked} height={200} width={200} ></img></center>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    };
};
export default FormExtended