const Services = {
  RSS: {
    path: "https://roldanapirss.azurewebsites.net",
    // path: 'http://localhost:8000',
    method: "GET",
    descrip: "Get RSS Feeds",
  },
};

export default Services;
