import React, { Component } from 'react';
import Cards from '../../../Components/CardsR';
import { Pagination } from 'semantic-ui-react';
import { Services } from '../Services';
import axios from 'axios';
import dummy from '../dummy'

class Ingresos extends Component {
  state = {
    clientesSelect:this.props.clientesSelect,
    activePage:1,
    limit: 10,
    numberPage: 1,
    totalPages: 1,
    data: [],
    paramsxml: {},
    config:[
      { title: 'Ingreso', id2:'Estatus',  titleId: 'Estatus', xls: true ,foot: { show: true, style: { fontSize: '12px', fontWeight: 'bold' } }, data: { link: false, progress: false, icon: true, status: true, }, id: 'Estatus', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: '', titleId: 'NoIngreso', idExcel: 'NoIngreso', xls: true, foot: { show: false, style: { fontSize: '12px', fontWeight: 'bold', color: 'green' } }, data: { link: false, progress: false, icon: false, status: false }, header: { link: true }, idlink: 'IngresoId', id: 'RefCliente', link: '/storage-entries-detail/', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Referencia del Cliente', idExcel: 'RefCliente', xls: true, id: '', className: 'hidden', header: {hide: true} },
      { title: 'Cliente', xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'Cliente', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Tipo de Almacén', xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'TipoAlmacen', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Doc. de Transporte', xls: true, colorLogo: '#643296c2', id: 'DocTransporte', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'DO de Aduana', xls: true, id: 'DoAduana', className: 'col-md-3 col-lg-1 col-sm-6', },
      { title: 'Notificación de Ingreso', xls: true, id: 'FechaNotificacionIngreso', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Almacén', xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'Almacen', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Tipo de Vehículo', xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'TipoVehiculo', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Llegada a Almacén', viewCard:false ,xls: true, data: { link: false, progress: false, icon: false, status: false }, id: 'FechaLlegadaAlmacen', className: 'col-md-3 col-lg-1 col-sm-6' },
    ],
    isSearch: false,
    url: '',
    advancedSearch: [
      {
        title: 'Información del Almacén',
        data: [
          [
            {
              title: 'No de Ingreso', type: 'text', id: 'NoIngreso', class: 'col-3', values: [], value: ''
            },
            {
              title: 'Cliente', type: 'select', id: 'Cliente', class: 'col-3', values: [], value: ''
            },
            {
              title: 'Tipo de Almacen', type: 'select', id: 'TipoAlmacen', class: 'col-3', values: [], value: ''
            },
            {
              title: 'Almacen', type: 'select', id: 'Almacen', class: 'col-3', values: [], value: ''
            },

          ],
          [
            {
              title: 'Documento de Transporte', type: 'text', id: 'DocTransporte', class: 'col-3', values: [], value: ''
            },
            {
              title: 'Referencia de Cliente', type: 'text', id: 'RefCliente', class: 'col-3', values: [], value: ''
            },
            {
              title: 'Tipo de Vehículo', type: 'select', id: 'TipoVehiculo', class: 'col-3', values: [], value: ''
            },
            {
              title: 'Estatus',UseIN:true, type: 'multipleSelect', id: 'Estatus', class: 'col-3', values: [], value: ''
            }
          ]
        ]
      },
      {
        title: 'Fechas',
        data: [
          [
            {
              title: 'Notificación de Ingreso', type: 'dateRange', id: 'FechaNotificacionIngreso', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            },
            {
              title: 'Llegada a Almacén', type: 'dateRange', id: 'FechaLlegadaAlmacen', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            }
          ],
          [
            {
              title: 'Inicio de Descargue', type: 'dateRange', id: 'FechaInicioDescargue', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            },
            {
              title: 'Fin de Descargue', type: 'dateRange', id: 'FechaFinDescargue', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            }
          ],
          [
            {
              title: 'Manifiesto (Público)', type: 'dateRange', id: 'FechaManifiesto', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            },
            {
              title: 'Vencimiento del Manifiesto', type: 'dateRange', id: 'FechaVencimientoManifiesto', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            },
          ],
          [
            {
              title: 'Disponibilidad de Inventario', type: 'dateRange', id: 'FechaDisponibilidadInventario', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            },
            {
              title: 'Fecha de Devolución de Contenedor', type: 'dateRange', id: 'FechaDevolucionContenedor', class: 'col-6', values: [], value: '', date:{ init: '', end: ''}
            },
          ]
        ]
      }
    ],
    advancedObj: [],
    search: ''
  }

  componentDidMount = () =>{
    this.getListIncome();
    this.getCatalogsIng();
  }

  getCatalogsIng = () => {
    let data = {
      client:this.state.clientesSelect,
    };
    let success = dummy.Catalogos;
    // axios.get(`${Services.Get_Traceability_Imports.Path}customs/allCustomImports`, {params:{EsDTA:0, search: this.state.search, allData: true}} ).then(success => {
    // axios.post(`${Services.Get_storageIncome.path}/catalog/catalogIngreso`, data).then(success => {
      
      let TipoAlmacen = success.data.TipoAlmacen.map(e =>{return { value: e.TipoAlmacen, label: e.TipoAlmacen ? e.TipoAlmacen : 'Sin Valor' };});
      let Almacen = success.data.Almacen.map(e =>{return { value: e.Almacen, label: e.Almacen ? e.Almacen : 'Sin Valor' };});
      let TipoVehiculo = success.data.TipoVehiculo.map(e =>{return { value: e.TipoVehiculo, label: e.TipoVehiculo ? e.TipoVehiculo : 'Sin Valor' };});
      // let modalidad = [];
      let Estatus = success.data.Estatus.map((e,index) => { return { key: e.Estatus, text: e.Estatus, value: e.Estatus  ? e.Estatus : 'Sin Valor' }; });
      let Cliente = success.data.Cliente.map(e =>{return { value: e.Cliente, label: e.Cliente ? e.Cliente : 'Sin Valor' };});

      let newAdvanced = this.state.advancedSearch;
      newAdvanced.map(e =>{
        e.data.map(f =>{
          f.map(g =>{
            if (g.id === 'Estatus') {
              g.values = Estatus;
            }
            if(g.id === 'TipoAlmacen'){
              g.values = TipoAlmacen;
            }
            if(g.id === 'Almacen'){
              g.values = Almacen;
            }
            if(g.id === 'TipoVehiculo'){
              g.values = TipoVehiculo;
            }
            if(g.id === 'Cliente'){
              g.values = Cliente;
            }
            return g;
          });
          return f;
        });
        return e;
      });
      this.setState({
        advancedSearch: newAdvanced
      });
    // }).catch(error => {
    //   console.warn(error);
    // });
  }

  newSearch = (val) =>{
    if(val.length < 1){
      this.setState({ search: val, isSearch: false, activePage:1, limit:10,
        numberPage: 1,
        totalPages: 1},()=> { this.getListIncome();});
    }
    else{
      if(val.length < this.state.search.length){
        this.setState({ search: val, activePage:1, limit:10,
          numberPage: 1,
          totalPages: 1},()=> { this.getListIncome();});
      }
      else{
        this.setState({ search: val},()=> { this.getListIncome();});
      }
    }
  }

  handleChange = name => event => {
    let val = event.target.value;
    if(!this.state.isSearch){
      this.setState({isSearch: true}, () =>{
        this.setState({ search: val, activePage:1,
          limit:10,
          numberPage: 1,
          totalPages: 1},()=> { this.getListIncome();});
      });
    }
  }

  setAdvanced = (val) =>{
    this.setState({advancedObj: val}, () =>{ this.getListIncome();});
  }

  changePage = page => {
    this.setState({
      numberPage: page,
      activePage:page
    }, () => {
      this.getListIncome();
    });
  }

  getListIncome = () =>{
    // axios.get(`${Services.Get_storageIncome.Path}storage/IncomeData`, {params:{pag:this.state.numberPageIngresos,limit:this.state.limitIngresos,search: this.state.searchIngresos}}).then(success => {
    let objPost = {
      pag: this.state.numberPage,
      limit: this.state.limit,
      search: this.state.search,
      advanced: this.state.advancedObj,
      client:this.state.clientesSelect
    };
    this.setState({url: encodeURI(`${Services.Get_storageIncome.path}/storage/allIncomeData?search=${this.state.search}`), paramsxml: objPost});
    // axios.post(`${Services.Get_storageIncome.path}/storage/IncomeData`, objPost).then(success => {
      
      let data = dummy.Ingresos.data.map((e)=>{
        let icon={ icon: 'fa-2x mr-2 fas fa-sort-amount-down', iconstyle: { color: '#001489', marginLeft: '11px' }} ;
        e.icon = icon;
        return e;
      });
      this.setState({
        data:data,
        totalPages:dummy.Ingresos.totalPage,
      });
    // }).catch(error => {
    //   console.warn(error);
    // });
  }

  toggleDrawerMap = data => evt => {
    // this.setState({ visible: true, dataDrawer: data });
  };

  render() {
    const {advancedSearch,config, url, data, activePage, totalPages} = this.state;
    return (
      <>
        <div className="col-12">
          <Cards paramsxml={this.state.paramsxml} clientesSelect={this.state.clientesSelect}  advancedSearchData={advancedSearch} advancedSearch={(val) => {this.setAdvanced(val);}} pagination ={false} newSearch={(value) => {this.newSearch(value);}} config={config} visible={true} toggleDrawer={this.toggleDrawerMap} data={data} dataSearch={data} labelStatus={'closed'} nameXlsx={'Almacen_Ingreso.xlsx'}></Cards>
          {/* <Cards paramsxml={this.state.paramsxml} clientesSelect={this.state.clientesSelect}  urlReport={url} advancedSearchData={advancedSearch} advancedSearch={(val) => {this.setAdvanced(val);}} pagination ={false} newSearch={(value) => {this.newSearch(value);}} config={config} visible={true} toggleDrawer={this.toggleDrawerMap} data={data} dataSearch={data} labelStatus={'closed'} nameXlsx={'Almacen_Ingreso.xlsx'}></Cards> */}

        </div>
        <div className="col-12">
          <center>
            <Pagination
              activePage={activePage}
              ellipsisItem={null}
              totalPages={totalPages}
              onPageChange={(e, { activePage }) => this.changePage(activePage)} />
          </center>
        </div>
      </>
    );
  }
}

export default Ingresos;
