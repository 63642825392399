


//  Doughnut chart
// -----------------------------------

export const Doughnut = {
    data: {
        labels: [
            'Purple',
            'Yellow',
            'Blue',
            'red'
        ],
        datasets: [{
            data: [300, 500, 100, 500],
            backgroundColor: [
                '#7266ba',
                '#fad732',
                '#23b7e5',
                '#fad732'
            ],
            hoverBackgroundColor: [
                '#7266ba',
                '#fad732',
                '#23b7e5',
                '#fad732'
            ]
        }]
    },
    options: {
        legend: {
            display: false
        }
    }
}


