import { useEffect, useCallback, useState } from 'react';
import axios from 'axios';

/**
 * Use Feeds hook
 * @param{pageNumber: Integer} - Page number
 * @param{apiPath: String} - data path
 * @return{ loading: Boolean, error: Boolean, feeds: Array of feeds, hasMore: Boolean, details: Object{total: Integer, pages: Integer}}
 */
export default function useFeeds(pageNumber, apiPath){
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [details, setDetails] = useState({
    'total': 0,
    'pages': 0,
  });

  const getFeeds = useCallback(async() => {
    try{
      setLoading(true);
      setError(false);
      let cancel;
      let config = {
        method: 'POST',
        url: apiPath,
        data: {
          page: pageNumber
        },
        cancelToken: new axios.CancelToken(c => cancel = c)
      };
      const response = await axios(config);
      let data = response.data;
      setFeeds(prevFeeds => {
        return [...new Set(prevFeeds), ...response.data.items];
      });
      setDetails({
        total: data.total,
        pages: data.pages
      });
      setHasMore(response.data.hasMore);
      setLoading(false);
      return () => cancel();
    }catch(error){
      setError(true);
      setLoading(false);
      if(axios.isCancel(error)){
        return;
      }
    }
  }, [pageNumber, apiPath]);

  useEffect(() => {
    getFeeds();
  }, [getFeeds]);

  return {loading, error, feeds, hasMore, details};
}