import {
  FILTER_URL
} from './../../actions/Orders/types';

const initialState = {
  filterUrl: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FILTER_URL:
    return {
      ...state,
      filterUrl: action.payload
    };  
  default:
    return state;
  }
};