export const ModelCreateTicket = () => {
  return {
    Model: {
      UserProjectId: { value: undefined },
      ProjectId: { value: undefined },
      UserName: { valid: true, value: undefined, type: 'text', hidden: false, required: true, edited: false },
      Rol: { valid: true, value: undefined, type: 'text', hidden: false, required: true, edited: false },
      Email: { valid: true, value: undefined, type: 'text', hidden: false, required: true, edited: false },
      Phone: { valid: true, value: undefined, type: 'numberEx', hidden: false, required: false },
      TypeTicketId: { valid: true, value: undefined, type: 'text', hidden: false, required: true, edited: false },
      RequestModuleId: { valid: true, value: undefined, type: 'text', hidden: true, required: true, edited: false },
      RequestTypeSubModuleId: { valid: true, value: undefined, type: 'text', hidden: true, required: true, edited: false },
      CatSeverityId: { valid: true, value: undefined, type: 'text', hidden: true, required: true, edited: false },
      // Severity: { valid: true, value: undefined, type: 'text', hidden: true, required: true, edited: false },
      Request: { valid: true, value: undefined, type: 'text', hidden: true, required: true, edited: false },
      RequestType: { valid: true, value: undefined, type: 'text', hidden: true, required: true, edited: false },
      Subjet: { valid: true, value: undefined, type: 'text', hidden: false, required: true, edited: false },
      Description: { valid: true, value: undefined, type: 'noteval', hidden: false, required: true, edited: false },
      ccto: { valid: true, value: [], type: 'text', hidden: false, required: true, edited: false },
      MailsBD: { valid: true, value: [], type: 'text', hidden: false, required: true, edited: false },
      NameCC: { valid: true, value: undefined, type: 'text', hidden: false, required: true, edited: false },
      EmailCC: { valid: true, value: undefined, type: 'email', hidden: false, required: true, edited: false },
      Documents: { valid: true, value: [], hidden: false, required: true },
      language: { value: 'Ingles' },
    },
    Catalogs: {
      TypeTicketId: [],
      RequestModuleId: [],
      RequestTypeSubModuleId: [],
      CatSeverityId: [],
      Request: [],
      RequestType: [],
      ReasonsCloses: []
    },
  };
};