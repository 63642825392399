import React, { Component } from "react";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import CreateTemplate from "./components/createTemplate";
import Form from "./Forms/form";
import Errors from "../../../components/template/Errors";
import Loader from "./../../Global/Loader/";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: Form,
      customers: [],
      fullLoader: false,
      error: {
        open: false,
        message: "",
        type: "admin",
        errorValidate: false,
      },
    };
  }

  chamge = () => {
    this.setState({
      errorValidate: false,
    });
  };

  onError = (error) => {
    this.setState({
      error: error.error,
    });
  };

  onLoader = (state) => {
    this.setState({ fullLoader: state });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    return (
      <>
        <Loader hidden={this.state.fullLoader} />
        <ContentWrapper>
          <div>
            <div className="content-heading">
              <div className="row">
                <em className="fa-1x mr-2 fas fa-cogs"></em>
                Styles Management
              </div>
            </div>
          </div>
          <Errors
            open={this.state.error.open}
            type={this.state.error.type}
            message={this.state.error.message}
            setProps={this.recieveProps}
          ></Errors>
          <CreateTemplate
            chamge={this.chamge}
            errorValidate={this.state.errorValidate}
            putTemplate={this.putTemplate}
            form={this.state.form}
            onLoader={this.onLoader}
            onError={this.onError}
          ></CreateTemplate>
        </ContentWrapper>
      </>
    );
  }
}

export default Template;
