import React, { Component } from 'react';
import StepperEvent from '../../../../../Components/StepperEvent';
import Table from '../../../../../Components/Table';
import {Card,CardBody} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import TablesEvent from './tablesEvent';

class Events extends Component {
  state={
    allTables:[],
    allSteps:[],
    data:[],
    columns:[
      { label: 'Descripción del Evento', key: 'EventDescription' ,idTh: 'firstTh', idTd: 'firstTd'},
      { label: 'Fecha del Evento', key: 'EventDate',idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Categoría de Responsable', key: 'CategoryResponsable',idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Origen', key: 'Origin',idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha de Creación', key: 'CreateDate' ,idTh: 'firstTh', idTd: 'firstTd'},
    ],
    DOAduanaExpo:'',
    DocTransporte:'',
    HBL_HAWB:'',  
    id: this.props.match.params.id,
    steps : [
      {
        label: 'Instrucción Cliente AA (Reserva)',
        value: '',
        id: 2,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Autorización de Embarque',
        value: '',
        id: 8,
        completed: false,
        order: 2,
        idStep: '',
        middleStep: ''
      },
      {
        label: 'Cierre Físico',
        value: '',
        id: 13,
        completed: false,
        order: 3,
        middle: false,
        idStep: '',
        middleStep: ''
      },
      {
        label: 'Manifiesto de Carga',
        value: '',
        id: 16,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Facturación y Entrega de Documentos',
        value: '',
        id: 18,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      }
    ],
  }

  componentDidMount = () => {
    this.setState({
      allSteps:this.props.allSteps,
      steps:this.props.steps,
      HBL_HAWB:this.props.HBL_HAWB,
      DocTransporte:this.props.DocTransporte,
      DOAduanaExpo: this.props.DOAduanaExpo,
      Estatus: this.props.Estatus,
      cliente:this.props.cliente,
      NIT:this.props.NIT,
      Grupo:this.props.Grupo
    },()=>{
      this.createTables();
    });
  }

  componentWillReceiveProps = (nextProps) => {   
    this.setState({
      allSteps:nextProps.allSteps,
      steps:this.props.steps,
      HBL_HAWB:this.props.HBL_HAWB,
      DocTransporte:this.props.DocTransporte,
      DOAduanaExpo: this.props.DOAduanaExpo,
      Estatus: nextProps.Estatus,
      cliente:nextProps.cliente,
      NIT:nextProps.NIT,
      Grupo:nextProps.Grupo
    },()=>{this.createTables();});
  }

createTables = () => {
  var allTables = [];
  this.state.allSteps.map((e) => {
    var findTable = false;
    allTables.map((f) => {
      if(f.eventType === e.EventType){
        f.data.push(e);
        findTable = true;
      }
      return f;
    });
    if(!findTable){
      var obj = {
        eventType: e.EventType,
        data: [e]
      };
      allTables.push(obj);
    }
    return e;
  });
  this.setState({allTables: allTables});
}

render() {  
  let {cliente,NIT,Grupo} =this.state
  return (
        <>
            <Card className="myCard">
              <CardBody>
                <div className="row">
                  <div className="row" style={{ marginLeft: 15 }}>
                  <em style={{color:'rgb(88, 44, 131)'}} className="fa-3x mr-2 fas fa-arrow-alt-circle-left"></em> 
                    <div className="col">
                    <strong style={{ color: '' }}>{cliente} ({NIT})  / {Grupo}</strong>
                     <br/>
                    <h5 style={{ color: '#3697DC' }}> {this.state.DOAduanaExpo}</h5>
                      {this.state.DocTransporte}
                    </div>
                    <div className="col-12" /* style={{background: 'pink'}} */>
                      <div className="row">
                      </div>
                    </div>
                    <div className="col-12" /* style={{background: 'pink'}} */>
                      <div className="row">
                        <div className="col-4">
                          {this.state.Estatus}
                        </div>
                        <div className="col-4" /* style={{background:'pink'}} */>
                          {/* <center>
                            <label>24/05/2017</label>
                            <label>fecha maxima de estadia en puerto</label>
                        </center> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <StepperEvent steps={this.state.steps} />
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="myCard">
              <CardBody>
                { this.state.allTables.length > 0 ? <TablesEvent columns={this.state.columns} allTables={this.state.allTables}/> : 
                  <Table
                    columns={this.state.columns}
                    data={this.state.data}
                    options={{
                      large:false,
                      downloads: {
                        pdf: false,
                        xlsx: false
                      }
                    }}/>}
              </CardBody>
            </Card>
        </>

  );
}
}

export default withRouter(Events);
