import React, { Component } from 'react';
import { Card, CardBody} from 'reactstrap';

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <>
        <Card>
          <CardBody className="myCard">
          <h3>Upload</h3>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Upload;