import React, { Component } from 'react';
import ContentWrapper from './../../../template/Layout/ContentWrapper';
import restService from './../../../../services/restService';
import { Row, Input, Button } from 'reactstrap';
import Datetime from 'react-datetime';
import Form from './form';
import dateFormat from 'dateformat';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'loaders.css/loaders.css';
import { Table, Card, CardBody } from 'reactstrap';
import Confirmed from './Confirmed';
import {withRouter} from 'react-router-dom';
import { Services } from '../Services'

/**
 * Componentes
 */
import PurchaseOrderDetail from './purchaseOrder';

const rest = new restService();

/**
 * Clase principal de la aplicación
 */
class POConfirmation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      POId: this.props.match.params.POId ? this.props.match.params.POId : '',
      formOrderDetail: Form,
      orderConfirmationForm: Form.purchaseOrder,
      View: [],
      dataOrder: Form.dataOrder,
      viewOrderConfirmation: '',
      requested: true,
      QtyConfirmed: [],
      totalQty: 0,
      viewItemsList: [],
      confirmed: false
    };
  }

    /**
     * Get one order data for POConfirmation
     */
    getOrder = () => {
      rest.EXEC({ _function: 'getViewConfirmation', params: this.state.POId }, fetch).then(data => {
        if (data.data) {
          if (data.data.orderConfirmation && data.data.orderConfirmation.ConfirmationNumber && data.data.orderConfirmation.PromiseCargoReadyDate && data.data.orderConfirmation.TotalQtyConfirmed) {
            this.setState({
              confirmed: true,
              dataOrder: data.data
            });
          }
          else {
            data.data.orderConfirmation['ConfirmationDate'] = dateFormat(new Date(), 'isoDateTime');
            this.setState({ dataOrder: data.data }, () => {
              let { QtyConfirmed, totalQty } = this.state;
              let total = 0;
              this.state.dataOrder.lineItems.map((e) => {
                QtyConfirmed.push({
                  id: e.LineNumber,
                  value: e.QtyOrdered,
                  comment: '',
                  invalid: false,
                  alert: true
                });
                total = total + parseInt(e.QtyOrdered);
                return e;
              });
              totalQty = total;
              this.setState({ QtyConfirmed, totalQty }, () => {
                this.getOrderConfirmation();
                this.buildTable();
              });
            });
          }

        }

      }).catch(error => { console.error(error); });
    }

    /**
     * Validations of date onchange
     */
    validate = name => event => {
      let { dataOrder } = this.state;
      dataOrder.orderConfirmation[name] = event.target.value;
      this.setState(dataOrder, () => {
        this.getOrderConfirmation();
      });
    }

    /**
     * Validate date on change
     */
    handleChangeDate = name => event => {
      let order = this.state.dataOrder.orderConfirmation;
      try {
        order[name] = event.format('YYYY-MM-DD');
        this.setState({ order }, () => {
          this.getOrderConfirmation();
        });
      }
      catch (ex) {

      }
    }

    /**
     * Format a value of date to YYYY-MM-DD
     */
    format = (_date) => {
      let date = new Date(_date);
      return _date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
    }

    /**
     * Build Purchase Order Confirmation
     */
    getOrderConfirmation = () => {
      let { orderConfirmationForm, dataOrder } = this.state;
      let formPurchaseConfirmation = orderConfirmationForm.map((e) => {
        if (e.type === 'view') return (
          <div className="col-md-3 _views" key={e.id}>
            <h5><strong>{e.label}: </strong>{e.id === 'TotalQtyConfirmed' ? this.state.totalQty : dataOrder.orderConfirmation[e.id]}</h5>
          </div>
        );
        else if (e.type === 'viewConfirmed') return (
          <div className="col-md-3 _confirmed _views" key={e.id}>
            <h5><strong>{e.label}: </strong>{this.state.totalQty}</h5>
          </div>
        );
        else if (e.type === 'viewDate') return (
          <div className="col-md-3 _views" key={e.id}>
            <h5><strong>{e.label}: </strong>{this.format(dataOrder.orderConfirmation[e.id])}</h5>
          </div>
        );
        else if (e.type === 'viewConf') return (
          <div className="col-md-3 _views" key={e.id}>
            <h5><strong>{e.label}: </strong>{this.format(new Date())}</h5>
          </div>
        );
        else if (e.type === 'input') return (
          <div className="col-md-3" key={e.id}>
            <Row>
              <div className="col _inputLabel">
                <h5><strong>{e.label}</strong></h5>
              </div>
              <div className="col">
                <Input
                  required
                  type="text"
                  name={e.id}
                  value={dataOrder.orderConfirmation[e.id] ? dataOrder.orderConfirmation[e.id] : ''}
                  onChange={this.validate(e.id)}
                >
                </Input>
              </div>
            </Row>
          </div>
        );
        else if (e.type === 'date') return (
          <div className="col-md-3" key={e.id}>
            <Row>
              <div className="col _inputLabel">
                <h5><strong>{e.label}</strong></h5>
              </div>
              <div className="col">
                <Datetime onChange={this.handleChangeDate(e.id)} inputProps={{ className: 'form-control' }} value={dataOrder.orderConfirmation[e.id]} />
              </div>
            </Row>
          </div>
        );
        else return (
          <div className="col-md-3 _views" key={e.id}>
            <h5><strong>{e.label} </strong>{dataOrder.orderConfirmation[e.id] ? dataOrder.orderConfirmation[e.id] : ''}</h5>
          </div>
        );
      });
      this.setState({ viewOrderConfirmation: formPurchaseConfirmation });
    }

    /**
     * Modify the quantity of products
     */
    modifyQuantity = ordered => event => {
      let { QtyConfirmed, dataOrder } = this.state;
      let id = parseInt(event.target.id);
      let value = event.target.value;
      if (QtyConfirmed.find(o => o.id === id)) {
        let data = dataOrder.lineItems.find(o => parseInt(o.LineNumber) === id);
        let qty = QtyConfirmed.find(o => o.id === id);
        if (parseInt(value) < parseInt(data.QtyOrdered)) {
          document.getElementById(`${id}-id`).removeAttribute('disabled');

          dataOrder.lineItems.map((o) => {
            if (parseInt(o.LineNumber) === id) {
              if (qty.comment) o.invalid = false;
              else o.invalid = true;

              o.invalidConfirm = false;
              if (data.alert) {
                o.alert = false;
              }
            }
            return o;
          });
        } else if (parseInt(value) > parseInt(data.QtyOrdered)) {
          dataOrder.lineItems.map((o) => { if (parseInt(o.LineNumber) === id) o.invalidConfirm = true; return o;});
        } else {
          document.getElementById(`${id}-id`).setAttribute('disabled', 'disabled');
          document.getElementById(`${id}-id`).value = '';
          QtyConfirmed.find((o) => { if (parseInt(o.id) === id) o.comment = ''; return o;});

          dataOrder.lineItems.map((o) => {
            if (parseInt(o.LineNumber) === id) {
              o.invalid = false;
              o.invalidConfirm = false;
            }
            return o;
          });
        }

        QtyConfirmed.find((o) => {
          if (o.id === id) {
            o.value = value ? value : ordered;
          } else {
          }
          return o;
        });
      } else {
        QtyConfirmed.push({
          id: id,
          value: value ? value : 0,
          comment: '',
          firstLoad: true
        });
      }
      // set the status for data in the state
      this.setState({ QtyConfirmed, dataOrder, firstLoad: false }, () => {
        let { QtyConfirmed, totalQty } = this.state;
        let count = 0;
        QtyConfirmed.map(e => {
          count = count + parseInt(e.value);
          return e;
        });
        totalQty = count;
        this.setState({ totalQty }, () => {
          this.getOrderConfirmation();
          this.buildTable();
        });
      });
    }

    /**
     * Modify the content for one item
     */
    modifyComment = id => event => {
      let { QtyConfirmed, dataOrder } = this.state;
      let value = event.target.value;

      if (QtyConfirmed.find(o => o.id === parseInt(id))) {
        QtyConfirmed.find((o) => {
          if (o.id === parseInt(id)) {
            o.comment = value;
          }
          return o;
        });
      } else {
        QtyConfirmed.push({
          id: id,
          value: '',
          comment: value,
          commentactive: false
        });
      }
      if (value !== '') {
        dataOrder.lineItems.map((o) => {
          if (parseInt(id) === parseInt(o.LineNumber)) o.invalid = false;
          return o;
        });
      } else {
        dataOrder.lineItems.map((o) => {
          if (parseInt(id) === parseInt(o.LineNumber)) o.invalid = true;
          return o;
        });
      }
      // set state for data
      this.setState({ QtyConfirmed, dataOrder }, () => {
        this.buildTable();
      });
    }

    /**
     * Notification for alerts 
     */
    notify = () => toast('El total de items para la confirmación es menor a la orden, debe de agregar un comentario.', {
      type: 'info',
      position: 'top-right'
    });

    /**
     * Build table with items data
     */
    buildTable = () => {
      let { viewItemsList, dataOrder } = this.state;

      let table = dataOrder.lineItems.map((e) => {
        return (
          <tr key={e.LineNumber}>
            <td>{e.LineNumber}</td>
            <td>{e.ItemNumber}</td>
            <td>{e.ItemDescription}</td>
            <td>{e.QtyOrdered}</td>
            <td>
              <Input
                id={e.LineNumber}
                type="text"
                placeholder={e.QtyOrdered}
                value={this.QtyOrdered}
                invalid={e.invalidConfirm}
                onChange={this.modifyQuantity(e.QtyOrdered)}
              >
              </Input>
            </td>
            <td>{e.UOM}</td>
            <td>{e.OriginCountry}</td>
            <td>
              <Input
                id={`${e.LineNumber}-id`}
                disabled
                invalid={e.invalid}
                type="text"
                placeholder={e.CommentsLine}
                value={this.CommentsLine}
                onChange={this.modifyComment(e.LineNumber)}
              >
              </Input>
              <ToastContainer />
            </td>
          </tr>
        );
      });

      viewItemsList = table;

      // set state for data
      this.setState({ viewItemsList });
    };

    /**
     * Form for commet of supplier
     */
    formComment = () => {
      let { dataOrder } = this.state;
      return (<textarea rows="10" className="form-control" value={dataOrder.supplierComment ? dataOrder.supplierComment : ''} onChange={this.onChangeSupl('supplierComment')}></textarea>);
    }

    /**
     * Function that set a new value for suppliercomment
     */
    onChangeSupl = name => event => {
      let { dataOrder } = this.state;
      dataOrder[name] = event.target.value;

      this.setState(dataOrder, () => {
        this.formComment();
      });
    }


    /**
     * Function than send a confirmation data
     */
    sendConfirmation = event => {
      event.preventDefault();
      let { QtyConfirmed, dataOrder, totalQty } = this.state;
      let json = {};
      if (dataOrder.orderConfirmation && !dataOrder.orderConfirmation.ConfirmationNumber) {
        swal('Error', 'Confirmation number is required', 'warning');
      }
      else {
        json.Items = QtyConfirmed;
        json.Confirmation = {
          ConfirmationNumber: dataOrder.orderConfirmation.ConfirmationNumber,
          PromiseCargoReadyDate: dataOrder.orderConfirmation.PromiseCargoReadyDate,
          TotalQtyConfirmed: totalQty,
          ConfirmationDate: dataOrder.orderConfirmation.ConfirmationDate
        };
        json.comment = dataOrder.supplierComment;

        let url = `${Services.ORDER.path}order/confirmation/order/${this.state.POId}`;
        let options = {
          method: 'PUT',
          body: JSON.stringify(json),
          headers: { 'Content-Type': 'application/json' }
        };
        fetch(url, options)
          .then(res => res.json())
          .catch(error => { })
          .then((response) => {
            if (response && response.ok ? true : false) {
              swal('Good job!', 'Your purchase order has been successfully confirmated', 'success').then(success => {
                this.setState({
                  confirmed: true
                });
              });
            } else {
              swal('Sorry', 'Your purchase order hasn´t been confirmated', 'error');
            }
          });
      }
    }
    /**
     * Component will be mounted and get all order data for render
     */
    componentWillMount = () => {
      this.getOrder();
    }

    /**
     * Render Method for view
     */
    render() {
      const { confirmed, dataOrder } = this.state;

      return (
        <>
          <ContentWrapper>
            {!confirmed ?
              <div className="row">
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <h4 className="mb-3"><em className="fas fa-shopping-basket mr-2"></em>Purchase Order Detail</h4>
                      <PurchaseOrderDetail data={this.state.dataOrder.orderDetail}></PurchaseOrderDetail>
                    </CardBody>
                  </Card>
                </div>


                <div className="col-12">
                  <Card>
                    <CardBody>
                      <h4 className="mb-3"><em className="fas fa-dolly-flatbed mr-2"></em>Purchase Order Confirmation</h4>
                      <Row>
                        {this.state.viewOrderConfirmation}
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <h4 className="mb-3"><em className="fas fa-list mr-2"></em>Line Items</h4>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th >Lin</th>
                            <th >Item</th>
                            <th >Item Description</th>
                            <th >Qty Ordered</th>
                            <th >Qty Confirmed</th>
                            <th >UOM</th>
                            <th >Origin Country</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.viewItemsList}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12">
                  <Card>
                    <CardBody>
                      <h4 className="mb-3"><em className="fas fa-comments fa-1x mr-2" />PO Confirmation comments</h4>
                      <div className="row">
                        <div className="col-12">
                          {this.formComment()}
                        </div>
                        <div className="col-12">
                          <hr></hr>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10 col-lg-10 col-sm-12"></div>
                        <div className="col-md-2 col-lg-1 col-sm-12">
                          <Button variant="contained" color="primary" className="button" onClick={this.sendConfirmation} >
                                                    Save and confirm
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>

              : <Confirmed order={dataOrder}></Confirmed>
            }
          </ContentWrapper>
        </>
      );
    }

}

export default withRouter(POConfirmation);