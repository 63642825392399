import { combineReducers } from "redux";
import dashboard from "./dashboard";
import order_list from "./Orders/List";
import Header from "./Header";
import DesignProvider from "./Design";

export default combineReducers({
  dashboard,
  order_list,
  header: Header,
  design: DesignProvider,
});
