import React from 'react';


function TableBody({ rows, columns }) {
	const ROWS = rows.map((row, indexrow) => {
		return (
			<tr key={indexrow}>
				{
					columns.map((column, indxcol) => {
						return (
							< React.Fragment >
								{
									column.custom ?
										<th
											key={indxcol}
											// className={`table-td ${cellType} ${column.fixed ? 'col-fixed' : ''}`} id={row[column.key] ? (column.idTd ? column.idTd : row[column.key]) : row[column.key]}
											style={{ textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80, padding: '14px 8px' }}
										>
											{
												column.custom(row)
											}
										</th>
										:
										<th
											scope='row'
											key={indxcol}
											// className={`table-td ${cellType} ${column.fixed ? 'col-fixed' : ''}`}
											id={row[column.key] ? (column.idTd ? column.idTd : row[column.key]) : row[column.key]}
											style={{ fontSize: '12px', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80, padding: '14px 8px' }}
										>
											{row[column.key] ? row[column.key] : ''}
										</th>
								}
							</React.Fragment>
						);
					})
				}
			</tr >
		);
	});

	return (
		<React.Fragment>
			<tbody>
				{ROWS}
			</tbody>
		</React.Fragment>
	);
}

export default TableBody