import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Input } from 'reactstrap';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'react-select';
import Datetime from 'react-datetime';
import restService from '../../../../../../services/restService';
import './style.scss';
import Fade from 'react-reveal/Fade';
import {Card,CardBody} from 'reactstrap';

const rest = new restService();
class HeaderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      selectedOption: '',
      collapse: true,
      order: {},
      errors: {},
      selects: { INCOTERMS: [], PRODUCTS: [], UNITSMEASURE: [], CURRENCIES: [], TRANSPORTS: [], COUNTRIES: [], CUSTOMERS_DIVISIONS: [], CRUISES: [] },
      CreateOrderFormData: [],
      Form: {},
      icon: 'fas fa-angle-down',
      selected: [],
      orderSelected: [],
      firtsOrderLoader: false
    };
  }
    componentWillUnmount = ()=>{
      this.setState({
        Form : {}
      });
    }
    componentDidMount = () => {
      let { selected } = this.props;
      this.setState({
        Form: this.props.Form
      },()=>{
        if (this.validArray(selected)) this.setState({
          orderSelected: selected,
        }, () => this.setFirstOrder());
        else this.getAllCatalogs();
      });
       
    }
    componentWillReceiveProps = (nextProps) => {
      let { selected } = nextProps;
      this.setState({
        Form: nextProps.Form
      },()=>{
        if (this.validArray(selected)) this.setState({
          orderSelected: selected,
        }, () => this.setFirstOrder());
        else this.getAllCatalogs();
      });
    }

    setFirstOrder = () => {
      let firstOrder = this.state.orderSelected[0];
      let { firtsOrderLoader } = this.state;
      if (!firtsOrderLoader) {
        let order = {
          PONumber: firstOrder.PONumber,
          POCreateDate: firstOrder.POCreateDate ? firstOrder.POCreateDate : '',
          RequestedExWorksDate: firstOrder.RequestedExWorksDate ? firstOrder.RequestedExWorksDate : '',
          RequiredDestination: firstOrder.RequiredDestination ? firstOrder.RequiredDestination : '',
          Incoterm: firstOrder.CodIncoterm ? firstOrder.CodIncoterm : '',
          OriginCountryId: firstOrder.OriginCountryId ? firstOrder.OriginCountryId : '',
          TransportModeId: firstOrder.TransportModeId ? firstOrder.TransportModeId : '',
          PODescription: firstOrder.PODescription ? firstOrder.PODescription : '',
          ProductTypeId: firstOrder.ProductTypeId ? firstOrder.ProductTypeId : '',
          AdditionalPOTerms: firstOrder.AdditionalPOTerms ? firstOrder.AdditionalPOTerms : '',
          OrderCurrencyId: firstOrder.OrderCurrencyId ? firstOrder.OrderCurrencyId : '',
          EntryCreatedDate: firstOrder.EntryCreatedDate ? firstOrder.EntryCreatedDate : '',
          LastUpdateDate: '',
          SupplierCode: '',
          ConsigneeCode: '',
          CruiseId: firstOrder.CruiseId ? firstOrder.CruiseId : '',
          OrderValue: firstOrder.OrderValue ? firstOrder.OrderValue : '',
          DeliveryCode: '',
          DivisionId: firstOrder.DivisionId ? firstOrder.DivisionId : ''
        };
        this.setState({
          order,
          firtsOrderLoader: true
        }, () => {
          this.props.refreshStateOrder(this.state.order);
          this.getAllCatalogs();
        });
      }
    }

    validArray = (array) => Array.isArray(array) && array.length > 0 ? true : false

    getAllCatalogs = () => {
      rest.EXEC({ _function: 'GetAllCatalogs', params: '' }, fetch).then(data => {
        this.setState({ selects: data.data }, () => this.buildFormCreateOrder());
      }).catch(error => {
        console.error('all catalogs', error);
      });
    }
    handleChange = name => event => {
      let order = this.state.order;
      order[name] = event.target.value;
      this.setState({ order }, () => {
        this.buildFormCreateOrder();
        this.props.sendOrderField(name, this.state.order[name]);
      });
    }
    handleChangeSelect = (selected) => event => this.setState({ selected }, () => {
      let { order } = this.state;
      if (event && event.value) {
        order[selected] = event.value;
        this.setState({ order }, () => {
          this.buildFormCreateOrder();
          this.props.sendOrderField(selected, this.state.order[selected]);
        });
      } else {
        order[selected] = '';
        this.setState({ order }, () => {
          this.buildFormCreateOrder();
          this.props.sendOrderField(selected, '');
        });
      }
    });
    handleChangeDate = name => event => {
      let order = this.state.order;
      try {
        order[name] = event.format('YYYY-MM-DD');
        this.setState({ order }, () => {
          this.buildFormCreateOrder();
          this.props.sendOrderField(name, this.state.order[name]);
        });
      }
      catch (ex) {
        order[name] = '';
        this.setState({ order }
        );
      }
    }

    buildFormCreateOrder = () => {
      const { Form, order, selects } = this.state;
      let CreateOrderFormData = Form.CreateOrder.map((e, i) => {
        if (e.type === 'Input') return (
          <div className={e.class} key={e.id}>
            <Fade left opposite> <span id={e.label} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
            <Input
              id={e.id}
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={order[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max} />
            <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>

          </div>
        );
        else if (e.type === 'Select') return (
          <div className={e.class} key={e.id}>
            <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
            <Select
              id={e.id}
              required
              type={e.datatype}
              name={e.name}
              value={order[e.id]}
              onChange={this.handleChangeSelect(e.id)}
              options={selects[e.options]}
            />
            <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
            {/* <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label> */}

          </div>);
        else if (e.type === 'Datetime') return (
          <div className={e.class} key={e.id} >
            <Fade left opposite>
              <span id={e.label} style={e.style}> <label  >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
            <Datetime
              required
              type={e.datatype}
              dateFormat={'YYYY MM DD'}
              inputProps={{ className: 'form-control', id: e.id }}
              value={order[e.id]}
              onChange={this.handleChangeDate(e.id)} />
            <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
            {/* <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label> */}

          </div>
        );
        else if (e.type === 'textarea') return (
          <div className="col-md-6" key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}> <label >{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
            <Input
              required
              type={e.datatype}
              name={e.id}
              value={order[e.id]}
              id={e.id}
              onChange={this.handleChange(e.id)}
            >
              <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
            </Input>
          </div>
        );
        else return '';
      });
      this.setState({
        CreateOrderFormData
      });
    }
    render() {
      let { CreateOrderFormData } = this.state;
      return (
        <Card className="myCard">
          <CardBody>
            <ExpansionPanel elevation={0} defaultExpanded>
              <ExpansionPanelSummary id="mytext" expandIcon={<ExpandMoreIcon />}>
                <legend ><em className="fas fa-file-alt fa-1x mr-2"></em>Order Details</legend>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row">
                  {CreateOrderFormData}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>
        </Card>
            
      );
    }
}

export default HeaderDetails;
