import React, { Component } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import Form from '../../../../../../models/FormData'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Errors from '../../../../../template/Errors'
import Icon from '@material-ui/core/Icon';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FilePond, File } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import path from 'path'
import Fade from 'react-reveal/Fade';
import Tables from '../../../../../template/Table'
import {Card,CardBody} from 'reactstrap'
class FormUpload extends Component {
    state = {
        PONumber: this.props.PONumber,
        name: '',
        files: [],
        open: true,
        items: [],
        columns: [
            { label: 'Doc Name', key: 'Name' },
            { label: 'Doc Type', key: 'other' },
            { label: 'Doc Category', key: 'Category' },
            {
                label: 'Actions', key: '', custom: (value) => this.custom1(value)
            }
        ],
        Form: Form.CreateDocument,
        selects: {
            'DOC-CATEGORIES': [
                { value: 2, label: "Purchase Order" },
                { value: 1, label: " Commercial Invoice" },
            ]
        },
        Document: {
            Name: '',
            DocCategoryId: '',
            file: '',
            other: "",
            ModuleId: "",
            RowId: "",
            Category: "",

        },
        createDocumentForm: [],
        Documents: [],
        error: {
            open: false,
            message: '',
            type: ''
        },
        DocumentsList: ''
    }

    custom1 = (value) => (
        <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value)}>
            <Icon className="icon" color="inherent" style={{ fontSize: 20 }}>
                remove
                </Icon>
        </Button>
    )

    componentDidMount = () => this.buildDocumentForm()

    handleChange = name => event => {
        let { Document } = this.state
        Document[name] = event.target.value
        this.setState({ Document }, () => this.buildDocumentForm())
    }
    handleChangeSelect = name => event => {
        let { Document } = this.state
        if (event && event.value) {
            Document[name] = event.value
            this.setState({ Document }, () => { this.buildDocumentForm() })
        }
        else {
            Document[name] = ""
            this.setState({ Document }, () => { this.buildDocumentForm() })
        }

    }
    onDrop = fileItems => {
        let { Document } = this.state
        Document.file = fileItems.length > 0 ? fileItems[0].file : ''
        Document.Name = fileItems.length > 0 ? fileItems[0].file.name : ''
        this.setState({ Document }, () => this.buildDocumentForm())
    }
    buildDocumentForm = () => {
        const { Form, Document, selects } = this.state
        let createDocumentForm = Form.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <label>{e.label} {e.required === true ? <label>*</label> : ""}</label>
                    <Input
                        type="text"
                        placeholder={e.placeHolder}
                        value={Document[e.id]}
                        name={e.id}
                        onChange={this.handleChange(e.id)} />
                </div>
            )
            else if (e.type === "Select") return (
                <div className={e.class} key={e.id}><label >{e.label}  {e.required === true ? <label>*</label> : ""}</label>
                    <Select
                        name={e.name}
                        value={Document[e.id]}
                        onChange={this.handleChangeSelect(e.id)} options={selects[e.options]} />
                </div>)
            else return ''
        });

        this.setState({
            createDocumentForm: createDocumentForm
        })
    }
    addDocument = () => {
        let { Document } = this.state
        Document.other = path.extname(Document.file.name)
        if (Document.DocCategoryId === 1) {
            Document.Category = "Commercial Invoice"
        }
        if (Document.DocCategoryId === 2) {
            Document.Category = "Purchase Order"
        }
        Document.ModuleId = "1"
        Document.RowId = "INS01189253"

        let keys = ['file', 'Name', 'DocCategoryId']
        let errors = ''
        keys.forEach((e) => {
            if (!Document[e]) {
                if (e === 'file') e = "File"
                if (e === 'Name') e = "Doc name"
                if (e === 'DocCategoryId') e = "Doc Category"
                errors += e + ', '
            }
        })

        if (errors.length > 0)
            this.setState({
                error: {
                    message: 'Document fields are required (" ' + errors + ' ")',
                    open: true,
                    type: 'warning'
                }
            })
        else {
            this.setState({
                Document: {
                    Name: "",
                    DocCategoryId: "",
                    file: ''
                },
                files: [],
            }, () => {
                this.setState({
                    Documents: [...this.state.Documents, Document]
                }, () => {
                    this.buildDocumentsList()
                    this.buildDocumentForm()
                    this.props.sendDocuments(this.state.Documents)
                    this.setState({
                        error: {
                            message: 'Document Added',
                            open: true,
                            type: 'success'
                        }
                    })
                })
            })
        }
    }
    deleteLine = index => event => {
        let { Documents } = this.state
        Documents.splice(index, 1)
        this.buildDocumentsList()
        this.props.sendDocuments(this.state.Documents)
        this.setState({
            error: {
                message: 'Document Removed',
                open: true,
                type: 'warning'
            }
        })
    }
    buildDocumentsList = () => {
        const { Documents } = this.state
        // let categories = this.state.selects['DOC-CATEGORIES']
        if (Documents && Documents.length > 0) {
            let DocumentsList = Documents.map((e, index) => {
                return (
                    <tr>
                        <td><Fade left opposite>{e.Name}</Fade></td>
                        <td><Fade left opposite></Fade></td>
                        <td><Fade left opposite>{e.DocCategoryId}</Fade></td>

                        <td>
                            <Fade left opposite>
                                <center>
                                    <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(index)}>
                                        <Icon className="icon" color="inherent" style={{ fontSize: 20 }}>
                                            remove
                                </Icon>
                                    </Button>
                                </center>
                            </Fade>
                        </td>
                    </tr>
                )
            })
            this.setState({ DocumentsList })
        }
        else {
            this.setState({ DocumentsList: '' })
        }
    }
    handleClickOpen = () => this.setState({ open: true });
    handleClose = () => this.setState({ open: false });
    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })
    render() {
        // const { classes } = this.props
        let { Documents } = this.state
        return (
            <Card className="myCard">
                <CardBody>
                <ExpansionPanel elevation={0} defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <legend id ="Documents"><em className="fas fa-folder-open fa-1x mr-2"></em>Documents</legend>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-12">
                            <FilePond
                                onupdatefiles={(fileItems) => {
                                    this.setState({
                                        files: fileItems.map(fileItem => fileItem.file)
                                    }, () => {
                                        this.onDrop(fileItems);
                                    });
                                }}>
                                {this.state.files.map(file => (
                                    <File key={file} src={file} origin="local" />
                                ))}
                            </FilePond>
                        </div>
                        {this.state.createDocumentForm}
                        <div className="col-md-1" style={{ paddingTop: '33px' }}>
                            <Button variant="fab" mini color="primary" aria-label="Add" onClick={this.addDocument}>
                                <AddIcon />
                            </Button>
                        </div>
                        <div className="col-md-12">
                            <hr></hr>
                        </div>
                        <div className="col-md-12">
                            <Tables data={Documents} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}></Tables>
                        </div>
                        <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
                </CardBody>
            </Card>
            
        );
    }
}

export default FormUpload;

