import React from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.scss';
import Axios from 'axios';
import {saveAs} from 'file-saver';
/**
 * Card for Excel element 
 * @param {*} param0 icon
 */

export const FaCardExcel = ({ data, url, headers, keys}) => {
  const [loading, setLoading] = React.useState(false);

  async function downLoadFile(){
    setLoading(true);
    let success = await Axios.post(`${url}`,{ data, headers, keys});
    if (success.data.base64) {
      setLoading(false);
      const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';
      const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
      saveAs(linkSource, fileName);
    } else console.warn('Error downloading file', success);
  }
  
  return (
    <div className='right_'>
      {
        loading ? 
          <Icon.Group size='large'>
            <Icon loading={loading} name='circle notch' size="large"/>
            <Icon color='green' name='file excel'/>
          </Icon.Group>        
          :  <Icon onClick={downLoadFile} color='green' className="__downloader" name='file excel' size="big"/>
      }
    </div>
  );
};