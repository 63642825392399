import React from "react";
// import { Result } from 'antd';
// import { Icon } from 'semantic-ui-react';
// // import { MehOutlined } from '@ant-design/icons';
import "./styles.scss";

function BiGerencial() {
  const ref = React.useRef(null);
  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div class="embed-container">
        <iframe
          src="https://analytics-webapp.com/"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </React.Fragment>
  );
}

export default BiGerencial;
