import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from '../../../../../../Components/Table';

const ListItemEventTabTable = ({ title, events }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const columns = [
      {
        label: "Event description",
        key: "EventDescription",
        idTh: "firstTh",
        idTd: "firstTd",
      },
      { label: "Event date", key: "EventDate" },
      { label: "Origin", key: "Origin" },
      { label: "Update date", key: "CreateDate" },
    ];
    setColumns(columns);
  }, [events]);
  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12" style={{ marginLeft: "6px" }}>
          <legend className="tableTitleContenedor">{title}</legend>
        </div>
        <div className="col-12">
          <div className="table_container">
            <Table
              headerClass="headerTable"
              columns={columns}
              styleColumn={true}
              data={events}
              title={title}
              options={{
                styleColumn: true,
                large: true,
                downloads: {
                  pdf: false,
                  xlsx: false,
                },
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ height: 49 }} />
    </div>
  );
};

export default withRouter(ListItemEventTabTable);
