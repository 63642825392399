import React, { Component } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import '../style/style.scss';
import TablaPo from './tablePo';

class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      data: props.data ? props.data : [],
      optionSelected: [
        { value: 'PromiseCargoReadyDate', label: 'Promise Cargo Ready' },
        { value: 'ActualCargoReadyDate', label: 'Actual Cargo Ready' },
        { value: 'ActualCargoPickup', label: 'Actual Cargo Pickup' },
        { value: 'EstimatedArrivalConsolWH', label: 'Est. Arrival Consol WH' },
        { value: 'ActualArrivalConsolWH', label: 'Actual Arrival Consol WH' },
        // { value: 'GrossWeight', label: 'Total Weight' },
        // { value: 'NoPallets', label: 'No Pallets' },
        // { value: 'Spaces', label: 'Spaces' },
      ],
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.data,
    })
  }

  render() {
    let {data, optionSelected } = this.state;
    return (
      <>
        <Card>
          <CardBody className="myCard">
            <div className="col-md-12">
              <Row>
                <legend className="titleMulti" > Select a purchase order to update</legend>
              </Row>
            </div>
            <div className="col-md-12">
              <TablaPo optionSelected={optionSelected} data={data} putOrdersPo={this.props.putOrdersPo} />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Update;