import ContentWrapper from "../../../../components/template/Layout/ContentWrapper";
import React, { useEffect, useState, Suspense, useCallback } from "react";
import { useParams, withRouter, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Tab } from "semantic-ui-react";
import Axios from "axios";
import { Services } from "../Services";
import Loader from "../../../Global/Loader";
import { message } from "antd";
// import { validateIdParams } from "../../../utils/regularExpressionValidation";
import { poId } from "../mocks.js";

const Summary = React.lazy(() => import("./tabs_components/summary.js"));
const Events = React.lazy(() => import("../../OrderVendor/order-po-detail-view/components/Events"));

const Comments = React.lazy(() => import("../../OrderVendor/order-po-detail-view/components/Comments"));
const Documments = React.lazy(() => import("../../OrderVendor/order-po-detail-view/components/Documents"));

function vendor_detail(params) {
  const [data, setData] = useState([]);
  const [fullLoader, setFullLoader] = useState(false);

  const get_order = useCallback(async () => {
    try {

      setTimeout(() => {
        setData(poId);
        setFullLoader(true);
      }, 3000);

    } catch (error) {
      console.log("🚀 ~ constget_order=useCallback ~ error:", error)
    }
  }, []);

  useEffect(() => {
    get_order();
  }, []);

  return (
    <>
      <Loader hidden={fullLoader} />
      <ContentWrapper>
        <div className="content-heading">
          <i className="icon--po_suppliericon"></i>
          Vendor Management Detail
        </div>
        <Tab
          menu={{ secondary: true }}
          panes={[
            {
              menuItem: "Summary",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Summary
                    data={data}

                    rolId={params._rolId}
                  />
                </Suspense>
              ),
            },
            {
              menuItem: "Events",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Events rolId={params._rolId} data={data} />
                </Suspense>
              ),
            },
            {
              menuItem: "Comments",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Comments
                    data={data}
                    _user={params._user}
                    _customer={params._customer}
                  />
                </Suspense>
              ),
            },
            {
              menuItem: "Documents",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <Documments
                    rolId={params._rolId}
                    data={data}
                    
                    _user={params._user}
                  />
                </Suspense>
              ),
            },
          ]}
        ></Tab>
      </ContentWrapper>
    </>
  );
}

export default withRouter(vendor_detail);
