import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { SetFilterUrl } from './../../../../actions/Orders/List';
import Axios from 'axios';

import { subscribeToSocket } from './socket';
import Authentication from '../../../../services/Authentication.js';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import Table from '../../../template/Table';
import Loader from './../../../Global/TableLoader';
import { Services } from './../Services';
import { columns, CloseIcon } from './config';
import Comments from './components/Comments/';

import './styles.scss';
const auth = new Authentication();

const styles = {
  background: 'red'
};
const KeysFilter = ['POId', 'PONumber', 'StatusDesc', 'SupplierName', 'ConsigneeName', 'DeliveryName', 'DistributorPO', 'ProjectName', 'ContainerNumber', 'CustomerContainerID'];

class OrderListCommponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      data: [],
      loading: true,
      columns: columns,
      searchOnInit: false,
      searchText: '',
      POId: 0,
      openComments: false,
      handleResCom: 'unset',
      isSearchComments: true,
      tableLoader: false
    };
  }

  componentDidMount = () => {
    console.log('ando aqui men--->', this.props.filterUr);
    if (this.props.filterUrl) this.props.setFilterUrl('');
    this.getOrdersList();
    subscribeToSocket(this.props._customer, (error, message) => {
      if (!error) {
        if (message.customer === this.props._customer) {
          this.resetTable();
        }
      }
    });
  }


  /*=============================================
  =            Get Order List                   =
  =============================================*/

  getOrdersList = async () => {
    try {
      let query = new URLSearchParams(this.props.location.search);
      if (query.get('searching')) this.setState({ searchOnInit: true, searchText: query.get('searching') });
      let id = auth.getAuthentication('customerSelect');
      if (this.props.filterUrl) {
        let success = await Axios.get(`${this.props.filterUrl}`);
        this.setState({ data: success.data.data, loading: false });
      } else {
        let success = await Axios.get(`${Services.ORDER.path}order/search/all?type=idCustomer&id=${id}&read_messages=true&user=${this.props._user}`);
        // const url = 'https://poseidonorder-dev.azurewebsites.net';
        // let url = 'http://localhost:3004';
        // let url = 'https://orderscom-dev.azurewebsites.net';
        // let success = await Axios.get(`${url}/order/search/all?type=idCustomer&id=${id}&read_messages=true&user=${this.props._user}`);
        // console.log("order list ==>", success);
        this.setState({ data: success.data.data, loading: false });
      }
    } catch (err) {
      console.warn(err);
      this.setState({ loading: false });
    }
  }

  resetTable = () => {
    this.setState({ isSearchComments: false, handleResCom: 'unset' }, () => {
      this.getOrdersList();
    });
  }



  /*=============================================
  =            Selected Order                   =
  =============================================*/

  selectedOrder = (order) => {
    this.setState({ POId: order.POId, PONumber: order.PONumber, openComments: true, handleResCom: 'set', isSearchComments: true });
  }

  handleClose = () => {
    this.setState({ openComments: false, isSearchComments: false });
  }


  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    const { data, columns, loading, searchOnInit, searchText, openComments, POId, PONumber, handleResCom, isSearchComments, tableLoader } = this.state;
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-th-list fa-1x mr-2" />
              Purchase Orders List
            </div>
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            {
              loading ?
                <Loader />
                :
                <Col md={12} xl={12}>
                  <Row>
                    <Col md={12} xl={12}>
                      <Sidebar.Pushable as={Segment}>
                        <Sidebar
                          as={Menu}
                          animation='overlay'
                          icon='labeled'
                          direction='right'
                          // onHide={() => setVisible(false)}
                          vertical
                          visible={openComments}
                          width='very wide'
                        >
                          <Col md={12} className='wrapper-container'>
                            <Row>
                              <Col md={12} className='close_container'>
                                <div className='header_comments'>Order# {PONumber}</div>
                                <div className='close_icon' onClick={this.handleClose}>
                                  <CloseIcon />
                                </div>
                              </Col>
                              <Comments _customer={this.props._customer} POId={POId} _user={this.props._user} _rolId={this.props._rolId} isSearchComments={isSearchComments} resetTable={this.resetTable} />
                            </Row>
                          </Col>
                        </Sidebar>

                        <Sidebar.Pusher>
                          <Segment basic className='comments_table_container'>
                            <Table
                              columns={columns}
                              toFilter={KeysFilter}
                              data={data}
                              itemsByPage={10}
                              styles={styles}
                              name="OrderList"
                              modalName="ui Modal body order list"
                              customer={this.props._customer}
                              user={this.props._user}
                              options={
                                {
                                  pagination: true,
                                  search: true,
                                  modal: true,
                                  downloads: {
                                    xlsx: true,
                                    pdf: false
                                  },
                                  selectItems: false,
                                  orderAttention: {
                                    activeFilter: searchOnInit,
                                    activeText: searchText,
                                    search: true,
                                    xlsx: true
                                  },
                                  trigger: this.selectedOrder,
                                  handleResCom: handleResCom
                                }
                              }
                              cellType={'thick-rows'}
                            />
                          </Segment>
                        </Sidebar.Pusher>
                      </Sidebar.Pushable>

                    </Col>
                  </Row>
                </Col>
            }
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

const MapStateToProps = state => ({
  filterUrl: state.order_list.filterUrl
});

const MapDispatchToProps = dispatch => ({
  setFilterUrl: (url) => dispatch(SetFilterUrl(url))
});

export default connect(MapStateToProps, MapDispatchToProps)(withRouter(OrderListCommponent));