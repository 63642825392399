import React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { Table, Col } from 'reactstrap';
import { saveAs } from 'file-saver';
import { Popup } from 'semantic-ui-react';
import TableHeader from './header';
import TableBody from './body';
import TablePagination from './pagination';
import TableSearch from './search';
import Axios from 'axios';
import './styles.scss';

function TableComponent(params) {
  const [loaderExcel, setLoaderExcel] = useState(false);

  const generateExcel = useCallback(
    async (data, keys, headers, projectName, sheetName, _url, filName) => {
      setLoaderExcel(true);

      const dataxls = {
        data: data,
        options: {
          keys,
          headers,
          image: {
            name: projectName,
          },
          sheetName: sheetName,
        },
      };

      try {
        if (data.length >= 1) {
          const getXlsxFile = await Axios.post(_url, dataxls);
          const fileName = `${filName}.xlsx`;
          const linkSource =
            'data:application/xlsx;base64,' + getXlsxFile.data.base64;
          saveAs(linkSource, fileName);
          setLoaderExcel(false);
        } else {
          setLoaderExcel(false);
        }
      } catch (error) {
        console.log('The Error-->', error.message);
        setLoaderExcel(false);
      }
    },
    []
  );

  useEffect(() => {}, [params.data]);

  return (
    <>
      <div className='row mb-3 d-flex align-items-center'>
        {params.options ? (
          params.options.search ? (
            <Col xs={3}>
              <TableSearch
                placeholder='Search entity...'
                clear={true}
                handleChangeSearch={params.handleChangeSearch}
                value={params.options.valueSearch}
              />
            </Col>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {
          <Col xs={3}>
            {params.options && params.options.filter ? (
              <Popup
                content='Advanced filters'
                size='small'
                inverted
                trigger={
                  <i
                    className={`icon-ml-filter fa-spin fa-2x m-1`}
                    id='toggler'
                    style={{ color: '#13AFEF', cursor: 'pointer' }}
                  />
                }
              />
            ) : (
              ''
            )}
            {params.options && params.options.xlsx.show ? (
              <Popup
                content='Download excel file'
                size='small'
                inverted
                trigger={
                  <i
                    className={
                      loaderExcel
                        ? 'fas fa-spinner fa-spin fa-2x m-1'
                        : `icon-ml-xls fa-2x m-1`
                    }
                    style={{ color: '#13AFEF', cursor: 'pointer' }}
                    onClick={() => {
                      generateExcel(
                        params.data,
                        params.options.xlsx.keys,
                        params.options.xlsx.headers,
                        params.options.xlsx.projectName,
                        params.options.xlsx.sheetName,
                        params.options.xlsx.URL,
                        params.options.xlsx.filName
                      );
                    }}
                  />
                }
              />
            ) : (
              ''
            )}
          </Col>
        }
      </div>
      <div>
        {params.options && params.options.filter ? (
          <div>{params.children}</div>
        ) : (
          ''
        )}
      </div>
      <div>
        <Table
          size='sm'
          hover
          responsive
          // striped
        >
          <TableHeader columns={params.columns} />
          <TableBody
            rows={params.data}
            columns={params.columns}
            page={0}
            rowsPerPage={5}
          />
        </Table>
      </div>
      <div className='center'>
        <TablePagination
          activePage={params.activePague}
          totalPages={params.totalPages}
          onPageChange={params.onPageChange}
        />
      </div>
    </>
  );
}

export default TableComponent;
