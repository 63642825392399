import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import propTypes from 'prop-types';
import ButtonNewTicket from '../../Components/buttons'
import './style.scss';

const colorsCreate = {
  1: { Create: 'primary', Cancel: 'primary' },
  2: { Create: 'primary', Cancel: 'danger' },
  3: { Create: 'primary', Cancel: 'warning' },
  4: { Create: 'success', Cancel: 'warning' },
  5: { Create: 'success', Cancel: 'warning' },
  6: { Create: 'primary', Cancel: 'secondary' },
  7: { Create: 'primary', Cancel: 'secondary' },
};

function ModalContent(props) {
  // console.log('props modal create ticket',props);
  const [openModal, setopenModal] = React.useState(false);

  function toggle() {
    setopenModal(!openModal);
    props.infoUser()
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={6} lg={2} className='ml-auto mb-3'>
          <ButtonNewTicket projectId={props.projectId} etiquetas={props.etiquetas} onclick={toggle} />
        </Col>
      </Row>
      <Modal isOpen={openModal} scrollable={true}>
        {/* <modalContent /> */}
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          <div className='row'>
            {props.children}
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button disabled={props.disebledButton} color={colorsCreate[props.projectId].Create} className="btn-round" onClick={() => { props.createTicket(); props.onCancel(); setopenModal(false) }}>{props.etiquetas.btn_create}</Button>{' '}
          <Button
            // outline
            color={colorsCreate[props.projectId].Cancel} onClick={() => { setopenModal(false); props.onCancel(); }}>{props.etiquetas.btn_cancel}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

ModalContent.propTypes = {
  title: propTypes.string,
  labels: propTypes.object,
  onCancel: propTypes.func
};

ModalContent.defaultProps = {
  title: 'Modal title',
  onCancel: () => { },
  labels: {
    title: '',
    icon: '',
    btn_create: '',
    btn_cancel: ''
  }
};

export default ModalContent;