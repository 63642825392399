import React from 'react';
import { MDBDataTable } from 'mdbreact';
import propTypes from 'prop-types';
import './styles.scss';
/*=============================================
=            DataTabla redesign               =
=============================================*/

function DatatablePage ({columns, data}) {
  let _rows_ = [];
  if (Array.isArray(data)){
    for (let index = 0; index < data.length; index++) {
      const data_el = data[index];
      
      let item = {};
      for (let index = 0; index < columns.length; index++) {
        const row_el = columns[index];
        item[row_el.field] = row_el.custom ? row_el.custom(data_el): data_el[row_el.field];
      }
      _rows_.push(item);
    }
  }
  const _data_ = {
    columns: columns,
    rows: _rows_
  };
  return (
    <div className='p-3'>
      <MDBDataTable
        scrollY
        scrollX
        // fixed={true}
        infoLabel={false}
        info={false}
        paging={false}
        sortable={true}
        data={_data_}
        entries={10}
        searching={false}
        theadColor={'selector-dt-head'}
        // theadTextWhite={false}
        tbodyColor={'selector-dt-body'}
        maxHeight={200}


        // className="mg-table"
        // scrollY={true}
        // scrollX={true}

        // // fixed={true}

        // infoLabel={false}
        // info={false}
        // paging={false}
        // sortable={true}
        // autoWidth={false}
        // barReverse={false}
        // bordered={false}
        // borderless={false}
        // btn={false}
        // dark={false}
        // entries={10}
        // entriesLabel={'label'}
        // hover={true}
      

        // // infoLabel={['Show entries']}
        // maxHeight={'100px'}
        // pagesAmount={8}
      
        // paginationLabel={['<- Previous', 'Next ->']}

        // small={false}

        // striped={false}

      // theadColor={'selector-dt-head'}
      // theadTextWhite={false}
      // tbodyColor={'selector-dt-body'}
      // tbodyTextWhite={false}
      // clickEvent={() => {}}
      // data={_data_}
      // responsive={true}
      />
    </div>
  );
};

DatatablePage.propTypes = {
  columns: propTypes.array,
  data: propTypes.array
};

DatatablePage.defaultProps = {
  columns: [],
  data: []
};


export default DatatablePage;
