import React from 'react';
import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green, purple, blue, red} from '@material-ui/core/colors';


/**
 * Blue Button
 */

export const InfoButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    textTransform: 'capitalize',
    padding: '4px 10px !important',
    // margin: 'none !important',
    '&:hover': {
      backgroundColor: blue[700]
    }
  }
}))(Button);

/**
 * Red Button
 */

export const DangerButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    textTransform: 'capitalize',
    padding: '4px 10px !important',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700]
    }
  }
}))(Button);

/**
 * Default Button
 */

export const DefaultButton = withStyles(() => ({
  root: {
    textTransform: 'capitalize',
    padding: '4px 10px !important',
  }
}))(Button);