import React from 'react';
import { Container, Label } from 'semantic-ui-react';

const defaultStyles = {
  fontSize: '12px',
  backgroundColor: '#FDFEFE',
};

function CurrentLabel(props) {
  const nextStep = React.useCallback(() => {
    // console.log('CURRENT Siguiente paso', props.previousStep.value);
    const nextStep = Array.isArray(props.previousStep.value)
      ? props.previousStep.value[1]
      : props.previousStep.value;

    // console.log('CURRENT Next Step', nextStep);
    props.triggerNextStep({
      trigger: nextStep,
      value: props.previousStep.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    nextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (Array.isArray(props.previousStep.value)
    ? props.previousStep.value[0]
    : props.previousStep.value
  );
}

export default CurrentLabel;
