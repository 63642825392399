import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import Table from '../../../../../template/Table'
class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            rows: [],
            containers: [],
            columns: [
                { label: 'Count of Container', key: 'ContainerNo' },
                { label: 'Container Type Commodity', key: 'ContainerTypeCode' },
                { label: 'Commodity', key: 'Commodity' },
                { label: 'Container Grade ', key: 'ContainerGrade' },
                { label: 'Empty Equipmente Pickup Location', key: '' }
            ],
        }
    }

    componentDidMount = () => {
        this.setState({
            data: this.props.data
        }, () => {
            let containers = this.state.data && this.state.data.Container && Array.isArray(this.state.data.Container) && this.state.data.Container.length > 0 ? this.state.data.Container : []
            this.setState({
                containers
            })
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data

        }, () => {
            let containers = this.state.data && this.state.data.Container && Array.isArray(this.state.data.Container) && this.state.data.Container.length > 0 ? this.state.data.Container : []
            this.setState({
                containers
            })
        })
    }



    render = () => {
        let { containers } = this.state
        return (
            <Card>
                <CardBody>
                    <h4 className="mb-3 py-2">Containers</h4>
                    <div className="row">
                        <div className="col-1">
                            <em className="fas fa-ship fa-4x mb-3 py-2"></em>
                        </div>
                        <div className="col-10">
                            <Table data={containers} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}
                            ></Table>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Container;