import React from 'react';
import {Row} from 'reactstrap';

/**
 * Componentes stanless
 * @param {*} props 
 */
const PurchaseOrderDetail = ({ data }) => {

  return (
    <div>
      <Row>
        <div className="col _poNumber">
          <h5><strong>PONumber: </strong>{data.PONumber}</h5>
        </div>
        <div className="col"></div>
      </Row>
      <Row>
        <div className="col">
          <h5> <strong>Supplier Name: </strong>{data.SupplierName}</h5>
        </div>
        <div className="col">
          <h5><strong>Address: </strong>{data.Address}</h5>
        </div>
        <div className="col">
          <h5><strong>Address details: </strong>{data.AddressDetails}</h5>
        </div>
        <div className="col">
          <h5><strong>Contact Name: </strong>{data.Contact}</h5>
        </div>                                
      </Row>
    </div>
  );
};

export default PurchaseOrderDetail;