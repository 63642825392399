import React from 'react';

const Data = {
  sendEmailTo : (PONumber)=>[
    {
      'Email': 'carlos.salazar@magic-log.com',
      'Name': 'Carlos Salazar',
      'url': 'https://magic-dome.firebaseapp.com//poconfirmation/' + PONumber
    },
    {
      'Email': 'jakopo.trejo@magic-log.com',
      'Name': 'Jako',
      'url': 'https://magic-dome.firebaseapp.com//poconfirmation/' + PONumber
    },
    {
      'Email': 'shunashi.cruz@magic-log.com',
      'Name': 'Shunashi Cruz',
      'url': 'https://magic-dome.firebaseapp.com//poconfirmation/' + PONumber
    },
      
    {
      'Email': 'emmanuel.trejo@magic-log.com',
      'Name': 'estebi',
      'url': 'https://magic-dome.firebaseapp.com//poconfirmation/' + PONumber
    },
    {
      'Email': 'beker863686@gmail.com',
      'Name': 'carlos rene mendoza',
      'url': 'https://magic-dome.firebaseapp.com//poconfirmation/' + PONumber
    },
  ],
  config: [
    { label: 'PO Creation Date', id: 'POCreateDate', className: 'col-3' },
    { label: 'Requested Ex Works Date', id: 'RequestedExWorksDate', className: 'col-3' },
    { label: 'Required At Destination', id: 'RequiredDestination', className: 'col-3' },
    { label: 'Confirmation Number', id: 'ConfirmationNumber', className: 'col-3' },
    { label: 'Confirmation Date', id: 'ConfirmationDate', className: 'col-3' },
    { label: 'Promissed Cargo Date', id: 'PromiseCargoReadyDate', className: 'col-3' },
    { label: 'Total Qty Requested', id: 'TotalQtyOrdered', className: 'col-3' },
    { label: 'Total Qty Confirmed', id: 'TotalQtyConfirmed', className: 'col-3' }
  ],
  configHeader: [
    { label: 'PO Number', id: 'PONumber', className: 'col-12' },
    { label: 'Supplier Name', id: 'SupplierName', className: 'col-3' },
    { label: 'Address', id: 'Address', className: 'col-3' },
    { label: 'Address Details', id: 'AddressDetails', className: 'col-3' },
    { label: 'Contact Name', id: 'Contact', className: 'col-3' },
  ],
  columItems: [
    {
      label: 'Item No.',
      key: 'ItemNumber'
    },
    {
      label: 'Item Description',
      key: 'ItemDescription'
    },
    {
      label: 'Qty Ordered',
      key: 'QtyOrdered'
    },
    {
      label: 'Unit Measure',
      key: 'UnitsMeasure'
    },
    {
      label: 'PO Country',
      key: 'CountryOrigin'
    }
  ],
  map: {
    'consignees': 'ConsigneeCode',
    'deliveries': 'DeliveryCode',
    'suppliers': 'SupplierCode',
    'consigneeContact': 'ConsigneeContactId',
    'deliveryContact': 'DeliveryContactId',
    'supplierContact': 'SupplierContactId'
  },
  TransportMode: {
    1: 'FCL',
    2: 'LCL',
    3: 'Air',
    4: 'Inland',
    5: 'Break Bulk'
  },
  Consignee: {
    'CON1': '"Interglobo North America Inc"',
    'CON2': 'Norwegian Cruise Line'
  },
  columnsSumary: [
    {
      label: 'Container No',
      key: 'ContainerNo'
    },
    {
      label: 'Type',
      key: 'ContainerTypeCode'
    },
    {
      label: 'Commodity',
      key: 'Commodity'
    },
    {
      label: 'Grade',
      key: 'ContainerGrade'
    }
  ],
  columnsInvoice: [
    {
      label: 'Invoice No',
      key: 'PONumber'
    },
    {
      label: 'Invoice Date',
      key: 'InvoiceDate'
    },
    {
      label: ' Origin Warehouse',
      key: 'OriginWarehouse'
    },
    {
      label: 'Destination Warehouse kjaskjdhksjdfhkjh',
      key: 'DestinationWarehouse'
    }
  ],
  columnsInvoiceCreate: [
    {
      label: 'Invoice No',
      key: 'PONumber'
    },
    {
      label: 'Invoice Date',
      key: 'InvoiceDate'
    },
    {
      label: ' Origin Warehouse',
      key: 'OriginWarehouse'
    },
    {
      label: 'Destination Warehouse',
      key: 'DestinationWarehouse'
    }
  ],
  configSumaryHeader: [
    { label: 'Requested Ex Works', id: 'RequestedExWorksDate', type: 'simple' },
    { label: 'Required Destination', id: 'RequiredDestination', type: 'simple' },
    { label: 'Promise Cargo Ready', id: 'PromiseCargoReadyDate', type: 'simple' },
    { label: 'Actual Cargo Ready', id: 'ActualCargoReadyDate', type: 'simple' },
    { label: 'Product Type', id: 'ProductTypeId', type: 'simple' },
    { label: 'PO Division', id: 'DivisionId', type: 'simple' },
    { label: 'Country of Origin', id: 'OriginCountryId', type: 'simple' },
    { label: 'Transport Mode', id: 'TransportMode', type: 'simple' },
    { label: 'Incoterm', id: 'Incoterm', type: 'simple' },
    { label: 'POD', id: 'POPOD', type: 'simple' },
    { label: 'Final Destination', id: 'FinalDestination', type: 'simple' },
    { label: 'Project', id: 'ProjectId', type: 'simple' },
    { label: 'WMSOderRef', id: 'WMSOrderRef', type: 'simple' },
    { label: 'DistributorPO', id: 'DistributorPO', type: 'simple' },
  ],
  columnsItemsSumary: [
    {
      label: 'Line No.',
      key: 'ItemNumber'
    },
    {
      label: 'Item No.',
      key: 'ItemDescription'
    },
    {
      label: 'Item Description',
      key: 'QtyOrdered'
    },
    {
      label: 'QTY Ordered',
      key: 'UnitsMeasure'
    },
    {
      label: 'UOM',
      key: 'CountryOrigin'
    },
    {
      label: 'Country',
      key: 'CountryOrigin'
    },
    {
      label: 'Case QTY',
      key: 'CountryOrigin'
    },
    {
      label: 'Case Volume',
      key: 'CountryOrigin'
    },
    {
      label: 'Case Description',
      key: 'CountryOrigin'
    }
  ],
  columnsPackingsSumary: [
    {
      label: 'Count of Packages',
      key: 'CountPackages'
    },
    {
      label: 'Package Type',
      key: 'PackageType'
    },
    {
      label: 'Weight',
      key: 'Weight'
    },
    {
      label: 'Volume',
      key: 'Volume'
    }
  ],
  
  columnsContainers: [
    {
      label: 'Container No.',
      field: 'ContainerNumber',
      width: 100,
      custom: (e) => {
        return (
          <a href={`/container-detail/${e.ContainerNumber}`}>{e.ContainerNumber}</a>
        )
      }
    },
    {
      label: 'Seal 1',
      field: 'ContainerSeal',
      width: 100      
    },
    {
      label: 'Seal 2',
      field: '',
      width: 100,
      custom: (e) => {
        return (<h4>--</h4>);
      }
    },
    {
      label: 'Container Type',
      field: 'ContainerType',
      width: 100      
    }
  ],
  columnsPackages: [
    {
      label: 'Package ID',
      field: 'PackageId',
      width: 100
    },
    {
      label: 'Package Type',
      field: 'PackageType'
    },
    {
      label: 'Weight UOM',
      field: 'not',
      custom: (e) => {
        return (
          <h4>--</h4>
        );
      }
    },
    {
      label: 'Volume UOM',
      field: 'not2',
      custom: (e) => {
        return (
          <h4>--</h4>
        );
      }
    },
    {
      label: 'Dimensions UOM',
      field: 'not3',
      custom: (e) => {
        return (
          <h4>--</h4>
        );
      }
    }
  ]
};

export default Data;