import openSocket from 'socket.io-client';
const URL = 'https://poseidonsocketservices.azurewebsites.net/comments';
// const URL = 'http://localhost:2701/comments';

const  socket = openSocket(URL);
function subscribeToSocket(customer, cb) {

  socket.on('unread_message', customer => cb(null, customer));
  // socket.emit('mymess', 'message');
}
export { subscribeToSocket };