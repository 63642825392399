const Datas = {
    selectsAdditional : {
        "CARGO-TYPES": [], "SERVICES": [], "EMPTY-PICKUP-LOCATIONS": [], "UNITS-DIMENSION": [],
        "PORTS": [], "COMMODITIES": [], "INCO-TERMS": [], "FREIGHT-TERMS": [], "THERE-PLS": [],
        "PACKAGE-TYPES": [], "UNITS-VOLUME": [], "UNITS-WEIGHT": [], "CONTAINER-TYPES": [],
        "SHIPMENT-TYPES": [], "CONTAINER-GRADES": [], "TRANSPORTS-MODE": []
    },
    commentsAdditional : {
        Comments: '',
        InternalComments: ''
    },
    carrierBooking : {
        CarrierAirCode : '',
        CarrierOceanCode : '',
        VesselName: '',
        Voyage: '',
        CarrierBookingNumber: '',
        CoLoaderBookingNumber: '',
        EstimatedTimeDeparture: '',
        EstimatedTimeArrival: '',
        DocumentCuttOff: '',
        CargoCutOff: ''
    },
    map:{
        'consignees': 'ConsigneeCode',
        'deliveries': 'DeliveryCode',
        'suppliers': 'SupplierCode',
        'consigneeContact': 'ConsigneeContactId',
        'deliveryContact': 'DeliveryContactId',
        'supplierContact': 'SupplierContactId'
    },
    containerBooking : {
        BookingContainerId : '',
        ContainerTypeCode: '',
        CommodityId: '',
        ContainerGrade: '',
        EmptyPickupLocationId: ''
    },
    columnsBookingCreation: [
        { label: "PO No", key: 'PONumber' },
        { label: "Supplier", key: 'SupplierName' },
        { label: "Consignee", key: 'ConsigneeName' },
        { label: "Creation Date", key: 'POCreateDate' },
        { label: "Promise Cargo Ready", key: 'PromiseCargoReadyDate' },
        { label: "Status", key: 'StatusDesc' },
    ],
    selectsDocumentBooking :
    {
        'DOC-CATEGORIES': [
            { value: 3, label: "Carrier DO / CRO" },
        ]
    },
    DocumentDocumentBooking :{
        Name: '',
        DocCategoryId: '',
        file: '',
        other:'',
        ModuleId:"",
        RowId:"",
        Category:"",
        
    },
    columnsAddBooking: [
        {
            label: "PO No",
            key: 'PONumber'
        },
        {
            label: "Supplier",
            key: 'SupplierName'
        },
        {
            label: "Consignee",
            key: 'ConsigneeName'
        },
        {
            label: "Creation Date",
            key: 'POCreateDate'
        },
        {
            label: "Promise Cargo Ready",
            key: 'PromiseCargoReadyDate'
        },
        {
            label: "Status",
            key: 'StatusDesc'
        },
    ],
    _packageBooking: {
        CountPackages: '',
        PackageTypeCode: '',
        Weight: '',
        UnitWeightCode: '',
        Volume: '',
        UnitVolumeCode: '',
        Length: '',
        Width: '',
        Height: '',
        UnitDimensionCode: '',
        Dimensions:""
    },
}


export default Datas





