export const selectedPlace = {
    'id': '0000000001',
    BookingNumber: '',
    'From': {
      'Contact': {
        'name': '',
        'contactDetail': ''
      },
      'Location': {
        'country': '',
        'zipcode': '',
        'state': '',
        'city': '',
        'address': '',
        'Latitude': '',
        'Longitude': ''
      }
    },
    'To': {
      'Contact': {
        'name': '',
        'contactDetail': ''
      },
      'Location': {
        'country': '',
        'zipcode': '',
        'state': '',
        'city': '',
        'address': '',
  
        'Latitude': '',
        'Longitude': ''
      }
    },
    'Shipping': {
      'carrier': '',
      'service': ''
    },
    'Shipper': {
      'name': '',
      'address': '',
      'pickupDate': ''
    },
    "Supplier": {
      "name": "",
      "address": "",
      "details": "",
      "contact": "",
      "email": "",
      "pickupDate": ""
      },
    'Consignee': {
      'name': '',
      'address': '',
      'pickupDate': ''
    },
    'Delivery': {
      'name': '',
      'address': '',
      'pickupDate': ''
    },
    'Traking': {
      'name': ''
    },
    'Dispatch': {
      'status': '',
      'pickupDate': '',
      'sentDate': ''
    },
    'Notes': '',
    'Status': {
      'label': '',
      'id': 1
    },
    Project : {
      id : '',
      name : ''
    },
    'Action': '',
    'trackingStatus': [
  
    ],
    'PONumber': '',
    'ETD': '',
    'ETA': '',
    'LastUpdate': {
      'porcent': '',
      'date': ''
    },
    'Carrier': {
      'CarrierName': '',
      'VesselOrFlight': '',
      'Latitude': '',
      'Longitude': ''
    },
    'Cruise': {
      'CruiseName': ''
    },
    'CargoReady': '',
    'ATA': '',
    'ATD': ''
  };
  