import React from 'react';
import { Card, CardBody } from 'reactstrap'

let data = [
  {
      name: 'Ponte Caracciolo',
      id: 'ID1732488',
      actualArriveDateAtWarehouse: '10-jan-019',
      days: '45 days',
      color: 'red'
  },
  {
      name: 'Ponte Caracciolo',
      id: 'ID1732488',
      actualArriveDateAtWarehouse: '23-jan-019',
      days: '30 days',
      color: 'orange'
  },
  {
      name: 'Ponte Caracciolo',
      id: 'ID1732488',
      actualArriveDateAtWarehouse: '07-jan-019',
      days: '37 days',
      color: 'orange'
  }
];


/*=============================================
=  Events That Require Attention Component    =
=============================================*/

function EventsAttention(props){
  let CARDS = data.map((e, key) => {
    return (
        <Card className="myCard" key={key}>
            <CardBody>
                <div className="row" >
                    <div className="col-md-3 col-lg-3 col-sm-6" style={{ fontSize: '10px' }}>
                        {e.name}
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-6" style={{ fontSize: '10px' }}>
                        {e.id}
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-6" style={{ fontSize: '10px' }}>
                        {'Actual Arrive Date At Warehouse'}
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-6" style={{ fontSize: '10px' }}>
                        {e.actualArriveDateAtWarehouse}
                    </div>
                    <div className="col-md-2 col-lg-2 col-sm-6" style={{ fontSize: '10px' }}>
                        {e.days}
                    </div>
                    <div className="col-md-1 col-lg-1 col-sm-6" style={{ fontSize: '15px' }}>
                        <em className="fas fa-info-circle mr-3" style={{
                            color: e.color === 'red' ? '#d32f2f' : '#f57c00'
                        }}></em>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
  });

  return (
    <div className="row">
      <div className="col-12">
          <center><h4 className="mb-3 py-2">Events That Requires Attention</h4></center>

      </div>

      <div className="col-12" style={{ 'overflow': 'scroll', 'overflowX': 'hidden', height: '180px' }}>
          {CARDS}
      </div>
    </div>    
  )
}

export default EventsAttention;


