import React from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveBarCanvas = ({ position, data , width, height, left /* see data tab */ }) =>{
  let colors = [];
  data.map((e) => {
    colors.push(e.CancelledColor);
    return e;
  });
  return((
    <ResponsiveBarCanvas
      data={data}
      keys={[
        'Prospecting',
        'Proposal Submitted',
        'Proposal Follow Up',
        'Negotiation',
        'Won',
        'Lost',
        'Cancelled',
        'status2'
      ]}
      axisTop={null}
      indexBy="status"
      // isInteractive={false}
      margin={{ top: 3, right: 10, bottom: 80, left: left ? left : 80 }}
      pixelRatio={1}
      padding={0.3}
      innerPadding={0}
      minValue="auto"
      // minValue={-97}
      maxValue="auto"
      groupMode="stacked"
      layout={position ? position :'vertical'}
      reverse={false}
      colors={colors}
      // box-shadow={{0 8px 16px 0 rgba(0,0,0,0.2)}}
      colorBy="index"
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: -72,
        // legend: 'country',
        legendPosition: 'middle',
        legendOffset: 36
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'food',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      tooltip={ (val)=> {
        return (
          <h5>{val.data.id ? val.data.id  : val.data.status} -- {val.data.Cancelled}</h5>
        );
      }}
    
      enableGridX={false}
      enableGridY={false}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
    />
  ));
};
