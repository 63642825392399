export const Services = {
   "CATALOGS": {
      "path": "https://magicdomeapicatalog.azurewebsites.net/"
   },
   "ORDER": {
      "path": "https://magicdomeapiorder.azurewebsites.net/"
   },
   "SHIPMENTS": {
      "path": "https://magicdomeapishipments.azurewebsites.net/"
   },
   "BOOKING": {
      "path": "https://magicdomeapibooking.azurewebsites.net/"
   }
}