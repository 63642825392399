import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const documentMenu = [
    { label: 'Purchase Order Documents', trigger: 'purchaseOrderDoc', value: 'currentLabel' },
    { label: 'Shipment Documents', trigger: 'shipmentDoc', value: 'currentLabel' },
    { label: 'Customs Documents', trigger: 'customDoc', value: 'currentLabel' }
];

function DocumentMenu(props) {
    return (
        <div className="row" style={{ width: '100%' }}>
            <ChatbotButtons buttons={documentMenu} botSteps={props} />
        </div>
    );
}

export default DocumentMenu;
