export const HandlerSearch = (data, filterText, keys) => {
  if (filterText){
    let founded = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let valueFounded = 0;

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (element[key]){
          
          let elementParsed = '';
          // typeof element[key] === 'number' ?  element[key].toString(): element[key].toLowerCase();
          if (typeof element[key] === 'number'){
            // console.log("-->3", element[key]);
            elementParsed = element[key].toString();
            // elementParsed.toLowerCase();
          }
          if (typeof element[key] === 'object'){
            // console.log("-->2", element[key]);
            elementParsed = element[key].join('\n');
            elementParsed = elementParsed.toLowerCase();
            // console.log('--->', elementParsed);
          }
          if (typeof element[key] === 'string') {
            // console.log("-->", element[key]);
            elementParsed = element[key].toLowerCase();
          }

          if (elementParsed.includes(filterText.toLowerCase())){
            valueFounded ++;
          }
        }
      }

      if(valueFounded > 0)founded.push(element);
    }
    return founded;
  } else {
    return data;
  }
};
