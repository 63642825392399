import React, { Component } from 'react';

import Table from '../../../../../../template/Table'

const TITLES = {
    1 : 'EU - Rental Container & Transportation',
    2 : 'PICK UP OF EMPTY CONTAINER AND DELIVERY TO VENDOR\'S LOCATION (cost per Truck / 2x20\' cont)',
    3 : 'PICK UP OF FULL CONTAINER FROM VENDOR LOCATION AND DELIVERY TO MARSEILLE SHIPYARD (cost per Truck / 2x20\' cont, if weight allows it)',
    4 : 'PICK UP OF FULL CONTAINER/OFFLOADS FROM MARSEILLE SHIPYARD AND DELIVERY TO VENDORS (cost per Truck / 2x20\' cont, if weight allows it)',
    5 : 'PICK UP OF EMPTY CONTAINER/OFFLOADS FROM VENDORS LOCATION AND DELIVERY TO RENTAL PLACE',
    6 : 'PICK UP OF EMPTY CONTAINER FROM MARSEILLE SHIPYARD AND DELIVERY BACK TO RENTAL PLACE (cost per Truck / 2x20 cont)',
    7 : 'DELIVERY OF FULL TRAILER FROM GENOA WAREHOUSE TO MARSEILLE SHIPYARD'
}

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                { label : 'ID', key : 'ID'},
                { label : 'Description', key : 'Description'},
                { label : 'To From Marseille', key : 'ToFromMarseille'},
                { label : 'To From Brest', key : 'ToFromBrest'},
            ],
            data: []
        };
    }


    componentDidMount = () => {
        this.setState({
            data : this.props.results
        })
    }


    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data : nextProps.results
        })
    }



    render() {

        return (
            <div className="row">
                <div className="col-12">
                    <span style={{ fontWeight:'bold'}}>{TITLES[this.props.id] ? TITLES[this.props.id] : ''}</span>
                </div>
                <div className="col-12">
                <Table
                    columns={this.state.columns}
                    data={this.state.data}
                    large = {true}
                    options={{
                        // pagination:true,
                        large : true,

                        downloads: {
                            pdf: false,
                            xlsx: false
                        }
                    }}
                />
                </div>
            </div>
        );
    }
}

export default Results;


