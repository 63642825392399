const Required = {
    PONumber: "",
    POCreateDate: "",
    RequestedExWorksDate: "",
    RequiredDestination: "",
    OriginCountryId: "",
    TransportModeId: "",
    Incoterm: "",
    SupplierCode :"",
    ConsigneeCode :"",
    DeliveryCode :"",
}

export default Required;