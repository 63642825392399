import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

export const columns = (getShipmentList, customer, rmShipmentModal, user) => [
  {
    label: 'Po No', key: 'PONumber', minWidth: 200, fixed: true, align: 'center', custom: (value) => (
      <Col md={6} xl={6}>
        <Row>
          <Col md={12}>
            <Link to={'/po-detail-view/' + value.POId}>{value.PONumber}</Link>
          </Col>
        </Row>
      </Col>
    )
  },
  { label: 'Split No', key: 'SplitNo', minWidth: 80, align: 'center' },
  { label: 'PO Status', key: 'StatusDesc', minWidth: 140, align: 'center', custom: (value) => (<Button className={value.StatusDesc === 13 ? 'btn-oval _gray_button' : 'btn-oval'} color={'primary'} onClick={() => { }}>{value.StatusDesc}</Button>) },
  { label: 'Supplier', key: 'SupplierName', minWidth: 120, align: 'center' },
  { label: 'Consignee', key: 'ConsigneeName', minWidth: 120, align: 'center' },
  { label: 'Delivery', key: 'DeliveryName', minWidth: 120, align: 'center' },
  { label: 'POD', key: 'POPOD', minWidth: 100, align: 'center' },
  { label: 'Final Destination', key: 'FinalDestination', minWidth: 120, align: 'center' },
  { label: 'Creation Date', key: 'EntryCreatedDate', minWidth: 120, align: 'center' },
  { label: 'Promise Cargo Ready', edit: true, type: 'datatime', key: 'PromiseCargoReadyDate', minWidth: 130, align: 'center' },
  { label: 'Actual Cargo Ready', edit: true, type: 'datatime', key: 'ActualCargoReadyDate', minWidth: 120, align: 'center' },
  { label: 'Actual Cargo Pickup', edit: true, type: 'datatime', key: 'ActualCargoPickup', minWidth: 120, align: 'center' },
  { label: 'Est. Arrival Consol WH', edit: true, type: 'datatime', key: 'EstimatedArrivalConsolWH', minWidth: 130, align: 'center' },
  { label: 'Actual Arrival Consol WH', edit: true, type: 'datatime', key: 'ActualArrivalConsolWH', minWidth: 160, align: 'center' },
  { label: 'Total Weight', edit: true, type: 'Input', regExp: /^-*[0-9]+$/, key: 'GrossWeight', minWidth: 120, align: 'center' },
  { label: 'No Pallets', edit: true, type: 'Input', regExp: /^-*[0-9]+$/, key: 'NoPallets', minWidth: 120, align: 'center' },
  { label: 'Spaces', edit: true, type: 'Input', regExp: /^-*[0-9]+$/, key: 'Spaces', minWidth: 120, align: 'center' },
];
