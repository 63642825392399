import React, {useState} from "react";
import { useGetPOItems } from "../hooks";
import { withRouter } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { Search } from "semantic-ui-react";
import ListItemTabs from "./listItemTabs";
import { message } from "antd";
import Loader from "../../../../../../Global/Loader";
import { LibraryAddOutlined } from "@mui/icons-material";
import { ListItemMassiveUpdateModal } from "./listItemMassiveUpdateModal";

const ListItems = ({ poId, lng, user, getPO, rolId, poStatus }) => {
  const {
    poItems,
    loading,
    error,
    handleExpand,
    handleEditItem,
    updatePOItem,
    handleSearch,
    handleUpdateStatus,
    massiveUpdate,
    parcedPOItem,
  } = useGetPOItems(poId, user, lng);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const handleSendErrorMessage = () => {
    message.error("Error updating purchase order item.");
  };

  const updateStatus = async (item, status) => {
    try {
      await handleUpdateStatus(item.POId, item.POItemsId, status);
      await getPO();
    } catch (error) {
      throw new Error(error);
      // handleSendErrorMessage();
    }
  }

  const updateParcedPOItem = async (id) => {
    try {
      await parcedPOItem(id);
      await getPO();
    } catch (error) {
      // handleSendErrorMessage();
    }
  };

  const updateItem = async (item) => {
    try {
      await updatePOItem(item);
      await getPO();
      message.success("Purchase order item successfully updated.");
    } catch (error) {
      handleSendErrorMessage();
    }
  };

  if (loading) return <Loader />;
  if (error) return `Error! ${error}`;

  const handleSearchChange = (e, { value }) => {
    setSearch(value);
    handleSearch(value);
  };

  const toggle = () => {
    setOpen(!open);
  };

  const update = async (values) => {
    try {
      let body = {
        POId: poId,
        ...values,
      };
      await massiveUpdate(body);
      await getPO();
    } catch (error) {
      throw error;
    } finally {
      setOpen(false);
    }
  };

  return (
    <div className="listItemContainer">
      <ListItemMassiveUpdateModal
        open={open}
        toggle={toggle}
        update={update}
        lng={lng}
        poStatus={poStatus}
      />
      <div className="listItemHeader">
        <Search
          className="col-4"
          input={{ icon: "search", iconPosition: "left", fluid: true }}
          placeholder="Search by: Line No., Item, Description..."
          value={search}
          open={false}
          name="search"
          onSearchChange={handleSearchChange}
        />
        {poItems.length > 1 && (
          <div className="col-2 offset-6 divEnd">
            <Tooltip title={<label className="label-euro normal">Massive update</label>} arrow>
              <IconButton onClick={toggle}>
                <LibraryAddOutlined />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <ListItemTabs 
        poItems={poItems}
        handleExpand={handleExpand}
        handleEditItem={handleEditItem}
        handleUpdateStatus={updateStatus}
        parcedPOItem={updateParcedPOItem}
        handleSendErrorMessage={handleSendErrorMessage}
        updateItem={updateItem}
        error={error}
        lng={lng}
        rolId={rolId}
        />
    </div>
  );
};

export default withRouter(ListItems);
