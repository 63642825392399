import React, { Component } from 'react';
import AddComment from './components/add-comment';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { Services } from './../../../Services'
import { Menu, Dropdown } from 'antd';
import { MoreOutlined, DeleteOutlined, CloudDownloadOutlined, MonitorOutlined } from '@ant-design/icons';
// import RestService from '../../../../../../services/restService';
import Table from "../../../../../Components/Table";
import './styles.scss';
import Axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import { withRouter } from "react-router-dom";
import { DocumentsResponse } from '../../../mocks/poDetail';
import { Fab } from '@material-ui/core';

// const rest = new RestService();
const size = '18px';
class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match ? this.props.match.params.id : -1,
      modal: false,
      openDeleteModal: false,
      comments: [],
      order: {},
      data: [],
      columns: [
        {
          label: "Actions", key: '', custom: (value) => {

            return <Dropdown overlay={
              (
                <Menu>
                  <Menu.Item >
                    <div style={{ display: "inline-flex", alignItems: "center" }} className='container-options' >
                      <CloudDownloadOutlined style={{ fontSize: size }} />
                      <a href={value.URL}>
                        <i style={{ marginLeft: "10px" }} className='global-text'>Download</i>
                      </a>
                    </div>
                  </Menu.Item>
                  {!this.props.isMobil ?
                    <Menu.Item onClick={() => {
                      window.open(`${Services.ORDEN_SERV.path}orderDocument/document/view/pdf/?url=${value.URL}`);
                    }}>
                      <div style={{ display: "inline-flex", alignItems: "center" }} className='container-options' >
                        <MonitorOutlined style={{ fontSize: size }} /> <i style={{ marginLeft: "10px" }} className='global-text'>Open</i>
                      </div>
                    </Menu.Item>
                    :
                    ''
                  }

                  {
                      <Menu.Item onClick={() => this.rmDocument(value)}>
                        <div style={{ display: "inline-flex", alignItems: "center" }} className='container-options' >
                          <DeleteOutlined style={{ fontSize: size }} /> <i style={{ marginLeft: "10px" }} className='global-text'>Delete</i>
                        </div>
                      </Menu.Item>
                    // : ''
                  }
                </Menu>
              )
            } placement="topCenter">
              <div >
                <MoreOutlined className='btn-expander' style={{ fontSize: '26px' }} />
              </div>
            </Dropdown>

          }
        },
        { label: "Document name", key: 'Name' },
        { label: "Document category", key: 'Description' },
        { label: "Created at", key: 'CreationDateDocument' },
      ],
    };
  }


  componentDidMount = () => {
    this.setState({
      order: this.props.order,
      data: this.props.data,
    }, () => {
      this.getComments();
    });
  }


  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.data,
    }, () => this.getComments());
  }

  delDoc = (value) => (value.CreateBy === 'API' && this.props.rolId === '1') || (value.CreateBy === 'API' && this.props.rolId === '6') ? <a className="fas fa-trash-alt fa-2x" onClick={() => this.rmDocument(value)}></a> : <></>


  getComments = async () => {
    try {
      // let { id } = this.state;
      // let url = `${Services.ORDEN_SERV.path}orderDocument/search/${id}?userId=${this.props._user}&moduleId=${10}`;

      // let success = await Axios.get(url);
      // if (success.data && success.data.data) {
      //   const documents = success.data.data;

      //   this.setState({
      //     data: documents.filter((doc) => doc.Deleted === '0'),
      //   });
      // }
      this.setState({
        data: DocumentsResponse.filter((doc) => doc.Deleted === '0'),
      });
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  callback = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }), () => this.getComments());
  }

  onError = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }), () => this.getComments());
  }

  rmDocument = (value) => {
    this.setState({ openDeleteModal: true, modalValue: value, nameDoc: value.Name });
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      openDeleteModal: !prevState.openDeleteModal
    }));
  }

  removeDocumentModal = async () => {
    // let values = this.state.modalValue;
    // let url = `${Services.ORDEN_SERV.path}orderDocument/delete/`;

    // await Axios.post(url, values)
    //   .then(success => {
    //     if (success.data.code === 200) {
    //       this.setState({ openDeleteModal: false }, () => this.getComments());
    //     } else {
    //       console.error('No se pudo eliminar el documento');
    //     }
    //   });
  }

  render() {

    return (
      <>
        <Card className="myCard">
          <CardBody>
            <div className="row">
              <div className="col-12">
                <Table
                  columns={this.state.columns}
                  data={this.state.data}
                  itemsByPage={10}
                  options={{
                    large: false,
                    downloads: {
                      pdf: false,
                      xlsx: false
                    },
                    pagination: true,
                    search: false
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-11">
              </div>
              <div className="col-1">
                <Fab color="primary" size="small" aria-label="add" onClick={this.toggle}>
                  <AddIcon />
                </Fab>
              </div>
            </div>
          </CardBody>
        </Card>

        <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}>Create new document</ModalHeader>
          <ModalBody>
            <AddComment callback={this.callback} onError={this.onError} id={this.state.id} _user={this.props._user} />
          </ModalBody>
        </Modal>


        <Modal isOpen={this.state.openDeleteModal} toggle={this.toggleDeleteModal}>
          <ModalHeader id={'delete_document'} className="header Modal Remove Document" toggle={this.toggleDeleteModal}><h4>Delete document</h4></ModalHeader>
          <ModalBody>
            <center>{`Are you sure you want to eliminate this document ${this.state.nameDoc}?`}</center>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.removeDocumentModal}>Yes</Button>{' '}
            <Button color="secondary" onClick={this.toggleDeleteModal}>No</Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }
}

export default withRouter(Tracking);