import React, { Suspense } from 'react';
import { Card, CardBody } from 'reactstrap';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import { Tab } from 'semantic-ui-react';
const MyReportsComponent = React.lazy(() => import('./components/MyReports'));


/*=============================================
=            Reports and analytics            =
=============================================*/
class ReportAnalytics extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      inDetail: false,
      tabIndex: 0
    };
  }

  onChangeTab = (evt, data) => {
    this.setState({tabIndex: data.activeIndex});
  }

  componentWillReceiveProps = () => {
    this.setState({inDetail: false});
  }

  render(){
    const {tabIndex} = this.state;
    const allowed = {1:true, 2: true, 4: true, 6: true };
    let panes = [
      { menuItem: 'Standard Reports', render: () => <Suspense fallback={<div>Loading...</div>}> <MyReportsComponent type="Standard" _user={this.props._user} _customer={this.props._customer} inDetail={this.state.inDetail}/></Suspense>}
    ];
    if(allowed[this.props._rolId]){
      panes.push({ menuItem: 'My Reports', render: () => <Suspense fallback={<div>Loading...</div>}><MyReportsComponent type='MyReports' _user={this.props._user} _customer={this.props._customer} inDetail={this.state.inDetail}/></Suspense>});
    }

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas fa-1x mr-2 fas fa-table"/>
            My Reports
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            <Tab
              menu={{ secondary: true,className:'myWrapped' }}
              onTabChange={this.onChangeTab}
              activeIndex={tabIndex}
              menuPosition='right'
              panes={panes} />
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default ReportAnalytics;
