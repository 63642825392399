import React from 'react';

function DialogOperation(props) {
  const { references } = props;

  const defaultStyles = {
    listStyleType: 'circle',
  };

  const list = () => {
    return references.optionList.map((item, index) => (
      <li key={`li${index}`} style={defaultStyles}>
        {item}
      </li>
    ));
  };

  return (
    <>
      <div style={{ fontSize: '12px', marginLeft: '10px' }}>
        <b>{references.message}</b>
        <ul
          key={'tableComponent'}
          style={{ fontSize: '11px', marginLeft: '22px' }}
        >
          {list()}
        </ul>
      </div>
    </>
  );
}

export default DialogOperation;
