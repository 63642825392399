export const TrendData = [
  {
    'label': 'Containers',
    'color': '#768294',
    'data': [
      [
        'Jan',
        '0'
      ],
      [
        'Feb',
        '0'
      ],
      [
        'Mar',
        '0'
      ],
      [
        'Apr',
        '0'
      ],
      [
        'May',
        '0'
      ],
      [
        'Jun',
        '0'
      ],
      [
        'Jul',
        '0'
      ],
      [
        'Aug',
        '0'
      ],
      [
        'Sep',
        '0'
      ],
      [
        'Oct',
        '0'
      ],
      [
        'Nov',
        '0'
      ],
      [
        'Dec',
        '0'
      ]
    ]
  },
  {
    'label': 'Shipments',
    'color': '#1f92fe',
    'data': [
      [
        'Jan',
        '0'
      ],
      [
        'Feb',
        '0'
      ],
      [
        'Mar',
        '0'
      ],
      [
        'Apr',
        '0'
      ],
      [
        'May',
        '0'
      ],
      [
        'Jun',
        '0'
      ],
      [
        'Jul',
        '0'
      ],
      [
        'Aug',
        '0'
      ],
      [
        'Sep',
        '0'
      ],
      [
        'Oct',
        '0'
      ],
      [
        'Nov',
        '0'
      ],
      [
        'Dec',
        '0'
      ]
    ]
  }
];

export const LogisticData = [
  {
    'label': 'Open',
    'value': 0,
    'color': '#8dd3c7',
    'extra': ''
  },
  {
    'label': 'Cargo Ready',
    'value': 0,
    'color': '#ffffb3',
    'extra': ''
  },
  {
    'label': 'Picked Up',
    'value': 0,
    'color': '#bebada',
    'extra': ''
  },
  {
    'label': 'Arrived At Warehouse',
    'value': 0,
    'color': '#80b1d3',
    'extra': ''
  },
  {
    'label': 'PO Shipped',
    'value': 0,
    'color': '#fdb462',
    'extra': ''
  },
  {
    'label': 'Delivered',
    'value': 0,
    'color': '#b3de69',
    'extra': ''
  },
  {
    'label': 'On Hold',
    'value': 0,
    'color': '#fb8072',
    'extra': ''
  },
  {
    'label': 'Cancelled',
    'value': 0,
    'color': '#E0E0E0',
    'extra': ''
  }
];
