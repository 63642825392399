const Forms = {
    ROUTES:[
        {
            type: 'Select',
            options: 'CurrentPortLoad',
            label: 'Current PortLoad',
            class: 'col-md-6 col-lg-4 col-sm-12',
            name: 'CurrentPortLoad',
            id: 'CurrentPortLoad',
            datatype: 'select',
            show: true
        },
        {
            type: 'Select',
            options: 'DestinationPort',
            label: 'Destination Port',
            class: 'col-md-6 col-lg-4 col-sm-12',
            name: 'DestinationPort',
            id: 'DestinationPort',
            datatype: 'select',
            show: true
        },
        {
            type: 'Select',
            options: 'MoveType',
            label: 'Move Type',
            class: 'col-md-6 col-lg-4 col-sm-12',
            name: 'MoveType',
            id: 'MoveType',
            datatype: 'select',
            show: true
        },
    ],
    CONTAINER:[
        {
            type: 'Select',
            options: 'EquipSize',
            label: 'Equipment Size',
            class: 'col-md-6 col-lg-4 col-sm-12',
            name: 'EquipSize',
            id: 'EquipSize',
            datatype: 'select',
            show: true
    
        },
        {
            type: 'Select',
            options: 'EquipType',
            label: 'Equipment Type',
            class: 'col-md-6 col-lg-4 col-sm-12',
            name: 'EquipType',
            id: 'EquipType',
            datatype: 'select',
            show: true
        }
    ]
    
   
}

export default Forms