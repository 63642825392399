import React, { Component, useEffect } from "react";
import { TextField } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";
import Tooltip from "./components/tooltip";
import { PeopleAlt } from "@material-ui/icons";
// const rest = new restService();

const useStyles = (theme) => ({
  textfield: {
    "& .MuiInputBase-input.MuiAutocomplete-input": {
      fontSize: 12,
      color: "#2B3B57",
    },
  },
  datefield: {
    "& .MuiInput-input.MuiInputBase-input": {
      fontSize: 12,
      color: "#2B3B57",
    },
  },
  searchLabel: {
    fontSize: 12,
  },
  popupIndicator: {
    "& path": {
      d: "path('M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z')",
    },
  },
  popupIndicatorOpen: {
    "& path": {
      d: "path('M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z')",
    },
  },
});

const EditableEntities = ({ entities, handleChange }) => {
  return entities.map((info, index) => {
    return (
      <div className="col-4 row" key={`divEntitiesEdit-${index}`}>
        <div className="col-12">
          <div
            className="row"
            style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
          >
            <div className="col-md-12 col-lg-12 col-sm-6 input-form">
              <span className="label-euro bold">{info.titleEdit}</span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6 ">
              <span className="label-euro">
                {info["name"] || ` ${"\u00A0"}`}
              </span>
            </div>
          </div>
          <div style={{ marginTop: "1rem", marginRight: "5rem" }}>
            <Fade left opposite>
              <span>
                <label
                  className="label-euro bold"
                  style={{
                    color: info.direction || !info.id ? "#2b3b57" : "red",
                  }}
                >
                  Address
                  {info.id && <span style={{ color: "#ff0000" }}> *</span>}
                </label>
              </span>
            </Fade>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3px",
              }}
            >
              {info.id ? (
                <TextField
                  variant="standard"
                  type="text"
                  name={"name"}
                  error={info["direction"] ? !info["direction"].length : true}
                  value={info["direction"]}
                  InputProps={{
                    classes: {
                      input: "input-euro",
                    },
                  }}
                  onChange={(val) => {
                    handleChange(
                      info.idDirection,
                      val.target.value,
                      index,
                      "direction"
                    );
                  }}
                />
              ) : (
                <div className="col-md-12 col-lg-12 col-sm-6 ">
                  <span className="label-euro bold">
                    {info["direction"] || ` ${"\u00A0"}`}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div style={{ marginTop: "1rem", marginRight: "5rem" }}>
            <Fade left opposite>
              {" "}
              <span>
                <label
                  className="title-po-entity"
                  style={{
                    color: info.contact || !info.id ? "#2b3b57" : "red",
                  }}
                >
                  Contact
                  {info.id && <span style={{ color: "#ff0000" }}> *</span>}
                </label>
              </span>
            </Fade>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3px",
              }}
            >
              {info.id ? (
                <TextField
                  error={info["contact"] ? !info["contact"].length : true}
                  variant="standard"
                  type="text"
                  name={"name"}
                  value={info["contact"]}
                  InputProps={{
                    classes: {
                      input: "input-euro",
                    },
                  }}
                  onChange={(val) => {
                    handleChange(
                      info.idContact,
                      val.target.value,
                      index,
                      "contact"
                    );
                  }}
                />
              ) : (
                <div className="col-md-12 col-lg-12 col-sm-6 ">
                  <span className="label-euro">
                    {info["contact"] || ` ${"\u00A0"}`}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });
};

class CustomEntities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poInfo: this.props.poInfo,
      confEntities: this.props.confEntities,
      isEditMode: this.props.isEdit ? this.props.isEdit : false,
      updateEntityInfo: this.props.updateEntityInfo
        ? this.props.updateEntityInfo
        : () => {},
      entitiesInfo: [],
      entitiesEdit: <></>,
    };
  }

  componentDidMount = () => {
    this.buildEntities();
  };

  componentWillReceiveProps = (nextProps) => {
    let { poInfo, isEditMode } = this.state;
    if (nextProps.poInfo === poInfo && nextProps.isEditMode === isEditMode)
      return;
    this.setState(
      {
        poInfo: nextProps.poInfo,
        isEditMode: nextProps.isEditMode,
      },
      () => {
        this.buildEntities();
      }
    );
  };

  buildEntities = () => {
    let { poInfo } = this.state;
    let entitiesInfo = [];
    if (poInfo) {
      let entitySupplier = {
        title: "Supplier",
        name: poInfo.SupplierName,
        direction: poInfo.SupplierAddress,
        contact: poInfo.SupplierContact,
        tooltipValue: poInfo.SupplierNameEuro,
        tooltipAddress: poInfo.SupplierAddressEuro,
        titleEdit: "Supplier",
        idName: "SupplierName",
        idDirection: "SupplierAddress",
        idContact: "SupplierContact",
        id: poInfo.SupplierId,
      };

      entitiesInfo.push(entitySupplier);

      let entityConsignee = {
        title: "Consignee",
        name: poInfo.ConsigneeName,
        direction: poInfo.ConsigneeAddress,
        contact: poInfo.ConsigneeContact,
        tooltipValue: poInfo.ConsigneeNameEuro,
        tooltipAddress: poInfo.ConsigneeAddressEuro,
        titleEdit: "Consignee",
        idName: "ConsigneeName",
        idDirection: "ConsigneeAddress",
        idContact: "ConsigneeContact",
        id: poInfo.ConsigneeId,
      };

      entitiesInfo.push(entityConsignee);

      let entityDeliver = {
        title: "Delivery",
        name: poInfo.DeliveryName,
        direction: poInfo.DeliveryAddress,
        contact: poInfo.DeliveryContact,
        tooltipValue: poInfo.DeliveryNameEuro,
        tooltipAddress: poInfo.DeliveryAddressEuro,
        titleEdit: "Delivered to",
        idName: "DeliveryName",
        idDirection: "DeliveryAddress",
        idContact: "DeliveryContact",
        id: poInfo.DeliveryId,
      };

      entitiesInfo.push(entityDeliver);
    }
    this.setState({ entitiesInfo });
  };

  handleChange = (id, value, index, name) => {
    let { entitiesInfo } = this.state;
    entitiesInfo[index][name] = value;
    this.setState({ entitiesInfo });
    this.props.updateEntityInfo(id, value);
  };

  render() {
    const { entitiesInfo, isEditMode } = this.state;
    return (
      <div>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <div className="card-header">
            <div className="card-title" style={{ fontWeight: "bold" }}>
              Entities
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginBottom: "3rem", marginLeft: "1rem" }}
        >
          {isEditMode ? (
            <EditableEntities
              entities={entitiesInfo}
              handleChange={this.handleChange}
              t={this.props.t}
            />
          ) : (
            entitiesInfo.map((info, index) => {
              return (
                <div
                  className="col-4 row"
                  style={{ alignItems: "center" }}
                  key={`divEntities-${info.title}-${index}`}
                >
                  <div
                    className="col-lg-2 col-md-3"
                    style={{ display: "flex", alignContent: "center" }}
                  >
                    <PeopleAlt fontSize="large" color="disabled" />
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <div className="col-12 row">
                      <span className="title-po-entity">{info.title}</span>
                      <Tooltip entity={info} />
                    </div>
                    <div className="col-12 row" style={{ marginTop: "0.5rem" }}>
                      <span className="name-po-entity">{info.name}</span>
                    </div>
                    <div className="col-12 row">
                      <span className="label-po-entity">{info.direction}</span>
                    </div>
                    <div className="col-12 row">
                      <span className="label-po-entity">{info.contact}</span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(CustomEntities);
