import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import { Input } from 'reactstrap';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import 'react-toastify/dist/ReactToastify.css';
import Form from '../../../../../../models/FormData'
import restService from '../../../../../../services/restService';
import Errors from '../../../../../template/Errors'
const rest = new restService();
class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: {},
            errors: {},
            POLine: {
                ItemDescription: "",
                ItemNumber: "",
                OriginCountryId: "",
                QtyOrdered: "",
                UnitMeasure: "",
            },
            PONumber: this.props.PONumber,
            selects: { INCOTERMS: [], PRODUCTS: [], 'UNITS-MEASURE': [], CURRENCIES: [], TRANSPORTS: [], COUNTRIES: [], CUSTOMERS_DIVISIONS: [] },
            Form: Form.CreatePOLine,
            CreatePOLineForm: [],
            items: [],
            error: {
                open: false,
                message: '',
                type: 'error'
            }
        };
    }
    handleChange = name => event => {
        let POLine = this.state.POLine
        POLine[name] = event.target.value
        this.setState({ POLine }, () => this.buildFormPOLine())
    }
    handleChangeSelect = name => event => {
        let POLine = this.state.POLine
        if (event && event.value) {
            POLine[name] = event.value
            this.setState({ POLine }, () => { this.buildFormPOLine() })
        }
        else {
            POLine[name] = ""
            this.setState({ POLine }, () => { this.buildFormPOLine() })
        }
    }
    componentDidMount = () => {
        this.getAllCatalogs()
    }

    getAllCatalogs = () => {
        rest.EXEC({ _function: 'GetAllCatalogs', params: '' }, fetch).then(data => {
            this.setState({ selects: data.data }, () => this.buildFormPOLine())
        }).catch(error => {
            
        })
    }
    buildFormPOLine = () => {
        const { Form, POLine, selects } = this.state
        let CreatePOLineForm = Form.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <p id="Line Items"><label>{e.label} {e.required === true ? <label>*</label> : ""}</label></p>
                    <Input
                        type={e.datatype}
                        maxlength={e.maxlength}
                        minlength={e.minlength}
                        placeholder={e.placeHolder}
                        value={POLine[e.id]}
                        name={e.id}
                        onChange={this.handleChange(e.id)}
                    />
                    <label>{POLine[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>
            )
            else if (e.type === "Select") return (
                <div className={e.class} key={e.id}> <p id="Line Items"><label >{e.label} {e.required === true ? <label>*</label> : ""}</label></p>
                    <Select
                        name={e.name}
                        value={POLine[e.id]}
                        onChange={this.handleChangeSelect(e.id)} options={selects[e.options]}
                    />
                </div>)
            else if (e.type === 'textarea') return (
                <div className={e.class} key={e.id}>
                    <p id="Line Items"><label >{e.label} {e.required === true ? <label>*</label> : ""}</label></p>
                    <Input
                        id="Line Items"
                        type="textarea"
                        rows="1"
                        className="form-control"
                        value={POLine[e.id]}
                        onChange={this.handleChange(e.id)}></Input>
                </div>
            );
            else return '';
        })
        this.setState({ CreatePOLineForm })
    }

    addLine = () => {
        let { POLine } = this.state
        let keys = ['ItemNumber', 'OriginCountryId', 'QtyOrdered', 'UnitMeasure', 'ItemDescription']
        let errors = ''
        keys.forEach((e) => {
            if (!POLine[e]) {
                errors += e + ', '
            }
        })
        if (errors.length > 0)
            this.errorMessageShow(errors)
        else {
            this.setState({
                POLine: { ItemDescription: "", ItemNumber: "", OriginCountryId: "", QtyOrdered: "", UnitMeasure: "" }
            }, () => {
                POLine.PONumber = this.props.PONumber
                rest.EXEC({
                    _function: 'AddOneItemFromOrder',
                    data: POLine,
                    params: ''
                }, fetch).then(success => {
                    this.buildFormPOLine()
                    this.props.refreshItems(POLine)
                    this.successMessageShow()
                }).catch(error => {
                    
                })

            })
        }
    }

    errorMessageShow = (errors) => {
        this.setState({
            error: {
                message: 'Some fileds are required to add new document: ( ' + errors + ' )',
                open: true,
                type: 'warning'
            }
        })
    }
    successMessageShow = () => {
        this.setState({
            error: {
                message: 'New Line Item Added',
                open: true,
                type: 'success'
            }
        })
    }

    itemDeleted = () => this.setState({
        error: {
            message: 'Line Item Removed',
            open: true,
            type: 'error'
        }
    })

    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
        const { CreatePOLineForm } = this.state
        return (
            <div className="row">
                {CreatePOLineForm}
                <div className="col-md-2" style={{ paddingTop: '25px' }}>
                    <Button variant="fab" mini color="primary" aria-label="Add" onClick={this.addLine}>
                        <AddIcon />
                    </Button>
                </div>
                <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
            </div>
        );
    }
}
export default FormExtended