export const catalogsResponse = {
  StatusId: [
    {
      value: 46,
      key: 46,
      text: 46,
    },
    {
      value: 47,
      key: 47,
      text: 47,
    },
    {
      value: 48,
      key: 48,
      text: 48,
    },
    {
      value: 49,
      key: 49,
      text: 49,
    },
    {
      value: 51,
      key: 51,
      text: 51,
    },
    {
      value: 52,
      key: 52,
      text: 52,
    },
    {
      value: 53,
      key: 53,
      text: 53,
    },
    {
      value: 54,
      key: 54,
      text: 54,
    },
    {
      value: 70,
      key: 70,
      text: 70,
    },
  ],
  StatusPo: [
    {
      value: "Cancelada ",
      key: "Cancelada ",
      text: "Cancelada ",
    },
    {
      value: "Carga lista ",
      key: "Carga lista ",
      text: "Carga lista ",
    },
    {
      value: "Confirmada ",
      key: "Confirmada ",
      text: "Confirmada ",
    },
    {
      value: "Entrega",
      key: "Entrega",
      text: "Entrega",
    },
    {
      value: "Entrega parcial",
      key: "Entrega parcial",
      text: "Entrega parcial",
    },
    {
      value: "Parcialmente confirmada ",
      key: "Parcialmente confirmada ",
      text: "Parcialmente confirmada ",
    },
    {
      value: "Parcialmente lista ",
      key: "Parcialmente lista ",
      text: "Parcialmente lista ",
    },
    {
      value: "Parcialmente planeada ",
      key: "Parcialmente planeada ",
      text: "Parcialmente planeada ",
    },
    {
      value: "Parcialmente recolectada ",
      key: "Parcialmente recolectada ",
      text: "Parcialmente recolectada ",
    },
    {
      value: "Pendiente por confirmar ",
      key: "Pendiente por confirmar ",
      text: "Pendiente por confirmar ",
    },
    {
      value: "Planeada ",
      key: "Planeada ",
      text: "Planeada ",
    },
    {
      value: "Rechazada",
      key: "Rechazada",
      text: "Rechazada",
    },
    {
      value: "Recolectada ",
      key: "Recolectada ",
      text: "Recolectada ",
    },
  ],
  SupplierName: [
    {
      value: "a",
      key: "a",
      text: "a",
    },
    {
      value: "asdsa",
      key: "asdsa",
      text: "asdsa",
    },
    {
      value: "BENTELER AG",
      key: "BENTELER AG",
      text: "BENTELER AG",
    },
    {
      value: "CLIENTE ALWOOD",
      key: "CLIENTE ALWOOD",
      text: "CLIENTE ALWOOD",
    },
    {
      value: "GESA EOLICA MÉXICO",
      key: "GESA EOLICA MÉXICO",
      text: "GESA EOLICA MÉXICO",
    },
    {
      value: "GLASSTECH, INC",
      key: "GLASSTECH, INC",
      text: "GLASSTECH, INC",
    },
    {
      value: "ONDEO NALCO COMPANY",
      key: "ONDEO NALCO COMPANY",
      text: "ONDEO NALCO COMPANY",
    },
    {
      value: "PruebaSuppller1",
      key: "PruebaSuppller1",
      text: "PruebaSuppller1",
    },
    {
      value: "pryebas",
      key: "pryebas",
      text: "pryebas",
    },
    {
      value: "Shipper Name",
      key: "Shipper Name",
      text: "Shipper Name",
    },
    {
      value: "Shipper Test Name01",
      key: "Shipper Test Name01",
      text: "Shipper Test Name01",
    },
    {
      value: "Shipper Test Name02",
      key: "Shipper Test Name02",
      text: "Shipper Test Name02",
    },
    {
      value: "Test Shipper",
      key: "Test Shipper",
      text: "Test Shipper",
    },
    {
      value: "TEST SHIPPER",
      key: "TEST SHIPPER",
      text: "TEST SHIPPER",
    },
  ],
  ConsigneeName: [
    {
      value: "a",
      key: "a",
      text: "a",
    },
    {
      value: "Consignee Name",
      key: "Consignee Name",
      text: "Consignee Name",
    },
    {
      value: "Consignee Test Name01",
      key: "Consignee Test Name01",
      text: "Consignee Test Name01",
    },
    {
      value: "Consignee Test Name02",
      key: "Consignee Test Name02",
      text: "Consignee Test Name02",
    },
    {
      value: "CRISTALES INASTILLABLES DE MEXICO",
      key: "CRISTALES INASTILLABLES DE MEXICO",
      text: "CRISTALES INASTILLABLES DE MEXICO",
    },
    {
      value: "name11",
      key: "name11",
      text: "name11",
    },
    {
      value: "prubas",
      key: "prubas",
      text: "prubas",
    },
    {
      value: "prueb a 3",
      key: "prueb a 3",
      text: "prueb a 3",
    },
    {
      value: "prueba cons",
      key: "prueba cons",
      text: "prueba cons",
    },
    {
      value: "prueba cons 4",
      key: "prueba cons 4",
      text: "prueba cons 4",
    },
    {
      value: "pruebaConsegnnee",
      key: "pruebaConsegnnee",
      text: "pruebaConsegnnee",
    },
    {
      value: "prueba consi 2",
      key: "prueba consi 2",
      text: "prueba consi 2",
    },
    {
      value: "Test Consignee",
      key: "Test Consignee",
      text: "Test Consignee",
    },
    {
      value: "TEST CONSIGNEE",
      key: "TEST CONSIGNEE",
      text: "TEST CONSIGNEE",
    },
    {
      value: "Vitro Auto Planta VFX EO",
      key: "Vitro Auto Planta VFX EO",
      text: "Vitro Auto Planta VFX EO",
    },
    {
      value: null,
      key: null,
      text: null,
    },
  ],
  TransportMode: [
    {
      value: "Air",
      key: "Air",
      text: "Air",
    },
    {
      value: "Road",
      key: "Road",
      text: "Road",
    },
    {
      value: "Sea",
      key: "Sea",
      text: "Sea",
    },
    {
      value: null,
      key: null,
      text: null,
    },
  ],
  CustomerName: [
    {
      value: "CLIENTE DE PRUEBA",
      key: "CLIENTE DE PRUEBA",
      text: "CLIENTE DE PRUEBA",
    },
    {
      value: "PRUEBA",
      key: "PRUEBA",
      text: "PRUEBA",
    },
    {
      value: "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      key: "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      text: "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
    },
  ],
};

export const ChartsResponse = {
  totalInfo: [
    {
      Total: "85",
      Tipo: 4,
      CardName: "PO prioritarias",
    },
    {
      Total: "0",
      Tipo: 2,
      CardName: "PO-Item a tiempo por confirmar",
    },
    {
      Total: "263",
      Tipo: 1,
      CardName: "Total PO activas",
    },
    {
      Total: "484",
      Tipo: 3,
      CardName: "PO-Item con retraso por confirmar",
    },
  ],
  totalStatus: [
    {
      total: "125",
      estatus: "Pendiente por confirmar ",
    },
    {
      total: "19",
      estatus: "Confirmada ",
    },
    {
      total: "8",
      estatus: "Carga lista ",
    },
    {
      total: "14",
      estatus: "Planeada ",
    },
    {
      total: "34",
      estatus: "Recolectada ",
    },
    {
      total: "0",
      estatus: "Reservada",
    },
    {
      total: "0",
      estatus: "Salida",
    },
    {
      total: "0",
      estatus: "Llegada",
    },
    {
      total: "14",
      estatus: "Entrega",
    },
    {
      total: "1",
      estatus: "Cancelada ",
    },
    {
      total: "1",
      estatus: "Rechazada",
    },
  ],
  creationTendency: [
    {
      color: "#1f92fe",
      data: [
        ["Ene", "23"],
        ["Feb", "0"],
        ["Mar", "0"],
        ["Abr", "0"],
        ["May", "0"],
        ["Jun", "0"],
        ["Jul", "0"],
        ["Ago", "0"],
        ["Sep", "0"],
        ["Oct", "0"],
        ["Nov", "0"],
        ["Dic", "0"],
      ],
    },
  ],
};

export const poListResponse = [
  {
    POItemsId: 798,
    POId: 406,
    LineNo: "001",
    LineID: "1",
    StatusId: 48,
    Status: "Confirmado",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 16,
    QTYConfirmed: 16,
    UOMCode: "PZ",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "lbs",
    NetWeight: "29",
    NetWeightUOM: "lbs",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:21:33.229Z",
    UpdateDate: "2024-01-19T18:21:33.229Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "15-10-2023",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00023",
    PONumber: "PO-FADB-00045",
    StatusIdPo: 43,
    StatusPo: "Parcialmente recolectada ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: 16,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "47.21",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "45.30",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:21:33.229Z",
    UpdateDatePO: "2024-01-26T17:50:40.283Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Libras",
    ItemNetWeightUOMDescription: "Libras",
    ItemUOMDescription: "Piezas",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 798,
        POId: 406,
        LineNo: "001",
        LineID: "1",
        StatusId: 48,
        Status: "Confirmado",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 16,
        QTYConfirmed: 16,
        UOMCode: "PZ",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "lbs",
        NetWeight: "29",
        NetWeightUOM: "lbs",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:21:33.229Z",
        UpdateDate: "2024-01-19T18:21:33.229Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "15-10-2023",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00023",
        PONumber: "PO-FADB-00045",
        StatusIdPo: 43,
        StatusPo: "Parcialmente recolectada ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: 16,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "47.21",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "45.30",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:21:33.229Z",
        UpdateDatePO: "2024-01-26T17:50:40.283Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Libras",
        ItemNetWeightUOMDescription: "Libras",
        ItemUOMDescription: "Piezas",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 800,
        POId: 406,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 14,
        QTYConfirmed: null,
        UOMCode: "PZ",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "lbs",
        NetWeight: "29",
        NetWeightUOM: "lbs",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-26T23:50:40.182Z",
        UpdateDate: "2024-01-26T23:50:40.182Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00023",
        PONumber: "PO-FADB-00045",
        StatusIdPo: 43,
        StatusPo: "Parcialmente recolectada ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: 16,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "47.21",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "45.30",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:21:33.229Z",
        UpdateDatePO: "2024-01-26T17:50:40.283Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Libras",
        ItemNetWeightUOMDescription: "Libras",
        ItemUOMDescription: "Piezas",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 799,
        POId: 406,
        LineNo: "002",
        LineID: "2",
        StatusId: 54,
        Status: "Recolección/ recepción",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Bags",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "KG",
        NetWeight: "19",
        NetWeightUOM: "KG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:21:33.229Z",
        UpdateDate: "2024-01-19T18:21:33.229Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "25-01-2024",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: "2023-12-01T00:00:00.000Z",
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00023",
        PONumber: "PO-FADB-00045",
        StatusIdPo: 43,
        StatusPo: "Parcialmente recolectada ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: 16,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "47.21",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "45.30",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:21:33.229Z",
        UpdateDatePO: "2024-01-26T17:50:40.283Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Kilogramos",
        ItemUOMDescription: "Bolsas",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 796,
    POId: 405,
    LineNo: "001",
    LineID: "1",
    StatusId: 48,
    Status: "Confirmado",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: 30,
    UOMCode: "PZ",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "lbs",
    NetWeight: "29",
    NetWeightUOM: "lbs",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:20:43.228Z",
    UpdateDate: "2024-01-19T18:20:43.228Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "10-10-2023",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00022",
    PONumber: "PO-FADB-00044",
    StatusIdPo: 38,
    StatusPo: "Confirmada ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: 90,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "22.67",
    GrossWeightUOMPo: "lbs",
    NetWeightPO: "48.00",
    NetWeightUOMPo: "lbs",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:20:43.228Z",
    UpdateDatePO: "2024-01-26T18:29:00.087Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Libras",
    ItemNetWeightUOMDescription: "Libras",
    ItemUOMDescription: "Piezas",
    GrossWeightUOMDescriptionPO: "Libras",
    NetWeightUOMDescriptionPO: "Libras",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 796,
        POId: 405,
        LineNo: "001",
        LineID: "1",
        StatusId: 48,
        Status: "Confirmado",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: 30,
        UOMCode: "PZ",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "lbs",
        NetWeight: "29",
        NetWeightUOM: "lbs",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:20:43.228Z",
        UpdateDate: "2024-01-19T18:20:43.228Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "10-10-2023",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00022",
        PONumber: "PO-FADB-00044",
        StatusIdPo: 38,
        StatusPo: "Confirmada ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: 90,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "22.67",
        GrossWeightUOMPo: "lbs",
        NetWeightPO: "48.00",
        NetWeightUOMPo: "lbs",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:20:43.228Z",
        UpdateDatePO: "2024-01-26T18:29:00.087Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Libras",
        ItemNetWeightUOMDescription: "Libras",
        ItemUOMDescription: "Piezas",
        GrossWeightUOMDescriptionPO: "Libras",
        NetWeightUOMDescriptionPO: "Libras",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 797,
        POId: 405,
        LineNo: "002",
        LineID: "2",
        StatusId: 48,
        Status: "Confirmado",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: 60,
        UOMCode: "Bags",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "lbs",
        NetWeight: "19",
        NetWeightUOM: "lbs",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:20:43.228Z",
        UpdateDate: "2024-01-19T18:20:43.228Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "11-11-2023",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00022",
        PONumber: "PO-FADB-00044",
        StatusIdPo: 38,
        StatusPo: "Confirmada ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: 90,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "22.67",
        GrossWeightUOMPo: "lbs",
        NetWeightPO: "48.00",
        NetWeightUOMPo: "lbs",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:20:43.228Z",
        UpdateDatePO: "2024-01-26T18:29:00.087Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Libras",
        ItemNetWeightUOMDescription: "Libras",
        ItemUOMDescription: "Bolsas",
        GrossWeightUOMDescriptionPO: "Libras",
        NetWeightUOMDescriptionPO: "Libras",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 794,
    POId: 404,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: null,
    UOMCode: "PZ",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "GR",
    NetWeight: "29",
    NetWeightUOM: "MG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:14:18.241Z",
    UpdateDate: "2024-01-19T18:14:18.241Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00021",
    PONumber: "PO-FADB-00043",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "0.03",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "0.53",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:14:18.241Z",
    UpdateDatePO: "2024-01-19T06:14:18.241Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Gramos",
    ItemNetWeightUOMDescription: "Miligramos",
    ItemUOMDescription: "Piezas",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 794,
        POId: 404,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: null,
        UOMCode: "PZ",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "GR",
        NetWeight: "29",
        NetWeightUOM: "MG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:14:18.241Z",
        UpdateDate: "2024-01-19T18:14:18.241Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00021",
        PONumber: "PO-FADB-00043",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "0.03",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "0.53",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:14:18.241Z",
        UpdateDatePO: "2024-01-19T06:14:18.241Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Gramos",
        ItemNetWeightUOMDescription: "Miligramos",
        ItemUOMDescription: "Piezas",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 795,
        POId: 404,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Bags",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "MG",
        NetWeight: "19",
        NetWeightUOM: "OZ",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:14:18.241Z",
        UpdateDate: "2024-01-19T18:14:18.241Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00021",
        PONumber: "PO-FADB-00043",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "0.03",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "0.53",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:14:18.241Z",
        UpdateDatePO: "2024-01-19T06:14:18.241Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Miligramos",
        ItemNetWeightUOMDescription: "Onza",
        ItemUOMDescription: "Bolsas",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 786,
    POId: 401,
    LineNo: "001",
    LineID: "1",
    StatusId: 47,
    Status: "Cambio solicitado",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: 30,
    UOMCode: "Cont",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "GR",
    NetWeight: "29",
    NetWeightUOM: "MG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:12:33.224Z",
    UpdateDate: "2024-01-19T18:12:33.224Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "10-01-2024",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00020",
    PONumber: "PO-FADB-00041",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "0.03",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "0.53",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:12:33.224Z",
    UpdateDatePO: "2024-01-29T08:39:10.402Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Gramos",
    ItemNetWeightUOMDescription: "Miligramos",
    ItemUOMDescription: "Contenedores",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 786,
        POId: 401,
        LineNo: "001",
        LineID: "1",
        StatusId: 47,
        Status: "Cambio solicitado",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: 30,
        UOMCode: "Cont",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "GR",
        NetWeight: "29",
        NetWeightUOM: "MG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:12:33.224Z",
        UpdateDate: "2024-01-19T18:12:33.224Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "10-01-2024",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00020",
        PONumber: "PO-FADB-00041",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "0.03",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "0.53",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:12:33.224Z",
        UpdateDatePO: "2024-01-29T08:39:10.402Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Gramos",
        ItemNetWeightUOMDescription: "Miligramos",
        ItemUOMDescription: "Contenedores",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 787,
        POId: 401,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Rolls",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "MG",
        NetWeight: "19",
        NetWeightUOM: "OZ",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:12:33.224Z",
        UpdateDate: "2024-01-19T18:12:33.224Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "1712380100",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00020",
        PONumber: "PO-FADB-00041",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "0.03",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "0.53",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:12:33.224Z",
        UpdateDatePO: "2024-01-29T08:39:10.402Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Miligramos",
        ItemNetWeightUOMDescription: "Onza",
        ItemUOMDescription: "Rollos",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 784,
    POId: 400,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: null,
    UOMCode: "Packa",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "GR",
    NetWeight: "29",
    NetWeightUOM: "MG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:10:41.828Z",
    UpdateDate: "2024-01-19T18:10:41.828Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00019",
    PONumber: "PO-FADB-00040",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "0.03",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "0.53",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:10:41.828Z",
    UpdateDatePO: "2024-01-19T06:10:41.828Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Gramos",
    ItemNetWeightUOMDescription: "Miligramos",
    ItemUOMDescription: "Paquetes",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 784,
        POId: 400,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: null,
        UOMCode: "Packa",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "GR",
        NetWeight: "29",
        NetWeightUOM: "MG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:10:41.828Z",
        UpdateDate: "2024-01-19T18:10:41.828Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00019",
        PONumber: "PO-FADB-00040",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "0.03",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "0.53",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:10:41.828Z",
        UpdateDatePO: "2024-01-19T06:10:41.828Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Gramos",
        ItemNetWeightUOMDescription: "Miligramos",
        ItemUOMDescription: "Paquetes",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 785,
        POId: 400,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Cylnd",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "MG",
        NetWeight: "19",
        NetWeightUOM: "OZ",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:10:41.828Z",
        UpdateDate: "2024-01-19T18:10:41.828Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00019",
        PONumber: "PO-FADB-00040",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "0.03",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "0.53",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:10:41.828Z",
        UpdateDatePO: "2024-01-19T06:10:41.828Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Miligramos",
        ItemNetWeightUOMDescription: "Onza",
        ItemUOMDescription: "Cilindros",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 782,
    POId: 399,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: null,
    UOMCode: "PCK",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "GR",
    NetWeight: "29",
    NetWeightUOM: "MG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:08:22.527Z",
    UpdateDate: "2024-01-19T18:08:22.527Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00018",
    PONumber: "PO-FADB-00039",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "9.10",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "8.61",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:08:22.527Z",
    UpdateDatePO: "2024-01-29T11:11:24.755Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Gramos",
    ItemNetWeightUOMDescription: "Miligramos",
    ItemUOMDescription: "Empaques",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 782,
        POId: 399,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: null,
        UOMCode: "PCK",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "GR",
        NetWeight: "29",
        NetWeightUOM: "MG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:08:22.527Z",
        UpdateDate: "2024-01-19T18:08:22.527Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00018",
        PONumber: "PO-FADB-00039",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "9.10",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "8.61",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:08:22.527Z",
        UpdateDatePO: "2024-01-29T11:11:24.755Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Gramos",
        ItemNetWeightUOMDescription: "Miligramos",
        ItemUOMDescription: "Empaques",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 783,
        POId: 399,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: 60,
        UOMCode: "PZ",
        CaseVolume: "1620000",
        CaseDescription: "Juguetes de plastico",
        VendorCode: "VENDO34949059",
        Value: "32.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "lbs",
        NetWeight: "19",
        NetWeightUOM: "lbs",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: "30x30x30",
        CreationDate: "2024-01-19T18:08:22.527Z",
        UpdateDate: "2024-01-19T18:08:22.527Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "2012230101",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00018",
        PONumber: "PO-FADB-00039",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "9.10",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "8.61",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:08:22.527Z",
        UpdateDatePO: "2024-01-29T11:11:24.755Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: "FAC-3547-075",
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Libras",
        ItemNetWeightUOMDescription: "Libras",
        ItemUOMDescription: "Piezas",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 780,
    POId: 398,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: null,
    UOMCode: "PCK",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "KG",
    NetWeight: "29",
    NetWeightUOM: "KG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:06:01.247Z",
    UpdateDate: "2024-01-19T18:06:01.247Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00017",
    PONumber: "PO-FADB-00038",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852q23",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: "DAT",
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: 2,
    TransportMode: "Air",
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "39.07",
    GrossWeightUOMPo: "Kg",
    NetWeightPO: "19029.00",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:06:01.247Z",
    UpdateDatePO: "2024-01-19T18:04:32.543Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Kilogramos",
    ItemNetWeightUOMDescription: "Kilogramos",
    ItemUOMDescription: "Empaques",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 780,
        POId: 398,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: null,
        UOMCode: "PCK",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "KG",
        NetWeight: "29",
        NetWeightUOM: "KG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:06:01.247Z",
        UpdateDate: "2024-01-19T18:06:01.247Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00017",
        PONumber: "PO-FADB-00038",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852q23",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: "DAT",
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: 2,
        TransportMode: "Air",
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "39.07",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "19029.00",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:06:01.247Z",
        UpdateDatePO: "2024-01-19T18:04:32.543Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Kilogramos",
        ItemUOMDescription: "Empaques",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 781,
        POId: 398,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Truck",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "lbs",
        NetWeight: "19",
        NetWeightUOM: "TON",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:06:01.247Z",
        UpdateDate: "2024-01-19T18:06:01.247Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00017",
        PONumber: "PO-FADB-00038",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852q23",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: "DAT",
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: 2,
        TransportMode: "Air",
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "39.07",
        GrossWeightUOMPo: "Kg",
        NetWeightPO: "19029.00",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:06:01.247Z",
        UpdateDatePO: "2024-01-19T18:04:32.543Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Libras",
        ItemNetWeightUOMDescription: "Toneladas",
        ItemUOMDescription: "Camiones",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 778,
    POId: 397,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 30,
    QTYConfirmed: null,
    UOMCode: "Brrls",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "KG",
    NetWeight: "29",
    NetWeightUOM: "KG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T18:00:39.027Z",
    UpdateDate: "2024-01-19T18:00:39.027Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00016",
    PONumber: "PO-FADB-00037",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 73,
    SupplierCode: "SUP0039",
    SupplierName: "TEST SHIPPER",
    SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
    CustomerReference: "6852",
    ConsigneeId: 74,
    ConsigneeCode: "CON003",
    ConsigneeName: "TEST CONSIGNEE",
    ConsigneeAddress: "123 Street",
    DeliveryId: 58,
    DeliveryCode: "DEL0120",
    DeliveryName: "Delivery Name",
    DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
    Incoterms: "CPT",
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: 2,
    TransportMode: "Air",
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 90,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "50.00",
    GrossWeightUOMPo: "KG",
    NetWeightPO: "48.00",
    NetWeightUOMPo: "KG",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T12:00:39.027Z",
    UpdateDatePO: "2024-01-19T17:57:30.536Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "TEST SHIPPER",
    SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
    ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
    ConsigneeAddressEuro: "123 Main St, CP: 64820",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Supplier Contact Name ",
    ConsigneeContact: "Consignee Contact Name ",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 513,
    EuroConsigneeId: 564,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Kilogramos",
    ItemNetWeightUOMDescription: "Kilogramos",
    ItemUOMDescription: "Barriles",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 778,
        POId: 397,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 30,
        QTYConfirmed: null,
        UOMCode: "Brrls",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "KG",
        NetWeight: "29",
        NetWeightUOM: "KG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:00:39.027Z",
        UpdateDate: "2024-01-19T18:00:39.027Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00016",
        PONumber: "PO-FADB-00037",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: "CPT",
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: 2,
        TransportMode: "Air",
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "50.00",
        GrossWeightUOMPo: "KG",
        NetWeightPO: "48.00",
        NetWeightUOMPo: "KG",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:00:39.027Z",
        UpdateDatePO: "2024-01-19T17:57:30.536Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Kilogramos",
        ItemUOMDescription: "Barriles",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 779,
        POId: 397,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Spool",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "KG",
        NetWeight: "19",
        NetWeightUOM: "KG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T18:00:39.027Z",
        UpdateDate: "2024-01-19T18:00:39.027Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00016",
        PONumber: "PO-FADB-00037",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 73,
        SupplierCode: "SUP0039",
        SupplierName: "TEST SHIPPER",
        SupplierAddress: "1216 Bob Bullock Loop, CP: 78044",
        CustomerReference: "6852",
        ConsigneeId: 74,
        ConsigneeCode: "CON003",
        ConsigneeName: "TEST CONSIGNEE",
        ConsigneeAddress: "123 Street",
        DeliveryId: 58,
        DeliveryCode: "DEL0120",
        DeliveryName: "Delivery Name",
        DeliveryAddress: "AV. 18 DE MARZO No. 704COL. LA NGALERA",
        Incoterms: "CPT",
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: 2,
        TransportMode: "Air",
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 90,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "50.00",
        GrossWeightUOMPo: "KG",
        NetWeightPO: "48.00",
        NetWeightUOMPo: "KG",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T12:00:39.027Z",
        UpdateDatePO: "2024-01-19T17:57:30.536Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "TEST SHIPPER",
        SupplierAddressEuro: "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
        ConsigneeNameEuro: "MONTERREY CONSIGNEE TEST",
        ConsigneeAddressEuro: "123 Main St, CP: 64820",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Supplier Contact Name ",
        ConsigneeContact: "Consignee Contact Name ",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 513,
        EuroConsigneeId: 564,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Kilogramos",
        ItemUOMDescription: "Carretes",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 746,
    POId: 391,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 50,
    QTYConfirmed: null,
    UOMCode: "Spool",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "KG",
    NetWeight: "29",
    NetWeightUOM: "KG",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T17:49:17.435Z",
    UpdateDate: "2024-01-19T17:49:17.435Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "0",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00015",
    PONumber: "PO-2023-00258",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 50,
    SupplierCode: "SUP0001",
    SupplierName: "Test Shipper",
    SupplierAddress: "Calle 1234",
    CustomerReference: "6852",
    ConsigneeId: 51,
    ConsigneeCode: "CON001",
    ConsigneeName: "Test Consignee",
    ConsigneeAddress: "Calle 5874",
    DeliveryId: 75,
    DeliveryCode: "DEL0001",
    DeliveryName: "TEST CONSIGNEE",
    DeliveryAddress: "calle viva esperanza 234 chile, providencia chile",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 110,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "50.00",
    GrossWeightUOMPo: "KG",
    NetWeightPO: "48.00",
    NetWeightUOMPo: "KG",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T11:49:17.435Z",
    UpdateDatePO: "2024-01-19T05:49:17.435Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "MONTERREY CONSIGNEE TEST",
    SupplierAddressEuro: "123 Main St, CP: 64820",
    ConsigneeNameEuro: "CONSIGNEE PRUEBA",
    ConsigneeAddressEuro: "calle viva esperanza 234, CP: ",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Francis Delgado",
    ConsigneeContact: "Consignee Contact Name",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 564,
    EuroConsigneeId: 563,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Kilogramos",
    ItemNetWeightUOMDescription: "Kilogramos",
    ItemUOMDescription: "Carretes",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 746,
        POId: 391,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 50,
        QTYConfirmed: null,
        UOMCode: "Spool",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "KG",
        NetWeight: "29",
        NetWeightUOM: "KG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T17:49:17.435Z",
        UpdateDate: "2024-01-19T17:49:17.435Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00015",
        PONumber: "PO-2023-00258",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 50,
        SupplierCode: "SUP0001",
        SupplierName: "Test Shipper",
        SupplierAddress: "Calle 1234",
        CustomerReference: "6852",
        ConsigneeId: 51,
        ConsigneeCode: "CON001",
        ConsigneeName: "Test Consignee",
        ConsigneeAddress: "Calle 5874",
        DeliveryId: 75,
        DeliveryCode: "DEL0001",
        DeliveryName: "TEST CONSIGNEE",
        DeliveryAddress: "calle viva esperanza 234 chile, providencia chile",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 110,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "50.00",
        GrossWeightUOMPo: "KG",
        NetWeightPO: "48.00",
        NetWeightUOMPo: "KG",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T11:49:17.435Z",
        UpdateDatePO: "2024-01-19T05:49:17.435Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "MONTERREY CONSIGNEE TEST",
        SupplierAddressEuro: "123 Main St, CP: 64820",
        ConsigneeNameEuro: "CONSIGNEE PRUEBA",
        ConsigneeAddressEuro: "calle viva esperanza 234, CP: ",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Francis Delgado",
        ConsigneeContact: "Consignee Contact Name",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 564,
        EuroConsigneeId: 563,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Kilogramos",
        ItemUOMDescription: "Carretes",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 747,
        POId: 391,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Brrls",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "KG",
        NetWeight: "19",
        NetWeightUOM: "KG",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T17:49:17.435Z",
        UpdateDate: "2024-01-19T17:49:17.435Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00015",
        PONumber: "PO-2023-00258",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 50,
        SupplierCode: "SUP0001",
        SupplierName: "Test Shipper",
        SupplierAddress: "Calle 1234",
        CustomerReference: "6852",
        ConsigneeId: 51,
        ConsigneeCode: "CON001",
        ConsigneeName: "Test Consignee",
        ConsigneeAddress: "Calle 5874",
        DeliveryId: 75,
        DeliveryCode: "DEL0001",
        DeliveryName: "TEST CONSIGNEE",
        DeliveryAddress: "calle viva esperanza 234 chile, providencia chile",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 110,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "50.00",
        GrossWeightUOMPo: "KG",
        NetWeightPO: "48.00",
        NetWeightUOMPo: "KG",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T11:49:17.435Z",
        UpdateDatePO: "2024-01-19T05:49:17.435Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "MONTERREY CONSIGNEE TEST",
        SupplierAddressEuro: "123 Main St, CP: 64820",
        ConsigneeNameEuro: "CONSIGNEE PRUEBA",
        ConsigneeAddressEuro: "calle viva esperanza 234, CP: ",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Francis Delgado",
        ConsigneeContact: "Consignee Contact Name",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 564,
        EuroConsigneeId: 563,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Kilogramos",
        ItemUOMDescription: "Barriles",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
  {
    POItemsId: 744,
    POId: 390,
    LineNo: "001",
    LineID: "1",
    StatusId: 46,
    Status: "Pendiente por confirmar",
    ItemNo: "ITEM2536",
    Description: "Juguetes de plastico",
    QTYOrdered: 50,
    QTYConfirmed: null,
    UOMCode: "lbs",
    CaseVolume: "32.03",
    CaseDescription: null,
    VendorCode: "VENDORCOD",
    Value: "29.17",
    Currency: "USD",
    GrossWeight: "30",
    GrossWeightUOM: "KG",
    NetWeight: "29",
    NetWeightUOM: "GR",
    Collection: "Primera",
    Style: null,
    Color: null,
    Size: null,
    CreationDate: "2024-01-19T17:23:39.528Z",
    UpdateDate: "2024-01-19T17:23:39.528Z",
    RequiredCagoReady: "15-10-2023",
    PromiseCargoReady: "",
    ActualCargoReady: "",
    EstimatedPickedUp: null,
    ActualCargoPickup: null,
    RequestedExWorks: "2023-10-15T00:00:00.000Z",
    ActualArrivalConsolWH: null,
    FinalDestination: null,
    PriorityItem: "1",
    HSCode: "HS2583",
    DateDeparturePortAirport: null,
    POInternalNumber: "PO-2024-00014",
    PONumber: "PO-2023-00257",
    StatusIdPo: 36,
    StatusPo: "Pendiente por confirmar ",
    SupplierId: 50,
    SupplierCode: "SUP0001",
    SupplierName: "Test Shipper",
    SupplierAddress: "Calle 1234",
    CustomerReference: "6852",
    ConsigneeId: 51,
    ConsigneeCode: "CON001",
    ConsigneeName: "Test Consignee",
    ConsigneeAddress: "Calle 5874",
    DeliveryId: 75,
    DeliveryCode: "DEL0001",
    DeliveryName: "TEST CONSIGNEE",
    DeliveryAddress: "calle viva esperanza 234 chile, providencia chile",
    Incoterms: null,
    ProductType: "Juguete",
    Division: "Consumo",
    TransportModeId: null,
    TransportMode: null,
    POL: "Mexico",
    POD: null,
    Project: null,
    WMSReference: null,
    Buyer: "Roberto Martinez",
    TotalQTYOrdered: 110,
    TotalQTYConfirmed: null,
    TotalQTYUOM: "Unit",
    OrderValue: "60.52",
    OrderCurrency: "USD",
    GrossWeightPO: "50.00",
    GrossWeightUOMPo: "KG",
    NetWeightPO: "0.56",
    NetWeightUOMPo: "Kg",
    NoPalletsPO: null,
    PalletsType: null,
    DescriptionPO: null,
    SpecialInstructions: null,
    AdditionalTerms: null,
    ContainerReference: null,
    PickupAddress:
      "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
    CreateDatePO: "2024-01-19T11:23:39.528Z",
    UpdateDatePO: "2024-01-19T11:24:40.635Z",
    RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
    RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
    PriorityPO: "0",
    CustomerId: 134,
    CustomerName: "CLIENTE DE PRUEBA",
    SupplierNameEuro: "MONTERREY CONSIGNEE TEST",
    SupplierAddressEuro: "123 Main St, CP: 64820",
    ConsigneeNameEuro: "CONSIGNEE PRUEBA",
    ConsigneeAddressEuro: "calle viva esperanza 234, CP: ",
    DeliveryNameEuro: null,
    DeliveryAddressEuro: null,
    SupplierContact: "Francis Delgado",
    ConsigneeContact: "Consignee Contact Name",
    DeliveryContact: "Delivery Contact Name",
    RequiresAttention: null,
    Invoice: null,
    PORelationshipId: null,
    TransportPlanId: null,
    EuroSupplerId: 564,
    EuroConsigneeId: 563,
    ShipmentId: null,
    POCreationDate: "2023-10-21T00:00:00.000Z",
    ShipmentNumber: null,
    ItemGrossWeightUOMDescription: "Kilogramos",
    ItemNetWeightUOMDescription: "Gramos",
    ItemUOMDescription: "Libras",
    GrossWeightUOMDescriptionPO: "Kilogramos",
    NetWeightUOMDescriptionPO: "Kilogramos",
    TotalQTYUOMDescriptionPO: "Unidades",
    total: "509",
    Total: "261",
    createdate: "19-01-2024",
    requireddestinationdate: "15-10-2023",
    sub: [
      {
        POItemsId: 744,
        POId: 390,
        LineNo: "001",
        LineID: "1",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2536",
        Description: "Juguetes de plastico",
        QTYOrdered: 50,
        QTYConfirmed: null,
        UOMCode: "lbs",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "30",
        GrossWeightUOM: "KG",
        NetWeight: "29",
        NetWeightUOM: "GR",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T17:23:39.528Z",
        UpdateDate: "2024-01-19T17:23:39.528Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "1",
        HSCode: "HS2583",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00014",
        PONumber: "PO-2023-00257",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 50,
        SupplierCode: "SUP0001",
        SupplierName: "Test Shipper",
        SupplierAddress: "Calle 1234",
        CustomerReference: "6852",
        ConsigneeId: 51,
        ConsigneeCode: "CON001",
        ConsigneeName: "Test Consignee",
        ConsigneeAddress: "Calle 5874",
        DeliveryId: 75,
        DeliveryCode: "DEL0001",
        DeliveryName: "TEST CONSIGNEE",
        DeliveryAddress: "calle viva esperanza 234 chile, providencia chile",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 110,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "50.00",
        GrossWeightUOMPo: "KG",
        NetWeightPO: "0.56",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T11:23:39.528Z",
        UpdateDatePO: "2024-01-19T11:24:40.635Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "MONTERREY CONSIGNEE TEST",
        SupplierAddressEuro: "123 Main St, CP: 64820",
        ConsigneeNameEuro: "CONSIGNEE PRUEBA",
        ConsigneeAddressEuro: "calle viva esperanza 234, CP: ",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Francis Delgado",
        ConsigneeContact: "Consignee Contact Name",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 564,
        EuroConsigneeId: 563,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Gramos",
        ItemUOMDescription: "Libras",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
      {
        POItemsId: 745,
        POId: 390,
        LineNo: "002",
        LineID: "2",
        StatusId: 46,
        Status: "Pendiente por confirmar",
        ItemNo: "ITEM2538",
        Description: "Juguetes de plastico",
        QTYOrdered: 60,
        QTYConfirmed: null,
        UOMCode: "Cont",
        CaseVolume: "32.03",
        CaseDescription: null,
        VendorCode: "VENDORCOD",
        Value: "29.17",
        Currency: "USD",
        GrossWeight: "20",
        GrossWeightUOM: "KG",
        NetWeight: "19",
        NetWeightUOM: "OZ",
        Collection: "Primera",
        Style: null,
        Color: null,
        Size: null,
        CreationDate: "2024-01-19T17:23:39.528Z",
        UpdateDate: "2024-01-19T17:23:39.528Z",
        RequiredCagoReady: "15-10-2023",
        PromiseCargoReady: "",
        ActualCargoReady: "",
        EstimatedPickedUp: null,
        ActualCargoPickup: null,
        RequestedExWorks: "2023-10-15T00:00:00.000Z",
        ActualArrivalConsolWH: null,
        FinalDestination: null,
        PriorityItem: "0",
        HSCode: "HS2584",
        DateDeparturePortAirport: null,
        POInternalNumber: "PO-2024-00014",
        PONumber: "PO-2023-00257",
        StatusIdPo: 36,
        StatusPo: "Pendiente por confirmar ",
        SupplierId: 50,
        SupplierCode: "SUP0001",
        SupplierName: "Test Shipper",
        SupplierAddress: "Calle 1234",
        CustomerReference: "6852",
        ConsigneeId: 51,
        ConsigneeCode: "CON001",
        ConsigneeName: "Test Consignee",
        ConsigneeAddress: "Calle 5874",
        DeliveryId: 75,
        DeliveryCode: "DEL0001",
        DeliveryName: "TEST CONSIGNEE",
        DeliveryAddress: "calle viva esperanza 234 chile, providencia chile",
        Incoterms: null,
        ProductType: "Juguete",
        Division: "Consumo",
        TransportModeId: null,
        TransportMode: null,
        POL: "Mexico",
        POD: null,
        Project: null,
        WMSReference: null,
        Buyer: "Roberto Martinez",
        TotalQTYOrdered: 110,
        TotalQTYConfirmed: null,
        TotalQTYUOM: "Unit",
        OrderValue: "60.52",
        OrderCurrency: "USD",
        GrossWeightPO: "50.00",
        GrossWeightUOMPo: "KG",
        NetWeightPO: "0.56",
        NetWeightUOMPo: "Kg",
        NoPalletsPO: null,
        PalletsType: null,
        DescriptionPO: null,
        SpecialInstructions: null,
        AdditionalTerms: null,
        ContainerReference: null,
        PickupAddress:
          "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
        CreateDatePO: "2024-01-19T11:23:39.528Z",
        UpdateDatePO: "2024-01-19T11:24:40.635Z",
        RequiredCargoReadyDate: "2023-10-15T00:00:00.000Z",
        RequiredDestinationDate: "2023-10-15T00:00:00.000Z",
        PriorityPO: "0",
        CustomerId: 134,
        CustomerName: "CLIENTE DE PRUEBA",
        SupplierNameEuro: "MONTERREY CONSIGNEE TEST",
        SupplierAddressEuro: "123 Main St, CP: 64820",
        ConsigneeNameEuro: "CONSIGNEE PRUEBA",
        ConsigneeAddressEuro: "calle viva esperanza 234, CP: ",
        DeliveryNameEuro: null,
        DeliveryAddressEuro: null,
        SupplierContact: "Francis Delgado",
        ConsigneeContact: "Consignee Contact Name",
        DeliveryContact: "Delivery Contact Name",
        RequiresAttention: null,
        Invoice: null,
        PORelationshipId: null,
        TransportPlanId: null,
        EuroSupplerId: 564,
        EuroConsigneeId: 563,
        ShipmentId: null,
        POCreationDate: "2023-10-21T00:00:00.000Z",
        ShipmentNumber: null,
        ItemGrossWeightUOMDescription: "Kilogramos",
        ItemNetWeightUOMDescription: "Onza",
        ItemUOMDescription: "Contenedores",
        GrossWeightUOMDescriptionPO: "Kilogramos",
        NetWeightUOMDescriptionPO: "Kilogramos",
        TotalQTYUOMDescriptionPO: "Unidades",
        total: "509",
        createdate: "19-01-2024",
        requireddestinationdate: "15-10-2023",
      },
    ],
  },
];
