const Forms = [

    {
        type: 'Select',
        options: 'COMMENTS',
        label: 'Module',
        class: 'col-md-6',
        name: 'ModuleId',
        id: 'ModuleId',
        datatype : 'select',
        show : false

    },

    {
        type: 'Select',
        options: 'COMMENT-TYPES',
        label: 'Modulo:',
        class: 'col-md-6',
        name: 'ComTypeId',
        id: 'ComTypeId',
        datatype : 'select',
        show : true

    },


    {
        type: 'Datetime',
        label: 'Fecha:',
        placeHolder: '',
        class: 'col-md-6',
        name: 'Date',
        id: 'Date',
        required : true,
        show : true

    },

    {
        type: 'textarea',
        label: 'Comentario:',
        class: 'col-md-12',
        placeHolder: '',
        name: 'Comment',
        id: 'Comment',
        style : {},
        required : true,
        show : true
    },
]

export default Forms