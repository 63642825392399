import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import axios from 'axios';
import { Services } from '../../../Services';
import { withRouter } from 'react-router-dom';
import dummy from '../../../dummy'

class Documents extends Component {
  state = {
    id: this.props.match.params.id,
    url: '',
  };

  componentDidMount = () => {
    this.getUrl();
  };

  getUrl = () => {
    // axios
    //   .get(
    //     `${Services.Get_Doc_Export_ForId.path}/document/documentHash/${this.state.id}`
    //   )
    //   .then((success) => {
        // axios.get(`${'http://localhost:3208/'}document/documentHash/${this.state.id}`).then(success => {
          let success= dummy.documentosImportacion
        this.setState({
          url: success.data.url,
        });
      // })
      // .catch((error) => {
      //   console.warn(error);
      // });
  };

  render() {
    return (
      <Card className="myCard">
        <CardBody>
          <div className="row">
            {this.state.url ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: 18, margin: 20 }}
                href={this.state.url}
              >
                Ver documentos
              </a>
            ) : (
              <span style={{ fontSize: 18, margin: 20 }}>Cargando...</span>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(Documents);
