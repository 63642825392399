import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Col, Button } from 'reactstrap';
import propTypes from 'prop-types';
// import { Button } from 'antd';
// import './style.scss';

/**
 * Download Component
 * @param {*} props 
 */

;
function ModalContent(props) {
	// console.log('props modal create ticket',props);
	const [openModal, setopenModal] = React.useState(false);

	function toggle() {
		setopenModal(!openModal);
	}

	return (
		<React.Fragment>
			<Col xs={12} style={{ textAlign: 'right', }}>
				{/* <Button
					onClick={toggle}
					icon={<UploadOutlined />}>Click to Upload</Button> */}
				<div onClick={toggle} style={{ cursor: 'pointer' }}>
					<i className='fas fa-paperclip' style={{ color: '#00568aed' }} /> &nbsp;<spam style={{ color: '#00568aed' }}>{props.labels.button}</spam>
				</div>
			</Col>
			<Modal isOpen={openModal} scrollable={true} >
				{/* <modalContent /> */}
				<ModalHeader>{props.labels.title}</ModalHeader>
				<ModalBody>
					<div className='row'>
						{props.children}
					</div>
				</ModalBody>
				<ModalFooter style={{ justifyContent: 'center' }}>
					<Button color={props.colorsButtons.UploadDoc} disabled={!props.uploadDocumentButton} onClick={() => { props.uploadDocument(); setopenModal(false) }}>{props.labels.btn_upload}</Button>{' '}
					<Button color={props.colorsButtons.CancelDoc} onClick={() => { setopenModal(false) }}>{props.labels.btn_cancel}</Button>
				</ModalFooter>
			</Modal>
		</React.Fragment>
	);
};

ModalContent.propTypes = {
	title: propTypes.string,
	labels: propTypes.object,
	onCancel: propTypes.func
};

ModalContent.defaultProps = {
	title: 'Modal title',
	onCancel: () => { },
	labels: {
		title: '',
		icon: '',
		btn_create: '',
		btn_cancel: ''
	}
};

export default ModalContent;