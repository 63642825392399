import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Card, CardBody, Col} from 'reactstrap';
import '../style.scss';
import TablesEvent from '../../../../../../template/TablesEvent';
import StepperEvent from '../../../../../../template/StepperEvent';

class ContainerEventsDetail extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      container: this.props.container,
      steps: [...props.steps],
      allSteps: props.allSteps,
      allTables: [],
      columns: [
        { label: 'Event Description', key: 'EventDescription', idTh: 'firstTh', idTd: 'firstTd' },
        { label: 'Event Date', key: 'EventDate'},
        { label: 'Category Responsible', key: 'CategoryResponsable' },
        { label: 'Name', key: 'Name' },
        { label: 'Update Date', key: 'UpdateDate' }
      ]
    };
  }

  componentDidMount = () => {
    this.createTables();
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState(nextProps);
    this.createTables();
  }

  goBack = (valid) =>{
    if(valid){
      this.props.history.goBack();
    }
  }

  createTables = () => {
    var allTables = [];
    this.state.allSteps.map((e) => {
      var findTable = false;
      allTables.map((f) => {
        if(f.eventType == e.EventType){
          f.data.push(e);
          findTable = true;
        }
      });
      if(!findTable){
        var obj = {
          eventType: e.EventType,
          data: [e]
        };
        allTables.push(obj);
      }
    });
    this.setState({allTables: allTables});
  }
  render() {
    const { steps, allTables, columns } = this.state;
    steps.sort(function(a, b){return b.order-a.order;});
    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Card className="myCard myWrapped">
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <legend>{'Container #: ' + this.state.container.ContainerNumber}</legend>
                    </div>
                    <Col md={12}>
                      <StepperEvent steps={steps} />
                    </Col>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12">
              <Card className="myCard">
                <CardBody>
                  <div className="col-12">
                    <legend>Container Events</legend>
                  </div>
                  { allTables.length > 0 ? <TablesEvent columns={columns} allTables={allTables}/> : ''}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContainerEventsDetail);
