import React from 'react';
// import Card from './Card';
// import TableTikets from './Table/Table';
import { Link } from "react-router-dom";
// import { Button } from 'semantic-ui-react';
import Table from '../Components/Table/Table';
import { Badge } from "reactstrap";

const ColorEstatus = {
  1: '#2ca8ff',
  2: '#2ca8ff',
  3: '#2ca8ff',
  4: '#2ca8ff',
  5: '#2ca8ff',
  6: '#0561fccc',
  7: '#0060a8'
}

const ItemsToFilter = ['TicketNumber', 'UserProject', 'Subjet', 'Status', 'TypeTicket', 'UpdateDate', 'CreateDate'];

// const columns = [
//   { id: 'TicketNo', label: 'Ticket No', minWidth: 100, align: 'center', format: (value) => <Link to={"/dashboard/" + value} > {value}</ Link> },
//   { id: 'CreatedBy', label: 'Created By', minWidth: 100, align: 'center' },
//   { id: 'Subjet', label: 'Subjet', minWidth: 100, align: 'center' },
//   { id: 'Status', label: 'Status', minWidth: 100, align: 'center', format: (value) => <Button color='blue' fluid>{value}</Button> },
//   { id: 'Type', label: 'Type', minWidth: 100, align: 'center' },
//   { id: 'CreationDate', label: 'Creation Date', minWidth: 100, align: 'center' },
//   { id: 'CloseDate', label: 'Close Date', minWidth: 100, align: 'center' },
// ];


const HeadersEn = {
  keys: ['TicketNumber', 'UserProject', 'Subjet', 'Status', 'TypeTicket', 'createdate', 'updatedate'],
  headers: ['Ticket No', 'Created By', 'Subject', 'Status', 'Type', 'Creation Date', 'Last Update'],
}
const HeadersEs = {
  keys: ['TicketNumber', 'UserProject', 'Subjet', 'Status', 'TypeTicket', 'createdate', 'updatedate'],
  headers: ['Número de Ticket', 'Creado Por', 'Título', 'Estatus', 'Tipo', 'Fecha de Creación', 'Fecha de Actualización'],
}

const nameProject = {
  1: 'poseidon',
  2: 'roldan',
  3: 'sales',
  4: 'rrmagicvision',
  5: 'magicdome',
  6: 'euro',
  7: 'obl'
}



const dataTikets = [
  { TicketNumber: '20-0000001', CreatedBy: 'Omar Guillen', Subjet: 'Detalle en el Dashboard', Status: 'Open', Type: 'Error', CreationDate: '26-08-2020', LastUpdate: '26-08-2020' }
];

function OpenTickets({ tickets, language, idProject, styleProject }) {
  const columnsEnglish = [
    { key: 'TicketNumber', label: 'Ticket No', custom: (value) => <Link to={'/Detail/' + value.TicketId} className={styleProject ? (styleProject.links ? styleProject.links : '') : ''}> {value.TicketNumber}</Link > },
    { key: 'UserProject', label: 'Created By' },
    { key: 'Subjet', label: 'Subject' },
    { key: 'Status', label: 'Status', custom: (value) => <Badge style={{ width: '100%', background: ColorEstatus[idProject], padding: '0.3em' }} pill>{value.Status}</Badge> },
    { key: 'TypeTicket', label: 'Type' },
    { key: 'createdate', label: 'Creation Date' },
    { key: 'updatedate', label: 'Last Update' },
  ];

  const columnsEspanish = [
    { key: 'TicketNumber', label: 'Número de Ticket', custom: (value) => <Link to={'/Detail/' + value.TicketId} className={styleProject ? (styleProject.links ? styleProject.links : '') : ''}>{value.TicketNumber}</Link> },
    { key: 'UserProject', label: 'Creado Por' },
    { key: 'Subjet', label: 'Título' },
    { key: 'Status', label: 'Estatus', custom: (value) => <Badge style={{ width: '100%', background: ColorEstatus[idProject], padding: '0.3em' }} pill>{value.Status}</Badge> },
    { key: 'TypeTicket', label: 'Tipo' },
    { key: 'createdate', label: 'Fecha de Creación' },
    { key: 'updatedate', label: 'Fecha de Actualización' },
  ];



  // console.log('tickets-->', tickets);
  return (
    <>
      {/* <TableTikets columns={columns} dataTikets={dataTikets} /> */}
      <Table
        columns={language === 'Español' ? columnsEspanish : columnsEnglish}
        headers={language === 'Español' ? HeadersEs : HeadersEn}
        nameProject={nameProject[idProject]}
        data={tickets ? tickets : dataTikets}
        configSearch={{ fieldsToFilter: ItemsToFilter }}
        itemsPerPage={10}
        class={styleProject}
        options={
          {
            pagination: true,
            search: true,
            selectItems: false,
            xlsx: true,
            project: idProject
          }
        }
      />
    </>
  );
}

export default OpenTickets;