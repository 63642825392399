const dummy = {
  //data mapa
  ALMACENLOCATION: {
    message: "ALMACEN LOCATION",
    status: 200,
    data: [
      {
        AlmacenId: 20,
        TipoAlmacenId: 3,
        TipoAlmacen: "Depósito Habilitado",
        Almacen: "ALMACEN DEP PUBLICO",
        Codigo: "DPUB",
        Ubicacion: "Cra. 100 No. 25B – 40 Fontibón – Bogotá",
        Longitud: "-74.1312557",
        Latitud: "4.6840781",
        TotalIngresos: "106",
        TotalDespachos: "103",
        TotalInventario: "0",
      },
      {
        AlmacenId: 30,
        TipoAlmacenId: 1,
        TipoAlmacen: "Bodega Nacional",
        Almacen: " BODEGA NACIONAL",
        Codigo: "BNAL",
        Ubicacion: "Cra. 100 No. 25B – 40 Fontibón – Bogotá",
        Longitud: "-74.1323283",
        Latitud: "4.6842406",
        TotalIngresos: "16",
        TotalDespachos: "9",
        TotalInventario: "0",
      },
      {
        AlmacenId: 31,
        TipoAlmacenId: 1,
        TipoAlmacen: "Bodega Nacional",
        Almacen: "ALMACEN ABC 123",
        Codigo: "PP",
        Ubicacion: "Autopista medellin Km7 Parque Ind. Celta Bog 123 – Funza",
        Longitud: "-74.1804347",
        Latitud: "4.7711929",
        TotalIngresos: "9",
        TotalDespachos: "4",
        TotalInventario: "0",
      },
      {
        AlmacenId: 22,
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        Ubicacion:
          "Zona Franca La Candelaria Km 9 Via Mamonal- Acceso 1 - Cartagena",
        Longitud: "-75.4924424",
        Latitud: "10.3258326",
        TotalIngresos: "1381",
        TotalDespachos: "5773",
        TotalInventario: "0",
      },
    ],
  },
  //data card totales y graficas
  CustomList: {
    message: "Customs list",
    status: 200,
    data: {
      chartIncome: [
        {
          status: "En Inventario",
          Cancelled: 1197,
          id: "En Inventario",
          CancelledColor: "#90CAF9",
          key: 7,
        },
        {
          status: "En Descargue",
          Cancelled: 0,
          id: "En Descargue",
          CancelledColor: "#64B5F6",
          key: 3,
        },
        {
          status: "Arribado",
          Cancelled: 0,
          id: "Arribado",
          CancelledColor: "#42A5F5",
          key: 2,
        },
        {
          status: "Notificado",
          Cancelled: 0,
          id: "Notificado",
          CancelledColor: "#2196F3",
          key: 1,
        },
      ],
      chartOffice: [
        {
          status: "Entregado",
          Cancelled: 4837,
          id: "Entregado",
          CancelledColor: "#61CF35",
          key: 4,
        },
        {
          status: "En Alistamiento",
          Cancelled: 0,
          id: "En Alistamiento",
          CancelledColor: "#4EAA29",
          key: 2,
        },
        {
          status: "Notificado",
          Cancelled: 0,
          id: "Notificado",
          CancelledColor: "#3B831E",
          key: 1,
        },
      ],
      totalOffice: "4837",
      totalIncome: "1197",
      chartTypeStoage: [
        {
          id: "Depósito Habilitado",
          label: "Depósito Habilitado",
          Despacho: 71,
          despachoColor: "#00A9FF",
          Ingreso: 70,
          ingresoColor: "#00A9FF",
        },
        {
          id: "Zona Franca",
          label: "Zona Franca",
          Despacho: 1101,
          despachoColor: "#00A9FF",
          Ingreso: 4754,
          ingresoColor: "#00A9FF",
        },
        {
          id: "Bodega Nacional",
          label: "Bodega Nacional",
          Despacho: 25,
          despachoColor: "#00A9FF",
          Ingreso: 13,
          ingresoColor: "#00A9FF",
        },
      ],
    },
  },
  //data catalogos
  Catalogos: {
    message: "Customs list",
    status: 200,
    data: {
      TipoAlmacen: [
        { TipoAlmacen: "Depósito Habilitado" },
        { TipoAlmacen: "Zona Franca" },
        { TipoAlmacen: "Bodega Nacional" },
      ],
      Almacen: [
        { Almacen: "ALMACEN ABC 123" },
        { Almacen: " BODEGA NACIONAL" },
        { Almacen: "ALMACEN ZONA FRANCA" },
        { Almacen: "ALMACEN DEP PUBLICO" },
      ],
      TipoVehiculo: [
        { TipoVehiculo: "VEHICULO" },
        { TipoVehiculo: "MOTO" },
        { TipoVehiculo: "TURBO" },
        { TipoVehiculo: "LUV" },
        { TipoVehiculo: "MULA" },
        { TipoVehiculo: "PATINETA" },
        { TipoVehiculo: "0" },
        { TipoVehiculo: "SENCILLO" },
        { TipoVehiculo: "FURGON" },
      ],
      Cliente: [
        { Cliente: "CLIENTE ABC 123" },
        { Cliente: "CLIENTE ABC 567" },
      ],
      Estatus: [{ Estatus: "En Inventario" }],
    },
  },
  //data ingresos
  Ingresos: {
    message: "office storage list",
    status: 200,
    data: [
      {
        IngresoId: 18891,
        AlmacenId: 20,
        Almacen: "ALMACEN DEP PUBLICO",
        Codigo: "DPUB",
        TipoAlmacenId: 3,
        TipoAlmacen: "Depósito Habilitado",
        NoIngreso: 23881,
        DoAduana: "",
        RefCliente: "729MIA40529926",
        DocTransporte: "729MIA40529926",
        Documento: "0011787563821812",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "SENCILLO",
        CantidadesEsperadas: "2",
        CantidadesRecibidas: "2",
        CantidadesCalidad: null,
        ObservacionesRecepcion: "SE RECIBEN 2 ESTIBAS SIN VERIFICAR CONTENIDO",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7448,
        Cliente: "CLIENTE ABC 567",
        FechaNotificacionIngreso: "14-04-2022",
        FechaLlegadaAlmacen: "19-04-2022",
        NIT: "860002345",
        FechaInicioDescargue: "2022-04-19T13:36:38.709Z",
        FechaFinDescargue: "2022-04-19T14:03:45.995Z",
        FechaManifiesto: "2022-04-15T00:00:00.000Z",
        FechaVencimientoManifiesto: "2022-05-15T00:00:00.000Z",
        FechaDisponibilidadInventario: "20-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T14:15:04.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18832,
        AlmacenId: 20,
        Almacen: "ALMACEN DEP PUBLICO",
        Codigo: "DPUB",
        TipoAlmacenId: 3,
        TipoAlmacen: "Depósito Habilitado",
        NoIngreso: 23840,
        DoAduana: "",
        RefCliente: "3387274354",
        DocTransporte: "3387274354",
        Documento: "0011787563789418",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "FURGON",
        CantidadesEsperadas: "2",
        CantidadesRecibidas: "2",
        CantidadesCalidad: null,
        ObservacionesRecepcion:
          "SE RECIBEN 2 PIEZAS ABOLLADAS SIN VERIFICAR CONTENIDO",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7448,
        Cliente: "CLIENTE ABC 567",
        FechaNotificacionIngreso: "11-04-2022",
        FechaLlegadaAlmacen: "18-04-2022",
        NIT: "860002345",
        FechaInicioDescargue: "2022-04-18T11:20:20.177Z",
        FechaFinDescargue: "2022-04-18T11:30:03.898Z",
        FechaManifiesto: "2022-04-14T00:00:00.000Z",
        FechaVencimientoManifiesto: "2022-05-14T00:00:00.000Z",
        FechaDisponibilidadInventario: "18-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T01:15:06.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18823,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7284,
        DoAduana: "",
        RefCliente: "RLH464",
        DocTransporte: "RLH464",
        Documento: "918606554",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "SENCILLO",
        CantidadesEsperadas: "1440",
        CantidadesRecibidas: "1440",
        CantidadesCalidad: "1440",
        ObservacionesRecepcion:
          "SE RECIBEN 15 CAJAS EN BUEN ESTADO SIN NOVEDAD",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "13-04-2022",
        FechaLlegadaAlmacen: "13-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: null,
        FechaFinDescargue: null,
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "13-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-14T04:25:02.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18711,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7273,
        DoAduana: "",
        RefCliente: "HLCULIV220241761",
        DocTransporte: "HLCULIV220241761",
        Documento: "918605008",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "0",
        CantidadesEsperadas: "17316",
        CantidadesRecibidas: "17316",
        CantidadesCalidad: "17316",
        ObservacionesRecepcion:
          "SE RECIBEN 1323 CAJAS SIN NOVEDAD DEL  # CONT: HLBU1774428",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "08-04-2022",
        FechaLlegadaAlmacen: "08-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: "2022-04-08T11:27:11.581Z",
        FechaFinDescargue: "2022-04-08T14:47:45.181Z",
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "08-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-09T18:30:02.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18721,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7276,
        DoAduana: "",
        RefCliente: "HLCULIV220241889",
        DocTransporte: "HLCULIV220241889",
        Documento: "918605011",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "MULA",
        CantidadesEsperadas: "14400",
        CantidadesRecibidas: "14400",
        CantidadesCalidad: "14400",
        ObservacionesRecepcion:
          "SE RECIBEN 2280 CAJAS SIN NOVEDAD DEL # CONT: HLBU2184452",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "08-04-2022",
        FechaLlegadaAlmacen: "08-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: "2022-04-08T15:16:06.073Z",
        FechaFinDescargue: "2022-04-08T17:11:34.685Z",
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "08-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-09T18:45:02.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18719,
        AlmacenId: 20,
        Almacen: "ALMACEN DEP PUBLICO",
        Codigo: "DPUB",
        TipoAlmacenId: 3,
        TipoAlmacen: "Depósito Habilitado",
        NoIngreso: 23746,
        DoAduana: "",
        RefCliente: "02157125",
        DocTransporte: "02157125",
        Documento: "0011787563677826",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "SENCILLO",
        CantidadesEsperadas: "1",
        CantidadesRecibidas: "1",
        CantidadesCalidad: null,
        ObservacionesRecepcion:
          "SE RECIBE 1 HUACAL VINIPELADO SIN VERIFICAR CONTENIDO",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7448,
        Cliente: "CLIENTE ABC 567",
        FechaNotificacionIngreso: "25-03-2022",
        FechaLlegadaAlmacen: "08-04-2022",
        NIT: "860002345",
        FechaInicioDescargue: "2022-04-08T11:44:03.177Z",
        FechaFinDescargue: "2022-04-08T11:52:04.933Z",
        FechaManifiesto: "2022-04-05T00:00:00.000Z",
        FechaVencimientoManifiesto: "2022-05-05T00:00:00.000Z",
        FechaDisponibilidadInventario: "08-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-08T21:45:03.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18696,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7272,
        DoAduana: "",
        RefCliente: "HLCULIV220241801",
        DocTransporte: "HLCULIV220241801",
        Documento: "918604999",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "MULA",
        CantidadesEsperadas: "12600",
        CantidadesRecibidas: "12600",
        CantidadesCalidad: "12600",
        ObservacionesRecepcion:
          "SE RECIBEN 2100 CAJAS SIN NOVEDAD DEL # CONT: TEMU6809425",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "08-04-2022",
        FechaLlegadaAlmacen: "08-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: "2022-04-08T09:33:27.883Z",
        FechaFinDescargue: "2022-04-08T11:47:12.741Z",
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "08-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-09T18:20:02.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 18707,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7271,
        DoAduana: "",
        RefCliente: "HLCULE1220143400",
        DocTransporte: "HLCULE1220143400",
        Documento: "918604747",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "MULA",
        CantidadesEsperadas: "14328",
        CantidadesRecibidas: "14328",
        CantidadesCalidad: "14328",
        ObservacionesRecepcion:
          "SE RECIBEN 996 CAJAS SIN NOVEDAD DEL # CONT: UACU3585745",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "08-04-2022",
        FechaLlegadaAlmacen: "08-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: "2022-04-08T16:10:02.512Z",
        FechaFinDescargue: "2022-04-08T10:54:27.776Z",
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "08-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-09T18:10:01.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 13996,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7257,
        DoAduana: "",
        RefCliente: "HLCULE12202AQCT1",
        DocTransporte: "HLCULE12202AQCT1",
        Documento: "918604606",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "MULA",
        CantidadesEsperadas: "11376",
        CantidadesRecibidas: "11376",
        CantidadesCalidad: "11375",
        ObservacionesRecepcion:
          "SE RECIBEN 1098 CAJAS DEL # CONT: CAIU2650115, CON NOVEDAD EN UNA BOTELLA  ROTA DE DUBONNET 750 AL MOMENTO DEL DESCARGUE ",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "06-04-2022",
        FechaLlegadaAlmacen: "06-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: "2022-04-06T11:08:57.113Z",
        FechaFinDescargue: "2022-04-06T14:14:23.202Z",
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "06-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-07T14:50:02.000Z",
        Proyecto: null,
        Total: "1197",
      },
      {
        IngresoId: 13999,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        NoIngreso: 7260,
        DoAduana: "",
        RefCliente: "HLCULE1220143400",
        DocTransporte: "HLCULE1220143400",
        Documento: "918604747",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "OTRO",
        TipoVehiculo: "MULA",
        CantidadesEsperadas: "11088",
        CantidadesRecibidas: "11082",
        CantidadesCalidad: "11082",
        ObservacionesRecepcion:
          "SE RECIBEN 924 CAJAS DEL # CONT: HAMU1077296, CON NOVEDAD DE 6 UNIDADES FALTANTE DE ORIGEN DEL PRODUCTO DUBONET 750, RECIBIENDO 11082 UNIDADES",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionIngreso: "06-04-2022",
        FechaLlegadaAlmacen: "06-04-2022",
        NIT: "860001234",
        FechaInicioDescargue: "2022-04-06T14:54:38.318Z",
        FechaFinDescargue: null,
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "06-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-08T17:20:02.000Z",
        Proyecto: null,
        Total: "1197",
      },
    ],
    totalPage: 120,
  },
  //catlogos despachos
  catalogoDespachos: {
    message: "Customs list",
    status: 200,
    data: {
      TipoAlmacen: [
        { TipoAlmacen: "Depósito Habilitado" },
        { TipoAlmacen: "Zona Franca" },
        { TipoAlmacen: "Bodega Nacional" },
      ],
      Almacen: [
        { Almacen: "ALMACEN ZONA FRANCA" },
        { Almacen: "ALMACEN DEP PUBLICO" },
        { Almacen: "ALMACEN ABC 123" },
        { Almacen: " BODEGA NACIONAL" },
      ],
      Cliente: [
        { Cliente: "CLIENTE ABC 567" },
        { Cliente: "CLIENTE ABC 123" },
      ],
      Estatus: [{ Estatus: "Entregado" }],
    },
  },
  //data despachos
  dataDespachos: {
    message: "office storage list",
    status: 200,
    data: [
      {
        DespachoId: 86084,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "22000232",
        DocTransporte: null,
        NoDespacho: "29499",
        CantidadSolicitadas: "444",
        CantidadDespachadas: "168",
        PlacaVehiculo: "EGQ721",
        Destinatario: "CARTAGENA CONSUMO .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "22-03-2022",
        FechaConfirmacionAlistamiento: "25-03-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:15:03.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86080,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "22000319",
        DocTransporte: null,
        NoDespacho: "29803",
        CantidadSolicitadas: "2064",
        CantidadDespachadas: "2064",
        PlacaVehiculo: "TRF135",
        Destinatario: "POPAYAN CONSUMO .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "20-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:04.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86082,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "22000311",
        DocTransporte: null,
        NoDespacho: "29799",
        CantidadSolicitadas: "1686",
        CantidadDespachadas: "1686",
        PlacaVehiculo: "TRF135",
        Destinatario: "MANIZALES CONSUMO .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:05.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86081,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "22000313",
        DocTransporte: null,
        NoDespacho: "29800",
        CantidadSolicitadas: "834",
        CantidadDespachadas: "834",
        PlacaVehiculo: "TRF135",
        Destinatario: "ARMENIA CONSUMO .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:05.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86078,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "22000309",
        DocTransporte: null,
        NoDespacho: "29798",
        CantidadSolicitadas: "1920",
        CantidadDespachadas: "1920",
        PlacaVehiculo: "TRF135",
        Destinatario: "SYWORKS LATIN AMERICA S.A. .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:03.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86079,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "22000317",
        DocTransporte: null,
        NoDespacho: "29802",
        CantidadSolicitadas: "1926",
        CantidadDespachadas: "1926",
        PlacaVehiculo: "TRF135",
        Destinatario: "IBAGUE CONSUMO .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:04.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86076,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "102709",
        DocTransporte: null,
        NoDespacho: "29738",
        CantidadSolicitadas: "2418",
        CantidadDespachadas: "2418",
        PlacaVehiculo: "TRF135",
        Destinatario: "DISTRIBUIDORA SURTILIMA S.A.S. .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:02.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86077,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "102714",
        DocTransporte: null,
        NoDespacho: "29743",
        CantidadSolicitadas: "962",
        CantidadDespachadas: "962",
        PlacaVehiculo: "TRF135",
        Destinatario: "PUYO S.A .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:03:03.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86072,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "102831",
        DocTransporte: null,
        NoDespacho: "29790",
        CantidadSolicitadas: "96",
        CantidadDespachadas: "96",
        PlacaVehiculo: "TRF135",
        Destinatario: "DISTRIBUIDORA SURTILIMA S.A.S. .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:00:03.000Z",
        Proyecto: null,
        Total: "4849",
      },
      {
        DespachoId: 86073,
        AlmacenId: 22,
        Almacen: "ALMACEN ZONA FRANCA",
        Codigo: "ZCAN",
        TipoAlmacenId: 2,
        TipoAlmacen: "Zona Franca",
        RefCliente: "102824",
        DocTransporte: null,
        NoDespacho: "29793",
        CantidadSolicitadas: "48",
        CantidadDespachadas: "48",
        PlacaVehiculo: "TRF135",
        Destinatario: "DISTRIBUIDORA SURTILIMA S.A.S. .",
        Observaciones: null,
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 7554,
        Cliente: "CLIENTE ABC 123",
        FechaNotificacionOrden: "13-04-2022",
        FechaConfirmacionAlistamiento: "19-04-2022",
        FechaNotificacionSalida: "20-04-2022",
        FechaEntregaTransportista: "20-04-2022",
        NIT: "860001234",
        Proceso: "ENTG",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T21:00:04.000Z",
        Proyecto: null,
        Total: "4849",
      },
    ],
    totalPage: 485,
  },
  //detalle ingresos
  detalleIngresos: {
    message: "Storage Data Ofiice",
    status: 200,
    data: [
      {
        IngresoId: 18908,
        AlmacenId: 30,
        Almacen: " BODEGA NACIONAL",
        Codigo: "BNAL",
        TipoAlmacenId: 1,
        TipoAlmacen: "Bodega Nacional",
        NoIngreso: 5782,
        DoAduana: "",
        RefCliente: "1",
        DocTransporte: "W1207",
        Documento: "W1207",
        TipoDocumento: "RECE",
        Proceso: "RECE",
        TipoDocumentoEspecifico: "INGR",
        TipoVehiculo: "VEHICULO",
        CantidadesEsperadas: "1",
        CantidadesRecibidas: "1",
        CantidadesCalidad: "0",
        ObservacionesRecepcion:
          "SE RECIBE 1 CAJA EN BUEN ESTADO EN PRESENCIA DE ACOLCEX",
        EstatusId: 155,
        Estatus: "En Inventario",
        ClienteId: 8098,
        Cliente: "CLIENTE ABC 545",
        FechaNotificacionIngreso: "20-04-2022",
        FechaLlegadaAlmacen: "20-04-2022",
        NIT: "830089336",
        FechaInicioDescargue: "2022-04-19T16:21:32.402Z",
        FechaFinDescargue: "20-04-2022",
        FechaManifiesto: null,
        FechaVencimientoManifiesto: null,
        FechaDisponibilidadInventario: "20-04-2022",
        FechaDevolucionContenedor: null,
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "20-04-2022",
        Proyecto: null,
      },
    ],
  },
  //documentosIngresos
  documentosIngresos: {
    message: {
      arreglo_respuestas: [
        {
          punto: "BNAL",
          tipo_documento: "RECE",
          documento: "5782",
          estado: "",
          arreglo_conexiones: [
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/BNAL/RECE/RECE/20220420/5782/5782.pdf",
              ],
            },
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/BNAL/RECE/RECE/20220420/5782/img_20220419_133751_(fileminimizer).jpg",
              ],
            },
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/BNAL/RECE/RECE/20220420/5782/img_20220419_133800_(fileminimizer).jpg",
              ],
            },
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/BNAL/RECE/RECE/20220420/5782/img_20220419_133804_(fileminimizer).jpg",
              ],
            },
          ],
        },
      ],
    },
    status: 200,
  },
  //eventosIngresos
  eventosIngresos: {
    message: "SUCCESS",
    customMessage: "FIND CONTAINER EVENTS",
    data: [
      {
        EventId: 1601053,
        RowId: 18908,
        ModuleId: 6,
        Module: "Ingresos",
        EventDescriptionId: 3,
        EventDescription: "Fecha de Inicio de Descargue",
        EventTypeId: 10,
        EventType: "Bodega Nacional",
        InTimeLine: "1",
        EventDate: "19-04-2022 16:21",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "20-04-2022",
      },
      {
        EventId: 1601051,
        RowId: 18908,
        ModuleId: 6,
        Module: "Ingresos",
        EventDescriptionId: 1,
        EventDescription: "Fecha de Notificación de Ingreso",
        EventTypeId: 10,
        EventType: "Bodega Nacional",
        InTimeLine: "1",
        EventDate: "20-04-2022 00:00",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "20-04-2022",
      },
      {
        EventId: 1601054,
        RowId: 18908,
        ModuleId: 6,
        Module: "Ingresos",
        EventDescriptionId: 4,
        EventDescription: "Fecha de Fin de Descargue",
        EventTypeId: 10,
        EventType: "Bodega Nacional",
        InTimeLine: "1",
        EventDate: "20-04-2022 13:37",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "20-04-2022",
      },
      {
        EventId: 1601052,
        RowId: 18908,
        ModuleId: 6,
        Module: "Ingresos",
        EventDescriptionId: 2,
        EventDescription: "Fecha de Llegada a almacén",
        EventTypeId: 10,
        EventType: "Bodega Nacional",
        InTimeLine: "1",
        EventDate: "20-04-2022 15:06",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "20-04-2022",
      },
      {
        EventId: 1601055,
        RowId: 18908,
        ModuleId: 6,
        Module: "Ingresos",
        EventDescriptionId: 8,
        EventDescription: "Fecha de disponibilidad en inventario",
        EventTypeId: 10,
        EventType: "Bodega Nacional",
        InTimeLine: "1",
        EventDate: "20-04-2022 15:06",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "20-04-2022",
      },
    ],
    steps: [
      { name: "Confirmed", date: "", completed: false, EventDescriptionId: 31 },
      { name: "Booked", date: "", completed: false, EventDescriptionId: 34 },
      { name: "Departed", date: "", completed: false, EventDescriptionId: 62 },
      { name: "Arrived", date: "", completed: false, EventDescriptionId: 91 },
      {
        name: "Docs Released",
        date: "",
        completed: false,
        EventDescriptionId: 97,
      },
      {
        name: "Customs Released",
        date: "",
        completed: false,
        EventDescriptionId: 98,
      },
    ],
    code: 200,
  },
  //despachos
  //documentosDespachos
  documentosDespachos: {
    message: {
      arreglo_respuestas: [
        {
          punto: "DPUB",
          tipo_documento: "SALI",
          documento: "22203",
          estado: "",
          arreglo_conexiones: [
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/DPUB/SALI/SALI/20211011/22203/22333.pdf",
              ],
            },
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/DPUB/SALI/SALI/20211011/22203/272-3_(1).jpg",
              ],
            },
            {
              nombre_archivo: [
                "wms.roldanlogistica.com/suite/imagenes/DPUB/SALI/SALI/20211011/22203/272-3_(2).jpg",
              ],
            },
          ],
        },
      ],
    },
    status: 200,
  },
  //eventosDespachos
  eventosDespachos: {
    message: "SUCCESS",
    customMessage: "FIND CONTAINER EVENTS",
    data: [
      {
        EventId: 1223432,
        RowId: 65264,
        ModuleId: 5,
        Module: "Despachos",
        EventDescriptionId: 1,
        EventDescription: "Notificación de Orden de Salida ",
        EventTypeId: 8,
        EventType: "Despacho",
        InTimeLine: "1",
        EventDate: "11-10-2021 13:16",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1308619,
        RowId: 65264,
        ModuleId: 5,
        Module: "Despachos",
        EventDescriptionId: 2,
        EventDescription: "Confirmación de Alistamiento",
        EventTypeId: 8,
        EventType: "Despacho",
        InTimeLine: "1",
        EventDate: "11-10-2021 13:36",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1393811,
        RowId: 65264,
        ModuleId: 5,
        Module: "Despachos",
        EventDescriptionId: 3,
        EventDescription: "Notificación de Salida de Carga",
        EventTypeId: 8,
        EventType: "Despacho",
        InTimeLine: "1",
        EventDate: "11-10-2021 13:38",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1479003,
        RowId: 65264,
        ModuleId: 5,
        Module: "Despachos",
        EventDescriptionId: 4,
        EventDescription: "Entrega a Transportista",
        EventTypeId: 8,
        EventType: "Despacho",
        InTimeLine: "1",
        EventDate: "11-10-2021 13:38",
        CategoryResponsableId: 4,
        CategoryResponsable: "Almacén",
        Origin: "Systech",
        CreateDate: "07-04-2022",
      },
    ],
    steps: [
      { name: "Confirmed", date: "", completed: false, EventDescriptionId: 31 },
      { name: "Booked", date: "", completed: false, EventDescriptionId: 34 },
      { name: "Departed", date: "", completed: false, EventDescriptionId: 62 },
      { name: "Arrived", date: "", completed: false, EventDescriptionId: 91 },
      {
        name: "Docs Released",
        date: "",
        completed: false,
        EventDescriptionId: 97,
      },
      {
        name: "Customs Released",
        date: "",
        completed: false,
        EventDescriptionId: 98,
      },
    ],
    code: 200,
  },
  //detalleDespachos
  detalleDespachos: {
    message: "Storage Data Ofiice",
    status: 200,
    data: [
      {
        DespachoId: 65264,
        AlmacenId: 20,
        Almacen: "ALMACEN DEP PUBLICO",
        Codigo: "DPUB",
        TipoAlmacenId: 3,
        TipoAlmacen: "Depósito Habilitado",
        RefCliente: "032021001108594",
        DocTransporte: "EAMIA21103272-3",
        NoDespacho: "22203",
        CantidadSolicitadas: "3",
        CantidadDespachadas: "3",
        PlacaVehiculo: "THW086",
        Destinatario: "MEGASUMINISTROSYSERVICIOS SAS",
        Observaciones:
          "RETIRO  2 CAJAS CINTADAS Y 1 ESTIBA VINIPELADA SIN VERIFICAR CONTENIDO MEGASUMINISTROSYSERVICIOS SAS",
        EstatusId: 124,
        Estatus: "Entregado",
        ClienteId: 8183,
        Cliente: "MEGASUMINISTROSYSERVICIOS SAS",
        FechaNotificacionOrden: "2021-10-11T13:16:04.589Z",
        FechaConfirmacionAlistamiento: "11-10-2021",
        FechaNotificacionSalida: "2021-10-11T13:38:14.301Z",
        FechaEntregaTransportista: "2021-10-11T13:38:14.301Z",
        NIT: "901165737",
        Proceso: "SALI",
        TipoDocumento: "SALI",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "07-04-2022",
        Proyecto: null,
      },
    ],
  },

  //detalle de inventario
  //data Inventario
  inventario: {
    message: {
      arreglo_respuestas: [
        {
          punto: "DPUB",
          nombre_punto: "DEPOSITO PUBLICO BOGOTÁ",
          nombre2_punto: "ALMACEN DEP PUBLICO",
          articulo: ["02157125"],
          nombre_articulo: "02157125 Man:116575012279052 del 04/05/2022",
          presentacion: ["UND"],
          nombre_presentacion: [""],
          tipo_producto: "IMPO",
          nombre_tipo_producto: "IMPORTACION",
          serie: "0",
          lote: "0",
          documento1: "03022023746",
          documento2: "CONSIGNADA",
          estado_articulo: "ACTI",
          nombre_estado_articulo: "ACTIVO",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1,
          total_peso: 1,
          unidad_medida_peso: "kilos",
          total_volumen: 1,
          unidad_medida_volumen: "metros_cubico",
          alterno1: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "KBR7796",
          documento1: "HLCULIV211243527",
          documento2: "918587022",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-05",
          valor_mercado: "0.000000",
          cantidad: 3600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3600,
          total_peso_bruto: 5148,
          total_peso: 5148,
          unidad_medida_peso: "kilos",
          total_volumen: 8.23878,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "KBR7844",
          documento1: "HLCULIV211243527",
          documento2: "918587022",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-05",
          valor_mercado: "0.000000",
          cantidad: 720,
          cantidad_por_alistar: 0,
          cantidad_disponible: 720,
          total_peso_bruto: 1029.6,
          total_peso: 1029.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.647756,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR0793",
          documento1: "HLCULIV210231698",
          documento2: "918530545",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-04-14",
          valor_mercado: "0.000000",
          cantidad: 372,
          cantidad_por_alistar: 0,
          cantidad_disponible: 372,
          total_peso_bruto: 531.96,
          total_peso: 531.96,
          unidad_medida_peso: "kilos",
          total_volumen: 0.851341,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR0793",
          documento1: "HLCULIV210231698",
          documento2: "918530545",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-04-18",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1475",
          documento1: "HLCULIV210471444",
          documento2: "918541574",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-01",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1849",
          documento1: "HLCULIV210436241",
          documento2: "918542740",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-08-12",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1913",
          documento1: "HLCULIV210456127",
          documento2: "918542081",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1913",
          documento1: "HLCULIV210456127",
          documento2: "918542081",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.86,
          total_peso: 2.86,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004577,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1990",
          documento1: "HLCULIV210456010",
          documento2: "918542215",
          estado_articulo: "DIAN",
          nombre_estado_articulo: "DIAN",
          fecha_entrada: "2021-08-18",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2407",
          documento1: "HLCULIV210502378",
          documento2: "918544051",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-12",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2875",
          documento1: "HLCULIV210658403",
          documento2: "918551376",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-20",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3804",
          documento1: "HLCULIV210749810",
          documento2: "918558203",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-11-11",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3804",
          documento1: "HLCULIV210750052",
          documento2: "918558200",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1200,
          total_peso_bruto: 1716,
          total_peso: 1716,
          unidad_medida_peso: "kilos",
          total_volumen: 2.74626,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3804",
          documento1: "HLCULIV210750052",
          documento2: "918558200",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.86,
          total_peso: 2.86,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004577,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR4445",
          documento1: "HLCULIV210826990",
          documento2: "918561926",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 4,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4,
          total_peso_bruto: 5.72,
          total_peso: 5.72,
          unidad_medida_peso: "kilos",
          total_volumen: 0.009154,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR4458",
          documento1: "HLCULIV210839812",
          documento2: "918564226",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-01",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6615",
          documento1: "HLCULIV211243527",
          documento2: "918587022",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-05",
          valor_mercado: "0.000000",
          cantidad: 180,
          cantidad_por_alistar: 0,
          cantidad_disponible: 180,
          total_peso_bruto: 257.4,
          total_peso: 257.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.411939,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6615",
          documento1: "HLCULIV211056039",
          documento2: "918579309",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-01",
          valor_mercado: "0.000000",
          cantidad: 13118,
          cantidad_por_alistar: 3156,
          cantidad_disponible: 9962,
          total_peso_bruto: 18758.74,
          total_peso: 18758.74,
          unidad_medida_peso: "kilos",
          total_volumen: 30.021198,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6615",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 24,
          cantidad_por_alistar: 0,
          cantidad_disponible: 24,
          total_peso_bruto: 34.32,
          total_peso: 34.32,
          unidad_medida_peso: "kilos",
          total_volumen: 0.054925,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6615",
          documento1: "HLCULIV211118322",
          documento2: "918580504",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 3480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3480,
          total_peso_bruto: 4976.4,
          total_peso: 4976.4,
          unidad_medida_peso: "kilos",
          total_volumen: 7.964155,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6615",
          documento1: "HLCULIV211204356",
          documento2: "918587036",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 4320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4320,
          total_peso_bruto: 6177.6,
          total_peso: 6177.6,
          unidad_medida_peso: "kilos",
          total_volumen: 9.886536,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7796",
          documento1: "HLCULIV211243527",
          documento2: "918587022",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-05",
          valor_mercado: "0.000000",
          cantidad: 10620,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10620,
          total_peso_bruto: 15186.6,
          total_peso: 15186.6,
          unidad_medida_peso: "kilos",
          total_volumen: 24.304401,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7796",
          documento1: "HLCULIV211266846",
          documento2: "918587131",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-20",
          valor_mercado: "0.000000",
          cantidad: 15120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15120,
          total_peso_bruto: 21621.6,
          total_peso: 21621.6,
          unidad_medida_peso: "kilos",
          total_volumen: 34.602876,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7796",
          documento1: "HLCULIV211281622",
          documento2: "918589972",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-27",
          valor_mercado: "0.000000",
          cantidad: 15120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15120,
          total_peso_bruto: 21621.6,
          total_peso: 21621.6,
          unidad_medida_peso: "kilos",
          total_volumen: 34.602876,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7796",
          documento1: "HLCULIV211228160",
          documento2: "918586785",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 15120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15120,
          total_peso_bruto: 21621.6,
          total_peso: 21621.6,
          unidad_medida_peso: "kilos",
          total_volumen: 34.602877,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7844",
          documento1: "HLCULIV211228159",
          documento2: "918586803",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-03",
          valor_mercado: "0.000000",
          cantidad: 30240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 30240,
          total_peso_bruto: 43243.2,
          total_peso: 43243.2,
          unidad_medida_peso: "kilos",
          total_volumen: 69.205752,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7844",
          documento1: "HLCULIV211243465",
          documento2: "918589954",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-21",
          valor_mercado: "0.000000",
          cantidad: 15120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15120,
          total_peso_bruto: 21621.6,
          total_peso: 21621.6,
          unidad_medida_peso: "kilos",
          total_volumen: 34.602876,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7844",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 768,
          cantidad_por_alistar: 0,
          cantidad_disponible: 768,
          total_peso_bruto: 1098.24,
          total_peso: 1098.24,
          unidad_medida_peso: "kilos",
          total_volumen: 1.757607,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 4320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4320,
          total_peso_bruto: 6177.6,
          total_peso: 6177.6,
          unidad_medida_peso: "kilos",
          total_volumen: 9.886536,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220192271",
          documento2: "918599016",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 21600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 21600,
          total_peso_bruto: 30888,
          total_peso: 30888,
          unidad_medida_peso: "kilos",
          total_volumen: 49.43268,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 4248,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4248,
          total_peso_bruto: 6074.64,
          total_peso: 6074.64,
          unidad_medida_peso: "kilos",
          total_volumen: 9.72176,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220190360",
          documento2: "9018600221",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 7200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7200,
          total_peso_bruto: 10296,
          total_peso: 10296,
          unidad_medida_peso: "kilos",
          total_volumen: 16.47756,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220191491",
          documento2: "918600478",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 1440,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1440,
          total_peso_bruto: 2059.2,
          total_peso: 2059.2,
          unidad_medida_peso: "kilos",
          total_volumen: 3.295512,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220191385",
          documento2: "918600482",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 5760,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5760,
          total_peso_bruto: 8236.8,
          total_peso: 8236.8,
          unidad_medida_peso: "kilos",
          total_volumen: 13.182048,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220191352",
          documento2: "918600479",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 5760,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5760,
          total_peso_bruto: 8236.8,
          total_peso: 8236.8,
          unidad_medida_peso: "kilos",
          total_volumen: 13.182048,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220191290",
          documento2: "918600481",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-18",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 4118.4,
          total_peso: 4118.4,
          unidad_medida_peso: "kilos",
          total_volumen: 6.591024,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 720,
          cantidad_por_alistar: 0,
          cantidad_disponible: 720,
          total_peso_bruto: 1029.6,
          total_peso: 1029.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.647756,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220344399",
          documento2: "918603360",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 4118.4,
          total_peso: 4118.4,
          unidad_medida_peso: "kilos",
          total_volumen: 6.591024,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220360580",
          documento2: "918603367",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 1439,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1439,
          total_peso_bruto: 2057.77,
          total_peso: 2057.77,
          unidad_medida_peso: "kilos",
          total_volumen: 3.293223,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220360580",
          documento2: "918603367",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0747",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 12240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12240,
          total_peso_bruto: 17503.2,
          total_peso: 17503.2,
          unidad_medida_peso: "kilos",
          total_volumen: 28.011852,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-09",
          valor_mercado: "0.000000",
          cantidad: 2160,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2160,
          total_peso_bruto: 3088.8,
          total_peso: 3088.8,
          unidad_medida_peso: "kilos",
          total_volumen: 4.943268,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 4118.4,
          total_peso: 4118.4,
          unidad_medida_peso: "kilos",
          total_volumen: 6.591024,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220191491",
          documento2: "918600478",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 6480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6480,
          total_peso_bruto: 9266.4,
          total_peso: 9266.4,
          unidad_medida_peso: "kilos",
          total_volumen: 14.829804,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220191385",
          documento2: "918600482",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 15120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15120,
          total_peso_bruto: 21621.6,
          total_peso: 21621.6,
          unidad_medida_peso: "kilos",
          total_volumen: 34.602876,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220191352",
          documento2: "918600479",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 9360,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9360,
          total_peso_bruto: 13384.8,
          total_peso: 13384.8,
          unidad_medida_peso: "kilos",
          total_volumen: 21.420828,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220191290",
          documento2: "918600481",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-18",
          valor_mercado: "0.000000",
          cantidad: 8640,
          cantidad_por_alistar: 0,
          cantidad_disponible: 8640,
          total_peso_bruto: 12355.2,
          total_peso: 12355.2,
          unidad_medida_peso: "kilos",
          total_volumen: 19.773072,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 4118.4,
          total_peso: 4118.4,
          unidad_medida_peso: "kilos",
          total_volumen: 6.591024,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220344399",
          documento2: "918603360",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 7920,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7920,
          total_peso_bruto: 11325.6,
          total_peso: 11325.6,
          unidad_medida_peso: "kilos",
          total_volumen: 18.125316,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0782",
          documento1: "HLCULIV220360580",
          documento2: "918603367",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 18000,
          cantidad_por_alistar: 0,
          cantidad_disponible: 18000,
          total_peso_bruto: 25740,
          total_peso: 25740,
          unidad_medida_peso: "kilos",
          total_volumen: 41.1939,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-09",
          valor_mercado: "0.000000",
          cantidad: 9360,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9360,
          total_peso_bruto: 13384.8,
          total_peso: 13384.8,
          unidad_medida_peso: "kilos",
          total_volumen: 21.420828,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220191491",
          documento2: "918600478",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 6480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6480,
          total_peso_bruto: 9266.4,
          total_peso: 9266.4,
          unidad_medida_peso: "kilos",
          total_volumen: 14.829804,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220191385",
          documento2: "918600482",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 9360,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9360,
          total_peso_bruto: 13384.8,
          total_peso: 13384.8,
          unidad_medida_peso: "kilos",
          total_volumen: 21.420828,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220191290",
          documento2: "918600481",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-18",
          valor_mercado: "0.000000",
          cantidad: 3600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3600,
          total_peso_bruto: 5148,
          total_peso: 5148,
          unidad_medida_peso: "kilos",
          total_volumen: 8.23878,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 4118.4,
          total_peso: 4118.4,
          unidad_medida_peso: "kilos",
          total_volumen: 6.591024,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220344399",
          documento2: "918603360",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 4320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4320,
          total_peso_bruto: 6177.6,
          total_peso: 6177.6,
          unidad_medida_peso: "kilos",
          total_volumen: 9.886536,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0831",
          documento1: "HLCULIV220360580",
          documento2: "918603367",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 10800,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10800,
          total_peso_bruto: 15444,
          total_peso: 15444,
          unidad_medida_peso: "kilos",
          total_volumen: 24.71634,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0871",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-09",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 4118.4,
          total_peso: 4118.4,
          unidad_medida_peso: "kilos",
          total_volumen: 6.591024,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0871",
          documento1: "HLCULIV220191491",
          documento2: "918600478",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 720,
          cantidad_por_alistar: 0,
          cantidad_disponible: 720,
          total_peso_bruto: 1029.6,
          total_peso: 1029.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.647756,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0871",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 720,
          cantidad_por_alistar: 0,
          cantidad_disponible: 720,
          total_peso_bruto: 1029.6,
          total_peso: 1029.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.647756,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LPFN0834",
          documento1: "HLCULIV200142109",
          documento2: "918433781",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2020-09-03",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LPFN1463",
          documento1: "HLCULIV200142109",
          documento2: "918451709",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2020-04-29",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.43,
          total_peso: 1.43,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002289,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["10"],
          nombre_articulo: "WH SOMETHING SPECIAL / 750 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LPFP0261",
          documento1: "HLCULIV200411123",
          documento2: "918477221",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2020-08-14",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.86,
          total_peso: 2.86,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004577,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402795",
          alterno1: "SU08003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1001"],
          nombre_articulo: "WH CHIVAS 12Y 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR3380",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1001"],
          nombre_articulo: "WH CHIVAS 12Y 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR3380",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA2",
          nombre_estado_articulo: "ESTADO 2",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1001"],
          nombre_articulo: "WH CHIVAS 12Y 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR3380",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 4808,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4808,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1001"],
          nombre_articulo: "WH CHIVAS 12Y 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR4219",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1001"],
          nombre_articulo: "WH CHIVAS 12Y 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR4219",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 3595,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3595,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1001"],
          nombre_articulo: "WH CHIVAS 12Y 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR4219",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 724,
          cantidad_por_alistar: 0,
          cantidad_disponible: 724,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1002"],
          nombre_articulo: "WH SOMETHING SPECIAL 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKMR6401",
          documento1: "HLCULIV2111APIO0",
          documento2: "918584574",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-27",
          valor_mercado: "0.000000",
          cantidad: 840,
          cantidad_por_alistar: 0,
          cantidad_disponible: 840,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1009"],
          nombre_articulo: "RO LA HECHICERA 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "048",
          documento1: "RE-2001",
          documento2: "918596747",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-23",
          valor_mercado: "0.000000",
          cantidad: 180,
          cantidad_por_alistar: 0,
          cantidad_disponible: 180,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1009"],
          nombre_articulo: "RO LA HECHICERA 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "LOTE048",
          documento1: "RE-209776",
          documento2: "918600416",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 2172,
          cantidad_por_alistar: 276,
          cantidad_disponible: 1896,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1010"],
          nombre_articulo: "RON LA HECHICERA 96 X 50 ML",
          presentacion: ["96"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "NA",
          documento1: "RLH464",
          documento2: "918606554",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 1440,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1440,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["1013"],
          nombre_articulo: "WH ABERLOUR 12Y DOUBLE CASK 3 X 700 ML",
          presentacion: ["3"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "LKPR7160",
          documento1: "HLCULIV2111APIO0",
          documento2: "918584574",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-27",
          valor_mercado: "0.000000",
          cantidad: 501,
          cantidad_por_alistar: 0,
          cantidad_disponible: 501,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L0289B2",
          documento1: "HLCULE1201083875",
          documento2: "918511793",
          estado_articulo: "ESTM",
          nombre_estado_articulo: "ESTAMPILLADO",
          fecha_entrada: "2021-02-11",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.94,
          total_peso: 2.94,
          unidad_medida_peso: "kilos",
          total_volumen: 0.003235,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1180B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2022-04-06",
          valor_mercado: "0.000000",
          cantidad: 9,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9,
          total_peso_bruto: 13.23,
          total_peso: 13.23,
          unidad_medida_peso: "kilos",
          total_volumen: 0.014557,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1180B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 3850,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3850,
          total_peso_bruto: 5659.5,
          total_peso: 5659.5,
          unidad_medida_peso: "kilos",
          total_volumen: 6.22702,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1180B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 5,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5,
          total_peso_bruto: 7.35,
          total_peso: 7.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.008087,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1181B2",
          documento1: "HLCULE12109AQMI3",
          documento2: "918580341",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 9,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9,
          total_peso_bruto: 13.23,
          total_peso: 13.23,
          unidad_medida_peso: "kilos",
          total_volumen: 0.014557,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1181B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 504,
          cantidad_por_alistar: 0,
          cantidad_disponible: 504,
          total_peso_bruto: 740.88,
          total_peso: 740.88,
          unidad_medida_peso: "kilos",
          total_volumen: 0.815174,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1342B2",
          documento1: "HLCULE1220143400",
          documento2: "918604747",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 1344,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1344,
          total_peso_bruto: 1975.68,
          total_peso: 1975.68,
          unidad_medida_peso: "kilos",
          total_volumen: 2.173796,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1342B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 19319,
          cantidad_por_alistar: 0,
          cantidad_disponible: 19319,
          total_peso_bruto: 28398.93,
          total_peso: 28398.93,
          unidad_medida_peso: "kilos",
          total_volumen: 31.2467,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1342B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.47,
          total_peso: 1.47,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001617,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L2010B2",
          documento1: "HLCULE1220143400",
          documento2: "918604747",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 7560,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7560,
          total_peso_bruto: 11113.2,
          total_peso: 11113.2,
          unidad_medida_peso: "kilos",
          total_volumen: 12.227603,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L2011B2",
          documento1: "HLCULE1220143400",
          documento2: "918604747",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-06",
          valor_mercado: "0.000000",
          cantidad: 9234,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9234,
          total_peso_bruto: 13573.98,
          total_peso: 13573.98,
          unidad_medida_peso: "kilos",
          total_volumen: 14.935143,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["109"],
          nombre_articulo: "AP DUBONNET 14.8 / 750",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L2011B2",
          documento1: "HLCULE1220143400",
          documento2: "918604747",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 2520,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2520,
          total_peso_bruto: 3704.4,
          total_peso: 3704.4,
          unidad_medida_peso: "kilos",
          total_volumen: 4.075868,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933575001",
          alterno1: "PR24197",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["131"],
          nombre_articulo: "WH ROYAL SALUTE / 700 CTN",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKSR7675",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 6,
          cantidad_disponible: 0,
          total_peso_bruto: 10.68,
          total_peso: 10.68,
          unidad_medida_peso: "kilos",
          total_volumen: 0.021,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299211243",
          alterno1: "RS09022",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["131"],
          nombre_articulo: "WH ROYAL SALUTE / 700 CTN",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKSR7675",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 840,
          cantidad_por_alistar: 0,
          cantidad_disponible: 840,
          total_peso_bruto: 1495.2,
          total_peso: 1495.2,
          unidad_medida_peso: "kilos",
          total_volumen: 2.94,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299211243",
          alterno1: "RS09022",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["131"],
          nombre_articulo: "WH ROYAL SALUTE / 700 CTN",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKSR7675",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 240,
          cantidad_por_alistar: 100,
          cantidad_disponible: 140,
          total_peso_bruto: 427.2,
          total_peso: 427.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.84,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299211243",
          alterno1: "RS09022",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["131"],
          nombre_articulo: "WH ROYAL SALUTE / 700 CTN",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKSS0644",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-14",
          valor_mercado: "0.000000",
          cantidad: 660,
          cantidad_por_alistar: 0,
          cantidad_disponible: 660,
          total_peso_bruto: 1174.8,
          total_peso: 1174.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.31,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299211243",
          alterno1: "RS09022",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["131"],
          nombre_articulo: "WH ROYAL SALUTE / 700 CTN",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKSS0644",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 240,
          cantidad_por_alistar: 176,
          cantidad_disponible: 64,
          total_peso_bruto: 427.2,
          total_peso: 427.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.84,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299211243",
          alterno1: "RS09022",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["177"],
          nombre_articulo: "WH BALLANTINES FINEST / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2117",
          documento1: "HLCULIV210750052",
          documento2: "918558200",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 240,
          total_peso_bruto: 182.4,
          total_peso: 182.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.176269,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106112250",
          alterno1: "B313012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["177"],
          nombre_articulo: "WH BALLANTINES FINEST / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2117",
          documento1: "HLCULIV220241761",
          documento2: "918605008",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 2188.8,
          total_peso: 2188.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.115228,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106112250",
          alterno1: "B313012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["178"],
          nombre_articulo: "CH CHAMPAGNE MUMM 750 C ROUG",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-002",
          nombre_tipo_producto: "CHAMPAGNE",
          serie: "0",
          lote: "L2381631351",
          documento1: "1786",
          documento2: "918589505",
          estado_articulo: "ESTM",
          nombre_estado_articulo: "ESTAMPILLADO",
          fecha_entrada: "2022-01-18",
          valor_mercado: "0.000000",
          cantidad: 17,
          cantidad_por_alistar: 0,
          cantidad_disponible: 17,
          total_peso_bruto: 35.36,
          total_peso: 35.36,
          unidad_medida_peso: "kilos",
          total_volumen: 0.04036,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3043700103814",
          alterno1: "PCR0930J",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["4"],
          nombre_articulo: "WH CHIVAS 12 / 1750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKLR4679",
          documento1: "HLCULIV2111APIS4",
          documento2: "918584563",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-27",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 36.36,
          total_peso: 36.36,
          unidad_medida_peso: "kilos",
          total_volumen: 0.057375,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400463",
          alterno1: "CS28004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["4"],
          nombre_articulo: "WH CHIVAS 12 / 1750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKLR4679",
          documento1: "HLCULIV211200311",
          documento2: "918585791",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 66,
          cantidad_por_alistar: 0,
          cantidad_disponible: 66,
          total_peso_bruto: 199.98,
          total_peso: 199.98,
          unidad_medida_peso: "kilos",
          total_volumen: 0.315563,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400463",
          alterno1: "CS28004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["4"],
          nombre_articulo: "WH CHIVAS 12 / 1750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKLS0040",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 264,
          cantidad_por_alistar: 0,
          cantidad_disponible: 264,
          total_peso_bruto: 799.92,
          total_peso: 799.92,
          unidad_medida_peso: "kilos",
          total_volumen: 1.26225,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400463",
          alterno1: "CS28004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["4"],
          nombre_articulo: "WH CHIVAS 12 / 1750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKLS0040",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 78,
          cantidad_por_alistar: 12,
          cantidad_disponible: 66,
          total_peso_bruto: 236.34,
          total_peso: 236.34,
          unidad_medida_peso: "kilos",
          total_volumen: 0.372939,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400463",
          alterno1: "CS28004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["43"],
          nombre_articulo: "AP DUBONNET 14.8 / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1061B2",
          documento1: "HLCULE1210342084",
          documento2: "918545795",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-07-08",
          valor_mercado: "0.000000",
          cantidad: 21,
          cantidad_por_alistar: 0,
          cantidad_disponible: 21,
          total_peso_bruto: 16.59,
          total_peso: 16.59,
          unidad_medida_peso: "kilos",
          total_volumen: 0.020488,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933420004",
          alterno1: "PR24084",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["43"],
          nombre_articulo: "AP DUBONNET 14.8 / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1062B2",
          documento1: "MEDUL5369938",
          documento2: "918540437",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-05-31",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 2,
          cantidad_disponible: 0,
          total_peso_bruto: 1.58,
          total_peso: 1.58,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001951,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933420004",
          alterno1: "PR24084",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["43"],
          nombre_articulo: "AP DUBONNET 14.8 / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1343B2",
          documento1: "HLCULE1220143400",
          documento2: "918604747",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 4752,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4752,
          total_peso_bruto: 3754.08,
          total_peso: 3754.08,
          unidad_medida_peso: "kilos",
          total_volumen: 4.636051,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933420004",
          alterno1: "PR24084",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["43"],
          nombre_articulo: "AP DUBONNET 14.8 / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1348B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-06",
          valor_mercado: "0.000000",
          cantidad: 2304,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2304,
          total_peso_bruto: 1820.16,
          total_peso: 1820.16,
          unidad_medida_peso: "kilos",
          total_volumen: 2.247782,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933420004",
          alterno1: "PR24084",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["43"],
          nombre_articulo: "AP DUBONNET 14.8 / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1348B2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 7200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7200,
          total_peso_bruto: 5688,
          total_peso: 5688,
          unidad_medida_peso: "kilos",
          total_volumen: 7.02432,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933420004",
          alterno1: "PR24084",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["43"],
          nombre_articulo: "AP DUBONNET 14.8 / 375",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "SIN",
          documento1: "RCSH28112019",
          documento2: "918455997",
          estado_articulo: "ESTM",
          nombre_estado_articulo: "ESTAMPILLADO",
          fecha_entrada: "2019-12-28",
          valor_mercado: "0.000000",
          cantidad: 4,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4,
          total_peso_bruto: 3.16,
          total_peso: 3.16,
          unidad_medida_peso: "kilos",
          total_volumen: 0.003902,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163933420004",
          alterno1: "PR24084",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["475"],
          nombre_articulo: "WH CHIVAS 18 / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPR2595",
          documento1: "HLCULIV210772770",
          documento2: "918560914",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-09",
          valor_mercado: "0.000000",
          cantidad: 367,
          cantidad_por_alistar: 0,
          cantidad_disponible: 367,
          total_peso_bruto: 146.8,
          total_peso: 146.8,
          unidad_medida_peso: "kilos",
          total_volumen: 0.211392,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225271",
          alterno1: "C723004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["475"],
          nombre_articulo: "WH CHIVAS 18 / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPR2595",
          documento1: "HLCULIV210839812",
          documento2: "918564226",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 17,
          cantidad_por_alistar: 0,
          cantidad_disponible: 17,
          total_peso_bruto: 6.8,
          total_peso: 6.8,
          unidad_medida_peso: "kilos",
          total_volumen: 0.009792,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225271",
          alterno1: "C723004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["475"],
          nombre_articulo: "WH CHIVAS 18 / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPR2595",
          documento1: "HLCULIV210772770",
          documento2: "918560914",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-02-03",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.4,
          total_peso: 0.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000576,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225271",
          alterno1: "C723004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["475"],
          nombre_articulo: "WH CHIVAS 18 / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPR3068",
          documento1: "HLCULIV210772770",
          documento2: "918560914",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-18",
          valor_mercado: "0.000000",
          cantidad: 120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 120,
          total_peso_bruto: 48,
          total_peso: 48,
          unidad_medida_peso: "kilos",
          total_volumen: 0.06912,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225271",
          alterno1: "C723004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["481"],
          nombre_articulo: "VO ABSOLUT VODKA / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20200928P",
          documento1: "GTG0282667",
          documento2: "918509680",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2020-12-23",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0.22,
          total_peso: 0.22,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000271,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017508",
          alterno1: "1118804",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["481"],
          nombre_articulo: "VO ABSOLUT VODKA / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211201P",
          documento1: "GTG0312636",
          documento2: "918593302",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 5280,
          cantidad_por_alistar: 422,
          cantidad_disponible: 4858,
          total_peso_bruto: 580.8,
          total_peso: 580.8,
          unidad_medida_peso: "kilos",
          total_volumen: 0.715604,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017508",
          alterno1: "1118804",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["481"],
          nombre_articulo: "VO ABSOLUT VODKA / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220214P",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 7920,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7920,
          total_peso_bruto: 871.2,
          total_peso: 871.2,
          unidad_medida_peso: "kilos",
          total_volumen: 1.073406,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017508",
          alterno1: "1118804",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["481"],
          nombre_articulo: "VO ABSOLUT VODKA / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220215P",
          documento1: "GTG0317436",
          documento2: "918600474",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 7920,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7920,
          total_peso_bruto: 871.2,
          total_peso: 871.2,
          unidad_medida_peso: "kilos",
          total_volumen: 1.073406,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017508",
          alterno1: "1118804",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["493"],
          nombre_articulo: "VO WYBOROWA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "LZ21110973",
          documento1: "GDY0263464",
          documento2: "918587007",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-26",
          valor_mercado: "0.000000",
          cantidad: 714,
          cantidad_por_alistar: 72,
          cantidad_disponible: 642,
          total_peso_bruto: 871.08,
          total_peso: 871.08,
          unidad_medida_peso: "kilos",
          total_volumen: 1.244344,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5900685006043",
          alterno1: "2WW0001COS006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210120E",
          documento1: "GTG0290353",
          documento2: "918439581",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-03-29",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.79,
          total_peso: 1.79,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002161,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210121E",
          documento1: "GTG0290775",
          documento2: "918461720",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-04-14",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.79,
          total_peso: 1.79,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002161,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210205E",
          documento1: "HLCUFRA210209037",
          documento2: "918533728",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-06-23",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 3.58,
          total_peso: 3.58,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004321,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210401E",
          documento1: "GTG0294514",
          documento2: "918540788",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-06-23",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 3.58,
          total_peso: 3.58,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004322,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210406E",
          documento1: "GTG0295735",
          documento2: "918545218",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-14",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.79,
          total_peso: 1.79,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002161,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210517E",
          documento1: "MEDUUT667992",
          documento2: "918553444",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-18",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.79,
          total_peso: 1.79,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002161,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210517E",
          documento1: "MEDUUT667976",
          documento2: "918553447",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-18",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 3.58,
          total_peso: 3.58,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004322,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210621L",
          documento1: "GTG0300661",
          documento2: "918562133",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-11-11",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 10.74,
          total_peso: 10.74,
          unidad_medida_peso: "kilos",
          total_volumen: 0.012964,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211002E",
          documento1: "GTG0307854",
          documento2: "918579575",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-22",
          valor_mercado: "0.000000",
          cantidad: 234,
          cantidad_por_alistar: 0,
          cantidad_disponible: 234,
          total_peso_bruto: 418.86,
          total_peso: 418.86,
          unidad_medida_peso: "kilos",
          total_volumen: 0.505575,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211002E",
          documento1: "GTG0307854",
          documento2: "918579575",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.79,
          total_peso: 1.79,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002161,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211004E",
          documento1: "GTG0308231",
          documento2: "918579321",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 540,
          cantidad_por_alistar: 12,
          cantidad_disponible: 528,
          total_peso_bruto: 966.6,
          total_peso: 966.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.166711,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220110E",
          documento1: "CMDUGTG0315755",
          documento2: "918599899",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 9598,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9598,
          total_peso_bruto: 17180.42,
          total_peso: 17180.42,
          unidad_medida_peso: "kilos",
          total_volumen: 20.7372,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220110E",
          documento1: "CMDUGTG0315755",
          documento2: "918599899",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 3.58,
          total_peso: 3.58,
          unidad_medida_peso: "kilos",
          total_volumen: 0.004321,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L2022011E",
          documento1: "CMDUGTG0315755",
          documento2: "918599899",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 960,
          cantidad_por_alistar: 0,
          cantidad_disponible: 960,
          total_peso_bruto: 1718.4,
          total_peso: 1718.4,
          unidad_medida_peso: "kilos",
          total_volumen: 2.074152,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220127A",
          documento1: "GTG0316449",
          documento2: "918600473",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-24",
          valor_mercado: "0.000000",
          cantidad: 480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 480,
          total_peso_bruto: 859.2,
          total_peso: 859.2,
          unidad_medida_peso: "kilos",
          total_volumen: 1.037076,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220208L",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 2399,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2399,
          total_peso_bruto: 4294.21,
          total_peso: 4294.21,
          unidad_medida_peso: "kilos",
          total_volumen: 5.18322,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220208L",
          documento1: "GTG0317436",
          documento2: "918600474",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 6240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6240,
          total_peso_bruto: 11169.6,
          total_peso: 11169.6,
          unidad_medida_peso: "kilos",
          total_volumen: 13.481989,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220208L",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.79,
          total_peso: 1.79,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002161,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["533"],
          nombre_articulo: "VO ABSOLUT VODKA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L2022110E",
          documento1: "CMDUGTG0315755",
          documento2: "918599899",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 480,
          total_peso_bruto: 859.2,
          total_peso: 859.2,
          unidad_medida_peso: "kilos",
          total_volumen: 1.037076,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017034",
          alterno1: "11190038",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["573"],
          nombre_articulo: "LI RICARD / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-004",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L1181D2",
          documento1: "HLCULE1210781437",
          documento2: "918569750",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-27",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 7.5,
          total_peso: 7.5,
          unidad_medida_peso: "kilos",
          total_volumen: 0.012028,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163937011000",
          alterno1: "718586",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["573"],
          nombre_articulo: "LI RICARD / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-004",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L1355D2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-06",
          valor_mercado: "0.000000",
          cantidad: 570,
          cantidad_por_alistar: 0,
          cantidad_disponible: 570,
          total_peso_bruto: 712.5,
          total_peso: 712.5,
          unidad_medida_peso: "kilos",
          total_volumen: 1.142678,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163937011000",
          alterno1: "718586",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["573"],
          nombre_articulo: "LI RICARD / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-004",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L1355D2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 120,
          total_peso_bruto: 150,
          total_peso: 150,
          unidad_medida_peso: "kilos",
          total_volumen: 0.240564,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3163937011000",
          alterno1: "718586",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["6"],
          nombre_articulo: "WH CHIVAS 12 / 375",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR1874",
          documento1: "HLCULIV211045219",
          documento2: "918575754",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-08",
          valor_mercado: "0.000000",
          cantidad: 120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 120,
          total_peso_bruto: 87.6,
          total_peso: 87.6,
          unidad_medida_peso: "kilos",
          total_volumen: 0.137931,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400388",
          alterno1: "CS21016/01",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["6"],
          nombre_articulo: "WH CHIVAS 12 / 375",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3045",
          documento1: "HLCULIV210633590",
          documento2: "918362551",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-09",
          valor_mercado: "0.000000",
          cantidad: 25,
          cantidad_por_alistar: 0,
          cantidad_disponible: 25,
          total_peso_bruto: 18.25,
          total_peso: 18.25,
          unidad_medida_peso: "kilos",
          total_volumen: 0.028736,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400388",
          alterno1: "CS21016/01",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["6"],
          nombre_articulo: "WH CHIVAS 12 / 375",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3045",
          documento1: "HLCULIV210633590",
          documento2: "918362551",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 2.19,
          total_peso: 2.19,
          unidad_medida_peso: "kilos",
          total_volumen: 0.003448,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400388",
          alterno1: "CS21016/01",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["6"],
          nombre_articulo: "WH CHIVAS 12 / 375",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3631",
          documento1: "HLCULIV211045219",
          documento2: "918575754",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-08",
          valor_mercado: "0.000000",
          cantidad: 8196,
          cantidad_por_alistar: 0,
          cantidad_disponible: 8196,
          total_peso_bruto: 5983.08,
          total_peso: 5983.08,
          unidad_medida_peso: "kilos",
          total_volumen: 9.420681,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400388",
          alterno1: "CS21016/01",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["6"],
          nombre_articulo: "WH CHIVAS 12 / 375",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3631",
          documento1: "HLCULIV210774999",
          documento2: "918559909",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-11",
          valor_mercado: "0.000000",
          cantidad: 215,
          cantidad_por_alistar: 0,
          cantidad_disponible: 215,
          total_peso_bruto: 156.95,
          total_peso: 156.95,
          unidad_medida_peso: "kilos",
          total_volumen: 0.247126,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400388",
          alterno1: "CS21016/01",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["6"],
          nombre_articulo: "WH CHIVAS 12 / 375",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3631",
          documento1: "HLCULIV211045219",
          documento2: "918575754",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-01",
          valor_mercado: "0.000000",
          cantidad: 2013,
          cantidad_por_alistar: 64,
          cantidad_disponible: 1949,
          total_peso_bruto: 1469.49,
          total_peso: 1469.49,
          unidad_medida_peso: "kilos",
          total_volumen: 2.313791,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432400388",
          alterno1: "CS21016/01",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["66"],
          nombre_articulo: "WH SOMETHING SPECIAL / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7535",
          documento1: "HLCULIV211200735",
          documento2: "918587299",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 180,
          cantidad_por_alistar: 0,
          cantidad_disponible: 180,
          total_peso_bruto: 320.4,
          total_peso: 320.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.55755,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402771",
          alterno1: "SU06001",
          alterno2: "10080432402778",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["66"],
          nombre_articulo: "WH SOMETHING SPECIAL / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0257",
          documento1: "HLCULIV220192439",
          documento2: "918596664",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 8972,
          cantidad_por_alistar: 840,
          cantidad_disponible: 8132,
          total_peso_bruto: 15970.16,
          total_peso: 15970.16,
          unidad_medida_peso: "kilos",
          total_volumen: 27.79077,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402771",
          alterno1: "SU06001",
          alterno2: "10080432402778",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["66"],
          nombre_articulo: "WH SOMETHING SPECIAL / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0257",
          documento1: "HLCULIV220241889",
          documento2: "918605011",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 960,
          cantidad_por_alistar: 0,
          cantidad_disponible: 960,
          total_peso_bruto: 1708.8,
          total_peso: 1708.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.9736,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402771",
          alterno1: "SU06001",
          alterno2: "10080432402778",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["66"],
          nombre_articulo: "WH SOMETHING SPECIAL / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS1315",
          documento1: "HLCULIV220241889",
          documento2: "918605011",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 480,
          total_peso_bruto: 854.4,
          total_peso: 854.4,
          unidad_medida_peso: "kilos",
          total_volumen: 1.4868,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402771",
          alterno1: "SU06001",
          alterno2: "10080432402778",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["663"],
          nombre_articulo: "RO HAVANA SELECCIN MAESTROS",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L116750027",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 23,
          cantidad_por_alistar: 0,
          cantidad_disponible: 23,
          total_peso_bruto: 36.34,
          total_peso: 36.34,
          unidad_medida_peso: "kilos",
          total_volumen: 0.055456,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "8501110089852",
          alterno1: "AS1070BA06E/EN2",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["663"],
          nombre_articulo: "RO HAVANA SELECCIN MAESTROS",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L116750027",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 1,
          cantidad_disponible: 0,
          total_peso_bruto: 1.58,
          total_peso: 1.58,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002411,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "8501110089852",
          alterno1: "AS1070BA06E/EN2",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1291JLOTE05711",
          documento1: "HLCUME3211128586",
          documento2: "918581542",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-12-15",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.35,
          total_peso: 1.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001472,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1310JLOTE05742",
          documento1: "MEDUM5602401",
          documento2: "918583047",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.35,
          total_peso: 1.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001472,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1324LOTE05787",
          documento1: "HLCUME3211172654",
          documento2: "918585347",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 636,
          cantidad_por_alistar: 0,
          cantidad_disponible: 636,
          total_peso_bruto: 858.6,
          total_peso: 858.6,
          unidad_medida_peso: "kilos",
          total_volumen: 0.936401,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2006JLOTE05862",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 5616,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5616,
          total_peso_bruto: 7581.6,
          total_peso: 7581.6,
          unidad_medida_peso: "kilos",
          total_volumen: 8.268597,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2031JLOTE05907",
          documento1: "216205230",
          documento2: "918599375",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-15",
          valor_mercado: "0.000000",
          cantidad: 360,
          cantidad_por_alistar: 0,
          cantidad_disponible: 360,
          total_peso_bruto: 486,
          total_peso: 486,
          unidad_medida_peso: "kilos",
          total_volumen: 0.530038,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2031JLOTE05907",
          documento1: "216194092",
          documento2: "918599376",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 7964,
          cantidad_por_alistar: 276,
          cantidad_disponible: 7688,
          total_peso_bruto: 10751.4,
          total_peso: 10751.4,
          unidad_medida_peso: "kilos",
          total_volumen: 11.725623,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2049JLOTE05946",
          documento1: "HLCUME3220254577",
          documento2: "918600475",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-15",
          valor_mercado: "0.000000",
          cantidad: 6648,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6648,
          total_peso_bruto: 8974.8,
          total_peso: 8974.8,
          unidad_medida_peso: "kilos",
          total_volumen: 9.78804,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2049JLOTE05946",
          documento1: "HLCUME3220254577",
          documento2: "918600475",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 2400,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2400,
          total_peso_bruto: 3240,
          total_peso: 3240,
          unidad_medida_peso: "kilos",
          total_volumen: 3.533589,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["665"],
          nombre_articulo: "TE OLMECA SILVER 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2049JLOTE05947",
          documento1: "HLCUME3220254577",
          documento2: "918600475",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-15",
          valor_mercado: "0.000000",
          cantidad: 3432,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3432,
          total_peso_bruto: 4633.2,
          total_peso: 4633.2,
          unidad_medida_peso: "kilos",
          total_volumen: 5.053031,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402191",
          alterno1: "19829",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["7"],
          nombre_articulo: "WH CHIVAS 12 / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LC6R1343",
          documento1: "HLCULIV211243147",
          documento2: "918593532",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 5640,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5640,
          total_peso_bruto: 733.2,
          total_peso: 733.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.883579,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402504",
          alterno1: "CS30002",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["7"],
          nombre_articulo: "WH CHIVAS 12 / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LC6R1369",
          documento1: "HLCULIV211243147",
          documento2: "918593532",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 3480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3480,
          total_peso_bruto: 452.4,
          total_peso: 452.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.545187,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402504",
          alterno1: "CS30002",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["7"],
          nombre_articulo: "WH CHIVAS 12 / 50",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LC6R1369",
          documento1: "HLCULIV211243147",
          documento2: "918593532",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 2150,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2150,
          total_peso_bruto: 279.5,
          total_peso: 279.5,
          unidad_medida_peso: "kilos",
          total_volumen: 0.336825,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402504",
          alterno1: "CS30002",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR0590",
          documento1: "HLCULIV210226567",
          documento2: "918528395",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-03-26",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 4.62,
          total_peso: 4.62,
          unidad_medida_peso: "kilos",
          total_volumen: 0.00533,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1115",
          documento1: "HLCULIV210329815",
          documento2: "918533037",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 4,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4,
          total_peso_bruto: 6.16,
          total_peso: 6.16,
          unidad_medida_peso: "kilos",
          total_volumen: 0.007107,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2171",
          documento1: "HLCULIV210689321",
          documento2: "918552646",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-25",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.54,
          total_peso: 1.54,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001777,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3662",
          documento1: "HLCULIV210790387",
          documento2: "918562273",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 1900,
          cantidad_por_alistar: 12,
          cantidad_disponible: 1888,
          total_peso_bruto: 2926,
          total_peso: 2926,
          unidad_medida_peso: "kilos",
          total_volumen: 3.37535,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3720",
          documento1: "HLCULIV220190327",
          documento2: "918599374",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 18.48,
          total_peso: 18.48,
          unidad_medida_peso: "kilos",
          total_volumen: 0.021318,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3720",
          documento1: "HLCULIV210790387",
          documento2: "918562273",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 2028,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2028,
          total_peso_bruto: 3123.12,
          total_peso: 3123.12,
          unidad_medida_peso: "kilos",
          total_volumen: 3.602742,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR4517",
          documento1: "HLCULIV210839812",
          documento2: "918564226",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-09-14",
          valor_mercado: "0.000000",
          cantidad: 6600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6600,
          total_peso_bruto: 10164,
          total_peso: 10164,
          unidad_medida_peso: "kilos",
          total_volumen: 11.7249,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR4517",
          documento1: "HLCULIV220190327",
          documento2: "918599374",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 18.48,
          total_peso: 18.48,
          unidad_medida_peso: "kilos",
          total_volumen: 0.021318,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6883",
          documento1: "HLCULIV2111APIO0",
          documento2: "918584574",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 6600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6600,
          total_peso_bruto: 10164,
          total_peso: 10164,
          unidad_medida_peso: "kilos",
          total_volumen: 11.7249,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6883",
          documento1: "HLCULIV211200311",
          documento2: "918585791",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-23",
          valor_mercado: "0.000000",
          cantidad: 6600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6600,
          total_peso_bruto: 10164,
          total_peso: 10164,
          unidad_medida_peso: "kilos",
          total_volumen: 11.7249,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6883",
          documento1: "HLCULIV2111ARLK3",
          documento2: "918585849",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-24",
          valor_mercado: "0.000000",
          cantidad: 660,
          cantidad_por_alistar: 0,
          cantidad_disponible: 660,
          total_peso_bruto: 1016.4,
          total_peso: 1016.4,
          unidad_medida_peso: "kilos",
          total_volumen: 1.17249,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR6883",
          documento1: "HLCULIV211173539",
          documento2: "918583393",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 1320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1320,
          total_peso_bruto: 2032.8,
          total_peso: 2032.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.34498,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0264",
          documento1: "HLCULIV220190327",
          documento2: "918599374",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 1584,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1584,
          total_peso_bruto: 2439.36,
          total_peso: 2439.36,
          unidad_medida_peso: "kilos",
          total_volumen: 2.813976,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0264",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 1320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1320,
          total_peso_bruto: 2032.8,
          total_peso: 2032.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.34498,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0273",
          documento1: "HLCULIV220190327",
          documento2: "918599374",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 5400,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5400,
          total_peso_bruto: 8316,
          total_peso: 8316,
          unidad_medida_peso: "kilos",
          total_volumen: 9.5931,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["701"],
          nombre_articulo: "WH PASSPORT / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS0273",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 660,
          cantidad_por_alistar: 0,
          cantidad_disponible: 660,
          total_peso_bruto: 1016.4,
          total_peso: 1016.4,
          unidad_medida_peso: "kilos",
          total_volumen: 1.17249,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210079",
          alterno1: "PV06012",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR0255",
          documento1: "HLCULIV210169350",
          documento2: "918524848",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-03-25",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.12,
          total_peso: 1.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001102,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1622",
          documento1: "HLCULIV210397953",
          documento2: "918535839",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 5,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5,
          total_peso_bruto: 5.6,
          total_peso: 5.6,
          unidad_medida_peso: "kilos",
          total_volumen: 0.005508,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2576",
          documento1: "HLCULIV210526316",
          documento2: "918545839",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-19",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 3.36,
          total_peso: 3.36,
          unidad_medida_peso: "kilos",
          total_volumen: 0.003305,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2576",
          documento1: "HLCULIV210526444",
          documento2: "918545797",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-01-03",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.12,
          total_peso: 1.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001102,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2576",
          documento1: "HLCULIV210568518",
          documento2: "918545796",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 4144,
          cantidad_por_alistar: 300,
          cantidad_disponible: 3844,
          total_peso_bruto: 4641.28,
          total_peso: 4641.28,
          unidad_medida_peso: "kilos",
          total_volumen: 4.56503,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV210827251",
          documento2: "918561555",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-31",
          valor_mercado: "0.000000",
          cantidad: 696,
          cantidad_por_alistar: 0,
          cantidad_disponible: 696,
          total_peso_bruto: 779.52,
          total_peso: 779.52,
          unidad_medida_peso: "kilos",
          total_volumen: 0.766714,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV210790650",
          documento2: "918561646",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-31",
          valor_mercado: "0.000000",
          cantidad: 2879,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2879,
          total_peso_bruto: 3224.48,
          total_peso: 3224.48,
          unidad_medida_peso: "kilos",
          total_volumen: 3.171507,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV210790650",
          documento2: "918561646",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2021-08-31",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.12,
          total_peso: 1.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001102,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV220130720",
          documento2: "918593536",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 36,
          cantidad_por_alistar: 0,
          cantidad_disponible: 36,
          total_peso_bruto: 40.32,
          total_peso: 40.32,
          unidad_medida_peso: "kilos",
          total_volumen: 0.039658,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV210722640",
          documento2: "918558532",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 7200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7200,
          total_peso_bruto: 8064,
          total_peso: 8064,
          unidad_medida_peso: "kilos",
          total_volumen: 7.93152,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV210775004",
          documento2: "918561093",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 3576,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3576,
          total_peso_bruto: 4005.12,
          total_peso: 4005.12,
          unidad_medida_peso: "kilos",
          total_volumen: 3.939321,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCULIV210775004",
          documento2: "918561093",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 24,
          cantidad_por_alistar: 0,
          cantidad_disponible: 24,
          total_peso_bruto: 26.88,
          total_peso: 26.88,
          unidad_medida_peso: "kilos",
          total_volumen: 0.026438,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3898",
          documento1: "HLCULIV210827251",
          documento2: "918561555",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-31",
          valor_mercado: "0.000000",
          cantidad: 6504,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6504,
          total_peso_bruto: 7284.48,
          total_peso: 7284.48,
          unidad_medida_peso: "kilos",
          total_volumen: 7.164805,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3898",
          documento1: "HLCULIV210790650",
          documento2: "918561646",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-31",
          valor_mercado: "0.000000",
          cantidad: 7920,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7920,
          total_peso_bruto: 8870.4,
          total_peso: 8870.4,
          unidad_medida_peso: "kilos",
          total_volumen: 8.724672,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3898",
          documento1: "HLCUMEA210711637",
          documento2: "918561652",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-09-01",
          valor_mercado: "0.000000",
          cantidad: 3600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3600,
          total_peso_bruto: 4032,
          total_peso: 4032,
          unidad_medida_peso: "kilos",
          total_volumen: 3.96576,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-10",
          valor_mercado: "0.000000",
          cantidad: 1800,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1800,
          total_peso_bruto: 2016,
          total_peso: 2016,
          unidad_medida_peso: "kilos",
          total_volumen: 1.98288,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV2111APIS4",
          documento2: "918584563",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 6479,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6479,
          total_peso_bruto: 7256.48,
          total_peso: 7256.48,
          unidad_medida_peso: "kilos",
          total_volumen: 7.137266,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV2111APIS4",
          documento2: "918584563",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.12,
          total_peso: 1.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001102,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV211228170",
          documento2: "918586808",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-03",
          valor_mercado: "0.000000",
          cantidad: 15119,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15119,
          total_peso_bruto: 16933.28,
          total_peso: 16933.28,
          unidad_medida_peso: "kilos",
          total_volumen: 16.65509,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV211228170",
          documento2: "918586808",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-01-03",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.12,
          total_peso: 1.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001102,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-16",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.24,
          total_peso: 2.24,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002203,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7051",
          documento1: "HLCULIV220130720",
          documento2: "918593536",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 6204,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6204,
          total_peso_bruto: 6948.48,
          total_peso: 6948.48,
          unidad_medida_peso: "kilos",
          total_volumen: 6.834326,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR7304",
          documento1: "HLCULIV220130720",
          documento2: "918593536",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 876,
          cantidad_por_alistar: 0,
          cantidad_disponible: 876,
          total_peso_bruto: 981.12,
          total_peso: 981.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.965002,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS1054",
          documento1: "HLCULIV220214380",
          documento2: "918602534",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-28",
          valor_mercado: "0.000000",
          cantidad: 8627,
          cantidad_por_alistar: 0,
          cantidad_disponible: 8627,
          total_peso_bruto: 9662.24,
          total_peso: 9662.24,
          unidad_medida_peso: "kilos",
          total_volumen: 9.503503,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["702"],
          nombre_articulo: "WH PASSPORT / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBS1054",
          documento1: "HLCULIV220214380",
          documento2: "918602534",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-28",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.12,
          total_peso: 1.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001102,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210048",
          alterno1: "PV09013",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMP5873",
          documento1: "HLCULIV201164775",
          documento2: "918514677",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-04-18",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.42,
          total_peso: 0.42,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000442,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR1041",
          documento1: "HLCULIV210306825",
          documento2: "918530773",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.42,
          total_peso: 0.42,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000442,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2049",
          documento1: "HLCULIV210475880",
          documento2: "918542986",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-22",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.42,
          total_peso: 0.42,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000442,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2049",
          documento1: "HLCULIV210761888",
          documento2: "918558201",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-11-16",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.42,
          total_peso: 0.42,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000442,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3141",
          documento1: "HLCULIV210826990",
          documento2: "918561926",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1200,
          total_peso_bruto: 504,
          total_peso: 504,
          unidad_medida_peso: "kilos",
          total_volumen: 0.530669,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3141",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-02-10",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 1.26,
          total_peso: 1.26,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001327,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3141",
          documento1: "HLCULIV211242495",
          documento2: "918593527918593527",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 144,
          cantidad_por_alistar: 0,
          cantidad_disponible: 144,
          total_peso_bruto: 60.48,
          total_peso: 60.48,
          unidad_medida_peso: "kilos",
          total_volumen: 0.06368,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3141",
          documento1: "HLCULIV211273102",
          documento2: "918594963",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 7026,
          cantidad_por_alistar: 48,
          cantidad_disponible: 6978,
          total_peso_bruto: 2950.92,
          total_peso: 2950.92,
          unidad_medida_peso: "kilos",
          total_volumen: 3.107066,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3141",
          documento1: "HLCULIV211273102",
          documento2: "918594963",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 1.26,
          total_peso: 1.26,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001327,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3141",
          documento1: "HLCULIV211242495",
          documento2: "918593527918593527",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0.84,
          total_peso: 0.84,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000884,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR4202",
          documento1: "HLCULIV211273102",
          documento2: "918594963",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 9023,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9023,
          total_peso_bruto: 3789.66,
          total_peso: 3789.66,
          unidad_medida_peso: "kilos",
          total_volumen: 3.990187,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 5998,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5998,
          total_peso_bruto: 2519.16,
          total_peso: 2519.16,
          unidad_medida_peso: "kilos",
          total_volumen: 2.65246,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220192271",
          documento2: "918599016",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 63360,
          cantidad_por_alistar: 0,
          cantidad_disponible: 63360,
          total_peso_bruto: 26611.2,
          total_peso: 26611.2,
          unidad_medida_peso: "kilos",
          total_volumen: 28.01931,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-10",
          valor_mercado: "0.000000",
          cantidad: 9118,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9118,
          total_peso_bruto: 3829.56,
          total_peso: 3829.56,
          unidad_medida_peso: "kilos",
          total_volumen: 4.032198,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-11",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0.84,
          total_peso: 0.84,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000884,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220190360",
          documento2: "9018600221",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 21120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 21120,
          total_peso_bruto: 8870.4,
          total_peso: 8870.4,
          unidad_medida_peso: "kilos",
          total_volumen: 9.33977,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 3600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3600,
          total_peso_bruto: 1512,
          total_peso: 1512,
          unidad_medida_peso: "kilos",
          total_volumen: 1.592006,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.42,
          total_peso: 0.42,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000442,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220191787",
          documento2: "918602054",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 5759,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5759,
          total_peso_bruto: 2418.78,
          total_peso: 2418.78,
          unidad_medida_peso: "kilos",
          total_volumen: 2.546768,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220191586",
          documento2: "918598990",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-30",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0.84,
          total_peso: 0.84,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000884,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["703"],
          nombre_articulo: "WH PASSPORT / 200",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMS0154",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 12000,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12000,
          total_peso_bruto: 5040,
          total_peso: 5040,
          unidad_medida_peso: "kilos",
          total_volumen: 5.306688,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210208",
          alterno1: "PV22006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1309JLOTE05740",
          documento1: "MEDUM5602377",
          documento2: "918583070",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-12-20",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.35,
          total_peso: 1.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001472,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1324JLOTE05786",
          documento1: "HLCUME3211172654",
          documento2: "918585347",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 2352,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2352,
          total_peso_bruto: 3175.2,
          total_peso: 3175.2,
          unidad_medida_peso: "kilos",
          total_volumen: 3.462915,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1324JLOTE05786",
          documento1: "HLCUME3211172654",
          documento2: "918585347",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.35,
          total_peso: 1.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001472,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1324JLOTE05790",
          documento1: "HLCUME3211172705",
          documento2: "918590091",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.35,
          total_peso: 1.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001472,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1324JLOTE05790",
          documento1: "HLCUME3211172705",
          documento2: "918590091",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-02-08",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.7,
          total_peso: 2.7,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002945,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1335JLOTE05801",
          documento1: "HLCUME3211233415",
          documento2: "918590842",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-02-09",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.35,
          total_peso: 1.35,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001472,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2010JLOTE05868",
          documento1: "HLCUME3220166180",
          documento2: "918596207",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 192,
          cantidad_por_alistar: 0,
          cantidad_disponible: 192,
          total_peso_bruto: 259.2,
          total_peso: 259.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.282687,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2032JLOTE05912",
          documento1: "HLCUME3220231320",
          documento2: "918600484",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-29",
          valor_mercado: "0.000000",
          cantidad: 252,
          cantidad_por_alistar: 0,
          cantidad_disponible: 252,
          total_peso_bruto: 340.2,
          total_peso: 340.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.371027,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2032JLOTE05916",
          documento1: "HLCUME3220231320",
          documento2: "918600484",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 5,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5,
          total_peso_bruto: 6.75,
          total_peso: 6.75,
          unidad_medida_peso: "kilos",
          total_volumen: 0.007362,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2032JLOTE05916",
          documento1: "HLCUME3220231320",
          documento2: "918600484",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-29",
          valor_mercado: "0.000000",
          cantidad: 432,
          cantidad_por_alistar: 0,
          cantidad_disponible: 432,
          total_peso_bruto: 583.2,
          total_peso: 583.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.636046,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2050JLOTE05948",
          documento1: "ABTCWS011798",
          documento2: "918601657",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 11602,
          cantidad_por_alistar: 204,
          cantidad_disponible: 11398,
          total_peso_bruto: 15662.7,
          total_peso: 15662.7,
          unidad_medida_peso: "kilos",
          total_volumen: 17.081949,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2061JLOTE05968",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 1008,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1008,
          total_peso_bruto: 1360.8,
          total_peso: 1360.8,
          unidad_medida_peso: "kilos",
          total_volumen: 1.484107,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2062JLOTE05968",
          documento1: "ABTCWS011807",
          documento2: "918603336",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-30",
          valor_mercado: "0.000000",
          cantidad: 5040,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5040,
          total_peso_bruto: 6804,
          total_peso: 6804,
          unidad_medida_peso: "kilos",
          total_volumen: 7.420536,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2062JLOTE05968",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 2112,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2112,
          total_peso_bruto: 2851.2,
          total_peso: 2851.2,
          unidad_medida_peso: "kilos",
          total_volumen: 3.109558,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2062JLOTE05968",
          documento1: "ABTCWS011816",
          documento2: "918603331",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 10440,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10440,
          total_peso_bruto: 14094,
          total_peso: 14094,
          unidad_medida_peso: "kilos",
          total_volumen: 15.37111,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2062JLOTE05968",
          documento1: "ABTCWS011807",
          documento2: "918603336",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 7440,
          cantidad_por_alistar: 396,
          cantidad_disponible: 7044,
          total_peso_bruto: 10044,
          total_peso: 10044,
          unidad_medida_peso: "kilos",
          total_volumen: 10.954123,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["724"],
          nombre_articulo: "TE OLMECA REPOSADO / 700 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2062JLOTE05977",
          documento1: "ABTCWS011816",
          documento2: "918603331",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 2040,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2040,
          total_peso_bruto: 2754,
          total_peso: 2754,
          unidad_medida_peso: "kilos",
          total_volumen: 3.00355,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432108703",
          alterno1: "19372",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["741"],
          nombre_articulo: "TE OLMECA  REPOSADO / 350 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1308JLOTE05740",
          documento1: "MEDUM5602377",
          documento2: "918583070",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-20",
          valor_mercado: "0.000000",
          cantidad: 372,
          cantidad_por_alistar: 0,
          cantidad_disponible: 372,
          total_peso_bruto: 100.44,
          total_peso: 100.44,
          unidad_medida_peso: "kilos",
          total_volumen: 0.283763,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432109663",
          alterno1: "20397",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["741"],
          nombre_articulo: "TE OLMECA  REPOSADO / 350 35",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2062JLOTE05977",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 2640,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2640,
          total_peso_bruto: 712.8,
          total_peso: 712.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.013805,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432109663",
          alterno1: "20397",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["801000"],
          nombre_articulo: "POP REVISTA MONKEY 1 EDICION",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "STX2069898/001",
          documento2: "918436387",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2019-08-15",
          valor_mercado: "0.000000",
          cantidad: 60,
          cantidad_por_alistar: 0,
          cantidad_disponible: 60,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "801000",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["801001"],
          nombre_articulo: "POP REVISTA MONKEY 2 EDICION",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "STX2069898/001",
          documento2: "918436387",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2019-08-15",
          valor_mercado: "0.000000",
          cantidad: 60,
          cantidad_por_alistar: 0,
          cantidad_disponible: 60,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "801001",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["818"],
          nombre_articulo: "VO WYBOROWA / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "LZ21111572",
          documento1: "GDY0263464",
          documento2: "918587007",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-26",
          valor_mercado: "0.000000",
          cantidad: 30,
          cantidad_por_alistar: 0,
          cantidad_disponible: 30,
          total_peso_bruto: 48.6,
          total_peso: 48.6,
          unidad_medida_peso: "kilos",
          total_volumen: 0.07385,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5900685006067",
          alterno1: "2WW0004COS002",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211011E",
          documento1: "GTG0309939",
          documento2: "918584857",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-27",
          valor_mercado: "0.000000",
          cantidad: 11,
          cantidad_por_alistar: 0,
          cantidad_disponible: 11,
          total_peso_bruto: 13.75,
          total_peso: 13.75,
          unidad_medida_peso: "kilos",
          total_volumen: 0.018023,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211208L",
          documento1: "GTG0312633",
          documento2: "918593305",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-01",
          valor_mercado: "0.000000",
          cantidad: 9,
          cantidad_por_alistar: 0,
          cantidad_disponible: 9,
          total_peso_bruto: 11.25,
          total_peso: 11.25,
          unidad_medida_peso: "kilos",
          total_volumen: 0.014746,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220124E",
          documento1: "CMDUGTG0315749",
          documento2: "918599303",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 768,
          cantidad_por_alistar: 0,
          cantidad_disponible: 768,
          total_peso_bruto: 960,
          total_peso: 960,
          unidad_medida_peso: "kilos",
          total_volumen: 1.258303,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220124E",
          documento1: "CMDUGTG0315728",
          documento2: "918599898",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 816,
          cantidad_por_alistar: 0,
          cantidad_disponible: 816,
          total_peso_bruto: 1020,
          total_peso: 1020,
          unidad_medida_peso: "kilos",
          total_volumen: 1.336947,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220124E",
          documento1: "GTG0316449",
          documento2: "918600473",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-24",
          valor_mercado: "0.000000",
          cantidad: 7920,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7920,
          total_peso_bruto: 9900,
          total_peso: 9900,
          unidad_medida_peso: "kilos",
          total_volumen: 12.976248,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220124E",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 6600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6600,
          total_peso_bruto: 8250,
          total_peso: 8250,
          unidad_medida_peso: "kilos",
          total_volumen: 10.81354,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["833"],
          nombre_articulo: "VO ABSOLUT VODKA / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220124E",
          documento1: "GTG0315767",
          documento2: "918600472",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 9852,
          cantidad_por_alistar: 432,
          cantidad_disponible: 9420,
          total_peso_bruto: 12315,
          total_peso: 12315,
          unidad_medida_peso: "kilos",
          total_volumen: 16.14167,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017683",
          alterno1: "11190019",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["834"],
          nombre_articulo: "VO ABSOLUT VODKA / 350",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211116H",
          documento1: "GTG0311392",
          documento2: "918586757",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-03",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.67,
          total_peso: 0.67,
          unidad_medida_peso: "kilos",
          total_volumen: 0.000862,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017355",
          alterno1: "1118871E",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["834"],
          nombre_articulo: "VO ABSOLUT VODKA / 350",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220112H",
          documento1: "CMDUGTG0315728",
          documento2: "918599898",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 4416,
          cantidad_por_alistar: 48,
          cantidad_disponible: 4368,
          total_peso_bruto: 2958.72,
          total_peso: 2958.72,
          unidad_medida_peso: "kilos",
          total_volumen: 3.80808,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017355",
          alterno1: "1118871E",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["834"],
          nombre_articulo: "VO ABSOLUT VODKA / 350",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220112H",
          documento1: "GTG0316449",
          documento2: "918600473",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-24",
          valor_mercado: "0.000000",
          cantidad: 2304,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2304,
          total_peso_bruto: 1543.68,
          total_peso: 1543.68,
          unidad_medida_peso: "kilos",
          total_volumen: 1.986824,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017355",
          alterno1: "1118871E",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["834"],
          nombre_articulo: "VO ABSOLUT VODKA / 350",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220119H",
          documento1: "GTG0316449",
          documento2: "918600473",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-24",
          valor_mercado: "0.000000",
          cantidad: 1152,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1152,
          total_peso_bruto: 771.84,
          total_peso: 771.84,
          unidad_medida_peso: "kilos",
          total_volumen: 0.993412,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017355",
          alterno1: "1118871E",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["834"],
          nombre_articulo: "VO ABSOLUT VODKA / 350",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220120H",
          documento1: "GTG0316449",
          documento2: "918600473",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-24",
          valor_mercado: "0.000000",
          cantidad: 5760,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5760,
          total_peso_bruto: 3859.2,
          total_peso: 3859.2,
          unidad_medida_peso: "kilos",
          total_volumen: 4.96706,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "7312040017355",
          alterno1: "1118871E",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDR7273",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-21",
          valor_mercado: "0.000000",
          cantidad: 768,
          cantidad_por_alistar: 0,
          cantidad_disponible: 768,
          total_peso_bruto: 936.96,
          total_peso: 936.96,
          unidad_medida_peso: "kilos",
          total_volumen: 0.99821,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0385",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-09",
          valor_mercado: "0.000000",
          cantidad: 1500,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1500,
          total_peso_bruto: 1830,
          total_peso: 1830,
          unidad_medida_peso: "kilos",
          total_volumen: 1.94963,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0385",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 1320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1320,
          total_peso_bruto: 1610.4,
          total_peso: 1610.4,
          unidad_medida_peso: "kilos",
          total_volumen: 1.715674,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0385",
          documento1: "HLCULIV220190254",
          documento2: "918602034",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 11220,
          cantidad_por_alistar: 0,
          cantidad_disponible: 11220,
          total_peso_bruto: 13688.4,
          total_peso: 13688.4,
          unidad_medida_peso: "kilos",
          total_volumen: 14.583232,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0395",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 1020,
          cantidad_por_alistar: 24,
          cantidad_disponible: 996,
          total_peso_bruto: 1244.4,
          total_peso: 1244.4,
          unidad_medida_peso: "kilos",
          total_volumen: 1.325747,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0395",
          documento1: "HLCULIV220190254",
          documento2: "918602034",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 2040,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2040,
          total_peso_bruto: 2488.8,
          total_peso: 2488.8,
          unidad_medida_peso: "kilos",
          total_volumen: 2.651497,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0764",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 1583,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1583,
          total_peso_bruto: 1931.26,
          total_peso: 1931.26,
          unidad_medida_peso: "kilos",
          total_volumen: 2.057509,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["839"],
          nombre_articulo: "GI BEEFEATER / 700 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKDS0764",
          documento1: "HLCULIV220190254",
          documento2: "918602034",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 6120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6120,
          total_peso_bruto: 7466.4,
          total_peso: 7466.4,
          unidad_medida_peso: "kilos",
          total_volumen: 7.95449,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000329002193",
          alterno1: "FX09060",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["840"],
          nombre_articulo: "GI BEEFEATER 24 / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHR6430",
          documento1: "HLCULIV220130720",
          documento2: "918593536",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 354,
          cantidad_por_alistar: 54,
          cantidad_disponible: 300,
          total_peso_bruto: 555.78,
          total_peso: 555.78,
          unidad_medida_peso: "kilos",
          total_volumen: 0.545603,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299605004",
          alterno1: "FG09025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR0583",
          documento1: "HLCULIV210207874",
          documento2: "918450305",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-05-10",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 4.11,
          total_peso: 4.11,
          unidad_medida_peso: "kilos",
          total_volumen: 0.005885,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR2928",
          documento1: "HLCULIV210745166",
          documento2: "918562985",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-10-06",
          valor_mercado: "0.000000",
          cantidad: 4,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4,
          total_peso_bruto: 5.48,
          total_peso: 5.48,
          unidad_medida_peso: "kilos",
          total_volumen: 0.007847,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR7884",
          documento1: "HLCULIV220136844",
          documento2: "918599372",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-17",
          valor_mercado: "0.000000",
          cantidad: 10830,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10830,
          total_peso_bruto: 14837.1,
          total_peso: 14837.1,
          unidad_medida_peso: "kilos",
          total_volumen: 21.245626,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR7884",
          documento1: "HLCULIV220136844",
          documento2: "918599372",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 1770,
          cantidad_por_alistar: 668,
          cantidad_disponible: 1102,
          total_peso_bruto: 2424.9,
          total_peso: 2424.9,
          unidad_medida_peso: "kilos",
          total_volumen: 3.472277,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZS0166",
          documento1: "HLCULIV220259430",
          documento2: "918596651",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 2608,
          cantidad_por_alistar: 1960,
          cantidad_disponible: 648,
          total_peso_bruto: 3572.96,
          total_peso: 3572.96,
          unidad_medida_peso: "kilos",
          total_volumen: 5.116214,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZS0838",
          documento1: "HLCULIV220214347",
          documento2: "918603347",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1200,
          total_peso_bruto: 1644,
          total_peso: 1644,
          unidad_medida_peso: "kilos",
          total_volumen: 2.354086,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZS0838",
          documento1: "HLCULIV220241801",
          documento2: "918604999",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 7200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7200,
          total_peso_bruto: 9864,
          total_peso: 9864,
          unidad_medida_peso: "kilos",
          total_volumen: 14.124516,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZS1106",
          documento1: "HLCULIV220214347",
          documento2: "918603347",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 11397,
          cantidad_por_alistar: 0,
          cantidad_disponible: 11397,
          total_peso_bruto: 15613.89,
          total_peso: 15613.89,
          unidad_medida_peso: "kilos",
          total_volumen: 22.357932,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZS1106",
          documento1: "HLCULIV220214347",
          documento2: "918603347",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-04-01",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 4.11,
          total_peso: 4.11,
          unidad_medida_peso: "kilos",
          total_volumen: 0.005885,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZS1106",
          documento1: "HLCULIV220241801",
          documento2: "918604999",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 5400,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5400,
          total_peso_bruto: 7398,
          total_peso: 7398,
          unidad_medida_peso: "kilos",
          total_volumen: 10.593387,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["841"],
          nombre_articulo: "WH THE GLENLIVET FOUND RE 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LPCN1302",
          documento1: "HLCULIV191013436",
          documento2: "918447256",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-04-07",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.37,
          total_peso: 1.37,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001962,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299609347",
          alterno1: "FF09050",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["842"],
          nombre_articulo: "WH THE GLENLIVET 15 / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR5812",
          documento1: "HLCULIV211045219",
          documento2: "918575754",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-29",
          valor_mercado: "0.000000",
          cantidad: 30,
          cantidad_por_alistar: 0,
          cantidad_disponible: 30,
          total_peso_bruto: 41.1,
          total_peso: 41.1,
          unidad_medida_peso: "kilos",
          total_volumen: 0.058852,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299295021",
          alterno1: "LC09064",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR7284",
          documento1: "HLCULIV2111APIO0",
          documento2: "918584574",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-10",
          valor_mercado: "0.000000",
          cantidad: 60,
          cantidad_por_alistar: 0,
          cantidad_disponible: 60,
          total_peso_bruto: 70.2,
          total_peso: 70.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.081583,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR7284",
          documento1: "HLCULIV220130720",
          documento2: "918593536",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 972,
          cantidad_por_alistar: 0,
          cantidad_disponible: 972,
          total_peso_bruto: 1137.24,
          total_peso: 1137.24,
          unidad_medida_peso: "kilos",
          total_volumen: 1.32165,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR7773",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-17",
          valor_mercado: "0.000000",
          cantidad: 252,
          cantidad_por_alistar: 0,
          cantidad_disponible: 252,
          total_peso_bruto: 294.84,
          total_peso: 294.84,
          unidad_medida_peso: "kilos",
          total_volumen: 0.34265,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR7773",
          documento1: "HLCULIV220241761",
          documento2: "918605008",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 72,
          cantidad_por_alistar: 0,
          cantidad_disponible: 72,
          total_peso_bruto: 84.24,
          total_peso: 84.24,
          unidad_medida_peso: "kilos",
          total_volumen: 0.0979,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR7773",
          documento1: "HLCULIV220130720",
          documento2: "918593536",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 1667,
          cantidad_por_alistar: 516,
          cantidad_disponible: 1151,
          total_peso_bruto: 1950.39,
          total_peso: 1950.39,
          unidad_medida_peso: "kilos",
          total_volumen: 2.266656,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVS0129",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-17",
          valor_mercado: "0.000000",
          cantidad: 10547,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10547,
          total_peso_bruto: 12339.99,
          total_peso: 12339.99,
          unidad_medida_peso: "kilos",
          total_volumen: 14.340989,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVS0129",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-17",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.17,
          total_peso: 1.17,
          unidad_medida_peso: "kilos",
          total_volumen: 0.00136,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVS0129",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 2700,
          cantidad_por_alistar: 588,
          cantidad_disponible: 2112,
          total_peso_bruto: 3159,
          total_peso: 3159,
          unidad_medida_peso: "kilos",
          total_volumen: 3.671249,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["845"],
          nombre_articulo: "WH BALLANTINES FINEST / 700 E",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVS1270",
          documento1: "HLCULIV220241761",
          documento2: "918605008",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 13428,
          cantidad_por_alistar: 0,
          cantidad_disponible: 13428,
          total_peso_bruto: 15710.76,
          total_peso: 15710.76,
          unidad_medida_peso: "kilos",
          total_volumen: 18.25835,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106113073",
          alterno1: "B309220",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["851"],
          nombre_articulo: "WH CHIVAS EXTRAS 24/200ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPP5307",
          documento1: "HLCULIV210169350",
          documento2: "918524848",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-02-26",
          valor_mercado: "0.000000",
          cantidad: 4182,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4182,
          total_peso_bruto: 1672.8,
          total_peso: 1672.8,
          unidad_medida_peso: "kilos",
          total_volumen: 81.509794,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299611173",
          alterno1: "851",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["857"],
          nombre_articulo: "WH BALLANTINES 12 / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKAN4350",
          documento1: "RVO2212",
          documento2: "918598837",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-09",
          valor_mercado: "0.000000",
          cantidad: 120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 120,
          total_peso_bruto: 166.8,
          total_peso: 166.8,
          unidad_medida_peso: "kilos",
          total_volumen: 0.178215,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5010106110225",
          alterno1: "BC09077",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["861"],
          nombre_articulo: "WH JAMESON IRISH W. STD / 350G",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "L130912",
          documento1: "HLCUDUB220111854",
          documento2: "918600219",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 1920,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1920,
          total_peso_bruto: 1420.8,
          total_peso: 1420.8,
          unidad_medida_peso: "kilos",
          total_volumen: 1.8525,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5011007003654",
          alterno1: "10108923",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["861"],
          nombre_articulo: "WH JAMESON IRISH W. STD / 350G",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "L130912",
          documento1: "HLCUDUB220111854",
          documento2: "918600219",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 372,
          cantidad_por_alistar: 0,
          cantidad_disponible: 372,
          total_peso_bruto: 275.28,
          total_peso: 275.28,
          unidad_medida_peso: "kilos",
          total_volumen: 0.358922,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5011007003654",
          alterno1: "10108923",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["865"],
          nombre_articulo: "WH JAMESON ESTANDAR / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "L126616",
          documento1: "DUBA23658",
          documento2: "918596232",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.19,
          total_peso: 1.19,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001971,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5011007003005",
          alterno1: "10112437",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["865"],
          nombre_articulo: "WH JAMESON ESTANDAR / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "L202412",
          documento1: "DUBA24359",
          documento2: "918600219",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 3960,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3960,
          total_peso_bruto: 4712.4,
          total_peso: 4712.4,
          unidad_medida_peso: "kilos",
          total_volumen: 7.805952,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5011007003005",
          alterno1: "10112437",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["865"],
          nombre_articulo: "WH JAMESON ESTANDAR / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "L202412",
          documento1: "DUBA24362",
          documento2: "918599590",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-16",
          valor_mercado: "0.000000",
          cantidad: 16800,
          cantidad_por_alistar: 0,
          cantidad_disponible: 16800,
          total_peso_bruto: 19992,
          total_peso: 19992,
          unidad_medida_peso: "kilos",
          total_volumen: 33.11616,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5011007003005",
          alterno1: "10112437",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["865"],
          nombre_articulo: "WH JAMESON ESTANDAR / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "L202412",
          documento1: "DUBA24359",
          documento2: "918600219",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 8820,
          cantidad_por_alistar: 384,
          cantidad_disponible: 8436,
          total_peso_bruto: 10495.8,
          total_peso: 10495.8,
          unidad_medida_peso: "kilos",
          total_volumen: 17.385984,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5011007003005",
          alterno1: "10112437",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["868"],
          nombre_articulo: "AP LILLET BLANC / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1257C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-26",
          valor_mercado: "0.000000",
          cantidad: 600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 600,
          total_peso_bruto: 852,
          total_peso: 852,
          unidad_medida_peso: "kilos",
          total_volumen: 1.115923,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000253",
          alterno1: "719018",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["868"],
          nombre_articulo: "AP LILLET BLANC / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1257C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 828,
          cantidad_por_alistar: 0,
          cantidad_disponible: 828,
          total_peso_bruto: 1175.76,
          total_peso: 1175.76,
          unidad_medida_peso: "kilos",
          total_volumen: 1.539974,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000253",
          alterno1: "719018",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["868"],
          nombre_articulo: "AP LILLET BLANC / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1271C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-26",
          valor_mercado: "0.000000",
          cantidad: 1798,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1798,
          total_peso_bruto: 2553.16,
          total_peso: 2553.16,
          unidad_medida_peso: "kilos",
          total_volumen: 3.344049,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000253",
          alterno1: "719018",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["868"],
          nombre_articulo: "AP LILLET BLANC / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1271C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-01-03",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2.84,
          total_peso: 2.84,
          unidad_medida_peso: "kilos",
          total_volumen: 0.00372,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000253",
          alterno1: "719018",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["868"],
          nombre_articulo: "AP LILLET BLANC / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1280B8",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-06",
          valor_mercado: "0.000000",
          cantidad: 600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 600,
          total_peso_bruto: 852,
          total_peso: 852,
          unidad_medida_peso: "kilos",
          total_volumen: 1.115923,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000253",
          alterno1: "719018",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["869"],
          nombre_articulo: "AP LILLET ROSE / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1224C2",
          documento1: "HLCULE1210830631",
          documento2: "918570649",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-11",
          valor_mercado: "0.000000",
          cantidad: 126,
          cantidad_por_alistar: 0,
          cantidad_disponible: 126,
          total_peso_bruto: 178.92,
          total_peso: 178.92,
          unidad_medida_peso: "kilos",
          total_volumen: 0.234344,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000277",
          alterno1: "719025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["869"],
          nombre_articulo: "AP LILLET ROSE / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1245C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-26",
          valor_mercado: "0.000000",
          cantidad: 600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 600,
          total_peso_bruto: 852,
          total_peso: 852,
          unidad_medida_peso: "kilos",
          total_volumen: 1.115923,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000277",
          alterno1: "719025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["869"],
          nombre_articulo: "AP LILLET ROSE / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1250C2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-06",
          valor_mercado: "0.000000",
          cantidad: 1199,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1199,
          total_peso_bruto: 1702.58,
          total_peso: 1702.58,
          unidad_medida_peso: "kilos",
          total_volumen: 2.229987,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000277",
          alterno1: "719025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["869"],
          nombre_articulo: "AP LILLET ROSE / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1250C2",
          documento1: "HLCULE12202AQCT1",
          documento2: "918604606",
          estado_articulo: "SNA2",
          nombre_estado_articulo: "ESTADO 2",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.42,
          total_peso: 1.42,
          unidad_medida_peso: "kilos",
          total_volumen: 0.00186,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000277",
          alterno1: "719025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["869"],
          nombre_articulo: "AP LILLET ROSE / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1258C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-26",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1200,
          total_peso_bruto: 1704,
          total_peso: 1704,
          unidad_medida_peso: "kilos",
          total_volumen: 2.231846,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000277",
          alterno1: "719025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["869"],
          nombre_articulo: "AP LILLET ROSE / 750",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1258C2",
          documento1: "HLCULE12109AQMX9",
          documento2: "918580324",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1200,
          total_peso_bruto: 1704,
          total_peso: 1704,
          unidad_medida_peso: "kilos",
          total_volumen: 2.231846,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3057230000277",
          alterno1: "719025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["875"],
          nombre_articulo: "GI MONKEY 47 / 500",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "L06/2022",
          documento1: "DCOCTG039898",
          documento2: "918596219",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-23",
          valor_mercado: "0.000000",
          cantidad: 2394,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2394,
          total_peso_bruto: 2250.36,
          total_peso: 2250.36,
          unidad_medida_peso: "kilos",
          total_volumen: 2.987712,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "42213277",
          alterno1: "9070",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["875"],
          nombre_articulo: "GI MONKEY 47 / 500",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "L06/2022",
          documento1: "DCOCTG039898",
          documento2: "918596219",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 798,
          cantidad_por_alistar: 0,
          cantidad_disponible: 798,
          total_peso_bruto: 750.12,
          total_peso: 750.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.995904,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "42213277",
          alterno1: "9070",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["875"],
          nombre_articulo: "GI MONKEY 47 / 500",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "L180/2021",
          documento1: "STXG287936",
          documento2: "918582239",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-01-20",
          valor_mercado: "0.000000",
          cantidad: 10,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10,
          total_peso_bruto: 9.4,
          total_peso: 9.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.01248,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "42213277",
          alterno1: "9070",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["875"],
          nombre_articulo: "GI MONKEY 47 / 500",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "L180/2021",
          documento1: "STXG287936",
          documento2: "918582239",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-26",
          valor_mercado: "0.000000",
          cantidad: 500,
          cantidad_por_alistar: 30,
          cantidad_disponible: 470,
          total_peso_bruto: 470,
          total_peso: 470,
          unidad_medida_peso: "kilos",
          total_volumen: 0.624,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "42213277",
          alterno1: "9070",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["875"],
          nombre_articulo: "GI MONKEY 47 / 500",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "L55/2021",
          documento1: "STXG284334",
          documento2: "918581077",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-17",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.94,
          total_peso: 0.94,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001248,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "42213277",
          alterno1: "9070",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR1777",
          documento1: "88092",
          documento2: "918574051",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-10-28",
          valor_mercado: "0.000000",
          cantidad: 15,
          cantidad_por_alistar: 0,
          cantidad_disponible: 15,
          total_peso_bruto: 14.7,
          total_peso: 14.7,
          unidad_medida_peso: "kilos",
          total_volumen: 0.015488,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2942",
          documento1: "HLCUMEA210611544",
          documento2: "918555249",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-02",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 11.76,
          total_peso: 11.76,
          unidad_medida_peso: "kilos",
          total_volumen: 0.01239,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2942",
          documento1: "HLCULIV210633568",
          documento2: "918362548",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-08",
          valor_mercado: "0.000000",
          cantidad: 1533,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1533,
          total_peso_bruto: 1502.34,
          total_peso: 1502.34,
          unidad_medida_peso: "kilos",
          total_volumen: 1.582823,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2942",
          documento1: "HLCULIV210689471",
          documento2: "918552727",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-16",
          valor_mercado: "0.000000",
          cantidad: 144,
          cantidad_por_alistar: 0,
          cantidad_disponible: 144,
          total_peso_bruto: 141.12,
          total_peso: 141.12,
          unidad_medida_peso: "kilos",
          total_volumen: 0.14868,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2973",
          documento1: "HLCULIV210658403",
          documento2: "918551376",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-07-19",
          valor_mercado: "0.000000",
          cantidad: 180,
          cantidad_por_alistar: 0,
          cantidad_disponible: 180,
          total_peso_bruto: 176.4,
          total_peso: 176.4,
          unidad_medida_peso: "kilos",
          total_volumen: 0.18585,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2973",
          documento1: "HLCUMEA210611544",
          documento2: "918555249",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-02",
          valor_mercado: "0.000000",
          cantidad: 540,
          cantidad_por_alistar: 0,
          cantidad_disponible: 540,
          total_peso_bruto: 529.2,
          total_peso: 529.2,
          unidad_medida_peso: "kilos",
          total_volumen: 0.55755,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR2973",
          documento1: "HLCULIV210689471",
          documento2: "918552727",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-16",
          valor_mercado: "0.000000",
          cantidad: 1092,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1092,
          total_peso_bruto: 1070.16,
          total_peso: 1070.16,
          unidad_medida_peso: "kilos",
          total_volumen: 1.12749,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3508",
          documento1: "HLCUMEA210611544",
          documento2: "918555249",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-02",
          valor_mercado: "0.000000",
          cantidad: 3768,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3768,
          total_peso_bruto: 3692.64,
          total_peso: 3692.64,
          unidad_medida_peso: "kilos",
          total_volumen: 3.89046,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3508",
          documento1: "HLCUMEA210611493",
          documento2: "918555268",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-11",
          valor_mercado: "0.000000",
          cantidad: 1020,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1020,
          total_peso_bruto: 999.6,
          total_peso: 999.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.05315,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3508",
          documento1: "HLCULIV210716667",
          documento2: "918554887",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-28",
          valor_mercado: "0.000000",
          cantidad: 5184,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5184,
          total_peso_bruto: 5080.32,
          total_peso: 5080.32,
          unidad_medida_peso: "kilos",
          total_volumen: 5.35248,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3508",
          documento1: "HLCULIV210716667",
          documento2: "918554887",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0.98,
          total_peso: 0.98,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001033,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3526",
          documento1: "HLCUMEA210611544",
          documento2: "918555249",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-02",
          valor_mercado: "0.000000",
          cantidad: 47220,
          cantidad_por_alistar: 0,
          cantidad_disponible: 47220,
          total_peso_bruto: 46275.6,
          total_peso: 46275.6,
          unidad_medida_peso: "kilos",
          total_volumen: 48.75465,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3526",
          documento1: "HLCUMEA210611493",
          documento2: "918555268",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-11",
          valor_mercado: "0.000000",
          cantidad: 132,
          cantidad_por_alistar: 0,
          cantidad_disponible: 132,
          total_peso_bruto: 129.36,
          total_peso: 129.36,
          unidad_medida_peso: "kilos",
          total_volumen: 0.13629,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3526",
          documento1: "HLCUMEA210611482",
          documento2: "918554893",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-02",
          valor_mercado: "0.000000",
          cantidad: 419,
          cantidad_por_alistar: 419,
          cantidad_disponible: 0,
          total_peso_bruto: 410.62,
          total_peso: 410.62,
          unidad_medida_peso: "kilos",
          total_volumen: 0.432618,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3526",
          documento1: "HLCUMEA210611511",
          documento2: "918554897",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 45360,
          cantidad_por_alistar: 0,
          cantidad_disponible: 45360,
          total_peso_bruto: 44452.8,
          total_peso: 44452.8,
          unidad_medida_peso: "kilos",
          total_volumen: 46.8342,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3526",
          documento1: "HLCUMEA210611544",
          documento2: "918555249",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 16500,
          cantidad_por_alistar: 0,
          cantidad_disponible: 16500,
          total_peso_bruto: 16170,
          total_peso: 16170,
          unidad_medida_peso: "kilos",
          total_volumen: 17.03625,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCUMEA210611493",
          documento2: "918555268",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-11",
          valor_mercado: "0.000000",
          cantidad: 20424,
          cantidad_por_alistar: 0,
          cantidad_disponible: 20424,
          total_peso_bruto: 20015.52,
          total_peso: 20015.52,
          unidad_medida_peso: "kilos",
          total_volumen: 21.08778,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCUMEA210611511",
          documento2: "918554897",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 9949,
          cantidad_por_alistar: 9949,
          cantidad_disponible: 0,
          total_peso_bruto: 9750.02,
          total_peso: 9750.02,
          unidad_medida_peso: "kilos",
          total_volumen: 10.272343,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCUMEA210611482",
          documento2: "918554893",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 6172,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6172,
          total_peso_bruto: 6048.56,
          total_peso: 6048.56,
          unidad_medida_peso: "kilos",
          total_volumen: 6.37259,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3561",
          documento1: "HLCUMEA210611482",
          documento2: "918554893",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 7,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7,
          total_peso_bruto: 6.86,
          total_peso: 6.86,
          unidad_medida_peso: "kilos",
          total_volumen: 0.007228,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3755",
          documento1: "HLCULIV210722874",
          documento2: "918557921",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-18",
          valor_mercado: "0.000000",
          cantidad: 20268,
          cantidad_por_alistar: 0,
          cantidad_disponible: 20268,
          total_peso_bruto: 19862.64,
          total_peso: 19862.64,
          unidad_medida_peso: "kilos",
          total_volumen: 20.926711,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3755",
          documento1: "HLCULIV210722684",
          documento2: "918557906",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-23",
          valor_mercado: "0.000000",
          cantidad: 68035,
          cantidad_por_alistar: 0,
          cantidad_disponible: 68035,
          total_peso_bruto: 66674.3,
          total_peso: 66674.3,
          unidad_medida_peso: "kilos",
          total_volumen: 70.246138,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3755",
          documento1: "HLCULIV210722684",
          documento2: "918557906",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2021-12-14",
          valor_mercado: "0.000000",
          cantidad: 5,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5,
          total_peso_bruto: 4.9,
          total_peso: 4.9,
          unidad_medida_peso: "kilos",
          total_volumen: 0.005163,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3791",
          documento1: "HLCULIV210722874",
          documento2: "918557921",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-08-18",
          valor_mercado: "0.000000",
          cantidad: 2412,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2412,
          total_peso_bruto: 2363.76,
          total_peso: 2363.76,
          unidad_medida_peso: "kilos",
          total_volumen: 2.49039,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["879"],
          nombre_articulo: "WH PASSPORT / 500",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKBR3791",
          documento1: "HLCULIV210750052",
          documento2: "918558200",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-25",
          valor_mercado: "0.000000",
          cantidad: 2340,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2340,
          total_peso_bruto: 2293.2,
          total_peso: 2293.2,
          unidad_medida_peso: "kilos",
          total_volumen: 2.41605,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299210130",
          alterno1: "PV31004",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["880"],
          nombre_articulo: "WH CHIVAS 18 / 700 CTN X 12",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKGP3243",
          documento1: "HLCULIV200901289",
          documento2: "918498761",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2020-10-07",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.88,
          total_peso: 1.88,
          unidad_medida_peso: "kilos",
          total_volumen: 0.002401,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225004",
          alterno1: "C709025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["880"],
          nombre_articulo: "WH CHIVAS 18 / 700 CTN X 12",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKGR8297",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 120,
          total_peso_bruto: 225.6,
          total_peso: 225.6,
          unidad_medida_peso: "kilos",
          total_volumen: 0.28812,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225004",
          alterno1: "C709025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["880"],
          nombre_articulo: "WH CHIVAS 18 / 700 CTN X 12",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKGS0299",
          documento1: "HLCULIV220214391",
          documento2: "918602772",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-28",
          valor_mercado: "0.000000",
          cantidad: 3648,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3648,
          total_peso_bruto: 6858.24,
          total_peso: 6858.24,
          unidad_medida_peso: "kilos",
          total_volumen: 8.758848,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225004",
          alterno1: "C709025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["880"],
          nombre_articulo: "WH CHIVAS 18 / 700 CTN X 12",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKGS0299",
          documento1: "HLCULIV220241761",
          documento2: "918605008",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-04-08",
          valor_mercado: "0.000000",
          cantidad: 936,
          cantidad_por_alistar: 0,
          cantidad_disponible: 936,
          total_peso_bruto: 1759.68,
          total_peso: 1759.68,
          unidad_medida_peso: "kilos",
          total_volumen: 2.247336,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225004",
          alterno1: "C709025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["880"],
          nombre_articulo: "WH CHIVAS 18 / 700 CTN X 12",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKGS0299",
          documento1: "HLCULIV220214391",
          documento2: "918602772",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 1524,
          cantidad_por_alistar: 132,
          cantidad_disponible: 1392,
          total_peso_bruto: 2865.12,
          total_peso: 2865.12,
          unidad_medida_peso: "kilos",
          total_volumen: 3.659124,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299225004",
          alterno1: "C709025",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["881"],
          nombre_articulo: "GI BEEFEATER PINK / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHR7424",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 2304,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2304,
          total_peso_bruto: 2880,
          total_peso: 2880,
          unidad_medida_peso: "kilos",
          total_volumen: 2.994631,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299618073",
          alterno1: "FZ09007",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["881"],
          nombre_articulo: "GI BEEFEATER PINK / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHS0559",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 1203,
          cantidad_por_alistar: 72,
          cantidad_disponible: 1131,
          total_peso_bruto: 1503.75,
          total_peso: 1503.75,
          unidad_medida_peso: "kilos",
          total_volumen: 1.563603,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299618073",
          alterno1: "FZ09007",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["881"],
          nombre_articulo: "GI BEEFEATER PINK / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHS0559",
          documento1: "HLCULIV220190360",
          documento2: "918600221",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 3.75,
          total_peso: 3.75,
          unidad_medida_peso: "kilos",
          total_volumen: 0.003899,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299618073",
          alterno1: "FZ09007",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["884"],
          nombre_articulo: "WH CHIVAS EXTRA / 375 CTN",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "SOBINV2020",
          documento1: "SOBINV2020",
          documento2: "SOBINV2020",
          estado_articulo: "DIAN",
          nombre_estado_articulo: "DIAN",
          fecha_entrada: "2021-04-07",
          valor_mercado: "0.000000",
          cantidad: 3,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3,
          total_peso_bruto: 2.13,
          total_peso: 2.13,
          unidad_medida_peso: "kilos",
          total_volumen: 0.003308,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299611401",
          alterno1: "CX21002",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["885"],
          nombre_articulo: "WH CHIVAS 12 / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR7370",
          documento1: "HLCULIV220269872",
          documento2: "918599002",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 5,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5,
          total_peso_bruto: 6.65,
          total_peso: 6.65,
          unidad_medida_peso: "kilos",
          total_volumen: 0.010157,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432402931",
          alterno1: "CS09128",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["886"],
          nombre_articulo: "GI PLYMOUTH GIN / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LCR20166",
          documento1: "HLCULIV211200250",
          documento2: "918585809",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-23",
          valor_mercado: "0.000000",
          cantidad: 1440,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1440,
          total_peso_bruto: 2016,
          total_peso: 2016,
          unidad_medida_peso: "kilos",
          total_volumen: 2.718878,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299608005",
          alterno1: "PM09000",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["886"],
          nombre_articulo: "GI PLYMOUTH GIN / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LCR20166",
          documento1: "HLCULIV211200250",
          documento2: "918585809",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-16",
          valor_mercado: "0.000000",
          cantidad: 594,
          cantidad_por_alistar: 0,
          cantidad_disponible: 594,
          total_peso_bruto: 831.6,
          total_peso: 831.6,
          unidad_medida_peso: "kilos",
          total_volumen: 1.121538,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "5000299608005",
          alterno1: "PM09000",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["90"],
          nombre_articulo: "LI LICOR SOHO / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-004",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L1069B1",
          documento1: "HLCULE1210781437",
          documento2: "918569750",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 474,
          cantidad_por_alistar: 0,
          cantidad_disponible: 474,
          total_peso_bruto: 578.28,
          total_peso: 578.28,
          unidad_medida_peso: "kilos",
          total_volumen: 0.590426,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3047100057159",
          alterno1: "PR004917",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["90"],
          nombre_articulo: "LI LICOR SOHO / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-004",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L1218B1",
          documento1: "HLCULE1210781437",
          documento2: "918569750",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-27",
          valor_mercado: "0.000000",
          cantidad: 288,
          cantidad_por_alistar: 0,
          cantidad_disponible: 288,
          total_peso_bruto: 351.36,
          total_peso: 351.36,
          unidad_medida_peso: "kilos",
          total_volumen: 0.35874,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3047100057159",
          alterno1: "PR004917",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["90"],
          nombre_articulo: "LI LICOR SOHO / 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-004",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L1218B1",
          documento1: "HLCULE1210830631",
          documento2: "918570649",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-29",
          valor_mercado: "0.000000",
          cantidad: 390,
          cantidad_por_alistar: 0,
          cantidad_disponible: 390,
          total_peso_bruto: 475.8,
          total_peso: 475.8,
          unidad_medida_peso: "kilos",
          total_volumen: 0.485793,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "3047100057159",
          alterno1: "PR004917",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["917"],
          nombre_articulo: "TE OLMECA ALTOS REP / 700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1172JLOTE05532",
          documento1: "SUDU21297AUJ1119",
          documento2: "918560238",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-25",
          valor_mercado: "0.000000",
          cantidad: 24,
          cantidad_por_alistar: 0,
          cantidad_disponible: 24,
          total_peso_bruto: 31.92,
          total_peso: 31.92,
          unidad_medida_peso: "kilos",
          total_volumen: 0.040156,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432105528",
          alterno1: "27259",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["917"],
          nombre_articulo: "TE OLMECA ALTOS REP / 700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L20133LOTE05873",
          documento1: "HLCUME3220166180",
          documento2: "918596207",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-12",
          valor_mercado: "0.000000",
          cantidad: 90,
          cantidad_por_alistar: 78,
          cantidad_disponible: 12,
          total_peso_bruto: 119.7,
          total_peso: 119.7,
          unidad_medida_peso: "kilos",
          total_volumen: 0.150584,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432105528",
          alterno1: "27259",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["917"],
          nombre_articulo: "TE OLMECA ALTOS REP / 700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2013JLOTE05873",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 1055,
          cantidad_por_alistar: 600,
          cantidad_disponible: 455,
          total_peso_bruto: 1403.15,
          total_peso: 1403.15,
          unidad_medida_peso: "kilos",
          total_volumen: 1.765179,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432105528",
          alterno1: "27259",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["917"],
          nombre_articulo: "TE OLMECA ALTOS REP / 700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L2013JLOTE05873",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.33,
          total_peso: 1.33,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001673,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432105528",
          alterno1: "27259",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["918"],
          nombre_articulo: "TE OLMECA ALTOS PLATA  /700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1117JLOTE05450",
          documento1: "HLCUME3210506899",
          documento2: "918541808",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-09",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1.33,
          total_peso: 1.33,
          unidad_medida_peso: "kilos",
          total_volumen: 0.001673,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432106037",
          alterno1: "27254",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["918"],
          nombre_articulo: "TE OLMECA ALTOS PLATA  /700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L20113LOTE05870",
          documento1: "HLCUME3220166180",
          documento2: "918596207",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 101,
          cantidad_por_alistar: 0,
          cantidad_disponible: 101,
          total_peso_bruto: 134.33,
          total_peso: 134.33,
          unidad_medida_peso: "kilos",
          total_volumen: 0.168989,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432106037",
          alterno1: "27254",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["918"],
          nombre_articulo: "TE OLMECA ALTOS PLATA  /700 X6",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L201JLOTE05870",
          documento1: "ABTCWS011808",
          documento2: "918603254",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-13",
          valor_mercado: "0.000000",
          cantidad: 1056,
          cantidad_por_alistar: 515,
          cantidad_disponible: 541,
          total_peso_bruto: 1404.48,
          total_peso: 1404.48,
          unidad_medida_peso: "kilos",
          total_volumen: 1.766852,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "80432106037",
          alterno1: "27254",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["919"],
          nombre_articulo: "GI BEEFEATER / 350 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHS0323",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-17",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1200,
          total_peso_bruto: 1200,
          total_peso: 1200,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["919"],
          nombre_articulo: "GI BEEFEATER / 350 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHS0323",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 1608,
          cantidad_por_alistar: 24,
          cantidad_disponible: 1584,
          total_peso_bruto: 1608,
          total_peso: 1608,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["919"],
          nombre_articulo: "GI BEEFEATER / 350 40",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKHS0338",
          documento1: "HLCULIV2201AOWO0",
          documento2: "918600480",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-17",
          valor_mercado: "0.000000",
          cantidad: 3000,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3000,
          total_peso_bruto: 3000,
          total_peso: 3000,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["921"],
          nombre_articulo: "WH THE GLENLIVET 12 / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR8364",
          documento1: "HLCULIV211243147",
          documento2: "918593532",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 8,
          total_peso: 8,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["921"],
          nombre_articulo: "WH THE GLENLIVET 12 / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR8364",
          documento1: "HLCULIV211243231",
          documento2: "918596673",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-13",
          valor_mercado: "0.000000",
          cantidad: 860,
          cantidad_por_alistar: 96,
          cantidad_disponible: 764,
          total_peso_bruto: 6880,
          total_peso: 6880,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["921"],
          nombre_articulo: "WH THE GLENLIVET 12 / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR8364",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-23",
          valor_mercado: "0.000000",
          cantidad: 3192,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3192,
          total_peso_bruto: 25536,
          total_peso: 25536,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["921"],
          nombre_articulo: "WH THE GLENLIVET 12 / 700",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR8364",
          documento1: "HLCULIV220192450",
          documento2: "918602048",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 2400,
          cantidad_por_alistar: 24,
          cantidad_disponible: 2376,
          total_peso_bruto: 19200,
          total_peso: 19200,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["922"],
          nombre_articulo: "WH THE GLENLIVET CAPS RES/ 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR2112",
          documento1: "HLCULIV210456010",
          documento2: "918542215",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-06-26",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 7.96,
          total_peso: 7.96,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["922"],
          nombre_articulo: "WH THE GLENLIVET CAPS RES/ 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR3119",
          documento1: "HLCULIV210689895",
          documento2: "918552735",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-13",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 3.98,
          total_peso: 3.98,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["922"],
          nombre_articulo: "WH THE GLENLIVET CAPS RES/ 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR8090",
          documento1: "HLCULIV211273102",
          documento2: "9185949963",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 32,
          cantidad_por_alistar: 0,
          cantidad_disponible: 32,
          total_peso_bruto: 127.36,
          total_peso: 127.36,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["922"],
          nombre_articulo: "WH THE GLENLIVET CAPS RES/ 700",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKZR8090",
          documento1: "HLCULIV211273102",
          documento2: "9185949963",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2022-03-30",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 3.98,
          total_peso: 3.98,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["924"],
          nombre_articulo: "CHIVAS REGAL ULTIS BLENDED 3 X 700 ML",
          presentacion: ["3"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKRR7263",
          documento1: "HLCULIV220214380",
          documento2: "918602534",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 67,
          cantidad_por_alistar: 16,
          cantidad_disponible: 51,
          total_peso_bruto: 0.067,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["925"],
          nombre_articulo: "GI BEEFEATER / 1000",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKHR7735",
          documento1: "HLCULIV211228137",
          documento2: "918587356",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-20",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 136.44,
          total_peso: 136.44,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["926"],
          nombre_articulo: "RO HAVANA ANEJO 3 ANOS 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L-122950310",
          documento1: "HLCUHAV210900922",
          documento2: "918572089",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-03",
          valor_mercado: "0.000000",
          cantidad: 30,
          cantidad_por_alistar: 0,
          cantidad_disponible: 30,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["926"],
          nombre_articulo: "RO HAVANA ANEJO 3 ANOS 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L203550056",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 4380,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4380,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["926"],
          nombre_articulo: "RO HAVANA ANEJO 3 ANOS 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L203550056",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 1200,
          cantidad_por_alistar: 60,
          cantidad_disponible: 1140,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["926"],
          nombre_articulo: "RO HAVANA ANEJO 3 ANOS 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "L-203550056",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-02",
          valor_mercado: "0.000000",
          cantidad: 3960,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3960,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["929"],
          nombre_articulo: "TE OLMECA REPOSADO 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1149JLOTE05500",
          documento1: "HLCUME3210619814",
          documento2: "918272286",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-29",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["930"],
          nombre_articulo: "TE OLMECA BLANCO 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-006",
          nombre_tipo_producto: "TEQUILA",
          serie: "0",
          lote: "L1175JLOTE05534",
          documento1: "SUDU21297AUJ1116",
          documento2: "918560642",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-29",
          valor_mercado: "0.000000",
          cantidad: 52,
          cantidad_por_alistar: 0,
          cantidad_disponible: 52,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["931"],
          nombre_articulo: "CHIVAS EXTRA 6 X 1000 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-003",
          nombre_tipo_producto: "GINEBRA",
          serie: "0",
          lote: "LKGR0624",
          documento1: "HLCULIV210234188",
          documento2: "918436379",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-03-16",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR1051",
          documento1: "HLCULIV210328783",
          documento2: "918533150",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-04-18",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR1051",
          documento1: "HLCULIV210328783",
          documento2: "918533150",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-09-20",
          valor_mercado: "0.000000",
          cantidad: 11,
          cantidad_por_alistar: 0,
          cantidad_disponible: 11,
          total_peso_bruto: 11,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR1051",
          documento1: "HLCULIV210329815",
          documento2: "918533037",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-25",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR1370",
          documento1: "HLCULIV210397869",
          documento2: "918535487",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-11",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 2,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR1370",
          documento1: "HLCULIV210397869",
          documento2: "918535487",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-11-11",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 1,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR1370",
          documento1: "HLCULIV2111APIS4",
          documento2: "918584563",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-02-17",
          valor_mercado: "0.000000",
          cantidad: 26,
          cantidad_por_alistar: 0,
          cantidad_disponible: 26,
          total_peso_bruto: 26,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["937"],
          nombre_articulo: "BALLANTINES FINEST 12 X 1 L",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKVR2074",
          documento1: "HLCULIV2111APIS4",
          documento2: "918584563",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-27",
          valor_mercado: "0.000000",
          cantidad: 240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 240,
          total_peso_bruto: 240,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["950"],
          nombre_articulo: "ABSOLUT MANDRIN 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211027C",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 1079,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1079,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["950"],
          nombre_articulo: "ABSOLUT MANDRIN 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211027C",
          documento1: "GTG0317436",
          documento2: "918600474",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 899,
          cantidad_por_alistar: 0,
          cantidad_disponible: 899,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["950"],
          nombre_articulo: "ABSOLUT MANDRIN 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211027C",
          documento1: "GTG0317436",
          documento2: "918600474",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["950"],
          nombre_articulo: "ABSOLUT MANDRIN 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211027C",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2022-03-31",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["950"],
          nombre_articulo: "ABSOLUT MANDRIN 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211027C",
          documento1: "GTG0317436",
          documento2: "918600474",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-11",
          valor_mercado: "0.000000",
          cantidad: 996,
          cantidad_por_alistar: 108,
          cantidad_disponible: 888,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["951"],
          nombre_articulo: "WH BALLANTINES FINEST 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMP6048",
          documento1: "HLCULIV210234188",
          documento2: "918436379",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-12",
          valor_mercado: "0.000000",
          cantidad: 240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 240,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["951"],
          nombre_articulo: "WH BALLANTINES FINEST 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR0946",
          documento1: "HLCULIV210329815",
          documento2: "918533037",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-04-08",
          valor_mercado: "0.000000",
          cantidad: 4320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4320,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["951"],
          nombre_articulo: "WH BALLANTINES FINEST 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR0946",
          documento1: "HLCULIV210329815",
          documento2: "918533037",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-01",
          valor_mercado: "0.000000",
          cantidad: 4884,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4884,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["957"],
          nombre_articulo: "KIT SOMETHING 750  VASO",
          presentacion: ["8"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210456222",
          documento2: "KIT",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-20",
          valor_mercado: "0.000000",
          cantidad: 64,
          cantidad_por_alistar: 0,
          cantidad_disponible: 64,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["964"],
          nombre_articulo: "VO ABSOLUT CITRON 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210113L",
          documento1: "GTG0290358",
          documento2: "918456143",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-03-24",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["964"],
          nombre_articulo: "VO ABSOLUT CITRON 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210430L",
          documento1: "MEDUUT668008",
          documento2: "918553132",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-10-19",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["964"],
          nombre_articulo: "VO ABSOLUT CITRON 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211119L",
          documento1: "GTG0311392",
          documento2: "918586757",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-26",
          valor_mercado: "0.000000",
          cantidad: 1383,
          cantidad_por_alistar: 12,
          cantidad_disponible: 1371,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["964"],
          nombre_articulo: "VO ABSOLUT CITRON 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220201L",
          documento1: "GTG0317423",
          documento2: "918599602",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 1620,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1620,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["964"],
          nombre_articulo: "VO ABSOLUT CITRON 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20220201L",
          documento1: "GTG0317436",
          documento2: "918600474",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-26",
          valor_mercado: "0.000000",
          cantidad: 1080,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1080,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["965"],
          nombre_articulo: "ABSOLUT RASPBERRI 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20210909A",
          documento1: "GTG0309939",
          documento2: "918584857",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-18",
          valor_mercado: "0.000000",
          cantidad: 660,
          cantidad_por_alistar: 0,
          cantidad_disponible: 660,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["965"],
          nombre_articulo: "ABSOLUT RASPBERRI 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211005C",
          documento1: "GTG0309939",
          documento2: "918584857",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-18",
          valor_mercado: "0.000000",
          cantidad: 1350,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1350,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["965"],
          nombre_articulo: "ABSOLUT RASPBERRI 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211005C",
          documento1: "GTG0309939",
          documento2: "918584857",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-27",
          valor_mercado: "0.000000",
          cantidad: 612,
          cantidad_por_alistar: 18,
          cantidad_disponible: 594,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["965"],
          nombre_articulo: "ABSOLUT RASPBERRI 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-007",
          nombre_tipo_producto: "VODKA",
          serie: "0",
          lote: "L20211203C",
          documento1: "GTG0312636",
          documento2: "918593302",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-05",
          valor_mercado: "0.000000",
          cantidad: 3300,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3300,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["972"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY CASK 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPR1933",
          documento1: "HLCULIV210436727",
          documento2: "918544059",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 6539,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6539,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["972"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY CASK 24 X 200 ML",
          presentacion: ["24"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKPR1933",
          documento1: "HLCULIV210658403",
          documento2: "918551376",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 24,
          cantidad_por_alistar: 0,
          cantidad_disponible: 24,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["973"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKXR7556",
          documento1: "HLCULIV211200198",
          documento2: "918585851",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-20",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["973"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 6 X 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKXS0649",
          documento1: "HLCULIV220241889",
          documento2: "918605011",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 12960,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12960,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["974"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 120 X 50 ML",
          presentacion: ["120"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LC6R1136",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-21",
          valor_mercado: "0.000000",
          cantidad: 120,
          cantidad_por_alistar: 0,
          cantidad_disponible: 120,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["975"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 12 X 375 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR2458",
          documento1: "HLCULIV210658403",
          documento2: "918551376",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-14",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["975"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 12 X 375 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3260",
          documento1: "HLCULIV210658403",
          documento2: "918551376",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-09-14",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["975"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 12 X 375 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3260",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-10",
          valor_mercado: "0.000000",
          cantidad: 12,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["975"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 12 X 375 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3836",
          documento1: "HLCULIV210749810",
          documento2: "918558203",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-09-14",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["975"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 12 X 375 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3836",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-10",
          valor_mercado: "0.000000",
          cantidad: 3684,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3684,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["975"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 12 X 375 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR3836",
          documento1: "HLCULIV210815132",
          documento2: "918561813",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 2227,
          cantidad_por_alistar: 24,
          cantidad_disponible: 2203,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["976"],
          nombre_articulo: "WH CHIVAS EXTRA 13Y SHERRY 6 X 1 L",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKWR8024",
          documento1: "HLCULIV211243041",
          documento2: "918594968",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 30,
          cantidad_por_alistar: 0,
          cantidad_disponible: 30,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR1130",
          documento1: "HLCULIV210354189",
          documento2: "918533708",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2409",
          documento1: "HLCULIV210790387",
          documento2: "918562273",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-09-09",
          valor_mercado: "0.000000",
          cantidad: 480,
          cantidad_por_alistar: 0,
          cantidad_disponible: 480,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2409",
          documento1: "HLCULIV210987437",
          documento2: "918571634",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-10-15",
          valor_mercado: "0.000000",
          cantidad: 6336,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6336,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2409",
          documento1: "HLCULIV210689895",
          documento2: "918552735",
          estado_articulo: "NZD3",
          nombre_estado_articulo: "ESTADO 5",
          fecha_entrada: "2021-11-20",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2409",
          documento1: "HLCULIV210839812",
          documento2: "918564226",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 2719,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2719,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2409",
          documento1: "HLCULIV210826990",
          documento2: "918561926",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 629,
          cantidad_por_alistar: 0,
          cantidad_disponible: 629,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR2409",
          documento1: "HLCULIV210790387",
          documento2: "918562273",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 2400,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2400,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3294",
          documento1: "HLCULIV210790387",
          documento2: "918562273",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-09-08",
          valor_mercado: "0.000000",
          cantidad: 2640,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2640,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3294",
          documento1: "HLCULIV210987437",
          documento2: "918571634",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-10-15",
          valor_mercado: "0.000000",
          cantidad: 7200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7200,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR3294",
          documento1: "HLCULIV210826990",
          documento2: "918561926",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 1269,
          cantidad_por_alistar: 72,
          cantidad_disponible: 1197,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR5826",
          documento1: "HLCULIV210987437",
          documento2: "918571634",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-10-15",
          valor_mercado: "0.000000",
          cantidad: 864,
          cantidad_por_alistar: 0,
          cantidad_disponible: 864,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR5826",
          documento1: "HLCULIV211045391",
          documento2: "918575800",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-11-06",
          valor_mercado: "0.000000",
          cantidad: 7200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 7200,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR5826",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 12956,
          cantidad_por_alistar: 0,
          cantidad_disponible: 12956,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR5826",
          documento1: "HLCULIV211172993",
          documento2: "918582841",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2021-12-13",
          valor_mercado: "0.000000",
          cantidad: 4,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR5826",
          documento1: "HLCULIV211242495",
          documento2: "918593527918593527",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-09",
          valor_mercado: "0.000000",
          cantidad: 4320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4320,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR6598",
          documento1: "HLCULIV211204356",
          documento2: "918587036",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-12",
          valor_mercado: "0.000000",
          cantidad: 1440,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1440,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR6598",
          documento1: "HLCULIV211242495",
          documento2: "918593527918593527",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-02-09",
          valor_mercado: "0.000000",
          cantidad: 2880,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2880,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["977"],
          nombre_articulo: "WH SOMETHING SPECIAL 12 X 350 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKMR7796",
          documento1: "HLCULIV211204356",
          documento2: "918587036",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-01-12",
          valor_mercado: "0.000000",
          cantidad: 4320,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4320,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["978"],
          nombre_articulo: "WH CHIVAS MIZUNARA 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKXR7948",
          documento1: "HLCULIV211243147",
          documento2: "918593532",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-07",
          valor_mercado: "0.000000",
          cantidad: 2579,
          cantidad_por_alistar: 187,
          cantidad_disponible: 2392,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["980"],
          nombre_articulo: "RO HAVANA ANEJO 7 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L116050239",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 900,
          cantidad_por_alistar: 0,
          cantidad_disponible: 900,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["980"],
          nombre_articulo: "RO HAVANA ANEJO 7 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L-116050239",
          documento1: "HLCUHAV211101498",
          documento2: "918585973",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 156,
          cantidad_por_alistar: 0,
          cantidad_disponible: 156,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["980"],
          nombre_articulo: "RO HAVANA ANEJO 7 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L116150239",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 2760,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2760,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["980"],
          nombre_articulo: "RO HAVANA ANEJO 7 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L202850038",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 4740,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4740,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["980"],
          nombre_articulo: "RO HAVANA ANEJO 7 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L202850038",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 114,
          cantidad_por_alistar: 0,
          cantidad_disponible: 114,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["981"],
          nombre_articulo: "RO HAVANA CLUB ANEJO ESP 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L-202450029",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 10200,
          cantidad_por_alistar: 0,
          cantidad_disponible: 10200,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["981"],
          nombre_articulo: "RO HAVANA CLUB ANEJO ESP 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L-202450029",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 528,
          cantidad_por_alistar: 60,
          cantidad_disponible: 468,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["982"],
          nombre_articulo: "RO HAVANA CLUB 3 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L-129250421",
          documento1: "HLCUHAV211101498",
          documento2: "918585973",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-01-14",
          valor_mercado: "0.000000",
          cantidad: 192,
          cantidad_por_alistar: 0,
          cantidad_disponible: 192,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["982"],
          nombre_articulo: "RO HAVANA CLUB 3 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L20325004",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 900,
          cantidad_por_alistar: 0,
          cantidad_disponible: 900,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["982"],
          nombre_articulo: "RO HAVANA CLUB 3 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L203250046",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 8760,
          cantidad_por_alistar: 0,
          cantidad_disponible: 8760,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["982"],
          nombre_articulo: "RO HAVANA CLUB 3 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L203250046",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-04-20",
          valor_mercado: "0.000000",
          cantidad: 240,
          cantidad_por_alistar: 0,
          cantidad_disponible: 240,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["982"],
          nombre_articulo: "RO HAVANA CLUB 3 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L203350046",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2022-03-03",
          valor_mercado: "0.000000",
          cantidad: 3600,
          cantidad_por_alistar: 0,
          cantidad_disponible: 3600,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["982"],
          nombre_articulo: "RO HAVANA CLUB 3 ANOS 12 X 700 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-005",
          nombre_tipo_producto: "RON",
          serie: "0",
          lote: "L203350046",
          documento1: "HLCUHAV220200042",
          documento2: "918596621",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-19",
          valor_mercado: "0.000000",
          cantidad: 4763,
          cantidad_por_alistar: 204,
          cantidad_disponible: 4559,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["997"],
          nombre_articulo: "KIT WH CHIVAS 12YO 700 ML MAS 2 MINI X 50ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKXR4176",
          documento1: "HLCULIV210976639",
          documento2: "918572723",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-08",
          valor_mercado: "0.000000",
          cantidad: 41,
          cantidad_por_alistar: 0,
          cantidad_disponible: 41,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["997"],
          nombre_articulo: "KIT WH CHIVAS 12YO 700 ML MAS 2 MINI X 50ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKXR4176",
          documento1: "HLCULIV211200311",
          documento2: "918585791",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2022-03-01",
          valor_mercado: "0.000000",
          cantidad: 18,
          cantidad_por_alistar: 0,
          cantidad_disponible: 18,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["997"],
          nombre_articulo: "KIT WH CHIVAS 12YO 700 ML MAS 2 MINI X 50ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "LKXR4176",
          documento1: "HLCULIV210976639",
          documento2: "918572723",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2022-03-30",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["998"],
          nombre_articulo: "VAPS CHIVAS EXTRA 13Y  EXTRA 375 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210790450",
          documento2: "HLCULIV210796149",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2022-03-30",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-001"],
          nombre_articulo: "POP VAPS CHIVAS EXTRA 13Y  375 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "20211012",
          documento2: "0",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-12",
          valor_mercado: "0.000000",
          cantidad: 2292,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2292,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-001",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-001"],
          nombre_articulo: "POP VAPS CHIVAS EXTRA 13Y  375 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "20211012",
          documento2: "0",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-11-19",
          valor_mercado: "0.000000",
          cantidad: 161,
          cantidad_por_alistar: 0,
          cantidad_disponible: 161,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-001",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-003"],
          nombre_articulo: "POP ESTUCHE SOMETHING ESPECIAL 750VASO",
          presentacion: ["8"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "72819",
          documento2: "0",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-27",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-003",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-005"],
          nombre_articulo: "POP CAJA CORRUGADA KIT SOMETHING SPECIAL",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "72819",
          documento2: "0",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-18",
          valor_mercado: "0.000000",
          cantidad: 0.75,
          cantidad_por_alistar: 0,
          cantidad_disponible: 0.75,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-005",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-006"],
          nombre_articulo: "POP NIDO DE VASO KIT SOMETHING 750 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "72819",
          documento2: "918553557",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-27",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-006"],
          nombre_articulo: "POP NIDO DE VASO KIT SOMETHING 750 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "73955",
          documento2: "918570366",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 209,
          cantidad_por_alistar: 0,
          cantidad_disponible: 209,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-006",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-007"],
          nombre_articulo: "POP ESTUCHE CHIVAS REGAL 18",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "0",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 102,
          cantidad_por_alistar: 0,
          cantidad_disponible: 102,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "PR-007",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-008"],
          nombre_articulo: "POP - NIDO DE BOTELLA KIT SOMEHING SPECIAL 750 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "1",
          documento1: "72740",
          documento2: "918552781",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-008"],
          nombre_articulo: "POP - NIDO DE BOTELLA KIT SOMEHING SPECIAL 750 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "1",
          documento1: "73955",
          documento2: "918570366",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 110,
          cantidad_por_alistar: 0,
          cantidad_disponible: 110,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-009"],
          nombre_articulo: "POP - ESTUCHES ROYAL SALUTE 21 Y  TAPA",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV191122745",
          documento2: "918457082",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2019-12-06",
          valor_mercado: "0.000000",
          cantidad: 48,
          cantidad_por_alistar: 0,
          cantidad_disponible: 48,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-012"],
          nombre_articulo: "POP ESTUCHES CHIVAS EXTRA 700 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV201129210",
          documento2: "918515015",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-01-21",
          valor_mercado: "0.000000",
          cantidad: 672,
          cantidad_por_alistar: 0,
          cantidad_disponible: 672,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-018"],
          nombre_articulo: "POP - TAPAS CHIVAS 18Y 700 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "918546175",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 105,
          cantidad_por_alistar: 0,
          cantidad_disponible: 105,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-021"],
          nombre_articulo: "POP TAPAS CHIVAS EXTRA 700 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210215641",
          documento2: "918524326",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-02-17",
          valor_mercado: "0.000000",
          cantidad: 4557,
          cantidad_por_alistar: 0,
          cantidad_disponible: 4557,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-024"],
          nombre_articulo: "ESTUCHES CHIVAS 12Y 1 L",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "918546175",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 119,
          cantidad_por_alistar: 0,
          cantidad_disponible: 119,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-026"],
          nombre_articulo: "ESTUCHES GLENLIVET CAPTAINS 700 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "918546175",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 32,
          cantidad_por_alistar: 0,
          cantidad_disponible: 32,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-027"],
          nombre_articulo: "ESTUCHES GLENLIVET FOUNDERS 700 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "918546175",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 8,
          cantidad_por_alistar: 0,
          cantidad_disponible: 8,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-028"],
          nombre_articulo: "ESTUCHES CHIVAS 12Y 375 ML",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "918546175",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 202,
          cantidad_por_alistar: 0,
          cantidad_disponible: 202,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-029"],
          nombre_articulo: "ESTUCHES SOMETHING SPECIAL 1 L",
          presentacion: ["1"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "HLCULIV210526232",
          documento2: "918546175",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-30",
          valor_mercado: "0.000000",
          cantidad: 748,
          cantidad_por_alistar: 0,
          cantidad_disponible: 748,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-030"],
          nombre_articulo: "VAPS CHIVAS 18Y  EXTRA 200 ML",
          presentacion: ["6"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "1",
          documento1: "20120423408",
          documento2: "918569922",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-11",
          valor_mercado: "0.000000",
          cantidad: 53,
          cantidad_por_alistar: 0,
          cantidad_disponible: 53,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-031"],
          nombre_articulo: "FAJONES CHIVAS EXTRA 13 ANOS",
          presentacion: ["2400"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "0",
          documento1: "72830",
          documento2: "918553557",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-27",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-031"],
          nombre_articulo: "FAJONES CHIVAS EXTRA 13 ANOS",
          presentacion: ["2400"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "0",
          documento1: "281809",
          documento2: "918565453",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-21",
          valor_mercado: "0.000000",
          cantidad: 416,
          cantidad_por_alistar: 0,
          cantidad_disponible: 416,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-031"],
          nombre_articulo: "FAJONES CHIVAS EXTRA 13 ANOS",
          presentacion: ["2400"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "0",
          documento1: "20211012",
          documento2: "NA",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-28",
          valor_mercado: "0.000000",
          cantidad: 889,
          cantidad_por_alistar: 0,
          cantidad_disponible: 889,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-031"],
          nombre_articulo: "FAJONES CHIVAS EXTRA 13 ANOS",
          presentacion: ["2400"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-008",
          nombre_tipo_producto: "WHISKY",
          serie: "0",
          lote: "0",
          documento1: "20211012",
          documento2: "NA",
          estado_articulo: "NZD2",
          nombre_estado_articulo: "ESTADO 6",
          fecha_entrada: "2021-11-19",
          valor_mercado: "0.000000",
          cantidad: 161,
          cantidad_por_alistar: 0,
          cantidad_disponible: 161,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-032"],
          nombre_articulo: "ESTUCHES ABSOLUT  VASO 2021",
          presentacion: ["225"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73375",
          documento2: "918562597",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-09",
          valor_mercado: "0.000000",
          cantidad: 58,
          cantidad_por_alistar: 0,
          cantidad_disponible: 58,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-032"],
          nombre_articulo: "ESTUCHES ABSOLUT  VASO 2021",
          presentacion: ["225"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "281809",
          documento2: "918565453",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-21",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-032"],
          nombre_articulo: "ESTUCHES ABSOLUT  VASO 2021",
          presentacion: ["225"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73955",
          documento2: "918570366",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-033"],
          nombre_articulo: "NIDO ABSOLUT  VASO 2021",
          presentacion: ["300"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73375",
          documento2: "918562597",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-09",
          valor_mercado: "0.000000",
          cantidad: 48,
          cantidad_por_alistar: 0,
          cantidad_disponible: 48,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-033"],
          nombre_articulo: "NIDO ABSOLUT  VASO 2021",
          presentacion: ["300"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73955",
          documento2: "918570366",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-034"],
          nombre_articulo: "CORRUGADOS ABSOLUT",
          presentacion: ["30"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73375",
          documento2: "918562597",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-09",
          valor_mercado: "0.000000",
          cantidad: 116.5,
          cantidad_por_alistar: 0,
          cantidad_disponible: 116.5,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-034"],
          nombre_articulo: "CORRUGADOS ABSOLUT",
          presentacion: ["30"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73375",
          documento2: "PR-034",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-18",
          valor_mercado: "0.000000",
          cantidad: 1,
          cantidad_por_alistar: 0,
          cantidad_disponible: 1,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-035"],
          nombre_articulo: "ESTUCHES BALLANTINES  VASO",
          presentacion: ["150"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73955",
          documento2: "918570366",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 68,
          cantidad_por_alistar: 0,
          cantidad_disponible: 68,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-035"],
          nombre_articulo: "ESTUCHES BALLANTINES  VASO",
          presentacion: ["150"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "PR212021",
          documento2: "918572451",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-21",
          valor_mercado: "0.000000",
          cantidad: 110,
          cantidad_por_alistar: 0,
          cantidad_disponible: 110,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-036"],
          nombre_articulo: "NIDO BALLANTINES  VASO",
          presentacion: ["300"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73955",
          documento2: "918570366",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-10-16",
          valor_mercado: "0.000000",
          cantidad: 68,
          cantidad_por_alistar: 0,
          cantidad_disponible: 68,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-037"],
          nombre_articulo: "CORRUGADOS BALLANTINES",
          presentacion: ["30"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "INSUMOS",
          nombre_tipo_producto: "INSUMOS OPERACION",
          serie: "0",
          lote: "0",
          documento1: "73375",
          documento2: "918562597",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-09",
          valor_mercado: "0.000000",
          cantidad: 21.375,
          cantidad_por_alistar: 0,
          cantidad_disponible: 21.375,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-038"],
          nombre_articulo: "ESTUCHES TEQUILA OLMECA",
          presentacion: ["9"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "1",
          documento1: "20211108-005-006",
          documento2: "918576263",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-09",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-039"],
          nombre_articulo: "TERMOS TEQUILA OLMECA",
          presentacion: ["50"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "LICOR",
          nombre_tipo_producto: "LICOR",
          serie: "0",
          lote: "1",
          documento1: "20211108-005-006",
          documento2: "918576263",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-11-09",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-KAHLUA"],
          nombre_articulo: "KAHLUA 12 X 750 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L1161FJ",
          documento1: "EGLV405141881522",
          documento2: "918565027",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-09-16",
          valor_mercado: "0.000000",
          cantidad: 5712,
          cantidad_por_alistar: 0,
          cantidad_disponible: 5712,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-KAHLUA"],
          nombre_articulo: "KAHLUA 12 X 750 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L9311FJ",
          documento1: "EGLV405141881522",
          documento2: "918565027",
          estado_articulo: "SNA1",
          nombre_estado_articulo: "ESTADO 4",
          fecha_entrada: "2021-09-16",
          valor_mercado: "0.000000",
          cantidad: 6046,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6046,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-KAHLUA"],
          nombre_articulo: "KAHLUA 12 X 750 ML",
          presentacion: ["12"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "PR-001",
          nombre_tipo_producto: "APERITIVO",
          serie: "0",
          lote: "L9311FJ",
          documento1: "EGLV405141881522",
          documento2: "918565027",
          estado_articulo: "SNA3",
          nombre_estado_articulo: "ESTADO 3",
          fecha_entrada: "2021-10-12",
          valor_mercado: "0.000000",
          cantidad: 2,
          cantidad_por_alistar: 0,
          cantidad_disponible: 2,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-VASOS"],
          nombre_articulo: "POP - VASOS KIT SOMETHING SPECIAL 750 ML",
          presentacion: ["72"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "72741",
          documento2: "918552806",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-07-21",
          valor_mercado: "0.000000",
          cantidad: 6,
          cantidad_por_alistar: 0,
          cantidad_disponible: 6,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-VASOS"],
          nombre_articulo: "POP - VASOS KIT SOMETHING SPECIAL 750 ML",
          presentacion: ["72"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "73376",
          documento2: "918562597",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-10",
          valor_mercado: "0.000000",
          cantidad: 286,
          cantidad_por_alistar: 0,
          cantidad_disponible: 286,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
          codigo_barra: "",
          alterno1: "",
          alterno2: "",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-VASOSABSOLUT"],
          nombre_articulo: "POP - VASOS ABSOLUT",
          presentacion: ["48"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "281809",
          documento2: "918565453",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-21",
          valor_mercado: "0.000000",
          cantidad: 60,
          cantidad_por_alistar: 0,
          cantidad_disponible: 60,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
        {
          punto: "ZCAN",
          nombre_punto: "ALMACEN ZONA FRANCA",
          nombre2_punto: "ALMACEN ZONA FRANCA",
          articulo: ["PR-VASOSBALLANTINES"],
          nombre_articulo: "POP - VASOS BALLANTINES",
          presentacion: ["48"],
          nombre_presentacion: ["UNID"],
          tipo_producto: "POP",
          nombre_tipo_producto: "MATERIAL POP",
          serie: "0",
          lote: "0",
          documento1: "213108",
          documento2: "918561509",
          estado_articulo: "NZD1",
          nombre_estado_articulo: "ESTADO 1 OK",
          fecha_entrada: "2021-09-01",
          valor_mercado: "0.000000",
          cantidad: 31,
          cantidad_por_alistar: 0,
          cantidad_disponible: 31,
          total_peso_bruto: 0,
          total_peso: 0,
          unidad_medida_peso: "kilos",
          total_volumen: 0,
          unidad_medida_volumen: "metros_cubico",
        },
      ],
    },
  },
  //catalogoInvetory
  catalogoInvetory: {
    message: "CATALOG ALMACEN",
    status: 200,
    data: [
      { value: "CUCT", label: "DEPOSITO PUBLICO CUCUTA" },
      { value: "TAMP", label: "TAMPA" },
      { value: "ZCAN", label: "ALMACEN ZONA FRANCA" },
      { value: "DPUB", label: "ALMACEN DEP PUBLICO" },
      { value: "BNAL", label: " BODEGA NACIONAL" },
      { value: "PP", label: "ALMACEN ABC 123" },
      { value: "MEDE", label: "BODEGA NACIONAL MEDELLIN" },
      { value: "ZFRA", label: "ZONA FRANCA BOGOTA (ROLOG)" },
      { value: "CEL2", label: "CELTA BODEGA 123 - SAMSONITE" },
      { value: "ZFB", label: "ZONA FRANCA BOGOTA" },
      { value: "ZELC", label: "CTG PARQUIAMERICAS" },
      { value: "RL3M", label: "AV DORADO 3M" },
      { value: "BUEN", label: "PARQUE INDUSTRIAL DE CONTENEDORES ITS DEPOT" },
      { value: "CALI", label: "PARQUE LOGÍSTICO SERVICOMEX" },
      { value: "CELT", label: "PARQUE INDUSTRIAL CELTA BODEGA 68" },
    ],
  },
};

export default dummy;
