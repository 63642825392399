import React, { Component, Suspense } from 'react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import { Grid } from 'semantic-ui-react';
import { CardBody } from 'reactstrap';
import Axios from 'axios';
import {  message } from 'antd';
import { Services } from './Services'
import './styles.scss';
const Step2 = React.lazy(() => import('./components/Step2Route'));
const Step3 = React.lazy(() => import('./components/Step3date'));
const Step4 = React.lazy(() => import('./components/Step4cargo'));
const Step4Air = React.lazy(() => import('./components/Step4cargoAir'));
const QuoteOptions = React.lazy(() => import('./components/quoteOptions'));
const QuoteDetails = React.lazy(() => import('./components/componentDetails/'));
const OrderSummary = React.lazy(() => import('./components/OrderSummary'));


const shipping = {
  'FCL':{ id: 1, title: 'Full Container Load', icon: 'icon--shipment_containers' },
  'LCL':{ id: 1, title: 'Air', icon: 'icon--menu_customerserviceon' },
  'AIR':{ id: 1, title: 'Air', icon: 'icon--shipment_transport03' },
  'Inland':{ id: 1, title: 'Full Container Load', icon: 'icon--menu_customerserviceon' },
}


class QuoteService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info:{
        route:{},
        },
      // defaultVista:
      vista: <></>,
      level: 0,
      ContainersTypes: [],
      countries: [],
      optionShiping: '',
      options: [
        { id: 'FCL', className: '_iconHover  center', icon: 'icon--shipment_containers', label: 'FCL' },
        { id: 'LCL', disable: true, className: 'center _icondisable', icon: 'icon--po_ordersummary', label: 'LCL' },
        { id: 'AIR', className: '_iconHover  center', icon: 'icon--shipment_transport03', label: 'AIR' },
        { id: 'Inland', disable: true, className: 'center _icondisable', icon: 'icon--shipment_transport01', label: 'Inland' },
      ],
      resumenSelect: {
        'FCL': [
        // { id: 1, title: 'Full Container Load', icon: 'icon--menu_customerserviceon' },
        // { id: 2, title: 'Italy - United States', icon: 'icon--menu_customerserviceon', imgMap: true, DestinoLongitude: '10.378085', DestinoLatitude: '-75.509940', OrigenLatitude: '45.6804162', OrigenLongitude: '9.1161722' },
        // { id: 3, title: '18 March 2021', icon: 'icon--menu_customerserviceon' },
        // { id: 4, title: '(2)  40´ High Cube Dry Container', icon: 'icon--menu_customerserviceon' }
      ],
        'AIR': [
        //   { id: 1, title: 'Full Container Load', icon: 'icon--menu_customerserviceon' },
        // { id: 2, title: 'Italy - United States', icon: 'icon--menu_customerserviceon', imgMap: true, DestinoLongitude: '10.378085', DestinoLatitude: '-75.509940', OrigenLatitude: '45.6804162', OrigenLongitude: '9.1161722' },
        // { id: 3, title: '18 March 2021', icon: 'icon--menu_customerserviceon' },
        // { id: 4, title: '(2)  40´ High Cube Dry Container', icon: 'icon--menu_customerserviceon' }
      ]
      }
    }
  }


  componentDidMount = () => {
    this.getCountriesCatalogs();
    this.level0();
  }

  level0 = () => {
    let {options}=this.state;
    let defaultVista = (
      <div className="col-12">
        <div style={{ marginBottom: '10em' }} className="col-12  center">
          <em style={{ color: '#43ADEB' }} className="fa-1x mr-2 fas icon--menu_customerserviceon"></em>
          <label style={{ color: '#43ADEB', fontWeight: 'bold' }} className='label __h1 mb-3'>What are you shipping?</label>
        </div>
        <div className="col-md-12">
          <Grid >
            <Grid.Row columns={6}>
              <Grid.Column width={3}>
              </Grid.Column>
              {
                options.map((item, index) => {
                  return (
                    <Grid.Column id={item.id}>
                      <div className={item.className}>
                        <em onClick={() => item.disable ? '' : this.selectOption(item.id)} style={{ marginBottom: '10px' }} className={`fa-5x mr-2 fas ${item.icon}`}></em>
                        <label className='label __h1 mb-3 '>{item.label}</label>
                      </div>
                    </Grid.Column>
                  );
                })
              }
              <Grid.Column width={2}>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )
  
    this.setState({vista:defaultVista})
  }

  componentWillReceiveProps = () => {
  }

  getCountriesCatalogs = async () => {
    try {

      let success = await Axios.get(`${Services.QUOTESERVICE.path}quote_service/catalog-countries`);

      let countries = success.data.data ? success.data.data : [];
      let ContainersTypes = success.data.ContainersTypes ? success.data.ContainersTypes : []
      this.setState({ countries, ContainersTypes });
    }
    catch (err) {
      console.warn(err);
    }
  }

  selectOption = async (optionShiping) => {
    let {resumenSelect,info}=this.state;
    let option = shipping[optionShiping];
    let exit = false;
    resumenSelect[optionShiping].map((e)=>{
     if ( option.id === e.id ){
      exit = true;
      e = option
     } 
     return e;
    });

    if (!exit) {
      resumenSelect[optionShiping].push(option);
    }
    this.setState({
      resumenSelect,
      optionShiping
    }, () => this.changeLevel(true,[],{}))
  }

  reset = () => {
    let resumenSelect ={
      'FCL': [],
      'AIR': []
    }
    this.setState({ level:0,resumenSelect}, () => this.generateCenter())
  }

  saveService = () => {
    let resumenSelect ={
      'FCL': [],
      'AIR': []
    }
    this.setState({ level:0, resumenSelect}, () =>{
      this.generateCenter()
      message.success({
        content: 'Service saved successfully.',
        // className: 'classCustomMessa',
      });
    } )
  }

  changeLevel = async (suma,newoption,info) => {

    let { level,resumenSelect,optionShiping } = this.state;
    if (suma) {
      level = level + 1
      if(newoption){
        let option = newoption;
      option.map((e)=>{
      let exit = false;

        let obj = {}
        resumenSelect[optionShiping].map((y)=>{
          obj = e;   

          if ( e.id===y.id ){
            exit = true;
             y.title = e.title ;  
          } 
            return y;

        })
        if (!exit) {
          resumenSelect[optionShiping].push(obj);
        }
      
       return e;
      });
      
      }    
      this.setState({ level,resumenSelect,info}, () => this.generateCenter())
    }
    else {
      if (level > 0) {
        level = level - 1
        this.setState({ level}, () => this.generateCenter())
      }
    }

    
  }

  generateCenter = () => {
    let { optionShiping, level,countries,ContainersTypes, info} = this.state;
    if (level === 0) {
      this.level0()
    }
    else  if  (level === 1) {
      if(optionShiping   === 'FCL'){
        this.setState({
          vista :    <Suspense fallback={<div>Loading...</div>}> <Step2 info={info} optionShiping={optionShiping} changeLevel={this.changeLevel} countries={countries} /></Suspense>,
          level

        })
      }
      else  if (optionShiping   === 'AIR'){
        this.setState({
          vista :   <Suspense fallback={<div>Loading...</div>}> <Step2 info={info} optionShiping={optionShiping} changeLevel={this.changeLevel} countries={countries} /></Suspense>,
          level
        })
      }
      else  {
        this.setState({
          vista :  <h1>nivel 1 de default</h1>
        })
      }

    }
    else  if  (level === 2) {
      if(optionShiping   === 'FCL'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <Step3 info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}   /></Suspense>,
          level
        })
      }
      else  if (optionShiping   === 'AIR'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <Step3 info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}   /></Suspense>,
          level
        })
      }
      else  {
        this.setState({
          vista :  <h1>nivel 2 de default</h1>
        })
      }

    }
    else  if  (level === 3) {
      if(optionShiping   === 'FCL'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <Step4 info={info} optionShiping={optionShiping} changeLevel={this.changeLevel} ContainersTypes={ContainersTypes}  /></Suspense>,
          level
        })
      }
      else  if (optionShiping   === 'AIR'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <Step4Air info={info} optionShiping={optionShiping} changeLevel={this.changeLevel} ContainersTypes={ContainersTypes}  /></Suspense>,
          level
        })
      }
      else  {
        this.setState({
          vista :  <h1>nivel 3 de default</h1>
        })
      }
    }
    else  if  (level === 4) {
      if(optionShiping   === 'FCL'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <QuoteOptions info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}  /></Suspense>,
          level
        })
      }
      else  if (optionShiping   === 'AIR'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <QuoteOptions info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}  /></Suspense>,
          level
        })
      }
      else  {
        this.setState({
          vista :  <h1>nivel 4 de default</h1>
        })
      }

    }
    else  if  (level === 5) {
      if(optionShiping   === 'FCL'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <QuoteDetails info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}  /></Suspense> ,
          level
        })
      }
      else  if (optionShiping   === 'AIR'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <QuoteDetails info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}  /></Suspense> ,
          level
        })
      }
      else  {
        this.setState({
          vista :  <h1>nivel 6 de default</h1>
        })
      }

    }
    else  if  (level === 6) {
      if(optionShiping   === 'FCL'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <OrderSummary info={info} optionShiping={optionShiping} changeLevel={this.changeLevel} reset={this.reset} saveService={this.saveService} /></Suspense> ,
          level
        })
      }
      else  if (optionShiping   === 'AIR'){
        this.setState({
          vista :  <Suspense fallback={<div>Loading...</div>}> <OrderSummary info={info}  optionShiping={optionShiping} changeLevel={this.changeLevel} reset={this.reset} saveService={this.saveService} /></Suspense> ,
          level
        })
      }
      else  {
        this.setState({
          vista :  <h1>nivel 6 de default</h1>
        })
      }

    }

  };

  render() {
    let { options, resumenSelect, countries, ContainersTypes, optionShiping, vista, level,info} = this.state;

    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fa-1x mr-2 fas icon--shipment_po"></em>
              Quote a Service
            </div>
          </div>
          <div className="row">
            <Grid celled='internally' divided style={{ marginBottom: '8rem' }}>
              <Grid.Row>
              {/* <Grid.Column width={16}> */}

                <Grid.Column width={level  === 5 || level  === 6 ?  16 :11}>
                  <>{vista}</>
                  {/* <Suspense fallback={<div>Loading...</div>}> <QuoteDetails info={info} optionShiping={optionShiping} changeLevel={this.changeLevel}  /></Suspense> */}
                </Grid.Column>
                {level  ===  5 || level  === 6 ? <></>  : <Grid.Column width={5}>
                  <div className="col-12">
                    <div className="col-12 center">
                      <label style={{ fontWeight: 'bold' }} className='label __h2'>Quote a Service</label>
                    </div>
                    <div className="col-12 center">
                      <CardBody>
                        {resumenSelect[optionShiping !== "" ? optionShiping : 'FCL'].map((item, index) => {
                          return (
                            <><div style={{ height: '33px', marginBottom: '0.25rem' }} className="shadow card flex-row align-items-center align-items-stretch border-0">
                              <div className="col-3 d-flex align-items-center bg_resumen_car  ">
                                <em style={{ color: '#ffffff' }} className={`fa-2x mr-2 fas  ${item.icon}`} ></em>
                              </div>
                              <div className="col-9 py-2 bg_resumen_car">
                                <div style={{ fontSize: '12px' }} className="">{item.title}</div>
                              </div>
                            </div>
                              {item.imgMap ?
                                <div className="center col-12">
                                  <img className="borderImg" src= {`https://maps.googleapis.com/maps/api/staticmap?size=800x400&path=color:0x000000%7Cweight:3%7C${item.OrigenLatitude},${item.OrigenLongitude}%7C${item.DestinoLatitude},${item.DestinoLongitude}&markers=icon:https://fsposeidon.blob.core.windows.net/poseidonpic/placeholderr.png%7C${item.DestinoLatitude},${item.DestinoLongitude}%7C${item.OrigenLatitude},${item.OrigenLongitude}&key=AIzaSyAl_10ZKSqnrviAV-KSQ6c8i4Sa_gEhEmA`} width="100%" height="100%" alt="active_containers" />
                                </div>
                                : <></>}
                              <div style={{ height: '10px' }}></div></>
                          );
                        })}
                      </CardBody>
                    </div>
                  </div>
                </Grid.Column>}
              </Grid.Row>
            </Grid>
          </div>
        </div>

      </ContentWrapper>
    );
  }
}

export default QuoteService;