import React, { Component } from 'react';
import restService from '../../../../../../services/restService';
import Select from 'react-select';
import {Services} from './../../../Services';
import Axios from 'axios';
// const rest = new restService();

class Consignees extends Component {

    constructor(props) {
        super(props)
        this.state = {
            consignees: [],
            consigneesArray: [],
            selectedOption: '',
            consigneeSelected: {},
            contactSelected: {},
            contacts: [],
            selected: [],
            found: null,
            foundContact: null,
            isLoaded: false,
            errors : {},
            styleConsignee : {},
        }
    }

    componentDidMount = () => {
        let { isLoaded,styleConsignee } = this.state
        if (!isLoaded) {
            this.setState({
                selected: this.props.selected,
                errors : this.props.errors
            }, () => {
                if(this.state.errors){
                    if(Object.keys(this.state.errors).length > 0){
                        styleConsignee = { color : this.state.errors['ConsigneeCode'] || this.state.errors['ConsigneeContactId']? '#e57373' : '#616161'}
                    }
                    this.setState({
                        styleConsignee
                    },()=>{
                        this.getAllConsignees()
                    })
                }
                else this.getAllConsignees()
            })
        }
    }

    componentWillReceiveProps = (nextProps) => {
        let { isLoaded,styleConsignee } = this.state
        if (!isLoaded) {
            this.setState({
                selected: nextProps.selected,
                errors : nextProps.errors
            }, () => {
                if(this.state.errors){
                    if(Object.keys(this.state.errors).length > 0){
                        styleConsignee = { color : this.state.errors['ConsigneeCode'] || this.state.errors['ConsigneeContactId']? '#e57373' : '#616161'}
                    }
                    this.setState({
                        styleConsignee
                    },()=>{
                        this.getAllConsignees()
                    })
                }
                else{
                    this.getAllConsignees()
                }
            })
        }
    }

    getAllConsignees = () => {
      Axios.get(`${Services.DIRECTORY.path}consignee/all`).then( data => {
        this.setState({ consignees: data.data.data }, () => this.buildArrayConsignees());
      }).catch(error => {
      });
    }

    buildArrayConsignees = () => {
        const { consignees } = this.state;
        let array = [];
        consignees.forEach((e) => array.push({ value: e.ConsigneeCode, label: e.Name }));
        this.setState({ consigneesArray: array }, () => {
            this.setFirstOrderValues()
        });
    };

    setFirstOrderValues = () => {
        let { selected, consigneesArray, found } = this.state
        if (selected && selected.length > 0 && !found) {
            let Code = selected[0].ConsigneeCode
            let found = consigneesArray.find((element) => element.value === Code)
            if (found) this.handleChangeSelect(found)
            this.setState({
                found
            })
        }
    }

    handleChangeSelect = (selectedOption) => this.setState({ selectedOption }, () => {
        if (selectedOption)
            this.buildConsigneeDetail(selectedOption.value)
        else this.setState({ consigneeSelected: {} }, () => this.props.sendProps({
            stateField: 'consignees',
            id: ''
        }))
    });

    buildConsigneeDetail = (id) => {
        const { consignees } = this.state
        let index = consignees.findIndex(x => x.ConsigneeCode === id)
        this.setState({ consigneeSelected: consignees[index] }, () => {
            this.props.sendProps({ stateField: 'consignees', id: this.state.consigneeSelected.ConsigneeCode })
            this.getContacts(consignees[index].ConsigneeCode)
        });
    }

    getContacts = (id) => {
      Axios.get(`${Services.DIRECTORY.path}/consignee/search/${id}`).then(data => {
        this.setState({
            contacts: data.data.data.contacts
        }, () => {
            this.setFirstOrderValuesContacts()
        })
      }).catch( error => {
      });
    }

    setFirstOrderValuesContacts = () => {
        let { selected, contacts, foundContact } = this.state
        if (selected && selected.length > 0 && !foundContact) {
            let Code = selected[0].ConsigneeContactId
            let foundContact = contacts.find((element) => element.value === Code)
            if (foundContact) this.handleChangeSelectContact(foundContact)
            this.setState({
                foundContact,
                isLoaded: true
            })
        }
    }

    handleChangeSelectContact = contactSelected => {
        if (contactSelected)
            this.setState({ contactSelected }, () => {
                this.props.sendProps({ stateField: 'consigneeContact', id: contactSelected.value })
            })
        else this.setState({ contactSelected: {} }, () => {
            this.props.sendProps({ stateField: 'consigneeContact', id: '' })
        })
    }

    render() {
        const { consigneesArray, selectedOption, consigneeSelected, contacts, contactSelected } = this.state
        const value = selectedOption && selectedOption.value;
        const value2 = contactSelected && contactSelected.value
        return (
            <div className="row">
                <div className="col-md-12">
                    <legend id ="Consignee" style={this.state.styleConsignee}><em className="fas fa-address-card fa-1x mr-2"></em>Consignee *</legend>
                    <Select
                        id="ConsigneeCode"
                        required
                        name="select-name"
                        value={value}
                        onChange={this.handleChangeSelect}
                        placeholder='Select a Consignee'
                        options={consigneesArray}
                    />
                    <hr />
                    <Select
                        id="ConsigneeContactId"
                        required
                        name="select-contact"
                        placeholder='Select a contact'
                        value={value2}
                        onChange={this.handleChangeSelectContact}
                        options={contacts}
                    />
                    <hr />
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-user mr-2"></em>
                                <span>{consigneeSelected.Name} </span>
                            </div>

                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-map-marker-alt mr-2"></em>
                                <span>{consigneeSelected.Address} </span>
                            </div>

                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-info-circle mr-2"></em>
                                <span>{consigneeSelected.AddressDetails} </span>
                            </div>

                            <div className="card-body d-flex align-items-center">
                                <em className="fas fa-address-book mr-2"></em>
                                <span>{contactSelected.details} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Consignees