import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Table, Row, Col } from 'reactstrap';
import { Pagination, Grid, Search } from 'semantic-ui-react';
import Fade from 'react-reveal/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-datetime/css/react-datetime.css';
import { saveAs } from 'file-saver';
import { Modal } from 'semantic-ui-react';
import { Button } from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Input } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import Axios from 'axios';

import UsersSelection from '../Chip';
import Modall from '../Modal/AdvancedSearch';
import IMG from '../../../resources/filter.png';
import QuickView from './../../poseidon/Order/order-list/components/quickView/';
import { Services } from './../../poseidon/Order/Services';
import Authentication from './../../../services/Authentication';
import DialogComponent from './../../Global/Dialog';
import './styles.scss';
import { SetFilterUrl } from './../../../actions/Orders/List';


const auth = new Authentication();

const Column = (props) => {
  return (
    <div style={props.isLarge ? { width: '350px', fontSize: '12px', textAlign: 'center' } : { width: '150px', fontSize: '12px', textAlign: 'center' }} ><p>{props.title}</p></div>
  );
};

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameDataTime: {},
      editime: '',
      datatime: false,
      condate: true,
      order: {},
      formDate: [
        {
          type: 'Select',
          options: 'multiplateOption',
          label: 'Multiple Update',
          name: 'multi',
          class: 'col-md-3',
          id: 'multi',
          style: {},
          required: false
        },
        {
          type: 'Datetime',
          label: 'Value',
          name: 'Value',
          class: 'col-md-3',
          id: 'value',
          style: {},
          required: true
        },
      ],
      formSinDate: [
        {
          type: 'Select',
          options: 'multiplateOption',
          label: 'Multiple Update',
          name: 'multi',
          class: 'col-md-3',
          id: 'multi',
          style: {},
          required: false
        },
        {
          type: 'Input',
          label: 'Value',
          name: 'Value',
          class: 'col-md-3',
          id: 'value',
          style: {},
          required: false
        },
      ],
      updateData: [],
      selectedOpcion: '',
      CreateFormData: [],
      respaldo: [],
      edit: [],
      active: false,
      flat: false,
      Origin: '',
      Supplier: '',
      Customer: '',
      catOrigin: [],
      catSupplier: [],
      catCustomer: [],
      selectedPo: [],
      all: this.props.data ? this.props.data : [],
      data: this.props.data ? this.props.data : [],
      columns: [],
      mainColumns: [],
      columnsLogic: {},
      MainTable: '',
      grupoOpcion: [],

      /**
             *
             */
      ___rows: [],
      ___columns: [],
      ___mainColumns: [],
      /**
             *
             */
      pages: 0,
      activePage: 1,
      tableData: [],
      /**
             *
             */
      haveCheck: (props.options ? (props.options.selectItems) : false),
      search: '',
      sorts: {},
      selected: [],
      selectControls: {},
      /**
             * downloads
             */
      xlsx: '',
      // require attention component
      text: 'Quick view'
    };
  };

  toggle = () => this.setState({ collapse: !this.state.collapse });

  handleChange = name => event => {
    let { selectControls, data } = this.state;
    let checked = event.target.checked;
    selectControls[name] = checked;
    this.setState({
      selectControls,
    }, () => {
      this.buildRows();
      let array = this.state.selected;
      let index = data.findIndex(x => x.mainKey === name);
      if (checked) {
        array.push(data[index]);
      }
      else {
        if (array.length === 1)
          array = [];
        else
          array.splice(index, 1);
      }
      this.setState({ selected: array }, () => {
        this.props.onSelect(this.state.selected);
      });
    });
  };

  handleChangeMultiple = name => event => {
    let selectedPo = [];
    let { data, all, respaldo } = this.state;
    let checked = event.target.checked;

    data.map((e) => {
      if (name.POId == e.POId) {
        e.checked = checked;
        e.edit = true;
      }
      return e;
    });
    all.map((e) => {
      if (name.POId == e.POId) {
        e.checked = checked;
        e.edit = checked;
      }
      return e;
    });
    respaldo.map((e) => {
      if (name.POId == e.POId) {
        e.checked = checked;
        e.edit = checked;
      }
      return e;
    });

    all.map((e) => {
      if (e.checked) {
        let obj = {
          key: e.POId,
          label: e.PONumber,
          avatar: ' '
        };
        selectedPo.push(obj);
      }
      return e;
    });
    this.setState({
      data: checked == false ? respaldo : data,
      all: checked == false ? respaldo : all,
      respaldo: respaldo,
      selectedPo
    }, () => {
      if (selectedPo.length > 0) {
        this.setState({
          flat: true,
        }, () => this.buildRows());
      }
      else {
        this.setState({
          flat: false,
        }, () => this.buildRows());
      }
    });
  };

  componentDidMount = () => {
    this.buildFormCreate();
    let { options, columns } = this.props;
    let xlsx = options.downloads && options.downloads.xlsx ? <div><em className="fa-2x fas fa-file-excel mr-2" style={{ color: '#66bb6a', cursor: 'pointer' }} onClick={this.XLSX}></em></div> : '';

    if (options.orderAttention && options.orderAttention.activeFilter) {
      this.setPagination(columns, this.props);
      this.switchQuickView(options.orderAttention.activeText);
    } else
      this.setState({
        xlsx,
      }, () => {
        if (options && options.selectItems) {
          columns.map((f, index) => {
            if (f.key === 'select') {
              columns.splice(index, 1);
            }
            return f;
          });
          let selectControls = {};
          let lengthData = this.props.data.length;
          for (var i = 0; i < lengthData; i++) {
            this.props.data[i]['mainKey'] = i;
            selectControls[i] = false;
          }
          this.setState({ selectControls, haveCheck: true }, () => {
            columns.unshift({
              label: '',
              key: 'select',
              custom: (value) => {
                return (<Checkbox
                  checked={this.state.selectControls[value.mainKey]}
                  onChange={this.handleChange(value.mainKey)}
                  value={value.mainKey}
                  color="primary"
                />);
              }
            });
            this.setPagination(columns, this.props);
          });
        }
        else this.setPagination(columns, this.props);
      });
  }

  buildSorts = () => {
    let { columns } = this.state;
    let sorts = {};
    columns.forEach((e) => {
      sorts[e.key ? e.key : e.label] = {
        active: false,
        sortBy: 'ASC'
      };
    });
    this.setState({ sorts }, () => {
      this.buildMainColumns();
      this.buildColumns();
    });
  }

  partirArray = (page, all, pages) => {
    let { itemsByPage } = this.state;
    let array = [];
    if (page === 1)
      for (let i = 0; i < (all.length < itemsByPage ? all.length : itemsByPage); i++)
        array.push(all[i]);
    else if (page !== 1 && page !== pages)
      for (let i = (page - 1) * itemsByPage; i < (page) * itemsByPage; i++)
        array.push(all[i]);
    else
      for (let i = (page - 1) * itemsByPage; i < all.length; i++)
        array.push(all[i]);
    return array;
  }

  setPagination = (columns, props) => {
    let { activePage } = this.state;
    this.setState({
      mainColumns: props.mainColumns ? props.mainColumns : [],
      columns,
      itemsByPage: props.itemsByPage ? props.itemsByPage : 10,
      data: props.data,
    }, () => {
      this.buildSorts();
      if (props.options && props.options.pagination) {
        let pages = props.data.length > this.state.itemsByPage ? (props.data.length / this.state.itemsByPage) + 1 : 1;
        let tableData = this.partirArray(activePage, props.data, pages);
        let width = (this.state.columns.length > 2 ? 100 / this.state.columns.length : 50);
        this.setState({
          activePage,
          pages: parseInt(pages),
          tableData,
          width: parseInt(width)
        }, () => this.buildRows());
      }
      else this.setState({ tableData: props.data }, () => this.buildRows());
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.buildFormCreate();
    if (nextProps.MultiUpdate) {
      let data = nextProps.data;
      this.setState({
        selectedPo: [],
        catOrigin: nextProps.catOrigin,
        catSupplier: nextProps.catSupplier,
        catCustomer: nextProps.catCustomer,
        data,
        all: data,
        respaldo: data,
        mas: nextProps.data,
        updateData: nextProps.optionSelected
      });
    }
    if (nextProps.options && nextProps.options.handleResCom !== 'set') {
      if (nextProps.options) {
        if (nextProps.options.reset) {
          let keysSelectControls = Object.keys(this.state.selectControls);
          let newSelectControls = this.state.selectControls;
          keysSelectControls.map((e) => {
            newSelectControls[e] = false;
            return e;
          });
          this.setState({ selected: [], selectControls: newSelectControls });
        }
      }
      let { options, columns } = nextProps;
      let { selectControls } = this.state;
      let xlsx = options.downloads && options.downloads.xlsx ? <div><em className="fa-2x fas fa-file-excel mr-2" style={{ color: '#66bb6a', cursor: 'pointer' }} onClick={this.XLSX}></em></div> : '';

      this.setState({
        xlsx,
      }, () => {
        if (options && options.selectItems && !this.state.haveCheck) {
          let lengthData = nextProps.data.length;
          for (var i = 0; i < lengthData; i++) {
            nextProps.data[i]['mainKey'] = i;
            if (selectControls[i] === true)
              selectControls[i] = true;
            else
              selectControls[i] = false;
          }
          this.setState({ selectControls, xlsx }, () => {
            let index = columns.findIndex(x => x.key === 'select');

            if (index === -1)
              columns.unshift({
                label: 'Select',
                key: 'select',
                custom: (value) => {
                  return (<Checkbox
                    checked={this.state.selectControls[value.mainKey]}
                    onChange={this.handleChange(value.mainKey)}
                    value={value.mainKey}
                    color="primary"
                  />);
                }
              });
            this.setPagination(columns, nextProps);
          });
        }
        else this.setPagination(columns, nextProps);
      });
    }

  }


  buildText = () => {
    let { updateData, order, selectedPo } = this.state;
    let select = '';

    updateData.forEach((e) => {
      if (order.multi == e.value) {
        select = e.label;
      }
    });

    let text = `¿Are you sure want to Update the ${select} on ${selectedPo.length} POs ?`;

    this.setState({
      textMultiple: text
    });

  }

  handleChangeSelect = (selected) => event => {
    let { order } = this.state;
    if (event && event.value) {
      order[selected] = event.value;
      this.setState({
        selectedOpcion: event.value,
        condate: event.value == 'PromiseCargoReadyDate' || event.value == 'ActualCargoReadyDate' || event.value == 'ActualCargoPickup' || event.value == 'EstimatedArrivalConsolWH' || event.value == 'ActualArrivalConsolWH' ? true : false
      }, () => {
        this.buildFormCreate();
        this.buildText();
      });
    } else {
      order[selected] = '';
      this.setState({
        selectedOpcion: ''
      }, () => {
        this.buildFormCreate();
        this.buildText();
      });
    }
  };

  handleChangeDate2 = name => event => {
    let { order } = this.state;
    try {
      let value = event.format('YYYY-MM-DD');
      order[name] = value;
      this.buildFormCreate();
      this.buildText();
    }
    catch (ex) {
      order[name] = '';
      this.buildFormCreate();
      this.buildText();
    }
  }
  handleChangeopcion = name => event => {
    let { order } = this.state;
    order[name] = event.target.value;
    this.buildFormCreate();
    this.buildText();
  }

  buildFormCreate = () => {
    const { formDate, updateData, formSinDate, condate, order } = this.state;
    let dataopcion = condate ? formDate : formSinDate;
    let CreateFormData = dataopcion.map((e, i) => {
      if (e.type === 'Input') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite> <span id={e.label} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ''}</label></span></Fade>
          <Input
            id={e.id}
            disabled={this.state.edit === false ? true : false}
            required
            type={'Number'}
            placeholder={e.placeHolder}
            name={e.id}
            value={order[e.id]}
            maxlength={e.maxlength}
            minlength={e.minlength}
            onChange={this.handleChangeopcion(e.id)}
            max={e.max} />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>
      );
      else if (e.type === 'Select') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <Select
            id={e.id}
            disabled={this.state.edit === false ? true : false}
            required
            type={e.datatype}
            name={e.name}
            value={order[e.id]}
            onChange={this.handleChangeSelect(e.id)}
            options={updateData}
          />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>);
      else if (e.type === 'Datetime') return (
        <div className={e.class} key={e.id}>
          <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ''} </label></span></Fade>
          <Datetime
            timeFormat={false}
            type={e.datatype}
            dateFormat={'YYYY-MM-DD'}
            inputProps={{ className: 'form-control', id: e.id }}
            value={order[e.id]}
            onChange={this.handleChangeDate2(e.id)} />
          <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
        </div>);
      else return '';
    });
    this.setState({
      CreateFormData
    });
  }


  handleChangeInput = (name, edit, exp) => event => {
    let { data, all } = this.state;
    let value = event.target.value;

    if (exp) {
      const emailRegex = new RegExp(exp);
      if (emailRegex.test(value)) {
        data.map((e) => {
          if (name.POId == e.POId) {
            e[edit] = value;
          }
          return e;
        });
        all.map((e) => {
          if (name.POId == e.POId) {
            e[edit] = value;
          }
          return e;
        });
        this.setState({
          data,
          all
        }, () => this.buildRows());
      }
      else {
        if (value == '') {
          data.map((e) => {
            if (name.POId == e.POId) {
              e[edit] = value;
            }
            return e;
          });
          all.map((e) => {
            if (name.POId == e.POId) {
              e[edit] = value;
            }
            return e;
          });
          this.setState({
            data,
            all
          }, () => this.buildRows());
        }
        else {
          let valor = '';
          data.map((e) => {
            valor = '';
            if (name.POId == e.POId) {
              e[edit] = valor;
            }
            return e;
          });
          all.map((e) => {
            if (name.POId == e.POId) {
              e[edit] = valor;
            }
            return e;
          });
          this.setState({
            data,
            all
          }, () => this.buildRows());
        }
      }
    }
    else {
      data.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      all.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      this.setState({
        data,
        all
      }, () => this.buildRows());
    }
  }

  handleChangeDate = (name, edit) => event => {
    let { data, all } = this.state;
    try {
      let value = event.format('YYYY-MM-DD');
      data.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      all.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = value;
        }
        return e;
      });
      this.setState({
        data,
        all
      }, () => this.buildRows());
    }
    catch (ex) {
      data.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = '';
        }
        return e;
      });
      all.map((e) => {
        if (name.POId == e.POId) {
          e[edit] = '';
        }
        return e;
      });
      this.setState({
        data,
        all
      }, () => this.buildRows()
      );
    }
  }

  buildRows = () => {
    let { columns } = this.state;
    let ___rows = this.state.tableData.map((e, _index) => {
      let tds = [];
      if (e) {
        columns.forEach((column, index_) => {
          if (column.launch) {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${column.fixed ? 'col-fixed' : ''}`}
                id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]}
                style={{ fontSize: '12px', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80 }}>
                {column.custom(e, this.props.options && this.props.options.trigger ? this.props.options.trigger : () => { })}
              </td>
            );
          } else if (column.custom) {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${column.fixed ? 'col-fixed' : ''}`} id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]}
                style={{ fontSize: '12px', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80 }}>
                {this.props.MultiUpdate && column.key == 'PONumber' ?
                  <>
                    <Row>
                      <Col style={{ marginTop: '-12px' }} md={2} xl={2}>
                        <Checkbox
                          checked={e.checked}
                          onChange={this.handleChangeMultiple(e)}
                          value={e.checked}
                          color="primary"
                        />
                      </Col>
                      <>{column.custom(e)} </>
                    </Row>
                  </>
                  :
                  <> {column.custom(e)}</>
                }
              </td>
            );
          }
          else if (column.index) {
            tds.push(
              <td
                key={index_} className={`table-td ${this.props.cellType} ${column.fixed ? 'col-fixed' : ''}`}
                id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]}
                style={{ fontSize: '12px', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80 }}>
                {_index + 1}
              </td>
            );
          }
          else {
            tds.push(
              <td
                key={index_}
                className={`table-td ${this.props.cellType} ${column.fixed ? 'col-fixed' : ''}`}
                id={e[column.key] ? (column.idTd ? column.idTd : e[column.key]) : e[column.key]}
                style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center', minWidth: column.minWidth ? column.minWidth : 80 }}>
                {this.state.flat && e.edit ?
                  (column.type === 'datatime'
                    ?
                    <div className="pointer" onClick={() => {
                      let nameDataTime = e;
                      let editime = column.key;
                      this.setState({
                        datatime: true,
                        nameDataTime,
                        editime
                      });
                    }}>
                      <Input
                        type={''}
                        value={e[column.key]}
                        disabled={true}
                      />
                    </div>
                    :
                    (column.key === 'GrossWeight' || column.key === 'NoPallets' || column.key === 'Spaces'
                      ?
                      <Input
                        id={e.POId}
                        type="Input"
                        name={e.POId}
                        value={e[column.key]}
                        onChange={this.handleChangeInput(e, column.key, column.regExp)}
                      />
                      :
                      <> {e[column.key] ? e[column.key] : ''}</>
                    )
                  )
                  :
                  <> {e[column.key] ? column.concat  ? ( e[column.key] + ' ' + column.concatText) :  e[column.key] : ''}</>
                }
              </td>
            );
          }
        });
      }
      return (
        <tr key={_index}>
          {tds}
        </tr>
      );
    });
    this.setState({ ___rows }, () => this.buildColumns());
  }

  _sortBy = id => event => {
    let { data, sorts } = this.state;
    if (sorts[id]) {
      let { sortBy } = sorts[id];
      let formatedData = this.makeSort(data, id, sortBy);
      sorts[id].sortBy = sorts[id].sortBy === 'ASC' ? 'DESC' : 'ASC';
      this.setState({
        sorts,
        sortKey: id,
        sortBy: sorts[id].sortBy
      }, () => {
        let tableData = this.partirArray(this.state.activePage, formatedData, this.state.pages);
        this.setState({ tableData }, () => this.buildRows());
      });
    }
  }

  makeSort = (items, key, by) => {
    switch (by) {
      case 'ASC': return items.sort(function (a, b) {
        if (a[key] > b[key]) {
          return 1;
        }
        if (a[key] < b[key]) {
          return -1;
        }
        return 0;
      });
      case 'DESC':
        return items.sort(function (a, b) {
          if (a[key] < b[key]) {
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          }
          return 0;
        });
      default: return items;
    }
  }

  buildMainColumns = () => {
    let ___mainColumns = [];
    this.state.mainColumns.forEach((e, index) => {
      ___mainColumns.push(
        <th key={index} id={index} style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }} colspan={e.colspan}>{e.label}</th>
      );
    });
    this.setState({ ___mainColumns });
  }

  buildColumns = () => {
    let ___columns = [];
    let { sortKey, sortBy } = this.state;
    this.state.columns.forEach((e, index) => {
      let column = this.props.options.large ? <Column title={e.label} isLarge={e.isLarge} /> : e.label;
      if (e.key === sortKey) {
        ___columns.push(sortBy === 'DESC' ?
          <th className={e.fixed ? 'col-fixed' : ''} key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)} ><Fade left opposite ><em className="icon-arrow-down mr-2"></em></Fade>{column}</th>
          : <th className={e.fixed ? 'col-fixed' : ''} key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)}><Fade left opposite ><em className="icon-arrow-up mr-2"></em></Fade>{column}</th>
        );
      }
      else {
        ___columns.push(
          <th className={e.fixed ? 'col-fixed' : ''} key={index} id={e.idTh ? e.idTh : index} onClick={this._sortBy(e.key)} > <div className='thead-div' style={{ fontSize: '12px', cursor: 'pointer', textAlign: 'center' }} >{column}</div></th>
        );
      }
    });
    this.setState({ ___columns }, () => {
      this.buildTable();
    });
  }

  buildTable = () => {
    if (this.props.bordered) {
    }
    else {
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    let { data, pages } = this.state;
    let tableData = this.partirArray(activePage, data, pages);
    this.setState({ activePage, tableData }, () => this.buildRows());
  }

  handleSearch = name => event => this.setState({ [name]: event.target.value }, () => this.setSearch())
  setSearch = () => {
    let all = this.props.data;
    const { search } = this.state;
    let results = [];
    all.forEach((e) => {
      let found = 0;
      let keys = this.props.toFilter ? this.props.toFilter : Object.keys(e);
      keys.forEach((key) => {
        if ((e[key] ? (e[key] + '').toLowerCase().indexOf(search.toLowerCase()) : -1) >= 0) found += 1;
      });
      if (found !== 0) results.push(e);
    });

    this.setState({ data: results }, () => {
      let pages = results.length > this.state.itemsByPage ? (results.length / this.state.itemsByPage) + 1 : 1;
      let tableData = this.partirArray(1, results, pages);
      this.setState({
        activePage: 1,
        pages: parseInt(pages),
        tableData,
      }, () => this.buildRows());
    });
  }

  selectSearch = (value) => {
    let all = this.state.data;
    let results = [];
    if (value) {
      all.forEach((e) => {
        if (e.OriginCountryId === value.value) {
          results.push(e);
        }
      });
      this.setState({ data: results }, () => {
        let pages = results.length > this.state.itemsByPage ? (Math.ceil(parseInt(results.length) / this.state.itemsByPage)) : 1;
        let tableData = this.partirArray(1, results, pages);
        this.setState({
          activePage: 1,
          pages: parseInt(pages),
          tableData,
          Origin: value.value,
          data: all
        }, () => {
          this.buildRows();
        });
      });
    }
    else {
      this.setState({
        Origin: '',
        data: this.state.all
      }, () => {
        this.setSearch();
      });
    }
  }

  selectSearchSupplier = (value) => {
    let all = this.state.data;
    let results = [];
    if (value) {
      all.forEach((e) => {
        if (e.SupplierCode === value.value) {
          results.push(e);
        }
      });
      this.setState({ data: results }, () => {
        let pages = results.length > this.state.itemsByPage ? (Math.ceil(parseInt(results.length) / this.state.itemsByPage)) : 1;
        let tableData = this.partirArray(1, results, pages);
        this.setState({
          activePage: 1,
          pages: parseInt(pages),
          tableData,
          Supplier: value.value,
          data: all
        }, () => {
          this.buildRows();
        });
      });
    }
    else {
      this.setState({
        Supplier: '',
        data: this.state.all
      }, () => {
        this.setSearch();
      });
    }
  }

  selectSearchCustomerC = (value) => {
    let all = this.state.data;
    let results = [];
    if (value) {
      all.forEach((e) => {
        if (e.CustomerContainerID === value.value) {
          results.push(e);
        }
      });
      this.setState({ data: results }, () => {
        let pages = results.length > this.state.itemsByPage ? (Math.ceil(parseInt(results.length) / this.state.itemsByPage)) : 1;
        let tableData = this.partirArray(1, results, pages);
        this.setState({
          activePage: 1,
          pages: parseInt(pages),
          tableData,
          Supplier: value.value,
          data: all
        }, () => {
          this.buildRows();
        });
      });
    }
    else {
      this.setState({
        Supplier: '',
        data: this.state.all
      }, () => {
        this.setSearch();
      });

    }
  }
  /*=============================================
  =            XLSX Function to download        =
  =============================================*/
  XLSX = () => {
    this.setState(
      {
        xlsx: (
          <div className="ball-clip-rotate">
            <div></div>
          </div>
        ),
      },
      () => {
        let { columns, data } = this.state;
        let headers = [];
        let keys = [];
        columns.map((e) => {
          headers.push(e.label);
          keys.push(e.key);
          return e;
        });
        let obj = {
          data: data,
          options: {
            headers: headers,
            keys: keys,
            image: {
              name: 'magicdome',
            },
          },
        };
        if (headers.length > 0) {
          Axios.post(
            'https://servicereportapi.azurewebsites.net/report/dynamic/xlsx',
            obj
          )
            .then((success) => {
              if (success.data.base64) {
                //const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';

                let fileName;
                this.props.nameXlsx
                  ? (fileName = this.props.nameXlsx)
                  : (fileName =
                    Math.floor(Math.random() * 900000) +
                    100000 +
                    Math.floor(Date.now() / 1000) +
                    '.xlsx');

                const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
                saveAs(linkSource, fileName);
              } else console.warn('Error downloading file', success);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#66bb6a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            })
            .catch((error) => {
              console.error('errorr', error);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#66bb6a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            });
        }
      }
    );
  };
  /*=============================================
  =            Advance Search Component         =
  =============================================*/
  handleAdvancedSearch = (search, attention) => {
    let results = search;
    if (results === undefined) results = 0;
    if (!attention) this.setState({ text: 'Quick view' });

    this.setState({ data: search }, () => {
      let pages =
        results.length > this.state.itemsByPage
          ? results.length / this.state.itemsByPage + 1
          : 1;
      let tableData;

      results === 0
        ? (tableData = [])
        : (tableData = this.partirArray(1, results, pages));

      this.setState(
        {
          activePage: 1,
          pages: parseInt(pages),
          tableData
        },
        () => this.buildRows()
      );
    });
    this.setState({ isOpen: false });
  }

  handleSearchChange = (e, i) => {
    this.setState({ search: i.value });
  }

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }
  handleSetFilterUrl = (url) => {
    this.props.setFilterUrl(url);
  }
  // ? Swith Quick View Data
  switchQuickView = (type) => {
    let id = auth.getAuthentication('customerSelect');
    let url = '';
    if (type === 'ordersAttention') {

      url = `${Services.ORDER.path}order/search/all?type=requiresAttention&id=${id}&attention=${true}`;
      this.setState({ text: 'Orders that require attention' });
      this.handleSetFilterUrl(url);
    } else if (type === 'allOrders') {

      url = `${Services.ORDER.path}order/search/all?type=idCustomer&id=${id}&read_messages=true&user=${this.props.user}`;
      this.setState({ text: 'All Orders' });
      this.handleSetFilterUrl(url);
    } else if (type === 'newOrders') {

      url = `${Services.ORDER.path}order/search/all?type=newOrders&id=${id}&new=${true}`;
      this.setState({ text: 'New Orders' });
      this.handleSetFilterUrl(url);
    } else if (type === 'newCusComment') {

      url = `${Services.ORDER.path}order/comments/status?type=newCusComment&id=${id}&user=${this.props.user}`;
      this.setState({ text: 'New Customer Comments' });
      this.handleSetFilterUrl(url);
    } else if (type === 'newIntComment') {

      url = `${Services.ORDER.path}order/comments/status?type=newIntComment&id=${id}&user=${this.props.user}`;
      this.setState({ text: 'New 3PL Comments' });
      this.handleSetFilterUrl(url);
    } else if (type === 'ONC') {

      url = `${Services.ORDER.path}order/comments/status?type=ONC&id=${id}&user=${this.props.user}`;
      this.setState({ text: 'Orders With New Comments' });
      this.handleSetFilterUrl(url);
    } else {
      // url = `${Services.ORDER.path}/order/search/all?type=idCustomer&id=${id}&read_messages=true&user=${this.props.user}`;
      // this.handleSetFilterUrl(url);
    }

    Axios.get(url).then(success => {
      this.handleAdvancedSearch(success.data.data, true);
    }).catch(error => {
      console.warn('switchQuickView: ', error);
    });
  }

  handleDeleteSelected = chipToDelete => () => {
    const { data, selectedPo } = this.state;
    let newSelectedPo = [];
    let bulDeletd = false;
    selectedPo.map((f) => {
      if (chipToDelete.key === f.key) {
        bulDeletd = false;
      }
      else {
        bulDeletd = true;
      }
      if (bulDeletd) {
        newSelectedPo.push(f);
      }
      return f;
    });
    data.map((e) => {
      if (chipToDelete.key === e.POId) {
        e.checked = false;
        e.edit = false;
      }
      return e;
    });
    this.setState({
      data,
      selectedPo: newSelectedPo
    }, () => this.buildRows());
  }

  checkAll = () => {
    const { data, selectedPo } = this.state;
    let array = [];
    selectedPo.map((f) => {
      data.map((e) => {
        if (f.key === e.POId) {
          array.push(e);
        }
        return e;
      });
      return f;
    });
    this.props.checkAll(array);
  }

  multiSelect = () => {
    this.setState({
      active: true,
    });
  }

  handleOnClose = () => {
    this.setState({
      active: false,
      order: {},
      textMultiple: ''
    });
  };

  handleOnUpdate = () => {
    let { order, data, selectedPo } = this.state;
    let array = [];
    selectedPo.map((f) => {
      data.map((e) => {
        if (f.key == e.POId) {
          array.push(e);
        }
        return e;
      });
      return f;
    });

    array.map((f) => {
      f[order.multi] = order.value;
      return f;
    });
    this.props.checkAll(array);
    this.setState({
      selectedPo: [],
      order: {},
      active: false,
      textMultiple: ''
    });
  };

  handleClose = () => {
    this.setState({
      datatime: false
    });
  };
  /*=============================================
  =            Render Method For Table          =
  =============================================*/
  render() {
    const { search, text, data, selectedPo, textMultiple } = this.state;
    const options = this.props.options ? this.props.options : {};
    const MultiUpdate = this.props.MultiUpdate ? this.props.MultiUpdate : false;
    let style = { height: '37em' };
    return (
      <div className="col-12">
        <Grid columns="equal">
          <Grid.Row>
            {MultiUpdate ? <>
              <UsersSelection
                onDelete={this.handleDeleteSelected}
                users={selectedPo}
              />
              <div style={{ height: 30 }} className="col-md-12"></div>
              <div className="col-md-12">
                <Grid >
                  <Grid.Row columns={6}>
                    <Grid.Column>
                      <Grid.Column style={{ marginLeft: '15px' }} width={6} >
                        <Search
                          input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                          placeholder="..."
                          value={search}
                          open={false}
                          name="search"
                          id="search"
                          onSearchChange={this.handleSearch('search')}
                        />
                      </Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid.Column style={{ marginLeft: '5px' }} width={3}>
                        <Select
                          placeholder="Origin Country"
                          value={this.state.Origin}
                          options={this.state.catOrigin}
                          onChange={(value) => { this.selectSearch(value); }}
                        />
                      </Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid.Column style={{ marginLeft: '5px' }} width={3}>
                        <Select
                          placeholder="Supplier"
                          value={this.state.Supplier}
                          options={this.state.catSupplier}
                          onChange={(value) => { this.selectSearchSupplier(value); }}
                        />
                      </Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid.Column style={{ marginLeft: '5px' }} width={3}>
                        <Select
                          placeholder="Customer Container ID"
                          value={this.state.Customer}
                          options={this.state.catCustomer}
                          onChange={(value) => { this.selectSearchCustomerC(value); }}
                        />
                      </Grid.Column>
                    </Grid.Column>
                    <Grid.Column>
                      <div hidden={this.state.selectedPo.length > 0 ? false : true} className="row flex-container">
                        <em onClick={this.checkAll} className="fa-2x mr-2 fas fa-save colorText"></em>
                        Save Changes
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <div hidden={this.state.selectedPo.length > 1 ? false : true} className="row flex-container">
                        <em onClick={this.multiSelect} className="fa-2x mr-2  fab fa-gg-circle colorText"></em>
                        Multiple Update
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </>
              :
              <Grid.Column width={4}>
                {options.search ?
                  <Search
                    input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                    placeholder="..."
                    value={search}
                    open={false}
                    name="search"
                    id="search"
                    onSearchChange={this.handleSearch('search')} />
                  : ''}
                <Modall
                  customer={this.props.customer}
                  show={this.state.isOpen}
                  onClose={this.toggleModal}
                  triggerParentupdate={this.handleAdvancedSearch}
                  name={this.props.name}
                  modalName={this.props.modalName}
                  centered={true}
                />
              </Grid.Column>
            }
            <Grid.Column width={4} style={{ display: 'flex', alignItems: 'center', margin: '0', padding: '0' }} >
              {options.modal ?
                <img width={22} height={22} src={IMG} alt='options' onClick={this.toggleModal} style={{ cursor: 'pointer' }}></img>
                : ''}
            </Grid.Column>
            <Grid.Column width={7}>
              <div hidden={this.props.options.orderAttention ? false : true}>

                <div className='drop_content'>
                  <QuickView attentionOrders={this.switchQuickView} text={text} />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={1}>
              {this.state.xlsx}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="col-12" style={{ paddingTop: '1%' }}></div>
        <div className={`${this.props.fixed ? 'zui-scroller' : 'col-12'}`}>
          <Table id="_table_overflow" responsive className={`zui-table ${this.props.type === 'GENERIC' ? 'table-height table table-sticky table-bordered' : ''}`}>
            <thead style={{ textAlign: 'center', }}>
              <tr>
                {this.state.___mainColumns}
              </tr>
              <tr style={{ cursor: 'pointer' }}>
                {this.state.___columns}
              </tr>
            </thead>
            <tbody className="table-body">
              {this.state.___rows}
            </tbody>
          </Table>
        </div>
        {
          options && options.pagination ?
            <div className="col-12">
              <hr />
            </div> : ''
        }
        <div className="col-12" >
          {
            options && options.pagination ?
              <Pagination
                ellipsisItem={null}
                activePage={this.state.activePage}
                onPageChange={this.handlePaginationChange}
                totalPages={this.state.pages}>
              </Pagination>
              : ''
          }
        </div>
        {
          options && options.pagination ?
            <div className="col-12">
              <hr />
            </div> : ''
        }
        <Modal
          open={this.state.active}
          size={'large'}
          dimmer={'inverted'}
          centered={false}
          scrolling
          style={style}
        >
          <Modal.Header className='headerModal headerModalMulti'>{'Multiple Update'}
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="col-md-12">
              <strong style={{ marginLeft: '-12px' }}>Purchase Orders Selected</strong>
            </div>
            <div className="col-md-12 flex-container2 " >
              <UsersSelection
                users={selectedPo}
              />
            </div>
            <div className="col-md-12" style={{ height: 10 }} >
            </div>
            <div className="col-md-10" style={{ marginBottom: '12em' }} >
              <div className="row">
                {this.state.CreateFormData}
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions className='modal-actions'>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div style={{ textAlign: 'center' }} className="col-md-2 col-lg-2 col-sm-4">
                <Button variant="contained" color="danger" style={{ fontSize: '16px' }} className="button" onClick={this.handleOnClose} >
                  Cancel
                </Button>
              </div>
              <div hidden={(this.state.order.multi && this.state.order.value) ? false : true} className="col-md-2 col-lg-1 col-sm-4 buttonExter">
                <DialogComponent
                  multi={true}
                  disabled={false}
                  color={'primary'}
                  actionText={'Update'}
                  title='Multiple Update'
                  message={textMultiple}
                  cancelText='Cancel'
                  agreeText='Yes, I´m sure'
                  agree={() => this.handleOnUpdate()}
                />
              </div>
            </div>
          </Modal.Actions>
        </Modal>
        <Dialog
          fullWidth={false}
          maxWidth={'xs'}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.datatime}>
          <div style={{ height: 20, width: '21em' }}></div>
          <div className="col-md-12" key={1}>
            <Datetime
              placeholder='Seleted Date'
              open={true}
              timeFormat={false}
              type={'dataTime'}
              dateFormat={'YYYY-MM-DD'}
              inputProps={{ className: 'form-control', id: 1 }}
              value=""
              onChange={this.handleChangeDate(this.state.nameDataTime, this.state.editime)}
            />
          </div>
          <div style={{ height: 250 }}></div>
        </Dialog>
      </div >
    );
  }
}
TableComponent.propTypes = {
  cellType: propTypes.oneOf(['tick-rows', 'thin-rows']),
  fixed: propTypes.bool
};
TableComponent.defaultProps = {
  cellType: 'thick-rows',
  fixed: false
};

const mapStateToProps = () => ({
});
const mapDispatchToProps = dispatch => ({
  setFilterUrl: (url) => dispatch(SetFilterUrl(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
