const Host = {
  CATALOG : 'https://magicdomeapicatalog.azurewebsites.net',
  // CATALOG : 'http://localhost:3006',
  ADMIN : 'https://magicdomeapiadmin.azurewebsites.net',
  // ADMIN : 'http://localhost:3022',
  DIRECTORY : 'https://magicdomeapidirectory.azurewebsites.net',
  ORDER : 'https://magicdomeapiorder.azurewebsites.net',
  // ORDER : 'http://localhost:3013',
  CUSTOMER : 'https://www.magicanalytics.app/magicdome/api/customer/v1',
  FILES : 'https://magicdomeapifiles.azurewebsites.net',
  MAILS : 'https://www.magicanalytics.app/magicdome/api/mail/v1',
  BOOKING : 'https://magicdomeapibooking.azurewebsites.net',
  //BOOKING : 'http://localhost:3017',
  SHIPMENT : 'https://magicdomeapishipments.azurewebsites.net',
  //SHIPMENT : 'http://localhost:4022',
  COMMENTS : 'https://magicdomeapicomments.azurewebsites.net',
  EVENTS : 'https://magicdomeapievents.azurewebsites.net',
  RATES : 'https://magicdomeapirates.azurewebsites.net',
  WAREHOUSING : 'https://magicdomeapiwarehousing.azurewebsites.net',
};

export default Host;
