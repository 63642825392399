import React from 'react';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import ContentWrapper from '../../template/Layout/ContentWrapper';
import PoProcedures from './components/POProcedures';
import ShipmentProcedures from './components/ShipmentProcedures';

/**
 * Procedures Component
 */
function Procedures(props){
  return (
    <ContentWrapper>
      <div>
        <div className="content-heading">
          <div className="row">
            <em className="fa-1x mr-2 fas fa-cogs"></em>
            Run Procedure
          </div>
        </div>
      </div>
      
      <Tab
        loading={true}
        menu={{ secondary: true, className: 'myWrapped' }}
        menuPosition='right'
        panes={[
          { menuItem: 'POs Procedures', render: () => <PoProcedures _user={props._user}/> },
          { menuItem: 'Shipment Procedures', render: () => <ShipmentProcedures _customer={props._customer} _user={props._user}/> },
        ]} />
    </ContentWrapper>
  );  
}

export default withRouter(Procedures);