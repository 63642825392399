export const entitiesResponse = [
  {
      "CustomerEntityId": 74,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "CON003",
      "CustomerEntityName": "TEST CONSIGNEE",
      "CustomerEntityAddress": "123 Street",
      "CustomerEntityContact": "Consignee Contact Name ",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 564,
      "CodeEP": "0011v00002Xh9tkAAB",
      "CustomerEPName": "MONTERREY CONSIGNEE TEST",
      "CustomerEPAddress": "123 Main St, CP: 64820",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-12-28T22:53:05.849Z"
  },
  {
      "CustomerEntityId": 73,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "SUP0039",
      "CustomerEntityName": "TEST SHIPPER",
      "CustomerEntityAddress": "1216 Bob Bullock Loop, CP: 78044",
      "CustomerEntityContact": "Supplier Contact Name ",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-12-28T16:33:49.452Z"
  },
  {
      "CustomerEntityId": 71,
      "CustomerId": 946,
      "CustomerCodeEP": "0010Y00000DyzZ0QAJ",
      "CustomerEP": "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      "CustomerEntityCode": "SUP0041",
      "CustomerEntityName": "ONDEO NALCO COMPANY",
      "CustomerEntityAddress": "1601 W. DIEHL. RD. NAPERVILLE IL 60563-1198  EE.UU",
      "CustomerEntityContact": "Alberto Escobedo",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": null,
      "CodeEP": null,
      "CustomerEPName": null,
      "CustomerEPAddress": null,
      "CreateDateCustomerEP": "2023-07-12T17:26:18.433Z",
      "CreateDateCustomerEntity": "2023-12-12T21:47:32.266Z"
  },
  {
      "CustomerEntityId": 68,
      "CustomerId": 946,
      "CustomerCodeEP": "0010Y00000DyzZ0QAJ",
      "CustomerEP": "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      "CustomerEntityCode": "SUP0042",
      "CustomerEntityName": "BENTELER AG",
      "CustomerEntityAddress": "FRACHSTRASSE10 16 D4800 D-00000 WEST GERMANY",
      "CustomerEntityContact": "Amin Islam",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 1298,
      "CodeEP": "001Pw000006lBuNIAU",
      "CustomerEPName": "BENTELER AG,",
      "CustomerEPAddress": ", CP: D4800",
      "CreateDateCustomerEP": "2023-07-12T17:26:18.433Z",
      "CreateDateCustomerEntity": "2023-12-12T17:20:42.382Z"
  },
  {
      "CustomerEntityId": 69,
      "CustomerId": 946,
      "CustomerCodeEP": "0010Y00000DyzZ0QAJ",
      "CustomerEP": "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      "CustomerEntityCode": "CON005",
      "CustomerEntityName": "CRISTALES INASTILLABLES DE MEXICO",
      "CustomerEntityAddress": "Vitro Auto Planta Autotemplex Carretera Villa de García Km 10.3",
      "CustomerEntityContact": "LUIS ALBERTO PARRA HERNANDEZ",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 1284,
      "CodeEP": "001Pw000006lLDnIAM",
      "CustomerEPName": "CRISTALES INASTILLABLES DE MEXICO;",
      "CustomerEPAddress": "CARR A GARCIA KM 10 3 SN, CP: 66000",
      "CreateDateCustomerEP": "2023-07-12T17:26:18.433Z",
      "CreateDateCustomerEntity": "2023-12-12T17:20:42.382Z"
  },
  {
      "CustomerEntityId": 59,
      "CustomerId": 946,
      "CustomerCodeEP": "0010Y00000DyzZ0QAJ",
      "CustomerEP": "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      "CustomerEntityCode": "SUP0040",
      "CustomerEntityName": "GLASSTECH, INC",
      "CustomerEntityAddress": "995 FOURTH STREET PERRYSBURG OH 43551 EE.UU.",
      "CustomerEntityContact": " Pam Scanlon",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 1282,
      "CodeEP": "001Pw000006lJTZIA2",
      "CustomerEPName": "GLASSTECH, INC.,",
      "CustomerEPAddress": "43551 PERRYSBURG, CP: 43551",
      "CreateDateCustomerEP": "2023-07-12T17:26:18.433Z",
      "CreateDateCustomerEntity": "2023-12-12T14:46:54.271Z"
  },
  {
      "CustomerEntityId": 60,
      "CustomerId": 946,
      "CustomerCodeEP": "0010Y00000DyzZ0QAJ",
      "CustomerEP": "VITRO AUTOMOTRIZ // CRISTALES INASTILLABLES DE MEXICO",
      "CustomerEntityCode": "CON004",
      "CustomerEntityName": "Vitro Auto Planta VFX EO",
      "CustomerEntityAddress": "Carretera a Garcia Km 10.3  Garcia,NL  CP 66000",
      "CustomerEntityContact": "LUIS ALBERTO PARRA HERNANDEZ",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 1284,
      "CodeEP": "001Pw000006lLDnIAM",
      "CustomerEPName": "CRISTALES INASTILLABLES DE MEXICO;",
      "CustomerEPAddress": "CARR A GARCIA KM 10 3 SN, CP: 66000",
      "CreateDateCustomerEP": "2023-07-12T17:26:18.433Z",
      "CreateDateCustomerEntity": "2023-12-12T14:46:54.271Z"
  },
  {
      "CustomerEntityId": 56,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "sup1",
      "CustomerEntityName": "a",
      "CustomerEntityAddress": "sup3",
      "CustomerEntityContact": "a",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 697,
      "CodeEP": "0011v00001mysYrAAI",
      "CustomerEPName": "CONSIGNEE LAREDO",
      "CustomerEPAddress": "1216 Bob Bullock Loop, CP: 78044",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-10T01:08:02.626Z"
  },
  {
      "CustomerEntityId": 57,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "sup2",
      "CustomerEntityName": "a",
      "CustomerEntityAddress": "sup4",
      "CustomerEntityContact": "a",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-10T01:08:02.626Z"
  },
  {
      "CustomerEntityId": 54,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "SUP0038",
      "CustomerEntityName": "Shipper Test Name02",
      "CustomerEntityAddress": "Calle 1234",
      "CustomerEntityContact": "Supplier Contact Name ",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-08T21:13:08.929Z"
  },
  {
      "CustomerEntityId": 55,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "CON007",
      "CustomerEntityName": "Consignee Test Name02",
      "CustomerEntityAddress": "Calle 5874",
      "CustomerEntityContact": "Consignee Contact Name",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 564,
      "CodeEP": "0011v00002Xh9tkAAB",
      "CustomerEPName": "MONTERREY CONSIGNEE TEST",
      "CustomerEPAddress": "123 Main St, CP: 64820",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-08T21:13:08.929Z"
  },
  {
      "CustomerEntityId": 52,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "SUP0037",
      "CustomerEntityName": "Shipper Test Name01",
      "CustomerEntityAddress": "Calle 1234",
      "CustomerEntityContact": "Supplier Contact Name ",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-07T23:40:37.275Z"
  },
  {
      "CustomerEntityId": 53,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "CON006",
      "CustomerEntityName": "Consignee Test Name01",
      "CustomerEntityAddress": "Calle 5874",
      "CustomerEntityContact": "Consignee Contact Name",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 564,
      "CodeEP": "0011v00002Xh9tkAAB",
      "CustomerEPName": "MONTERREY CONSIGNEE TEST",
      "CustomerEPAddress": "123 Main St, CP: 64820",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-07T23:40:37.275Z"
  },
  {
      "CustomerEntityId": 51,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "CON001",
      "CustomerEntityName": "Test Consignee",
      "CustomerEntityAddress": "Calle 5874",
      "CustomerEntityContact": "Consignee Contact Name",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 563,
      "CodeEP": "0010Y00001cX56CQAS",
      "CustomerEPName": "CONSIGNEE PRUEBA",
      "CustomerEPAddress": "calle viva esperanza 234, CP: ",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-03T21:34:29.832Z"
  },
  {
      "CustomerEntityId": 50,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "SUP0001",
      "CustomerEntityName": "Test Shipper",
      "CustomerEntityAddress": "Calle 1234",
      "CustomerEntityContact": "Francis Delgado",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 564,
      "CodeEP": "0011v00002Xh9tkAAB",
      "CustomerEPName": "MONTERREY CONSIGNEE TEST",
      "CustomerEPAddress": "123 Main St, CP: 64820",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-03T21:34:29.832Z"
  },
  {
      "CustomerEntityId": 48,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "SUP0035",
      "CustomerEntityName": "Shipper Name",
      "CustomerEntityAddress": "C. Chilpancingo 151, Hipódromo, Cuauhtémoc, 06100 Ciudad de México, CDMX",
      "CustomerEntityContact": "Agente de Prueba",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-03T17:33:35.184Z"
  },
  {
      "CustomerEntityId": 49,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "CON002",
      "CustomerEntityName": "Consignee Name",
      "CustomerEntityAddress": "calle viva esperanza 234 chile, providencia chile",
      "CustomerEntityContact": "Consignee Contact ",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 563,
      "CodeEP": "0010Y00001cX56CQAS",
      "CustomerEPName": "CONSIGNEE PRUEBA",
      "CustomerEPAddress": "calle viva esperanza 234, CP: ",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-11-03T17:33:35.184Z"
  },
  {
      "CustomerEntityId": 47,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "Con123",
      "CustomerEntityName": "prubas",
      "CustomerEntityAddress": "direcc",
      "CustomerEntityContact": "contacrtN",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-26T15:36:41.753Z"
  },
  {
      "CustomerEntityId": 46,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "asdasd",
      "CustomerEntityName": "asdsa",
      "CustomerEntityAddress": "asd",
      "CustomerEntityContact": "cpsn",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T17:53:43.962Z"
  },
  {
      "CustomerEntityId": 45,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "pruebas",
      "CustomerEntityName": "asdsa",
      "CustomerEntityAddress": "asdasd",
      "CustomerEntityContact": "asdas",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T17:53:43.962Z"
  },
  {
      "CustomerEntityId": 42,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "cos-123",
      "CustomerEntityName": "name11",
      "CustomerEntityAddress": "adde",
      "CustomerEntityContact": "contan",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T17:42:29.199Z"
  },
  {
      "CustomerEntityId": 41,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "cc-123",
      "CustomerEntityName": "pryebas",
      "CustomerEntityAddress": "direcc",
      "CustomerEntityContact": "contat",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T17:42:21.685Z"
  },
  {
      "CustomerEntityId": 40,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "a",
      "CustomerEntityName": "a",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T17:17:13.411Z"
  },
  {
      "CustomerEntityId": 38,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "CodeSpp",
      "CustomerEntityName": "a",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 697,
      "CodeEP": "0011v00001mysYrAAI",
      "CustomerEPName": "CONSIGNEE LAREDO",
      "CustomerEPAddress": "1216 Bob Bullock Loop, CP: 78044",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T16:59:52.360Z"
  },
  {
      "CustomerEntityId": 39,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "Cpde44",
      "CustomerEntityName": "prueba",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T16:59:52.360Z"
  },
  {
      "CustomerEntityId": 37,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "Cdessp11",
      "CustomerEntityName": "pruebaConsegnnee",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T00:23:59.717Z"
  },
  {
      "CustomerEntityId": 36,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "Cdessp21",
      "CustomerEntityName": "pruebaConsegnnee3",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T00:21:03.675Z"
  },
  {
      "CustomerEntityId": 35,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "Cdessp31",
      "CustomerEntityName": "pruebaConsegnnee2",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T00:18:17.924Z"
  },
  {
      "CustomerEntityId": 21,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "cdeSSp1",
      "CustomerEntityName": "PruebaSuppller1",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 565,
      "CodeEP": "0011v00002vVKlzAAG",
      "CustomerEPName": "TEST SHIPPER EPQN V2",
      "CustomerEPAddress": "Valle del Mar 5008, CP: 64117",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-25T00:04:52.968Z"
  },
  {
      "CustomerEntityId": 20,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "Cdessp51",
      "CustomerEntityName": "pruebaConsegnnee1",
      "CustomerEntityAddress": "a",
      "CustomerEntityContact": "a",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 563,
      "CodeEP": "0010Y00001cX56CQAS",
      "CustomerEPName": "CONSIGNEE PRUEBA",
      "CustomerEPAddress": "calle viva esperanza 234, CP: ",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-24T23:59:41.126Z"
  },
  {
      "CustomerEntityId": 11,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "prueba cons 5",
      "CustomerEntityName": "prueba cons 4",
      "CustomerEntityAddress": "Dirección de prueba Consignatario",
      "CustomerEntityContact": "Contacto de prueba Consignatario",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 563,
      "CodeEP": "0010Y00001cX56CQAS",
      "CustomerEPName": "CONSIGNEE PRUEBA",
      "CustomerEPAddress": "calle viva esperanza 234, CP: ",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-10-06T18:36:56.679Z"
  },
  {
      "CustomerEntityId": 10,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "conisgee 3",
      "CustomerEntityName": "prueb a 3",
      "CustomerEntityAddress": "pruebs 3 direccion",
      "CustomerEntityContact": "0000011111",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-09-05T16:28:58.255Z"
  },
  {
      "CustomerEntityId": 9,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "consine 2",
      "CustomerEntityName": "prueba consi 2",
      "CustomerEntityAddress": "prueba consignee 222",
      "CustomerEntityContact": null,
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-09-05T16:27:25.342Z"
  },
  {
      "CustomerEntityId": 8,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "cons234",
      "CustomerEntityName": "prueba cons",
      "CustomerEntityAddress": "Calle Girasol 2345 ",
      "CustomerEntityContact": "Oscar Fuentes ",
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 231,
      "CodeEP": "0011v00002EabUiAAJ",
      "CustomerEPName": "TEST CONSIGNEE",
      "CustomerEPAddress": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-08-15T23:30:04.285Z"
  },
  {
      "CustomerEntityId": 7,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "dev123",
      "CustomerEntityName": "prueba dev",
      "CustomerEntityAddress": "Pabellon de la orquideas 3463",
      "CustomerEntityContact": "Marina Martínez",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-08-15T23:29:05.259Z"
  },
  {
      "CustomerEntityId": 5,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "245021",
      "CustomerEntityName": "GESA EOLICA MÉXICO",
      "CustomerEntityAddress": "Pº de la Reforma 5054",
      "CustomerEntityContact": "Moises Rodriguez",
      "RecordTypeId": 4,
      "RecordType": "Shipper",
      "EntityRelationshipId": 513,
      "CodeEP": "0011v00002EabSgAAJ",
      "CustomerEPName": "TEST SHIPPER",
      "CustomerEPAddress": "CHILPANCINGO 151, COL. HIPODROMO, CP: 06100",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-08-15T18:28:56.559Z"
  },
  {
      "CustomerEntityId": 2,
      "CustomerId": 134,
      "CustomerCodeEP": "0010Y00000Ev1ejQAB",
      "CustomerEP": "CLIENTE DE PRUEBA",
      "CustomerEntityCode": "PPRR-001",
      "CustomerEntityName": "CLIENTE PRUEBA",
      "CustomerEntityAddress": "ADREES PRUEBA",
      "CustomerEntityContact": null,
      "RecordTypeId": 5,
      "RecordType": "Consignee",
      "EntityRelationshipId": 563,
      "CodeEP": "0010Y00001cX56CQAS",
      "CustomerEPName": "CONSIGNEE PRUEBA",
      "CustomerEPAddress": "calle viva esperanza 234, CP: ",
      "CreateDateCustomerEP": "2023-08-08T13:48:11.000Z",
      "CreateDateCustomerEntity": "2023-07-27T17:33:10.910Z"
  }
]

export const entitiesIdResponse = [
  {
      "entityCode": "0011v00002EabUiAAJ",
      "entityName": "TEST CONSIGNEE",
      "address": "SANTA BEATRIZ 170, PROVIDENCIA, CP: 7500000",
      "telephone": "+(56)22364963",
      "city": "SANTIAGO",
      "state": "",
      "country": "CHILE",
      "contacts": ""
  },
  {
      "entityCode": "0011v00002Xh9tkAAB",
      "entityName": "MONTERREY CONSIGNEE TEST",
      "address": "123 Main St, CP: 64820",
      "telephone": "",
      "city": "Monterrey",
      "state": "NL",
      "country": "MX",
      "contacts": [
          {
              "contactCode": "0031v00002ZX00HAAT",
              "name": "Marcos Rodriguez",
              "telephone": "",
              "email": "m.r@europartners.com.mx"
          }
      ]
  },
  {
      "entityCode": "0010Y00001cX56CQAS",
      "entityName": "CONSIGNEE PRUEBA",
      "address": "calle viva esperanza 234, CP: ",
      "telephone": "",
      "city": "chile",
      "state": "providencia",
      "country": "chile",
      "contacts": [
          {
              "contactCode": "0037Z00001tOWXAQA4",
              "name": "Manuel De la Torre",
              "telephone": "",
              "email": "manuel.delatorre@gmail.com"
          },
          {
              "contactCode": "0037Z00001tQbHrQAK",
              "name": "Roberto Lopez",
              "telephone": "",
              "email": "roberto@gmail.com"
          }
      ]
  },
  {
      "entityCode": "001Pw000006p6ZNIAY",
      "entityName": "VITRO UNI-TRADE FORWARDING",
      "address": "130 Flecha Lane, CP: 78045",
      "telephone": "",
      "city": "Laredo",
      "state": "TX",
      "country": "United States",
      "contacts": [
          {
              "contactCode": "003Pw000006QFhWIAW",
              "name": "Manuel Garza",
              "telephone": "",
              "email": "manuel.garza@ltx.uni-trade.com"
          }
      ]
  }
]