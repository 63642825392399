export const getCommentsResponse = {
  Comment: [
    {
      ComType: 'Tipo 1',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-06-20',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    },
    {
      ComType: 'Tipo 2',
      ComTypeId: 1,
      Comment: 'Comentario de prueba',
      CommentId: 73,
      Date: '2019-07-02',
      Module: 'Comentario',
      ModuleId: 1,
      RowId: 861,
      UpdateBy: 'API',
    }
  ],
  CommentGeneral: [],
};

export const getMentionsResponse = [
  {
    id: 181,
    display: "@AlonsoRomero Gomez",
  },
  {
    id: 145,
    display: "@Carlos Gutierrez",
  },
  {
    id: 180,
    display: "@Customer Test",
  },
  {
    id: 125,
    display: "@Debbie Harry",
  },
  {
    id: 182,
    display: "@Executive sales Test",
  },
  {
    id: 103,
    display: "@George Harrison",
  },
  {
    id: 108,
    display: "@javtest test",
  },
  {
    id: 98,
    display: "@Kurt Cobain",
  },
  {
    id: 192,
    display: "@Leandro Test",
  },
  {
    id: 124,
    display: "@Paul Maccartney",
  },
  {
    id: 176,
    display: "@prueba 2",
  },
  {
    id: 183,
    display: "@Purchasing executive customer Test",
  },
  {
    id: 100,
    display: "@Rodrigo Rangel",
  },
  {
    id: 77,
    display: "@Ruth Martinez",
  },
  {
    id: 187,
    display: "@TEST ASA",
  },
  {
    id: 186,
    display: "@TEST CORREO TEST",
  },
  {
    id: 185,
    display: "@TEST CUSTOMER",
  },
  {
    id: 189,
    display: "@TEST TEST",
  },
  {
    id: 190,
    display: "@TEST TEST",
  },
];
