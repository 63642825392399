import React, { Component } from 'react'
import { Table, Card, CardBody } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Data from './data'
import IMAGENGLO from "./file"

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: Data,
            rows: [],
            totalInvoices: 0,
            totalInvoicesAmount: 0,
        }
    }
    componentDidMount = () => {

        this.buildTable()

    }

    buildTable = () => {
        let { data } = this.state
        let rows = data.map((e) => {
            return (
                <tr key={e.invoice}>
                    <td><Fade left opposite> <a href="#">{e.country}</a></Fade></td>
                    <td><Fade left opposite>{e.poCount}</Fade></td>
                    <td><Fade left opposite>{`\$ ${parseInt(e.poAmount).toLocaleString('en-US')}.00`}</Fade></td>
                </tr>
            )
        })
        this.setState({
            rows
        })
    }

    render() {
        return (
            <Card>
                <CardBody>
                    {/* <div className="row"> */}
                    <div className="row"> 
                <img className="imgggggg1" src= {IMAGENGLO} />
                    <span><h4 className="mb-3 py-2">Global Purchasing</h4></span>
                    </div>
                        <div className="col-12">
                            <Table responsive>
                                <thead className="titleTable">
                                    <tr>
                                        <th>By Country</th>
                                        <th>PO Count</th>
                                        <th>PO Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.rows}
                                    <tr>
                                        <td><Fade left opposite> <span>Grand Total</span></Fade></td>
                                        <td><Fade left opposite> <span style={{fontWeight:'bold'}}>
                                                167
                                            </span></Fade></td>
                                        <td><Fade left opposite> <span style={{fontWeight:'bold'}}>
                                    $1,246,149.00
                                </span></Fade></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="col-12">
                            <hr></hr>
                        </div>
                       
                    {/* </div> */}
                </CardBody>
            </Card>

        )
    }
}

export default DataTable;