import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import FlotChart from './Float';
import { ChartSpline, ChartBar } from './ChartFlot.setup.js';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { Doughnut } from './ChartChartJS.setup';


class Graphic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sparkOptions: {
        barColor: '#cfdbe2',
        height: 20,
        barWidth: 3,
        barSpacing: 2
      },
      sparkValues: [1, 0, 4, 9, 5, 7, 8, 9, 5, 7, 8, 4, 7],
      data: this.props.data,
      Graphic1: [],
      enero: 0,
      chart1: '',
      chart2: '',
      chart3: '',
      ChartSpline: ChartSpline,
      ChartBar: ChartBar,
      Doughnut: Doughnut
    };
  };
    componentDidMount = () => {
      this.buildMounths();
      this.buildSuppliers();
      this.buildCruises();
    }

    buildCruises = () => {
      let { data, Doughnut } = this.state;
      let json = {};
      data.forEach((e) => {
        if (!json[e.cruise])
          json[e.cruise] = { val: 0, label: e.cruise };
      });
      data.forEach((e) => {
        let found = json[e.cruise];
        if (found) {
          json[e.cruise].val += parseInt(e.invoiceAmount);
        }
      });
      let color = {
        1: '#7266ba',
        2: '#fad732',
        3: '#23b7e5',
        4: '#fad732'
      };

      let _labels = [];
      let _data = [];
      let _backgroundColor = [];
      let _hoverBackgroundColor = [];

      Object.keys(json).forEach((e, index) => {
        _data.push(json[e].val);
        _backgroundColor.push(color[index]);
        _hoverBackgroundColor.push(color[index]);
        _labels.push(json[e].label);
      });

      Doughnut.data.labels = _labels;
      Doughnut.data.datasets[0].data = _data;
      Doughnut.data.datasets[0].backgroundColor = _backgroundColor;
      Doughnut.data.datasets[0].hoverBackgroundColor = _hoverBackgroundColor;
      this.setState({
        Doughnut,
        chart3: <DoughnutChart data={Doughnut.data} options={Doughnut.options} width={700} height={450} />
      });
    }

    buildSuppliers = () => {
      let { data, ChartBar } = this.state;
      let json = {};
      data.forEach((e) => {
        if (!json[e.supplier])
          json[e.supplier] = { val: 0, label: e.supplier };
      });
      data.forEach((e) => {
        let found = json[e.supplier];
        if (found) {
          json[e.supplier].val += 1;
        }
      });
      let _data = [];
      Object.keys(json).forEach((e) => {
        let array = [];
        array.push(json[e].label);
        array.push(json[e].val);
        _data.push(array);
      });
      _data.sort(function (a, b) {
        if (a[1] < b[1]) {
          return 1;
        }
        if (a[1] > b[1]) {
          return -1;
        }
        return 0;
      });
      if (_data.length > 5)
        _data = _data.slice(0, 5);
      ChartBar.data[0].data = [];
      ChartBar.data[0].data = _data;
      this.setState({
        ChartBar,
        chart2: ''
      }, () => {
        this.setState({
          chart2: <FlotChart options={ChartBar.options} data={ChartBar.data} className="flot-chart" height="250px" />
        });
      });
    }

    buildMounths = () => {
      const InvoiceTrend = this.state.data;
      let { ChartSpline } = this.state;
      let json = {
        1: { val: 0, label: 'Jan' },
        2: { val: 0, label: 'Feb' },
        3: { val: 0, label: 'Mar' },
        4: { val: 0, label: 'Apr' },
        5: { val: 0, label: 'May' },
        6: { val: 0, label: 'Jun' },
        7: { val: 0, label: 'Jul' },
        8: { val: 0, label: 'Aug' },
        9: { val: 0, label: 'Sep' },
        10: { val: 0, label: 'Oct' },
        11: { val: 0, label: 'Nov' },
        12: { val: 0, label: 'Dec' },
      };
        
      InvoiceTrend.map(function (index) {
        let date = index.invoiceDate.split('/')[1] + '/' + index.invoiceDate.split('/')[0] + '/' + index.invoiceDate.split('/')[2];
        let Mes = new Date(date).getMonth() + 1;
        let found = json[Mes];
        if (found) {
          json[Mes].val += parseInt(index.invoiceAmount);
        }
        return index;
      });
      let data = [];
      Object.keys(json).forEach((e) => {
        let array = [];
        array.push(json[e].label);
        array.push(json[e].val);
        data.push(array);
      });
      ChartSpline.data[0].data = data;
      this.setState({
        ChartSpline,
        chart1: ''
      }, () => {
        this.setState({
          chart1: <FlotChart options={ChartSpline.options} data={ChartSpline.data} className="flot-chart" height="250px" />
        });
      });
    }

    componentWillReceiveProps = (nextProps) => {
      this.setState({
        data: nextProps.data
      }, () => {
        this.buildMounths();
        this.buildCruises();
        this.buildSuppliers();
      });
    }

    render() {
      return (
      // <ContentWrapper>
        <Container fluid>
          <Row>
            <Col lg={4}>
              {/* <Card className="card-default"> */}
              {/* <CardHeader>Invoice Trend</CardHeader> */}
              {/* <CardBody> */}
              <center><h4 className="mb-3 py-2">Invoice Trend</h4></center>
                        
              <center>{this.state.chart1}</center>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
            <Col lg={4}>
              {/* <Card className="card-default"> */}
              {/* <CardHeader>TOP SUPPLIERS</CardHeader> */}
              {/* <CardBody> */}
              <center><h4 className="mb-3 py-2">Invoice Suppliers</h4></center>
              <center>{this.state.chart2}</center>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
            <Col lg={4}>
              <center><h4 className="mb-3 py-2">Invoice Amount Per Cruise</h4></center>
              {/* <Card body> */}
              {/* <DoughnutChart data={this.state.chart3} options={Doughnut.options} width={600} height={300}/> */}
              <div className="text-center py-5">
                {this.state.chart3}
              </div>
                            
              {/* </Card> */}
            </Col>
          </Row>
        </Container>
      // </ContentWrapper>
      );
    }

}

export default Graphic;
