import React from 'react';

function RealizarOtraConsulta(props) {
  const { triggerNextStep, previousStep } = props;

  const setOptions = React.useCallback(async () => {
    const options = previousStep.value;
    
    triggerNextStep({ value: options, trigger: 'anotherQuery' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>Do you need to make another inquiry?</div>
    </>
  );
}

export default RealizarOtraConsulta;
