import lod from 'lodash';

function formatValues (array, value, key) {
  let otherArray = [];
  if (key == 'purchase') {
    lod.forEach(array, (val) => {
      val.purchaseOrder.forEach((x) => {
        if (x.toLowerCase().indexOf(value) != -1) {
          otherArray.push(val);
        }
      });
    });
  } else if (key == 'date') {
  } else {
    lod.forEach(array, (val) => {
      if (val[key].toLowerCase().indexOf(value) != -1)
        otherArray.push(val);
    });
  }

  
  
  return otherArray;
}
export const FilterHelper = (criterials, data) => {
  let search = {};
  let keys = ['invoice', 'purchase', 'supplier', 'cruise', 'date'];
  let newArray = [];

  for (let i= keys.length; i >= 0; i--) {
    if (criterials[keys[i]]) {
      if (keys[i] == 'date')
        criterials[keys[i]] != '' ? search[keys[i]] = criterials[keys[i]]['dateStart'].toLowerCase(): search[keys[i]] = criterials[keys[i]]['dateEnd'].toLowerCase();
      else
        search[keys[i]] = criterials[keys[i]].toLowerCase();

    }
  }

  lod.forEach(keys, (value) => {
    if (search[value]) {
      if (value === 'invoice') {
        newArray=formatValues(data.data, search[value], value);
      }
      if (value === 'cruise') {
        newArray=formatValues(newArray.lenght > 0 ? newArray: data.data, search[value], value);
      }
      if(value == 'supplier') {
        newArray=formatValues(newArray.lenght > 0 ? newArray: data.data, search[value], value);
      }
      if (value == 'purchase') {
        newArray=formatValues(newArray.lenght > 0 ? newArray: data.data, search[value], value);
      }
      if (value == 'date') {
      }
    }
  });

  return newArray;

};
