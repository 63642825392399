import React from 'react';
import propTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

import Axios from 'axios';
import Table from '../../../../template/Table';
import {Services} from '../../Services';
import Authentication from '../../../../../services/Authentication.js';
import Loader from './../../../../Global/TableLoader';
import {columns} from './config';

const auth = new Authentication();

/**
 * PO Procedure Component
 */
function PoProcedures(props){
  let[loading, setLoading] = React.useState(true);
  let[data, setData] = React.useState([]);

  /**
   * Get PO List
   */
  const getPOsList = React.useCallback(async () => {
    try{
      let id  = auth.getAuthentication('customerSelect');
      let success = await Axios.get(`${Services.ORDER.path}/order/search/procedures?type=poProcedures&id=${id}`);
      setData(success.data.data);
      setLoading(false);
    }catch(error){
      console.error(error);
    }
  }, []);

  /**
   * Delete PO
   * @param {*} POdata 
   */
  async function deletePO(POdata){
    try{
      // const url = 'http://localhost:3004';
      // await Axios.get(`${url}/order/delete/po?POId=${POdata.POId}&user=${props._user}`);
      await Axios.get(`${Services.ORDER.path}/order/delete/po?POId=${POdata.POId}&user=${props._user}`);
      getPOsList();
    }catch(error){
      console.error('deletePo -->:', error);
      // this.setState({ openDeleteModal: false});
    }
  }
  /**
   * Duplicate PO
   * @param {*} POdata 
   */
  async function duplicatePO(POdata){
    try{
      // const url = 'http://localhost:3004';
      // await Axios.get(`${url}/order/duplicate/po?POId=${POdata.POId}&user=${props._user}`);
      await Axios.get(`${Services.ORDER.path}/order/duplicate/po?POId=${POdata.POId}&user=${props._user}`);

      getPOsList();
    }catch(error){
      console.error('duplicate -- error:', error);
    }
  }

  React.useEffect(() => {
    getPOsList();
  }, [getPOsList]);

  return (
    <React.Fragment>
      <Card>
        <CardBody className="myCard">
          {
            loading ? <Loader/>
              :
              <Table
                columns={columns(deletePO, duplicatePO)}
                data={data}
                itemsByPage={10}
                options={{
                  pagination: true,
                  search: true,
                }}
                cellType={'thin-rows'}
                fixed
              />
          }
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
PoProcedures.propTypes = {
  _user: propTypes.string
};
PoProcedures.defaultProps = {
  _user: '0'
};

export default PoProcedures;
