import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { Button, Card, CardBody } from 'reactstrap';
import swal from 'sweetalert';
import {Services} from './../../../Services';
import Axios from 'axios';
import {withRouter} from 'react-router-dom';
// import restService from '../../../../../../services/restService';


class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      POId: this.props.POId,
      comment: '',
      confirmDate: '',
      detail : {}
    };
  }

    componentDidMount = () => {
      this.setState({
        POId: this.props.POId,
        data: this.props.data
      });
    }

    componentWillReceiveProps = (nextProps) => {
      this.setState({
        POId: nextProps.POId,
        data: nextProps.data
      });
    }

    onChangeComment = name => event => {
      this.setState({
        [name]: event.target.value
      });
    }

    handleChangeDate = name => event => {
      try {
        this.setState({
          [name]: event.format('YYYY-MM-DD')
        });
      }
      catch (ex) {
      }
    }

    getDetails = async()=>{
      let {POId}  = this.state;
      let success = await Axios.get(`${Services.ORDER.path}order/${POId}?type=detail`);
      if (success.data && success.data.data) {
        this.setState({
          detail: success.data.data
        },()=>{
          this.sendConfirmation();
        });
      } else swal('Error', 'INTERNAL SERVER ERROR', 'warning');

      // rest.EXEC({ _function: 'GetOneOrder', params: POId + '?type=detail' }, fetch).then(success => {
      //     if (success.data) {
      //         this.setState({
      //             detail: success.data
      //         },()=>{
      //             this.sendConfirmation()
      //         })
      //     }
      // }).catch(error => {
      //     swal("Error", 'INTERNAL SERVER ERROR', "warning")
      // })
    }
    sendConfirmation = () => {
      // let { data ,detail} = this.state;
      if(!this.state.confirmDate){
        swal('Error', 'Cargo Readiness Confirmation Date is required', 'warning');
      }
      else{
        Axios.put(`${Services.ORDER.path}order/confirmation/cargo-ready/`).then( 
          success => 
            swal('Good job!', success.data.label ? success.data.label : 'Successfull', 'success').then(() => {
              this.props.history.push('/order-list');
            })
        ).catch(error => swal('Error', error.label ? error.label : 'Internal Server Error', 'error'));
        // rest.EXEC({
        //     _function: 'CargoReadyConfirmation', params: '', data: {
        //         POId: this.props.POId,
        //         ActualCargoReadyDate: this.state.confirmDate
        //     }
        // }, fetch).then(success => {
        //     swal("Good job!", success.label ? success.label : 'Successfull', "success").then(() => {
        //         this.props.history.push('/order-list');
        //     })
        // }).catch(error => {
        //     swal("Error", error.label ? error.label : 'Internal Server Error', "error")
        // })
      }
    }

    render = () => {
      return (
        <Card>
          <CardBody>
            <h4 className="mb-3"> <em className="fas fa-comments fa-1x mr-2" />Cargo Readiness Confirmation Comments</h4>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-4">
                    <label style={{ color: '#9e9e9e', fontWeight: 'bold' }}>Cargo Readiness Confirmation Date:</label>
                    <Datetime onChange={this.handleChangeDate('confirmDate')} value={this.state.confirmDate} />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <label style={{ color: '#9e9e9e', fontWeight: 'bold' }}>Comments:</label>
                <textarea rows="10" className="form-control" value={this.state.comment} onChange={this.onChangeComment('comment')}></textarea>
              </div>
              <div className="col-12">
                <hr></hr>
              </div>
              <div className="col-10"></div>
              <div className="col-2">
                <Button className="" color="success" onClick={this.getDetails}>Save and confirm</Button>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
}

export default withRouter(Summary);