/*=============================================
=            Validator Service                =
=============================================*/

export const ValidatorService = (type, value) => {
	let status = false;
	let expressions = {
		text: new RegExp(/^[0-9a-zA-Z-ZñÑáéíóúÁÉÍÓÚ\-?¿'&%$_.,;:!"#¡\s]*$/),
		password: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_-])([A-Za-z\d$@$!%*?&_-]|[^ ]){8,15}$/),
		number: new RegExp(/^[0][1-9]\d{9}$|^[1-9]\d{9}$/),
		numberEx: new RegExp(/^[0-9]*$/), // Acepta solo numeros y cadena vacia
		// numberEx: new RegExp(/^[0][1-9]\d{0,49}$|^[1-9]\d{0,49}$/),
		numberD: new RegExp(/^[0-9]*$/),
		email: new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/)

		// "/^[0-9]*$/"; Solo cadena vacia o numeros
		// "/^[0-9]+$/"; Solo numeros. No se admite cadena vacia

		//"/^[a-zA-Z]+$/"; Solo letras en mayusculas/minusculas. No se admite cadena vacia

		// "/^[0-9a-zA-Z]+$/";Solo letras en mayusculas/minusculas y numeros. No se admite cadena vacia 
	};

	if (type === 'noteval') return true;

	if (expressions[type].test(value))
		status = true;
	return status;
};

/*=============================================
=            Validator Schema                 =
=============================================*/
/**
 * required : true
 * valid: true
 * value != 
 * 
 */

export const ValidatorSchema = (data, keys) => {
	// console.log('data-->', data);
	let valid = true;
	for (let index = 0; index < keys.length; index++) {
		const element = keys[index];
		// console.log('element-->', element);
		if (data[element].required)
			if (data[element].valid === false || data[element].value === undefined || data[element].value === '')
				valid = false;
	}
	return valid;
};