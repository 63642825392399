import React from 'react';

const urlIcons = 'https://fsposeidon.blob.core.windows.net';

const Icons = {
  Shipment: {
    ModoTransporte: (
      <img alt="" src={`${urlIcons}/magicdome/Shipment_Transport.png`} width="35"></img>
    )
  },
  PurchaseOrder: {
    ModoTransporte: (
      //<img alt="" src={`${urlIcons}/magicdome/PO_TitleIcon.png`} width="35"></img>
      <em style={{ color: '#43ADEB'}} className="fa-2x mr-2 icon--po_titleicon"></em>
    )
  }
};

export default Icons;
