import React from 'react';
import Paper from '@material-ui/core/Paper';
import ContentWrapper from './../../template/Layout/ContentWrapper';
import './styles.scss';
import LoaderSPINER from './../../../resources/Spinner-V2.gif';



/*=============================================
=            FullLoader Screen                =
=============================================*/

function Loader(props) {
  return (
    <Paper elevation={0} className="__loader_container" hidden={props.hidden}>
      <ContentWrapper className="__center">
        <div className="col-md-12 __loader">
          <div className="abs-center wd-xl">
            <div className="text-center mb-4">
              {/* <img src={LoaderSPINER} alt="this slowpoke moves"  width="250" alt="404 image"/> */}
              {/* <img src='https://fsposeidon.blob.core.windows.net/magicdome/Spinner-V2.gif' alt="description of gif" width="250"  />                    */}
              <svg id="786aecb1-a851-4ee2-b41f-5250ac8e5399" data-name="MagicDome" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.44 116.41">
                <path
                className="st0"
                  d="M197.72,158.2H185.8v-5.95c0-54.34-38.49-98.54-85.8-98.54s-85.8,44.2-85.8,98.54v5.95H2.28v-5.95C2.28,91.34,46.12,41.8,100,41.8s97.72,49.54,97.72,110.45Z"
                  transform="translate(-2.28 -41.8)"
                  style={{ fill: '#31ade3' }} />
                <svg>
                  <path
                  className="st0"
                    d="M43,120.53H72.79a0,0,0,0,1,0,0v23.81a6,6,0,0,1-6,6H49a6,6,0,0,1-6-6V120.53A0,0,0,0,1,43,120.53Z"
                    transform="translate(110.42 -43.08) rotate(45)"
                    style={{ fill: '#31ade3' }} />
                  <animate
                    attributeName="opacity"
                    values="0.7;1;0;0.7" dur="3s"
                    repeatCount="indefinite"
                  /></svg>
                <svg>
                  <path
                  className="st0"
                    d="M70,99.49H93.83a0,0,0,0,1,0,0v29.76a0,0,0,0,1,0,0H64.07a0,0,0,0,1,0,0V105.44A6,6,0,0,1,70,99.49Z"
                    transform="translate(101.71 -64.13) rotate(45)"
                    style={{ fill: '#31ade3' }} />
                  <animate
                    attributeName="opacity"
                    values="0.3;0;1;0.3" dur="2s"
                    repeatCount="indefinite"
                  /></svg>
                <svg>
                  <path
                  className="st0"
                    d="M85.12,120.53h29.76a0,0,0,0,1,0,0v23.81a6,6,0,0,1-6,6H85.12a0,0,0,0,1,0,0V120.53A0,0,0,0,1,85.12,120.53Z"
                    transform="translate(122.75 -72.85) rotate(45)"
                    style={{ fill: '#31ade3' }} />
                  <animate
                    attributeName="opacity"
                    values="1;0;1" dur="3s"
                    repeatCount="indefinite"
                  /></svg>
                <svg>
                  <path
                  className="st0"
                    d="M112.12,99.49h23.81a0,0,0,0,1,0,0v29.76a0,0,0,0,1,0,0H106.17a0,0,0,0,1,0,0V105.44A6,6,0,0,1,112.12,99.49Z"
                    transform="translate(114.04 -93.89) rotate(45)"
                    style={{ fill: '#31ade3' }} />
                  <animate
                    attributeName="opacity"
                    values="0.7;0;1;0.7" dur="3s"
                    repeatCount="indefinite"
                  /></svg>
                <svg>
                  <path
                  className="st0"
                    d="M127.21,120.53H151a6,6,0,0,1,6,6v17.85a6,6,0,0,1-6,6H127.21a0,0,0,0,1,0,0V120.53a0,0,0,0,1,0,0Z"
                    transform="translate(135.08 -102.61) rotate(45)"
                    style={{ fill: '#31ade3' }} />
                  <animate
                    attributeName="opacity"
                    values="0.3;1;0;0.3" dur="2s"
                    repeatCount="indefinite"
                  /></svg>
              </svg>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </Paper>
  );
}
export default Loader;



//alternativo

// import React from 'react';
// import Paper from '@material-ui/core/Paper';
// import ContentWrapper from './../../template/Layout/ContentWrapper';
// import './styles.scss';
// import LoaderSPINER from './../../../resources/Spinner-V2.gif';



// /*=============================================
// =            FullLoader Screen                =
// =============================================*/

// function Loader(props){
//   return (
//     <Paper elevation={0} className="__loader_container" hidden={props.hidden}>
//       <ContentWrapper className="__center">
//         <div className="col-md-12 __loader">
//           <div className="abs-center wd-xl">
//             <div className="text-center mb-4">
//             {/* <img src={LoaderSPINER} alt="this slowpoke moves"  width="250" alt="404 image"/> */}
//             {/* <img src='https://fsposeidon.blob.core.windows.net/magicdome/Spinner-V2.gif' alt="description of gif" width="250"  />                    */}
//             <svg version="1.1" width="100px"  height="100px" id="Poseidon_Spinner" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
//                 viewBox="0 0 100 100">
//                   <g>
//                     <g>
//                       <path class="st0" d="M95,73.7h-5.5V71c0-25.1-17.8-45.6-39.7-45.6S10.2,45.8,10.2,71v2.8H4.6V71c0-28.2,20.3-51.1,45.2-51.1
//                         S95,42.8,95,71V73.7z"/>
//                     </g>
//                     <path class="st0" d="M28.4,71l-5.8-5.8c-1.1-1.1-1.1-2.8,0-3.9l7.8-7.8l9.7,9.7L32.3,71C31.2,72,29.5,72,28.4,71z"/>
//                     <path class="st0" d="M40.1,63.2l-9.7-9.7l7.8-7.8c1.1-1.1,2.8-1.1,3.9,0l7.8,7.8L40.1,63.2z"/>
//                     <path class="st0" d="M47.9,71l-7.8-7.8l9.7-9.7l9.7,9.7L51.8,71C50.7,72,49,72,47.9,71z"/>
//                     <path class="st0" d="M59.6,63.2l-9.7-9.7l7.8-7.8c1.1-1.1,2.8-1.1,3.9,0l7.8,7.8L59.6,63.2z"/>
//                     <path class="st0" d="M67.3,71l-7.8-7.8l9.7-9.7l7.8,7.8c1.1,1.1,1.1,2.8,0,3.9L71.2,71C70.2,72,68.4,72,67.3,71z"/>
//                     <animate  accumulate="none" additive="replace" attributeName="stroke-dasharray" calcMode="linear" dur="4s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="100 100;100 100">
//                       </animate>
//                       <animate  accumulate="none" additive="replace" attributeName="stroke-dashoffset" calcMode="linear" dur="4s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="0;-800">
//                       </animate>
//                 </g>
//               </svg> 
//             </div>
//           </div>
//         </div>
//       </ContentWrapper>
//     </Paper>  
//   );
// }
// export default Loader;