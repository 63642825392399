import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import Select from "react-select";
import queryString from "query-string";
import { Services } from "./Services";
import axios from "axios";
import Authentication from "../../../../src/services/Authentication.js";
import imagenLogin from "./../../../resources/Login_CheckDark.png";
import { connect } from "react-redux";

const auth = new Authentication();

const Customers = [];

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Toke: "",
      userData: "",
      flat: false,
      Customers: [],
      customerSelect: "",
      CreateFormData: [],
      login: {
        email: props.email ? props.email : "",
        password: "",
      },
      fakeLogin: {
        email: "info@magic-log.com",
        password: "123456",
      },
      PrimaryColor: this.props.PrimaryColor,
      SecondaryColor: this.props.SecondaryColor,
      HoverColor: this.props.HoverColor,
    };
  }

  componentDidMount = () => {
    this.selectCustomer();
  };

  validatorData = (name) => (event) => {
    let { login } = this.state;

    login.password = event.target.value;

    this.setState({ login });
  };
  handleLogin = () => {
    let {
      login,
      fakeLogin,
      PrimaryColor,
      SecondaryColor,
      HoverColor,
    } = this.state;
    login.password = document.getElementById("input_password").value;

    const values = queryString.parse(this.props.location.search);

    let haveClient = false;
    let havePO = false;
    let newCs = "";
    let newPO = "";
    if (values) {
      if (values.cs) {
        newCs = values.cs.replace(/\s/g, "+");
        haveClient = true;
      }
      if (values.po) {
        havePO = true;
        newPO = values.po.replace(/\s/g, "+");
      }
    }
    if (!login.email) {
      this.props.onError({
        error: {
          message: "Email is required",
          open: true,
          type: "warning",
        },
      });
    } else if (!login.password) {
      this.props.onError({
        error: {
          message: "Password is required",
          open: true,
          type: "warning",
        },
      });
    } else {
      let data = {
        email: login.email,
        password: login.password,
        client: haveClient ? newCs : false,
        po: havePO ? newPO : false,
      };

      axios
        .post(`${Services.ADMIN.path}oauth/setAuthentication`, data)
        .then((response) => {
          this.setState({
            Token: response.data.token,
            userData: response.data.data,
          });

          let id = response.data.data[0].UserId;
          if (response.data.design) {
            localStorage.setItem(
              "design",
              JSON.stringify(response.data.design)
            );
            this.props.updateDesign(response.data.design);
            let element = document.querySelector(":root");
            element.style.setProperty("--start-color", PrimaryColor);
            element.style.setProperty("--end-color", SecondaryColor);
            element.style.setProperty("--icon-color", HoverColor);
          }

          if (haveClient) {
            auth.setAuthentication(
              this.state.Token,
              this.state.userData,
              Number(response.data.payload.client)
            );
            if (values.to) {
              switch (values.to) {
                case "po":
                  this.props.history.push("/order-list");
                  break;
                case "podetail":
                  if (response.data.payload.po) {
                    this.props.history.push(
                      "/po-detail-view/" + response.data.payload.po
                    );
                  } else {
                    this.props.history.push("/order-list");
                  }
                  break;
                default:
                  this.props.history.push("/dashboard");
              }
            } else {
              this.props.history.push("/dashboard");
            }
          } else {
            axios
              .get(
                `${Services.ADMIN.path}admin/customerForId` + "/" + `${id}`,
                {}
              )
              .then((response) => {
                if (response.data.resp.length == 1) {
                  this.setState({
                    flat: false,
                  });
                }
                let data = [];
                response.data.resp.forEach((e) => {
                  data.push({ value: e.CustomerId, label: e.CustomerName });
                });

                if (data.length === 1) {
                  this.setState(
                    {
                      customerSelect: data[0].value,
                    },
                    () => {
                      auth.setAuthentication(
                        this.state.Token,
                        this.state.userData,
                        this.state.customerSelect
                      );
                      this.props.history.push("/dashboard");
                    }
                  );
                } else {
                  this.setState({
                    Customers: data,
                    flat: true,
                  });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch(() => {
          document.getElementById("error_alert").removeAttribute("hidden");
        });
    }
  };

  selectCustomer = () => {
    let CreateFormData = Customers.map((option, i) => {
      return (
        <select className="_input" form="carform">
          <option key={option.id} value={option.id}>
            {" "}
            {option.text}
          </option>
        </select>
      );
    });
    this.setState({
      CreateFormData,
    });
  };

  validateOnChange = (name) => (event) => {
    if (event && event.value) {
      let id = event.value;
      this.setState(
        {
          customerSelect: id,
        },
        () => {
          auth.setAuthentication(
            this.state.Token,
            this.state.userData,
            this.state.customerSelect
          );
          this.props.history.push("/dashboard");
        }
      );
    } else {
      let id = "";
      this.setState({
        customerSelect: id,
      });
    }
  };

  render() {
    return (
      <div className="col-md-12 _containerLogin">
        <div style={{}} className="col-md-12">
          {/* <div style={{marginRight:'-69px'}} className="col-md-12"> */}

          <div className="row">
            {/* <input hidden={this.state.flat ? true : false} type="text" className="col-md-11 _input" placeholder="Email Address" defaultValue={this.state.login.email} disabled></input>  */}
            <input
              hidden={this.state.flat ? true : false}
              type="text"
              className="_input2"
              placeholder="Email Address"
              defaultValue={this.state.login.email}
              disabled
            ></input>

            {!this.state.flat ? (
              // <img style={{marginLeft:'11px', marginTop:'9px'}} height={19} width={27}  src={imagenLogin} alt='check'></img>
              <img
                style={{ marginLeft: "-3em", marginTop: "9px", zIndex: "9999" }}
                height={19}
                width={27}
                src={imagenLogin}
                alt="check"
              ></img>
            ) : (
              " "
            )}
          </div>
        </div>
        <input
          hidden={this.state.flat ? true : false}
          type="password"
          className="_input2"
          placeholder="Password"
          id="input_password"
          value={this.state.login.password}
          onChange={this.validatorData()}
        ></input>
        {this.state.flat ? (
          <div className="col-md-8">
            <Select
              id=""
              placeholder="Select Customer"
              required
              type=""
              name=""
              value={this.state.customerSelect}
              onChange={this.validateOnChange()}
              options={this.state.Customers}
            />
          </div>
        ) : (
          <></>
        )}
        {this.state.flat ? (
          <></>
        ) : (
          <div className="_alert" id="error_alert" hidden>
            Looks like you have the wrong email or password
          </div>
        )}
        <button
          hidden={this.state.flat ? true : false}
          className="_loginButton2"
          onClick={this.handleLogin}
        >
          Sign In
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  PrimaryColor: state.design.custom.PrimaryColor,
  SecondaryColor: state.design.custom.SecondaryColor,
  HoverColor: state.design.custom.HoverColor,
});

const mapDispatchToProps = (dispatch) => ({
  updateDesign: (newData) =>
    dispatch({
      type: "SET_DESIGN",
      payload: newData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
