const _MAXLENGHT = 15;

export const Data = {
  'data': [
    {
      "poNumber": "JWL10180709",
      "poDate": "29/8/2018",
      "supplier": "UPSMEIER POWER SYSTEMS AG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 48800,
      "currency": "EUR",
      "invoiceNumber": "PA18036,PA18037,PA18038",
      "invoiceAmount": 48800
    },
    {
      "poNumber": "JWL10180709",
      "poDate": "29/8/2018",
      "supplier": "UPSMEIER POWER SYSTEMS AG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 15470,
      "currency": "EUR",
      "invoiceNumber": "FV18-01603,FV18-01602",
      "invoiceAmount": 17450
    },
    {
      "poNumber": "JWL10180871T",
      "poDate": "29/8/2018",
      "supplier": "NCL STAR",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 20000,
      "currency": "EUR",
      "invoiceNumber": "569/18",
      "invoiceAmount": 20000
    },
    {
      "poNumber": "JWL10180633",
      "poDate": "29/8/2018",
      "supplier": "NAVALIMPIANTI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 10200,
      "currency": "EUR",
      "invoiceNumber": "PA18039,PA18040",
      "invoiceAmount": 10200
    },
    {
      "poNumber": "JWL10180739",
      "poDate": "29/8/2018",
      "supplier": "C.S. SUPPLIES",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 1960,
      "currency": "EUR",
      "invoiceNumber": "FV18-01604",
      "invoiceAmount": 1960
    },
    {
      "poNumber": "JWL10180589",
      "poDate": "29/8/2018",
      "supplier": "C.S. SUPPLIES",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 2041.2,
      "currency": "EUR",
      "invoiceNumber": "569/19",
      "invoiceAmount": 2041.2
    },
    {
      "poNumber": "JWL10180668",
      "poDate": "29/8/2018",
      "supplier": "CALLENBERG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 6591,
      "currency": "EUR",
      "invoiceNumber": "PA18052",
      "invoiceAmount": 6591
    },
    {
      "poNumber": "JWL12189003",
      "poDate": "30/8/2018",
      "supplier": "KAEFER",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 26164,
      "currency": "EUR",
      "invoiceNumber": "FV18-01605",
      "invoiceAmount": 26164
    },
    {
      "poNumber": "JWL12189003",
      "poDate": "30/8/2018",
      "supplier": "KAEFER",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 15000,
      "currency": "EUR",
      "invoiceNumber": "569/20",
      "invoiceAmount": 15000
    },
    {
      "poNumber": "JWL12189003",
      "poDate": "30/8/2018",
      "supplier": "KAEFER",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 7000,
      "currency": "EUR",
      "invoiceNumber": "PA18058",
      "invoiceAmount": 7000
    },
    {
      "poNumber": "JWL10180545",
      "poDate": "30/8/2018",
      "supplier": "DE WAVE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Engine",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8500",
      "amount": 12480,
      "currency": "EUR",
      "invoiceNumber": "FV18-01606",
      "invoiceAmount": 12480
    },
    {
      "poNumber": "JWL10180545",
      "poDate": "30/8/2018",
      "supplier": "DE WAVE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 440,
      "currency": "EUR",
      "invoiceNumber": "569/21",
      "invoiceAmount": 440
    },
    {
      "poNumber": "JWL10180545",
      "poDate": "30/8/2018",
      "supplier": "DE WAVE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 1629.8,
      "currency": "EUR",
      "invoiceNumber": "PA18074",
      "invoiceAmount": 1629.8
    },
    {
      "poNumber": "JWL10180627",
      "poDate": "30/8/2018",
      "supplier": "BOURNE GROUP LLC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 30220,
      "currency": "EUR",
      "invoiceNumber": "FV18-01607",
      "invoiceAmount": 30220
    },
    {
      "poNumber": "JWL12189003",
      "poDate": "30/8/2018",
      "supplier": "KAEFER",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 2856,
      "currency": "EUR",
      "invoiceNumber": "569/22",
      "invoiceAmount": 2856
    },
    {
      "poNumber": "JWL12189004",
      "poDate": "30/8/2018",
      "supplier": "KAEFER",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 2000,
      "currency": "EUR",
      "invoiceNumber": "PA18041",
      "invoiceAmount": 2000
    },
    {
      "poNumber": "JWL10180545",
      "poDate": "3/9/2018",
      "supplier": "SIDEM SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 13000,
      "currency": "EUR",
      "invoiceNumber": "FV18-01608",
      "invoiceAmount": 13000
    },
    {
      "poNumber": "JWL10180545",
      "poDate": "3/9/2018",
      "supplier": "NEW GSI SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 750,
      "currency": "EUR",
      "invoiceNumber": "569/23",
      "invoiceAmount": 750
    },
    {
      "poNumber": "JWL10180545",
      "poDate": "3/9/2018",
      "supplier": "PRECETTI SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 105,
      "currency": "EUR",
      "invoiceNumber": "PA18042",
      "invoiceAmount": 105
    },
    {
      "poNumber": "JWL12189008",
      "poDate": "3/9/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 4615.5,
      "currency": "EUR",
      "invoiceNumber": "FV18-01609",
      "invoiceAmount": 4615.5
    },
    {
      "poNumber": "JWL12189008",
      "poDate": "3/9/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8501",
      "amount": 10769.5,
      "currency": "EUR",
      "invoiceNumber": "569/24",
      "invoiceAmount": 10769.5
    },
    {
      "poNumber": "JWL12189064",
      "poDate": "3/9/2018",
      "supplier": "SOHO MYRIAD",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 30220,
      "currency": "EUR",
      "invoiceNumber": "PA18043",
      "invoiceAmount": 30220
    },
    {
      "poNumber": "JWL1218908S2",
      "poDate": "5/9/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 31780.74,
      "currency": "EUR",
      "invoiceNumber": "FV18-01610",
      "invoiceAmount": 31780.74
    },
    {
      "poNumber": "JWL12189079S",
      "poDate": "7/9/2018",
      "supplier": "AECOR MARINE LTD",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 4950,
      "currency": "EUR",
      "invoiceNumber": "569/25",
      "invoiceAmount": 4950
    },
    {
      "poNumber": "JWL12189121A",
      "poDate": "19/9/2018",
      "supplier": "UNIVERSAL  COMPANIES INC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 1139.67,
      "currency": "EUR",
      "invoiceNumber": "PA18044",
      "invoiceAmount": 1139.67
    },
    {
      "poNumber": "JWL10180786S",
      "poDate": "19/9/2018",
      "supplier": "CONSILIUM SAL NAVIGATION AB",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 8028,
      "currency": "EUR",
      "invoiceNumber": "FV18-01611",
      "invoiceAmount": 8028
    },
    {
      "poNumber": "JWL10180127",
      "poDate": "19/9/2018",
      "supplier": "AERIUS",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 4598.3,
      "currency": "EUR",
      "invoiceNumber": "569/26",
      "invoiceAmount": 4598.3
    },
    {
      "poNumber": "JWL10180687",
      "poDate": "19/9/2018",
      "supplier": "NAVICO",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8502",
      "amount": 716.88,
      "currency": "EUR",
      "invoiceNumber": "PA18045",
      "invoiceAmount": 716.88
    },
    {
      "poNumber": "JWL10180924",
      "poDate": "19/9/2018",
      "supplier": "DISKOWSKI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8503",
      "amount": 1950,
      "currency": "EUR",
      "invoiceNumber": "FV18-01612",
      "invoiceAmount": 1950
    },
    {
      "poNumber": "JWL10180843",
      "poDate": "19/9/2018",
      "supplier": "MACOR",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Marketing",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8503",
      "amount": 4865.86,
      "currency": "EUR",
      "invoiceNumber": "569/27",
      "invoiceAmount": 4865.86
    },
    {
      "poNumber": "JWL12189095S",
      "poDate": "19/9/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Marketing",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8503",
      "amount": 2065.4,
      "currency": "EUR",
      "invoiceNumber": "PA18046",
      "invoiceAmount": 2065.4
    },
    {
      "poNumber": "JWL10180457",
      "poDate": "19/9/2018",
      "supplier": "VULCAN",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Marketing",
      "cruise": "NCL Jewel",
      "projectCode": "18DJWL044",
      "accountNumber": "14604-409-8503",
      "amount": 295230,
      "currency": "EUR",
      "invoiceNumber": "FV18-01613",
      "invoiceAmount": 295230
    },
    {
      "poNumber": "INS01180351",
      "poDate": "31/8/2018",
      "supplier": "RCG-USA",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 8398.8,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX1",
      "invoiceAmount": 8398.8
    },
    {
      "poNumber": "INS01189148",
      "poDate": "4/9/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 4925.6,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX2",
      "invoiceAmount": 4925.6
    },
    {
      "poNumber": "INS01189165",
      "poDate": "19/9/2018",
      "supplier": "CRIVELLARI DIVANI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 3675,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX2",
      "invoiceAmount": 3675
    },
    {
      "poNumber": "INS01189145",
      "poDate": "20/8/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 18528,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX3",
      "invoiceAmount": 18528
    },
    {
      "poNumber": "INS01189146",
      "poDate": "26/9/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 12220.6,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX3",
      "invoiceAmount": 12220.6
    },
    {
      "poNumber": "INS01180750",
      "poDate": "27/9/2018",
      "supplier": "ITALIAN SCANDINAVIAN",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 3630,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX4",
      "invoiceAmount": 3630
    },
    {
      "poNumber": "INS01180698",
      "poDate": "27/9/2018",
      "supplier": "ITALIAN SCANDINAVIAN",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 1051.65,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX4",
      "invoiceAmount": 1051.65
    },
    {
      "poNumber": "INS01189181",
      "poDate": "3/10/2018",
      "supplier": "MEDIALIGHT",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 1465,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX5",
      "invoiceAmount": 1465
    },
    {
      "poNumber": "INS01180504S",
      "poDate": "4/10/2018",
      "supplier": "BC SERVICE SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 11881.19,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX5",
      "invoiceAmount": 11881.19
    },
    {
      "poNumber": "INS01180207",
      "poDate": "4/10/2018",
      "supplier": "ULTRAFOG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 65000,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX6",
      "invoiceAmount": 65000
    },
    {
      "poNumber": "INS01180207",
      "poDate": "3/10/2018",
      "supplier": "ULTRAFOG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 100000,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX6",
      "invoiceAmount": 100000
    },
    {
      "poNumber": "INS01180207",
      "poDate": "3/10/2018",
      "supplier": "ULTRAFOG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 3630,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX7",
      "invoiceAmount": 3630
    },
    {
      "poNumber": "INS01180207",
      "poDate": "5/10/2018",
      "supplier": "ULTRAFOG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 4000,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX7",
      "invoiceAmount": 4000
    },
    {
      "poNumber": "INS01189197",
      "poDate": "5/10/2018",
      "supplier": "MEDIALIGHT",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 5416,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX8",
      "invoiceAmount": 5416
    },
    {
      "poNumber": "INS01189183",
      "poDate": "8/10/2018",
      "supplier": "MEDIALIGHT",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 3600,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX8",
      "invoiceAmount": 3600
    },
    {
      "poNumber": "INS01180604",
      "poDate": "9/10/2018",
      "supplier": "IMH - INDUTEC METALL GMBH",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 3313.8,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX9",
      "invoiceAmount": 3313.8
    },
    {
      "poNumber": "INS01189197",
      "poDate": "10/10/2018",
      "supplier": "MEDIALIGHT",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 3630,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX9",
      "invoiceAmount": 3630
    },
    {
      "poNumber": "INS01189218",
      "poDate": "10/10/2018",
      "supplier": "MEDIALIGHT",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 4200,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX10",
      "invoiceAmount": 4200
    },
    {
      "poNumber": "INS01180505S",
      "poDate": "10/10/2018",
      "supplier": "NAVALTECH",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 3630,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX2",
      "invoiceAmount": 3630
    },
    {
      "poNumber": "INS01189171",
      "poDate": "11/10/2018",
      "supplier": "F.LLI BOLOGNA E MARCACCIO",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 5386.5,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX3",
      "invoiceAmount": 5386.5
    },
    {
      "poNumber": "INSPCH8P37775",
      "poDate": "11/8/2018",
      "supplier": "PRECETTI INC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 678.4,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX3",
      "invoiceAmount": 678.4
    },
    {
      "poNumber": "INS01180548",
      "poDate": "12/10/2018",
      "supplier": "ADTS",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 22410.51,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX4",
      "invoiceAmount": 22410.51
    },
    {
      "poNumber": "INS01180577",
      "poDate": "15/10/2018",
      "supplier": "CARRAL MARINE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 9061.78,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX4",
      "invoiceAmount": 9061.78
    },
    {
      "poNumber": "INS01180303",
      "poDate": "15/10/2018",
      "supplier": "NAVALIMPIANTI USA",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 10920,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX5",
      "invoiceAmount": 10920
    },
    {
      "poNumber": "INS01180835",
      "poDate": "16/10/2018",
      "supplier": "IMATECH",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 2813.85,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX5",
      "invoiceAmount": 2813.85
    },
    {
      "poNumber": "INS01189253",
      "poDate": "17/10/2018",
      "supplier": "CAREDI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 1060,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX6",
      "invoiceAmount": 1060
    },
    {
      "poNumber": "INS01189158",
      "poDate": "17/10/2018",
      "supplier": "CAREDI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 2850,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX6",
      "invoiceAmount": 2850
    },
    {
      "poNumber": "INS01189187",
      "poDate": "17/10/2018",
      "supplier": "CAREDI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8502",
      "amount": 2590,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX7",
      "invoiceAmount": 2590
    },
    {
      "poNumber": "INS01189261",
      "poDate": "17/10/2018",
      "supplier": "CAREDI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8503",
      "amount": 784,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX7",
      "invoiceAmount": 784
    },
    {
      "poNumber": "INS01189207S",
      "poDate": "17/10/2018",
      "supplier": "NAVALTECH",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8503",
      "amount": 33960,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX8",
      "invoiceAmount": 33960
    },
    {
      "poNumber": "INS01189224S",
      "poDate": "17/10/2018",
      "supplier": "NAVALTECH",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8503",
      "amount": 3534,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX8",
      "invoiceAmount": 3534
    },
    {
      "poNumber": "INS01180733",
      "poDate": "17/10/2018",
      "supplier": "ALMACO",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8503",
      "amount": 5533.48,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX9",
      "invoiceAmount": 5533.48
    },
    {
      "poNumber": "INS01180504S",
      "poDate": "15/10/2018",
      "supplier": "BC SERVICE SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 5614.83,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX9",
      "invoiceAmount": 5614.83
    },
    {
      "poNumber": "INS01180844",
      "poDate": "25/10/2018",
      "supplier": "ENRICO POLIPODIO",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 5412,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX10",
      "invoiceAmount": 5412
    },
    {
      "poNumber": "INS01180729",
      "poDate": "25/10/2018",
      "supplier": "GECOM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 2600,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX10",
      "invoiceAmount": 2600
    },
    {
      "poNumber": "INS01180706AA",
      "poDate": "25/10/2018",
      "supplier": "GECOM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 2600,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX11",
      "invoiceAmount": 2600
    },
    {
      "poNumber": "INS01189139",
      "poDate": "25/10/2018",
      "supplier": "CAREDI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 2950,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX3",
      "invoiceAmount": 2950
    },
    {
      "poNumber": "INS01189227S/0S",
      "poDate": "29/10/2018",
      "supplier": "PRECETTI INC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 44992.75,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX4",
      "invoiceAmount": 44992.75
    },
    {
      "poNumber": "INS01189237S",
      "poDate": "29/10/2018",
      "supplier": "NOVA MARINE SERVICES",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 400,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX4",
      "invoiceAmount": 400
    },
    {
      "poNumber": "INS01189241/2S",
      "poDate": "25/10/2018",
      "supplier": "GEROLAMO SCORZA",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 4880,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX5",
      "invoiceAmount": 4880
    },
    {
      "poNumber": "INS01189237S",
      "poDate": "30/10/2018",
      "supplier": "NOVA MARINE SERVICES",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 10940,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX5",
      "invoiceAmount": 10940
    },
    {
      "poNumber": "INS01189241/2S",
      "poDate": "29/10/2018",
      "supplier": "GEROLAMO SCORZA",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 7750,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX6",
      "invoiceAmount": 7750
    },
    {
      "poNumber": "INS01189241/2S",
      "poDate": "30/10/2018",
      "supplier": "GEROLAMO SCORZA",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8500",
      "amount": 7750,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX6",
      "invoiceAmount": 7750
    },
    {
      "poNumber": "INS01180452S",
      "poDate": "30/10/2018",
      "supplier": "SIDEM SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 10000,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX7",
      "invoiceAmount": 10000
    },
    {
      "poNumber": "INS01189240",
      "poDate": "31/10/2018",
      "supplier": "TIROLO SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 12759.6,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX7",
      "invoiceAmount": 12759.6
    },
    {
      "poNumber": "INS01189180",
      "poDate": "31/10/2018",
      "supplier": "RUBELLI SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 5450,
      "currency": "EUR",
      "invoiceNumber": "FT-180157-EX8",
      "invoiceAmount": 5450
    },
    {
      "poNumber": "INS01189228",
      "poDate": "31/10/2018",
      "supplier": "MEDIALIGHT LLC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Insigna",
      "projectCode": "18DING043",
      "accountNumber": "14604-409-8501",
      "amount": 30800,
      "currency": "EUR",
      "invoiceNumber": "FT-180156-EX8",
      "invoiceAmount": 30800
    },
    {
      "poNumber": "SKY12189041S",
      "poDate": "23/10/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 2705.04,
      "currency": "EUR",
      "invoiceNumber": "FT-180159",
      "invoiceAmount": 2705.04
    },
    {
      "poNumber": "SKY10181299",
      "poDate": "19/11/2018",
      "supplier": "NAVALIMPIANTI USA INC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 6981,
      "currency": "EUR",
      "invoiceNumber": "FT-180160",
      "invoiceAmount": 6981
    },
    {
      "poNumber": "SKY10181169",
      "poDate": "19/11/2018",
      "supplier": "ATISA SPA",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 8976,
      "currency": "EUR",
      "invoiceNumber": "FT-180161",
      "invoiceAmount": 8976
    },
    {
      "poNumber": "SKY12189050S",
      "poDate": "12/12/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 191845.56,
      "currency": "EUR",
      "invoiceNumber": "FT-180162",
      "invoiceAmount": 191845.56
    },
    {
      "poNumber": "SKY12189050S",
      "poDate": "12/12/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 0,
      "currency": "EUR",
      "invoiceNumber": "FT-180163",
      "invoiceAmount": 0
    },
    {
      "poNumber": "SKY12189009",
      "poDate": "12/12/2018",
      "supplier": "PRIMA SAS",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 16303,
      "currency": "EUR",
      "invoiceNumber": "FT-180164",
      "invoiceAmount": 16303
    },
    {
      "poNumber": "SKY11180616",
      "poDate": "12/12/2018",
      "supplier": "BOURNE GROUP LLC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 5760,
      "currency": "EUR",
      "invoiceNumber": "FT-180165",
      "invoiceAmount": 5760
    },
    {
      "poNumber": "SKY11180582",
      "poDate": "12/12/2018",
      "supplier": "MARAND MARINE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 646.08,
      "currency": "EUR",
      "invoiceNumber": "FT-180166",
      "invoiceAmount": 646.08
    },
    {
      "poNumber": "SKY10180900S",
      "poDate": "12/12/2018",
      "supplier": "SCHINDLER",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 120,
      "currency": "EUR",
      "invoiceNumber": "FT-180167",
      "invoiceAmount": 120
    },
    {
      "poNumber": "SKY12189073S",
      "poDate": "12/12/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8501",
      "amount": 1817.6,
      "currency": "EUR",
      "invoiceNumber": "FT-180168",
      "invoiceAmount": 1817.6
    },
    {
      "poNumber": "SKY11180582",
      "poDate": "12/12/2018",
      "supplier": "MARAND MARINE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 6034.75,
      "currency": "EUR",
      "invoiceNumber": "FT-180169",
      "invoiceAmount": 6034.75
    },
    {
      "poNumber": "SKY10181214",
      "poDate": "12/12/2018",
      "supplier": "GUMIS DOO",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 14470,
      "currency": "EUR",
      "invoiceNumber": "FT-180170",
      "invoiceAmount": 14470
    },
    {
      "poNumber": "SKY10181230",
      "poDate": "12/12/2018",
      "supplier": "GUMIS DOO",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 10715,
      "currency": "EUR",
      "invoiceNumber": "FT-180171",
      "invoiceAmount": 10715
    },
    {
      "poNumber": "SKY10181175",
      "poDate": "12/12/2018",
      "supplier": "NAVALIMPIANTI USA INC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 20354,
      "currency": "EUR",
      "invoiceNumber": "FT-180172",
      "invoiceAmount": 20354
    },
    {
      "poNumber": "SKY12189024S",
      "poDate": "12/12/2018",
      "supplier": "IS MAKINEN OY",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 1411,
      "currency": "EUR",
      "invoiceNumber": "FT-180173",
      "invoiceAmount": 1411
    },
    {
      "poNumber": "SKY10181287S",
      "poDate": "11/12/2018",
      "supplier": "TMS",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 2796.5,
      "currency": "EUR",
      "invoiceNumber": "FT-180174",
      "invoiceAmount": 2796.5
    },
    {
      "poNumber": "SKY10181393S",
      "poDate": "11/12/2018",
      "supplier": "GF PIPING SYSTEMS",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "John Dylon",
      "division": "Spare Parts",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8502",
      "amount": 1411,
      "currency": "EUR",
      "invoiceNumber": "FT-180175",
      "invoiceAmount": 1411
    },
    {
      "poNumber": "SKY10181070",
      "poDate": "11/12/2018",
      "supplier": "ELRO-WERKE AG",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Rick Thompson",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8503",
      "amount": 24352,
      "currency": "EUR",
      "invoiceNumber": "FT-180176",
      "invoiceAmount": 24352
    },
    {
      "poNumber": "SKY10189147S",
      "poDate": "7/12/2018",
      "supplier": "SAVI",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8503",
      "amount": 209532,
      "currency": "EUR",
      "invoiceNumber": "FT-180177",
      "invoiceAmount": 209532
    },
    {
      "poNumber": "SKY12189050S",
      "poDate": "13/12/2018",
      "supplier": "MJM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8503",
      "amount": 10710.93,
      "currency": "EUR",
      "invoiceNumber": "FT-180178",
      "invoiceAmount": 10710.93
    },
    {
      "poNumber": "SKY11180669",
      "poDate": "14/12/2018",
      "supplier": "MARAND MARINE",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Marketing",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8503",
      "amount": 1476.8,
      "currency": "EUR",
      "invoiceNumber": "FT-180179",
      "invoiceAmount": 1476.8
    },
    {
      "poNumber": "SKY12189091",
      "poDate": "13/12/2018",
      "supplier": "UNIVERSAL COMPANIES INC",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Jennifer Parker",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8500",
      "amount": 0,
      "currency": "EUR",
      "invoiceNumber": "FT-180180",
      "invoiceAmount": 0
    },
    {
      "poNumber": "0011008815010",
      "poDate": "14/12/2018",
      "supplier": "MANTERO SISTEMI SRL",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8500",
      "amount": 6800,
      "currency": "EUR",
      "invoiceNumber": "FT-180181",
      "invoiceAmount": 6800
    },
    {
      "poNumber": "SKY12189091",
      "poDate": "19/12/2018",
      "supplier": "UNIVERSAL COMPANIES TM",
      "company": "NORWEGIAN CRUISE LINES",
      "buyer": "Natacha Martinello",
      "division": "Furniture",
      "cruise": "NCL Sky",
      "projectCode": "18DSKY048",
      "accountNumber": "14604-409-8500",
      "amount": 634.47,
      "currency": "EUR",
      "invoiceNumber": "FT-180182",
      "invoiceAmount": 634.47
    }
  ]
};

export const PurchaseModel = {
  poNumber: '',
  supplier: '',
  buyer: '',
  cruise: '',
  division: '',
  company: '',
  date: {
    dateStart: '',
    dateEnd: ''
  }
};

export const PurchaseForm = {
  form: [
    {
      type: 'Input',
      label:'Po Number',
      id: 'poNumber',
      name: 'poNumber',
      class: '_inputFilters',
      placeholder: 'PO Number',
      maxlenght: _MAXLENGHT
    },
    {
      type: 'Input',
      label:'Supplier',
      id: 'supplier',
      name: 'supplier',
      class: '_inputFilters',
      placeholder: 'Supplier',
      maxlenght: _MAXLENGHT
    },
    {
      type: 'Input',
      label:'Buyer',
      id: 'buyer',
      name: 'buyer',
      class: '_inputFilters',
      placeholder: 'Buyer',
      maxlenght: _MAXLENGHT
    },
    {
      type: 'Input',
      label:'Cruise',
      id: 'cruise',
      name: 'cruise',
      class: '_inputFilters',
      placeholder: 'Cruise',
      maxlenght: _MAXLENGHT
    },
    {
      type: 'Input',
      label:'Division',
      id: 'division',
      name: 'division',
      class: '_inputFilters',
      placeholder: 'Division',
      maxlenght: _MAXLENGHT
    },
    {
      type: 'Input',
      label:'Company',
      id: 'company',
      name: 'company',
      class: '_inputFilters',
      placeholder: 'Company',
      maxlenght: _MAXLENGHT
    },
    {
      type: 'Date',
      label:'Purchasing start date',
      id: 'dateStart',
      name: 'dateStart',
      class: '_inputFilters'
    },
    {
      type: 'Date',
      label:'Purchasing end date',
      id: 'dateEnd',
      name: 'dateEnd',
      class: '_inputFilters'
    }
  ]
};
