const dataModel = [
  {
    _section: [
      { label: '', size: 3, type: 'Label' },
      { label: 'PO Information', size: 6, type: 'Label' },
      { label: '', size: 3, type: 'Icon' }
    ],
    _sectionContent: [
      {
        label: 'PO Origin Country',
        name: 'poOrigin',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-COUNTRY'
      },
      {
        label: 'Project',
        name: 'project',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-PROJECT'
      },
      {
        label: 'Transport Mode',
        name: 'transportMode',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-TRANSPORTS-MODE'
      },
      {
        label: 'Order Value',
        name: 'orderValue',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Input'
      },
      {
        label: 'PO Status',
        name: 'poStatus',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-STATUS'
      },
      {
        label: 'Supplier',
        name: 'supplier',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-SUPPLIER'
      },
      {
        label: 'Consignee',
        name: 'consignee',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-CONSIGNEE'
      },
      {
        label: 'Delivery',
        name: 'delivery',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'ORDER-DELIVERY'
      }
    ],

    _subSection: [
      { label: 'Dates', size: 6 },
      { label: 'Item Information', size: 6 }
    ],
    _subSectionContent: [
      {
        label: 'Creation Date',
        name: 'creationDateStart',
        class: 'col-md-6',
        input: [
          { inp: 'input 1', size: 6, id: 'creationStart', valid: true },
          { inp: 'input 1', size: 6, id: 'creationEnd', valid: true }
        ],
        type: 'Datetime'
      },
      {
        label: 'Item Number',
        name: 'itemNumber',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12, id: 'itemNumber' }],
        type: 'Input'
      },
      {
        label: 'Item Description',
        name: 'itemDescription',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12, id: 'itemDescription' }],
        type: 'Input'
      },
      {
        label: 'Requested Ex Works Date',
        name: 'requestedExWorks',
        class: 'col-md-6',
        input: [
          { inp: 'input 1', size: 6, id: 'orderRequestedStart', valid: true },
          { inp: 'input 1', size: 6, id: 'orderRequestedEnd', valid: true }
        ],
        type: 'Datetime'
      },
      {
        label: '',
        name: 'pl',
        class: 'col-md-3',
        input: [],
        type: 'select'
      },
      {
        label: '',
        name: 'pl',
        class: 'col-md-3',
        input: [],
        type: 'select'
      },
      {
        label: 'Required at Destination',
        name: 'requiredDestination',
        class: 'col-md-6',
        input: [
          { inp: 'input 1', size: 6, id: 'requiredDestinationStart', valid: true },
          { inp: 'input 1', size: 6, id: 'requiredDestinationEnd', valid: true }
        ],
        type: 'Datetime'
      }
    ]
  },
  {
    _section: [
      { label: 'Booking Information', size: 8, type: 'Label' },
      { label: '', size: 1, type: 'Label' },
      { label: 'PO Information', size: 6, type: 'Label' },
      { label: '', size: 1, type: 'Icon' }
    ],
    _sectionContent: [
      {
        label: 'Booking Number',
        name: 'bookingNumber',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Input'
      },
      {
        label: 'Booking Status',
        name: 'bookingStatus',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'BOOKING-STATUS'
      },
      // {
      //   label: 'Carrier',
      //   name: 'carrier',
      //   class: 'col-md-3',
      //   input: [{ inp: 'input 1', size: 12 }],
      //   type: 'Select',
      //   options: 'BOOKING-CARRIER'
      // },
      {
        label: 'Origin',
        name: 'origin',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'BOOKING-ORIGIN'
      },
      {
        label: 'Destination',
        name: 'destination',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'BOOKING-DESTINATION'
      },
      {
        label: 'PO\'s Number',
        name: 'poNumber',
        class: 'col-md-6',
        input: [{ inp: 'input 1', size: 12 }],
        section: 'Input',
        type: 'poInformationInput'
        
      },
      {
        label: 'Supplier',
        name: 'supplier',
        class: 'col-md-6',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'poInformationSelect',
        section: 'Select',
        options: 'BOOKING-SUPPLIER'
      },
      {
        label: '3PL',
        name: 'pl',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'BOOKING-THEREPLS'
      },
      {
        label: '3PL Reference',
        name: 'plReference',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Input'
      },
      {
        label: 'Transport Mode',
        name: 'transportMode',
        class: 'col-md-3',
        input: [{ inp: 'input 1', size: 12 }],
        type: 'Select',
        options: 'BOOKING-TRANSPORTS-MODE'
      },
      {
        label: 'Consignee',
        name: 'consignee',
        class: 'col-md-6',
        input: [{ inp: 'input 1', size: 12 }],
        section: 'Select',
        type: 'poInformationSelect',
        options: 'BOOKING-CONSIGNEE'
      },
      {
        label: 'Delivery',
        name: 'delivery',
        class: 'col-md-6',
        input: [{ inp: 'input 1', size: 12 }],
        section: 'Select',
        type: 'poInformationSelect',
        options: 'BOOKING-DELIVERY'
      }
    ],

    _subSection: [{ label: 'Dates', size: 12 }],
    _subSectionContent: [
      {
        label: 'Cargo Ready Date',
        name: 'cargoReady',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'cargoReadyStart' },
          { inp: 'input 1', size: 6, id: 'cargoReadyEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'Approved date',
        name: 'aprovedDate',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'aprovedStart' },
          { inp: 'input 1', size: 6, id: 'aprovedEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'ETA',
        name: 'eta',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'bookingEtaStart' },
          { inp: 'input 1', size: 6, id: 'bookingEtaEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'Booking Date',
        name: 'bookingDate',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'bookingStart' },
          { inp: 'input 1', size: 6, id: 'bookingEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'Requested Date',
        name: 'requestDate',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'bookingRequestedStart' },
          { inp: 'input 1', size: 6, id: 'bookingRequestedEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'ETD',
        name: 'etd',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'bookingEtdStart' },
          { inp: 'input 1', size: 6, id: 'bookingEtdEnd' }
        ],
        type: 'Datetime'
      }
    ]
  },
  {
    _section: [
      { label: '', size: 3, type: 'Label' },
      { label: 'Shipment Information', size: 6, type: 'Label' },
      { label: '', size: 3, type: 'Icon' }
    ],
    _sectionContent: [
      {
        label: 'Shipment Number',
        name: 'shipmentNumber',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'shipmentNumber' }],
        type: 'Input'
      },
      {
        label: 'Shipment Status',
        name: 'shipmentstatus',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'shipmentStatus' }],
        type: 'Select',
        options: 'SHIPMENT-STATUS'
      },
      {
        label: '3PL',
        name: 'pl',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'pl' }],
        type: 'Select',
        options: 'SHIPMENT-THEREPLS'
      },
      {
        label: '3PL Reference',
        name: 'plReference',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'plReference' }],
        type: 'Input'
      },
      {
        label: 'Carrier',
        name: 'carrier',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'carrier' }],
        type: 'Select',
        options: 'SHIPMENT-CARRIER'
      },
      {
        label: 'Final Destination',
        name: 'finalDestination',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'filaDestination' }],
        type: 'Input',
      },
      {
        label: 'Booking Number',
        name: 'bookingNumber',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'bookingnumber' }],
        type: 'Input'
      },
      {
        label: 'PO\'s Number',
        name: 'poNumber',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'poNumber' }],
        type: 'Input'
      },
      {
        label: 'Origin',
        name: 'origin',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'origin' }],
        type: 'Select',
        options: 'SHIPMENT-ORIGIN'
      },
      {
        label: 'Destination',
        name: 'destination',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'destination' }],
        type: 'Select',
        options: 'SHIPMENT-DESTINATION'
      },
      {
        label: 'Transport Mode',
        name: 'transportMode',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'transportMode' }],
        type: 'Select',
        options: 'SHIPMENT-TRANSPORTS-MODE'
      },
      {
        label: 'Incoterm',
        name: 'incoterm',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'intercom' }],
        type: 'Select',
        options: 'SHIPMENT-INCOTERMS'
      },
      {
        label: 'Supplier',
        name: 'supplier',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'supplier' }],
        type: 'Select',
        options: 'SHIPMENT-SUPPLIER'
      },
      {
        label: 'Consignee',
        name: 'consignee',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'consignee' }],
        type: 'Select',
        options: 'SHIPMENT-CONSIGNEE'
      },
      {
        label: 'Delivery',
        name: 'delivery',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'delivery' }],
        type: 'Select',
        options: 'SHIPMENT-DELIVERY'
      },
      {
        label: 'Project',
        name: 'project',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'project' }],
        type: 'Input'
      },
      {
        label: 'HBL',
        name: 'hbl',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'hbl' }],
        type: 'Input'
      },
      {
        label: 'MBL',
        name: 'mbl',
        class: 'col-md-2',
        input: [{ inp: 'input 1', size: 12, id: 'mbl' }],
        type: 'Input'
      }
    ],

    _subSection: [{ label: 'Dates', size: 12 }],
    _subSectionContent: [
      {
        label: 'Shipment Date',
        name: 'shipmentDate',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'shimpmentStart' },
          { inp: 'input 1', size: 6, id: 'shipmentEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'ETA',
        name: 'eta',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'etaStart' },
          { inp: 'input 1', size: 6, id: 'etaEnd' }
        ],
        type: 'Datetime'
      },
      {
        label: 'ETD',
        name: 'etd',
        class: 'col-md-4',
        input: [
          { inp: 'input 1', size: 6, id: 'etdStart' },
          { inp: 'input 1', size: 6, id: 'etdEnd' }
        ],
        type: 'Datetime'
      }
    ]
  }
];

export default dataModel;
