import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { Container, Row ,Card,CardBody} from 'reactstrap'
import createHistory from 'history/createBrowserHistory';
// import RestService from '../../../../../services/restService';
import Axios from 'axios';
import {Services} from './../../Services';
// const rest = new RestService()

if (!window.AppHistory) {
    window.AppHistory = createHistory();
}


/*=============================================
=            Maps Container                   =
=============================================*/
export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            tags: '',
            myProps: this.props,
            data: [],
        };
    }

    componentDidMount = () => {
        this.getWarehouses()
        // this.buildTags()
    }

    getWarehouses = async () => {
      let success = await Axios.get(`${Services.RATES.path}search/warehouses/`)
      if (success.data && success.data.data) {
        this.setState({
            data: success.data.data,
        },()=> this.buildTags())
      }
    }

    onMarkerClick(props, marker, e) {
        this.setState({
            selectedPlace: props.data,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    buildTags = () => {
        let { data } = this.state
        let tags = []
        data.map((e, index) => {
            tags.push(<Marker
                onClick={this.onMarkerClick}
                title={'TO'}
                name={e.Name}
                data={e}
                position={{ lat: e.Latitude, lng: e.Longitude }}
            />)
        })
        this.setState({
            tags
        })
    }

    onInfoWindowOpen(props, e) {
        let { selectedPlace } = this.state
        const button = (
            // <Container>
                <div className="row" style={{width:'250px'}}>
                    <div className="col-3">
                        <em className="fa-3x fas fa-warehouse mr-2"></em>
                    </div>

                    <div className="col-9">
                        <div className="row">
                            <div className="col-12">
                                <span style={{fontWeight:'bold'}}>{selectedPlace.Name}</span>
                            </div>
                            <div className="col-12">
                                <span style={{fontWeight:'bold'}}>Location: </span>
                                <span>{selectedPlace.Location}</span>
                            </div>
                            <div className="col-12">
                                <span style={{fontWeight:'bold'}}>Size: </span>
                                <span>{selectedPlace.Size}</span>
                            </div>
                        </div>
                    </div>
                </div>
            // </Container>
        );
        ReactDOM.render(
            React.Children.only(button),
            document.getElementById("iwc")
        );
    }


    render() {
        if (!this.props.google) {
            return <div>Loading...</div>;
        }
        return (
          
                <Card className="myCard">
                    <CardBody>
                    <div
                    style={{
                        position: "relative",
                        height: "530px"
                    }}>
                    <Map style={{}} google={this.props.google} zoom={2} initialCenter={{
                        lat: 33.028859,
                        lng: -44.5060691
                    }}>
                        {this.state.tags}

                        <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}
                            onOpen={e => {
                                this.onInfoWindowOpen(this.props, e);
                            }}>
                            <div id="iwc" />
                        </InfoWindow>
                    </Map>
                </div>
                    </CardBody>
               
            </Card>
            
            
        );
    }
}
export default GoogleApiWrapper({
    apiKey: "AIzaSyAl_10ZKSqnrviAV-KSQ6c8i4Sa_gEhEmA",
    v: "3.30"
})(MapContainer);
