import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import restService from '../../../services/restService';
import { Grid, Modal, Button } from 'semantic-ui-react';
import filterIconHeader from '../../../resources/filter_white.png';
import {OrderForm} from './components/orders';
import {BookingForm} from './components/bookings';
import {ShipmentForm} from './components/shipments';
import {Services} from './Services';

import Authentication from '../../../services/Authentication.js';
import axios from 'axios';
import './styles.scss';

const auth = new Authentication();
class ModalAdvanceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.options,
      name: this.props.name,
      modalName: this.props.modalName,
      data: [],
      inputs: [],
      results: [],
      order: {},
      modalStatus: this.props.show,
      valid: true,
      isValdid: true,
      selectOptions:{},
      search: {}
    };
  }

  componentDidMount = () => {
    this.getAllCatalogs();
  };

  getAllCatalogs = async() => {
    let url = '';
    if (this.props.customer){
      if (this.props.name === 'OrderList'){
        url = `${Services.CATALOGS.path}advanced/search?type=order&cid=${this.props.customer}`;
      }else if(this.props.name === 'BookingList'){
        url = `${Services.CATALOGS.path}advanced/search?type=booking&cid=${this.props.customer}`;
      } else {
        url = `${Services.CATALOGS.path}advanced/search?type=tracking&cid=${this.props.customer}`;
      }
      
      let data = await axios.get(url);
      this.setState({ selectOptions:  data.data.data }, () => {
        this.buildForm();
      });
    }
  };

  getOrderFilter = () => {
    const sendOrder = this.state.order;
    const id  = auth.getAuthentication('customerSelect');
    const order = {
      CustomerId: id,
      OriginCountryId: sendOrder['poOrigin'],
      ProjectId: sendOrder['project'],
      TransportModeId: sendOrder['transportMode'],
      OrderValue: sendOrder['orderValue'],
      StatusId: sendOrder['poStatus'],
      SupplierCode: sendOrder['supplier'],
      ConsigneeCode: sendOrder['consignee'],
      DeliveryCode: sendOrder['delivery'],
      ItemNumber: sendOrder['itemNumber'],
      ItemDescription: sendOrder['itemDescription'],
      POCreateDate: {
        startDate: sendOrder['creationStart'],
        endDate: sendOrder['creationEnd']
      },
      RequestedExWorksDate: {
        startDate: sendOrder['orderRequestedStart'],
        endDate: sendOrder['orderRequestedEnd']
      },
      RequiredDestination: {
        startDate: sendOrder['requiredDestinationStart'],
        endDate: sendOrder['requiredDestinationEnd']
      }
    };

    axios.post(`${Services.ORDER.path}/order/search/orderlist`,{order})
      .then(response => {
        // console.log('Order list search -->', response);
        this.setState({ search: response.data.data }, () =>
          this.props.triggerParentupdate(this.state.search)
        );
      }).catch(() => {
      });
      
  };

  getBookingList = () => {
    const sendOrder = this.state.order;
    const id  = auth.getAuthentication('customerSelect');
    const order = {
      BookingNumber: sendOrder['bookingNumber'],
      CustomerId: id,
      StatusId: sendOrder['bookingStatus'],
      CarrierCode: sendOrder['carrier'],
      OriginName: sendOrder['origin'],
      SupplierCode: sendOrder['supplier'],
      ThreePLId: sendOrder['pl'],
      ThreePLBookingRef: sendOrder['plReference'],
      TransportModeId: sendOrder['transportMode'],
      DestinationName: sendOrder['destination'],
      ConsigneeCode: sendOrder['consignee'],
      DeliveryCode: sendOrder['delivery'],
      POIds: sendOrder['poNumber'],
      ETA: {
        startDate: sendOrder['bookingEtaStart'],
        endDate: sendOrder['bookingEtaEnd']
      },
      ETD: {
        startDate: sendOrder['bookingEtdStart'],
        endDate: sendOrder['bookingEtdEnd']
      },
      BookingDate: {
        startDate: sendOrder['bookingStart'],
        endDate: sendOrder['bookingEnd']
      },
      CargoReadyDate: {
        startDate: sendOrder['cargoReadyStart'],
        endDate: sendOrder['cargoReadyEnd']
      }
    };
    // _function: 'GetBookingsFilter',
    // axios.post(`${Services.GET_ALL_BOOKINGS.path}/search`, order).then( success => {
    axios.post('http://localhost:3017/search', order).then( success => {
      this.setState({ search: success.data.data }, () => this.props.triggerParentupdate(this.state.search));
    }).catch( error => {
      if (error.customMessage) {
        this.setState({ search: 0 }, () =>
          this.props.triggerParentupdate(this.state.search)
        );
      }      
    });
  };

  getShipmentList = () => {
    const sendOrder = this.state.order;
    const id  = auth.getAuthentication('customerSelect');
    const order = {
      ShipmentNumber: sendOrder['shipmentNumber'],
      CustomerId: id,
      StatusId: sendOrder['shipmentstatus'],
      ThreePLId: sendOrder['pl'],
      ThreePLBookingRef: sendOrder['plReference'],
      CarrierName: sendOrder['carrier'],
      FinalDeliveryDesc: sendOrder['finalDestination'],
      BookingNumber: sendOrder['bookingNumber'],
      POs: sendOrder['poNumber'],
      OriginName: sendOrder['origin'],
      DestinationName: sendOrder['destination'],
      TransportModeId: sendOrder['transportMode'],
      IncotermCode: sendOrder['incoterm'],
      SupplierCode: sendOrder['supplier'],
      ConsigneeCode: sendOrder['consignee'],
      DeliveryCode: sendOrder['delivery'],
      Project: sendOrder['project'],
      HBL_HAWB: sendOrder['hbl'],
      MBL_AWB: sendOrder['mbl'],
      ETA: {
        startDate: sendOrder['etaStart'],
        endDate: sendOrder['etaEnd']
      },
      ETD: {
        startDate: sendOrder['etdStart'],
        endDate: sendOrder['etdEnd']
      },
      ShipmentDate: {
        startDate: sendOrder['shimpmentStart'],
        endDate: sendOrder['shipmentEnd']
      },
      ContainerNumber: sendOrder['containerNumber']
    };
    // _function: 'GetShipmentsFilter'
    // axios.post('http://localhost:3001/shipment/search/filter/', order).then(success => {
    axios.post(`${Services.SHIPMENTS.path}shipment/search/filter`, order).then(success => {
      this.setState({ data: success.data.data }, () => this.props.triggerParentupdate(this.state.data));
    }).catch(error => {
      console.error('getShipmentList: ', error);
    });
  };

  handleResults = (results) => {
    this.setState({order: results} );
  }

  validateDate = (isValdid) => {
    this.setState({isValdid});
  }

  onclose = () => {
    const { order, name } = this.state;
    try {
      if (Object.keys(order).length === 0) {
        name === 'OrderList'
          ? this.setState({ order: [] }, () => this.getOrderFilter())
          : name === 'BookingList'
            ? this.setState({ order: [] }, () => this.getBookingList())
            : this.setState({ order: [] }, () => this.getShipmentList());
      } else {
        let result = '';
        Object.values(order).forEach(value => {
          result += value;
        });

        if (result === '') {
          name === 'OrderList'
            ? this.setState({ order: [] }, () => this.getOrderFilter())
            : name === 'BookingList'
              ? this.setState({ order: [] }, () => this.getBookingList())
              : this.setState({ order: [] }, () => this.getShipmentList());
        } else {
          this.setState(this.props.onClose);
        }
      }
    } catch (error) {
      this.setState(this.props.onClose);
    }
  };

  buildForm() {
    const modal = this.props.name;
    return (
      <Grid>
        {modal === 'OrderList' ? (
          <OrderForm orderExist={this.state.order} selects={this.state.selectOptions} onResults={this.handleResults} validateDate={this.validateDate}/>
        ) : modal=== 'BookingList' ? (
          <BookingForm orderExist={this.state.order} selects={this.state.selectOptions} onResults={this.handleResults} validateDate={this.validateDate}/>
        ) : (
          <ShipmentForm orderExist={this.state.order} selects={this.state.selectOptions} onResults={this.handleResults} validateDate={this.validateDate}/>
        )}
      </Grid>
    );
  
  }

  render() {
    const { name } = this.state;
  
    return (
      <Modal open={this.props.show} className={this.props.modalName} size={'large'} centered={false}>
        
        <Modal.Header className="ui header Modal advanced search">
          <h5><img className="ui filter header" src={filterIconHeader} alt="img"/>Advanced Search</h5>
        </Modal.Header>

        <Modal.Content className="ui Modal content body" scrolling>
          {this.buildForm()}
        </Modal.Content>
        
        
        <Modal.Actions className="ui Modal actions">
          <Grid>
            <Grid.Column width={8}>
              <Button className="ui button search" compact floated='right'
                disabled={!this.state.isValdid}
                onClick={
                  name === 'OrderList'
                    ? () => this.getOrderFilter()
                    : name === 'BookingList'
                      ? () => this.getBookingList()
                      : () => this.getShipmentList()
                }
              >Search</Button>
            </Grid.Column>
            <Grid.Column width={8}>
              <Button className="ui button cancel" floated='left' compact inverted color='red' onClick={this.onclose} content='Cancel'/>
            </Grid.Column>
          </Grid>
        </Modal.Actions> 
      </Modal>
    );
  }
}

ModalAdvanceSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool
};

export default ModalAdvanceSearch;
