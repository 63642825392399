
export const EN = {
	'btn_new_ticket': {
		'title': 'Create new',
		'icon': 'far fa-question-circle mr-2',
		'btn_create': 'Create Ticket',
		'btn_cancel': 'Cancel',
		'upload': 'Click to upload (images, excel, pdf, word)',
	},
	'header': {
		'title': 'Service Desk',
		'icon': 'far fa-question-circle mr-2'
	},
	'open_tickets': {
		'title': 'Open Tickets',
		// 'table_headers': {
		//   'ticket_no': 'Ticket No.'
		// },
		// 'table_footer': {}
	},
	'closed_tickets': {
		'title': 'Closed Tickets',
		// 'table_headers': {
		//   'ticket_no': 'Ticket No.'
		// },
		// 'table_footer': {}
	},
	'new_ticket_form': {
		'title': 'New Ticket',
		'name': {
			'title': 'Name',
		},
		'rol': {
			'title': 'Role'
		},
		'email': {
			'title': 'Email'
		},
		'phone': {
			'title': 'Phone',
			'placeholder': 'Phone'
		},
		'ccto': {
			'title': 'CC to',
			'info': 'Place the email of the users you want to be notified of the track of the ticket.',
			'name': 'Name',
			'email': 'Email',
			'placeholderName': 'Contact Name',
			'placeholderEmail': 'Email'
		},
		'type': {
			'title': 'Type',
			'help': `• Error: Partial or total service interruption of a module or functionality.
• Request: Creation of users, reports and alerts.
• Change:  Addition,  modification  or  deletion  of  something  that  could  affect  the current functioning of the application.`,
			'placeholder': 'Type'
		},
		'module': {
			'title': 'Module',
			'help': 'Reference module for attention.',
			'placeholder': 'Module'
		},
		'sub_module': {
			'title': 'Sub Module',
			'help': 'Specify the reference module for attention.',
			'placeholder': 'Sub Module'
		},
		'severity': {
			'title': 'Severity',
			'help': 'Select the level of impact of the Error in the operation of the application.',
			'placeholder': 'Severity'
		},
		'request': {
			'title': 'Request',
			'help': 'Select the request you require.',
			'placeholder': 'Request'
		},
		'request_type': {
			'title': 'Request Type',
			'help': 'The  type  refers  to  a  Creation,  Modification  or  deletion  of  the  previously  selected application.',
			'placeholder': 'Request Type'
		},
		'subject': {
			'title': 'Subject',
			'help': 'Ticket title',
			'placeholder': 'Subject'
		},
		'description': {
			'title': 'Description',
			'help': 'Ticket description',
			'placeholder': 'Description'
		},
		// 'attachments': {
		//   'title': 'Attachments',
		//   'upload': {
		//     'title': 'Documents'
		//   }
		// },
		// 'add_comment': {
		//   'title': 'Add comment'
		// }
	},
	'details': {
		'title': 'Service Desk',
		'last_update': 'Last Update',
		'lbl_close': 'Close Date:',
		'btn_close_ticket': {
			'title': 'Cancel Ticket',
			'titleModal': 'Cancel Ticket',
			// 'warning': 'Are you sure want to cancel the ticket',
			// 'reason': 'Reason',
			'btn_cancel': 'Cancel',
			'btn_confirm': 'Cancel Ticket'
		},
		'user': {
			'title': 'User',
			'info': {
				'name': 'Name',
				'role': 'Role',
				'email': 'Email',
				'phone': 'Phone'
			}
		},
		'ticket': {
			'title': 'Ticket',
			'info': {
				'type': 'Type',
				'module': 'Module',
				'RequestModule': 'Request',
				'sub_module': 'Sub Module',
				'RequestTypeSubModule': 'Request type',
				'creation_date': 'Creation Date',
				'assignet_to': 'Assigned To',
				'severity': 'Severity',
				'description': 'Description',
			}
		},
		'comments': {
			'title': 'Comments',
			'from': 'User',
			'btn_send_comment': 'Send Comment'
		},
		'documents': {
			'title': 'Documents',
			'modal_add_document': {
				'button': ' Attachments',
				'title': 'Add Document',
				'upload': 'Click to upload (images, excel, pdf, word)',
				'label': 'Document Name',
				'placeholder': 'Document Name',
				'btn_upload': 'Upload',
				'btn_cancel': 'cancel'
			}
		},
	},
	'alerts': {
		'new_ticket': {
			'header': 'Ticket Created!!!',
			'message': 'Your ticket has been created successfully!'
		},
		'closed_ticket': {
			'header': 'ticket closed',
			'message': 'Your ticket has been closed!!'
		},
		'confirm_deletion': {
			'title': 'Are you sure to delete the contact?',
			'confim': 'Confirm',
			'cancel': 'Cancel'
		},
	},
};

export const ES = {
	'btn_new_ticket': {
		'title': 'Crear Nuevo',
		'icon': 'far fa-question-circle mr-2',
		'btn_create': 'Crear Ticket',
		'btn_cancel': 'Cancelar',
		'upload': 'Click para subir documentos (Imágenes, Excel, PDF, Word)',
	},
	'header': {
		'title': 'Service Desk',
		'icon': 'far fa-question-circle mr-2'
	},
	'open_tickets': {
		'title': 'Tickets Abiertos',
		// 'table_headers': {
		//   'ticket_no': 'Ticket No.'
		// },
		// 'table_footer': {}
	},
	'closed_tickets': {
		'title': 'Tickets Cerrados',
		// 'table_headers': {
		//   'ticket_no': 'Ticket No.'
		// },
		// 'table_footer': {}
	},
	'new_ticket_form': {
		'title': 'Nuevo Ticket',
		'name': {
			'title': 'Nombre'
		},
		'rol': {
			'title': 'Rol'
		},
		'email': {
			'title': 'Correo electrónico'
		},
		'phone': {
			'title': 'Teléfono',
			'placeholder': 'Teléfono'
		},
		'ccto': {
			'title': 'CC to',
			'info': 'Coloca  el  correo  electrónico  de  los  usuarios  que  quieres  que  se  les  notifique  el seguimiento del ticket',
			'name': 'Nombre',
			'email': 'Correo',
			'placeholderName': 'Nombre del Contacto',
			'placeholderEmail': 'Correo'
		},
		'type': {
			'title': 'Tipo',
			'help': `• Error: Interrupción parcial o total del servicio de algún módulo o funcionalidad. 
• Request: Creación de usuarios, reportes y alertas. 
• Change:  Adición,  modificación  o  eliminación  de  algo  que  podría  afectar  el funcionamiento actual de la aplicación.`,
			'placeholder': 'Tipo'
		},
		'module': {
			'title': 'Módulo',
			'help': 'Módulode referencia para la atención.',
			'placeholder': 'Módulo'
		},
		'sub_module': {
			'title': 'Sub Módulo',
			'help': 'Especificar el módulo de referencia para la atención.',
			'placeholder': 'Sub Módulo'
		},
		'severity': {
			'title': 'Severidad',
			'help': 'Seleccione el nivel de impacto del Error en el funcionamiento de la aplicación',
			'placeholder': 'Severidad'
		},
		'request': {
			'title': 'Solicitud',
			'help': 'Seleccione la solicitud que requiere. ',
			'placeholder': 'Solicitud'
		},
		'request_type': {
			'title': 'Tipo de solicitud',
			'help': 'El typese refiere una Creación, Modificación o eliminación de la solicitud previamente seleccionada.',
			'placeholder': 'Tipo de solicitud'
		},
		'subject': {
			'title': 'Título',
			'help': 'Título del Ticket',
			'placeholder': 'Título'
		},
		'description': {
			'title': 'Descripción',
			'help': 'Descripción de el Ticket',
			'placeholder': 'Descripción'
		},
		// 'attachments': {
		//   'title': 'Attachments',
		//   'upload': {
		//     'title': 'Documents'
		//   }
		// },
		// 'add_comment': {
		//   'title': 'Add comment'
		// }
	},
	'details': {
		'title': 'Service Desk',
		'last_update': 'Ultima actualización',
		'lbl_close': 'Fecha de Cierre:',
		'btn_close_ticket': {
			'title': 'Cancelar Ticket',
			'titleModal': 'Cancelar Ticket',
			// 'warning': '¿Seguro que quiere cancelar el Ticket?',
			// 'reason': 'Razon',
			'btn_cancel': 'Cancelar',
			'btn_confirm': 'Cancelar Ticket'
		},
		'user': {
			'title': 'Usuario',
			'info': {
				'name': 'Nombre',
				'role': 'Rol',
				'email': 'Correo Electrónico',
				'phone': 'Teléfono'
			}
		},
		'ticket': {
			'title': 'Ticket',
			'info': {
				'type': 'Tipo',
				'module': 'Módulo',
				'sub_module': 'Sub Módulo',
				'creation_date': 'Fecha de Creación',
				'assignet_to': 'Asignado a',
				'severity': 'Severidad',
				'description': 'Descripción',
			}
		},
		'comments': {
			'title': 'Comentarios',
			'from': 'Usuario',
			'btn_send_comment': 'Enviar Comentario'
		},
		'documents': {
			'title': 'Documentos',
			'modal_add_document': {
				'button': ' Archivos Adjuntos',
				'title': 'Agregar Documentos',
				'upload': 'Click para subir documentos (Imágenes, Excel, PDF, Word)',
				'label': 'Nombre del Documento',
				'placeholder': 'Nombre del Documento',
				'btn_upload': 'Subir',
				'btn_cancel': 'Cancelar'
			}
		},
	},
	'alerts': {
		'new_ticket': {
			'header': 'Ticket creado',
			'message': 'Su ticket ha sido creado!!'
		},
		'closed_ticket': {
			'header': 'Ticket Cerrado!',
			'message': 'El ticket a sido cerrado correctamente!'
		},
		'confirm_deletion': {
			'title': 'Esta seguro de que desea eliminar el contacto',
			'confim': 'Confirmar',
			'cancel': 'Cancelar'
		},
	},
};

