const dummy = {
  //Data Charts
  DataCharts: {
    message: "Customs list",
    status: 200,
    data: {
      chartImpr: [
        {
          status: "Entregado",
          Cancelled: 96,
          id: "Entregado",
          CancelledColor: "#4EAA29",
          key: 10,
        },
        {
          status: "Radicado",
          Cancelled: 3076,
          id: "Radicado",
          CancelledColor: "#3B831E",
          key: 9,
        },
        {
          status: "Facturado",
          Cancelled: 0,
          id: "Facturado",
          CancelledColor: "#CFF9BD",
          key: 8,
        },
        {
          status: "Despachado",
          Cancelled: 7,
          id: "Despachado",
          CancelledColor: "#3B831E",
          key: 7,
        },
        {
          status: "Levantado",
          Cancelled: 15,
          id: "Levantado",
          CancelledColor: "#98F56C",
          key: 6,
        },
        {
          status: "Aceptado",
          Cancelled: 14,
          id: "Aceptado",
          CancelledColor: "#9EF571",
          key: 5,
        },
        {
          status: "En Inspección",
          Cancelled: 1,
          id: "En Inspección",
          CancelledColor: "#78F340",
          key: 4,
        },
        {
          status: "En Depósito",
          Cancelled: 2,
          id: "En Depósito",
          CancelledColor: "#61CF35",
          key: 3,
        },
        {
          status: "Arribado",
          Cancelled: 10,
          id: "Arribado",
          CancelledColor: "#4EAA29",
          key: 2,
        },
        {
          status: "Instruccionado",
          Cancelled: 9,
          id: "Instruccionado",
          CancelledColor: "#3B831E",
          key: 1,
        },
      ],
      charExpo: [
        {
          status: "Radicado",
          Cancelled: 943,
          id: "Radicado",
          CancelledColor: "#F9F3C1",
          key: 7,
        },
        {
          status: "Facturado",
          Cancelled: 0,
          id: "Facturado",
          CancelledColor: "#FAF2B1",
          key: 6,
        },
        {
          status: "Manifestado",
          Cancelled: 8,
          id: "Manifestado",
          CancelledColor: "#FBF1A0",
          key: 5,
        },
        {
          status: "Autorizado",
          Cancelled: 0,
          id: "Autorizado",
          CancelledColor: "#FCF07B",
          key: 4,
        },
        {
          status: "En Zona Primaria",
          Cancelled: 0,
          id: "En Zona Primaria",
          CancelledColor: "#F5E663",
          key: 3,
        },
        {
          status: "Recolectado",
          Cancelled: 0,
          id: "Recolectado",
          CancelledColor: "#FDEE4F",
          key: 2,
        },
        {
          status: "Instruccionado",
          Cancelled: 3,
          id: "Instruccionado",
          CancelledColor: "#FAEA4E",
          key: 1,
        },
      ],
    },
  },
  //Data map
  DataMap: {
    message: "Customs LOCATION",
    status: 200,
    data: [
      {
        AduanaId: 39,
        Codigo: "MCJ",
        Aduana: "MAICAO",
        Ubicacion: "Calle 16 Base Militar San Jorge",
        Longitud: "-72.2668317",
        Latitud: "11.3834794",
        TotalImpo: "0",
        TotalDTA: "0",
        TotalExpo: "5",
        Total: "5",
      },
      {
        AduanaId: 35,
        Codigo: "BUN",
        Aduana: "BUENAVENTURA",
        Ubicacion: "Calle 8 N° 3 - 50 Oficina 302/ 303 Edificio Compañia",
        Longitud: "-77.076481",
        Latitud: "3.890819",
        TotalImpo: "391",
        TotalDTA: "0",
        TotalExpo: "122",
        Total: "513",
      },
      {
        AduanaId: 48,
        Codigo: "CTG",
        Aduana: "CARTAGENA",
        Ubicacion: "Manga, Carrera 27 No. 29 - 43 Unidad 4 Zona Franca",
        Longitud: "-75.5315826",
        Latitud: "10.4097472",
        TotalImpo: "3596",
        TotalDTA: "5",
        TotalExpo: "639",
        Total: "4240",
      },
      {
        AduanaId: 3,
        Codigo: "BOG",
        Aduana: "BOGOTA",
        Ubicacion: "Cra. 100 No. 25b-40",
        Longitud: "-74.1314617",
        Latitud: "4.6844635",
        TotalImpo: "349",
        TotalDTA: "0",
        TotalExpo: "331",
        Total: "680",
      },
      {
        AduanaId: 87,
        Codigo: "BAQ",
        Aduana: "BARRANQUILLA",
        Ubicacion:
          "Carrera 30 Avenida Hamburgo Edificio Administrativo Zona Franca Piso 2",
        Longitud: "-74.7685746",
        Latitud: "10.9671021",
        TotalImpo: "0",
        TotalDTA: "0",
        TotalExpo: "13",
        Total: "13",
      },
      {
        AduanaId: 37,
        Codigo: "IPI",
        Aduana: "IPIALES",
        Ubicacion: "Carrera 6 No. 14 - 33 Oficina 402 Piso 4 Edificio Bastidas",
        Longitud: "-77.6391767",
        Latitud: "0.835371",
        TotalImpo: "0",
        TotalDTA: "0",
        TotalExpo: "72",
        Total: "72",
      },
    ],
    marker: {
      marker: [
        {
          coords: { latitude: 11.3834794, longitude: -72.2668317 },
          data: {
            AduanaId: 39,
            Codigo: "MCJ",
            Aduana: "MAICAO",
            Ubicacion: "Calle 16 Base Militar San Jorge",
            Longitud: "-72.2668317",
            Latitud: "11.3834794",
            TotalImpo: "0",
            TotalDTA: "0",
            TotalExpo: "5",
            Total: "5",
          },
          icon: "Aduana",
        },
        {
          coords: { latitude: 3.890819, longitude: -77.076481 },
          data: {
            AduanaId: 35,
            Codigo: "BUN",
            Aduana: "BUENAVENTURA",
            Ubicacion: "Calle 8 N° 3 - 50 Oficina 302/ 303 Edificio Compañia",
            Longitud: "-77.076481",
            Latitud: "3.890819",
            TotalImpo: "391",
            TotalDTA: "0",
            TotalExpo: "122",
            Total: "513",
          },
          icon: "Aduana",
        },
        {
          coords: { latitude: 10.4097472, longitude: -75.5315826 },
          data: {
            AduanaId: 48,
            Codigo: "CTG",
            Aduana: "CARTAGENA",
            Ubicacion: "Manga, Carrera 27 No. 29 - 43 Unidad 4 Zona Franca",
            Longitud: "-75.5315826",
            Latitud: "10.4097472",
            TotalImpo: "3596",
            TotalDTA: "5",
            TotalExpo: "639",
            Total: "4240",
          },
          icon: "Aduana",
        },
        {
          coords: { latitude: 4.6844635, longitude: -74.1314617 },
          data: {
            AduanaId: 3,
            Codigo: "BOG",
            Aduana: "BOGOTA",
            Ubicacion: "Cra. 100 No. 25b-40",
            Longitud: "-74.1314617",
            Latitud: "4.6844635",
            TotalImpo: "349",
            TotalDTA: "0",
            TotalExpo: "331",
            Total: "680",
          },
          icon: "Aduana",
        },
        {
          coords: { latitude: 10.9671021, longitude: -74.7685746 },
          data: {
            AduanaId: 87,
            Codigo: "BAQ",
            Aduana: "BARRANQUILLA",
            Ubicacion:
              "Carrera 30 Avenida Hamburgo Edificio Administrativo Zona Franca Piso 2",
            Longitud: "-74.7685746",
            Latitud: "10.9671021",
            TotalImpo: "0",
            TotalDTA: "0",
            TotalExpo: "13",
            Total: "13",
          },
          icon: "Aduana",
        },
        {
          coords: { latitude: 0.835371, longitude: -77.6391767 },
          data: {
            AduanaId: 37,
            Codigo: "IPI",
            Aduana: "IPIALES",
            Ubicacion:
              "Carrera 6 No. 14 - 33 Oficina 402 Piso 4 Edificio Bastidas",
            Longitud: "-77.6391767",
            Latitud: "0.835371",
            TotalImpo: "0",
            TotalDTA: "0",
            TotalExpo: "72",
            Total: "72",
          },
          icon: "Aduana",
        },
      ],
      region: [
        { latitude: 11.3834794, longitude: -72.2668317 },
        { latitude: 3.890819, longitude: -77.076481 },
        { latitude: 10.4097472, longitude: -75.5315826 },
        { latitude: 4.6844635, longitude: -74.1314617 },
        { latitude: 10.9671021, longitude: -74.7685746 },
        { latitude: 0.835371, longitude: -77.6391767 },
      ],
    },
  },
  //importCatalogo
  importCatalogo: {
    message: "Customs list",
    status: 200,
    data: {
      Aduana: [
        { Aduana: "BOGOTA" },
        { Aduana: "CARTAGENA" },
        { Aduana: "BUENAVENTURA" },
      ],
      Estatus: [
        { Estatus: null },
        { Estatus: "Aceptado" },
        { Estatus: "Entregado" },
        { Estatus: "Inspección Finalizada" },
        { Estatus: "Contenedor Devuelto" },
        { Estatus: "Despachado" },
        { Estatus: "Arribado" },
        { Estatus: "Levantado" },
        { Estatus: "Docs Liberados" },
        { Estatus: "Docs Entregados" },
        { Estatus: "Instruccionado" },
        { Estatus: "En Inspección" },
        { Estatus: "Radicado" },
        { Estatus: "En Depósito" },
      ],
      TransportadorInternacional: [
        { TransportadorInternacional: null },
        { TransportadorInternacional: "AEROLINEA DEL CARIBE S.A." },
        {
          TransportadorInternacional:
            "AIR EUROPA LINEAS AEREAS SOCIEDAD ANONIMA - TRANSPORTE AEREO DE CARGA",
        },
        { TransportadorInternacional: "LINEA AEREA CARGUERA DE COLOMBIA S.A." },
        { TransportadorInternacional: "NAVEMAR S.A.S TRANSPORTE MARITIMO" },
        {
          TransportadorInternacional:
            "FRONTIER AGENCIA MARITIMA DEL CARIBE S.A.S. - CABOTAJE",
        },
        { TransportadorInternacional: "SEABOARD DE COLOMBIA S.A." },
        { TransportadorInternacional: "IBERIA - TRANSPORTE AEREO DE CARGA" },
        {
          TransportadorInternacional:
            "MAS AIR. AEROTRANSPORTES MAS DE CARGA S.A. DE C.V. SUC. COLOMBIA",
        },
        { TransportadorInternacional: "HAPAG LLOYD COLOMBIA LTDA." },
        { TransportadorInternacional: "VARIOS - TRANSPORTADOR TERRESTRE" },
        {
          TransportadorInternacional:
            "MEDITERRANEAN SHIPPING COMPANY COLOMBIA S.A. - TRANSPORTADOR MARITIMO (CARTAGENA)",
        },
        {
          TransportadorInternacional:
            "FEDERAL EXPRESS CORPORATION: TRANSPORTE AEREO DE CARGA",
        },
        {
          TransportadorInternacional:
            "AVIANCA S.A. AEROVIAS DEL CONTINENTE AMERICANO SA - TRANSPORTE AEREO DE CARGA",
        },
        { TransportadorInternacional: "EDUARDO L GERLEIN S.A" },
        { TransportadorInternacional: "AIR FRANCE" },
        { TransportadorInternacional: "GERLEINCO S.A.S." },
        { TransportadorInternacional: "VARIOS- TRANSPORTADOR TERRESTRE" },
        { TransportadorInternacional: "AIR CANADA SUCURSAL COLOMBIA\t" },
        { TransportadorInternacional: "ATLAS AIR INC SUCURSAL COLOMBIA" },
        { TransportadorInternacional: "AGENCIA MARITIMA TRANSMARES LTDA" },
        {
          TransportadorInternacional: "KLM CIA. REAL HOLANDESA DE AVIACION.\t",
        },
        { TransportadorInternacional: "CIA TRANSPORTADORA S.A." },
        { TransportadorInternacional: "AGENCIA OCEANICA OCEANIC LTDA" },
        { TransportadorInternacional: "QATAR AIRWAYS SUCURSAL COLOMBIA" },
        {
          TransportadorInternacional:
            "AMERICAN AIRLINES\t INC SUCURSAL COLOMBIANA",
        },
        {
          TransportadorInternacional:
            "AGENCIA OCEANICA OCEANIC LTDA. - TRANSPORTADOR MARITIMO",
        },
        { TransportadorInternacional: "NAVES S.A." },
        { TransportadorInternacional: "AGENCIA MARITIMA INTERNACIONAL" },
        {
          TransportadorInternacional:
            "HAPAG LLOYD COLOMBIA LTDA. - CABOTAJE  CODIGO",
        },
        {
          TransportadorInternacional: "MEDITERRANEAN SHIPPING COMPANY COLOMBIA",
        },
        { TransportadorInternacional: "MAERSK COLOMBIA S.A" },
        {
          TransportadorInternacional: "DHL AERO EXPRESO S A SUCURSAL COLOMBIA",
        },
        { TransportadorInternacional: "1962" },
        {
          TransportadorInternacional:
            "AEROVIAS DE MEXICO S.A DE C.V AEROMEXICO SUCURSAL COLOMBIA - TRANSPORTE AEREO DE CARGA",
        },
        { TransportadorInternacional: "UNION ANDINA DE TRANSPORTES" },
        { TransportadorInternacional: "FRONTIER AGENCIA MARITIMA" },
        {
          TransportadorInternacional:
            "FRONTIER AGENCIA MARITIMA - TRANSPORTADOR MARITIMO",
        },
        { TransportadorInternacional: "FRONTIER AGENCIA MARITIMA\t" },
        {
          TransportadorInternacional: "TURKISH AIRLINES INC SUCURSAL COLOMBIA",
        },
        {
          TransportadorInternacional:
            "MAERSK COLOMBIA S.A - TRANSPORTADOR MARITIMO",
        },
        {
          TransportadorInternacional:
            "TAMPA - TRANSPORTES AEREOS MERCANTILES PANAMERICANOS S.A.",
        },
        {
          TransportadorInternacional:
            "COMPANIA PANAMENA DE AVIACION S.A. COPA.\t",
        },
        { TransportadorInternacional: "UNITED PARCEL SERVICES\t" },
        { TransportadorInternacional: "NAVEMAR S.A.S" },
        { TransportadorInternacional: "FEDERAL EXPRESS" },
        {
          TransportadorInternacional:
            "AGENCIA MARITIMA INTERNACIONAL- TRANSPORTE MARITIMO",
        },
        { TransportadorInternacional: "TAMPA CARGO S.A.S. - CABOTAJE" },
        { TransportadorInternacional: "DHL EXPRESS COLOMBIA LTDA" },
        {
          TransportadorInternacional:
            "ROLCO SHIPPING SAS - TRANSPORTADOR MARITIMO",
        },
        {
          TransportadorInternacional:
            "ALMAVIVA GLOBAL CARGO S.A.S - TRANSITO ADUANERO DE MERCANCIAS SIN NACIONALIZAR",
        },
        {
          TransportadorInternacional: "CARIBBEAN AMERICAN SHIPPING AGENCY LTDA",
        },
        { TransportadorInternacional: "VARIOS" },
        { TransportadorInternacional: "LUFTHANSA CARGO SUCURSAL COLOMBIA" },
      ],
      ModoTransporte: [
        { ModoTransporte: "MARÍTIMO" },
        { ModoTransporte: "AÉREO" },
      ],
      Cliente: [
        { Cliente: "CLIENTE 1 SA" },
        { Cliente: "CLIENTE 2 SA" },
      ],
      ConFechaLevante: [{ ConFechaLevante: "No" }, { ConFechaLevante: "Si" }],
    },
  },
  //listado importaciones
  listadoImportaciones: {
    message: "Customs list",
    status: 200,
    data: [
      {
        ImportacionId: 236085,
        DOAduanaImpo: "CTG2206053001",
        EsDTA: "0",
        DocTransporte: "LPL1106062",
        RefCliente: "20976",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "FRONTIER AGENCIA MARITIMA",
        TipoCarga: null,
        PuertoOrigen: "GOTHENBURG",
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: "FRONTIER AGENCIA MARITIMA",
        ETA: "2022-04-18T00:00:00.000Z",
        ATA: "18-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "19-04-2022",
        FechaLevante: "20-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "452,047,170",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionClienteAA: "2022-03-25T00:00:00.000Z",
        NIT: "860002067",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "116015.08",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-21T14:21:14.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-24T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-03-27T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito:
          "TNAL C/NEDOR C/GENA SA DEPOSITO PUBLICO-CONTECAR CARTAGENA",
        Total: "3242",
      },
      {
        ImportacionId: 243616,
        DOAduanaImpo: "BOG2207785001",
        EsDTA: "0",
        DocTransporte: "729MIA40529926",
        RefCliente: "21178",
        Proyecto: null,
        AduanaId: 3,
        Aduana: "BOGOTA",
        ModoTransporte: "AÉREO",
        TransportadorInternacional:
          "TAMPA - TRANSPORTES AEREOS MERCANTILES PANAMERICANOS S.A.",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: "2022-04-15T00:00:00.000Z",
        ATA: "15-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "19-04-2022",
        FechaLevante: "20-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 13,
        Estatus: "Docs Entregados",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "94,640,335",
        TotalIva: "17982000",
        TotalAranceles: "0",
        TotalTributos: "17982000",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionClienteAA: "2022-04-19T00:00:00.000Z",
        NIT: "860002067",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "19448.53",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-21T15:12:52.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: "2022-04-21T00:00:00.000Z",
        FechaEstimadaLevante: "2022-04-21T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-20T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "DEPOSITO ABC 1",
        Total: "3242",
      },
      {
        ImportacionId: 243306,
        DOAduanaImpo: "BOG2207458001",
        EsDTA: "0",
        DocTransporte: "3387274354",
        RefCliente: "21122",
        Proyecto: null,
        AduanaId: 3,
        Aduana: "BOGOTA",
        ModoTransporte: "AÉREO",
        TransportadorInternacional: "DHL EXPRESS COLOMBIA LTDA",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 2,
        Comentarios: null,
        ETA: "2022-04-15T00:00:00.000Z",
        ATA: "14-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "14-04-2022",
        FechaLevante: "20-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 13,
        Estatus: "Docs Entregados",
        TotalDeclaraciones: "2",
        DecIniciales: "2",
        DecAnticipadas: "0",
        DecOrdinarias: "2",
        DecTemporales: "0",
        TotalCIF: "32,357,169",
        TotalIva: "6147000",
        TotalAranceles: "0",
        TotalTributos: "6147000",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionClienteAA: "2022-04-13T00:00:00.000Z",
        NIT: "860002067",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "8228.97",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-21T14:16:16.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: "2022-04-21T00:00:00.000Z",
        FechaEstimadaLevante: "2022-04-20T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-14T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "DEPOSITO ABC 1",
        Total: "3242",
      },
      {
        ImportacionId: 236084,
        DOAduanaImpo: "CTG2206052001",
        EsDTA: "0",
        DocTransporte: "LPL1115266",
        RefCliente: "21069",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "FRONTIER AGENCIA MARITIMA",
        TipoCarga: null,
        PuertoOrigen: "GOTHENBURG",
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: "2022-04-18T00:00:00.000Z",
        ATA: "18-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "18-04-2022",
        FechaLevante: "20-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "300,983,637",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionClienteAA: "2022-03-25T00:00:00.000Z",
        NIT: "860002067",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "77241.41",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-21T14:21:15.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-24T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-03-27T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito:
          "TNAL C/NEDOR C/GENA SA DEPOSITO PUBLICO-CONTECAR CARTAGENA",
        Total: "3242",
      },
      {
        ImportacionId: 243008,
        DOAduanaImpo: "CTG2118702009",
        EsDTA: "0",
        DocTransporte: "HLCULIV210839812",
        RefCliente: "26-2022",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "VARIOS- TRANSPORTADOR TERRESTRE",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: null,
        ATA: "11-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "11-04-2022",
        FechaLevante: "19-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "5,559,866",
        TotalIva: "283000",
        TotalAranceles: "100000",
        TotalTributos: "383000",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "1399.21",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:40:26.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-17T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-11T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "ZONA FRANCA DE LA CANDELARIA",
        Total: "3242",
      },
      {
        ImportacionId: 211509,
        DOAduanaImpo: "BUN2206051001",
        EsDTA: "0",
        DocTransporte: "ANKA2189901",
        RefCliente: "20969",
        Proyecto: null,
        AduanaId: 35,
        Aduana: "BUENAVENTURA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional:
          "FRONTIER AGENCIA MARITIMA - TRANSPORTADOR MARITIMO",
        TipoCarga: null,
        PuertoOrigen: "KAOHSIUNG",
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: "2022-04-12T00:00:00.000Z",
        ATA: "13-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "18-04-2022",
        FechaLevante: "19-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "171,632,834",
        TotalIva: "32610000",
        TotalAranceles: "0",
        TotalTributos: "32610000",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionClienteAA: "2022-03-25T00:00:00.000Z",
        NIT: "860002067",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "43840",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T16:15:26.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-19T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-03-27T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "SOCIEDAD PUERTO INDUSTRIAL AGUA DULCE",
        Total: "3242",
      },
      {
        ImportacionId: 243007,
        DOAduanaImpo: "CTG2204475005",
        EsDTA: "0",
        DocTransporte: "HLCULIV220269872",
        RefCliente: "26-2022",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "VARIOS- TRANSPORTADOR TERRESTRE",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 2,
        Comentarios: null,
        ETA: null,
        ATA: "11-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "08-03-2022",
        FechaLevante: "19-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "2",
        DecIniciales: "2",
        DecAnticipadas: "0",
        DecOrdinarias: "2",
        DecTemporales: "0",
        TotalCIF: "34,331,762",
        TotalIva: "1737000",
        TotalAranceles: "407000",
        TotalTributos: "2144000",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "8567.48",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:39:31.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-17T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-11T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "FISICO",
        NombreDeposito: "ZONA FRANCA DE LA CANDELARIA",
        Total: "3242",
      },
      {
        ImportacionId: 243130,
        DOAduanaImpo: "CTG2206602003",
        EsDTA: "0",
        DocTransporte: "HLCULIV220241761",
        RefCliente: "24-2022",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "VARIOS- TRANSPORTADOR TERRESTRE",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: "2022-04-12T00:00:00.000Z",
        ATA: "12-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "12-04-2022",
        FechaLevante: "19-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "6,072,759",
        TotalIva: "309000",
        TotalAranceles: "109000",
        TotalTributos: "418000",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "1521.19",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:36:35.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-18T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-12T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "ZONA FRANCA DE LA CANDELARIA",
        Total: "3242",
      },
      {
        ImportacionId: 236207,
        DOAduanaImpo: "CTG2206603001",
        EsDTA: "0",
        DocTransporte: "HLCULIV220241889",
        RefCliente: "24-2022",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "VARIOS- TRANSPORTADOR TERRESTRE",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 2,
        Comentarios: null,
        ETA: "2022-04-12T00:00:00.000Z",
        ATA: "12-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "12-04-2022",
        FechaLevante: "19-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 16,
        Estatus: "Radicado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "241,288,680",
        TotalIva: "12282000",
        TotalAranceles: "4343000",
        TotalTributos: "16625000",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: "2022-04-11T00:00:00.000Z",
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "62208.42",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:37:32.000Z",
        FechaDespacho: "2022-04-08T00:00:00.000Z",
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: "2022-04-07T00:00:00.000Z",
        FechaEstimadaLevante: "2022-04-18T00:00:00.000Z",
        FechaFacturacion: "2022-04-11T00:00:00.000Z",
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-04T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "ZONA FRANCA DE LA CANDELARIA",
        Total: "3242",
      },
      {
        ImportacionId: 243009,
        DOAduanaImpo: "CTG2118272010",
        EsDTA: "0",
        DocTransporte: "HLCULIV210790387",
        RefCliente: "26-2022",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "VARIOS- TRANSPORTADOR TERRESTRE",
        TipoCarga: null,
        PuertoOrigen: null,
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: null,
        ATA: "11-04-2022",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "11-04-2022",
        FechaLevante: "19-04-2022",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "9,344,256",
        TotalIva: "476000",
        TotalAranceles: "168000",
        TotalTributos: "644000",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "2371.55",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:40:27.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-17T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-04-11T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito: "ZONA FRANCA DE LA CANDELARIA",
        Total: "3242",
      },
    ],
    totalPage: 325,
  },
  //infoDetailImportacion
  infoDetailImportacion: {
    message: "Customs list",
    status: 200,
    data: {
      infoImport: [
        {
          ImportacionId: 236085,
          DOAduanaImpo: "CTG2206053001",
          EsDTA: "0",
          DocTransporte: "LPL1106062",
          RefCliente: "20976",
          Proyecto: null,
          AduanaId: 48,
          Aduana: "CARTAGENA",
          ModoTransporte: "MARÍTIMO",
          TransportadorInternacional: "FRONTIER AGENCIA MARITIMA",
          TipoCarga: null,
          PuertoOrigen: "GOTHENBURG",
          PuertoDestino: null,
          CantidadItems: 1,
          Comentarios: "FRONTIER AGENCIA MARITIMA",
          ETA: "18-04-2022",
          ATA: "18-04-2022",
          NoAutorizacionDTA: null,
          FechaIngresoDeposito: "2022-04-19T00:00:00.000Z",
          FechaLevante: "2022-04-20T00:00:00.000Z",
          FechaAceptacionSolicitudDTA: null,
          FechaFinalizacionDTA: null,
          EstatusId: 11,
          Estatus: "Levantado",
          TotalDeclaraciones: "1",
          DecIniciales: "1",
          DecAnticipadas: "0",
          DecOrdinarias: "1",
          DecTemporales: "0",
          TotalCIF: "452,047,170",
          TotalIva: "0",
          TotalAranceles: "0",
          TotalTributos: "0",
          ClienteId: 7448,
          Cliente: "CLIENTE 1 SA",
          FechaInstruccionClienteAA: "2022-03-25T00:00:00.000Z",
          NIT: "860002067",
          FechaRadicacionFactura: null,
          FechaSalidaDTA: null,
          FechaAutorizacionInspeccion: null,
          TotalFOB: "116015.08",
          GrupoId: null,
          Grupo: null,
          FechaActualizacion: "21-04-2022",
          FechaDespacho: null,
          FechaEntregaMercancia: null,
          FechaEntregaDocTransportista: null,
          FechaEstimadaLevante: "2022-04-24T00:00:00.000Z",
          FechaFacturacion: null,
          ConFechaLevante: "Si",
          FechaCreacionDO: "2022-03-27T00:00:00.000Z",
          AlertaLevante: 0,
          TipoLevante: "AUTOMATICO",
          NombreDeposito:
            "TNAL C/NEDOR C/GENA SA DEPOSITO PUBLICO-CONTECAR CARTAGENA",
        },
      ],
      DeclaracionesImpo: [
        {
          DeclaracionImpoId: 323376,
          ImportacionId: 236085,
          NoAceptacion: "482022000235138",
          Sticker: "92482201409436",
          NoLevante: "482022000212511",
          FechaLevante: "20-04-2022",
          Subpartida: "3904101000",
          NoFactura: "1484006278",
          Modalidad: "C190",
          Arancel: "0",
          IVA: "0",
          TipoDeclaracion: 1,
          Selectividad: "AUTOMATICO",
          TRMNacionalizacion: "3736.7",
          CIF: "452047170",
          FOB: "116015.08",
          Fletes: "4716.06",
          Seguros: "30.86",
          OtrosGastos: "0",
          Ajustes: "0",
          ValorAduana: "120974.97",
          DescripcionSubpartida: null,
          PesoNeto: "62100",
          PesoBruto: "63840",
          FechaSticker: "2022-04-20T00:00:00.000Z",
          NombreProveedor: "INOVYN TRADE SERVICES SA",
          ATA: "",
        },
      ],
      Remisiones: [],
    },
  },
  //eventImportacion
  eventImportacion: {
    message: "SUCCESS",
    customMessage: "FIND CONTAINER EVENTS",
    data: [
      {
        EventId: 168223,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 3,
        EventDescription: "Fecha de Instrucción Cliente AA",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "1",
        EventDate: "25-03-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 228838,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 4,
        EventDescription: "ETA",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "0",
        EventDate: "18-04-2022",
        CategoryResponsableId: 2,
        CategoryResponsable: "Agente de Carga",
        Origin: "OpenComex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1600408,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 5,
        EventDescription: "ATA (Manifiesto)",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "1",
        EventDate: "18-04-2022",
        CategoryResponsableId: 2,
        CategoryResponsable: "Agente de Carga",
        Origin: "OpenComex",
        CreateDate: "20-04-2022",
      },
      {
        EventId: 1601574,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 7,
        EventDescription: "Fecha Ingreso Depósito",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "1",
        EventDate: "19-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "20-04-2022",
      },
      {
        EventId: 1601832,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 10,
        EventDescription: "Fecha de Aceptación",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "0",
        EventDate: "20-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1601833,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 12,
        EventDescription: "Fecha de Levante",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "1",
        EventDate: "20-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1602119,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 6,
        EventDescription: "Fecha de Liberación Doc. Transporte",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "0",
        EventDate: "21-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1600409,
        RowId: 236085,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 11,
        EventDescription: "Fecha Estimada de Levante (calculado)",
        EventTypeId: 1,
        EventType: "Aduana Impo",
        InTimeLine: "0",
        EventDate: "24-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "20-04-2022",
      },
    ],
    steps: [
      { name: "Confirmed", date: "", completed: false, EventDescriptionId: 31 },
      { name: "Booked", date: "", completed: false, EventDescriptionId: 34 },
      { name: "Departed", date: "", completed: false, EventDescriptionId: 62 },
      { name: "Arrived", date: "", completed: false, EventDescriptionId: 91 },
      {
        name: "Docs Released",
        date: "",
        completed: false,
        EventDescriptionId: 97,
      },
      {
        name: "Customs Released",
        date: "",
        completed: false,
        EventDescriptionId: 98,
      },
    ],
    code: 200,
  },
  //documentosImportacion
  documentosImportacion: {
    message: "SUCCESS",
    customMessage: "Se ha generado el hash correctamente",
    data: {
      url:
        "https://opensmart.syc.com.co/Controles/VisorUContenidos.aspx?hash=4C39D9EF3592E35E1354A80E0A6A7B55E4ABA104FBE537072F2045A2E43B1FCA&aadidxxx=0105&cliidxxx=860002067&do=CTG-2206053-001&us=1111&ed=0",
      data: {
        ImportacionId: 236085,
        DOAduanaImpo: "CTG2206053001",
        EsDTA: "0",
        DocTransporte: "LPL1106062",
        RefCliente: "20976",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: "FRONTIER AGENCIA MARITIMA",
        TipoCarga: null,
        PuertoOrigen: "GOTHENBURG",
        PuertoDestino: null,
        CantidadItems: 1,
        Comentarios: null,
        ETA: "2022-04-18T00:00:00.000Z",
        ATA: "2022-04-18T00:00:00.000Z",
        NoAutorizacionDTA: null,
        FechaIngresoDeposito: "2022-04-19T00:00:00.000Z",
        FechaLevante: "2022-04-20T00:00:00.000Z",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 11,
        Estatus: "Levantado",
        TotalDeclaraciones: "1",
        DecIniciales: "1",
        DecAnticipadas: "0",
        DecOrdinarias: "1",
        DecTemporales: "0",
        TotalCIF: "452047170",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionClienteAA: "2022-03-25T00:00:00.000Z",
        NIT: "860002067",
        FechaRadicacionFactura: null,
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "116015.08",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-21T14:21:14.000Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: "2022-04-24T00:00:00.000Z",
        FechaFacturacion: null,
        ConFechaLevante: "Si",
        FechaCreacionDO: "2022-03-27T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: "AUTOMATICO",
        NombreDeposito:
          "TNAL C/NEDOR C/GENA SA DEPOSITO PUBLICO-CONTECAR CARTAGENA",
      },
      date: "2022-04-21T10:13:53.918Z",
      hash: "11110CTG-2206053-001010586000206721042022",
      date2: 21,
      offset: 0,
    },
    status: 200,
  },
  //dtasCatalogos
  dtasCatalogos: {
    message: "Customs list",
    status: 200,
    data: {
      Aduana: [{ Aduana: "CARTAGENA" }],
      Estatus: [{ Estatus: "Radicado" }],
      TransportadorInternacional: [{ TransportadorInternacional: null }],
      ModoTransporte: [{ ModoTransporte: "MARÍTIMO" }],
      Cliente: [{ Cliente: "CLIENTE 2 SA" }],
      ConFechaLevante: [{ ConFechaLevante: "No" }],
    },
  },
  //listadoDtas
  listadoDtas: {
    message: "Customs list",
    status: 200,
    data: [
      {
        ImportacionId: 231331,
        DOAduanaImpo: "CTG2116890001",
        EsDTA: "1",
        DocTransporte: "",
        RefCliente: "",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: null,
        TipoCarga: null,
        PuertoOrigen: "",
        PuertoDestino: "",
        CantidadItems: null,
        Comentarios: null,
        ETA: null,
        ATA: "",
        NoAutorizacionDTA: "",
        FechaIngresoDeposito: "",
        FechaLevante: "",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 16,
        Estatus: "Radicado",
        TotalDeclaraciones: "0",
        DecIniciales: null,
        DecAnticipadas: null,
        DecOrdinarias: null,
        DecTemporales: null,
        TotalCIF: "0",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: "2021-09-21T00:00:00.000Z",
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "0",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-07T03:06:32.135Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: null,
        FechaFacturacion: "2021-09-20T00:00:00.000Z",
        ConFechaLevante: "No",
        FechaCreacionDO: "2021-08-09T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: null,
        NombreDeposito: null,
        Total: "5",
      },
      {
        ImportacionId: 231327,
        DOAduanaImpo: "CTG2116885001",
        EsDTA: "1",
        DocTransporte: "",
        RefCliente: "",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: null,
        TipoCarga: null,
        PuertoOrigen: "",
        PuertoDestino: "",
        CantidadItems: null,
        Comentarios: null,
        ETA: null,
        ATA: "",
        NoAutorizacionDTA: "",
        FechaIngresoDeposito: "",
        FechaLevante: "",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 16,
        Estatus: "Radicado",
        TotalDeclaraciones: "0",
        DecIniciales: null,
        DecAnticipadas: null,
        DecOrdinarias: null,
        DecTemporales: null,
        TotalCIF: "0",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: "2021-09-03T00:00:00.000Z",
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "0",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-07T03:06:32.135Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: null,
        FechaFacturacion: "2021-09-03T00:00:00.000Z",
        ConFechaLevante: "No",
        FechaCreacionDO: "2021-08-09T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: null,
        NombreDeposito: null,
        Total: "5",
      },
      {
        ImportacionId: 231329,
        DOAduanaImpo: "CTG2116888001",
        EsDTA: "1",
        DocTransporte: "",
        RefCliente: "",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: null,
        TipoCarga: null,
        PuertoOrigen: "",
        PuertoDestino: "",
        CantidadItems: null,
        Comentarios: null,
        ETA: null,
        ATA: "",
        NoAutorizacionDTA: "",
        FechaIngresoDeposito: "",
        FechaLevante: "",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 16,
        Estatus: "Radicado",
        TotalDeclaraciones: "0",
        DecIniciales: null,
        DecAnticipadas: null,
        DecOrdinarias: null,
        DecTemporales: null,
        TotalCIF: "0",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: "2021-09-03T00:00:00.000Z",
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "0",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-07T03:06:32.135Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: null,
        FechaFacturacion: "2021-09-03T00:00:00.000Z",
        ConFechaLevante: "No",
        FechaCreacionDO: "2021-08-09T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: null,
        NombreDeposito: null,
        Total: "5",
      },
      {
        ImportacionId: 231328,
        DOAduanaImpo: "CTG2116886001",
        EsDTA: "1",
        DocTransporte: "",
        RefCliente: "",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: null,
        TipoCarga: null,
        PuertoOrigen: "",
        PuertoDestino: "",
        CantidadItems: null,
        Comentarios: null,
        ETA: null,
        ATA: "",
        NoAutorizacionDTA: "",
        FechaIngresoDeposito: "",
        FechaLevante: "",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 16,
        Estatus: "Radicado",
        TotalDeclaraciones: "0",
        DecIniciales: null,
        DecAnticipadas: null,
        DecOrdinarias: null,
        DecTemporales: null,
        TotalCIF: "0",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: "2021-09-03T00:00:00.000Z",
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "0",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-07T03:06:32.135Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: null,
        FechaFacturacion: "2021-09-03T00:00:00.000Z",
        ConFechaLevante: "No",
        FechaCreacionDO: "2021-08-09T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: null,
        NombreDeposito: null,
        Total: "5",
      },
      {
        ImportacionId: 231330,
        DOAduanaImpo: "CTG2116889001",
        EsDTA: "1",
        DocTransporte: "HLCULIV210582240",
        RefCliente: "DTA 4 - VEHICULO 4",
        Proyecto: null,
        AduanaId: 48,
        Aduana: "CARTAGENA",
        ModoTransporte: "MARÍTIMO",
        TransportadorInternacional: null,
        TipoCarga: null,
        PuertoOrigen: "",
        PuertoDestino: "",
        CantidadItems: null,
        Comentarios: null,
        ETA: null,
        ATA: "",
        NoAutorizacionDTA: "",
        FechaIngresoDeposito: "",
        FechaLevante: "",
        FechaAceptacionSolicitudDTA: null,
        FechaFinalizacionDTA: null,
        EstatusId: 16,
        Estatus: "Radicado",
        TotalDeclaraciones: "0",
        DecIniciales: null,
        DecAnticipadas: null,
        DecOrdinarias: null,
        DecTemporales: null,
        TotalCIF: "0",
        TotalIva: "0",
        TotalAranceles: "0",
        TotalTributos: "0",
        ClienteId: 7554,
        Cliente: "CLIENTE 2 SA",
        FechaInstruccionClienteAA: null,
        NIT: "830031849",
        FechaRadicacionFactura: "2021-09-21T00:00:00.000Z",
        FechaSalidaDTA: null,
        FechaAutorizacionInspeccion: null,
        TotalFOB: "0",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-07T03:06:32.135Z",
        FechaDespacho: null,
        FechaEntregaMercancia: null,
        FechaEntregaDocTransportista: null,
        FechaEstimadaLevante: null,
        FechaFacturacion: "2021-09-20T00:00:00.000Z",
        ConFechaLevante: "No",
        FechaCreacionDO: "2021-08-09T00:00:00.000Z",
        AlertaLevante: 0,
        TipoLevante: null,
        NombreDeposito: null,
        Total: "5",
      },
    ],
    totalPage: 1,
  },
  //eventosDtas
  eventosDtas: {
    message: "SUCCESS",
    customMessage: "FIND CONTAINER EVENTS",
    data: [
      {
        EventId: 813112,
        RowId: 231331,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 15,
        EventDescription: "Fecha de Facturación",
        EventTypeId: 2,
        EventType: "Aduana DTA",
        InTimeLine: "0",
        EventDate: "20-09-2021",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 887310,
        RowId: 231331,
        ModuleId: 1,
        Module: "Importaciones",
        EventDescriptionId: 16,
        EventDescription: "Fecha de Radicación de Factura",
        EventTypeId: 2,
        EventType: "Aduana DTA",
        InTimeLine: "1",
        EventDate: "21-09-2021",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "07-04-2022",
      },
    ],
    steps: [
      { name: "Confirmed", date: "", completed: false, EventDescriptionId: 31 },
      { name: "Booked", date: "", completed: false, EventDescriptionId: 34 },
      { name: "Departed", date: "", completed: false, EventDescriptionId: 62 },
      { name: "Arrived", date: "", completed: false, EventDescriptionId: 91 },
      {
        name: "Docs Released",
        date: "",
        completed: false,
        EventDescriptionId: 97,
      },
      {
        name: "Customs Released",
        date: "",
        completed: false,
        EventDescriptionId: 98,
      },
    ],
    code: 200,
  },
  //infoDetailDta
  infoDetailDtas: {
    message: "Customs list",
    status: 200,
    data: {
      infoImport: [
        {
          ImportacionId: 231331,
          DOAduanaImpo: "CTG2116890001",
          EsDTA: "1",
          DocTransporte: "",
          RefCliente: "890001",
          Proyecto: null,
          AduanaId: 48,
          Aduana: "CARTAGENA",
          ModoTransporte: "MARÍTIMO",
          TransportadorInternacional: 10,
          TipoCarga: 10,
          PuertoOrigen: "",
          PuertoDestino: "",
          CantidadItems: 10,
          Comentarios: "CLIENTE 2 SA",
          ETA: "2021-09-21",
          ATA: "",
          NoAutorizacionDTA: "",
          FechaIngresoDeposito: null,
          FechaLevante: null,
          FechaAceptacionSolicitudDTA: null,
          FechaFinalizacionDTA: null,
          EstatusId: 16,
          Estatus: "Radicado",
          TotalDeclaraciones: "0",
          DecIniciales: 0,
          DecAnticipadas: 0,
          DecOrdinarias: 0,
          DecTemporales: 0,
          TotalCIF: "0",
          TotalIva: "0",
          TotalAranceles: "0",
          TotalTributos: "0",
          ClienteId: 7554,
          Cliente: "CLIENTE 2 SA",
          FechaInstruccionClienteAA: 0,
          NIT: "830031849",
          FechaRadicacionFactura: "2021-09-21T00:00:00.000Z",
          FechaSalidaDTA: 0,
          FechaAutorizacionInspeccion: 0,
          TotalFOB: 0,
          GrupoId: 0,
          Grupo: 0,
          FechaActualizacion: "07-04-2022",
          FechaDespacho: 10,
          FechaEntregaMercancia: 10,
          FechaEntregaDocTransportista: 10,
          FechaEstimadaLevante: 10,
          FechaFacturacion: "2021-09-20T00:00:00.000Z",
          ConFechaLevante: "No",
          FechaCreacionDO: "2021-08-09T00:00:00.000Z",
          AlertaLevante: 0,
          TipoLevante: 10,
          NombreDeposito: 10,
        },
      ],
      DeclaracionesImpo: [],
      Remisiones: [],
    },
  },
  // exportCalogs
  exportCalogs: {
    message: "Customs list",
    status: 200,
    data: {
      Aduana: [
        { Aduana: "MAICAO" },
        { Aduana: "BUENAVENTURA" },
        { Aduana: "IPIALES" },
        { Aduana: "CARTAGENA" },
        { Aduana: "BOGOTA" },
        { Aduana: "BARRANQUILLA" },
      ],
      Estatus: [
        { Estatus: "Radicado" },
        { Estatus: "Instruccionado" },
        { Estatus: "Cierre Fisico" },
        { Estatus: "Manifestado" },
      ],
      TransportadorInt: [
        { TransportadorInt: "NAVES " },
        { TransportadorInt: "MAERSK" },
        { TransportadorInt: "AEROSUCRE" },
        { TransportadorInt: "AIR CANADA" },
        { TransportadorInt: "AIR FRANCE" },
        { TransportadorInt: "UPS" },
        { TransportadorInt: "L.A.S." },
        { TransportadorInt: "AVIANCA" },
        { TransportadorInt: "MEDITERRANEAN" },
        { TransportadorInt: "CARIBE" },
        { TransportadorInt: "LATAM" },
        { TransportadorInt: "GERLEINCO " },
        { TransportadorInt: "SOTRASUR" },
        { TransportadorInt: "COPA" },
        { TransportadorInt: "TRANSCARGA VENEZUELA" },
        { TransportadorInt: "FRONTIER S.A.S." },
        { TransportadorInt: "TRANSPORTE DE CARGA Y ENCOMIENDAS TC & E C.A." },
        { TransportadorInt: "NAVEMAR" },
        { TransportadorInt: "CARIBBEAN " },
        { TransportadorInt: "LUFTHANSA" },
        { TransportadorInt: "" },
        { TransportadorInt: "AGENCIA MARITIMA INTERNACIONAL" },
        { TransportadorInt: "DHL AERO" },
        { TransportadorInt: "TRANSPORTISTAS UNIDOS ECUATORIANOS C.A TRUECA" },
        { TransportadorInt: "TAMPA" },
        { TransportadorInt: "AGUNSA" },
        { TransportadorInt: "OCEANICA" },
        { TransportadorInt: "HAPAG LLOYD " },
        { TransportadorInt: "AEROMEXICO" },
        { TransportadorInt: "SEABOARD" },
        {
          TransportadorInt:
            "CONALCA S A S,TRANSPORTISTAS UNIDOS ECUATORIANOS C.A TRUECA",
        },
        { TransportadorInt: "KLM" },
        { TransportadorInt: "NAVENAL" },
        { TransportadorInt: "COMPAÑÍA TRANSPORTADORA" },
        { TransportadorInt: "IBERIA" },
      ],
      Cliente: [
        { Cliente: "CLIENTE 2 SA" },
        { Cliente: "CLIENTE 1 SA" },
      ],
    },
  },
  //listExport
  listExport: {
    message: "Customs list",
    status: 200,
    data: [
      {
        ExportacionId: 9142,
        DOAduanaExpo: "CTG2206511001",
        DocTransporte: "CTGPEV19355",
        ModoTransporte: "MARITIMO",
        RefCliente: "EX 47939-47948",
        AduanaId: 48,
        Aduana: "CARTAGENA",
        Proyecto: "",
        NoPedido: "EX 47939-47948",
        TransportadorInt: "CARIBBEAN ",
        FechaAutorizaEmbarque: "06-04-2022",
        ETD: "09-04-2022",
        Consignatario: null,
        PaisDestino: "US",
        Incoterm: null,
        AgenteCarga: "Agente",
        EstatusId: 40,
        Estatus: "Radicado",
        FechaDEX: "21-04-2022",
        TotalDeclaraciones: "26",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-01T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: "2022-04-13T00:00:00.000Z",
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-21T17:31:09.000Z",
        FechaCierreFisico: "2022-04-08T00:00:00.000Z",
        FechaManifiestoCarga: "09-04-2022",
        FechaFacturacion: "2022-04-13T00:00:00.000Z",
        FechaAperturaDO: "2022-04-01T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 5943,
        DOAduanaExpo: "BUN2206826001",
        DocTransporte: "COL2204323",
        ModoTransporte: "MARITIMO",
        RefCliente: "EX 47978",
        AduanaId: 35,
        Aduana: "BUENAVENTURA",
        Proyecto: "",
        NoPedido: "EX 47978",
        TransportadorInt: "NAVEMAR",
        FechaAutorizaEmbarque: "07-04-2022",
        ETD: "20-04-2022",
        Consignatario: null,
        PaisDestino: "PE",
        Incoterm: null,
        AgenteCarga: "msl",
        EstatusId: 38,
        Estatus: "Manifestado",
        FechaDEX: "20-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-06T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: null,
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T17:13:41.000Z",
        FechaCierreFisico: "2022-04-08T00:00:00.000Z",
        FechaManifiestoCarga: "18-04-2022",
        FechaFacturacion: null,
        FechaAperturaDO: "2022-04-06T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 14977,
        DOAduanaExpo: "CTG2207174001",
        DocTransporte: "KOSLCTGPEV19427",
        ModoTransporte: "MARITIMO",
        RefCliente: "EX 47987-47991-EX 47992-47995-EX 47996-48003",
        AduanaId: 48,
        Aduana: "CARTAGENA",
        Proyecto: "",
        NoPedido: "EX 47987-47991-EX 47992-47995-EX 47996-48003",
        TransportadorInt: "CARIBBEAN ",
        FechaAutorizaEmbarque: "13-04-2022",
        ETD: "16-04-2022",
        Consignatario: null,
        PaisDestino: "US",
        Incoterm: null,
        AgenteCarga: "Agente",
        EstatusId: 38,
        Estatus: "Manifestado",
        FechaDEX: "20-04-2022",
        TotalDeclaraciones: "22",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-11T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: null,
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T18:09:40.000Z",
        FechaCierreFisico: "2022-04-15T00:00:00.000Z",
        FechaManifiestoCarga: "18-04-2022",
        FechaFacturacion: null,
        FechaAperturaDO: "2022-04-12T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 15030,
        DOAduanaExpo: "CTG2207088001",
        DocTransporte: "BOGA04258",
        ModoTransporte: "MARITIMO",
        RefCliente: "EX 47986",
        AduanaId: 48,
        Aduana: "CARTAGENA",
        Proyecto: "",
        NoPedido: "EX 47986",
        TransportadorInt: "FRONTIER S.A.S.",
        FechaAutorizaEmbarque: "11-04-2022",
        ETD: "17-04-2022",
        Consignatario: null,
        PaisDestino: "ES",
        Incoterm: null,
        AgenteCarga: "DHL",
        EstatusId: 38,
        Estatus: "Manifestado",
        FechaDEX: "20-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-11T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: null,
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T17:24:00.000Z",
        FechaCierreFisico: "2022-04-16T00:00:00.000Z",
        FechaManifiestoCarga: "18-04-2022",
        FechaFacturacion: null,
        FechaAperturaDO: "2022-04-11T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 15019,
        DOAduanaExpo: "IPI2207018001",
        DocTransporte: "CO02623-22",
        ModoTransporte: "CARRETERO",
        RefCliente: "EX 47983",
        AduanaId: 37,
        Aduana: "IPIALES",
        Proyecto: "",
        NoPedido: "EX 47983",
        TransportadorInt: "TRANSPORTISTAS UNIDOS ECUATORIANOS C.A TRUECA",
        FechaAutorizaEmbarque: "12-04-2022",
        ETD: "12-04-2022",
        Consignatario: null,
        PaisDestino: "EC",
        Incoterm: null,
        AgenteCarga: "",
        EstatusId: 38,
        Estatus: "Manifestado",
        FechaDEX: "18-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-08T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: null,
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T16:54:11.000Z",
        FechaCierreFisico: null,
        FechaManifiestoCarga: "12-04-2022",
        FechaFacturacion: null,
        FechaAperturaDO: "2022-04-08T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 4430,
        DOAduanaExpo: "BOG2206478001",
        DocTransporte: "057-06272615",
        ModoTransporte: "AEREO",
        RefCliente: "EX 47951",
        AduanaId: 3,
        Aduana: "BOGOTA",
        Proyecto: "",
        NoPedido: "EX 47951",
        TransportadorInt: "AIR FRANCE",
        FechaAutorizaEmbarque: "01-04-2022",
        ETD: "12-04-2022",
        Consignatario: null,
        PaisDestino: "GB",
        Incoterm: null,
        AgenteCarga: "cargo compas",
        EstatusId: 40,
        Estatus: "Radicado",
        FechaDEX: "18-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-01T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: "2022-04-05T00:00:00.000Z",
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T17:18:40.000Z",
        FechaCierreFisico: null,
        FechaManifiestoCarga: "12-04-2022",
        FechaFacturacion: "2022-04-05T00:00:00.000Z",
        FechaAperturaDO: "2022-04-01T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 15026,
        DOAduanaExpo: "CTG2206995001",
        DocTransporte: "BOGA04257",
        ModoTransporte: "MARITIMO",
        RefCliente: " EX 47984",
        AduanaId: 48,
        Aduana: "CARTAGENA",
        Proyecto: "",
        NoPedido: " EX 47984",
        TransportadorInt: "OCEANICA",
        FechaAutorizaEmbarque: "11-04-2022",
        ETD: "14-04-2022",
        Consignatario: null,
        PaisDestino: "DE",
        Incoterm: null,
        AgenteCarga: "dhl",
        EstatusId: 40,
        Estatus: "Radicado",
        FechaDEX: "18-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-08T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: "2022-04-20T00:00:00.000Z",
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T13:29:22.000Z",
        FechaCierreFisico: "2022-04-13T00:00:00.000Z",
        FechaManifiestoCarga: "16-04-2022",
        FechaFacturacion: "2022-04-20T00:00:00.000Z",
        FechaAperturaDO: "2022-04-08T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 9155,
        DOAduanaExpo: "CTG2206821001",
        DocTransporte: "SUDU22051AZ7RBBF",
        ModoTransporte: "MARITIMO",
        RefCliente: "EX 47977",
        AduanaId: 48,
        Aduana: "CARTAGENA",
        Proyecto: "",
        NoPedido: "EX 47977",
        TransportadorInt: "GERLEINCO ",
        FechaAutorizaEmbarque: "08-04-2022",
        ETD: "13-04-2022",
        Consignatario: null,
        PaisDestino: "MX",
        Incoterm: null,
        AgenteCarga: "CLIENTE 1 SAS",
        EstatusId: 40,
        Estatus: "Radicado",
        FechaDEX: "18-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-06T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: "2022-04-20T00:00:00.000Z",
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:16:51.000Z",
        FechaCierreFisico: "2022-04-11T00:00:00.000Z",
        FechaManifiestoCarga: "09-04-2022",
        FechaFacturacion: "2022-04-20T00:00:00.000Z",
        FechaAperturaDO: "2022-04-06T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 9156,
        DOAduanaExpo: "CTG2206830001",
        DocTransporte: "CTG-036-22",
        ModoTransporte: "MARITIMO",
        RefCliente: "EX 47979",
        AduanaId: 48,
        Aduana: "CARTAGENA",
        Proyecto: "",
        NoPedido: "EX 47979",
        TransportadorInt: "AGUNSA",
        FechaAutorizaEmbarque: "08-04-2022",
        ETD: "12-04-2022",
        Consignatario: null,
        PaisDestino: "CR",
        Incoterm: null,
        AgenteCarga: "TMA CARGO",
        EstatusId: 40,
        Estatus: "Radicado",
        FechaDEX: "18-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-06T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: "2022-04-20T00:00:00.000Z",
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-19T21:34:53.000Z",
        FechaCierreFisico: "2022-04-10T00:00:00.000Z",
        FechaManifiestoCarga: "13-04-2022",
        FechaFacturacion: "2022-04-20T00:00:00.000Z",
        FechaAperturaDO: "2022-04-06T00:00:00.000Z",
        Total: "956",
      },
      {
        ExportacionId: 15024,
        DOAduanaExpo: "BOG2207161001",
        DocTransporte: "729-43092582",
        ModoTransporte: "AEREO",
        RefCliente: "EX 48804 ",
        AduanaId: 3,
        Aduana: "BOGOTA",
        Proyecto: "",
        NoPedido: "EX 48804 ",
        TransportadorInt: "AVIANCA",
        FechaAutorizaEmbarque: "11-04-2022",
        ETD: "15-04-2022",
        Consignatario: null,
        PaisDestino: "ES",
        Incoterm: null,
        AgenteCarga: "ZIX",
        EstatusId: 38,
        Estatus: "Manifestado",
        FechaDEX: "18-04-2022",
        TotalDeclaraciones: "1",
        ClienteId: 7448,
        Cliente: "CLIENTE 1 SA",
        FechaInstruccionCliente: "2022-04-11T00:00:00.000Z",
        NIT: "860002067",
        FechaEntregaDocumentos: null,
        Observaciones: "",
        GrupoId: null,
        Grupo: null,
        FechaActualizacion: "2022-04-20T13:23:02.000Z",
        FechaCierreFisico: null,
        FechaManifiestoCarga: "15-04-2022",
        FechaFacturacion: null,
        FechaAperturaDO: "2022-04-11T00:00:00.000Z",
        Total: "956",
      },
    ],
    totalPage: 96,
  },
  //infoDetailExport
  infoDetailExport: {
    message: "Customs list",
    status: 200,
    data: {
      infoExport: [
        {
          ExportacionId: 9142,
          DOAduanaExpo: "CTG2206511001",
          DocTransporte: "CTGPEV19355",
          ModoTransporte: "MARITIMO",
          RefCliente: "EX 47939-47948",
          AduanaId: 48,
          Aduana: "CARTAGENA",
          Proyecto: "",
          NoPedido: "EX 47939-47948",
          TransportadorInt: "CARIBBEAN ",
          FechaAutorizaEmbarque: "2022-04-06T00:00:00.000Z",
          ETD: "2022-04-09",
          Consignatario: null,
          PaisDestino: "US",
          Incoterm: null,
          AgenteCarga: "Agente",
          EstatusId: 40,
          Estatus: "Radicado",
          FechaDEX: "21-04-2022",
          TotalDeclaraciones: "26",
          ClienteId: 7448,
          Cliente: "CLIENTE 1 SA",
          FechaInstruccionCliente: "01-04-2022",
          NIT: "860002067",
          FechaEntregaDocumentos: "13-04-2022",
          Observaciones: "Agente",
          GrupoId: null,
          Grupo: null,
          FechaActualizacion: "21-04-2022",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: "2022-04-09T00:00:00.000Z",
          FechaFacturacion: "2022-04-13T00:00:00.000Z",
          FechaAperturaDO: "2022-04-01T00:00:00.000Z",
        },
      ],
      DeclaracionesExpo: [
        {
          DeclaracionExpoId: 13704,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47943",
          NoSAE: "6027692500417",
          FechaSAE: "01-04-2022",
          NoAE: "14169161584033",
          NoDEX: "6007694282758",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "SPRADLING INTERNATIONAL. INC.- SIR",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13707,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47946",
          NoSAE: "6027692500470",
          FechaSAE: "01-04-2022",
          NoAE: "14169161583952",
          NoDEX: "6007694282781",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13715,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47966",
          NoSAE: "6027692501160",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647279",
          NoDEX: "6007694282876",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 5",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13725,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47976",
          NoSAE: "6027692501311",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674095",
          NoDEX: "6007694282962",
          FechaDEX: "21-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13702,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47941",
          NoSAE: "6027692500384",
          FechaSAE: "01-04-2022",
          NoAE: "14169161584001",
          NoDEX: "6007694282733",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 4",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13700,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47939",
          NoSAE: "6027692500352",
          FechaSAE: "01-04-2022",
          NoAE: "14169161584026",
          NoDEX: "6007694282719",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: "2022-04-09T00:00:00.000Z",
          Consignatario: "COMPAÑIA ABC 5",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13701,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47940",
          NoSAE: "6027692500377",
          FechaSAE: "01-04-2022",
          NoAE: "14169161583991",
          NoDEX: "6007694282726",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13703,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47942",
          NoSAE: "6027692500391",
          FechaSAE: "01-04-2022",
          NoAE: "14169161583945",
          NoDEX: "6007694282740",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 5",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13705,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47944",
          NoSAE: "6027692500431",
          FechaSAE: "01-04-2022",
          NoAE: "14169161584019",
          NoDEX: "6007694282765",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 5",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13706,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47945",
          NoSAE: "6027692500456",
          FechaSAE: "01-04-2022",
          NoAE: "14169161583984",
          NoDEX: "6007694282772",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13708,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47947",
          NoSAE: "6027692500495",
          FechaSAE: "01-04-2022",
          NoAE: "14169161583961",
          NoDEX: "6007694282797",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 2",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13709,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47947",
          NoSAE: "6027692500528",
          FechaSAE: "01-04-2022",
          NoAE: "14169161584058",
          NoDEX: "6007694824461",
          FechaDEX: "21-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-05T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 3",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-05T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13710,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47961",
          NoSAE: "6027692501081",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647208",
          NoDEX: "6007694282812",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13711,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47962",
          NoSAE: "6027692501099",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647231",
          NoDEX: "6007694282821",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13712,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47963",
          NoSAE: "6027692501107",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647254",
          NoDEX: "6007694282837",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 3",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13713,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47964",
          NoSAE: "6027692501114",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647261",
          NoDEX: "6007694282844",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 4",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13714,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47965",
          NoSAE: "6027692501121",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647215",
          NoDEX: "6007694282851",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "SPRADLING INTERNATIONAL. INC.-WEST",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13716,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47967",
          NoSAE: "6027692501178",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647191",
          NoDEX: "6007694282890",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13717,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47968",
          NoSAE: "6027692501185",
          FechaSAE: "05-04-2022",
          NoAE: "14169161647247",
          NoDEX: "6007694282916",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 2",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13718,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47969",
          NoSAE: "6027692501200",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674103",
          NoDEX: "6007694282930",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 5",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13719,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47970",
          NoSAE: "6027692501257",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674056",
          NoDEX: "6007694282955",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 2",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13720,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47971",
          NoSAE: "6027692501264",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674031",
          NoDEX: "6007694282869",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 3",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13721,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47972",
          NoSAE: "6027692501271",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674070",
          NoDEX: "6007694282883",
          FechaDEX: "21-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 5",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13722,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47973",
          NoSAE: "6027692501289",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674063",
          NoDEX: "6007694282909",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13723,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47974",
          NoSAE: "6027692501296",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674024",
          NoDEX: "6007694282923",
          FechaDEX: "11-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
        {
          DeclaracionExpoId: 13724,
          ExportacionId: 9142,
          NoFacturaComercial: "EX-47975",
          NoSAE: "6027692501304",
          FechaSAE: "05-04-2022",
          NoAE: "14169161674049",
          NoDEX: "6007694282948",
          FechaDEX: "21-04-2022",
          Regimen: "Exportación definitiva",
          FechaLlegadaZonaPrimaria: null,
          FechaAE: "2022-04-06T00:00:00.000Z",
          FechaCierreDocumental: "2022-04-06T00:00:00.000Z",
          FechaInspeccionAntinarcoticos: "2022-04-09T00:00:00.000Z",
          FechaCierreFisico: "2022-04-08T00:00:00.000Z",
          FechaManifiestoCarga: null,
          Consignatario: "COMPAÑIA ABC 1",
          Incoterm: "CFR",
          TipoSelectiviad: "DIRECTO",
          FechaSelectividad: "2022-04-06T00:00:00.000Z",
          FechaInspeccionDian: null,
          Rechazo: "0",
          EstadoDeclaracion: "ACTIVO",
          CodigoTipoDatos: "01",
          NombreTipoDatos: "Definitivos al embarque",
          ExpZonaFranca: "NO",
        },
      ],
      Certificados: [],
    },
  },
  //eventosExport
  eventosExport: {
    message: "SUCCESS",
    customMessage: "FIND CONTAINER EVENTS",
    data: [
      {
        EventId: 1011236,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 2,
        EventDescription: "Fecha de Instrucción Cliente AA (Reserva)",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "1",
        EventDate: "01-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 996301,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 1,
        EventDescription: "Fecha apertura de file DO",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "01-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1025831,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 3,
        EventDescription: "Fecha de SAE",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "05-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1155652,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 20,
        EventDescription: "Fecha selectividad",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "06-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1050631,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 9,
        EventDescription: "Fecha de cierre documental",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "06-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1602568,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 8,
        EventDescription: "Fecha de autorización de Embarque",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "1",
        EventDate: "06-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1066608,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 13,
        EventDescription: "Fecha de cierre físico",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "1",
        EventDate: "08-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1602698,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 16,
        EventDescription: "Fecha de manifiesto de carga",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "1",
        EventDate: "09-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1074134,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 14,
        EventDescription: "Fecha de Zarpe (ETD)",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "09-04-2022",
        CategoryResponsableId: 2,
        CategoryResponsable: "Agente de Carga",
        Origin: "Tramitex",
        CreateDate: "07-04-2022",
      },
      {
        EventId: 1602566,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 10,
        EventDescription: "Fecha de inspección antinarcóticos",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "09-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1602697,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 15,
        EventDescription: "Fecha de zarpe (ATD)",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "09-04-2022",
        CategoryResponsableId: 2,
        CategoryResponsable: "Agente de Carga",
        Origin: "Tramitex",
        CreateDate: "21-04-2022",
      },
      {
        EventId: 1595310,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 18,
        EventDescription: "Fecha de facturación",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "1",
        EventDate: "13-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "13-04-2022",
      },
      {
        EventId: 1595311,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 19,
        EventDescription: "Fecha de radicación factura",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "1",
        EventDate: "13-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "OpenComex",
        CreateDate: "13-04-2022",
      },
      {
        EventId: 1602569,
        RowId: 9142,
        ModuleId: 2,
        Module: "Exportaciones",
        EventDescriptionId: 17,
        EventDescription: "Fecha de DEX",
        EventTypeId: 3,
        EventType: "Aduana EXPO",
        InTimeLine: "0",
        EventDate: "21-04-2022",
        CategoryResponsableId: 1,
        CategoryResponsable: "Agente Aduanal",
        Origin: "Tramitex",
        CreateDate: "21-04-2022",
      },
    ],
    steps: [
      { name: "Confirmed", date: "", completed: false, EventDescriptionId: 31 },
      { name: "Booked", date: "", completed: false, EventDescriptionId: 34 },
      { name: "Departed", date: "", completed: false, EventDescriptionId: 62 },
      { name: "Arrived", date: "", completed: false, EventDescriptionId: 91 },
      {
        name: "Docs Released",
        date: "",
        completed: false,
        EventDescriptionId: 97,
      },
      {
        name: "Customs Released",
        date: "",
        completed: false,
        EventDescriptionId: 98,
      },
    ],
    code: 200,
  },
  //documentosExpor
  documentosExpor: {
    message: "Customs list",
    status: 200,
    data: {
      recordsets: [
        [
          {
            Fecha: "01-04-2022",
            Descripcion: "10864955",
            Nombre: "4048951",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048951",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "CTGPEV19355",
            Nombre: "4048953",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048953",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500352",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963747",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500377",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963748",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500384",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963749",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500391",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963750",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500417",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963751",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500431",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963752",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500456",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963753",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500470",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963754",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500495",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963755",
          },
          {
            Fecha: "01-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692500528",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3963756",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501081",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979075",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501099",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979077",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501107",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979078",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501114",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979079",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501121",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979080",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501160",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979081",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501178",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979082",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501185",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979083",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501200",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979405",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501257",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979406",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501264",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979407",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501271",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979408",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501289",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979409",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501296",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979410",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501304",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979411",
          },
          {
            Fecha: "05-04-2022",
            Descripcion: "SAE",
            Nombre: "6027692501311",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=3979412",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282719",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049142",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282726",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049101",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282733",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048978",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282740",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049139",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282758",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049123",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282765",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049132",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282772",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049082",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282781",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049090",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282797",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049120",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282812",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049006",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282821",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049011",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282837",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049092",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282844",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049003",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282851",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049037",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282876",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049023",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282890",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049015",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282916",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049020",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282930",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049033",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282955",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049017",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282869",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049096",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282909",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048992",
          },
          {
            Fecha: "11-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282923",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048995",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "11627677873324",
            Nombre: "4048954",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048954",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "11627677873689",
            Nombre: "4048958",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048958",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "11627677873696",
            Nombre: "4048962",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048962",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "FACT EX47939-47948",
            Nombre: "4048957",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048957",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "FACT EX47961-47968",
            Nombre: "4048960",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048960",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "FACT EX47969-47976",
            Nombre: "4048964",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048964",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "gastos",
            Nombre: "4048972",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048972",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282883",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049026",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282948",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048998",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694282962",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4048991",
          },
          {
            Fecha: "21-04-2022",
            Descripcion: "DEX",
            Nombre: "6007694824461",
            ext: "pdf",
            URL: "https://d.rocas.co/d?i=4049051",
          },
        ],
      ],
      recordset: [
        {
          Fecha: "01-04-2022",
          Descripcion: "10864955",
          Nombre: "4048951",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048951",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "CTGPEV19355",
          Nombre: "4048953",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048953",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500352",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963747",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500377",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963748",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500384",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963749",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500391",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963750",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500417",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963751",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500431",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963752",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500456",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963753",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500470",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963754",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500495",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963755",
        },
        {
          Fecha: "01-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692500528",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3963756",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501081",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979075",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501099",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979077",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501107",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979078",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501114",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979079",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501121",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979080",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501160",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979081",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501178",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979082",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501185",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979083",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501200",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979405",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501257",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979406",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501264",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979407",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501271",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979408",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501289",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979409",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501296",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979410",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501304",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979411",
        },
        {
          Fecha: "05-04-2022",
          Descripcion: "SAE",
          Nombre: "6027692501311",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=3979412",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282719",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049142",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282726",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049101",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282733",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048978",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282740",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049139",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282758",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049123",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282765",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049132",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282772",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049082",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282781",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049090",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282797",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049120",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282812",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049006",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282821",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049011",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282837",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049092",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282844",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049003",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282851",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049037",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282876",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049023",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282890",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049015",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282916",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049020",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282930",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049033",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282955",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049017",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282869",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049096",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282909",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048992",
        },
        {
          Fecha: "11-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282923",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048995",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "11627677873324",
          Nombre: "4048954",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048954",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "11627677873689",
          Nombre: "4048958",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048958",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "11627677873696",
          Nombre: "4048962",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048962",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "FACT EX47939-47948",
          Nombre: "4048957",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048957",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "FACT EX47961-47968",
          Nombre: "4048960",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048960",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "FACT EX47969-47976",
          Nombre: "4048964",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048964",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "gastos",
          Nombre: "4048972",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048972",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282883",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049026",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282948",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048998",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694282962",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4048991",
        },
        {
          Fecha: "21-04-2022",
          Descripcion: "DEX",
          Nombre: "6007694824461",
          ext: "pdf",
          URL: "https://d.rocas.co/d?i=4049051",
        },
      ],
      output: {},
      rowsAffected: [1, 61],
    },
  },
};

export default dummy;
