const bookings =
[
  {
    BookingNo: 'BO000001',
    Supplier: 'Vicostone',
    Consignee: 'Pental',
    Delivery: 'Pental',
    Carrier: 'MSC',
    Vessel: 'MSC MELINE',
    Voyage: 6789,
    FreightForwarder: 'Interglobo',
    FreightForwarderReference: 7645678,
    Origin: 'VNHPH',
    Destination: 'USBOS',
    DocumentCutOff: '2019-10-04',
    CargoCutOff: '2019-10-04',
    ETD: '2019-10-08',
    ETA: '2019-10-23',
    Notifications: [
      {
        Date: '2019-10-08',
        Notifier: 'Me',
        Comment: 'My Comment',
        Category: 'Category',
        NotifiedTo: 'Me'
      }
    ],
    Containers: [
      {
        BookingNo: 'BO000001',
        CountOfContainer: 3,
        ContainerType: '40 Dry Cargo',
        Commodity: 'Ceramic Tiles',
        ContainerGrade: 'A',
        EmptyPickUpLocation: 'At Port'
      },
      {
        BookingNo: 'BO000001',
        CountOfContainer: 2,
        ContainerType: '20 Dry Cargo',
        Commodity: 'Baths Accessories',
        ContainerGrade: 'A',
        EmptyPickUpLocation: 'At Port'
      }
    ],
    routing: [
      {
        BookingNo: 'BO000001',
        ConsoleNo: 21345,
        TransportMod: 'FCL',
        LegType: 'Transshipment 1',
        Sequence: 1,
        Vessel: 'VESSEL FEEDER',
        Voyage: 5843
      },
      {
        BookingNo: 'BO000001',
        ConsoleNo: 21345,
        TransportMod: 'FCL',
        LegType: 'Main Leg',
        Sequence: 2,
        Vessel: 'MSC MELINE',
        Voyage: 6789
      }
    ],
    PurchaseOrderList: [
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO400456',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 100,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO500634',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 350,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO401543',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 275,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO400456',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 100,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO500634',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 350,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO401543',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 275,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO400456',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 100,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO500634',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 350,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO401543',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 275,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO400456',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 100,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO500634',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 350,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO401543',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 275,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO400456',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 100,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO500634',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 350,
      },
      {
        BookingNo: 'BO000001',
        PoNumber: 'PO401543',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 275,
      }
    ]
  },
  {
    BookingNo: 'BO000002',
    Supplier: 'Vicostone',
    Consignee: 'Pental',
    Delivery: 'Pental',
    Carrier: 'MSC',
    Vessel: 'MSC JOY',
    Voyage: 7409,
    FreightForwarder: 'Interglobo',
    FreightForwarderReference: 7645832,
    Origin: 'VNHPH',
    Destination: 'USSEA',
    DocumentCutOff: '2019-10-11',
    CargoCutOff: '2019-10-11',
    ETD: '2019-10-15',
    ETA: '2019-10-30',
    Containers: [
      {
        BookingNo: 'BO000002',
        CountOfContainer: 4,
        ContainerType: '40 Dry Cargo',
        Commodity: 'Ceramic Tiles',
        ContainerGrade: 'A',
        EmptyPickUpLocation: 'At Port'
      }
    ],
    routing: [
      {
        BookingNo: 'BO000002',
        ConsoleNo: 21347,
        TransportMod: 'FCL',
        LegType: 'Transshipment 1',
        Sequence: 1,
        Vessel: 'VESSEL FEEDER',
        Voyage: '0045'
      },
      {
        BookingNo: 'BO000002',
        ConsoleNo: 21347,
        TransportMod: 'FCL',
        LegType: 'Main Leg',
        Sequence: 2,
        Vessel: 'MSC JOY',
        Voyage: 4532
      }
    ],
    PurchaseOrderList: [
      {
        BookingNo: 'BO000002',
        PoNumber: 'PO400457',
        Supplier: 'Vicostone',
        Consignee: 'Pental',
        ShipTo: 'Pental',
        TotalQtyOrdered: 1635,
      }
    ]
  }
];

export default bookings;
