import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import Form from '../../../../../../models/FormData'
const config = [
    { label: 'Carrier', id: 'CarrierName', type: 'simple' },
    { label: 'Vessel / Flight NO', id: 'VesselName', type: 'simple' },
    { label: 'Voyage', id: 'Voyage', type: 'simple' },
    { label: 'Carrier Booking #', id: 'CarrierBookingNumber', type: 'simple' },
    { label: 'Co-Loader Booking #', id: 'Co-CoLoaderBookingNumber', type: 'simple' },
    { label: 'ETD', id: 'ETD', type: 'simple' },
    { label: 'ETA', id: 'ETA', type: 'simple' },
    { label: 'Document Cut-Off', id: 'DocumentCuttOff', type: 'simple' },
    { label: 'Cargo Cut-Off', id: 'CargoCutOff', type: 'simple' },
    { label: 'Estimated Cargo Picked Up/rECEIVED', id: 'EstimatedCargoPicked ', type: 'simple' },
    { label: 'Shipment Type', id: 'ShipmentType', type: 'simple' },
]
class CarrierDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            rows: [],
            rowss: [],
            Form: Form,
        }
    }

    componentDidMount = () => {
        this.setState({
            data: this.props.data
        }, () => this.buildRows())
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        }, () => this.buildRows())
    }


    buildRows = () => {
        let { data } = this.state
        let rows = config.map((e) => {
            return (
                <div className="col-3" style={{ paddingTop: '1%' }}>
                    <div className="row">
                        <div className="col-12">
                            <span style={{ color: '#9e9e9e', fontWeight: 'bold' }}  >
                                {e.label} <br />
                            </span>  <span >{data[e.id] ? data[e.id] : ''}</span>
                        </div>
                        <div className="col-12">

                        </div>
                    </div>
                </div>
            )
        })
        this.setState({
            rows
        })
    }

    render = () => {
        return (
            <Card>
                <CardBody>
                    <h4 className="mb-3 py-2">&nbsp;&nbsp;Carrier Details</h4>
                    <div className="row">
                        <div className="col-1">
                            <em className="fas fa-shipping-fast fa-4x mb-3 py-5"></em>
                        </div>
                        <div className="col-10">
                            <div className="row">
                                {this.state.rows}
                            </div>
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default CarrierDetail;