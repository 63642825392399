import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Grid } from "@mui/material";
import { useEntitiesEP } from "../hooks";
import Table from "./table";
import { Modal as ModalAntd } from "antd";

const { confirm } = ModalAntd;

const DetailModal = ({ open, toggle, entity, t, handleAssociate }) => {
  const {
    filterEntitiesEP,
    loading,
    handleFilterEntitiesEP,
    setEntityEPService,
  } = useEntitiesEP(entity.CustomerCodeEP, entity.RecordTypeId);
  const [entityEP, setEntityEP] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (entity && entity.EntityRelationshipId) {
      setEntityEP({
        EntityId: entity.EntityRelationshipId,
        Nombre: entity.CustomerEPName,
        Address: entity.CustomerEPAddress,
      });
    } else {
      setEntityEP(null);
    }
  }, [entity]);

  const handleClose = () => {
    handleFilterEntitiesEP("");
    toggle();
    if (entity && entity.EntityRelationshipId) {
      setEntityEP({
        EntityId: entity.EntityRelationshipId,
        Nombre: entity.CustomerEPName,
        Address: entity.CustomerEPAddress,
      });
    } else {
      setEntityEP(null);
    }
  };

  const handleClickRow = (value) => {
    setEntityEP({
      ...value,
      RecordTypeId: entity.RecordTypeId,
      Nombre: value.entityName,
      Address: value.address,
    });
    if (value.entityCode === entity.CodeEP) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  };

  const handleConfirmAssociate = async () => {
    try {
      if (!entityEP || entityEP.EntityId) {
        return;
      }
      handleAssociate(
        await setEntityEPService(entity.CustomerEntityId, {
          ...entityEP,
        })
      );
    } catch (error) {
      console.log(error);
      handleAssociate(false);
    }
  };

  const handleClickAssociate = async () => {
    confirm({
      zIndex: 10000,
      wrapClassName: "confirmModalEntities",
      title: <hr className="hr-popover"></hr>,
      centered: true,
      icon: false,
      cancelText: "No",
      okText: "Yes",
      content: (
        <div>
          <div className="col-12" style={{ textAlign: "center" }}>
            <p class="seacargo-pop-icon">
              <em class="fas fa-exclamation-triangle"></em>
            </p>
            <span>Confirm</span>
          </div>
        </div>
      ),
      onOk: handleConfirmAssociate,
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      dataIndex: "entityName",
      key: "entityName",
      title: "EP entity name",
      label: "EP entity name",
      width: "40%",
      showAllText: true,
    },
    {
      dataIndex: "address",
      key: "address",
      title: "EP entity address",
      label: "EP entity address",
      width: "60%",
      showAllText: true,
    },
  ];

  return (
    <Modal
      backdrop="static"
      centered={true}
      isOpen={open}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "1000px", width: "100%", zIndex: '1000' }}
      className="listItemModal"
    >
      <ModalHeader className="advancedSearchModal detailModalHeader">
        <span className="card-title bold">
          Associate consignee
        </span>
      </ModalHeader>
      <ModalBody className="noPaddingTop">
        <Grid container spacing={2} style={{ margin: "0.1rem" }}>
          <Grid
            xs={6}
            item
            style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
          >
            <label className="label-euro bold">
              Client entity information
            </label>
            <Grid
              container
              xs={12}
              style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
            >
              <Grid item xs={5}>
                <label className="label-euro bold marginTopBottom">
                  Name
                </label>
                <label className="label-euro marginTopBottom">
                  {entity.CustomerEntityName}
                </label>
              </Grid>
              <Grid item xs={5}>
                <label className="label-euro bold marginTopBottom">
                  Address
                </label>
                <label className="label-euro marginTopBottom">
                  {entity.CustomerEntityAddress}
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={6}
            item
            style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
          >
            <label className="label-euro bold">
              EP entity information
            </label>
            <Grid
              container
              xs={12}
              style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
            >
              <Grid item xs={5}>
                <label className="label-euro bold marginTopBottom">
                  Name
                </label>
                <label className="label-euro marginTopBottom">
                  {entityEP && entityEP.Nombre ? entityEP.Nombre : ''}
                </label>
              </Grid>
              <Grid item xs={5}>
                <label className="label-euro bold marginTopBottom">
                  Address
                </label>
                <label className="label-euro marginTopBottom">
                  {entityEP && entityEP.Address ? entityEP.Address : ''}
                </label>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "4rem", gap: "2rem" }}>
              <button className="btn btn-secondary" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                disabled={loading || disabled}
                onClick={handleClickAssociate}
              >
                Associate
              </button>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
            className="gridTable"
          >
            <Table
              columns={columns}
              data={loading ? [] : filterEntitiesEP}
              loading={loading}
              handleClickRow={handleClickRow}
              handleSearch={handleFilterEntitiesEP}
              showSearch={true}
              placeholderSearch="Search"
            />
          </Grid>
        </Grid>
      </ModalBody>
    </Modal>
  );
};

export default DetailModal;
