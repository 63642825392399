import React from 'react';
// import { Redirect, } from 'react-router';
import { withRouter } from 'react-router-dom';
import openSocket from 'socket.io-client';
import { connect } from 'react-redux';
import { SetNewMessages, SetCustomerComments, SetIntergloboComments, SetOrdersAndAttention } from './../../actions/dashboard';
import { Services as DashboardServices } from './../poseidon/Dashboard/Services';

// ? components
// import Oauth from '../../Oauth';
import Base from './../template/Layout/Base';
// import NotFound from'./../template/pages/notFound';
import { MenuHandler, NameRole } from './MenuHandler';
// import ContentWrapper from './../template/Layout/ContentWrapper';

// import Paper from '@material-ui/core/Paper';
// import {Loader} from 'semantic-ui-react';
// import './WrapperRoutes_styles.scss';

import axios from 'axios';
import { Services } from './Services';

import Authentication from '../../services/Authentication.js';
const auth = new Authentication();



/*=============================================
=            Map Validation For Access        =
=============================================*/

// const map_validation = {
//   'salesleads': [1, 2, 3],
//   'user_create': [1],
//   'user_list': [1],
//   'search': [1, 2, 3, 4],
//   'fair_create': [1],
//   'fair_list': [1, 2, 3, 4],
//   'fair_one': [1, 2, 3, 4],
//   'teamleads': [1, 3],
//   'fair_assignation': [1, 3],
//   'statistics': [1, 3],
//   'my_queries': [1, 2, 3],
//   'my_fair_queries': [1, 2, 3],
//   'op_create_op': [1, 2, 3],
//   'op_create_full': [1, 2, 3],
//   'op_create_list': [1, 2, 3],
//   'op_manage_report': [1, 2, 3]
// };


/*=============================================
=            Loader COmponent evvery Change   =
=============================================*/

// const LoaderComponent = ({}) => {
//   return (
//     <Paper elevation={0}>
//       <ContentWrapper>
//         <div className="col-md-12 __loader">
//           <div className="abs-center wd-xl">
//               <div className="text-center mb-4">
//                 <Loader active inline='centered' size='huge'/>
//               </div>
//           </div>
//         </div>
//       </ContentWrapper>
//     </Paper>
//   )
// }


/*=============================================
=            Component For WrapperRoute       =
=============================================*/
const WrapperRoute = props => {

  const MainComponent = props.component;
  let token = auth.getAuthentication('token');
  let RoleId = auth.getAuthentication('RolId');
  let UserId = auth.getAuthentication('dataUser');
  let customerId = auth.getAuthentication('customerSelect');
  let CustomerSelected = auth.getAuthentication('customerSelect');
  let ProjectId = 5;
  let Email = auth.getAuthentication('Email');
  let UserName = auth.getAuthentication("UserName");
  let Role = NameRole[RoleId];



  /*=============================================
  =            OnChange Year Search             =
  =============================================*/

  const OnNewMessage = React.useCallback(async () => {
    let customer = customerId;
    customer = parseInt(customerId) ? parseInt(customerId) : '';
    // console.log('desde el lanzamiento de ONE MASSAGE');
    try {
      let newMessages = await axios.post(`${DashboardServices.DASHBOARD.path}dashboard`, {
        query: `query TrendCat($customer: Int!, $user: Int!){
          NewMessages(_customer: $customer, _user: $user){
            new
            customer
            interglobo
            new_orders
            requires_attention
          }
        }`,
        variables: {
          customer: customer,
          user: parseInt(UserId) ? parseInt(UserId) : 0
        }
      });
      // console.log('this is the messages ==>', newMessages);
      let totals_alert = newMessages.data.data.NewMessages;

      localStorage.setItem('_new_messages_', totals_alert.new);
      localStorage.setItem('_require_attention_interglobo', totals_alert.interglobo);
      localStorage.setItem('_customer_comments_', totals_alert.customer);
      localStorage.setItem('_attention_and_new_', JSON.stringify({
        new_orders: totals_alert.new_orders,
        requires_attention: totals_alert.requires_attention
      }));

      props.SetNewMessages(totals_alert.new);
      props.SetCustomerComments(totals_alert.customer);
      props.SetIntergloboComments(totals_alert.interglobo);
      props.SetOrdersAndAttention({
        new_orders: totals_alert.new_orders,
        requires_attention: totals_alert.requires_attention
      });
      // console.log(TrendShipmentByYear.data.data.TrendOrders.Trend);
      // this.setState({flotData: TrendShipmentByYear.data.data.TrendOrders.Trend});

    } catch (error) {
      console.error(error);
    }
  }, [customerId, UserId]);


  React.useEffect(() => {
    const URL = 'https://poseidonsocketservices.azurewebsites.net/comments';
    // const URL = 'http://localhost:2701/comments';
    const socket = openSocket(URL);
    socket.on('review_comments', (message) => {
      // console.log('review messages ===>', message, 'vs', customerId);
      if (message.customer === customerId || `${message.customer}` === customerId) {
        // console.log('review messages2 ===>', message.customer, 'vs', customerId);
        OnNewMessage();
      }
    });

    socket.on('unread_message', (message) => {
      if (message.customer === customerId || `${message.customer}` === customerId) {
        OnNewMessage();
      }
    });
  }, [OnNewMessage, customerId]);

  axios.get(`${Services.ADMIN.path}oauth/validate?token=${token}`, {
  }).then(response => {
    document.getElementById('body').className = 'layout-fixed';
    return (
      <Base Menu={{ Menu: MenuHandler[RoleId] }} SideBarConfig={{ nada: 'user nothing' }}> <MainComponent _customerSelect={CustomerSelected} name={props.name} _user={UserId} _rolId={RoleId} _customer={response.CustomerId} /> </Base>
    );
  }).catch(() => {
    auth.deleteAuthentication('token');
    auth.deleteAuthentication('dataUser');
    auth.deleteAuthentication('customerSelect');
    props.history.push('/');
    return (
      <Base Menu={{ Menu: MenuHandler[RoleId] }} SideBarConfig={{ nada: 'user nothing' }}> <MainComponent _customerSelect={CustomerSelected} name="/" _user={UserId} /> </Base>
    );

  });

  return (
    <Base Menu={{ Menu: MenuHandler[RoleId] }} SideBarConfig={{ nada: 'user nothing' }}>
      <MainComponent
        _customerSelect={CustomerSelected}
        name={props.name}
        _user={UserId}
        _rolId={RoleId}
        _customer={customerId}
        ProjectId={ProjectId}
        Email={Email}
        _Role={Role}
        UserName={UserName}
      /> </Base>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
  return ({
    SetNewMessages: (new_messages) => dispatch(SetNewMessages(`${new_messages}`)),
    SetCustomerComments: (new_customer_comment) => dispatch(SetCustomerComments(new_customer_comment)),
    SetIntergloboComments: (new_interglobo_comment) => dispatch(SetIntergloboComments(new_interglobo_comment)),
    SetOrdersAndAttention: (neworder_attention) => dispatch(SetOrdersAndAttention(neworder_attention)),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrapperRoute));

