const initialState = {
  custom: localStorage.getItem("design")
    ? JSON.parse(localStorage.getItem("design"))
    : {
        BigLogo:
          "https://magiclog.blob.core.windows.net/magiclogpic/logo_almacafe_s.png",
        SmallLogo:
          "https://magiclog.blob.core.windows.net/magiclogpic/coffe.png",
        PrimaryColor: "red",
        SecondaryColor: "pink",
        HoverColor: "orange",
      },
};

function designReducer(state = initialState, action) {
  if (action.type === "SET_DESIGN") {
    return {
      ...state,
      custom: action.payload,
    };
  }
  return state;
}

export default designReducer;
