import React from 'react';
import Table from '../../../../Components/Table';
import './styles.css';

/*=============================================
=            Table Events Builder             =
=============================================*/

function TablesEvent(props){
  return (
    <div>
      {
        props.allTables.map((e, key) =>(
          <div className="col-12" key={key}>
            <div className="row">
              <div className="col-12">
                <legend>{e.eventType}</legend>
              </div>
              <div className="col-12">
                <div className="table_container">
                  <Table
                    columns={props.columns}
                    data={e.data}
                    options={{
                      large:true,
                      downloads: {
                        pdf: false,
                        xlsx: false
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default TablesEvent;
