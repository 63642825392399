import React, { Component } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import { Card, CardBody, Input, Button } from "reactstrap";
import Fade from "react-reveal/Fade";
import Select from "react-select";
import "./style/style.scss";
import ListTemplates from "./listTemplates";
import axios from "axios";
import { Services } from "../../Services";
import Checkbox from "@material-ui/core/Checkbox";
import Errors from "../../../../template/Errors";
import { Dropdown } from "semantic-ui-react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import { connect } from "react-redux";
import smallLogoDefault from "../../../../../resources/smalldome4.png";
import bigLogoDefault from "../../../../../resources/logoMD.png";

registerPlugin(FilePondPluginImageValidateSize);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

class createTemplate extends Component {
  constructor(props) {
    super(props);
    this.pondBig = React.createRef();
    this.pondSmall = React.createRef();
    this.state = {
      currentValues: [],
      BigLogo: {
        file: bigLogoDefault,
        fileName: "",
        size: "",
      },
      SmallLogo: {
        file: smallLogoDefault,
        fileName: "",
        size: "",
      },
      CreateFormData: [],
      CreateForAdd: [],
      Form: this.props.form,
      selected: [],
      demoConfiguration: {
        Customer: "",
        BigLogo: {
          file: bigLogoDefault,
          fileName: "",
          size: "",
        },
        SmallLogo: {
          file: smallLogoDefault,
          fileName: "",
          size: "",
        },
        PrimaryColor: "",
        SecondaryColor: "",
        HoverColor: "",
        SelectedProject: 0,
      },
      items: [],
      error: {
        open: false,
        message: "",
        type: "admin",
      },
      submitt: false,
      viewForm: false,
    };
  }

  componentDidMount = () => {
    this.getDemoConfigurations();
  };

  validateLogos = () => {
    if (this.state.SmallLogo.fileName && this.state.BigLogo.fileName) {
      this.saveTemplate();
      this.props.onError({
        error: {
          message: "The template has been saved correctly",
          open: true,
          type: "admin",
        },
      });
    } else {
      this.props.onError({
        error: {
          message: "Small Logo and Big Logo is required",
          open: true,
          type: "warning",
        },
      });
    }
  };

  validateSaveCustomer = (e) => (evt) => {
    let validation = [];
    let errors = "";
    let Form = this.props.form.AddTemplates;
    Form.map((e) => {
      if (e.required === true) {
        validation.push(e);
      }
      return e;
    });
    validation.forEach((e) => {
      if (this.state.demoConfiguration[e.id] === "") {
        errors += e.label + ", ";
        document.getElementById(e.id).style.color = "red";
      }
    });
    if (errors.length > 0) {
      this.setState(
        {
          submitt: true,
        },
        () => {
          this.buildFormAdd();
        }
      );
      this.setState({
        error: {
          message: "Campos requeridos: " + errors + "",
          open: true,
          type: "error",
        },
      });
    } else {
      this.validateLogos();
    }
  };

  saveTemplate = () => {
    let { demoConfiguration } = this.state;
    let { BigLogo, SmallLogo } = this.state;

    let data = demoConfiguration;
    data.BigLogo = BigLogo;
    data.SmallLogo = SmallLogo;
    axios
      .post(`${Services.ADMIN.path}admin/demoConfiguration`, data)
      .then((success) => {
        let { items } = this.state;
        items.push(success.data.data);
        this.setState({
          demoConfiguration: {
            Customer: "",
            BigLogo: {
              file: bigLogoDefault,
              fileName: "",
              size: "",
            },
            SmallLogo: {
              file: smallLogoDefault,
              fileName: "",
              size: "",
            },
            PrimaryColor: "",
            SecondaryColor: "",
            HoverColor: "",
            SelectedProject: 0,
          },
          items: items,
          BigLogo: {
            file: bigLogoDefault,
            fileName: "",
            size: "",
          },
          SmallLogo: {
            file: smallLogoDefault,
            fileName: "",
            size: "",
          },
          viewForm: false,
        });
        this.buildFormAdd();
      })
      .catch((error) => {
        this.props.onError({
          error: {
            message: "Incorrect data, please verify",
            open: true,
            type: "error",
          },
        });
      });
  };

  deleteStyle = (value) => {
    let id = value.DemoConfigurationId;
    axios
      .delete(`${Services.ADMIN.path}admin/demoConfiguration/${id}`)
      .then((response) => {
        this.setState(
          {
            error: {
              open: true,
              message: "The style has been deleted!",
              type: "admin",
            },
          },
          () => {}
        );
        this.getDemoConfigurations();
      })
      .catch((error) => {});
  };

  getDemoConfigurations = () => {
    axios
      .get(`${Services.ADMIN.path}admin/demoConfiguration`)
      .then((success) => {
        this.setState({ items: success.data.data }, () => {
          this.buildFormAdd();
          this.props.onLoader(true);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  SelectDemoConfiguration = (option) => {
    axios
      .put(`${Services.ADMIN.path}admin/demoConfiguration`, option)
      .then((success) => {
        let design = {
          BigLogo: option.BigLogo,
          SmallLogo: option.SmallLogo,
          PrimaryColor: option.PrimaryColor,
          SecondaryColor: option.SecondaryColor,
          HoverColor: option.HoverColor,
        };
        localStorage.setItem("design", JSON.stringify(design));
        this.props.updateDesign(design);
        let element = document.querySelector(":root");
        element.style.setProperty("--start-color", option.PrimaryColor);
        element.style.setProperty("--end-color", option.SecondaryColor);
        element.style.setProperty("--icon-color", option.HoverColor);

        this.getDemoConfigurations();
      })
      .catch((error) => {
        this.props.onError({
          error: {
            message: "Incorrect data, please verify",
            open: true,
            type: "error",
          },
        });
      });
  };

  handleChangeAdd = (name) => (event) => {
    document.getElementById(name).style.color = "black";
    let { demoConfiguration } = this.state;
    demoConfiguration[name] = event.target.value;
    this.setState(
      {
        demoConfiguration,
      },
      () => {
        this.buildFormAdd();
      }
    );
  };

  handleChangeChecking = (selected) => (event) => {
    let { demoConfiguration } = this.state;
    let checked = event.target.checked;
    demoConfiguration[selected] = checked;
    this.setState({ selected }, () => {
      this.buildFormCreate();
    });
  };

  handleChangeCheck = (name) => (event) => {
    this.state.servicesServicces.map((e) => {
      if (e.CargoId == event.target.value) {
        e.check = !e.check;
      }
      return e;
    });
    this.setState(
      {
        servicesServicces: this.state.servicesServicces,
      },
      () => {
        this.buildFormCreate();
      }
    );
  };

  buildFormCreate = () => {
    const { Form, demoConfiguration } = this.state;

    let CreateFormData = Form.CreateCustomer.map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required == true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={demoConfiguration[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              onChange={this.handleChange(e.id)}
              max={e.max}
              error={true}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "check") {
        return (
          <div className={e.class} key={e.id}>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <Fade left opposite>
                <span id={e.label} style={e.style}>
                  {" "}
                  <label>
                    {e.label} {e.required == true ? <span>*</span> : ""}{" "}
                  </label>
                </span>
              </Fade>
            </div>
            <div
              className="col-md-12"
              style={{ textAlign: "center", marginTop: "-12px" }}
            >
              <Checkbox
                checked={demoConfiguration[e.id]}
                onChange={this.handleChangeChecking(e.id)}
                value={demoConfiguration[e.id]}
                color="primary"
              />
            </div>
          </div>
        );
      } else if (e.type === "multiCombo") {
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label}>
                {" "}
                <label>
                  {e.label} {e.required == true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Dropdown
              onChange={this.handleOnChangeData}
              id={e.id}
              options={this.state.combo}
              clearable
              placeholder="modul ..."
              search
              selection
              fluid
              value={this.state.currentValues}
              multiple
              allowAdditions
            />
          </div>
        );
      } else return "";
    });
    this.setState({
      CreateFormData,
    });
  };

  handleOnChangeData = (evt, { value }) => {
    this.setState({ currentValues: value }, () => {
      this.buildFormCreate();
    });
  };

  buildFormAdd = () => {
    const { Form, demoConfiguration, selects } = this.state;
    let CreateForAdd = Form.AddTemplates.map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required == true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              required
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={demoConfiguration[e.id]}
              onChange={this.handleChangeAdd(e.id)}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Select")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.id} style={e.style}>
                {" "}
                <label>
                  {e.label} {e.required == true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Select
              required
              type={e.datatype}
              name={e.name}
              value={"test"}
              onChange={this.handleChangeSelect(e.id)}
              options={[]}
              error={true}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      return e;
    });
    this.setState({
      CreateForAdd,
    });
  };

  showFormAdd = () => {
    this.setState({ viewForm: !this.state.viewForm });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    var img = "";
    return (
      <>
        <Card>
          <CardBody className="myCard">
            <Errors
              open={this.state.error.open}
              type={this.state.error.type}
              message={this.state.error.message}
              setProps={this.recieveProps}
            ></Errors>
            <div align="right" className="col-md-12">
              <em
                onClick={this.showFormAdd}
                className="fa-2x mr-2 fas fa-plus-circle"
                style={{ marginTop: "33px", color: "#0079B8" }}
              ></em>
            </div>
            {this.state.viewForm && (
              <div>
                <ExpansionPanel elevation={0} defaultExpanded>
                  <div className="col-md-12">
                    <strong style={{ marginLeft: "-12px" }}>
                      Add Template
                    </strong>
                  </div>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="d-flex col-md-12 flex-column">
                        <div className="row divcenter">
                          <FilePond
                            id="logoTemplateCreate"
                            allowFileEncode={true}
                            labelIdle="Big Logo"
                            ref={this.pondBig}
                            labelFileWaitingForSize={false}
                            styleButtonRemoveItemPosition="right"
                            labelButtonRetryItemLoad={false}
                            allowImageValidateSize={true}
                            imageValidateSizeMaxWidth={65535}
                            imageValidateSizeMaxHeight={65535}
                            onupdatefiles={(fileItems) => {
                              if (fileItems.length > 0) {
                                setTimeout(function() {
                                if (!(fileItems[0].file instanceof Blob)) {
                                  this.props.onError({
                                    error: {
                                      message:
                                        "error uploading file, try again",
                                      open: true,
                                      type: "error",
                                    },
                                  });
                                  this.pondBig.current.removeFiles();
                                  this.setState({
                                    BigLogo: {
                                      file: bigLogoDefault,
                                      fileName: "",
                                      size: 0,
                                    },
                                  });
                                } else {
                                  if (
                                    fileItems[0].file.type == "image/jpeg" ||
                                    fileItems[0].file.type == "image/png" ||
                                    fileItems[0].file.type == "image/jpg"
                                  ) {
                                    if (fileItems[0].file.size < 5000000) {
                                      var reader = new FileReader();
                                      reader.readAsDataURL(fileItems[0].file);
                                      reader.onload = () => {
                                        this.setState({
                                          BigLogo: {
                                            file: reader.result,
                                            fileName: fileItems[0].file.name,
                                            size: fileItems[0].file.size,
                                          },
                                        });
                                      };
                                    } else {
                                      this.pondBig.current.removeFiles();
                                      this.setState({
                                        BigLogo: {
                                          file: bigLogoDefault,
                                          fileName: "",
                                          size: 0,
                                        },
                                      });
                                      this.props.onError({
                                        error: {
                                          message:
                                            "exceed the maximum supported size!",
                                          open: true,
                                          type: "error",
                                        },
                                      });
                                    }
                                  } else {
                                    this.pondBig.current.removeFiles();
                                    this.setState({
                                      BigLogo: {
                                        file: bigLogoDefault,
                                        fileName: "",
                                        size: 0,
                                      },
                                    });
                                    this.props.onError({
                                      error: {
                                        message: "unsupported file type!",
                                        open: true,
                                        type: "error",
                                      },
                                    });
                                  }
                                }
                              }.bind(this), 1000);
                              } else {
                                this.pondBig.current.removeFiles();
                                this.setState({
                                  BigLogo: {
                                    file: bigLogoDefault,
                                    fileName: "",
                                    size: 0,
                                  },
                                });
                              }
                            }}
                          ></FilePond>
                          <img
                            src={this.state.BigLogo.file}
                            width="117"
                            height="117"
                            className="rounded_admin"
                            alt="biglogo_image"
                          />
                          <FilePond
                            id="logoTemplateCreate"
                            allowFileEncode={true}
                            labelIdle="Small Logo"
                            ref={this.pondSmall}
                            labelFileWaitingForSize={false}
                            styleButtonRemoveItemPosition="right"
                            labelButtonRetryItemLoad={false}
                            allowImageValidateSize={true}
                            imageValidateSizeMaxWidth={100}
                            imageValidateSizeMaxHeight={59}
                            imageValidateSizeLabelExpectedMaxSize={
                              "Maximum size is {maxWidth} × {maxHeight}"
                            }
                            onupdatefiles={(fileItems) => {
                              if (fileItems.length > 0) {
                                setTimeout(function() {
                                  if (!(fileItems[0].file instanceof Blob)) {
                                    this.props.onError({
                                      error: {
                                        message:
                                          "error uploading file, try again",
                                        open: true,
                                        type: "error",
                                      },
                                    });
                                    this.pondSmall.current.removeFiles();
                                    this.setState({
                                      SmallLogo: {
                                        file: smallLogoDefault,
                                        fileName: "",
                                        size: 0,
                                      },
                                    });
                                  } else {
                                    if (
                                      fileItems[0].file.type === "image/jpeg" ||
                                      fileItems[0].file.type === "image/png" ||
                                      fileItems[0].file.type === "image/jpg"
                                    ) {
                                      if (fileItems[0].file.size < 5000000) {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(fileItems[0].file);
                                        reader.onload = () => {
                                          this.setState({
                                            SmallLogo: {
                                              file: reader.result,
                                              fileName: fileItems[0].file.name,
                                              size: fileItems[0].file.size,
                                            },
                                          });
                                        };
                                      } else {
                                        this.pondSmall.current.removeFiles();
                                        this.setState({
                                          SmallLogo: {
                                            file: smallLogoDefault,
                                            fileName: "",
                                            size: 0,
                                          },
                                        });
                                        this.props.onError({
                                          error: {
                                            message:
                                              "exceed the maximum supported size!",
                                            open: true,
                                            type: "error",
                                          },
                                        });
                                      }
                                    } else {
                                      this.pondSmall.current.removeFiles();
                                      this.setState({
                                        SmallLogo: {
                                          file: smallLogoDefault,
                                          fileName: "",
                                          size: 0,
                                        },
                                      });
                                      this.props.onError({
                                        error: {
                                          message: "unsupported file type!",
                                          open: true,
                                          type: "error",
                                        },
                                      });
                                    }
                                  }
                              }.bind(this), 1000);
                               
                              } else {
                                this.pondSmall.current.removeFiles();
                                this.setState({
                                  SmallLogo: {
                                    file: smallLogoDefault,
                                    fileName: "",
                                    size: 0,
                                  },
                                });
                              }
                            }}
                          ></FilePond>
                          <img
                            src={this.state.SmallLogo.file}
                            width="117"
                            height="117"
                            className="rounded_admin"
                            alt="small_image"
                          />
                        </div>
                        <div className="d-flex divcenter flex-column">
                          <span style={{ color: "red" }}>
                            The maximum recommended size for the big logo is
                            1034 * 331.
                          </span>
                          <span style={{ color: "red" }}>
                            The maximum size for the big logo is 100 * 59.
                          </span>
                        </div>
                      </div>

                      <div
                        className="row col-md-12"
                        style={{ marginTop: "5%" }}
                      >
                        {this.state.CreateForAdd}
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <div className="row">
                  <div className="col-md-6 col-lg-4 col-sm-12"></div>
                  <div
                    style={{ textAlign: "center" }}
                    className="col-md-2 col-lg-2 col-sm-4"
                  >
                    <Button
                      variant="contained"
                      color="danger"
                      className="button"
                      onClick={this.showFormAdd}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-md-2 col-lg-1 col-sm-4">
                    <Button
                      variant="contained"
                      color="primary"
                      className="button"
                      onClick={this.validateSaveCustomer()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px" }}>Projects</strong>
            </div>
            <ListTemplates
              SelectDemoConfiguration={this.SelectDemoConfiguration}
              item={this.state.items}
              deleteStyle={this.deleteStyle}
              form={this.state.Form}
              onError={this.onError}
              getDemoConfigurations={this.getDemoConfigurations}
            ></ListTemplates>
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  BigLogo: state.design.custom.BigLogo,
  SmallLogo: state.design.custom.SmallLogo,
  PrimaryColor: state.design.custom.colorStart,
  SecondaryColor: state.design.custom.colorEnd,
  HoverColor: state.design.custom.colorIcon,
});

const mapDispatchToProps = (dispatch) => ({
  updateDesign: (newData) =>
    dispatch({
      type: "SET_DESIGN",
      payload: newData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(createTemplate);
