import React, { Component } from 'react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import { Tab } from 'semantic-ui-react'
import CreateCustomer from './components/createCustomer';
import CreateUser from './components/createUsers';
import Form from './Forms/form'
// import restService from '../../../services/restService';
import axios from 'axios';
import { Services } from './Services'
import Errors from '../../../components/template/Errors'
import { Grid } from "@mui/material";
// const rest = new restService();

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: Form,
      country: [],
      customer: [],
      error: {
        open: false,
        message: '',
        type: 'admin',
        errorValidate: false
      }
    };
  }

  componentDidMount = () => {
    this.getlist()
  }

  componentWillReceiveProps = () => {
    this.getlist()
  }

  getlist = () => {
    axios.get(`${Services.ADMIN.path}admin/listCustomer`).then(success => {
      this.setState({
        customer: success.data.data
      })
    }).catch(error => {
    });
  }

  getReturn = () => {
    this.getlist()
  }

  getReturn = () => {
    this.getlist()
  }

  chamge = () => {
    this.setState({
      errorValidate: false
    })
  }

  deleteCustomer = (value) => {
    let id = value.CustomerId
    // let body = { CustomerId: id }
    axios.delete(`${Services.ADMIN.path}admin/deleteCustomer/${id}`).then(response => {
      this.setState({
        error: {
          open: true,
          message: 'The customer has been deleted!',
          type: 'admin'
        }
      }, () => { this.getlist() })
    }).catch(error => {
    });
  }

  deleteUser = (value) => {
    let id = value.UserId
    axios.delete(`${Services.ADMIN.path}admin/deleteUser/${id}`).then(response => {
      this.setState({
        error: {
          open: true,
          message: 'The user has been deleted!',
          type: 'admin'
        }
      }, () => { this.getlist() })
    }).catch(error => {
    });

  }


  putCustomer = (body) => {
    // let url = 'http://localhost:3022/';
    // axios.put(`${url}admin/updateCustomer`, body
    axios.put(`${Services.ADMIN.path}admin/updateCustomer`, body
    ).then(response => {
      this.getlist()
      this.setState({
        error: {
          message: 'The customer has been saved correctly',
          open: true,
          type: 'admin'
        },
        errorValidate: false
      })
    }).catch(error => {
      this.setState({
        errorValidate: true
      })
    });
  }

  onError = error => {
    this.setState({
      error: error.error
    });
  };

  recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

  render() {
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fa-1x mr-2 fas fa-user-friends"></em>
              Customer Management
            </div>
          </div>
        </div>
        <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
        <Tab
          loading={true}
          menu={{ secondary: true, className: 'myWrapped' }}
          menuPosition='right'
          panes={[
            { menuItem: 'Create Customer', render: () => <CreateCustomer chamge={this.chamge} errorValidate={this.state.errorValidate} onError={this.onError} putCustomer={this.putCustomer} deleteCustomer={this.deleteCustomer} getlist={this.getlist} customerList={this.state.customer} form={this.state.form}></CreateCustomer> },
            { menuItem: 'Create User', render: () => <CreateUser getReturn={this.getReturn} _user={this.props._user} deleteUser={this.deleteUser} onError={this.onError} getlist={this.getlist} customerList={this.state.customer} form={this.state.form}></CreateUser> },
          ]} />
      </ContentWrapper>
    );
  }
}

export default Admin;