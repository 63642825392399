import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { withRouter } from "react-router-dom";

const SuccessTooltipContent = ({ name, address }) => {
  return (
    <div>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginLeft: "1em",
          paddingLeft: "0px",
        }}
        className="col-12"
      >
        <h5 className="date_header label-euro">
          <span className="label_title"> Entity: </span>
        </h5>
        <b className="label-euro" style={{ marginLeft: "5px" }}>
          {name || ""}
        </b>
      </div>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginLeft: "1em",
          paddingLeft: "0px",
        }}
        className="col-12"
      >
        <h5 className="date_header label-euro">
          <span className="label_title"> Address: </span>
        </h5>
        <b className="label-euro" style={{ marginLeft: "5px" }}>
          {address || ""}
        </b>
      </div>
    </div>
  );
};

const FailureTooltipContent = () => {
  return (
    <div>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginLeft: "1em",
          paddingLeft: "0px",
        }}
        className="col-12"
      >
        <h5 className="date_header label-euro">
          <span className="label_title colorRed bold">Not associated</span>
        </h5>
      </div>
    </div>
  );
};

const EntityTooltip = ({ entity }) => {
  const { tooltipValue, tooltipAddress, idName } = entity;
  const condition = tooltipValue || tooltipAddress;

  if (idName === "DeliveryName") {
    return null;
  }
  const tooltipComponent = condition ? (
    <SuccessTooltipContent name={tooltipValue} address={tooltipAddress} />
  ) : (
    <FailureTooltipContent />
  );
  return (
    <Tooltip
      style={{ marginLeft: "5px" }}
      placement="right"
      title={tooltipComponent}
    >
      <em
        className={`icon-final-25 mr-3 euro-info-icon ${
          condition ? "colorGreen" : "colorRed"
        }`}
      />
    </Tooltip>
  );
};

export default withRouter(EntityTooltip);
