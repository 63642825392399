import React from 'react';
import { ResponsiveBarCanvas } from '@nivo/bar';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const MyResponsiveBarCanvas = ({ position, data, keys, colors  /* see data tab */ }) =>{
  var colorsGraphs = colors;
  if(!colors){
    let newColors = [];
    data.map((e) => {
      newColors.push(e.color);
      return e;
    });
    colorsGraphs = newColors;
  }
  return((
    <ResponsiveBarCanvas
      data={data}
      keys={keys}
      indexBy="id"
      margin={{ top: 40, right: 0, bottom: 60, left: 50 }}
      pixelRatio={1}
      padding={0.45}
      innerPadding={0}
      minValue="auto"
      maxValue="auto"
      groupMode="stacked"
      layout={position ? position :'vertical'}
      reverse={false}
      colors={colorsGraphs}
      // box-shadow={{0 8px 16px 0 rgba(0,0,0,0.2)}}
      colorBy="id"
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -90,
        // legend: 'country',
        legendPosition: 'middle',
        legendOffset: 36
      }}
      // axisLeft={{
      //   // tickSize: 5,
      //   // tickPadding: 5,
      //   // tickRotation: 0,
      //   // legend: 'food',
      //   // legendPosition: 'middle',
      //   // legendOffset: -40
      // }}
      enableGridX={true}
      enableGridY={false}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      isInteractive={true}
    />
  ));
};
