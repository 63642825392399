import React, { Component, Suspense } from 'react';
import TablesData from './TablesData';
import MainInfo from './MainInfo';
import mapJson from '../../../../models/mapJson';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import BookingModel from '../../../../models/bookingModel';
import { Button } from '@material-ui/core';
import { Row, Col } from 'reactstrap';

const CardResumen = React.lazy(() => import('../../../Components/CardResumen'));

class SumaryDetail extends Component {
  state = {
    DoEmbarque: '',
    HBL_HAWB: '',
    Estatus:'',
    Info:[],
    id:this.props.match.params.id,
    headers:{title:'Embarque',logo:''},
    data: [],
    dataDrawer: '',
    visible: true,
    polyline: [],
    Resumen:[],
    tables: [
      {
        columns: [
          { label: 'Count of Container', field: 'CountOfContainer', sort: 'asc', width: 100 },
          { label: 'Container Type', field: 'ContainerType', sort: 'asc', width: 100 },
          { label: 'Commodity', field: 'Commodity', sort: 'asc', width: 100},
          { label: 'Container Grade', field: 'ContainerGrade', sort: 'asc', width: 100},
          { label: 'Empty Equipment Pickup Location', field: 'EmptyPickUpLocation', sort: 'asc', width: 100}
        ],
        data: [],
        title: 'Containers',
        key:'Containers',
        icon: 'https://fsposeidon.blob.core.windows.net/poseidonpic/container (1).png'
      },
      {
        columns: [
          { label: 'Console No.', field: 'ConsoleNo', sort: 'asc', width: 100},
          { label: 'Transport Mode', field: 'TransportMod', sort: 'asc', width: 100},
          { label: 'Leg Type', field: 'LegType', sort: 'asc', width: 100},
          { label: 'Sequence', field: 'Sequence', sort: 'asc', width: 100},
          { label: 'Vessel', field: 'Vessel', sort: 'asc', width: 100},
          { label: 'Voyage / Flight No.', field: 'Voyage', sort: 'asc', width: 100}
        ],
        data: [],
        title: 'Routing',
        key:'Routing',
        icon: 'https://fsposeidon.blob.core.windows.net/poseidonpic/destination.png'
      },
      // {
      //   columns: [
      //     { label: 'Date', field: 'Date', sort: 'asc', width: 100 },
      //     { label: 'Notifier', field: 'Notifier', sort: 'asc', width: 100 },
      //     { label: 'Comment', field: 'Comment', sort: 'asc', width: 100},
      //     { label: 'Category', field: 'Category', sort: 'asc', width: 100},
      //     { label: 'Notified To', field: 'NotifiedTo', sort: 'asc', width: 100}
      //   ],
      //   data: [],
      //   title: 'Notifications',
      //   key:'Notifications',
      //   icon: 'https://fsposeidon.blob.core.windows.net/poseidonpic/container (1).png'
      // }
    ],
    purchaseTable: {
      columns: [
        { label: 'PO No', field: 'PoNumber', sort: 'asc', width: 100 ,custom: (value) => (
          <Link to={'/weekly-planning/BO7333636'}>{value.PoNumber}</Link>) },
        { label: 'Supplier', field: 'Supplier', sort: 'asc', width: 100},
        { label: 'Consignee', field: 'Consignee', sort: 'asc', width: 100},
        { label: 'Ship to', field: 'ShipTo', sort: 'asc', width: 100},
        { label: 'Total Qty Ordered', field: 'TotalQtyOrdered', sort: 'asc', width: 100}
      ],
      data: [],
      title: 'Purchase Order List',
      key:'PurchaseOrder'
    }
  }
  componentDidMount = () =>{
    let { id, tables, purchaseTable } = this.state;
    if(id === 'BO000001'){
      tables[0].data = BookingModel[0].Containers;
      tables[1].data = BookingModel[0].routing;
      // tables[2].data = BookingModel[0].Notifications;
      purchaseTable.data = BookingModel[0].PurchaseOrderList;
    }
    else{
      tables[0].data = BookingModel[1].Containers;
      tables[1].data = BookingModel[1].routing;
      // tables[2].data = BookingModel[0].Notifications;
      purchaseTable.data = BookingModel[1].PurchaseOrderList;
    }
    this.setState({tables: tables, purchaseTable: purchaseTable});
  }

  render () {
    let { tables, purchaseTable } = this.state;

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row col-12">
            <div className="col-6">
              <em className="fas fa-broadcast-tower fa-1x mr-2"></em>
              Control Tower
            </div>
            <div className="col-6" style={{alignItems: 'right', textAlign: 'right'}}>
              <Button size="small" style={{marginLeft: 5, marginRight: 5, color: 'white', backgroundColor: '#A3CE73'}} variant="contained">Approve</Button>
              <Button size="small" style={{marginLeft: 5, marginRight: 5, color: 'white', backgroundColor: '#F46A62'}} variant="contained">Reject</Button>
              <Button size="small" style={{marginLeft: 5, marginRight: 5, color: 'white', backgroundColor: '#52ACF2'}} variant="contained">Notify</Button>
            </div>
          </div>
        </div>

        <Col md={12} sm={12} lg={12} xl={12} xs={12}>
          <Row>
            <Col md={12} sm={12} lg={12} xl={12} xs={12}>
              <Row>
                <Suspense fallback={<div>Loading...</div>}> 
                  <MainInfo id={this.state.id}/>
                </Suspense>
                <Col md={8} sm={12} lg={8} xl={8} xs={12}>
                  <TablesData
                    columns={tables[0].columns}
                    data={tables[0].data}
                    title={tables[0].title}
                    icon={tables[0].icon}
                  />
                </Col>
              </Row>
            </Col>
            <TablesData
              columns={tables[1].columns}
              data={tables[1].data}
              title={tables[1].title}
              icon={tables[1].icon}
            />              
            {/* {
              tables.map((e, index) => {
                return (<TablesData
                  columns={e.columns}
                  data={e.data}
                  key={index}
                  title={e.title}
                  icon={e.icon}
                />);
              })
            } */}
            <TablesData
              columns={purchaseTable.columns}
              data={purchaseTable.data}
              title={purchaseTable.title}
            />
          </Row>
        </Col>
      </ContentWrapper>
    );
  }
}

export default withRouter(SumaryDetail);
