import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormCreator from '../../../FormBuilder';
// import {FormEnglish, FormEsp} from '../../../Form';
import { ModelDetailTicket } from '../model';
import { Row, Col } from 'reactstrap';
import Table from './table'
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Comments from './comments';
import { EN, ES } from '../../../Etiquetas/Labels';
import { Popconfirm } from 'antd';
import Axios from "axios";
import { Services } from '../../../Services';
import Download from './dowload';
import ImageUpload from '../../CreateTicket/Components/ImageUpload';
import ModalAddDoc from './addDocumen'
// import { Upload, message, Button } from 'antd';
// import { UploadOutlined } from '@ant-design/icons';
import { Button as ButtonSemantic, Form as Com } from 'semantic-ui-react';

import './calen.scss'

// const moment = require("moment-timezone");
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  // secondaryHeading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   color: theme.palette.text.secondary,
  // },
  root2: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(1.0),
  },
}));

const columnsIng = [
  { label: 'Document', key: 'DocumentName' },
  { label: 'Name', key: 'Name' },
  // { label: 'Responsible', key: 'TypeDocument' },
  { label: 'Creation Date', key: 'CreationDate' },
  { label: 'Type', key: 'TypeDocument' },
  {
    label: 'Download', key: 'URL', custom: (value) => {
      // console.log('value-->', value);
      return (
        <Download file={value.URL} type={value.TypeDocument} name={value.DocumentName} action='download' />
      )
    }
  },
  // {
  //   label: 'View', key: 'DocumentKeyAsString', custom: (value) => {
  //     return (
  //       <Download file={value.URL} type={value.Type} name={value.CreationDateDocument} action='visualization' />
  //     )
  //   }
  // },
]

const columnsEs = [
  { label: 'Nombre de Documento', key: 'DocumentName' },
  { label: 'Nombre', key: 'Name' },
  // { label: 'Responsible', key: 'TypeDocument' },
  { label: 'Fecha de Creación', key: 'CreationDate' },
  { label: 'Tipo', key: 'TypeDocument' },
  {
    label: 'Descargar', key: 'URL', custom: (value) => {
      // console.log('value-->', value);
      return (
        <Download file={value.URL} type={value.TypeDocument} name={value.DocumentName} action='download' />
      )
    }
  },
  // {
  //   label: 'View', key: 'DocumentKeyAsString', custom: (value) => {
  //     return (
  //       <Download file={value.URL} type={value.Type} name={value.CreationDateDocument} action='visualization' />
  //     )
  //   }
  // },
]

const AccordionComponent = ({ classes, title, children }) => {

  return (
    <Accordion defaultExpanded='true' >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes ? classes.tagTitle : ''}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className='col-12'>
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};


export default function ControlledAccordions(props) {

  const textInput = useRef();
  const classes = useStyles();
  const [Model, setModel] = React.useState({ ...ModelDetailTicket() });
  const [idTicket, setIdTicket] = React.useState(0);
  const [comment, setCommet] = React.useState('');
  const [comments, setComments] = useState([])
  const [documents, setDocuments] = useState([])
  const [newDocuments, setNewDocuments] = useState([])
  const [key, setKey] = useState(1)
  const [buttonAddCom, setButtonAddCom] = useState(true);
  const [labels, setLabels] = useState(EN);
  const [Form, setForm] = React.useState({});
  // const [disabledComponent, setDisabledComponent] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [uploadDocumentButton, setUploadDocumentButton] = useState(false)
  const [tz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

  function focusTextIbput() {
    textInput.current.scrollTo(0, 1000000)
  }

  const handleDelete = (chipToDelete) => () => {
    // console.log(chipToDelete);
    // console.log(Model.Model.ccto.value);
    Model.Model.ccto.value = Model.Model.ccto.value.filter((chip) => chip.key !== chipToDelete.key);
    setModel({ Model, ...Model });


    Axios.put(`${Services.TICKETS.path}Ticket/updateTicket/${idTicket}`, Model.Model.ccto)
      .then((resp) => {
        console.log('resp---->', resp);
      })
      .catch((error) => {
        console.warn(error);
      });

  };

  function BuildDetail() {
    // console.log('jeje-->', props.dataTikets);
    Model.Model['UserProject'].value = props.dataTikets.UserProject;
    Model.Model['RolUserProject'].value = props.dataTikets.RolUserProject;
    Model.Model['Email'].value = props.dataTikets.EmailUsersProjects;
    Model.Model['Phone'].value = props.dataTikets.Phone;
    Model.Model['TypeTicket'].value = props.dataTikets.TypeTicket;
    Model.Model['RequestModule'].value = props.dataTikets.RequestModule;
    Model.Model['RequestTypeSubModule'].value = props.dataTikets.RequestTypeSubModule;
    Model.Model['Severity'].value = props.dataTikets.Severity;
    Model.Model['CreateDate'].value = props.dataTikets.createdate;
    Model.Model['UserFirstLevel'].value = props.dataTikets.UserFirstLevel ? 'First Level' : '';
    Model.Model['Description'].value = props.dataTikets.Description;
    Model.Model['ccto'].value = props.dataTikets.EmailUsersCopy ? props.dataTikets.EmailUsersCopy : [];
    // setDisabledComponent(props.dataTikets.CatStatusId === 4 ? true : false)

    setIdTicket(props.id);
    setModel({ Model, ...Model });
  }

  function getComments() {
    Axios.post(`${Services.TICKETS.path}comments/getcomments/${idTicket}`, { timezone: tz })
      .then((response) => {
        setComments(response.data.data)
        setKey(Math.random())
        focusTextIbput()

      })
      .catch((error) => {
        console.warn(error);
      });
  }

  function getDocuments() {
    Axios.get(`${Services.TICKETS.path}documents/getdocumment/${idTicket}`)
      .then((response) => {
        // console.log('response docuemnts--->', response);
        setDocuments(response.data.data)
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  function handleChange(evt, data,) {
    // console.log('data---->', data.value);
    // focusTextIbput()
    if (data.value.trim() !== "") {
      setButtonAddCom(false)
    } else {
      setButtonAddCom(true)
    }
    setCommet(data.value);
  }

  // const createOffsetValue = (date) => {
  //   var sign = date.getTimezoneOffset() > 0 ? '-' : '+';
  //   var offset = Math.abs(date.getTimezoneOffset());
  //   var hours = Math.floor(offset / 60);
  //   var minutes = ((offset % 60) / 60) * 10;
  //   return sign + hours + '.' + minutes;
  // };

  function addComment() {
    // const dteMoment = moment().format('ZZ')
    // let date = moment(new Date()).format('YYYY-MM-DD h:mm:ss a');
    // console.log('dte--->', dte);
    // console.log('dteMoment--->', dteMoment);
    // const dte = createOffsetValue(new Date());
    let body = {
      comm: comment,
      // dte: dte,
      // date: date,
      // dteMoment: dteMoment
    }
    Axios.post(`${Services.TICKETS.path}comments/createcomment/${idTicket}`, body)
      .then((response) => {
        console.log('response', response);
        Model.Model['Comments'].value = [];
        setModel({ Model, ...Model });
        getComments()
        setButtonAddCom(true)
        props.GetTicketId()
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  const callFile = (file) => {
    // console.log('callfile-->', file);
    if (file.length !== 0) {
      setNewDocuments(file)
      setUploadDocumentButton(true)
    } else {
      setNewDocuments(file)
      setUploadDocumentButton(false)
    }


  }

  function handleNameDocuemnt(evt, data) {
    // console.log('data.value-->', newDocuments.length);
    setDocumentName(data.value)
    if (data.value && newDocuments.length > 0) {
      setUploadDocumentButton(true)
    }
    else {
      setUploadDocumentButton(false)
    }
  }

  const UploadDocument = () => {
    // console.log('create ticket-->', newDocuments);
    if (newDocuments.length !== 0) {
      let doc = newDocuments[0].file
      let docinfo = newDocuments[0]
      // console.log('doc-->', doc);
      let formData = new FormData();
      formData.append('image', doc)

      Axios.post(`${Services.TICKETS.path}documents/uploadDocumment/:${idTicket}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          console.log(response.data.ok);
          let data = response.data.data
          let body = { urlDoc: data.url, Name: documentName, idTicket: idTicket, Type: docinfo.fileExtension, ByUser: 1, ByHelpDesk: 0 }
          Axios.post(`${Services.TICKETS.path}documents/saveDocumment`, body)
            .then(response => {
              // setActiveLoader(true)
              console.log(response.data.message);
              getDocuments()
              // Swal.fire({
              //   // position: 'top-end',
              //   icon: 'success',
              //   title: props.labels.alerts.new_ticket.header,
              //   text: props.labels.alerts.new_ticket.message,
              //   showConfirmButton: false,
              //   timer: 2500
              // });
            }).catch(error => {
              console.log('hubo error?', error);
            })
        }).catch(error => {
          console.log('hubo error?', error);
        })
    }
  };

  useEffect(() => {
    setLabels(props.Labels)
    setForm(props.Form)
    BuildDetail();
    getComments();
    getDocuments();
    focusTextIbput()
  }, [props]);

  return (
    // <div className={props.Closed ? 'disabledbutton' : ''}>
    <>
      <AccordionComponent classes={props.class} title={labels.details.user.title}>
        <Row>
          <div className='col-xs-2 col-md-1 col-lg-1 m-0 d-flex align-items-center justify-content-center'>
            <i className='far fa-address-card' style={{ fontSize: '2.5em' }}></i>
          </div>
          <div className='col-xs-10 col-md-11 col-lg-11'>
            <Row>
              <FormCreator dataForm={Form.DetailUser} Model={Model.Model} style={props.class} />
              {/* <Col xs={3}> */}
              <Col>
                <Row>
                  <label className='input__label'>
                    CC to
                  </label>
                  <Paper elevation={0} component="ul"
                    className={classes.root2}
                  >
                    {Model.Model.ccto.value.map((data, index) => {
                      return (
                        <li key={index}>
                          <Popconfirm
                            title={labels.alerts.confirm_deletion.title}
                            placement="topRight"
                            onConfirm={handleDelete(data)}
                            // onCancel={cancel}
                            okText={labels.alerts.confirm_deletion.confim}
                            cancelText={labels.alerts.confirm_deletion.cancel}
                          >
                            <Chip
                              label={data.label}
                            // onDelete={handleDelete(data)}
                            // className={classes.chip}
                            />
                          </Popconfirm>
                        </li>
                      );
                    })}
                  </Paper>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
        {/* </Col> */}
      </AccordionComponent>
      <AccordionComponent classes={props.class} title={labels.details.ticket.title}>
        <Row>
          <div className='col-xs-2 col-md-1 col-lg-1 m-0 d-flex align-items-center justify-content-center'>
            {/* <i className='far fa-address-card' style={{ fontSize: '3em' }}></i> */}
            <i className='far fa-address-card' style={{ fontSize: '2.5em' }}></i>
          </div>
          <div className='col-xs-10 col-md-11 col-lg-11'>
            <Row>
              <FormCreator dataForm={props.dataTikets.TypeTicketId !== 2 ? Form.DetailTicketErr : Form.DetailTicketRequest} Model={Model.Model} style={props.class} />
            </Row>
          </div>
        </Row>
      </AccordionComponent>
      <AccordionComponent classes={props.class} title={labels.details.comments.title}>
        <Row>
          <Col xs={12} md={6} lg={6} key={key} className={props.Closed ? 'disabledComponent' : '' + 'mb-4'}>
            <Com reply>
              <Com.TextArea onChange={handleChange} />
              <ButtonSemantic content={labels.details.comments.btn_send_comment} labelPosition='left' icon='comment alternate' primary
                onClick={addComment}
                disabled={buttonAddCom}
              />
            </Com>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <div ref={textInput} style={{ 'overflow': 'auto', 'overflowX': 'hidden', height: '15em' }}>
              {/* <Row> */}
              <Comments
                ref={textInput}
                // handleChange={handleChange}
                // addComment={addComment}
                comments={comments}
                // buttonAddCom={buttonAddCom}
                key={key}
                labels={labels}
              // dataTikets={props.dataTikets} 
              />
              {/* </Row> */}
            </div>
          </Col>
        </Row>
      </AccordionComponent>
      <AccordionComponent classes={props.class} title={labels.details.documents.title}>
        <Row>
          {
            props.Closed ? '' :
              <Col xs={12}>
                <ModalAddDoc
                  labels={labels.details.documents.modal_add_document}
                  // title={props.labels.new_ticket_form.title}
                  // etiquetas={props.labels.btn_new_ticket}
                  // onCancel={handleCancel}
                  uploadDocument={UploadDocument}
                  uploadDocumentButton={uploadDocumentButton}
                  colorsButtons={props.colorsButtons}
                // disebledButton={disabledButton}
                // projectId={props.Propsproject.ProjectId}
                // infoUser={infoUser}
                >
                  <Col xs={12}>
                    <ImageUpload
                      labels={labels.details.documents.modal_add_document}
                      callFile={callFile}
                    />
                    <Com>
                      <Com.Input fluid label={labels.details.documents.modal_add_document.label} placeholder={labels.details.documents.modal_add_document.placeholder} onChange={handleNameDocuemnt} />
                    </Com>
                  </Col>
                </ModalAddDoc>
              </Col>
          }
          <Table
            columns={props.validate ? columnsIng : columnsEs}
            itemsByPage={10}
            data={documents}
            options={{
              large: false,
              downloads: {
                pdf: false,
                xlsx: false
              },
              pagination: false,
              search: false,
            }}
          />

        </Row>
      </AccordionComponent>
      <br></br>
      <br></br>


    </>
  );
}