import React from 'react';
import propTypes from 'prop-types';

// * Local imports
// import Table from '../../../Components/Table';
import Table from '../../../Global/Table/';
import Card from './../../../Global/Card/';

/**
 * Tables Data Component
 */
function TablesData(props){
  return (
    <Card>
      <div className="col-12">
        <div className="row">
          {
            props.icon ? <img className="imgggggg1" src={props.icon} alt='content'/> : <></>
          }
          <span style={{marginLeft: props.icon ? 0 : 60 }}><h4 className="mb-3 py-2">{props.title}</h4></span>
        </div>
      </div>
      <div className="col-12">
        <Table
          columns={props.columns}
          data={props.data}
          itemsByPage={10}
          options={{
            large:false,
            downloads: {
              pdf: false,
              xlsx: false
            }
          }}/>
      </div>
    </Card>
  );
}

TablesData.propTypes = {
  columns: propTypes.array,
  data: propTypes.array,
  icon: propTypes.string,
  title: propTypes.string
};

TablesData.defaultProps = {
  columns: [],
  data: [],
  icon: '',
  title: ''
};

export default TablesData;
