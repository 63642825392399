import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import {withRouter} from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import Update from './components/Update';
import Upload from './components/Upload';
import 'react-datetime/css/react-datetime.css';
import './styles.scss';
import axios from 'axios';
import moment from 'moment';

import {Services} from './../Services';

class MultiUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [{ value: 2, label: 'Actual Cargo Ready' }],
      CreateFormData: [],
      active: true,
      data: [],
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getOrdersList();
  }

  getOrdersList = async () => {
    try {
      let success = await axios.get(`${Services.ORDER.path}order/search/all?type=idCustomer&id=${this.props._customer}`);
      success.data.data.map((e) => e.ActualArrivalConsolWH = e.ActualArrivalConsolWH ? moment(e.ActualArrivalConsolWH).format('YYYY-MM-DD') : '');
      this.setState({ data: success.data.data, loading: false });
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }

  putOrdersPo = async (array) => {
    try {
      await axios.post(`${Services.ORDER.path}order/multiupdate/`, {data: array});
      this.getOrdersList();
    } catch (err) {
      console.error(err);
      this.setState({ loading: false });
    }
  }

  render() {
    let { data } = this.state;
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fa-1x mr-2 fas icon-cloud-upload"></em>
              Purchase Orders - Multiple Update
            </div>
          </div>
        </div>
        <Tab
          loading={true}
          menu={{ secondary: true, className: 'myWrapped' }}
          menuPosition='right'
          panes={[
            { menuItem: 'Update', render: () => <Update putOrdersPo={this.putOrdersPo} data={data}></Update> },
            { menuItem: 'Upload', render: () => <Upload ></Upload> },
          ]} />
      </ContentWrapper>
    );
  }
}

export default withRouter(MultiUpdate);