export const FormModel = {
  form: [
    {
      type: 'Input',
      label:'Invoice Number',
      id: 'invoice',
      name: 'invoice',
      class: '_inputFilters',
      placeholder: 'Invoice Number'
    },
    {
      type: 'Input',
      label:'PO Number',
      id: 'purchase',
      name: 'purchase',
      class: '_inputFilters',
      placeholder: 'PO Number'
    },
    {
      type: 'Input',
      label:'Supplier',
      id: 'supplier',
      name: 'supplier',
      class: '_inputFilters',
      placeholder: 'Supplier'
    },
    {
      type: 'Input',
      label:'Cruise',
      id: 'cruise',
      name: 'cruise',
      class: '_inputFilters',
      placeholder: 'Cruise'
    },
    {
      type: 'Datee',
      label:'Start',
      id: 'dateStart',
      name: 'dateStart',
      class: '_inputFilters'
    },
    {
      type: 'Datee',
      label:'Finish',
      id: 'dateEnd',
      name: 'dateEnd',
      class: '_inputFilters'
    }
  ]
};

export const ModelData = {
  invoice: '',
  purchase: '',
  supplier: '',
  cruise: '',
  date: {
    dateStart: '',
    dateEnd: ''
  },
};

export const Cost = {
  "totalInvoiceAmount": "45000",
  "totalInvoices": "2",
  "data": [
    {
      "invoice": "FT-180156-EX1",
      "invoiceDate": "31/8/2018",
      "supplier": "RCG-USA",
      "invoiceAmount": 8398.8,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180351,INS01180251,INS01280351",
      "poAmount": 8398.8
    },
    {
      "invoice": "FT-180157-EX2",
      "invoiceDate": "4/9/2018",
      "supplier": "MJM",
      "invoiceAmount": 4925.6,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189148,INS01182148",
      "poAmount": 4925.6
    },
    {
      "invoice": "FT-180156-EX2",
      "invoiceDate": "19/9/2018",
      "supplier": "CRIVELLARI DIVANI",
      "invoiceAmount": 3675,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189165,INS01289165",
      "poAmount": 3675
    },
    {
      "invoice": "FT-180157-EX3",
      "invoiceDate": "20/8/2018",
      "supplier": "MJM",
      "invoiceAmount": 18528,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189145,INS02189145,INS03189145",
      "poAmount": 18528
    },
    {
      "invoice": "FT-180156-EX3",
      "invoiceDate": "26/9/2018",
      "supplier": "MJM",
      "invoiceAmount": 12220.6,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189146",
      "poAmount": 12220.6
    },
    {
      "invoice": "FT-180157-EX4",
      "invoiceDate": "27/9/2018",
      "supplier": "ITALIAN SCANDINAVIAN",
      "invoiceAmount": 3630,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180750",
      "poAmount": 3630
    },
    {
      "invoice": "FT-180156-EX4",
      "invoiceDate": "27/9/2018",
      "supplier": "ITALIAN SCANDINAVIAN",
      "invoiceAmount": 1051.65,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180698",
      "poAmount": 1051.65
    },
    {
      "invoice": "FT-180157-EX5",
      "invoiceDate": "3/10/2018",
      "supplier": "MEDIALIGHT",
      "invoiceAmount": 1465,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189181",
      "poAmount": 1465
    },
    {
      "invoice": "FT-180156-EX5",
      "invoiceDate": "4/10/2018",
      "supplier": "BC SERVICE SRL",
      "invoiceAmount": 11881.19,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180504S",
      "poAmount": 11881.19
    },
    {
      "invoice": "FT-180157-EX6",
      "invoiceDate": "4/10/2018",
      "supplier": "ULTRAFOG",
      "invoiceAmount": 65000,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180207",
      "poAmount": 65000
    },
    {
      "invoice": "FT-180156-EX6",
      "invoiceDate": "3/10/2018",
      "supplier": "ULTRAFOG",
      "invoiceAmount": 100000,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180207",
      "poAmount": 100000
    },
    {
      "invoice": "FT-180157-EX7",
      "invoiceDate": "3/10/2018",
      "supplier": "ULTRAFOG",
      "invoiceAmount": 3630,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180207",
      "poAmount": 3630
    },
    {
      "invoice": "FT-180156-EX7",
      "invoiceDate": "5/10/2018",
      "supplier": "ULTRAFOG",
      "invoiceAmount": 4000,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180207",
      "poAmount": 4000
    },
    {
      "invoice": "FT-180157-EX8",
      "invoiceDate": "5/10/2018",
      "supplier": "MEDIALIGHT",
      "invoiceAmount": 5416,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189197",
      "poAmount": 5416
    },
    {
      "invoice": "FT-180156-EX8",
      "invoiceDate": "8/10/2018",
      "supplier": "MEDIALIGHT",
      "invoiceAmount": 3600,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189183",
      "poAmount": 3600
    },
    {
      "invoice": "FT-180157-EX9",
      "invoiceDate": "9/10/2018",
      "supplier": "IMH - INDUTEC METALL GMBH",
      "invoiceAmount": 3313.8,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180604",
      "poAmount": 3313.8
    },
    {
      "invoice": "FT-180156-EX9",
      "invoiceDate": "10/10/2018",
      "supplier": "MEDIALIGHT",
      "invoiceAmount": 3630,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189197",
      "poAmount": 3630
    },
    {
      "invoice": "FT-180157-EX10",
      "invoiceDate": "10/10/2018",
      "supplier": "MEDIALIGHT",
      "invoiceAmount": 4200,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189218",
      "poAmount": 4200
    },
    {
      "invoice": "FT-180156-EX2",
      "invoiceDate": "10/10/2018",
      "supplier": "NAVALTECH",
      "invoiceAmount": 3630,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180505S",
      "poAmount": 3630
    },
    {
      "invoice": "FT-180157-EX3",
      "invoiceDate": "11/10/2018",
      "supplier": "F.LLI BOLOGNA E MARCACCIO",
      "invoiceAmount": 5386.5,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189171",
      "poAmount": 5386.5
    },
    {
      "invoice": "FT-180156-EX3",
      "invoiceDate": "11/8/2018",
      "supplier": "PRECETTI INC",
      "invoiceAmount": 678.4,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INSPCH8P37775",
      "poAmount": 678.4
    },
    {
      "invoice": "FT-180157-EX4",
      "invoiceDate": "12/10/2018",
      "supplier": "ADTS",
      "invoiceAmount": 22410.51,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180548",
      "poAmount": 22410.51
    },
    {
      "invoice": "FT-180156-EX4",
      "invoiceDate": "15/10/2018",
      "supplier": "CARRAL MARINE",
      "invoiceAmount": 9061.78,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180577",
      "poAmount": 9061.78
    },
    {
      "invoice": "FT-180157-EX5",
      "invoiceDate": "15/10/2018",
      "supplier": "NAVALIMPIANTI USA",
      "invoiceAmount": 10920,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180303",
      "poAmount": 10920
    },
    {
      "invoice": "FT-180156-EX5",
      "invoiceDate": "16/10/2018",
      "supplier": "IMATECH",
      "invoiceAmount": 2813.85,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180835",
      "poAmount": 2813.85
    },
    {
      "invoice": "FT-180157-EX6",
      "invoiceDate": "17/10/2018",
      "supplier": "CAREDI",
      "invoiceAmount": 1060,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189253",
      "poAmount": 1060
    },
    {
      "invoice": "FT-180156-EX6",
      "invoiceDate": "17/10/2018",
      "supplier": "CAREDI",
      "invoiceAmount": 2850,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189158",
      "poAmount": 2850
    },
    {
      "invoice": "FT-180157-EX7",
      "invoiceDate": "17/10/2018",
      "supplier": "CAREDI",
      "invoiceAmount": 2590,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189187",
      "poAmount": 2590
    },
    {
      "invoice": "FT-180156-EX7",
      "invoiceDate": "17/10/2018",
      "supplier": "CAREDI",
      "invoiceAmount": 784,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189261",
      "poAmount": 784
    },
    {
      "invoice": "FT-180157-EX8",
      "invoiceDate": "17/10/2018",
      "supplier": "NAVALTECH",
      "invoiceAmount": 33960,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189207S",
      "poAmount": 33960
    },
    {
      "invoice": "FT-180156-EX8",
      "invoiceDate": "17/10/2018",
      "supplier": "NAVALTECH",
      "invoiceAmount": 3534,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189224S",
      "poAmount": 3534
    },
    {
      "invoice": "FT-180157-EX9",
      "invoiceDate": "17/10/2018",
      "supplier": "ALMACO",
      "invoiceAmount": 5533.48,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180733",
      "poAmount": 5533.48
    },
    {
      "invoice": "FT-180156-EX9",
      "invoiceDate": "15/10/2018",
      "supplier": "BC SERVICE SRL",
      "invoiceAmount": 5614.83,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180504S",
      "poAmount": 5614.83
    },
    {
      "invoice": "FT-180157-EX10",
      "invoiceDate": "25/10/2018",
      "supplier": "ENRICO POLIPODIO",
      "invoiceAmount": 5412,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180844",
      "poAmount": 5412
    },
    {
      "invoice": "FT-180156-EX10",
      "invoiceDate": "25/10/2018",
      "supplier": "GECOM",
      "invoiceAmount": 2600,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180729",
      "poAmount": 2600
    },
    {
      "invoice": "FT-180157-EX11",
      "invoiceDate": "25/10/2018",
      "supplier": "GECOM",
      "invoiceAmount": 2600,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180706AA",
      "poAmount": 2600
    },
    {
      "invoice": "FT-180156-EX3",
      "invoiceDate": "25/10/2018",
      "supplier": "CAREDI",
      "invoiceAmount": 2950,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189139",
      "poAmount": 2950
    },
    {
      "invoice": "FT-180157-EX4",
      "invoiceDate": "29/10/2018",
      "supplier": "PRECETTI INC",
      "invoiceAmount": 44992.75,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189227S/0S",
      "poAmount": 44992.75
    },
    {
      "invoice": "FT-180156-EX4",
      "invoiceDate": "29/10/2018",
      "supplier": "NOVA MARINE SERVICES",
      "invoiceAmount": 400,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189237S",
      "poAmount": 400
    },
    {
      "invoice": "FT-180157-EX5",
      "invoiceDate": "25/10/2018",
      "supplier": "GEROLAMO SCORZA",
      "invoiceAmount": 4880,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189241/2S",
      "poAmount": 4880
    },
    {
      "invoice": "FT-180156-EX5",
      "invoiceDate": "30/10/2018",
      "supplier": "NOVA MARINE SERVICES",
      "invoiceAmount": 10940,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189237S",
      "poAmount": 10940
    },
    {
      "invoice": "FT-180157-EX6",
      "invoiceDate": "29/10/2018",
      "supplier": "GEROLAMO SCORZA",
      "invoiceAmount": 7750,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189241/2S",
      "poAmount": 7750
    },
    {
      "invoice": "FT-180156-EX6",
      "invoiceDate": "30/10/2018",
      "supplier": "GEROLAMO SCORZA",
      "invoiceAmount": 7750,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189241/2S",
      "poAmount": 7750
    },
    {
      "invoice": "FT-180157-EX7",
      "invoiceDate": "30/10/2018",
      "supplier": "SIDEM SRL",
      "invoiceAmount": 10000,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01180452S",
      "poAmount": 10000
    },
    {
      "invoice": "FT-180156-EX7",
      "invoiceDate": "31/10/2018",
      "supplier": "TIROLO SRL",
      "invoiceAmount": 12759.6,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189240",
      "poAmount": 12759.6
    },
    {
      "invoice": "FT-180157-EX8",
      "invoiceDate": "31/10/2018",
      "supplier": "RUBELLI SRL",
      "invoiceAmount": 5450,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189180",
      "poAmount": 5450
    },
    {
      "invoice": "FT-180156-EX8",
      "invoiceDate": "31/10/2018",
      "supplier": "MEDIALIGHT LLC",
      "invoiceAmount": 30800,
      "currency": "EUR",
      "cruise": "NCL Insigna",
      "purchaseOrder": "INS01189228",
      "poAmount": 30800
    },
    {
      "invoice": "FT-180159",
      "invoiceDate": "23/10/2018",
      "supplier": "MJM",
      "invoiceAmount": 2705.04,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189041S",
      "poAmount": 2705.04
    },
    {
      "invoice": "FT-180160",
      "invoiceDate": "19/11/2018",
      "supplier": "NAVALIMPIANTI USA INC",
      "invoiceAmount": 6981,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181299",
      "poAmount": 6981
    },
    {
      "invoice": "FT-180161",
      "invoiceDate": "19/11/2018",
      "supplier": "ATISA SPA",
      "invoiceAmount": 8976,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181169",
      "poAmount": 8976
    },
    {
      "invoice": "FT-180162",
      "invoiceDate": "12/12/2018",
      "supplier": "MJM",
      "invoiceAmount": 191845.56,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189050S",
      "poAmount": 191845.56
    },
    {
      "invoice": "FT-180163",
      "invoiceDate": "12/12/2018",
      "supplier": "MJM",
      "invoiceAmount": 0,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189050S",
      "poAmount": 0
    },
    {
      "invoice": "FT-180164",
      "invoiceDate": "12/12/2018",
      "supplier": "PRIMA SAS",
      "invoiceAmount": 16303,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189009",
      "poAmount": 16303
    },
    {
      "invoice": "FT-180165",
      "invoiceDate": "12/12/2018",
      "supplier": "BOURNE GROUP LLC",
      "invoiceAmount": 5760,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY11180616",
      "poAmount": 5760
    },
    {
      "invoice": "FT-180166",
      "invoiceDate": "12/12/2018",
      "supplier": "MARAND MARINE",
      "invoiceAmount": 646.08,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY11180582",
      "poAmount": 646.08
    },
    {
      "invoice": "FT-180167",
      "invoiceDate": "12/12/2018",
      "supplier": "SCHINDLER",
      "invoiceAmount": 120,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10180900S",
      "poAmount": 120
    },
    {
      "invoice": "FT-180168",
      "invoiceDate": "12/12/2018",
      "supplier": "MJM",
      "invoiceAmount": 1817.6,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189073S",
      "poAmount": 1817.6
    },
    {
      "invoice": "FT-180169",
      "invoiceDate": "12/12/2018",
      "supplier": "MARAND MARINE",
      "invoiceAmount": 6034.75,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY11180582",
      "poAmount": 6034.75
    },
    {
      "invoice": "FT-180170",
      "invoiceDate": "12/12/2018",
      "supplier": "GUMIS DOO",
      "invoiceAmount": 14470,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181214",
      "poAmount": 14470
    },
    {
      "invoice": "FT-180171",
      "invoiceDate": "12/12/2018",
      "supplier": "GUMIS DOO",
      "invoiceAmount": 10715,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181230",
      "poAmount": 10715
    },
    {
      "invoice": "FT-180172",
      "invoiceDate": "12/12/2018",
      "supplier": "NAVALIMPIANTI USA INC",
      "invoiceAmount": 20354,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181175",
      "poAmount": 20354
    },
    {
      "invoice": "FT-180173",
      "invoiceDate": "12/12/2018",
      "supplier": "IS MAKINEN OY",
      "invoiceAmount": 1411,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189024S",
      "poAmount": 1411
    },
    {
      "invoice": "FT-180174",
      "invoiceDate": "11/12/2018",
      "supplier": "TMS",
      "invoiceAmount": 2796.5,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181287S",
      "poAmount": 2796.5
    },
    {
      "invoice": "FT-180175",
      "invoiceDate": "11/12/2018",
      "supplier": "GF PIPING SYSTEMS",
      "invoiceAmount": 1411,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181393S",
      "poAmount": 1411
    },
    {
      "invoice": "FT-180176",
      "invoiceDate": "11/12/2018",
      "supplier": "ELRO-WERKE AG",
      "invoiceAmount": 24352,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10181070",
      "poAmount": 24352
    },
    {
      "invoice": "FT-180177",
      "invoiceDate": "7/12/2018",
      "supplier": "SAVI",
      "invoiceAmount": 209532,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY10189147S",
      "poAmount": 209532
    },
    {
      "invoice": "FT-180178",
      "invoiceDate": "13/12/2018",
      "supplier": "MJM",
      "invoiceAmount": 10710.93,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189050S",
      "poAmount": 10710.93
    },
    {
      "invoice": "FT-180179",
      "invoiceDate": "14/12/2018",
      "supplier": "MARAND MARINE",
      "invoiceAmount": 1476.8,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY11180669",
      "poAmount": 1476.8
    },
    {
      "invoice": "FT-180180",
      "invoiceDate": "13/12/2018",
      "supplier": "UNIVERSAL COMPANIES INC",
      "invoiceAmount": 0,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189091",
      "poAmount": 0
    },
    {
      "invoice": "FT-180181",
      "invoiceDate": "14/12/2018",
      "supplier": "MANTERO SISTEMI SRL",
      "invoiceAmount": 6800,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "0011008815010",
      "poAmount": 6800
    },
    {
      "invoice": "FT-180182",
      "invoiceDate": "19/12/2018",
      "supplier": "UNIVERSAL COMPANIES TM",
      "invoiceAmount": 634.47,
      "currency": "EUR",
      "cruise": "NCL Sky",
      "purchaseOrder": "SKY12189091",
      "poAmount": 634.47
    }
  ]
};