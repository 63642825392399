import React, { Component } from "react";
import { Table, Collapse } from "reactstrap";
import { Row } from "react-flexbox-grid";
import Fade from "react-reveal/Fade";
import { Search, Icon, Pagination, Label } from "semantic-ui-react";
import IconButton from "@material-ui/core/IconButton";
import { Popup } from "semantic-ui-react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Popover } from "antd";
import "./styles/main.scss";

const limitString = 20;
const IMG = "https://magiclog.blob.core.windows.net/europartnerspic/filtro.png";
class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isPanelOpen: false,
      data: [],
      filtrosPanel: props.filtrosPanel ? props.filtrosPanel : "",
      sumaryInfo: props.sumaryInfo ? props.sumaryInfo : "",
      onRowClick: this.props.onRowClick,
      resetCards: this.props.reset,
      labelSelected: this.props.labelSelected,
      rows: [],
      columnsTable: [],
      subTable: [],
      totalInvoices: 0,
      totalInvoicesAmount: 0,
      pages: this.props.pages ? this.props.pages : 0,
      activePage: 0,
      startSubTable: this.props.startSubTable ? this.props.startSubTable : 1,
      colspanSubTable: this.props.colspanSubTable
        ? this.props.colspanSubTable
        : 0,
      paddingSubtable: this.props.paddingSubtable
        ? this.props.paddingSubtable
        : "",
      tableData: [],
      number: this.props.maxPerPage ? this.props.maxPerPage : 10,
      maxPerPage: this.props.maxPerPage ? this.props.maxPerPage : 10,
      ___columns: [],
      ___rows: [],
      ___colap: [],
      open: false,
      sorts: {},
      search: this.props.search ? this.props.search : "",
      randomKey: 0,
      advancedSearchDataPanel: props.advancedSearchDataPanel,
      advancedSearchData: this.props.advancedSearchData
        ? this.props.advancedSearchData
        : [],
      ExtraFilter: this.props.ExtraFilter ? this.props.ExtraFilter : "",
      showExcel: this.props.showExcel !== false ? true : false,
      showSearch: this.props.showSearch !== false ? true : false,
      arrayAttrs: this.props.arrayAttrs ? this.props.arrayAttrs : [],
    };
  }

  componentDidMount = () => {
    let { data, columnsTable, subTable, maxPerPage, arrayAttrs, allMyData } =
      this.props;
    this.setState(
      {
        data,
        maxPerPage,
        arrayAttrs,
        allMyData,
        columnsTable,
        subTable,
      },
      () => {
        let pages = 0;
        if (this.props.pages) {
          pages = this.props.pages;
        } else {
          let dataSize = this.state.allMyData ? this.state.allMyData.length : 0;
          pages = Math.ceil(parseInt(dataSize) / this.state.maxPerPage);
        }

        let tableData = this.partirArray(1, data, pages);
        this.setState(
          {
            activePage: 1,
            pages: parseInt(pages),
            tableData,
          },
          () => {
            this.buildRows();
          }
        );
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        data: nextProps.data,
        allMyData: nextProps.data,
        columnsTable: nextProps.columnsTable,
        subTable: nextProps.subTable,
        loading: nextProps.excelLoading,
        filtrosPanel: nextProps.filtrosPanel,
        advancedSearchData: nextProps.advancedSearchData,
        labelSelected: nextProps.labelSelected,
        search: nextProps.search,
        ExtraFilter: nextProps.ExtraFilter,
        sumaryInfo:
          nextProps.sumaryInfo && nextProps.sumaryInfo !== ""
            ? nextProps.sumaryInfo
            : this.state.sumaryInfo,
      },
      () => {
        let newpages = [];
        nextProps.data.forEach((e) => {
          if (!e.hidden) {
            newpages.push(e);
          }
        });
        let pages = 0;
        if (nextProps.pages && nextProps.pages > 0) {
          pages = nextProps.pages;
        } else {
          pages = Math.ceil(
            parseInt(this.state.allMyData.length) / this.state.maxPerPage
          );
        }

        let activePage = nextProps.activePage ? nextProps.activePage : 1;

        let tableData = this.partirArray(1, nextProps.data, pages);
        this.setState(
          {
            activePage,
            pages: parseInt(pages),
            tableData,
          },
          () => {
            this.buildRows();
          }
        );
      }
    );
  };

  partirArray = (page, all, pages) => {
    let hiddtable = [];
    all.forEach((e) => {
      if (!e.hidden) {
        hiddtable.push(e);
      }
    });
    let array = [];
    if (page === 1)
      for (
        let i = 0;
        i <
        (hiddtable.length < this.state.number
          ? hiddtable.length
          : this.state.number);
        i++
      )
        array.push(hiddtable[i]);
    else if (page !== 1 && page !== pages)
      for (
        let i = (page - 1) * this.state.maxPerPage;
        i < page * this.state.maxPerPage;
        i++
      )
        array.push(hiddtable[i]);
    else
      for (
        let i = (page - 1) * this.state.maxPerPage;
        i < hiddtable.length;
        i++
      )
        array.push(hiddtable[i]);
    return array;
  };

  handlePaginationChange = (_e, { activePage }) => {
    let { data, pages } = this.state;
    let tableData = this.partirArray(activePage, data, pages);
    this.setState({ activePage, tableData }, () => {
      this.buildRows();
    });
  };

  _sortBy = (id) => () => {
    let { data, sorts } = this.state;
    if (sorts[id]) {
      let { sortBy } = sorts[id];
      let formatedData = this.makeSort(data, id, sortBy);
      sorts[id].sortBy = sorts[id].sortBy === "ASC" ? "DESC" : "ASC";
      this.setState(
        {
          sorts,
          sortKey: id,
          sortBy: sorts[id].sortBy,
        },
        () => {
          let tableData = this.partirArray(
            this.state.activePage,
            formatedData,
            this.state.pages
          );
          this.setState({ tableData }, () => this.buildRows());
        }
      );
    }
  };

  makeSort = (items, key, by) => {
    switch (by) {
      case "ASC":
        return items.sort(function (a, b) {
          if (a[key] > b[key]) {
            return 1;
          }
          if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      case "DESC":
        return items.sort(function (a, b) {
          if (a[key] < b[key]) {
            return 1;
          }
          if (a[key] > b[key]) {
            return -1;
          }
          return 0;
        });
      default:
        return items;
    }
  };

  buildColumns = () => {
    let ___columns = [];
    let { sortKey, sortBy } = this.state;
    this.state.columnsTable.forEach((e, index) => {
      let column = e.label;
      if (e.key === sortKey) {
        ___columns.push(
          sortBy === "DESC" ? (
            <th
              key={index}
              id={e.idTh ? e.idTh : index}
              onClick={this._sortBy(e.key)}
            >
              <Fade left opposite>
                <em className="icon-arrow-down mr-2"></em>
              </Fade>
              {column}
            </th>
          ) : (
            <th key={index} id={e.idTh ? e.idTh : index} onClick={""}>
              <Fade left opposite>
                <em className="icon-arrow-up mr-2"></em>
              </Fade>
              {column}
            </th>
          )
        );
      } else {
        ___columns.push(
          <th
            style={{ textAlign: "center", borderTop: "none" }}
            key={index}
            id={e.idTh ? e.idTh : index}
            onClick={this._sortBy(e.key)}
          >
            {" "}
            <span className="titleCard">{column}</span>
          </th>
        );
      }
    });
    this.setState({ ___columns });
  };

  builsub = (all) => {
    let { subTable } = this.state;
    let subrows = all.map((w, index) => {
      let subtds = [];
      subTable.forEach((subcolumn, __index_) => {
        let limitColumnsString =
          subcolumn.limitString > 0 ? subcolumn.limitString : limitString;
        let valueTD = "";
        if (subcolumn.custom) {
          valueTD = subcolumn.custom(w);
        } else if (subcolumn.separator) {
          valueTD = w[subcolumn.key]
            ? "$" + Number(w[subcolumn.key]).toLocaleString("En-us")
            : "";
        } else {
          valueTD = (
            <span>
              {w[subcolumn.key] ? (
                !subcolumn.showAllText &&
                w[subcolumn.key].length > limitColumnsString ? (
                  <Popup
                    content={w[subcolumn.key]}
                    popperModifiers={{
                      preventOverflow: {
                        boundariesElement: "offsetParent",
                      },
                    }}
                    key={w[subcolumn.key]}
                    position="right center"
                    positionFixed={true}
                    size="mini"
                    trigger={
                      <span>
                        {w[subcolumn.key].substring(0, limitColumnsString - 3)}
                        ...
                      </span>
                    }
                  />
                ) : (
                  w[subcolumn.key]
                )
              ) : (
                ""
              )}
            </span>
          );
        }
        subtds.push(
          <td
            key={__index_}
            className={
              this.state.styletd === true ? "table-td2" : "subTitleCard"
            }
            id={__index_}
            style={{
              fontSize: "12px",
              cursor: subcolumn.cursorPointer ? "pointer" : false,
              textAlign:
                subcolumn.alignText && subcolumn.alignText !== ""
                  ? subcolumn.alignText
                  : "center",
              width:
                subcolumn.width === 0
                  ? false
                  : subcolumn.width > 0
                  ? subcolumn.width + "px"
                  : "150px",
              minWidth:
                subcolumn.minWidth === 0
                  ? false
                  : subcolumn.minWidth > 0
                  ? subcolumn.minWidth + "px"
                  : "150px",
              maxWidth:
                subcolumn.maxWidth === 0
                  ? false
                  : subcolumn.maxWidth > 0
                  ? subcolumn.maxWidth + "px"
                  : "150px",
            }}
          >
            {valueTD}
          </td>
        );
      });

      return (
        <>
          <tr key={index}>{subtds}</tr>
        </>
      );
    });
    return subrows;
  };

  buildRows = () => {
    let { columnsTable, tableData } = this.state;
    let ___rows = tableData.map((e, _index) => {
      let tds = [];
      if (e.hidden) {
        return <></>;
      } else {
        if (e) {
          columnsTable.forEach((column, index_) => {
            let limitColumnsString =
              column.limitString > 0 ? column.limitString : limitString;
            let tdValue = "";
            if (column.custom) {
              tdValue = column.custom(e);
            } else if (column.index) {
              tdValue = _index + 1;
            } else if (column.shiwchColor) {
              tdValue = e[column.key] ? e[column.key] : "";
            } else if (column.CollapseTable) {
              tdValue = (
                <Row>
                  <div className="col-md-12">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={(index_) => {
                        if (tableData[_index].collapse) {
                          tableData[_index].collapse = false;
                        } else {
                          tableData[_index].collapse = true;
                        }
                        this.setState(
                          { tableData, randomKey: Math.random() },
                          () => {
                            this.buildRows();
                            this.forceUpdate();
                          }
                        );
                      }}
                    >
                      {e.collapse ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                </Row>
              );
            } else if (column.separator) {
              tdValue = e[column.key]
                ? "$" + Number(e[column.key]).toLocaleString("En-us")
                : "";
            } else if (column.popover) {
              tdValue = e[column.key] ? (
                <Popover
                  title={column.titlePopover}
                  content={
                    <div
                      style={{ width: 140, maxHeight: 150, overflowY: "auto" }}
                    >
                      {e[column.key]}
                    </div>
                  }
                >
                  {e[column.key]
                    ? `${e[column.key].substring(0, 22)}${
                        e[column.key].length > 15 ? "..." : ""
                      }`
                    : ""}
                </Popover>
              ) : (
                ""
              );
            } else {
              tdValue = (
                <span>
                  {e[column.key] ? (
                    e[column.key].length > limitColumnsString &&
                    !column.showAllText ? (
                      <Popup
                        content={e[column.key]}
                        key={e[column.key]}
                        popperModifiers={{
                          preventOverflow: {
                            boundariesElement: "offsetParent",
                          },
                        }}
                        positionFixed={true}
                        position="top center"
                        size="mini"
                        trigger={
                          <span className="label-euro">
                            {e[column.key].substring(0, limitColumnsString - 3)}
                            ...
                          </span>
                        }
                      />
                    ) : (
                      e[column.key]
                    )
                  ) : (
                    ""
                  )}
                </span>
              );
            }
            tds.push(
              <td
                id2={column.key}
                onClick={
                  this.props.onRowClick
                    ? column.key === "commentViewAction"
                      ? () => {}
                      : this.props.onRowClick(e)
                    : () => {}
                }
                key={index_}
                className={
                  this.state.styletd === true ? "table-td2" : "subTitleCard"
                }
                id={
                  e[column.key]
                    ? column.idTd
                      ? column.idTd
                      : e[column.key]
                    : e[column.key]
                }
                style={{
                  fontSize: "12px",
                  cursor: column.cursorPointer ? "pointer" : false,
                  textAlign:
                    column.alignText && column.alignText !== ""
                      ? column.alignText
                      : "center",
                  minWidth:
                    column.minWidth === 0
                      ? false
                      : column.minWidth > 0
                      ? column.minWidth + "px"
                      : "150px",
                  maxWidth:
                    column.maxWidth === 0
                      ? false
                      : column.maxWidth > 0
                      ? column.maxWidth + "px"
                      : "100px",
                }}
              >
                {tdValue}
              </td>
            );
          });
        }
      }

      return (
        <>
          <tr
            style={
              this.props.onRowClick || this.props.handleClick
                ? { cursor: "pointer" }
                : {}
            }
            key={_index}
            onClick={() => {
              if (this.props.handleClick) {
                this.props.handleClick(e);
              }
            }}
          >
            {tds}
          </tr>
          {e.collapse ? (
            <tr>
              <td
                colspan={
                  this.state.startSubTable > 0 ? this.state.startSubTable : "1"
                }
              ></td>
              <td
                colspan={
                  this.state.colspanSubTable > 0
                    ? this.state.colspanSubTable
                    : this.state.subTable.length
                }
                style={{
                  paddingLeft:
                    this.state.paddingSubtable !== ""
                      ? this.state.paddingSubtable
                      : "0px",
                  paddingRight: "0px",
                }}
              >
                <Table striped hover responsive>
                  <thead className="titleTable">
                    <tr>
                      {this.state.subTable && this.state.subTable.length > 0
                        ? this.state.subTable.map((sub) => {
                            return (
                              <th
                                className="titleCard"
                                style={{
                                  textAlign: "center",
                                  minWidth:
                                    sub.minWidth === 0
                                      ? false
                                      : sub.minWidth > 0
                                      ? sub.minWidth + "px"
                                      : "150px",
                                  maxWidth:
                                    sub.maxWidth === 0
                                      ? false
                                      : sub.maxWidth > 0
                                      ? sub.maxWidth + "px"
                                      : "150px",
                                  width:
                                    sub.width === 0
                                      ? false
                                      : sub.width > 0
                                      ? sub.width + "px"
                                      : "150px",
                                }}
                              >
                                {sub.label}
                              </th>
                            );
                          })
                        : false}
                    </tr>
                  </thead>
                  <tbody>{this.builsub(e.sub)}</tbody>
                </Table>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </>
      );
    });
    this.setState({ ___rows }, () => this.buildColumns());
  };

  buildHeaders = () => {
    let { data } = this.state;
    let totalInvoices = Array.isArray(data) ? data.length : 0;
    let totalInvoicesAmount = 0;
    data.forEach((e) => {
      totalInvoicesAmount += parseInt(e.invoiceAmount);
    });
    totalInvoicesAmount = "$" + totalInvoicesAmount.toLocaleString("en-US");
    this.setState({
      totalInvoices,
      totalInvoicesAmount,
    });
  };

  onPageChange = (evt, data) => {
    this.setState({ activePage: data.activePage }, () => {
      if (this.props.OnPageChange) {
        this.props.OnPageChange(evt, {
          activePageOrder: this.state.activePage,
        });
      } else {
        this.handlePaginationChange(evt, { activePage: this.state.activePage });
      }
    });
  };
  searchBy = (words, array, arrAttrs) => {
    let results = [];
    let all = array;
    all.forEach((e) => {
      let found = 0;
      let keys = Object.keys(e);
      keys.forEach((key) => {
        if (
          (e[key]
            ? (e[key] + "").toLowerCase().indexOf(words.toLowerCase())
            : -1) >= 0
        ) {
          if (!arrAttrs || arrAttrs.length === 0 || arrAttrs.indexOf(key) >= 0) {
            found += 1;
          }
        }
      });
      if (found !== 0) results.push(e);
    });
    return results;
  };
  initPages = () => {
    let pages = Math.ceil(
      parseInt(this.state.data.length) / this.state.maxPerPage
    );
    if (pages === 0) pages = 1;
    let tableData = this.partirArray(1, this.state.data, pages);
    this.setState(
      {
        activePage: 1,
        pages: parseInt(pages),
        tableData,
      },
      () => {
        this.buildRows();
      }
    );
  };
  handleChange = (name, value) => {
    if (this.props.newSearch) {
      this.setState({ [name]: value }, () => this.props.newSearch(value));
    } else {
      let { allMyData } = this.state;
      this.setState(
        {
          [name]: value,
        },
        () => {
          if (this.state.search === "") {
            this.setState(
              {
                data: allMyData,
              },
              () => this.initPages()
            );
          } else {
            let results = this.searchBy(
              this.state.search,
              allMyData,
              this.state.arrayAttrs
            );
            this.setState(
              {
                data: results,
              },
              () => this.initPages()
            );
          }
        }
      );
    }
  };

  handleSearchChange = (event) => {
    if (this.props.handleSearch) {
      this.setState({ search: event.target.value }, () =>
        this.props.handleSearch("search", this.state.search)
      );
    } else {
      this.handleChange("search", event.target.value);
    }
  };
  onKeyPressSearch = (event) => {
    if (this.props.onKeyPressSearch) {
      if (event.key === "Enter" && event.charCode === 13) {
        this.setState({ search: event.target.value }, () =>
          this.props.onKeyPressSearch("search", this.state.search)
        );
      }
    }
  };
  togglePanel = () => {
    this.setState({ isPanelOpen: !this.state.isPanelOpen });
  };

  render() {
    return (
      <>
        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div className="col-xs-7 col-md-4 col-lg-4">
            {this.state.showSearch ? (
              <Search
                input={{ icon: "search", iconPosition: "left", fluid: true }}
                placeholder="..."
                value={this.state.search}
                open={false}
                name="search"
                id="search"
                onSearchChange={this.handleSearchChange}
                onKeyPress={this.onKeyPressSearch}
              />
            ) : (
              false
            )}
          </div>
          <div
            className={
              this.state.sumaryInfo === ""
                ? "col-xs-5 col-md-4 col-lg-4"
                : "col-xs-5 col-md-4 col-lg-4"
            }
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
            }}
          >
            {(this.state.advancedSearchData &&
              this.state.advancedSearchData.length > 0) ||
            this.props.panel ? (
              <img
                onClick={
                  this.props.panel
                    ? this.togglePanel
                    : () => {
                        if (this.state.advancedSearchData.length > 1) {
                          this.setState({ isOpen: true });
                        }
                      }
                }
                alt="img"
                width={22}
                height={22}
                src={IMG}
                title="Advanced filters"
                style={{ cursor: "pointer" }}
              ></img>
            ) : (
              false
            )}

            {this.state.showExcel ? (
              this.state.loading ? (
                <Icon
                  loading={this.state.loading}
                  style={{ marginTop: "9px" }}
                  name="circle notch"
                  size="large"
                />
              ) : (
                <em
                  style={{
                    color: "#66bb6a",
                    marginLeft: "15px",
                    fontSize: "23px",
                  }}
                  title="Download Excel"
                  onClick={() => {
                    this.setState({ loading: true }, this.props.downloadExcel);
                  }}
                  className="download fa-2x fas fa-file-excel mr-2"
                ></em>
              )
            ) : (
              false
            )}

            {this.state.ExtraFilter ? this.state.ExtraFilter : false}
          </div>
          <div className="col-xs-12 col-md-4 col-lg-4">
            {this.state.sumaryInfo}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.labelSelected !== "" ? (
              <div className="">
                <br></br>
                <Label as="a">
                  {this.state.labelSelected}
                  <Icon name="delete" onClick={this.state.resetCards} />
                </Label>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12 col-lg-12 col-sm-12"></div>
          </div>
        </div>
        <div className="row">
          <Collapse isOpen={this.state.isPanelOpen} style={{ width: "100%" }}>
            <div className="col-12">
              <div style={{ marginBottom: "20px" }}>
                {this.state.filtrosPanel}
              </div>
              <hr />
            </div>
          </Collapse>
        </div>
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="col-12">
            <Table striped responsive key={this.state.randomKey}>
              <thead className="titleTable">
                <tr>{this.state.___columns}</tr>
              </thead>
              <tbody>{this.state.___rows}</tbody>
            </Table>
          </div>
        </div>
        {this.props.showPagination && (
          <div className="row" style={{ marginTop: "15px" }}>
            <div className="col-12">
              <center>
                <Pagination
                  ellipsisItem={null}
                  activePage={this.state.activePage}
                  size={"mini"}
                  siblingRange={0}
                  totalPages={this.state.pages}
                  onPageChange={this.onPageChange}
                />
              </center>
            </div>
          </div>
        )}
      </>
    );
  }
}

DataTable.defaultProps = {
  showPagination: true,
};

export default DataTable;
