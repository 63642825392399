import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import '../style.scss';
import Table from '../../../../../../template/Table';
import { Chip, Typography, Step, StepContent, StepLabel, Stepper, Icon} from '@material-ui/core';
import axios from 'axios';
import propTypes from 'prop-types';
import { Services } from '../../../../Services';


function ColorlibStepIcon(props) {

  const { completed } = props;

  const icons = {
    1: <Icon color="primary">check_circle</Icon>,
    2: <Icon>radio_button_unchecked</Icon>
  };

  return (
    <div>
      {completed ? icons[String(1)]: icons[String(2)]}
    </div>
  );
}
ColorlibStepIcon.propTypes = {
  completed: propTypes.bool 
};

ColorlibStepIcon.defaultProps = {
  completed: false
};

const DatosEstaticos = [
  {
    label: 'General Information',
    data: [
      {
        value: '',
        key: 'ShipmentNumber',
        label: 'Shipment No.',
        primary: true,
        otro: false
      },
      {
        value: '',
        key: 'InternalReference',
        label: 'Container Reference',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'ContainerType',
        label: 'Container Type',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'ContainerSeal',
        label: 'Seal 1',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'ContainerSeal2',
        label: 'Seal 2',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'RequestedTemperature',
        label: 'Requested Temperature',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'TemperatureRecorderDevice',
        label: 'Temp. Recorder Device No',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'ContainerGrade',
        label: 'Container Grade',
        primary: false,
        otro: false
      }]
  },
  {
    label: 'Route',
    data: [
      {
        value: '',
        key: 'ETD',
        label: 'ETD',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'ETA',
        label: 'ETA',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'PickupLocation',
        label: 'Pickup Location',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'DeliveryTown',
        label: 'Delivery Town',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'DeliveryState',
        label: 'Delivery State',
        primary: false,
        otro: false
      }]
  },
  {
    label: 'Cargo',
    data: [
      {
        value: '',
        key: 'TotalPackages',
        label: 'Total Pieces',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'Weight',
        label: 'Total Weight',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'Volume',
        label: 'Total Volume',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'Commodity',
        label: 'Commodity',
        primary: false,
        otro: false
      },
      {
        value: '',
        key: 'SpecialEquipmentInstructions',
        label: 'Special Instructions',
        primary: false,
        otro: true,
      }]
  }
];

class ContainerSummaryDetail extends Component {
  constructor(props) {
    super(props);
    // this.goBack = this.goBack.bind(this);
    this.state = {
      container: this.props.container,
      containerInfo: DatosEstaticos,
      containerItems: [],
      columns: [
        { label: 'ID', key: 'ContainerItemId' },
        { label: 'Reference', key: 'ItemReference'},
        { label: 'Supplier', key: 'SupplierName' },
        { label: 'Pieces', key: 'Pieces' },
        { label: 'Package Type', key: 'PackageType' },
        { label: 'Weight', key: 'Weight' },
        { label: 'Volume', key: 'Volume' },
        { label: 'Items Details', key: 'GoodsDetails' }
      ],
      steps: this.props.steps
    };
  }

  componentDidMount = () => {
    if (this.props.container && this.props.container.BookingContainerId)
      this.fillValues();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.container) this.setState({container: nextProps.container}, () => this.fillValues());
  }
  
  /*=============================================
  =            Get Container Items Component    =
  =============================================*/
  
  getContainerItems = async () => {
    let idContainer = this.state.container.BookingContainerId ? this.state.container.BookingContainerId : '';
    let response = await axios.get(`${Services.BOOKING.path}containers-booking/containerItems/${idContainer}`);
    if (response.data && response.data.data){
      this.setState({containerItems: response.data.data});
    }
  }
  
  /*=============================================
  =            FillValues Function              =
  =============================================*/
  
  fillValues = () => {
    var newData = this.state.containerInfo;

    newData.map((e) =>{
      e.data.map((ee) =>{
        if(this.state.container[ee.key] || this.props.container[ee.key]){
          ee.value = this.state.container[ee.key] ? this.state.container[ee.key] : this.props.container[ee.key];
        }
        return ee;
      });
      return e;
    });

    this.setState({containerInfo: newData, container: this.props.container}, () => this.getContainerItems());
  }
  
  /*=============================================
  =            GoBack Function                  =
  =============================================*/
  
  goBack = (valid) =>{
    if(valid){
      this.props.history.goBack();
    }
  }
  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-4 col-lg-4 col-sm-12">
              <Card className="myCard">
                <CardBody>
                  <div className="row">
                    <div className="col-12 row">
                      <legend className="col-8" style={{fontSize: '16px', fontWeight: 'bold'}}>{'Container #: ' + this.state.container.ContainerNumber}</legend>
                      <div className="col-4">
                        <Chip size="small" style={{color: 'white', backgroundColor: '#5D9CEC' }} label={this.state.container.StatusDesc ? this.state.container.StatusDesc : 'Arrived'} />
                      </div>
                    </div>
                    {
                      this.state.containerInfo.map((e, index) =>(
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <span className="mr-3" style={{fontWeight:'bold',color:'black'}}>{e.label}</span>
                            </div>
                            {
                              e.data.map((ee , index1) => (
                                <>
                                  <div className="col-6" key={'label-'+index1}>
                                    <span style={{fontWeight:'bold',fontSize:'12px'}}>{ee.label}</span>
                                  </div>

                                {ee.otro ? 
                                  <div className="col-12" key={'val-'+index1}>
                                    <br/>
                                    <span className={ee.primary ? 'clickeable' : ''} onClick={() => this.goBack(ee.primary)} style={{fontSize:'12px', color: ee.primary ? '#5d9cec' : ''}}>{ee.value}</span>
                                  </div>
                                  : 
                                  <div className="col-6" key={'value-'+index1} style={{textAlign: 'right'}}>
                                    <span className={ee.primary ? 'clickeable' : ''} onClick={() => this.goBack(ee.primary)} style={{fontSize:'12px', color: ee.primary ? '#5d9cec' : ''}}>{ee.key === 'RequestedTemperature' ? `${ee.value}  ℃`: ee.value}</span>
                                  </div>
                                }
                                </>
                              ))
                            }
                            {index !== this.state.containerInfo.length -1 ? <div className="col-12"><hr></hr></div> : ''}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-md-8 col-lg-8 col-xs-12">
              <Card className="myCard">
                <CardBody>
                  <div className="col-12">
                    <legend>Events</legend>
                    <div className="col-12">
                      <Stepper orientation="vertical" style={{alignContent: 'center', flexWrap: 'wrap'}} activeStep={true}>
                        {this.state.steps.map((step, index) => {
                          const props = {};
                          // console.log('===> the step', step);
                          return (
                            <Step key={`${index}-${step.label}`} active={false} completed={step.completed} {...props}>
                              <StepLabel style={{color: '#656565'}} StepIconComponent={ColorlibStepIcon} optional={<Typography variant="caption">{step.value}</Typography>}>
                                {step.label}
                              </StepLabel>
                              <StepContent>
                                <Typography style={{color: 'grey', fontSize: '12px'}}>{step.value}</Typography>
                              </StepContent>
                            </Step>
                          );
                        }
                        )}
                      </Stepper>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12">
              <Card className="myCard">
                <CardBody>
                  <div className="col-12">
                    <legend>Container Items</legend>
                  </div>
                  <div className="col-12">
                    <Table data={this.state.containerItems} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ContainerSummaryDetail);
