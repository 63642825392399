import React from 'react';
function QuoteService(props) {
 
  return (
    <div>
      Quote Service
     
    </div>
  );
}

export default QuoteService;
