import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import NoResultsFound from '../SecComponents/noResultsFound';
import CardContent from '../SecComponents/cardContent';
import { Card, Button, Grid } from 'semantic-ui-react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Table from '../../DocumentManager/Custom/components/dataTablev1';
import { Services } from '../Services';
import Authentication from '../../../../services/Authentication';
const noResultsNextSteps = {
  newSearch: 'Welcome',
  mainMenu: 'Welcome',
  rateService: 'prevRanking',
};

var avilableOptions = {
  PurchaseOrder: {
    Icon: 'ModoTransporte',
    Header: 'PONumber',
    SubHeader: 'StatusDesc',
    Action: { path: '/po-detail-view/', id: 'POId' },
    val1: { title: 'PO Creation', id: 'FormatPOCreateDate' },
    val2: { title: 'Distributor PO', id: 'DistributorPO' },
    val3: { title: 'Supplier', id: 'SupplierName' },
    val4: { title: 'Ship To', id: 'ConsigneeName' },
    tabIndex: 0,
    LabelButton: 'View Detail'
  },
  Shipment: {
    Icon: 'ModoTransporte',
    Header: 'ShipmentNumber',
    SubHeader: 'Status',
    Action: { path: '/tracking/', id: 'ShipmentNumber' },
    val1: { title: 'Origin:', id: 'Origin' },
    val2: { title: 'Destination:', id: 'Destination' },
    val3: { title: 'ETD', id: 'FormatETD' },
    val4: { title: 'ETA', id: 'FormatETA' },
    tabIndex: 0,
    LabelButton: 'View Detail'
  }, PurchaseOrderDoc: {
    Icon: 'ModoTransporte',
    Header: 'PONumber',
    SubHeader: 'StatusDesc',
    Action: { path: '/po-detail-view/', id: 'POId' },
    val1: { title: 'PO Creation', id: 'FormatPOCreateDate' },
    val2: { title: 'Distributor PO', id: 'DistributorPO' },
    val3: { title: 'Supplier', id: 'SupplierName' },
    val4: { title: 'Ship To', id: 'ConsigneeName' },
    tabIndex: 3,
    LabelButton: 'View Detail'
  },
  ShipmentDoc: {
    Icon: 'ModoTransporte',
    Header: 'ShipmentNumber',
    SubHeader: 'Status',
    Action: { path: '/tracking/', id: 'ShipmentNumber' },
    val1: { title: 'Origin:', id: 'Origin' },
    val2: { title: 'Destination:', id: 'Destination' },
    val3: { title: 'ETD', id: 'FormatETD' },
    val4: { title: 'ETA', id: 'FormatETA' },
    tabIndex: 3,
    LabelButton: 'View Detail'
  }
};

const defaultStyles = {
  fontSize: '13px',
};

function ResultsOperationStatus(props) {
  const [thereAreResults, setThereAreResults] = React.useState(true);
  const [results, setResults] = React.useState('');
  const [singleResult, setSingleResult] = React.useState('');
  const [option, setOption] = React.useState('');

  const [loader, setLoader] = React.useState(true);

  const { previousStep } = props;
  const { id, value } = previousStep;

  const [open, setOpen] = React.useState(false);

  const search = async () => {
    let operationType;
    if (id === 'purchaseOrderSearch') operationType = 'PurchaseOrder';
    if (id === 'purchaseOrderDocSearch') operationType = 'PurchaseOrderDoc';
    if (id === 'shipmentSearch') operationType = 'Shipment';
    if (id === 'shipmentDocSearch') operationType = 'ShipmentDoc';

    const qSearch = value.trim();
    const auth = new Authentication();
    let customerId = auth.getAuthentication("customerSelect");
    const data = {
      cliente: customerId,
      tipoOperacion: operationType,
    };

    setOption(operationType);

    const url = `${Services.CHATBO0T.path}chatbot?q=${qSearch}&op=${operationType.replace("Doc", "")}`;

    try {
      const getResults = await axios.post(url, data);
      // eslint-disable-next-line no-console

      if (!getResults.data.data.length) {
        setLoader(false);
        return setThereAreResults(false);
      }

      let opType;

      if (getResults.data.data.length === 1) {
        opType = getResults.data.data[0];
        setSingleResult(opType);
      }

      if (getResults.data.data.length > 1) {
        opType = getResults.data.data;
        setResults(opType);
      }

      setLoader(false);
      props.triggerNextStep({
        value: ['MainMenu', 'prevRanking'],
        trigger: 'anotherRequest',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  };

  const handleTypeOperation = React.useCallback(() => {
    search();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    handleTypeOperation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => {
    setOpen((prevState) => !prevState);
  };

  const actionButton = () => {
    setOpen((prevState) => !prevState);
  };

  const handlerResponse = (value) => {
    setSingleResult(value);
    setOpen((prevState) => !prevState);
  };

  const colData = {
    PurchaseOrder: [
      { label: 'PO Number', key: 'PONumber' },
      { label: 'PO Status', key: 'StatusDesc' },
      { label: 'PO Creation', key: 'FormatPOCreateDate' },
      { label: 'Distributor PO', key: 'DistributorPO' },
      { label: 'Supplier', key: 'SupplierName' },
      { label: 'Ship To', key: 'ConsigneeName' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ],
    Shipment: [
      { label: 'Shipment Number', key: 'ShipmentNumber' },
      { label: 'Shipment Status', key: 'Status' },
      { label: 'Origin', key: 'Origin' },
      { label: 'Destination', key: 'Destination' },
      { label: 'ETD', key: 'FormatETD' },
      { label: 'ETA', key: 'FormatETA' },
      {
        label: '',
        key: 'Download',
        separator: true,
        custom: (value) => (
          <button
            style={{ color: '#5d9cec' }}
            className="fas fa-search fa-2x  dataIcon"
            onClick={() => handlerResponse(value)}
          ></button>
        ),
      },
    ]
  };

  const multipleresults = () => {
    return (
      <Card fluid>
        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Column>
              <Grid.Row
                columns={1}
                style={{ fontSize: '12px', marginLeft: '10px' }}
              >
                These are the results for &nbsp;
                <b> {` ${value}`}</b>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              fluid
              compact
              color="grey"
              onClick={() => actionButton()}
              style={defaultStyles}
            >
              Show results
            </Button>
          </div>
        </Card.Content>
        <Modal isOpen={open} toggle={toggle} size="lg">
          <ModalHeader
            className="advancedSearchModal"
            style={{ background: '#003F70', color: 'white' }}
            toggle={toggle}
          >
            <b>Results {` ${value}`}</b>
          </ModalHeader>
          <ModalBody>
            <Table columnsTable={colData[option.replace("Doc", "")]} data={results}></Table>
          </ModalBody>
        </Modal>
      </Card>
    );
  };

  const singleResults = () => {
    return (
      <CardContent
        data={singleResult}
        option={option.replace("Doc", "")}
        avilableOptions={avilableOptions[option]}
      />
    );
  };

  const showData = () => {
    return !thereAreResults ? (
      <NoResultsFound botSteps={props} nextSteps={noResultsNextSteps} />
    ) : (
        ''
      );
  };

  return (
    <div className="row" style={{ width: '100%' }}>
      {loader ? <Loader active inline="centered" /> : showData()}
      {results.length > 1 ? multipleresults() : ''}
      {singleResult ? singleResults() : ''}
    </div>
  );
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsOperationStatus);
