import React from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';

// import { InfoButton } from './../../../../Global/Button/';
import Alert from './../../../../Global/Alert/';
import DialogComponent from './../../../../Global/Dialog';
import Table from './../../../../Global/TableV1';
import Modal from './../../../../Global/Modal/';
import {columns} from './config';
import {Services} from './../../Services';


// const Actions = 
// ? Header Component
const Header = (props) => {
  return (
    <React.Fragment>
      <h3 className='text-primary'>Shipment# {props.shipment}</h3>
      <h4 className='text-muted'>Select the PO´s to add:</h4>
    </React.Fragment>
  );
};

// ? Actions Component
const Actions = (props) => {
  return (
    <div>
      <DialogComponent
        disabled={false}
        color={'primary'}
        icon={'add'}
        actionText={'Add'}
        title='Add POs'
        // message={`Are you sure do you want to add the PO´s ${props.po_ids.join(',')} to the Shipment ${props.shipment}?`}
        message={`Are you sure do you want to add the PO´s to the Shipment ${props.shipment}?`}
        cancelText='Cancel'
        agreeText='Yes, I´m sure'
        agree={()=> props.action()}
      /> 
    </div>
  );
};

/**
 * Associate PO Component
 */
function AssociatePOComponent(props){
  let [selected, setSelected] = React.useState([]);
  let [rows, setRows] = React.useState([]);
  // * alert
  let [alert_open, setAlertOpen] = React.useState({
    open: false,
    type: 'success',
    message: ''
  });

  // ? on open alert
  const handleOpenAlert = React.useCallback((open, type, message) => {
    let alert = {
      open,
      type,
      message
    };
    setAlertOpen({...alert});
  }, []);

  /**
   * Get PO´s
   */
  const getPOs = React.useCallback(async() => {
    try{
      const url = Services.ORDER.path;
      // const url = 'http://localhost:3004';
      const response = await axios.get(`${url}/order/search/all?type=bookingCreate&id=${props._customer}`);
      // console.log('this is the associate ==>', response);
      setRows(response.data.data);
    }catch(error){
      console.error('error trying get associate POs');
    }
  }, [props._customer]);

  /**
   * Assign POs
   */
  const AssignPOs = React.useCallback(async() => {
    try{
      // const response = 
      const url = Services.ORDER.path;
      // const url = 'http://localhost:3004';
      await axios.post(`${url}/procedures/assign`, {
        user: props._user,
        shipment: props.shipment,
        po_ids: selected
      });
      // console.log('The assign POs -->', response);
      getPOs();
      setSelected([]);
      // props.restart();
      
    }catch(error){
      handleOpenAlert(true, 'error', 'Error trying associate PO´s');
      console.error('assing error ==>', error);
    }
  }, [selected, getPOs, handleOpenAlert, props]);

  /**
   * Check handler
   * @param {*} object 
   */
  function checkHandler(lines){
    // console.log('check Handler ->', lines);
    setSelected(lines);
  }

  /**
   * Check all lines handler
   * @param {*} allLines 
   */
  function checkAll(allLines){
    // console.log('checked all ==>', allLines);
    setSelected(allLines);
  }
  
  return (
    <React.Fragment>
      <Alert open={alert_open.open} message={alert_open.message} type={alert_open.type}/>
      <Modal
        title
        Header={<Header shipment={props.shipment}></Header>}
        actions={selected.length > 0 ? true: false}
        Actions={<Actions po_ids={selected} shipment={props.shipment} action={AssignPOs}></Actions>}
        buttonTitle={'Add PO'}
        iconButton={'add_circle_outline'}
        sizeButton={'small'}
        typeButon={'info'}
        widthModal={'xl'}
        init={getPOs}
        onClose={props.restart}
      >
        <Table
          rows={rows}
          handler={checkHandler}
          onselectAll={checkAll}
          columns={columns}
          showSelect
          keyCheck={'POId'}
        />
      </Modal>
    </React.Fragment>
  );
};

AssociatePOComponent.propTypes = {
  _customer: propTypes.string,
  shipment: propTypes.string,
  _user: propTypes.string
};
AssociatePOComponent.defaultProps = {
  _customer: '0',
  shipment: '0',
  _user: '0'
};

export default AssociatePOComponent;