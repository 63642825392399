import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import StepperEvent from "../../../../../../Components/StepperEvent";
import ListItemTabTable from "./listItemEventTabTable";

const ListItemEventTab = ({
  item,
}) => {
  const [poItemEvents, setPoItemEvents] = useState([]);

  const groupEvents = useCallback(() => {
    const groupedEvents = item.eventDetail.reduce((result, event) => {
      const eventType = event.EventType;
      const existingGroup = result.find(group => group.title === eventType);
  
      if (existingGroup) {
          existingGroup.events.push(event);
      } else {
          result.push({ title: eventType, events: [event] });
      }
  
      return result;
  }, []);
    setPoItemEvents(groupedEvents);
    console.log("groupedData", groupedEvents);
  }, [item]);

  useEffect(() => {
    groupEvents();
  }, [item, groupEvents]);

  return (
    <div className="eventContainer">
      <div className="row">
        <div className="col-12">
          <StepperEvent steps={item.Steps} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {poItemEvents.map((event, index) => (
            <div className="row" key={`${event.title}-${index}`}>
              <div className="col-12">
                <ListItemTabTable title={event.title} events={event.events} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ListItemEventTab);
