const Forms = [
    {
        type: 'Input',
        label: 'Shipment Number',
        class: 'col-md-6',
        placeHolder: '',
        name: 'ShipmentNumber',
        id: 'ShipmentNumber',
        style : {},
        required : true
    },
    {
        type: 'Datetime',
        label: 'Actual Time Departure',
        name: 'ActualTimeDeparture',
        class: 'col-md-6',
        id: 'ActualTimeDeparture',
        style : {},
        required : true
    },
    {
        type: 'Datetime',
        label: 'Actual Time Arrival',
        name: 'ActualTimeArrival',
        class: 'col-md-6',
        id: 'ActualTimeArrival',
        style : {},
        required : true
    },

    {
        type: 'Input',
        label: 'HBL HAWB',
        class: 'col-md-6',
        placeHolder: '',
        name: 'HBL_HAWB',
        id: 'HBL_HAWB',
        style : {},
        required : true
    },

    {
        type: 'Input',
        label: 'MBL AWB',
        class: 'col-md-6',
        placeHolder: '',
        name: 'MBL_AWB',
        id: 'MBL_AWB',
        style : {},
        required : true
    },

    {
        type: 'Input',
        label: 'Voyage Flight Number',
        class: 'col-md-6',
        placeHolder: '',
        name: 'Voyage_FlightNumber',
        id: 'Voyage_FlightNumber',
        style : {},
        required : true
    },
    {
        type: 'Datetime',
        label: 'Shiment Date',
        name: 'ShimentDate',
        class: 'col-md-6',
        id: 'ShimentDate',
        style : {},
        required : true
    },
]

export default Forms