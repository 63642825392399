import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { Form, TextArea } from 'semantic-ui-react';

import propTypes from 'prop-types';

function ModalCloseTicket(props) {
  const [openModal, setopenModal] = React.useState(false);

  function toggle(props) {
    setopenModal(!openModal);
  }

  return (
    <React.Fragment>
      {/* {props.Ticket.CatStatusId !== 3 ? */}
      <Button
        block
        size='sm'
        color={props.colorsButtons.Cancel}
        // className={`btn_close_ticket_project_${props.idProject}`}
        // hidden={props.Ticket.CatStatusId === 3 || props.Ticket.CatStatusId === 4 ? true : false}
        hidden={props.Ticket.CatStatusId !== 1 ? true : false}
        onClick={toggle}
      >
        {props.labels.details.btn_close_ticket.title}
      </Button>
      {/* :
				''
			} */}
      <Modal isOpen={openModal} >
        <ModalHeader>{props.labels.details.btn_close_ticket.titleModal}</ModalHeader>
        <ModalBody>
          <spam style={{ color: '#006cbb' }}><b>Tiket: {props.Ticket.TicketNumber}</b></spam>
          <Form>
            <TextArea placeholder='Tell us more' style={{ minHeight: 100 }}
              onChange={props.onChange}
            />
          </Form>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button color={props.colorsButtons.CancelYes} disabled={props.disabledButton} onClick={() => { setopenModal(false); props.CancelTicket(); }}>{props.labels.details.btn_close_ticket.btn_confirm}</Button>{' '}
          <Button color={props.colorsButtons.CancelNo} onClick={() => { setopenModal(false); }}>{props.labels.details.btn_close_ticket.btn_cancel}</Button>
          {/* <Button color="blue" compact onClick={() => { CancelTicket() }}>Cancel Ticket</Button>{' '}
          <Button color="red" compact onClick={() => { setopenModalCommentUser(false) }}>Cancel</Button> */}
        </ModalFooter>
        {/* <ModalBody>
          <CardBody>
            <div style={{ textAlign: 'center' }}>
              <Col xs={12}>
                <Label><h2><b>{props.labels.details.btn_close_ticket.titleModal}</b></h2></Label>
              </Col>
              <Col xs={12}>
                <i className='fas fa-exclamation-triangle fa-5x' style={{ color: 'orange' }} />
              </Col>
              <Col xs={12}>
                <CardBody>
                  <Label><h5>{props.labels.details.btn_close_ticket.warning}</h5></Label>
                </CardBody>
              </Col>
            </div>
            <Col xs={12}>
              <CardBody>
                <Form.Select
                  fluid
                  label={props.labels.details.btn_close_ticket.reason}
                  options={props.catalogos.ReasonsCloses}
                  placeholder='Select'
                  onChange={(evt, data) => { props.onChange(evt, data); }}
                />
              </CardBody>
            </Col>
          </CardBody>
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'center' }}>
          <Button color="primary" className="btn-round" onClick={() => { setopenModal(false); props.ClosedTicket(); }}>{props.labels.details.btn_close_ticket.btn_confirm}</Button>{' '}
          <Button outline color="danger" onClick={() => { setopenModal(false); }}>{props.labels.details.btn_close_ticket.btn_cancel}</Button>
        </ModalFooter> */}
      </Modal>
    </React.Fragment>
  );
};

ModalCloseTicket.propTypes = {
  labels: propTypes.object,
  onCancel: propTypes.func
};

ModalCloseTicket.defaultProps = {
  onCancel: () => { },
  labels: {
    title: '',
    icon: '',
    btn_create: '',
    btn_cancel: ''
  }
};

export default ModalCloseTicket;