import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
// import TagFacesIcon from '@material-ui/icons/TagFaces';
import Avatar from '@material-ui/core/Avatar';
import propTypes from 'prop-types';
import { blue, pink } from '@material-ui/core/colors';

import './styles.scss';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
    color:'#5d9cec',
  },
  palette:{
    primary: blue,
    secondary: pink
  }
}));

/*=============================================
=            User Selection                   =
=============================================*/

function SelectedUsersComponent(props){
  const classes = useStyles();
  return (
    <div className='users-selection'>
      <Paper
        elevation={0}
        className={classes.root}
      >
        {props.users.map(data => {
          return (
            <Chip
            //   avatar={<Avatar>{data.avatar}</Avatar>}
              variant='outlined'
              key={data.key}
              // icon={icon}
              label={data.label}
              onDelete={data.label === 'React' ? undefined : props.onDelete(data)}
              className={classes.chip}
            />
          );
        })}
      </Paper>
    </div>
  );
}

SelectedUsersComponent.propTypes = {
  onDelete: propTypes.func,
  users: propTypes.array
};

SelectedUsersComponent.defaultProps = {
  onDelete: () => {},
  users: []
};


export default SelectedUsersComponent;