import React, { useEffect } from 'react';
import ModalAddTicket from './../../Components/Modal/';
// import {FormEnglish} from './../../Form/';
// import {FormBuilder} from './../../FormBuilder';
import FormCreator from '../../FormBuilder';
import { ValidatorService, ValidatorSchema } from '../../Helpers';
import { Popup, Icon, Button } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { ModelCreateTicket } from './model';
import { Col, Row } from 'reactstrap';
import ImageUpload from './Components/ImageUpload';
import { Link } from "react-router-dom";
import Axios from "axios";
import Swal from 'sweetalert2'
import { Services } from '../../Services'
import Loader from '../../loaders';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function CreateTicket(props) {
  const [Form, setForm] = React.useState({});
  const [Model, setModel] = React.useState({ ...ModelCreateTicket() });
  const [ccTo, setccTo] = React.useState(false);
  const classes = useStyles();
  const [Catalogos, setCatalogos] = React.useState({ ...ModelCreateTicket().Catalogs });
  const [Cat, setCat] = React.useState({});
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [ActiveLoader, setActiveLoader] = React.useState(true);
  const [addBtnAddEMail, setBtnAddEmail] = React.useState(true);
  const [projectId, setProjectId] = React.useState(1);
  const [rolId, setRolId] = React.useState(1);
  const [tz] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone)


  const handleCancel = () => {
    setModel({ ...ModelCreateTicket() });
    setccTo(false)
    // infoUser();
  };

  const handleDelete = (chipToDelete) => () => {
    Model.Model.ccto.value = Model.Model.ccto.value.filter((chip) => chip.key !== chipToDelete.key);
    setModel({ Model, ...Model });
  };

  const createTicket = () => {
    setActiveLoader(false)
    Axios.post(`${Services.TICKETS.path}Ticket/createTicket`, Model.Model)
      .then((response) => {
        let idTicket = response.data.data.idTicket.rows[0].TicketId
        let TicketNumber = response.data.data.idTicket.rows[0].TicketNumber
        UpdateDoc(idTicket, TicketNumber)
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const UpdateDoc = (id, TickNum) => {
    let docs1 = Model.Model.Documents.value
    let Languaje = { language: Model.Model.language, timezone: tz }
    if (docs1.length !== 0) {
      let docs = Model.Model.Documents.value[0].file
      let formData = new FormData();
      formData.append('image', docs)

      Axios.post(`${Services.TICKETS.path}documents/uploadDocumment/:${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          let data = response.data.data
          let body = { urlDoc: data.url, Name: docs1[0].filenameWithoutExtension, idTicket: id, Type: docs1[0].fileExtension, ByUser: 1, ByHelpDesk: 0 }
          Axios.post(`${Services.TICKETS.path}documents/saveDocumment`, body)
            .then(response => {
              setActiveLoader(true)
              props.GetTikets(projectId);
              Swal.fire({
                // position: 'top-end',
                icon: 'success',
                title: props.labels.alerts.new_ticket.header,
                text: `${props.labels.alerts.new_ticket.message + ' ' + TickNum}`,
                showConfirmButton: false,
                timer: 2500
              });

            }).catch(error => {
              console.log('hubo error?', error);
            })
          Axios.post(`${Services.TICKETS.path}alerts/ActiveNewTicket/${id}`, Languaje)
            .then(res => {
              console.log(res.data.message);
            }).catch(err => {
              console.log('err send mail--->', err);
            })
        }).catch(error => {
          console.log('hubo error?', error);
        })
    } else {
      setActiveLoader(true)
      props.GetTikets(projectId);
      Swal.fire({
        // position: 'top-end',
        icon: 'success',
        title: props.labels.alerts.new_ticket.header,
        text: `${props.labels.alerts.new_ticket.message + ' ' + TickNum}`,
        showConfirmButton: false,
        timer: 2500
      });
      Axios.post(`${Services.TICKETS.path}alerts/ActiveNewTicket/${id}`, Languaje)
        .then(res => {
          console.log(res.data.message);
        }).catch(err => {
          console.log('err send mail--->', err);
        })
    }
  };

  const callFile = (file) => {
    Model.Model['Documents'].value = file;
    setModel({ Model, ...Model });
  }

  const getCatalogos = React.useCallback(async () => {
    try {
      // console.log('projectId-->', projectId);
      let body = {
        idProject: projectId
      }
      const url = `${Services.TICKETS.path}catalogos/catalogos/`;
      const resultCatalogos = await Axios.post(url, body);
      let Catresul = resultCatalogos.data.data;
      let newCatType = [];
      let newCatModule = [];
      let newCatSubmodule = [];
      let newCatSeverities = [];
      let newCatReasons = [];

      Catresul.dataCatTypesTickets.map((e) => {
        newCatType.push({ 'key': e.TypeTicketId, 'value': e.TypeTicketId, 'text': e.TypeTicket });
        return e;
      });

      Catresul.dataCatRequestModules.map((e) => {
        newCatModule.push({ 'key': e.RequestModuleId, 'value': e.RequestModuleId, 'text': e.RequestModule });
        return e;
      });

      Catresul.dataCatRequestTypesSubModules.map((e) => {
        newCatSubmodule.push({ 'key': Math.random().toString(36).substring(7), 'value': e.RequestTypeSubModuleId, 'text': e.Description });
        return e;
      });

      Catresul.dataCatSeverities.map((e) => {
        newCatSeverities.push({ 'key': e.SeverityId, 'value': e.SeverityId, 'text': e.Severity });
        return e;
      });

      Catresul.dataCatReasonsCloses.map((e) => {
        newCatReasons.push({ 'key': e.ReasonCloseI, 'value': e.ReasonCloseI, 'text': e.SeveritReasonClosey });
        return e;
      });

      Catalogos.TypeTicketId = newCatType;
      Catalogos.RequestModuleId = newCatModule;
      Catalogos.RequestTypeSubModuleId = newCatSubmodule;
      Catalogos.CatSeverityId = newCatSeverities;
      Catalogos.ReasonsCloses = newCatReasons;

      setCat(Catresul);
      setCatalogos({ Catalogos, ...Catalogos });

    } catch (error) {
      console.log('The Error-->', error);
    }
  }, [projectId]);

  function infoUser() {
    Model.Model['UserName'].value = `${props.PropsProject.UserName + ' ' + props.PropsProject.UserNameLast}`;
    Model.Model['Email'].value = props.PropsProject.Email;
    Model.Model['UserProjectId'].value = props.PropsProject._user;
    Model.Model['Rol'].value = props.PropsProject._Role;
    Model.Model['ProjectId'].value = props.PropsProject.ProjectId;
    Model.Model['language'].value = props.language;


    setModel({ Model, ...Model });
  };

  useEffect(() => {
    setForm(props.Form)
    getCatalogos();
    infoUser();
    setProjectId(props.PropsProject.ProjectId)
    setRolId(props.PropsProject._rolId)
  }, [getCatalogos, props]);


  function AddEmail() {
    Model.Model.ccto.value.push({ key: Math.random().toString(36).substring(7), label: `${Model.Model.NameCC.value} (${Model.Model.EmailCC.value})` });
    Model.Model.MailsBD.value.push({ name: Model.Model.NameCC.value, Mails: Model.Model.EmailCC.value });
    Model.Model.NameCC.value = undefined;
    Model.Model.EmailCC.value = undefined;
    setModel({ Model, ...Model });
    setBtnAddEmail(true)
  };



  function handleChange(evt, data) {
    let ModelSend = { ...Model.Model };
    let id = data.id;
    let value = data.value;
    ModelSend[id].value = value;

    if (id === 'TypeTicketId' && value === 1) {
      let NewCat = [];
      Cat.dataCatRequestModules.map((e) => {
        if (value === e.TypeTicketId) {
          NewCat.push({ 'key': e.RequestModuleId, 'value': e.RequestModuleId, 'text': e.RequestModule })
        }
        return e;
      });
      Model.Model['TypeTicketId'].value = data.value;
      Model.Model['RequestModuleId'].hidden = false;
      Model.Model['RequestTypeSubModuleId'].hidden = false;
      Model.Model['CatSeverityId'].hidden = false;
      Model.Model['Request'].hidden = true;
      Model.Model['RequestType'].hidden = true;

      Catalogos.RequestModuleId = NewCat;
    }

    else if (id === 'TypeTicketId' && value === 2) {
      let NewCat = [];
      Cat.dataCatRequestModules.map((e) => {
        if (projectId === 2) {
          if (rolId === '3') {
            if (e.RequestModuleId !== 92 && e.RequestModuleId !== 93) {
              if (value === e.TypeTicketId) {
                NewCat.push({ 'key': e.RequestModuleId, 'value': e.RequestModuleId, 'text': e.RequestModule })
              }
              return e;
            }
          }
          else {
            if (value === e.TypeTicketId) {
              NewCat.push({ 'key': e.RequestModuleId, 'value': e.RequestModuleId, 'text': e.RequestModule })
            }
            return e;
          }
        }
        else {
          if (value === e.TypeTicketId) {
            NewCat.push({ 'key': e.RequestModuleId, 'value': e.RequestModuleId, 'text': e.RequestModule })
          }
          return e;
        }

      });

      Model.Model['TypeTicketId'].value = data.value;

      Model.Model['RequestModuleId'].hidden = true;
      Model.Model['RequestTypeSubModuleId'].hidden = true;
      Model.Model['CatSeverityId'].hidden = true;
      Model.Model['Request'].hidden = false;
      Model.Model['RequestType'].hidden = false;

      Catalogos.Request = NewCat;
    }

    else if (id === 'TypeTicketId' && value === 3) {

      let NewCat = [];
      Cat.dataCatRequestModules.map((e) => {
        if (value === e.TypeTicketId) {
          NewCat.push({ 'key': e.RequestModuleId, 'value': e.RequestModuleId, 'text': e.RequestModule });
        }
        return e;
      });
      Model.Model['TypeTicketId'].value = data.value;
      Model.Model['RequestModuleId'].hidden = false;
      Model.Model['RequestTypeSubModuleId'].hidden = false;
      Model.Model['CatSeverityId'].hidden = true;
      Model.Model['Request'].hidden = true;
      Model.Model['RequestType'].hidden = true;

      Catalogos.RequestModuleId = NewCat;

    }

    else if (data.id === 'RequestModuleId') {
      let NewCat = [];
      Cat.dataCatRequestTypesSubModules.map((e) => {
        if (value === e.RequestModuleId) {
          NewCat.push({ 'key': e.RequestTypeSubModuleId, 'value': e.RequestTypeSubModuleId, 'text': e.Description });
        }
        return e;
      });
      Catalogos.RequestTypeSubModuleId = NewCat;
    }

    else if (data.id === 'Request') {
      let NewCat = [];
      Cat.dataCatRequestTypesSubModules.map((e) => {
        if (value === e.RequestModuleId) {
          NewCat.push({ 'key': e.RequestTypeSubModuleId, 'value': e.RequestTypeSubModuleId, 'text': e.Description });
        }
        return e;
      });
      Catalogos.RequestType = NewCat;
    }
    let res = ValidatorService(ModelSend[id].type, value);
    setCatalogos({ Catalogos, ...Catalogos });
    setModel({ Model, ...Model });

    //Valida el boton de guardar ticket que tenga los valores requeridos de acuerdo al tipo de ticket
    if (Model.Model.TypeTicketId.value === 1) {
      if (res) {
        if (Model.Model.EmailCC.value && Model.Model.NameCC.value) {
          setBtnAddEmail(false)
        }
        else if (Model.Model.NameCC.value === "") {
          setBtnAddEmail(true)
        }
        ModelSend[id].valid = true;
        ModelSend[id].value = value;
        let valid = ValidatorSchema(ModelSend, ['Phone', 'RequestModuleId', 'RequestTypeSubModuleId', 'CatSeverityId', 'Subjet', 'Description']);
        if (valid) {
          setDisabledButton(false)
        } else {
          setDisabledButton(true)
        }
      } else {
        if (Model.Model.EmailCC.value) {
          setBtnAddEmail(true)
        }
        ModelSend[id].valid = false;
        ModelSend[id].value = value;
        setDisabledButton(true)
      }
    }
    else if (Model.Model.TypeTicketId.value === 2) {
      if (res) {
        if (Model.Model.EmailCC.value && Model.Model.NameCC.value) {
          setBtnAddEmail(false)
        }
        else if (Model.Model.NameCC.value === "") {
          setBtnAddEmail(true)
        }
        ModelSend[id].valid = true;
        ModelSend[id].value = value;
        let valid = ValidatorSchema(ModelSend, ['Phone', 'Request', 'RequestType', 'Subjet', 'Description']);
        if (valid) {
          setDisabledButton(false)
        } else {
          setDisabledButton(true)
        }
      } else {
        if (Model.Model.EmailCC.value) {
          setBtnAddEmail(true)
        }
        ModelSend[id].valid = false;
        ModelSend[id].value = value;
        setDisabledButton(true)
      }
    }
    else if (Model.Model.TypeTicketId.value === 3) {
      if (res) {
        if (Model.Model.EmailCC.value && Model.Model.NameCC.value) {
          setBtnAddEmail(false)
        }
        else if (Model.Model.NameCC.value === "") {
          setBtnAddEmail(true)
        }
        ModelSend[id].valid = true;
        ModelSend[id].value = value;
        let valid = ValidatorSchema(ModelSend, ['Phone', 'RequestModuleId', 'RequestModuleId', 'Subjet', 'Description']);
        if (valid) {
          setDisabledButton(false)
        } else {
          setDisabledButton(true)
        }
      } else {
        if (Model.Model.EmailCC.value) {
          setBtnAddEmail(true)
        }
        ModelSend[id].valid = false;
        ModelSend[id].value = value;
        setDisabledButton(true)
      }
    }
    else {
      if (res) {
        ModelSend[id].valid = true;
        ModelSend[id].value = value;
        if (Model.Model.EmailCC.value && Model.Model.NameCC.value) {
          setBtnAddEmail(false)
        }
        else if (Model.Model.NameCC.value === "") {
          setBtnAddEmail(true)
        }
      } else {
        ModelSend[id].valid = false;
        ModelSend[id].value = value;
        if (Model.Model.EmailCC.value) {
          setBtnAddEmail(true)
        }
      }
      setDisabledButton(true)
    }
  }

  function onclickCC() {
    setccTo(!ccTo);
    // Model.Model['NameCC'].value = undefined;
    // Model.Model['EmailCC'].value = undefined;
    // Model.Model['ccto'].value = [];
    // setModel({ Model, ...Model });
  }


  return (
    <>
      <Loader hidden={ActiveLoader} projectId={props.PropsProject.ProjectId} />
      <ModalAddTicket
        title={props.labels.new_ticket_form.title}
        etiquetas={props.labels.btn_new_ticket}
        onCancel={handleCancel}
        createTicket={createTicket}
        disebledButton={disabledButton}
        projectId={props.PropsProject.ProjectId}
        infoUser={infoUser}
      >
        <FormCreator dataForm={Form.ModalInfo} Model={Model.Model} onChange={handleChange} onClick={onclickCC} style={props.PropsProject.style} />
        <div className='col-12'>
          <label className='input__labelcc' onClick={onclickCC}>
            {/* <Link> */}
            <Popup
              content={props.labels.new_ticket_form.ccto.info}
              trigger={<Icon name='info circle' size='small' color='green' />} />
            {props.labels.new_ticket_form.ccto.title}
            {/* </Link> */}
          </label>
          <hr></hr>
          {ccTo ?
            <div className='row'>
              <Col xs={12} md={12} lg={12}>
                <Row>
                  <FormCreator dataForm={Form.ModalCCto} Model={Model.Model} onChange={handleChange} />
                  <Col xs={1} md={1} lg={1} className='d-flex align-items-center' style={{ paddingTop: 10, paddingLeft: 0 }}>
                    <Button circular color='blue' icon='add' size='mini' onClick={AddEmail}
                      disabled={addBtnAddEMail}
                    // {Model.Model.NameCC.value && Model.Model.EmailCC.value ? false : true} 
                    />
                  </Col>
                </Row>
              </Col>
              {Model.Model.ccto.value.length > 0 ?
                <div className='col-md-12'>
                  <Paper elevation={0} component="ul" className={classes.root}>
                    {Model.Model.ccto.value.map((data, index) => {
                      return (
                        <li key={index}>
                          <Chip
                            label={data.label}
                            onDelete={handleDelete(data)}
                            className={classes.chip}
                          />
                        </li>
                      );
                    })}
                  </Paper>
                </div>
                :
                ''
              }
            </div>
            : ''
          }
        </div>
        <FormCreator dataForm={Form.ModalSelect} Model={Model.Model} Catalog={Catalogos} onChange={handleChange} />
        <Col xs={12}>
          <ImageUpload callFile={callFile} labels={props.labels.btn_new_ticket} />
        </Col>
      </ModalAddTicket>
    </>
  );
}

export default CreateTicket;