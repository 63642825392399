import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap'


class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount = () => {

    }

    componentWillReceiveProps = (nextProps) => {

    }

    render() {
        return (
            <Card style={{
                background: '#e0e0e0',
                textAlign:'justify',
                fontSize:'12px',
                fontWeight:'bold',
                // color:'#fff'
                // height: '240px'
            }}>
                <CardBody>
                    <div className="row">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <em className="fas fa-info-circle mr-3"></em>
                        </div>
                        <div className="col-12">
                            <span>
                                Comments:
                            </span>
                        </div>

                        <div className="col-12">
                            <span>
                                Rates shown in this schedule to include charges related to:
                            </span>
                        </div>
                        <div className="col-12">
                            <span>
                            Ground Transportation from vendor warehouses (origins) around Europe to the closest consolidation
                            warehouse/hub (include applicable fuel surcharges, and custom formalities)
                            </span>
                        </div>
                        <div className="col-12"><br></br></div>
                        <div className="col-12">
                            <span>
                                Ratios:
                            </span>
                        </div>

                        <div className="col-12">
                            <span>
                                Stackable cargo: 1 Cbm = 300 Kg. This will apply in the following way:
                            </span>
                        </div>

                        <div className="col-12">
                            <span>
                                Max of [Actual shipment weight] or [Length * Base * Height (in meters) * 300kgs]
                            </span>
                        </div>
                        <div className="col-12"><br></br></div>
                        <div className="col-12">
                            <span>
                            Non-stackable cargo: 1 Loading meter = 1,600 Kgs. This will apply in the following way:
                            </span>
                        </div>

                        <div className="col-12">
                            <span>
                            Max of [Actual shipment weight] or [Length (meters) * Width (meters) / 2.4 * 1600kgs]

                            </span>
                        </div>
                        <div className="col-12"><br></br></div>
                        <div className="col-12">
                            <span>
                            Fuel rates will be monitored on a quarterly basis with the index published by the European Commission, DG
                            Energy and Transport, in the Oil Bulletin (http://ec.europa.eu/energy/observatory/oil/bulletin_en.htm). Should
                            there be large variations (+/- 10%) from the previous quarter's rates, a discussion will be set up to discuss
                            potential fuel compensation adjustments
                            </span>
                        </div>
                        <div className="col-12"><br></br></div>
                        <div className="col-12">
                            <span>
                                The rates quoted within this document must be valid until end of 2018.
                            </span>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default Comments;


