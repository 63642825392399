export const Services = {
   "PURCHASE_ORDER": {
      "path": "https://euro-api-purchaseorder.devsandbox.magicanalytics.app/",
      "method": "",
      "descrp": "API TO HANDLE ORDEN COMPRA"
   },
   "REPORT": {
      "path": "https://servicereportapi.azurewebsites.net",
      "method": "",
      "descrp": "API TO HANDLE REPORT"
   },
   "ORDEN_SERV": {
      "path": "https://euro-api-ordenserv.devsandbox.magicanalytics.app/",
      "method": "",
      "descrp": "API TO HANDLE ORDEN SERVICIO"
   },
   "DOCUMENTS": {
      "path": "https://euro-api-documents.devsandbox.magicanalytics.app/",
      "method": "",
      "descrp": "API TO HANDLE DOCUMENTS"
   }
}