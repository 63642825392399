import React, { Component } from 'react';
// import restService from '../../../../services/restService';
import { Button } from 'reactstrap';
import swal from 'sweetalert';
import {Card,CardBody} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';

import ContentWrapper from '../../../template/Layout/ContentWrapper';
import Table from '../../../template/Table';
import CreateNewShipment from './components/create-shipment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Authentication from '../../../../services/Authentication.js';
import Loader from './../../../Global/TableLoader';
import Popup from './../../../Global/Popup/';

import {Services} from './../Services';

const auth = new Authentication();


// const rest = new restService();

let status = {
  1: <Button color="secondary" className="btn-oval">Draft</Button>,
  21: <Button color="primary" className="btn-oval">Booking Requested</Button>,
  22: <Button color="success" className="btn-oval">Booking Approved</Button>,
  23: <Button color="warning" className="btn-oval">Booking Rejected</Button>,
  25: <Button color="primary" className="btn-oval">Booking On Board</Button>,
  24: <Button color="danger" className="btn-oval">Booking Cancelled</Button>,
  2: <Button color="inverse" className="btn-oval">Open</Button>
};

class Booking extends Component {
  constructor(props){
    super(props);
    this.state = {
      bookings: [],
      data: [],
      modal: false,
      loading: true,
      columns: [
        { label: 'Transport', key: 'TransportModeId', minWidth: 80, custom: (value) => this.customTranport(value) },
        { label: 'Booking No', key: 'BookingNumber', minWidth: 80 },
        { label: 'Status', key: 'StatusDesc', minWidth: 100, custom: (value) => <Button color="primary" className="btn-oval">{value.StatusDesc}</Button>},
        { label: 'Supplier', key: 'Supplier', minWidth: 100 },
        { label: 'Consignee', key: 'Consignee', minWidth: 100 },
        { label: 'Transport Mode', key: 'TransportMode', minWidth: 80 },
        { label: 'Origin', key: 'Origin', minWidth: 80 },
        { label: 'Destination', key: 'Destination', minWidth: 80 },
        { label: 'ETD', key: 'ETD', minWidth: 80 },
        { label: 'ETA', key: 'ETA', minWidth: 80 },
        { label: 'POs', key: 'POs', minWidth: 80, custom: (value) => (<div><Popup title='POs' content={value.POs}><p>{value.POs}</p></Popup></div>)},
        // { label: 'Status', key: 'StatusId', custom: (value) => this.customStatusId(value) },
        // { label: 'Action', key: 'BookingNumber', custom: (value) => this.customBookingNumber(value) },
      ],
      BookingSelected : {}
    };
  }

    customTranport = (value) => (
      value.TransportModeId === 1 || value.TransportModeId === 2
        ? <em className="fa-2x fas fa-ship" style={{ color: '#656565' }}></em>
        : <em className="fa-2x fas fa-plane" style={{ color: '#656565' }}></em>
    )

    customStatusId = (value) => (
      <span>{status[value.StatusId] ? status[value.StatusId] : ''}</span>
    )
    customBookingNumber = (value) => {        
      return (
      // <Button color="success" className="btn-oval" onClick={this.selectBooking(value)}>Convert To Shipment</Button>
        value.StatusId === 22 ?<Button color="success" className="btn-oval" onClick={this.selectBooking(value)}>Convert To Shipment</Button> : ''
      );
    }

    getBookingList = () => {
      let id  = auth.getAuthentication('customerSelect');
      Axios.get(`${Services.BOOKING.path}search/?id=${id}`).then((success) => {
        this.setState({ bookings: success.data.data, loading: false });
      }).catch(() => {
        this.setState({loading: false});
      });
    } 
    componentDidMount = () => this.getBookingList()

    selectBooking = BookingSelected => event =>{
      this.setState(prevState => ({
        modal: !prevState.modal,
        BookingSelected
      }));
    }
    toggle = value => evt => this.setState(prevState => ({
      modal: !prevState.modal
    }));
    callback = ()=>{
      this.setState(prevState => ({
        modal: !prevState.modal
      }),()=>{
        swal('Success','Shipment Has been Created','success').then(()=>{
          this.props.history.push('/shipment-sumary');
        });
      });
    }

    onError = ()=>{
      this.setState(prevState => ({
        modal: !prevState.modal
      }),()=>{
        swal('Error','Internal Error','error');
      });
    }

    render() {
      let { columns, bookings, loading} = this.state;
      return (
        <ContentWrapper>
          <div className="content-heading">
            <div className="row">
              <em className="fas fa-list-alt fa-1x mr-2"></em>
                        Booking List
            </div>
          </div>
          <Card className="myCard">
            <CardBody>
              {loading ? 
                <Loader/>:
                <Table
                  columns={columns}
                  data={bookings}
                  itemsByPage={10}
                  name="BookingList"
                  modalName="ui Modal body booking list"
                  customer={this.props._customer}
                  options={
                    {
                      pagination: true,
                      search: true,
                      selectItems: false,
                      modal: true
                    }
                  }
                />
              }
            </CardBody>
          </Card>
               
          <Modal isOpen={this.state.modal} toggle={this.toggle()} >
            <ModalHeader toggle={this.toggle()}>Create new shipment</ModalHeader>
            <ModalBody>
              <CreateNewShipment 
                Booking = {this.state.BookingSelected} 
                callback={this.callback}
                onError = {this.onError}
              />
            </ModalBody>
          </Modal>
        </ContentWrapper>
      );
    }
}
export default withRouter(Booking);