import React, {Component} from "react";
import Datetime from "react-datetime";
import Select from "react-virtualized-select";
import { Col, Row, Input, Label, FormGroup, Container } from "reactstrap";
import { Icon, Grid } from "semantic-ui-react";
import filterIcon from "../../../../resources/filter.png";
import FormModel from "../models/models";

export class ShipmentForm extends Component {

  state = {
    order: Object.keys(this.props.orderExist).length === 0 ? {} : this.props.orderExist,
    valueNames:[],
  }

  handleChange = selected => event => {
    let { order } = this.state;
    if (event && event.value) {
      order[selected] = event.value;
      this.props.onResults(order)
      this.setState({ order });
    } else {
      order[selected] = "";
      this.setState({ order });
    }
  };

  handeInputData = selected => event => {
    let { order } = this.state;
    if (event.target.value) {
      order[selected] = event.target.value;
      this.props.onResults(order)
      this.setState({ order });
    } else {
      order[selected] = "";
      this.setState({ order });
    }
  };

  handleChangeDates = name => event => {
    let order = this.state.order;
    let valueNames =  this.state.valueNames
    try {
      order[name] = event.format("YYYY-MM-DD");
      valueNames[name] = name
      this.props.onResults(order)
      this.setState({ order, valueNames }, () => {
        this.validateInputTime()
      });
    } catch (ex) {
      order[name] = "";
      this.setState({ order });
    }

    /* ======================================= VALIDATE INPUTS DATE SHIPMENTS ========================================= */
    if(!order['shipmentEnd'] && this.state.val === false ) {this.setState({id: 'shipmentEnd', val: true, isValidDate: false })}
    if(!order['etaEnd'] && this.state.val2 === false ) {this.setState({id2: 'etaEnd', val2: true, isValidDate2: false })}
    if(!order['etdEnd'] && this.state.val3 === false ) {this.setState({id3: 'etdEnd', val3: true, isValidDate3: false })}

    if(!order['shipmentStart'] && order['shipmentEnd'] && this.state.val === false ) {this.setState({id: 'shipmentEnd', val: true, isValidDate: false })}
    if(!order['etaStart'] && order['etaEnd'] && this.state.val2 === false ) {this.setState({id2: 'etaEnd', val2: true, isValidDate2: false })}
    if(!order['etdStart'] && order['etdEnd'] && this.state.val3 === false ) {this.setState({id3: 'etdEnd', val3: true, isValidDate3: false })}
 
  };

  validateInputTime = (event) =>{
    const {order } = this.state

    FormModel[2]._subSectionContent.map( h => {
      h.input.map( j => {
        /* ================================= SHIPMENTS VALIDATE DATE =============================================== */
        if(order['shimpmentStart']){
          if(j.id === 'shipmentEnd' && order['shipmentEnd'] ){
            const validCurrentTime  = order['shipmentEnd'] >= order['shimpmentStart']  
            this.setState({id: 'shipmentEnd', val: validCurrentTime, isValidDate: !validCurrentTime})
          }
        }
        if(order['etaStart']){
          if(j.id === 'etaEnd' && order['etaEnd']){
            const validCurrentTime  = order['etaEnd'] >= order['etaStart']
            this.setState({id2: 'etaEnd', val2: validCurrentTime, isValidDate2: !validCurrentTime })
          }
        }
        if(order['etdStart']){
          if(j.id === "etdEnd" && order['etdEnd']){
            const validCurrentTime  = order['etdEnd'] >= order['etdStart']
            this.setState({id3: 'etdEnd', val3: validCurrentTime, isValidDate3: !validCurrentTime })
          }
        }
        return j;
      })
      return h;
    })
  }

  removeFilters = () => {
    this.props.onResults(this.state.order = {})
    this.setState({ order: {}, 
      id: false, id2: false, id3: false, isValidDate: false, isValidDate2: false, isValidDate3: false
    });
  };
  
  render(){
    const { selects } = this.props;
    const shipmentLayout = FormModel[2];
    const { order } = this.state;
    
    return (
      <Grid celled="internally">
        <Grid.Row>
          <Grid.Column>
            <Grid.Row>
              {shipmentLayout._section.map((e, i) => {
                if (e.type === "Label")
                  return (
                    <Col xs={e.size} key={i}>
                      <center>
                        <h4 className="mb-3 py-2">{e.label}</h4>
                      </center>
                    </Col>
                  );
                else if (e.type === "Icon")
                  return (
                    <Col xs={e.size} key={i}>
                      <Icon.Group size="large" className="alignright">
                        <img
                          alt="ui-filter"
                          className="ui filter body"
                          src={filterIcon}
                          onClick={this.removeFilters}
                        />
                        <Icon
                          corner
                          name="cancel" onClick={this.removeFilters}
                        />
                      </Icon.Group>
                    </Col>
                  );
                else return '';
              })}
            </Grid.Row>
            <Grid.Row>
              {shipmentLayout._sectionContent.map((e, i) => {
                if (e.type === "Select")
                  return (
                    <div
                      className={e.class}
                      key={i}
                    >
                      <Label>{e.label}</Label>
                      <Select
                        id={e.name}
                        type={e.type}
                        name={e.name}
                        placeholder=""
                        value={order[e.name]}
                        onChange={this.handleChange(e.name)}
                        options={selects[e.options] }
                      />
                      <Label />
                    </div>
                  );
                else if (e.type === "Input")
                  return (
                    <div className={e.class} key={i}>
                      <Label>{e.label}</Label>
                      <Input
                        id={e.name}
                        name={e.name}
                        value={order[e.name] ? order[e.name] : ""}
                        onChange={this.handeInputData(e.name)}
                      />
                      <Label />
                    </div>
                  );
                else return '';
              })}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Grid.Row>
              <Col key='shipments-date'>
                <center>
                  <h4 className="mb-3 py-2">{shipmentLayout._subSection[0].label}</h4>
                </center>
              </Col>
            </Grid.Row>
  
            <Grid.Row>
              {shipmentLayout._subSectionContent.map((e, i) => {
                if (e.type === "Datetime") {
                  return (
                    <Col className={"col-md-4"} key={i}>
                      <Label
                        style={{ display: "block", textAlign: "center" }}
                        id={i}
                      >
                        {e.label}
                      </Label>
                      <Row>
                        {e.input.map((h, j) => {
                          if (e.type === "Datetime")
                            return (
                              <Col xs={h.size} key={j}>
                                <Datetime
                                  id={h.id}
                                  type={e.type}
                                  name={h.id}
                                  value={order[h.id]}
                                  dateFormat={"YYYY-MM-DD"}
                                  onChange={this.handleChangeDates(h.id)}
                                />
                                <span
                                  id={h.id}
                                  style={{ color: "rgb(229, 115, 115)" }}
                                >
                                  <Label>
                                    {this.state.id === h.id
                                      ? this.state.val
                                        ? ""
                                        : "invalid range"
                                      : "" || this.state.id2 === h.id
                                      ? this.state.val2
                                        ? ""
                                        : "invalid range"
                                      : "" || this.state.id3 === h.id
                                      ? this.state.val3
                                        ? ""
                                        : "invalid range"
                                      : ""}
                                  </Label>
                                </span>
                              </Col>
                            );
                          else return '';
                        })}
                      </Row>
                    </Col>
                  );
                } else return '';
              })}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Container>
            <Label />
            <Row>
              <div className="col-12">
                <center>
                  <h4 className="mb-3 py-2">Container Information</h4>
                </center>
              </div>
            </Row>
            <Row>
              <div className="col-4" />
              <div className="col-4">
                <FormGroup>
                  <Label
                    style={{ display: "block", textAlign: "center" }}
                    for="myLabel"
                  >
                    Container Number
                  </Label>
                  <Input
                    name="containerNumber"
                    id="containerNumber"
                    value={
                      order["containerNumber"] ? order["containerNumber"] : ""
                    }
                    onChange={this.handeInputData("containerNumber")}
                  />
                </FormGroup>
              </div>
              <div className="col-4" />
            </Row>
          </Container>
        </Grid.Row>
      </Grid>
    );
  }
};
