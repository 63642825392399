import React, { useEffect, useState } from "react";
import { useCatalogs } from "../general/hooks";
import { Input } from "./Input";

export default ({ fields, values, lng, updateEntityInfo }) => {
  const {
    incotermCatalog,
    uomCatalog,
    transportModeCatalog,
    uomCatalogD,
    uomCatalogO,
    uomCatalogQ,
    uomCatalogT,
    uomCatalogV,
    uomCatalogW,
  } = useCatalogs(lng);
  const [poInfo, setPoInfo] = useState(values);
  const [isOpen, setIsOpen] = useState({});

  useEffect(() => {
    setPoInfo(values);
  }, [values]);

  const handleChange = (id, value) => {
    let newPoInfo = { ...poInfo };
    newPoInfo[id] = value;
    updateEntityInfo(id, value);
    setPoInfo(newPoInfo);
  };

  const handleCheckbox = (id) => {
    let newPoInfo = { ...poInfo };
    newPoInfo[id] = Number(!Boolean(newPoInfo[id]));
    handleChange(id, newPoInfo[id]);
  };

  const findCatalog = (catalogName) => {
    switch (catalogName) {
      case "incotermCatalog":
        return incotermCatalog;
      case "uomCatalog":
        return uomCatalog;
      case "transportModeCatalog":
        return transportModeCatalog;
      case "uomCatalogO":
        return uomCatalogO;
      case "uomCatalogV":
        return uomCatalogV;
      case "uomCatalogT":
        return uomCatalogT;
      case "uomCatalogW":
        return uomCatalogW;
      case "uomCatalogQ":
        return uomCatalogQ;
      case "uomCatalogD":
        return uomCatalogD;
      default:
        return [];
    }
  };

  const findInCatalog = (values, id, catalogName) => {
    let catalog = findCatalog(catalogName);
    let value = catalog.find((item) => {
      return item.value === values[id] || item.label === values[id];
    });
    if (value && value.value !== poInfo[id]) {
      handleChange(id, value.value);
    }
    return value || "";
  };

  const handleOpen = (id, open) => {
    let newIsOpen = { ...isOpen };
    newIsOpen[id] = open;
    setIsOpen(newIsOpen);
  };

  return fields.map((field, index) => (
    <div
      className={field.class}
      style={{ paddingTop: "1%" }}
      key={`${field.id}-${index}-edit`}
    >
      <Input
        field={field}
        values={poInfo}
        handleChange={handleChange}
        handleCheckbox={handleCheckbox}
        handleOpen={handleOpen}
        findCatalog={findCatalog}
        findInCatalog={findInCatalog}
        lng={lng}
        isOpen={isOpen}
      />
    </div>
  ));
};
