import React, { Component } from 'react';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { Progress, Button } from 'reactstrap';
import { selectedPlace } from '../../models';
import {  Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Maps from '../map';
import cruise from './../../../../../../resources/icons/cruise.png';
import './styles/drawer.scss';

const status_ = {
  1: <em className="fa-1x fas fa-ship mr-2"></em>,
  2: <em className="fa-1x fas fa-ship mr-2"></em>,
  3: <em className="fa-1x fas fa-plane mr-2"></em>,
  4: <em className="fa-1x fas fa-plane mr-2"></em>,
  5: <em className="fa-1x fas fa-plane mr-2"></em>,
  6: <em className="fa-1x fas fa-plane mr-2"></em>,
  7: <em className="fa-1x fas fa-plane mr-2"></em>
};

class DrawerMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      visible: this.props.visible ? this.props.visible : false,
      toggleDrawer: this.props.toggleDrawer,
      dataDrawer: selectedPlace,
      polyline: this.props.polyline,
    };
  }

    toggleDrawerOut = () => {
      let { visible } = this.state;
      this.setState({ visible: !visible });
    };

    detail = id => event => {
      this.props.history.push('/tracking/'+id);
    }

    componentDidMount = () => {
      this.setState({
        data: this.props.data
      });
    }

    componentWillReceiveProps = (props) => {
      this.setState({
        visible: props.visible,
        dataDrawer: props.dataDrawer,
        polyline: props.polyline,
        data: props.data
      });
    };

    render() {
      let { visible, dataDrawer } = this.state;
      return (
        <div className="myCard">
          <Sidebar.Pushable as={Segment}>
            <Sidebar
              as={Menu}
              animation='overlay'
              icon='labeled'
              vertical
              visible={visible}
              width='wide'
              direction={'right'}
            >
              <Menu.Item as='a' onClick={this.toggleDrawerOut}> Close </Menu.Item>
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-6">
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-hover mb-mails">
                            <tbody>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="mb-mail-meta">
                                      {status_[dataDrawer.TransportMode ? dataDrawer.TransportMode.id : 1]}
                                      <span>
                                        {dataDrawer.Status ? dataDrawer.Status.label : ''}
                                      </span>
                                      <br/>
                                      <Link to={'/tracking/' + dataDrawer.id} style={{ textDecoration: 'none' }}>
                                        <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                          {dataDrawer.BookingNumber}
                                        </span>
                                      </Link>-
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.id}
                                      </span>
                                      <br />
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.PONumber}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <em style={{ color: '#0097dc', paddingTop: '5%' }} className="fas fa-warehouse mr-3"></em>
                                    <div className="mb-mail-meta" >
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                                            Cargo Ready: {dataDrawer.CargoReady}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <em style={{ color: '#0097dc', paddingTop: '8%' }} className="fas fa-truck-moving mr-3"></em>
                                    <div className="mb-mail-meta">
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Supplier ? dataDrawer.Supplier.name : ''}
                                      </span>
                                      <br />
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Supplier ? dataDrawer.Supplier.address : ''}
                                      </span>
                                      <br />
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Supplier ? dataDrawer.Supplier.pickupDate : ''}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-down"></em>
                                    <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-down mr-3"></em>
                                    <div className="mb-mail-meta">
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {`ETD: ${dataDrawer.ETD}`}
                                      </span>
                                      <br />
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {`ATD: ${dataDrawer.ATD}`}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-up"></em>
                                    <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-long-arrow-alt-up mr-3"></em>
                                    <div className="mb-mail-meta">
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {`ETA: ${dataDrawer.ETA}`}
                                      </span>
                                      <br></br>
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {`ATA: ${dataDrawer.ATA}`}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <em style={{ color: '#0097dc', paddingTop: '10%' }} className="fas fa-truck mr-2"></em>
                                    <div className="mb-mail-meta">

                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Delivery ? dataDrawer.Delivery.name : ''}
                                      </span>
                                      <br></br>

                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Delivery ? dataDrawer.Delivery.address : ''}
                                      </span>
                                      <br></br>

                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Delivery ? dataDrawer.Delivery.pickupDate : ''}
                                      </span>
                                      <br></br>

                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                                            Actual Delivery:
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-hover mb-mails">
                            <tbody>
                              <tr>
                                <td >
                                  <div className="d-flex">
                                    <div className="mb-mail-meta">
                                      {<span style={{ color: 'green' }}>On Time</span>}
                                      <div className="mb-mail-subject">
                                        <Progress color={'success'} value={dataDrawer.Progress} />
                                      </div>

                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {/* {`Last Update: ${dataDrawer.LastUpdate ? dataDrawer.LastUpdate.date : ''}`} */}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="mb-mail-meta">
                                      <em className="fas fa-map-marker-alt mr-2"></em>
                                    </div>
                                    <div className="mb-mail-meta">
                                      <div className="mb-mail-from">
                                        <span style={{ fontWeight: 'bold', fontSize: '12px' }}>From</span>
                                      </div>
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.From && dataDrawer.From.Location && dataDrawer.From.Location.country ? dataDrawer.From.Location.country : ''}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="mb-mail-meta">
                                      <em className="fas fa-map-marker-alt mr-2"></em>
                                    </div>
                                    <div className="mb-mail-meta">
                                      <div className="mb-mail-from">
                                        <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                                            To
                                        </span>
                                      </div>
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.To && dataDrawer.To.Location && dataDrawer.To.Location.country ? dataDrawer.To.Location.country : ''}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="mb-mail-meta">
                                      <em className="icon-compass mr-2" style={{paddingTop: '10%' }}></em>
                                    </div>
                                    <div className="mb-mail-meta">
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Carrier ? dataDrawer.Carrier.VesselOrFlight : ''}
                                      </span>
                                      <br></br>
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Carrier ? dataDrawer.Carrier.Latitude : ''}
                                      </span>
                                      <br></br>
                                      <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        {dataDrawer.Carrier ? dataDrawer.Carrier.Longitude : ''}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="mb-mail-meta">
                                      <img src={cruise} width="19" height="19" className="mr-2" ></img>
                                    </div>
                                    <div className="mb-mail-meta" style={{ paddingTop: '7%' }}>
                                      <div className="mb-mail-from">
                                        <span>{dataDrawer.Project ? dataDrawer.Project.name : ''}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <center>
                  <Button color="primary" className="btn-oval btn_redirect" onClick={this.detail(dataDrawer.id)}>View Details</Button>
                </center>
              </Card>
            </Sidebar>

            <Sidebar.Pusher className="myCard">
              <Segment basic>
                <Maps _redirect={this.redirectTo} toggleDrawer={this.state.toggleDrawer} polyline={this.state.polyline} data={this.state.data}></Maps>
              </Segment>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      );
    }

};
export default withRouter(DrawerMap);