import React from 'react';

const CardComponent = (props) => {
  return (
    <div class="card shadow-sm p-1 mb-2 mr-2 bg-white rounded">
      <div class="card-body">
        {props.children}
        {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
        {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
      </div>
    </div>
  );
};

export default CardComponent;