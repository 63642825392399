import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import { Input } from 'reactstrap';
import Select from 'react-select';

// ? Material
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import 'react-toastify/dist/ReactToastify.css';
import Form from '../../../../../../models/FormData';
import restService from '../../../../../../services/restService';
import Errors from '../../../../../template/Errors';


import ValidationService from '../../../../../../validation';

const validation = new ValidationService();
const rest = new restService();
class POLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
      errors: {},
      POLine: {
        ItemDescription: '',
        ItemNumber: '',
        OriginCountryId: '',
        QtyOrdered: '',
        UnitMeasure: '',
      },
      PONumber: this.props.PONumber,
      selects: { INCOTERMS: [], PRODUCTS: [], 'UNITS-MEASURE': [], CURRENCIES: [], TRANSPORTS: [], COUNTRIES: [], CUSTOMERS_DIVISIONS: [] },
      Form: Form.CreatePOLine,
      CreatePOLineForm: [],
      items: [],
      error: {
        open: false,
        message: '',
        type: 'error'
      }
    };
  }
    handleChange = name => event => {
      if((event.target.value+'').length >= 11 && name === 'QtyOrdered'){}
      else{
        let POLine = this.state.POLine;
        POLine[name] = event.target.value;
        this.setState({ POLine }, () => this.buildFormPOLine());
      }
    }
    handleChangeSelect = name => event => {
      let POLine = this.state.POLine;
      if ( event && event.value){
        POLine[name] = event.value;
        this.setState({ POLine }, () => { this.buildFormPOLine(); });
      }
      else {
        POLine[name] = '';
        this.setState({ POLine }, () => { this.buildFormPOLine(); });
      }
    }
    componentDidMount = () => {
      this.getAllCatalogs();
    }

   
    getAllCatalogs = () => {
      rest.EXEC({ _function: 'GetAllCatalogs', params: '' }, fetch).then(data => {
        this.setState({ selects: data.data }, () => this.buildFormPOLine());
      }).catch(error => { });
    }
    buildFormPOLine = () => {
      const { Form, POLine, selects } = this.state;
      let CreatePOLineForm = Form.map((e, i) => {
        if (e.type === 'Input') return (
          <div className={e.class} key={e.id}>
            <label id = {e.id} style={e.style}>{e.label}  *</label>
            <Input
              type={e.datatype}
              maxlength= {e.maxlength}
              minlength = {e.minlength}
              placeholder={e.placeHolder}
              value={POLine[e.id]}
              name={e.id}
              onChange={this.handleChange(e.id)}
            />
            <label style={e.style}>{e.error}</label>
          </div>
        );
        else if (e.type === 'Select') return (
          <div className={e.class} key={e.id}>
            <label id = {e.id} style={e.style}>{e.label} *</label>
            <Select
              name={e.name}
              value={POLine[e.id]}
              onChange={this.handleChangeSelect(e.id)} options={selects[e.options]}
            />
            <label style={e.style}>{e.error}</label>
          </div>);
        else if (e.type === 'textarea') return (
          <div className={e.class} key={e.id}>
            <label id = {e.id} style={e.style}>{e.label} *</label>
            <Input
              id="Line Items"
              type="textarea"
              rows="1"
              className="form-control"
              value={POLine[e.id]}
              onChange={this.handleChange(e.id)}></Input>
            <label style={e.style}>{e.error}</label>
          </div>
        );
        else return '';
      });
      this.setState({ CreatePOLineForm });
    }

    addLine = () => {
      let { POLine,Form } = this.state;
      validation.validateObject({
        target : 'PO-LINE',
        data : POLine
      }).then(()=>{
        Form.forEach((e)=>{
          e.style={ color : '#616161'};
          e.error ='';
        });
        
        this.setState({
          POLine: {
            ItemDescription: '',
            ItemNumber: '',
            OriginCountryId: '',
            QtyOrdered: '',
            UnitMeasure: '',
            VendorCode: '',
            Collection: '',
            Style: '',
            Color: '',
            Size: ''
          },Form
        }, () => {
          this.buildFormPOLine();
          this.props.refreshItems(POLine);
          this.setState({
            error: {
              message: 'A line item has been added correctly',
              open: true,
              type: 'success'
            }
          });
        });
      }).catch(errors =>{
        let Element = document.getElementById(Object.keys(errors)[0]);
        if(Element){
          Element.focus();
        }
        Form.forEach((e)=>{
          if(errors[e.id]){
            e.style={ color : '#e57373' };
            e.error = errors[e.id].label;
          }
          else{
            e.style={ color : '#616161' };
            e.error = '';
          }
        });
        this.setState({
          Form
        },()=>{
          this.buildFormPOLine();
        });
      });
    }
    itemDeleted = () => this.setState({
      error: {
        message: 'Line Item Removed',
        open: true,
        type: 'error'
      }
    })

    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
      const { CreatePOLineForm } = this.state;
      return (
        <div className="row">
          {CreatePOLineForm}
          <div className="col-md-2" style={{ paddingTop: '40px' }}>
            <Fab color="primary" size="small" aria-label="add" onClick={this.addLine}>
              <AddIcon />
            </Fab>
          </div>
          <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
        </div>
      );
    }
}
export default POLines;