import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography';
import propTypes from 'prop-types';
import StepButton from '@material-ui/core/StepButton';


const classes = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));


/*=============================================
=            Vertical Linear Stteper          =
=============================================*/
function VerticalLinearStepper(props){
  const [steps] = React.useState(props.steps);


  return (
    <div className={classes.root}>
      <Stepper orientation="vertical" activeStep={true}>
        {steps.map(e => {
          const props = {};
          const buttonProps = {};
          buttonProps.optional = <Typography variant="caption">{e.value}</Typography>;

          return (
            <Step key={`${e.label}-${e.value}`} {...props} >
              <StepButton
                completed={e.completed}
                {...buttonProps}>
                {e.label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div> 
  );
}
VerticalLinearStepper.propTypes = {
  steps: propTypes.array
};

VerticalLinearStepper.defaultProps = {
  steps: []
};

export default VerticalLinearStepper;