class Authentication {
  test = () => {};
  setAuthentication = (token, dataUser, customerSelect) => {
    localStorage.setItem("token", token);
    localStorage.setItem("dataUser", dataUser[0].UserId);
    localStorage.setItem("UserName", dataUser[0].Name);
    localStorage.setItem("UserNameLast", dataUser[0].LastName);
    localStorage.setItem("Email", dataUser[0].Email);
    localStorage.setItem("RolId", dataUser[0].RolId);
    localStorage.setItem("DATAUSER", dataUser.toString());
    localStorage.setItem("customerSelect", customerSelect);
  };
  getAuthentication = (value) => {
    return localStorage.getItem(value);
  };

  deleteAuthentication = (value) => {
    localStorage.removeItem(value);
    localStorage.removeItem("design");
  };
}

export default Authentication;
