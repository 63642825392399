import React from 'react';
import { Button } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
function ChatBotButtons(props) {
  const { buttons, botSteps } = props;
  let history = useHistory();
  const defaultStyles = {
    fontSize: '11px',
  };

  const defaultClassName = props.size ? props.size : 'col-md-6';

  const buttonFunction = (nextStep) => {
    botSteps
      ? botSteps.triggerNextStep({
        value: nextStep.value,
        trigger: nextStep.trigger
      })
      : props.triggerNextStep({
        value: nextStep.value,
        trigger: nextStep.trigger
      });
    if (typeof nextStep.redirect !== 'undefined') {

      history.push({
        pathname: nextStep.redirect,

      });
    }
  };

  const createCols = () => {
    return buttons.map((item, element) =>
      element % 2 ? (
        <div className={defaultClassName}>
          <Button
            fluid
            compact
            size="tiny"
            style={defaultStyles}
            onClick={() => buttonFunction(item)}
          >
            {Array.isArray(item.label) ? item.label[0] : item.label}
          </Button>
          <div style={{ height: '10px' }}></div>
        </div>
      ) : (
          <div className={defaultClassName}>
            <Button
              fluid
              compact
              size="tiny"
              style={defaultStyles}
              onClick={() => buttonFunction(item)}
            >
              {Array.isArray(item.label) ? item.label[0] : item.label}
            </Button>
            <div style={{ height: '10px' }}></div>
          </div>
        )
    );
  };

  return (
    <div className="row" style={{ width: '100%' }}>
      {createCols()}
    </div>
  );
}

export default ChatBotButtons;
