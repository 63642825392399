import React, {useEffect, useState} from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core/";
import { Divider} from  "@mui/material";
import { CardBody } from "reactstrap";
import { Tab } from "semantic-ui-react";
import ListItemsTitle from "./listItemTitle";
import ItemSummary from "./itemSummary";
import ListItemEventTab from "./listItemEventTab";

const ListItemTab = ({
  poItems,
  handleExpand,
  handleEditItem,
  handleUpdateStatus,
  parcedPOItem,
  handleSendErrorMessage,
  updateItem,
  error,
  lng,
  rolId,
}) => {
  const [tabActive, setTabActive] = useState([]);

  useEffect(() => {
    setTabActive(poItems.map(() => 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (e, { activeIndex }, index) => {
    const newTabActive = [...tabActive];
    newTabActive[index] = activeIndex === 1 ? 1 : 0;
    setTabActive(newTabActive);
  };

  if (!poItems || error) return null;
  return (poItems
    .sort((a, b) => b.POItemsId - a.POItemsId)
    .map((item, index) => (
      <>
        <ExpansionPanel
          key={`${item.LineID}-${index}-exp`}
          elevation={0}
          defaultExpanded={false}
          expanded={item.expanded}
        >
          <ExpansionPanelSummary>
            <ListItemsTitle
              item={item}
              index={index}
              handleExpand={handleExpand}
              handleEditItem={handleEditItem}
              handleUpdateStatus={handleUpdateStatus}
              parcedPOItem={parcedPOItem}
              rolId={rolId}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <CardBody>
              <Tab
                menu={{
                  secondary: true,
                  className: "wrapped",
                  pointing: true,
                  style: { fontSize: "14px" },
                }}
                activeIndex={tabActive[index]}
                className={"label-euro"}
                menuPosition="right"
                onTabChange={(e, { activeIndex }) => handleTabChange(e, { activeIndex }, index)}
                panes={[
                  {
                    menuItem: "Summary",
                    render: () => (
                      <ItemSummary
                        handleSendErrorMessage={handleSendErrorMessage}
                        item={item}
                        index={index}
                        handleEditItem={handleEditItem}
                        update={updateItem}
                        isEdit={item.edit}
                        lng={lng}
                      />
                    ),
                  },
                  {
                    menuItem: "Events",
                    render: () => <ListItemEventTab item={item} />,
                  },
                ]}
              />
            </CardBody>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Divider key={`${item.LineID}-${index}-divider`} />
      </>
    )));
};

export default ListItemTab;
