export const Services = {
   "Get_Traceability_Imports": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Imports_ForId": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Location_Custom": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Export_ForId": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Doc_Export_ForId": {
      "path": "https://roldanapidocument.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Post_Preview_Doc_Export_ForId": {
      "path": "https://roldanapidocument.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Shipments": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Shipments_ForId": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_ContainerDetail": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Expo": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_MapsShipments": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Events_General": {
      "path": "https://roldanapiaevents.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Chars_StatusImpoExpo": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_list_integrated": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Integred_ForId": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Integred_Change": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Post_Integrada_Charts": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Post_IntegradaExpo_Charts": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_list_integrada_Expo": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   },
   "Get_Traceability_Integred_Expo_ForId": {
      "path": "https://roldanapitrazabilidad.azurewebsites.net",
      "method": "",
      "descrp": ""
   }
}