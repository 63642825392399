import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import MainMenu from './SecComponents/chatBotButtons';
import CurrentLabel from './SecComponents/currentLabel';
import RateService from './SecComponents/ranking';
import AnotherQuery from './SecComponents/anotherQuery';
import OtraConsulta from './SecComponents/realizarOtraConsulta';
/** Consultar estatus PO o Shipmment */
import POShipMenu from './POShipStatus/poShipMenu';
import PurchaseOptions from './POShipStatus/purchaseOrder';
import ShipmentOptions from './POShipStatus/shipment';
import ResultPOShipStatus from './POShipStatus/resultsPOShipStatus';

/** Consultar documento */
import DocumentMenu from './Document/documentMenu';

/** System support */
import SystemSupport from './SystemSupport/menu';

/** Quote Service */
import QuoteService from './QuoteService/quoteService';

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#000',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#13B0F0',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const mainMenu = [
  {
    label: 'PO / Shipment Status',
    trigger: 'currentLabel',
    value: ['PO / Shipment Status', 'menuPOShip']
  },
  {
    label: 'View Documents',
    trigger: 'currentLabel',
    value: ['View Documents', 'menuDocument']
  },
  {
    label: 'Quote a Service',
    trigger: 'quoteService',
    value: ['Quote a Service', 'quoteService'],
    redirect: "/quote-service"
  },

  {
    label: 'Customer Service Support',
    trigger: 'Welcome',
    value: 'currentLabel'
  },
  {
    label: 'System support',
    trigger: 'currentLabel',
    value: ['System support', 'systemSupport']
  },

  {
    label: 'Submit a claim',
    trigger: 'Welcome',
    value: 'currentLabel'
  },
];

const steps = [
  {
    id: 'Welcome',
    message:
      'Hello! How can i help you today?',
    trigger: 'MainMenu',
  },
  {
    id: 'MainMenu',
    component: <MainMenu buttons={mainMenu} />,
    waitAction: true,
  },
  {
    id: 'currentLabel',
    component: <CurrentLabel />,
  },
  /**
   * VER PO Estatus
   * */
  {
    id: 'menuPOShip',
    component: <POShipMenu />
  },
  {
    id: 'purchaseOrder',
    component: < PurchaseOptions />,
    asMessage: true,
    trigger: 'purchaseOrderSearch'

  }, {
    id: 'purchaseOrderSearch',
    user: true,
    trigger: 'poShipResult',
  },
  {
    id: 'poShipResult',
    component: <ResultPOShipStatus />,
    waitAction: true,
  },
  /**
   * VER Shipment Estatus
   * */
  {
    id: 'shipment',
    component: < ShipmentOptions />,
    asMessage: true,
    trigger: 'shipmentSearch'

  }, {
    id: 'shipmentSearch',
    user: true,
    trigger: 'poShipResult',
  },
  /**
   * Documents
   * */
  {
    id: 'menuDocument',
    component: <DocumentMenu />
  },
  /**
  * Documents PO
  * */
  {
    id: 'purchaseOrderDoc',
    component: < PurchaseOptions />,
    asMessage: true,
    trigger: 'purchaseOrderDocSearch'

  }, {
    id: 'purchaseOrderDocSearch',
    user: true,
    trigger: 'poShipResult',
  },
  /**
  * Documents Shipment
  * */
  {
    id: 'shipmentDoc',
    component: < ShipmentOptions />,
    asMessage: true,
    trigger: 'shipmentDocSearch'

  }, {
    id: 'shipmentDocSearch',
    user: true,
    trigger: 'poShipResult',
  },

  /**
   * SystemSupport
   * */
  {
    id: 'systemSupport',
    component: <SystemSupport />
  },
  /**
   * Quote Service
   * */
  {
    id: 'quoteService',
    component: <QuoteService />,
    trigger: 'anotherRequest'
  },


  /**
   * Common
   * */
  {
    id: 'anotherRequest',
    component: <OtraConsulta />,
    asMessage: true
  },
  {
    id: 'anotherQuery',
    component: <AnotherQuery />
  }, {
    id: 'Ranking',

    component: <RateService />,
  }, {
    id: 'prevRanking',
    message: 'Please help us evaluating the service. Did we solve your problem?',
    trigger: 'Ranking'
  },
  {
    id: 'Farewell',
    message:
      'We appreciate you using MagicDome ChatBot see you soon!',
    trigger: 'Welcome',
  }, {
    id: 'genericSolution',
    message:
      'To enter from the mobile application it is necessary to use the same username and password that you use in the web version. It is important to confirm that the version of MagicDome is the most recent, this can be confirmed directly from the app store of your device',
    trigger: 'anotherRequest'
  }

];

function ChatTemplate() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <ChatBot
          headerTitle={'Chat Bot'}
          steps={steps}
          floating={true}
          floatingIcon={
            <img
              src="https://fsposeidon.blob.core.windows.net/magicdome/catbig.png"
              width={'65px'}
              alt="magicdome chatbot"
            />
          }
          botAvatar="https://fsposeidon.blob.core.windows.net/magicdome/mingato.png"
          floatingStyle={{ width: '40px', height: '40px' }}
          width={'400px'}
          placeholder={'Write your message'}
        />
      </ThemeProvider>
    </div>
  );
}

export default ChatTemplate;
