import React, { Component } from 'react';
import { Row, Label} from 'reactstrap';
import QuickCard from './../../../../Global/CardItems';
import { Pagination } from 'semantic-ui-react';
import {configOrder, configBooking, configShipment} from './config';

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activePageorder: 0,
      activePageBooking: 0,
      activePageShipment:0,
      totalPagesOrder: 0,
      totalPagesBooking: 0,
      totalPageShipment: 0
    };
  };

    componentWillReceiveProps = (nextProps) => {
      if (nextProps.data) this.setState({ data: nextProps.data, dataPage: nextProps.data, allData: nextProps.data }, () => { this.initPages(); });
    }

    initPages = ()=>{
      const {dataPage} = this.state;

      let pagOrder = Math.ceil(parseInt(dataPage.Order.length) / 10);
      let pagBooking = Math.ceil(parseInt(dataPage.Booking.length) / 10);
      let pagShipment = Math.ceil(parseInt(dataPage.Shipment.length) / 10);
      const totalOrders = dataPage.Order.length;
      const totalBookings = dataPage.Booking.length;
      const totalShipments = dataPage.Shipment.length;

      this.setState({
        activePageorder: 1,
        activePageBooking: 1,
        activePageShipment: 1, 
        totalPagesOrder: pagOrder,
        totalPagesBooking: pagBooking,
        totalPageShipment: pagShipment,
        totalOrders,  totalBookings, totalShipments
      },()=>{
        this.buildPageResults();
      }); 
    }

    buildPageResults = ()=>{
      let { activePageShipment, activePageorder, activePageBooking} = this.state;
        
      let dataOrder = this.setResultsByPage(activePageorder, this.state.dataPage.Order);
      let dataBooking = this.setResultsByPage(activePageBooking, this.state.dataPage.Booking);
      let dataShipments = this.setResultsByPage(activePageShipment, this.state.dataPage.Shipment);
       
      this.setState({
        dataOrder,
        dataBooking,
        dataShipments
      },()=>{
        this.buildCards();
      });

    }

    setResultsByPage = (page, all) => {
      page = page - 1;
      const pag = all.slice( (page) * 10 , (page + 1) *  10);
      return pag;
    }

    changePage = (page, from) => {
       
      if(from === 'order'){
        this.setState({
          activePageorder: page,
        },()=>{
          this.buildPageResults();
        });
      }
      if(from === 'booking'){
        this.setState({
          activePageBooking: page,
        },()=>{
          this.buildPageResults();
        });
      }
      if(from === 'shipment'){
        this.setState({
          activePageShipment: page,
        },()=>{
          this.buildPageResults();
        });
      }
        
    }

    buildCards = () => {
      const {dataOrder, dataBooking, dataShipments } = this.state;
      /* ========================ORDERS================================================================*/
      let tags = dataOrder.map((e, index) => {
        return (
          <div className="col-12" key={index}>
            <QuickCard element={e} config={configOrder}></QuickCard>
          </div>
        );
      });
      this.setState({
        cards: ''
      }, () => this.setState({ cards: tags }));
    

      /* ========================BOOKINGS================================================================*/
      let tagsBooking = dataBooking.map((e, index) => {
        return (
          <div className="col-12" key={index}>
            <QuickCard element={e} config={configBooking}></QuickCard>
          </div>
        );
      });
      this.setState({
        cardsBooking: ''
      }, () => this.setState({ cardsBooking: tagsBooking }));


      /* =======================SHIPMENTS================================================================ */
      let tagsShipments = dataShipments.map((e, index) => {
        return (
          <div className="col-12" key={index}>
            <QuickCard element={e} config={configShipment}></QuickCard>
          </div>
        );
      });
      this.setState({
        cardsShipments: ''
      }, () => this.setState({ cardsShipments: tagsShipments }));
    }


    render() {
      let { activePageShipment, activePageBooking, activePageorder, totalOrders, totalPagesOrder,  totalBookings, totalPagesBooking,  totalShipments, totalPageShipment } = this.state;
      return (
        <Row>
                
          <div className="col-12">
            <h4>Purchase Orders</h4>
            <Label>{(activePageorder * 10) > totalOrders ? totalOrders : activePageorder * 10} of {totalOrders} results</Label>
            <div className="col-12">
              {this.state.cards}   
            </div> 
            {(totalOrders !== 0 ) ?
              <div className="col-12">
                <center>
                  <Pagination
                    activePage = {activePageorder}
                    ellipsisItem={null}
                    totalPages={totalPagesOrder}
                    onPageChange = {(e, { activePage }) =>this.changePage(activePage, 'order')}/>
                </center>
              </div> : '' 
            }
            <br/>                              
          </div>

          <div className="col-12">
            <h4>Booking</h4>
            <Label>{(activePageBooking * 10) > totalBookings ? totalBookings : activePageBooking * 10} of {totalBookings} results</Label>
            <div className="col-12">
              {this.state.cardsBooking}   
            </div> 
            {(totalBookings !== 0 ?
              <div className="col-12">
                <center>
                  <Pagination
                    activePage = {activePageBooking}
                    ellipsisItem={null}
                    totalPages={totalPagesBooking}
                    onPageChange = {(e, { activePage }) =>this.changePage(activePage, 'booking')}/>
                </center>
              </div> : ''
            )}
            <br/>                              
          </div> 

          <div className="col-12">
            <h4>Shipment</h4>
            <Label>{(activePageShipment * 10) > totalShipments ? totalShipments : activePageShipment * 10} of {totalShipments} results</Label>
            <div className="col-12">
              {this.state.cardsShipments}   
            </div> 
            {(totalShipments !==0 ?
              <div className="col-12">
                <center>
                  <Pagination
                    activePage = {activePageShipment}
                    ellipsisItem={null}
                    totalPages={totalPageShipment}
                    onPageChange = {(e, { activePage }) =>this.changePage(activePage, 'shipment')}/>
                </center>
              </div> : ''
            )} 
            <br/>                              
          </div>  
                
        </Row>
      );
    }
}
export default Cards;


