import React, { Component } from 'react';
import RestService from '../../../../../../../services/restService'
import { Card, CardBody } from 'reactstrap';
import FormModel from './models/form'
import Fade from 'react-reveal/Fade';
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";

const rest = new RestService()



class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Form: FormModel,
            ROUTES: '',
            CONTAINER: '',
            selects: {
                CurrentPortLoad: [],
                DestinationPort: [],
                MoveType: [],
                EquipSize: [],
                EquipType: []
            },
            Filter: {},
            Inputs: ''
        }
    }


    componentDidMount = () => {
        this.getCatalogs()
    }


    componentWillReceiveProps = (nextProps) => {
        this.getCatalogs()
    }


    getCatalogs = () => {
        rest.EXEC({
            _function: 'RatesOceanFreightFilters',
            params: ''
        }, fetch).then(success => {
            if (success.data) {
                this.setState({
                    selects: success.data
                }, () => {
                    this.buildForm('ROUTES')
                    this.buildForm('CONTAINER')
                })
            }
        })
    }
    handleChangeSelect = (selected) => event => {
        let { Filter } = this.state;
        if (event && event.value) {
            Filter[selected] = event.value;
            this.setState({ Filter }, () => {
                this.props.sendQuery(this.state.Filter)
                this.buildForm('ROUTES')
                this.buildForm('CONTAINER')
            })
        } else {
            Filter[selected] = '';
            this.setState({ Filter }, () => {
                this.props.sendQuery(this.state.Filter)
                this.buildForm('ROUTES')
                this.buildForm('CONTAINER')
            })
        }
    };



    buildForm = (key) => {
        const { Filter, selects, Form } = this.state
        let Inputs = Form[key].map((e, i) => {
            if (e.type === "Select" && e.show) return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required == true ? <span>*</span> : ""} </label></span></Fade>
                    <Select
                        id={e.id}
                        required
                        type={e.datatype}
                        name={e.name}
                        value={Filter[e.id]}
                        onChange={this.handleChangeSelect(e.id)}
                        options={selects[e.options]}
                    />
                    <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
                </div>)
        })
        this.setState({
            [key]: Inputs
        })
    }


    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <legend>Route</legend>
                    </div>
                    {this.state.ROUTES}
                </div>
                <div className="row">
                    <div className="col-12">
                        <legend>Container</legend>
                    </div>
                    {this.state.CONTAINER}
                </div>
            </>
        );
    }
}

export default Filters;


