import React from 'react';
import Paper from '@material-ui/core/Paper';
import ContentWrapper from '../Components/ContenWraper/ContentWrapper';
import SVGLoader from './svg/Spin.svg';

import './styles.scss';
// import { Button, Label } from 'bootstrap';

function Loader({ hidden, projectId }) {
	// return TypeLoader(hidden)
	// console.log('hidden--->', hidden);
	// console.log('projectId--->', projectId);

	// let project = 2
	switch (projectId) {
		case 1:
			// console.log('entro al loader de Poseidon');
			return (
				<Paper elevation={0} className="__loader_container" hidden={hidden}>
					<div className="col-md-12 __loader_">
						<div className="abs-center wd-sd">
							<div className="text-center wd-xl">
								<svg version="1.1" width="85px" height="85px" id="Poseidon_Spinner" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
									viewBox="0 0 85 85">
									<g>
										<path className="stPoseidon" d="M60.7,45.5v-9.6l2.5,0.5l-4.5-8.3l-4.3,8.3l2.3-0.5v9.7c0,0,0,4.6-3.6,8.3c-2.4,2.4-6,5.5-8.7,7.6v-3.3V27.8
                l2.4,0.5L42.6,20l-4.5,8.3l2.4-0.5v30.4v3.3c-2.6-2.1-6.2-5.2-8.7-7.6c-3.6-3.7-3.6-8.3-3.6-8.3v-9.6l2.3,0.5l-4.3-8.3l-4.5,8.3
                l2.5-0.5v9.6c0,0.2-0.1,6.2,4.7,11c3.6,3.6,9.5,8.3,11.5,10v4v3.7v0.7c0.5,0.3,0.9,0.5,1,0.6c0.3,0.2,0.6,0.3,1,0.3
                c0.3,0,0.7-0.1,1-0.3c0.1,0,0.4-0.2,1-0.6v-0.7v-3.7v-4c2.1-1.6,8-6.4,11.5-10C60.8,51.7,60.7,45.8,60.7,45.5z"/>
										<path className="stPoseidon" d="M72,47.8V27.5c0-0.5-0.2-0.9-0.5-1.2c-1.2-0.1-3-0.9-4.5-1.5c-0.7-0.3-1.4-0.6-2.2-1.1
                c-0.2-0.1-0.3-0.2-0.3-0.2l0,0c-6-3.1-14.6-9.2-20.7-13.9c-0.7-0.5-1.8-0.5-2.5,0c-6.1,4.7-14.7,10.8-20.7,13.9l0,0
                c0,0,0,0-0.1,0.1c-0.9,0.5-1.8,0.9-2.5,1.2c-1.5,0.7-3.3,1.4-4.4,1.5c-0.3,0.3-0.5,0.8-0.5,1.2l0,20.3c0,0.7-0.1,7.3,7.3,13.6
                c4.5,3.9,11.7,8.4,16.4,11.2v-1.4c0-0.7-0.3-3.2-2.5-4.7c-3.9-2.5-8.3-5.4-11.3-8c-3.2-2.8-4.7-5.5-5.3-7.3C16.9,49.3,17,48,17,48
                l0-18.8c7.1-1.8,20.9-12,25.5-15.6c4.6,3.5,18.4,13.7,25.5,15.6v18.7l0,0.1c0,0,0,1.3-0.6,3.3c-0.6,1.8-2.1,4.6-5.3,7.3
                c-3,2.6-7.3,5.5-11.2,8c-2.4,1.6-2.6,4.4-2.6,4.8v1.3c4.7-2.9,11.9-7.4,16.4-11.2C72,55.1,72,48.6,72,47.8z"/>
									</g>
									<animate accumulate="none" additive="replace" attributeName="stroke-dasharray" calcMode="linear" dur="2s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="480 100;480 100">
									</animate>
									<animate accumulate="none" additive="replace" attributeName="stroke-dashoffset" calcMode="linear" dur="2s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="0;-1180">
									</animate>
								</svg>
							</div>
						</div>
					</div>
				</Paper>
			);
			break;
		case 2:
			// console.log('entro al loader de Roldan');
			return (
				<Paper elevation={0} className="__loader_container_Roldan_" hidden={hidden}>
					<div className="col-md-12 __loader_">
						<div className="abs-center wd-xl">
							<div className="text-center mb-4">
								<svg
									version="1.1"
									id="Layer_1"
									xmlns="http://www.w3.org/2000/svg"
									xlink="http://www.w3.org/1999/xlink"
									x="0px"
									y="0px"
									viewBox="0 0 256 218"
									style={{
										'enable-background': 'new 0 0 256 218',
									}}
									space="preserve"
								>
									<style type="text/css"></style>
									<path
										style={{
											fill: 'none',
											stroke: '#D61D28',
											'stroke-miterlimit': 10,
										}}
										class="st0"
										d="M235.8,187.8c-0.4,0.4-4.2,0.5-6.4,0.7c-24.2,1.2-62.5-40.6-80.8-85.6c25.5-9.5,50.8-25.4,52.7-37.6
	c2.4-14.7-29.4-31.6-66.8-36c-0.7-0.1-1.3-0.2-2-0.2c3-2,6.2-3.8,9.7-5.5l-0.9-1.8c-7.4,2.4-13.7,4.6-19.1,6.5
	C69.8,25.5,19.5,44.1,19.5,65.7c0,8.5,7.7,15.2,17.6,15.2l0.7-1.5c-2.6-2.2-3.9-5.7-3.9-10.3c0-23.8,39.3-39.3,81.3-38.4
	c-12.3,4.6-18.6,8-23,12c-8.8,7.9-16,23.3-19.3,41.9c-3.5,18.7-4.6,48.3-2.6,68.9c0,0.2,0.2,0.4,0.6,0.4l13-1.5
	c0.2,0,0.4-0.2,0.4-0.5c0.6-20,4-38.2,11.9-63.2c9.8-30.4,18.3-46.1,32.6-57c1.7,0.2,3.3,0.4,5,0.6c24.2,3.3,37.7,15.2,35.4,31.8
	c-1.7,12.5-11.7,23.5-23.7,31c-2-5.7-3.7-11.4-4.9-17.1c-0.2-0.7-0.5-1.1-1.3-0.7c-21.3,10.3-27.9,14.1-32.9,17.6
	c-10.7,7.7-11.2,14.7,0.6,16c5.4,0.7,12.3,0,19.8-1.5c11.6,30.7,35.2,66.3,59.9,79.4c16,8.5,36.2,9,49.6,0.9L235.8,187.8z
	 M122.5,104.8c-18.1,3.9-14.8-7.8-1.3-14.2c0.9,4.3,2.2,8.9,3.8,13.6C124.2,104.4,123.4,104.6,122.5,104.8z"
									>
										<animate
											accumulate="none"
											additive="replace"
											attributeName="stroke-dasharray"
											calcMode="linear"
											dur="4s"
											fill="remove"
											keyTimes="0;1"
											repeatCount="indefinite"
											restart="always"
											values="480 100;480 110"
										></animate>

										<animate
											accumulate="none"
											additive="replace"
											attributeName="stroke-dashoffset"
											calcMode="linear"
											dur="4s"
											fill="remove"
											keyTimes="0;1"
											repeatCount="indefinite"
											restart="always"
											values="0;-1180"
										></animate>
									</path>
								</svg>
							</div>
						</div>
					</div>
				</Paper>
			)

			break;
		case 3:
			// console.log('entro al loader de Sales Inteligence');
			return (
				<Paper elevation={0} className="__loader_container_sales" hidden={hidden}>
					<div className="col-md-12 __loader_">
						<div className="abs-center wd-sd">
							<div className="text-center wd-xl">
								<svg version="1.1" height="300" width="300" id="Poseidon_Spinner" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 85 85">
									<g>
										<ellipse className="stSales" cx="42.5" cy="42.5" rx="11.3" ry="31"></ellipse>
										<g>
											<path className="stSales" d="M10.3,42.5C10.3,27.7,17,14.7,27,8c-0.9-0.5-1.6-1-2.3-1.6C11.4,13,2.3,26.7,2.3,42.5
												c0,15.8,9.1,29.5,22.4,36.1c0.7-0.6,1.4-1.1,2.3-1.6C17,70.3,10.3,57.4,10.3,42.5z"/>
											<path className="stSales" d="M82.7,42.5c0-15.8-9.1-29.5-22.4-36C59.7,7,58.9,7.6,58,8c10,6.7,16.7,19.6,16.7,34.4
												C74.7,57.4,68,70.3,58,77c0.9,0.5,1.6,1,2.3,1.6C73.6,72,82.7,58.3,82.7,42.5z"/>
											<path className="stSales" d="M64.3,42.5c0,14.1-4.9,26.3-12.1,32.2c2.2,0.6,4.2,1.3,5.8,2.3c10-6.7,16.7-19.6,16.7-34.5
												C74.7,27.7,68,14.7,58,8c-1.6,0.9-3.6,1.7-5.8,2.3C59.4,16.2,64.3,28.4,64.3,42.5z"/>
											<path className="stSales" d="M20.7,42.5c0-14.1,4.9-26.3,12.1-32.2C30.6,9.7,28.6,9,27,8c-10,6.7-16.7,19.6-16.7,34.4
												C10.3,57.4,17,70.3,27,77c1.6-0.9,3.6-1.7,5.8-2.3C25.6,68.8,20.7,56.6,20.7,42.5z"/>
											<path className="stSales" d="M20.7,42.5c0,14.1,4.9,26.3,12.1,32.2c2.9-0.8,6.2-1.2,9.7-1.2c3.5,0,6.8,0.4,9.7,1.2
												c7.2-5.9,12.1-18.1,12.1-32.2c0-14.1-4.9-26.3-12.1-32.2c-2.9,0.8-6.2,1.2-9.7,1.2c-3.5,0-6.8-0.4-9.7-1.2
												C25.6,16.2,20.7,28.4,20.7,42.5z"/>
											<path className="stSales" d="M60.4,6.5C55,3.8,48.9,2.3,42.5,2.3c-6.4,0-12.5,1.5-17.9,4.2" />
											<path className="stSales" d="M24.6,78.6c5.4,2.7,11.4,4.2,17.9,4.2c6.4,0,12.5-1.5,17.9-4.2" />
										</g>

										<animate accumulate="none" additive="replace" attributeName="stroke-dasharray" calcMode="linear" dur="5s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="480 100;480 110"></animate>
										<animate accumulate="none" additive="replace" attributeName="stroke-dashoffset" calcMode="linear" dur="5s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="0;-1180"></animate>
										<line className="stSales" x1="8.8" y1="20.3" x2="76.2" y2="20.3" />
										<line className="stSales" x1="4" y1="30.5" x2="81" y2="30.5" />
										<line className="stSales" x1="2.3" y1="42.5" x2="82.7" y2="42.5" />
										<line className="stSales" x1="42.5" y1="82.7" x2="42.5" y2="2.2" />
										<line className="stSales" x1="4" y1="54.5" x2="81" y2="54.5" />
										<line className="stSales" x1="8.8" y1="64.7" x2="76.2" y2="64.7" />
									</g>
								</svg>
								{/* </div> */}
							</div>
						</div>
					</div>
				</Paper>
			);
			break;
		case 4:
			// console.log('entro al loader de RR Magic Vision');
			return (
				<Paper elevation={0} className="__loader_container_" hidden={hidden}>
					<div className="col-md-12 __loader_">
						<div className="abs-center wd-sd">
							<div className="text-center wd-xl">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 400 400" style={{ "enableBackground": "new 0 0 400 400" }}>
									<g>
										<path className="st0" d="M200,154.5c-16.9,14.3-27.7,35.5-27.7,59.4s10.8,45.1,27.7,59.4c16.9-14.3,27.7-35.5,27.7-59.4
								S216.9,168.8,200,154.5z"/>
										<path className="st1" d="M145.3,286.5C86.3,248,62.8,175.9,88.2,116c1.3-0.2,2.5-0.4,3.8-0.6c11.8-1.7,23.4-3,34.5-3.5
								c1.1-0.2,2.3-0.2,3.2-0.2c11.6-0.5,22.9-0.2,33.7,0.5c1.1,0.2,2.3,0.2,3.4,0.2c3.4,0.2,6.7,0.6,10.1,1c1.1,0.2,2.1,0.2,3.2,0.5
								c5.3,0.8,10.5,1.6,15.6,2.7c2.1,0.4,4,0.9,5.9,1.4c2.3,0.6,4.7,1.1,7,1.9c2.7,0.8,5.5,1.6,8.2,2.5c2.1,0.6,4,1.4,6.1,2.1
								c3.6,1.4,7.1,2.6,10.5,4.1c1.4,0.6,2.7,1.3,4.1,1.9c3.4,1.5,6.7,3.2,9.9,5c0.8,0.4,1.5,0.8,2.1,1.1c3.8,2.1,7.7,4.4,11.4,7
								c1.4,0.9,2.6,1.7,3.8,2.6c1.4,0.9,2.6,1.9,3.8,2.7c3.8,2.9,7.3,5.7,10.8,8.9c0.9,0.8,1.5,1.5,2.3,2.1c2.6,2.6,5.3,5.1,7.8,8
								c1,1.1,2.1,2.1,3,3.2c2.7,3.2,5.5,6.5,8,9.8c1.1,1.5,2.1,3,3.2,4.4c1.6,2.3,3.2,4.7,4.7,7.2c1,1.6,2.1,3.2,3,5
								c2,3.5,3.8,7.1,5.6,10.8c0.9,1.9,1.7,3.8,2.6,5.7c1.1,2.6,2.3,5.5,3.2,8.2c0.8,1.9,1.5,3.8,2.1,5.6c1.4,4,2.6,8,3.6,12.3
								c0.5,1.7,0.9,3.6,1.4,5.3c0.9,4.1,1.9,8.4,2.6,12.7c0.2,0.6,0.2,1.3,0.2,1.9C290.8,315.3,208.1,327.6,145.3,286.5z"/>
									</g>
									<animate accumulate="none" additive="replace" attributeName="stroke-dasharray" calcMode="linear" dur="2s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="480 100;480 100">
									</animate>
									<animate accumulate="none" additive="replace" attributeName="stroke-dashoffset" calcMode="linear" dur="2s" fill="remove" keyTimes="0;1" repeatCount="indefinite" restart="always" values="0;-1180">
									</animate>
								</svg>
							</div>
						</div>
					</div>
				</Paper>
			)
			break;
		case 5:
			// console.log('entro al loader de Magic Dome');
			return (
				<Paper elevation={0} className="__loader_container__dome_" hidden={hidden}>
					<ContentWrapper className="__center">
						<div className="col-md-12 __loader">
							<div className="abs-center wd-xl">
								<div className="text-center mb-4">
									{/* <img src={LoaderSPINER} alt="this slowpoke moves"  width="250" alt="404 image"/> */}
									{/* <img src='https://fsposeidon.blob.core.windows.net/magicdome/Spinner-V2.gif' alt="description of gif" width="250"  />                    */}
									<svg id="786aecb1-a851-4ee2-b41f-5250ac8e5399" data-name="MagicDome" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.44 116.41">
										<path
											className="stMagicDome"
											d="M197.72,158.2H185.8v-5.95c0-54.34-38.49-98.54-85.8-98.54s-85.8,44.2-85.8,98.54v5.95H2.28v-5.95C2.28,91.34,46.12,41.8,100,41.8s97.72,49.54,97.72,110.45Z"
											transform="translate(-2.28 -41.8)"
											style={{ fill: '#31ade3' }} />
										<svg>
											<path
												className="stMagicDome"
												d="M43,120.53H72.79a0,0,0,0,1,0,0v23.81a6,6,0,0,1-6,6H49a6,6,0,0,1-6-6V120.53A0,0,0,0,1,43,120.53Z"
												transform="translate(110.42 -43.08) rotate(45)"
												style={{ fill: '#31ade3' }} />
											<animate
												attributeName="opacity"
												values="0.7;1;0;0.7" dur="3s"
												repeatCount="indefinite"
											/></svg>
										<svg>
											<path
												className="stMagicDome"
												d="M70,99.49H93.83a0,0,0,0,1,0,0v29.76a0,0,0,0,1,0,0H64.07a0,0,0,0,1,0,0V105.44A6,6,0,0,1,70,99.49Z"
												transform="translate(101.71 -64.13) rotate(45)"
												style={{ fill: '#31ade3' }} />
											<animate
												attributeName="opacity"
												values="0.3;0;1;0.3" dur="2s"
												repeatCount="indefinite"
											/></svg>
										<svg>
											<path
												className="stMagicDome"
												d="M85.12,120.53h29.76a0,0,0,0,1,0,0v23.81a6,6,0,0,1-6,6H85.12a0,0,0,0,1,0,0V120.53A0,0,0,0,1,85.12,120.53Z"
												transform="translate(122.75 -72.85) rotate(45)"
												style={{ fill: '#31ade3' }} />
											<animate
												attributeName="opacity"
												values="1;0;1" dur="3s"
												repeatCount="indefinite"
											/></svg>
										<svg>
											<path
												className="stMagicDome"
												d="M112.12,99.49h23.81a0,0,0,0,1,0,0v29.76a0,0,0,0,1,0,0H106.17a0,0,0,0,1,0,0V105.44A6,6,0,0,1,112.12,99.49Z"
												transform="translate(114.04 -93.89) rotate(45)"
												style={{ fill: '#31ade3' }} />
											<animate
												attributeName="opacity"
												values="0.7;0;1;0.7" dur="3s"
												repeatCount="indefinite"
											/></svg>
										<svg>
											<path
												className="stMagicDome"
												d="M127.21,120.53H151a6,6,0,0,1,6,6v17.85a6,6,0,0,1-6,6H127.21a0,0,0,0,1,0,0V120.53a0,0,0,0,1,0,0Z"
												transform="translate(135.08 -102.61) rotate(45)"
												style={{ fill: '#31ade3' }} />
											<animate
												attributeName="opacity"
												values="0.3;1;0;0.3" dur="2s"
												repeatCount="indefinite"
											/></svg>
									</svg>
								</div>
							</div>
						</div>
					</ContentWrapper>
				</Paper>
			)
			break;
		case 6:
			// console.log('entro al loader de Euro');
			return (
				<Paper elevation={0} className="__loader_container_euro" hidden={hidden}>
					<div className="col-md-12 __loader_">
						<div style={{ overflow: "hidden" }} className="abs-center wd-xl">
							<div className="text-center mb-4">
								<div class="loader"
									style={{ marginTop: "15vh" }}
								>

								</div>

							</div>
						</div>
					</div>
				</Paper>
			);
			break;
		case 7:
			// console.log('entro al loader de OBL');
			return (
				<div className="loader-background" hidden={hidden}>
					<div className="loader">
						<object data={SVGLoader}></object>
					</div>
				</div>
			);
			break;
		default:
			// console.log('no hay nada');
			return (
				<>
					{/* <label>nada</label> */}
					<label>nada</label>
					{/* <Button>jeje</Button> */}
				</>

			)
			break;
	}
}

export default Loader;

