import moment from 'moment';

export default (value, format) => {
  if (!format) return value;
  const parsedDate = moment(value, format);
  if (parsedDate.isValid()) {
    return parsedDate.format(format);
  } else {
    return value;
  }
};
