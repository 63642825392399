import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
// import DirectionsIcon from '@material-ui/icons/Directions';
import './styles.scss';

const useStyles = makeStyles((theme) => ({
  // root: {
  // },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '80%'
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

/**
 * Customized Search Component
 * @param {*} props 
 */
function CustomizedSearch(props) {
  const classes = useStyles();

  return (

    <Paper component="form" className='search-v1-component' elevation={0}>
      <IconButton className={classes.iconButton} aria-label="menu">
        {/* <MenuIcon /> */}
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder={props.title}
        inputProps={{ 'aria-label': 'search component' }}
        onChange={props.onChange}
      />
      {/* <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton> */}
      {/* <Divider className={classes.divider} orientation="vertical" /> */}
      {/* <IconButton color="primary" className={classes.iconButton} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
}

CustomizedSearch.propTypes = {
  onChange: propTypes.func,
  title: propTypes.string
};

CustomizedSearch.defaultProps = {
  onChange: () => {},
  title: ''
};


export default CustomizedSearch;