import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Table from '../../../../../template/Table';
import Config from '../../../Data/Config';

function Items(props){
  let items = Array.isArray(props.data && props.data.items ? props.data.items: []);
  let columns = Config.columItems;

  return (
    <Card>
      <CardBody>
        <h4 className="mb-3"><em className="fas fa-list mr-2"></em>Line Items</h4>
        <Table
          data={items}
          columns={columns}
          options={
            {
              pagination: false,
              search: false,
              downloads: {
                xlsx: false,
                pdf: false
              }
            }
          }
        ></Table>
      </CardBody>
    </Card>
  );
}
export default Items;