import HTTP_REQUEST from '../../models/HTPP_REQUEST';
class REQUEST {
    EXEC = (config, fetch) => {
      return new Promise((resolve, rejected) => {
        let { host, headers, method } = HTTP_REQUEST[config._function];
        fetch(host + (config.params ? config.params : ''), {
          headers: headers,
          body: /*** IF DATA DOESN'T EXISTS MUST BE UNDEFINED **/ config.data ? JSON.stringify(config.data) : undefined /******* IF DATA DOESN'T EXISTS MUST BE UNDEFINED */,
          method: method
        }).then(success => {
          return success.json();
        }).then(data => {
          if (data.code === 200 || data.status === 200) {
            resolve(data);
          }
          else {
            rejected(data);
          }
        }).catch(() => {
          rejected({
            message: 'error',
            customMessage: 'No se ha logrado conectar con el servicio',
            host: host
          });
        });
      });
    }
}
export default REQUEST;