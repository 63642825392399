import React, { useState } from 'react';
import './style.scss'

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// import * as FilePond from 'filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Our app
function ImageUpload(props) {
  // const [files, setFiles] = useState([]);
  // const [Model, setModel] = React.useState({ ...props.Model });
  const [pond, setPond] = useState(React.createRef());

  // const Files = (x) => {
  //   console.log(Model)

  //   Model['Documents'].value = x;
  //   setModel({...props.Model });

  // }

  // useEffect(() => {
  //   console.log('entre aqui')
  // }, [props]);


  return (
    <div >
      <FilePond
        // files={files}
        // onupdatefiles={setFiles}
        onupdatefiles={(fileItems) => { props.callFile(fileItems) }}
        // onupdatefiles={(fileItems) => {
        //   // console.log('fileItems-->', fileItems);
        //   if (fileItems.length > 0) {
        //     if (fileItems[0].file.type === 'image/jpeg' || fileItems[0].file.type === 'image/png' || fileItems[0].file.type === 'image/jpg' || fileItems[0].file.type === 'application/msword' || fileItems[0].file.type === 'application/pdf' || fileItems[0].file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileItems[0].file.type === 'text/csv' || fileItems[0].file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        //       props.callFile(fileItems)
        //     }
        //     else {
        //       console.log('else');
        //       // let options = {
        //       //   place: "tr",
        //       //   message: "no valido alv",
        //       //   type: "info",
        //       //   icon: "now-ui-icons ui-1_bell-53",
        //       //   autoDismiss: 7
        //       // };
        //       pond.current.removeFiles();
        //       // props.callFile([], options);
        //     }
        //   }
        //   // else {
        //   //   this.pond.current.removeFiles();
        //   //   this.updateImg('https://fsposeidon.blob.core.windows.net/files/user-default-grey.png');
        //   // }
        // }}
        allowMultiple={true}
        maxFiles={1}
        server={{
          process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            // if (file) {
            //   console.log('si viene archivo-->');
            //   error('oh my goodness');
            //   load()
            //   return {
            //     abort: () => {
            //       abort();
            //     }
            //   }
            // }

            if (file) {
              console.log('file-->', file.type);
              if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'application/msword' || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.ms-excel') {
                // props.callFile(file)
                progress(true, 0, 1024);
                load(file)
              }
              else {
                error('oh no, invalid file, try again');
                props.callFile([])
                // pond.current.removeFiles();
                // props.callFile([], options);
              }
            }
          }
        }}

        // name="files"
        // labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        // acceptedFileTypes={['image/*', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        // acceptedFileTypes={['image/*', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
        // fileValidateTypeDetectType={(sourse, type) =>
        //   new Promise((resolve, reject) => {
        //     console.log('resolve-->', type);
        //     resolve(type)
        //   })
        // }
        one
        // labelFileTypeNotAllowed={'archivo novalido'}
        labelIdle={props.labels.upload}
        ref={pond}
        checkValidity={true}
        labelFileProcessingError={(err) => {
          console.log(err);
          return err.body
        }}
      />
    </div>
  );
}

export default ImageUpload;
