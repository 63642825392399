import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap'


class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount = () => {

    }

    componentWillReceiveProps = (nextProps) => {

    }

    render() {
        return (
            <Card style={{
                background: '#e0e0e0',
                textAlign:'justify',
                fontSize:'12px',
                fontWeight:'bold',
            }}>
                <CardBody>
                    <div className="row">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <em className="fas fa-info-circle mr-3"></em>
                        </div>
                        <div className="col-12">
                            <span>
                                Comments:
                            </span>
                        </div>
                        <div className="col-12"><br></br></div>


                        <div className="col-12">
                            <span>
                                Rates shown in this schedule to include charges related to:
                            </span>
                        </div>
                        <div className="col-12">
                            <span>
                                CY/CY ocean freight base rate, accessorial charges, detention & demurrage  days
                                /fees, booking and container monitoring fee, container documentation handling fee.
                            </span>
                        </div>
                        <div className="col-12"><br></br></div>

                        <div className="col-12">
                            <span>
                                The rates quoted within this document must be valid until end of 2018.
                            </span>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default Comments;


