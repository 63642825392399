const Forms = [
  {
    type: "Select",
    options: "StatusPo",
    label: "Status",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "StatusPo",
    id: "StatusPo",
    datatype: "select",
    show: true,
    style: { fontWeight: "bold" },
  },
  {
    type: "Select",
    options: "CustomerName",
    label: "Company",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "CustomerName",
    id: "CustomerName",
    datatype: "select",
    show: true,
    style: { fontWeight: "bold" },
  },
  {
    type: "Select",
    options: "SuupplierName",
    label: "Shipper",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "SupplierName",
    id: "SupplierName",
    datatype: "select",
    show: true,
    style: { fontWeight: "bold" },
  },
  {
    type: "Select",
    options: "ConsigneeName",
    label: "Consignee",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "ConsigneeName",
    id: "ConsigneeName",
    datatype: "select",
    show: true,
    style: { fontWeight: "bold" },
  },
  {
    type: "Select",
    options: "TransportMode",
    label: "Transport mode",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "TransportMode",
    id: "TransportMode",
    datatype: "select",
    show: true,
    style: { fontWeight: "bold" },
  },
  {
    type: "Datetime",
    label: "Required at destination",
    name: "requireddestinationdate",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "RequiredDestinationDate",
    style: { fontWeight: "bold" },
  },
  {
    type: "Datetime",
    label: "Ready charge request",
    name: "RequiredCagoReady",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "RequiredCagoReady",
    style: { fontWeight: "bold", maxWidth: "60%" },
  },
  {
    type: "Datetime",
    label: "Promise charge request",
    name: "PromiseCargoReady",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "PromiseCargoReady",
    style: { fontWeight: "bold" },
  },
];

export default Forms;
