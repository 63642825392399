import React from 'react';
import ChatbotButtons from '../SecComponents/chatBotButtons';

const poShipStatusMenu = [
    { label: 'Purchase Order', trigger: 'purchaseOrder', value: 'currentLabel' },
    { label: 'Shipment', trigger: 'shipment', value: 'currentLabel' }
];

function POShipMenu(props) {
    return (
        <div className="row" style={{ width: '100%' }}>
            <ChatbotButtons buttons={poShipStatusMenu} botSteps={props} />
        </div>
    );
}

export default POShipMenu;
