import React, { Component } from 'react'
import { Row, Col } from 'reactstrap';
import Global from './Global'
import Budget from './Budget'
import Spend from './Spend'

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }
    componentDidMount = () => {
        let { data } = this.props
        this.setState({
            data
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        })
    }
    render() {
        return (
            <Row>
                <Col md={8} xl={8}>
                    <Budget data={this.state.data}></Budget>
                </Col>
                <Col md={4} xl={4}>
                    <Global></Global>
                </Col>
                <Col md={12} xl={12}>
                    <Spend data={this.state.data}></Spend>
                </Col>
            </Row>
        )
    }
}

export default DataTable;