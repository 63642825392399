import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import io from "socket.io-client";

import ContentWrapper from "./../../template/Layout/ContentWrapper";
// Layout components
// import Sparkline from './../../template/Common/Sparklines';
import FlotChart from "./../../template/Common/Flot";
import VectorMap from "./../../template/Maps/VectorMap";
// import { Paper } from '@material-ui/core';
import EventsTable from "./components/EventsTable";
import CardsTotals from "./components/CardTotals";
// import HorizonBar from './components/HorizontalBar';
import Chart from "react-google-charts";
import { TableLoader, FullLoader } from "./components/Loader/";
import Loader from "./../../Global/Loader/";
import YearsSelect from "./components/CatYears";
import Axios from "axios";
import EasyPieChart from "easy-pie-chart";
// Styles
import "./styles.scss";
import { Services } from "./Services";
import { TrendData, LogisticData } from "./charts.config";
import { Divider } from "antd";
import RSS from "./RSS";

const defaultColors = {
  markerColor: "#23b7e5", // the marker points
  bgColor: "transparent", // the background
  scaleColors: ["#878c9a"], // the color of the region in the serie
  regionFill: "#bbbec6", // the base region color
};

const flotOptions = {
  legend: {
    show: false,
  },

  series: {
    lines: {
      show: false,
    },
    points: {
      show: true,
      radius: 4,
    },
    splines: {
      show: true,
      tension: 0.4,
      lineWidth: 1,
      fill: 0.5,
    },
  },
  grid: {
    borderColor: "#eee",
    borderWidth: 1,
    hoverable: true,
    backgroundColor: "#fcfcfc",
    color: "#dee1ec",
  },
  tooltip: true,
  tooltipOpts: {
    content: (label, x, y) => x + " : " + y,
  },
  xaxis: {
    show: true,
    color: "#fafafa",
    tickColor: "#fcfcfc",
    mode: "categories",
    font: { size: 100, style: "normal", width: 300 },
    gridLines: false,
  },
  yaxis: {
    show: true,
    min: 0,
    max: 10, // optional: use it for a clear represetation
    tickColor: "#eee",
    autoscaleMargin: 1,
    color: "#fafafa",
    //position: 'right' or 'left',
    tickDecimals: 0,
    tickFormatter: (v) => v /* + ' visitors'*/,
  },
  shadowSize: 1,
};

let orderFillment = {
  animate: {
    duration: 800,
    enabled: true,
  },
  barColor: "#23b7e5",
  trackColor: "rgba(200,200,200,0.4)",
  scaleColor: false,
  lineWidth: 10,
  lineCap: "round",
  size: 145,
};

/*=============================================
=            Dashboard Component Functions    =
=============================================*/
async function calculateSeries(data) {
  let Series = {};
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    Series[element.country] = element.value;
  }
  return Series;
}
async function calculateLogistics(data) {
  let Logistics = [
    [
      "Element",
      "Total",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
  ];
  try {
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      Logistics.push([
        element.label,
        element.value,
        element.color,
        element.extra,
      ]);
    }
    return Logistics;
  } catch (error) {
    return Logistics;
  }
}
/*=============================================
=            Dashboard Component              =
=============================================*/
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totals: {
        Bookings: "",
        Containers: "",
        CustomerId: "",
        OrderFullfillment: "",
        POs: "",
        Shipments: "",
        VendorFullfillment: "",
      },
      flotData: [],
      Attention: [],
      Logistics: [],
      Map: [],
      loadingTrends: true,
      loadingTotals: true,
      RegionValues: {},
      fullLoader: false,
    };
  }

  /*=============================================
  =            Get Info Dashboard Function      =
  =============================================*/
  getInfoDashBoard = async () => {
    let customer = this.props._customer;
    customer = parseInt(customer) ? parseInt(customer) : "";

    try {
      // ? Data for totals
      let response = await Axios.post(`${Services.DASHBOARD.path}dashboard`, {
        query:
          "query Totals($num:Int!){totals(_id: $num) {CustomerId POs Bookings Shipments Containers OrderFullfillment VendorFullfillment}}",
        variables: { num: customer },
      });
      if (response.data && response.data.data) {
        let chart = new EasyPieChart(this.refs.easypie, orderFillment);
        let chart2 = new EasyPieChart(this.refs.easypie2, orderFillment);
        chart.update(response.data.data.totals.OrderFullfillment);
        chart2.update(response.data.data.totals.VendorFullfillment);
        this.setState({
          totals: response.data.data.totals,
          loadingTotals: false,
        });
      }

      // ? Trends For Shipment/Containers
      let trendOfOrders = await Axios.post(
        `${Services.DASHBOARD.path}dashboard`,
        {
          query: `query Trend($customer: Int!, $user: Int!){ 
          TrendOrders(_id: $customer, user: $user) {
            Trend{label color data}
            Logistics{label value color extra}
            Attention{event impact total type launch}

            Map{name}
            MapTotals {country value}
          }
        }`,
          variables: {
            customer: customer,
            user: parseInt(this.props._user) ? parseInt(this.props._user) : 0,
          },
        }
      );
      //Map{latLng name}
      // TrendOrders{
      // TotalAttention
      // }
      if (trendOfOrders.data && trendOfOrders.data.data) {
        let Logistics = [];
        Logistics = await calculateLogistics(
          trendOfOrders.data.data.TrendOrders.Logistics
        );
        console.log(
          "🚀 ~ Dashboard ~ getInfoDashBoard= ~ Logistics:",
          Logistics
        );

        let Series = {};
        Series = await calculateSeries(
          trendOfOrders.data.data.TrendOrders.MapTotals
        );

        let Attention = trendOfOrders.data.data.TrendOrders.Attention;

        const URL = "https://poseidonsocketservices.azurewebsites.net/comments";
        const socket = io(URL);
        socket.emit("reset_comments", {
          user: this.props.user,
          customer: customer,
        });
        socket.emit("disconnect");
        socket.off();

        let Map = trendOfOrders.data.data.TrendOrders.Map;
        this.setState({
          flotData: trendOfOrders.data.data.TrendOrders.Trend,
          Logistics,
          Attention,
          Map,
          loadingTrends: false,
          RegionValues: Series,
          fullLoader: true,
        });
      } else this.resetTotalTrends();
    } catch (error) {
      this.resetTotalTrends();
      this.setState({
        flotData: TrendData,
        Map: [],
        loadingTrends: false,
        loadingTotals: false,
        Logistics: await calculateLogistics(LogisticData),
        fullLoader: true,
      });
    }
  };

  /*=============================================
  =            Reset Total Trends               =
  =============================================*/

  resetTotalTrends = () => {
    localStorage.setItem("_total_attention_", "0");
    if (document.getElementById("Badge_"))
      document.getElementById("Badge_").innerHTML = "0";
  };

  /*=============================================
  =            OnChange Year Search             =
  =============================================*/

  onChangeYear = async (data) => {
    // ? flotData
    let customer = this.props._customer;
    customer = parseInt(customer) ? parseInt(customer) : "";
    try {
      let TrendShipmentByYear = await Axios.post(
        `${Services.DASHBOARD.path}dashboard`,
        {
          query: `query TrendCat($customer: Int!, $date_value: String, $user: Int!){ 
          TrendOrders(_id: $customer, date: $date_value, user: $user) {
            Trend{label color data}
          }
        }`,
          variables: {
            customer: customer,
            date_value: data.value,
            user: parseInt(this.props._user) ? parseInt(this.props._user) : 0,
          },
        }
      );
      this.setState({
        flotData: TrendShipmentByYear.data.data.TrendOrders.Trend,
      });
    } catch (error) {
      console.error(error);
    }
  };

  /*=============================================
  =            Life Cicle Component             =
  =============================================*/

  componentDidMount() {
    this.getInfoDashBoard();
    document.getElementById("body").className = "layout-fixed"; // !changed for nothing
  }

  /*=============================================
  =            Render Method                    =
  =============================================*/

  render() {
    const {
      totals,
      flotData,
      Logistics,
      Attention,
      Map,
      loadingTrends,
      loadingTotals,
      RegionValues,
      fullLoader,
    } = this.state;
    return (
      <>
        <Loader hidden={fullLoader} />
        <ContentWrapper>
          <Card>
            <CardBody>
              <Row>
                <CardsTotals
                  image={
                    <img
                      src="https://fsposeidon.blob.core.windows.net/poseidonpic/Dashboard_PO@3x.png"
                      width="50"
                      height="60"
                      alt="active_po"
                    />
                  }
                  xl={3}
                  md={6}
                  loading={loadingTotals}
                  total={totals.POs}
                  title={"Active POs"}
                  propertiesIcon={
                    "col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left"
                  }
                  propertiesTitle={
                    "col-8 py-3 bg-primary-dark  rounded-right endPosition"
                  }
                />
                <CardsTotals
                  image={
                    <img
                      src="https://fsposeidon.blob.core.windows.net/poseidonpic/Dashboard_Booking@3x.png"
                      width="50"
                      height="60"
                      alt="active_booking"
                    />
                  }
                  xl={3}
                  md={6}
                  loading={loadingTotals}
                  total={totals.Bookings}
                  title={"Active Bookings"}
                  propertiesIcon={
                    "col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left"
                  }
                  propertiesTitle={
                    "col-8 py-3 bg-purple-dark rounded-right endPosition"
                  }
                />
                <CardsTotals
                  image={
                    <img
                      src="https://fsposeidon.blob.core.windows.net/poseidonpic/Dashboard_Shipment@3x.png"
                      width="50"
                      height="60"
                      alt="active_shipment"
                    />
                  }
                  xl={3}
                  md={6}
                  loading={loadingTotals}
                  total={totals.Shipments}
                  title={"Active Shipments"}
                  propertiesIcon={
                    "col-4 d-flex align-items-center bg-green-dark justify-content-center rounded-left "
                  }
                  propertiesTitle={
                    "col-8 py-3 bg-green-dark  rounded-right endPosition"
                  }
                />
                <CardsTotals
                  image={
                    <img
                      src="https://fsposeidon.blob.core.windows.net/poseidonpic/Dashboard_Container@3x.png"
                      width="50"
                      height="60"
                      alt="active_containers"
                    />
                  }
                  xl={3}
                  md={6}
                  loading={loadingTotals}
                  total={totals.Containers}
                  title={"Active Containers"}
                  propertiesIcon={
                    "col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left"
                  }
                  propertiesTitle={
                    "col-8 py-3  bg-primary-dark rounded-right endPosition"
                  }
                />
                {/*<Divider />
                <Col
                  xs={2}
                  md={12}
                  lg={12}
                  hidden={this.props._customer !== "193" ? false : true}
                >
                  <RSS type="dashboard"></RSS>
                </Col>*/}
                <Divider />
                <Col xl={this.props._customer !== "193" ? 10 : 12}>
                  <Row>
                    <Col xl={6}>
                      <div className="card myCard equalsWidth">
                        <div className="card-header">
                          <div className="card-title"> Orders by Status </div>
                        </div>
                        <div className="card-body">
                          {loadingTrends ? (
                            <FullLoader />
                          ) : (
                            <div>
                              <Chart
                                className={"_table_content"}
                                width={"100%"}
                                height={"100%"}
                                chartType="BarChart"
                                loader={<div>Loading Chart</div>}
                                data={Logistics}
                                options={{
                                  height: 360,
                                  // width:
                                  bar: { groupWidth: "80%" },
                                  legend: { position: "none" },
                                  animation: {
                                    startup: true,
                                    easing: "linear",
                                    duration: 1000,
                                  },
                                }}
                                rootProps={{ "data-testid": "6" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xl={6}>
                      {/* START card */}
                      <div className="card myCard equalsWidth">
                        <div className="card-header">
                          <Col md={12}>
                            <Row>
                              <Col md={8}>
                                <div className="card-title">
                                  {" "}
                                  Trend of Shipments / Containers{" "}
                                </div>
                              </Col>
                              <Col md={4}>
                                <YearsSelect onChangeYear={this.onChangeYear} />
                              </Col>
                            </Row>
                          </Col>
                        </div>
                        <div className="card-body mx-md-n5">
                          {loadingTrends ? (
                            <FullLoader />
                          ) : (
                            <FlotChart
                              data={flotData}
                              options={flotOptions}
                              width={"100%"}
                              height={"95%"}
                            />
                          )}
                        </div>
                      </div>
                      {/* END widget */}
                    </Col>

                    <Col xl={6}>
                      <Card className="myCard equalsWidth">
                        <div className="card-header">
                          <div className="card-title">
                            {" "}
                            Events that Require Attention{" "}
                          </div>
                        </div>
                        <CardBody>
                          {loadingTrends ? (
                            <TableLoader />
                          ) : (
                            <EventsTable data={Attention} />
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={6}>
                      <Card className="myCard equalsWidth">
                        <div className="card-header">
                          <div className="card-title">
                            {" "}
                            Orders by Origin Country{" "}
                          </div>
                        </div>
                        <CardBody>
                          {loadingTrends ? (
                            <FullLoader />
                          ) : (
                            <VectorMap
                              options={{
                                map: "world_mill_en",
                                backgroundColor: defaultColors.bgColor,
                                zoomMin: 1,
                                zoomMax: 8,
                                zoomOnScroll: false,
                                regionStyle: {
                                  initial: {
                                    fill: defaultColors.regionFill,
                                    "fill-opacity": 1,
                                    stroke: "none",
                                    "stroke-width": 1.5,
                                    "stroke-opacity": 1,
                                  },
                                  hover: {
                                    "fill-opacity": 0.8,
                                  },
                                  selected: {
                                    fill: "blue",
                                  },
                                  selectedHover: {},
                                },
                                focusOn: {
                                  x: 0.4,
                                  y: 0.6,
                                  scale: 1,
                                },
                                markerStyle: {
                                  initial: {
                                    fill: defaultColors.markerColor,
                                    stroke: defaultColors.markerColor,
                                  },
                                },
                                onRegionLabelShow: (e, el, code) => {
                                  if (RegionValues[code])
                                    el.html(
                                      el.html() +
                                        ": " +
                                        RegionValues[code] +
                                        " orders"
                                    );
                                },
                              }}
                              series={{
                                regions: [
                                  {
                                    values: RegionValues,
                                    scale: defaultColors.scaleColors,
                                    normalizeFunction: "polynomial",
                                  },
                                ],
                              }}
                              // markers={Map}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                {/* </Row> */}
                <Col
                  xl={2}
                  md={12}
                  sm={12}
                  hidden={this.props._customer !== "193" ? false : true}
                >
                  {/* START loader widget */}
                  <div className="card equalsWidth myCard">
                    <div className="card-body">
                      {/* <a className="text-muted float-right" href=""> */}
                      {/* <em className="fa fa-arrow-right"></em> */}
                      {/* </a> */}
                      <div className="text-info">Order Fulfillment</div>
                      <div className="text-center py44 py-4">
                        <div
                          ref="easypie"
                          data-percent="0"
                          className="easypie-chart easypie-chart-lg"
                        >
                          <span>{totals.OrderFullfillment}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END loader widget */}
                  {/* START loader widget */}
                  <div className="card equalsWidth myCard">
                    <div className="card-body">
                      {/* <a className="text-muted float-right" href=""> */}
                      {/* <em className="fa fa-arrow-right"></em> */}
                      {/* </a> */}
                      <div className="text-info">Vendor Fulfillment</div>
                      <div className="text-center py44 py-4 _center">
                        <div
                          ref="easypie2"
                          data-percent="0"
                          className="easypie-chart easypie-chart-lg"
                        >
                          <span>{totals.VendorFullfillment}%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END loader widget */}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ContentWrapper>
      </>
    );
  }
}

export default withRouter(Dashboard);
