import React, { Component } from 'react';

import Table from '../../../../../../template/Table'


class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainColumns : [
                { label : 'Lane Definition', colspan : '2'},
                { label : 'Freight Forwarder Proposal', colspan : '12'},
            ],
            columns: [
                { label : 'Lane ID', key : 'LaneID'},
                { label : 'Country Of Origin', key : 'CountryOfOrigin'},
                { label : 'Consolidation Location' , key:'ConsolidationLocation'},
                { label : 'Transit Time From Origin', key : 'TransitTimeFromOrigin'},
                { label : 'Currency', key : 'Currency'},
                { label : 'Minimum Charge', key : 'MinimumCharge'},
                { label : 'Maximum Charge', key : 'MaximumCharge'},
                { label : '0-50kgs', key : 'Packages0_50kgs'},
                { label : '50-100kgs', key : 'Packages50_100kgs'},
                { label : '100-200kgs', key : 'Packages100_200kgs'},
                { label : '200-500kgs', key : 'Packages200_500kgs'},
                { label : '500-1000kgs', key : 'Packages500_1000kgs'},
                { label : '1000-2000kgs', key : 'Packages1000_2000kgs'},
                { label : '2000kgs', key : 'Packages2000kgs'},
            ],
            data: []
        };
    }


    componentDidMount = () => {
        this.setState({
            data : this.props.results
        })
    }


    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data : nextProps.results
        })
    }



    render() {

        return (
            <div className="row">
                <div className="col-12">
                <Table
                    columns={this.state.columns}
                    mainColumns = {this.state.mainColumns}
                    data={this.state.data}
                    large={true}
                    bordered = {true}
                    type = {'GENERIC'}

                    options={{
                        pagination:true,
                        large : true,
                        downloads: {   
                            pdf: false,
                            xlsx: true
                        }
                    }}
                />
                </div>
            </div>
        );
    }
}

export default Results;


