import io from 'socket.io-client';

const END_POINT = 'https://poseidonsocketservices.azurewebsites.net/comments';

/**
 * Reset Comments Event
 * @param {*} props 
 */
export const sendResetComments = (props) => {
  let socket;
  socket = io(END_POINT);
  socket.emit('reset_comments', {user: props.user, customer: props.customer});
  socket.emit('disconnect');
  socket.off();
};

/**
 * Send New Comment Event
 * @param {*} props 
 */
export const sendNewCommentEvent = (props) => {
  let socket;
  socket = io(END_POINT);
  socket.emit('new_comment', {user: props.user, customer: props.customer});
  socket.emit('disconnect');
  socket.off();
};