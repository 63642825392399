import * as React from "react";
import {Button} from "reactstrap";
export const MyCommandCell = (props) => {
  const {dataItem} = props;
  const inEdit = dataItem[props.editField];
  return inEdit ? (
    <td>
      <Button
        className="btn-width-table mr-2"
        color="danger"
        size="sm"
        onClick={() => props.cancel(dataItem)}
      >
        Cancel
      </Button>{" "}
      <Button
        className="btn-width-table"
        color="primary"
        size="sm"
        onClick={() => props.update(dataItem)}
      >
        Save
      </Button>
    </td>
  ) : (
    <td className="k-command-cell">
      <Button
        className="btn-width-table"
        color="success"
        size="sm"
        onClick={() => props.edit(dataItem)}
      >
        Edit
      </Button>
    </td>
  );
};
