import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
const variantIcon = {
  success: CheckCircleIcon,
  admin: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const styles1 = theme => ({
  admin: {
    backgroundColor: blue[600],
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

/*=============================================
=            My Snackbar Content              =
=============================================*/

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});


/*=============================================
=            Customized SnackBars             =
=============================================*/

function CustomizedSnackbars(props){
  const [openAlert, setOpenAlert] = React.useState(props.open);

  // function handleClick() {}
  function handleClose() { setOpenAlert(false);}
  
  // const { classes } = this.props;
  React.useEffect(() => {
    setOpenAlert(props.open);
  }, [props.open]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: props.vertical ? props.vertical : 'top',
          horizontal: props.horizontal ? props.horizontal : 'right',
        }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleClose}>
        <MySnackbarContentWrapper
          onClose={handleClose}
          variant={props.type}
          message={props.message}
        />
      </Snackbar>
    </div>
  );
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
};

CustomizedSnackbars.defaultProps = {
  type: 'success',
  message: 'message'
};

export default withStyles(styles2)(CustomizedSnackbars);
