import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { Button, Input } from "reactstrap";
import Tables from "../../../../../template/Table";
import Icon from "@material-ui/core/Icon";
import swal from "sweetalert";
import axios from "axios";
import { Services } from "../../../Services";
import { Modal, Icon as Icon2 } from "semantic-ui-react";
import Errors from "../../../../../template/Errors";
import Table from "../../../../../template/Table";
import { FilePond } from "react-filepond";
import { ExpansionPanel, ExpansionPanelDetails } from "@material-ui/core/";
import Fade from "react-reveal/Fade";
import Select from "react-select";

class listTemplates extends Component {
  constructor(props) {
    super(props);
    this.pondBig = React.createRef();
    this.pondSmall = React.createRef();
    this.state = {
      columns: [
        {
          label: "NO",
          key: "DemoConfigurationId",
          custom: (value) => (
            <>
              {value.DemoConfigurationId != 1 ? (
                <em
                  onClick={() => {
                    this.modalCustomer(value);
                  }}
                >
                  {" "}
                  <label style={{ color: "#349ced" }}>
                    {value.DemoConfigurationId}
                  </label>{" "}
                </em>
              ) : (
                <label>1</label>
              )}
            </>
          ),
        },
        { label: "Customer", key: "Customer" },
        { label: "Active Option", key: "SelectedProject" },
        {
          label: "Select Option",
          key: "",
          custom: (value) => this.custom1(value),
        },
        { label: "Delete", key: "", custom: (value) => this.custom2(value) },
      ],
      items: [],
      active: false,
      edit: false,
      smallLogoDefault: "",
      bigLogoDefault: "",
      demoConfiguration: {
        DemoConfigurationId: "",
        Customer: "",
        BigLogo: {
          file: "",
          fileName: "",
          size: "",
        },
        SmallLogo: {
          file: "",
          fileName: "",
          size: "",
        },
        PrimaryColor: "",
        SecondaryColor: "",
        HoverColor: "",
        SelectedProject: 0,
      },
      CreateForAdd: [],
      Form: this.props.form,
      error: {
        open: false,
        message: "",
        type: "admin",
      },
      BigLogo: {
        file: "",
        fileName: "",
        size: "",
      },
      SmallLogo: {
        file: "",
        fileName: "",
        size: "",
      },
    };
  }

  custom1 = (value) => (
    <Button
      variant="fab"
      mini
      color="secondary"
      aria-label="Add"
      onClick={this.selectOption(value)}
    >
      <Icon className="icon" style={{ fontSize: 20 }}>
        remove
      </Icon>
    </Button>
  );

  custom2 = (value) => (
    <>
      {value.SelectedProject === "No" && value.DemoConfigurationId != 1 && (
        <em
          onClick={() => this.validateDelete(value)}
          className="fa-2x mr-2 far fa-trash-alt"
          style={{ color: "#297AB9" }}
        ></em>
      )}
    </>
  );

  modalCustomer = (value) => {
    this.setState({ active: true });

    this.setState({ demoConfiguration: { ...value } }, () => {
      this.setState({
        SmallLogo: { file: value.SmallLogo },
        smallLogoDefault: value.SmallLogo,
      });
      this.setState({
        BigLogo: { file: value.BigLogo },
        bigLogoDefault: value.BigLogo,
      });
      this.buildFormAdd();
    });
  };

  handleOnClose = () => {
    this.setState({
      active: !this.state.active,
      edit: false,
      error: { open: false },
    });
  };

  validateDelete = (value) => {
    let id = `${value.Customer}`;
    swal({
      title: "Are you sure want to delete style?",
      text: id,
      icon: "warning",
      buttons: true,
    }).then((yeah) => {
      if (yeah) this.props.deleteStyle(value);
    });
  };

  putTemplate = () => {
    let body = this.state.demoConfiguration;
    body.SelectedProject = body.SelectedProject === "Yes" ? 1 : 0;
    body.BigLogo = this.state.BigLogo;
    body.SmallLogo = this.state.SmallLogo;
    delete body.CreateDate;
    axios
      .put(`${Services.ADMIN.path}admin/demoConfigurationById`, body)
      .then((response) => {
        this.props.getDemoConfigurations();
        this.setState({
          error: {
            message: "The style has been saved correctly",
            open: true,
            type: "admin",
          },
        });
        this.handleOnClose();
      })
      .catch((error) => {
        this.setState({
          error: {
            message: "The style has not saved",
            open: true,
            type: "error",
          },
        });
      });
  };

  componentDidMount = () => {
    if (this.props) {
      this.setState({
        items: this.props.item,
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps) {
      this.setState({
        items: nextProps.item,
      });
    }
  };

  selectOption = (index) => (event) => {
    this.props.SelectDemoConfiguration(index);
  };

  handleChangeAdd = (name) => (event) => {
    document.getElementById(name).style.color = "black";
    let { demoConfiguration } = this.state;
    demoConfiguration[name] = event.target.value;
    this.setState(
      {
        demoConfiguration,
      },
      () => {
        this.buildFormAdd();
      }
    );
  };

  buildFormAdd = () => {
    const { Form, demoConfiguration, selects } = this.state;
    let CreateForAdd = Form.AddTemplates.map((e, i) => {
      if (e.type === "Input")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.id} style={e.style}>
                <label>
                  {e.label} {e.required == true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>
            <Input
              required
              disabled={!this.state.edit}
              type={e.datatype}
              placeholder={e.placeHolder}
              name={e.id}
              value={demoConfiguration[e.id]}
              onChange={this.handleChangeAdd(e.id)}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Select")
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.id} style={e.style}>
                {" "}
                <label>
                  {e.label} {e.required == true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Select
              required
              disabled={!this.state.edit}
              type={e.datatype}
              name={e.name}
              value={"test"}
              onChange={this.handleChangeSelect(e.id)}
              options={[]}
              error={true}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      return e;
    });
    this.setState({
      CreateForAdd,
    });
  };

  recieveProps = (open) =>
    this.setState({ error: { open: open, type: this.state.error.type } });

  render() {
    let { items } = this.state;
    items = items.map((i) => {
      let it = { ...i };
      it.SelectedProject = it.SelectedProject === "1" ? "Yes" : "No";
      return it;
    });
    return (
      <>
        <Card>
          <Tables
            data={items}
            columns={this.state.columns}
            options={{
              pagination: false,
              search: false,
              downloads: { xlsx: false, pdf: false },
            }}
          ></Tables>
        </Card>
        <Modal
          open={this.state.active}
          size={"large"}
          dimmer={"inverted"}
          centered={false}
          scrolling
        >
          <Modal.Header className="headerModal">
            {this.state.edit === false ? "View Style" : "Edit Style"}
            <div className="iconHeader">
              <Icon2
                name="pencil"
                size="small"
                disabled=""
                onClick={() => {
                  this.setState({ edit: !this.state.edit }, () => {
                    this.buildFormAdd();
                  });
                }}
              />
            </div>
          </Modal.Header>
          <Errors
            open={this.state.error.open}
            type={this.state.error.type}
            message={this.state.error.message}
            setProps={this.recieveProps}
          ></Errors>

          <Modal.Content>
            <div className="col-md-12">
              <strong style={{ marginLeft: "-12px" }}>Style Information</strong>
            </div>
            <br></br>
            {this.state.edit === true ? (
              <div>
                <ExpansionPanel elevation={0} defaultExpanded>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="d-flex col-md-12 flex-column">
                        <div className="row divcenter">
                          <FilePond
                            id="logoTemplateCreate"
                            allowFileEncode={true}
                            labelIdle="Big Logo"
                            ref={this.pondBig}
                            labelFileWaitingForSize={false}
                            styleButtonRemoveItemPosition="right"
                            labelButtonRetryItemLoad={false}
                            onupdatefiles={(fileItems) => {
                              if (fileItems.length > 0) {
                                setTimeout(
                                  function() {
                                    if (
                                      fileItems[0].file.type == "image/jpeg" ||
                                      fileItems[0].file.type == "image/png" ||
                                      fileItems[0].file.type == "image/jpg"
                                    ) {
                                      if (
                                        fileItems[0].file.size < 1000000 &&
                                        fileItems[0].file instanceof Blob
                                      ) {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(fileItems[0].file);
                                        reader.onload = () => {
                                          this.setState({
                                            BigLogo: {
                                              file: reader.result,
                                              fileName: fileItems[0].file.name,
                                              size: fileItems[0].file.size,
                                            },
                                          });
                                        };
                                      } else {
                                        this.pondBig.current.removeFiles();
                                        this.setState({
                                          BigLogo: {
                                            file: this.state.bigLogoDefault,
                                            fileName: "",
                                            size: 0,
                                          },
                                        });
                                        this.setState({
                                          error: {
                                            message:
                                              "error uploading file, try again",
                                            open: true,
                                            type: "error",
                                          },
                                        });
                                      }
                                    } else {
                                      this.pondBig.current.removeFiles();
                                      this.setState({
                                        BigLogo: {
                                          file: this.state.bigLogoDefault,
                                          fileName: "",
                                          size: 0,
                                        },
                                      });
                                      this.setState({
                                        error: {
                                          message: "unsupported file type!",
                                          open: true,
                                          type: "error",
                                        },
                                      });
                                    }
                                  }.bind(this),
                                  1000
                                );
                              } else {
                                this.pondBig.current.removeFiles();
                                this.setState({
                                  BigLogo: {
                                    file: this.state.bigLogoDefault,
                                    fileName: "",
                                    size: 0,
                                  },
                                });
                              }
                            }}
                          ></FilePond>
                          <img
                            src={this.state.BigLogo.file}
                            width="117"
                            height="117"
                            className="rounded"
                            alt="biglogo_image"
                          />
                          <FilePond
                            id="logoTemplateCreate"
                            allowFileEncode={true}
                            labelIdle="Small Logo"
                            ref={this.pondSmall}
                            labelFileWaitingForSize={false}
                            styleButtonRemoveItemPosition="right"
                            labelButtonRetryItemLoad={false}
                            imageValidateSizeMaxWidth={100}
                            imageValidateSizeMaxHeight={59}
                            imageValidateSizeLabelExpectedMaxSize={
                              "Maximum size is {maxWidth} × {maxHeight}"
                            }
                            onupdatefiles={(fileItems) => {
                              if (fileItems.length > 0) {
                                setTimeout(
                                  function() {
                                    if (
                                      fileItems[0].file.type == "image/jpeg" ||
                                      fileItems[0].file.type == "image/png" ||
                                      fileItems[0].file.type == "image/jpg"
                                    ) {
                                      if (
                                        fileItems[0].file.size < 1000000 &&
                                        fileItems[0].file instanceof Blob
                                      ) {
                                        var reader = new FileReader();
                                        reader.readAsDataURL(fileItems[0].file);
                                        reader.onload = () => {
                                          this.setState({
                                            SmallLogo: {
                                              file: reader.result,
                                              fileName: fileItems[0].file.name,
                                              size: fileItems[0].file.size,
                                            },
                                          });
                                        };
                                      } else {
                                        this.pondSmall.current.removeFiles();
                                        this.setState({
                                          SmallLogo: {
                                            file: this.state.smallLogoDefault,
                                            fileName: "",
                                            size: 0,
                                          },
                                        });
                                        this.setState({
                                          error: {
                                            message:
                                              "error uploading file, try again",
                                            open: true,
                                            type: "error",
                                          },
                                        });
                                      }
                                    } else {
                                      this.pondSmall.current.removeFiles();
                                      this.setState({
                                        SmallLogo: {
                                          file: this.state.smallLogoDefault,
                                          fileName: "",
                                          size: 0,
                                        },
                                      });
                                      this.setState({
                                        error: {
                                          message: "unsupported file type!",
                                          open: true,
                                          type: "error",
                                        },
                                      });
                                    }
                                  }.bind(this),
                                  1000
                                );
                              } else {
                                this.pondSmall.current.removeFiles();
                                this.setState({
                                  SmallLogo: {
                                    file: this.state.smallLogoDefault,
                                    fileName: "",
                                    size: 0,
                                  },
                                });
                              }
                            }}
                          ></FilePond>
                          <img
                            src={this.state.SmallLogo.file}
                            width="117"
                            height="117"
                            className="rounded"
                            alt="small_image"
                          />
                        </div>
                        <div className="d-flex divcenter flex-column">
                          <span style={{ color: "red" }}>
                            The maximum recommended size for the big logo is
                            1034 * 331.
                          </span>
                          <span style={{ color: "red" }}>
                            The maximum size for the big logo is 100 * 59.
                          </span>
                        </div>
                      </div>

                      <div
                        className="row col-md-12"
                        style={{ marginTop: "5%" }}
                      >
                        {this.state.CreateForAdd}
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            ) : (
              <div>
                <ExpansionPanel elevation={0} defaultExpanded>
                  <ExpansionPanelDetails>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="d-flex col-md-12 flex-column">
                        <div className="row divcenter">
                          <img
                            src={this.state.BigLogo.file}
                            width="117"
                            height="117"
                            className="rounded"
                            alt="biglogo_image"
                          />
                          <img
                            src={this.state.SmallLogo.file}
                            width="117"
                            height="117"
                            className="rounded"
                            alt="small_image"
                          />
                        </div>
                      </div>

                      <div
                        className="row col-md-12"
                        style={{ marginTop: "5%" }}
                      >
                        {this.state.CreateForAdd}
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions className="actions_modalSelect">
            <div
              hidden={this.state.edit === true ? true : false}
              style={{ textAlign: "center" }}
              className="col-md-2 col-lg-12 col-sm-4"
            >
              <Button
                variant="contained"
                color="danger"
                className="button"
                onClick={this.handleOnClose}
              >
                Close
              </Button>
            </div>
            <div
              hidden={this.state.edit === true ? false : true}
              className="row"
            >
              <div className="col-md-6 col-lg-4 col-sm-12"></div>
              <div
                style={{ textAlign: "center" }}
                className="col-md-2 col-lg-2 col-sm-4"
              >
                <Button
                  variant="contained"
                  color="danger"
                  className="button"
                  onClick={this.handleOnClose}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-md-2 col-lg-1 col-sm-4">
                <Button
                  variant="contained"
                  color="primary"
                  className="button"
                  onClick={() => {
                    this.putTemplate();
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default listTemplates;
