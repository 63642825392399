import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Row } from 'reactstrap';
// import restService from '../../../../services/restService';
import { Tab } from 'semantic-ui-react';
import ShipmentModel from './models/shipment.model';
import shipmentDataPlaneModel from './models/shipment.plane.model';
import Axios from 'axios';
import { Services } from './../Services';
import Loader from './../../../Global/Loader';

const Summary = React.lazy(() => import('./components/summary'));
const Documents = React.lazy(() => import('./components/documents'));
const Comments = React.lazy(() => import('./components/comments'));
const Events = React.lazy(() => import('./components/events'));

/*=============================================
=            Tracking summary component       =
=============================================*/

const locations = [

  [20.602192, -99.768074],

  [24.918113, -70.062439],

  [25.502374, -45.523002],

  [27.667796, -18.723354],

  [31.553074, 7.092821],

  [51.732587, 20.799167],

  [64.172003, 54.185598]

]
class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panes: [],
      shipment: ShipmentModel,
      shipmentDataPlane: shipmentDataPlaneModel,
      steps: [],
      fullLoader: false,
      tabIndex: 0,
      count: 2,
      animatedMarker: {}
    };
  }

  componentDidMount = async () => {
    this.getShipment();

  }

  getShipment = async (shipmentId) => {
    var shipment = typeof shipmentId !== 'undefined' ? shipmentId : this.props.match.params.id;
    let success = await Axios.get(`${Services.SHIPMENTS.path}shipment/${parseInt(shipment) ? parseInt(shipment) : shipment}`);
    if (success.data && success.data.data) {
      this.setState({
        shipment: success.data.data,
        shipmentDataPlane: success.data.plane,
        tabIndex: (typeof this.props.location !== 'undefined' && typeof this.props.location.state !== 'undefined') ? this.props.location.state.tabIndex : 0,
      }, () => this.handleGetEvents(success.data.data.ShipmentId));
    }
  }
  handleOnTabChange = (evt, data) => {
    // console.log('inside tab Index');
    this.setState({ tabIndex: data.activeIndex });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getShipment(this.props.location.pathname.split("/")[2]);
    }
  }

  handleGetEvents = async (shipmentId) => {
    let moduleId = 3;
    let events = await Axios.get(`${Services.BOOKING.path}containers-booking/containerEventsTimeline/${moduleId}/${shipmentId}`);
    //let events = await Axios.get(`http://localhost:3017/containers-booking/containerEventsTimeline/${moduleId}/${shipmentId}`);
    if (events.data) {
      let update = false;
      for (let index = events.data.steps.length; index >= 0; index--) {
        const element = events.data.steps[index];
        if (element && element.completed) update = true;
        if (update) element.completed = true;
      }
      this.setState({ steps: events.data.steps, fullLoader: true }, () => {
        this.setMarkers();
      });
    }
  }

  setMarkers = () => {
    const intervalId = setInterval(() => {
      let { animatedMarker, shipment, count } = this.state;
      if (shipment.To && shipment.To.Location && shipment.To.Location.Latitude
        &&
        shipment.From && shipment.From.Location && shipment.From.Location.Latitude
        &&
        count < locations.length) {
        // animatedMarker.position = [{
        //   lat: parseFloat(shipment.From.Location.Latitude),
        //   lng: parseFloat(shipment.From.Location.Longitude)

        // }, {
        //   lat: parseFloat(shipment.To.Location.Latitude),
        //   lng: parseFloat(shipment.To.Location.Longitude)

        // }];
        // animatedMarker.position.push({ lat: parseFloat(shipment.From.Location.Latitude * count) });
        // animatedMarker.position.push({ lat: parseFloat(shipment.From.Location.Latitude * count) });
        if (animatedMarker.position) {
          animatedMarker.position.push({
            lat: locations[count][0],
            lng: locations[count][1]
            // lat: parseFloat(animatedMarker.position ? animatedMarker.position.lat : markers[0].position.lat) + 5,
            // lng: parseFloat(animatedMarker.position ? animatedMarker.position.lng : markers[0].position.lng) - 5
          });
        } else {
          animatedMarker.position = [{
            lat: locations[0][0],
            lng: locations[0][1]
            // lat: parseFloat(animatedMarker.position ? animatedMarker.position.lat : markers[0].position.lat) + 5,
            // lng: parseFloat(animatedMarker.position ? animatedMarker.position.lng : markers[0].position.lng) - 5
          }, {
            lat: locations[1][0],
            lng: locations[1][1]
            // lat: parseFloat(animatedMarker.position ? animatedMarker.position.lat : markers[0].position.lat) + 5,
            // lng: parseFloat(animatedMarker.position ? animatedMarker.position.lng : markers[0].position.lng) - 5
          }];
        }

        animatedMarker.icon =
        {
          url: 'https://fsposeidon.blob.core.windows.net/files/placeholder.png',
          scaledSize: new window.google.maps.Size(32, 32),
        }

        animatedMarker.tittle = "Actual";
      }
      this.setState({ animatedMarker, count: count + 1 });
    }, 7000);
  }


  render() {
    const { shipment, shipmentDataPlane, steps, fullLoader, tabIndex, animatedMarker } = this.state;
    return (
      <ContentWrapper>
        <Loader hidden={fullLoader} />
        <div className="content-heading">
          <Row>
            <legend><em className="fas fa-globe fa-1x mr-2"></em>Shipment Details</legend>
          </Row>
        </div>
        <Tab
          menu={{ secondary: true, pointing: true, className: 'myWrapped' }}
          menuPosition='right'
          activeIndex={tabIndex}
          onTabChange={this.handleOnTabChange}
          panes={[
            { menuItem: 'Summary', render: () => <Suspense fallback={<div>Loading...</div>}><Summary rolId={this.props._rolId} shipment={shipment} shipmentDataPlane={shipmentDataPlane} steps={steps} animatedMarker={animatedMarker}></Summary></Suspense> },
            { menuItem: 'Events', render: () => <Suspense fallback={<div>Loading...</div>}><Events shipment={shipment} shipmentDataPlane={shipmentDataPlane}></Events></Suspense> },
            { menuItem: 'Comments', render: () => <Suspense fallback={<div>Loading...</div>}><Comments shipment={shipment} shipmentDataPlane={shipmentDataPlane} _user={this.props._user}></Comments></Suspense> },
            { menuItem: 'Documents', render: () => <Suspense fallback={<div>Loading...</div>}><Documents rolId={this.props._rolId} shipment={shipment} shipmentDataPlane={shipmentDataPlane} _user={this.props._user}></Documents></Suspense> },
          ]} />
      </ContentWrapper>
    );
  }
}

export default withRouter(Tracking);


