import React, { Component } from 'react';
import { ExpansionPanel, Slide, ExpansionPanelSummary, ExpansionPanelDetails, Button, Dialog } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachMore from './add';
// import restService from '../../../../../../services/restService'
import Icon from '@material-ui/core/Icon';
import Table from '../../../../../template/Table';
import Errors from '../../../../../template/Errors';
import { Card, CardBody } from 'reactstrap';
// const rest = new restService();

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class _List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
      AttachMore: <AttachMore selected={this.props.selected} sendProps={this.reciceveSelected}></AttachMore>,
      data: [],
      open: false,
      columns: [
        { label: 'PO Number', key: 'PONumber' },
        { label: 'Split No.', key: 'SplitNo'},
        { label: 'Creation Date', key: 'POCreateDate' },
        { label: 'Promise Cargo Ready', key: 'PromiseCargoReadyDate' },
        { label: 'QTY Requested', key: 'TotalQtyOrdered' },
        { label: 'QTY Confirmed', key: 'TotalQtyConfirmed' },
        { label: 'Country Of Origin', key: 'CountryOrigin' },
        { label: 'Actions', key: 'PONumber', custom: (value) => this.custom1(value) },
      ],
      error: {
        open: false,
        message: '',
        type: 'error'
      }
    };
  }
    custom1 = (value) => (
      <center>
        <Button variant="fab" mini color="secondary" aria-label="Add" onClick={this.deleteLine(value.PONumber)}>
          <Icon className="icon" color="inherent" style={{ fontSize: 20 }}>
                    remove
          </Icon>
        </Button>
      </center>
    )
    componentDidMount = () => {
      this.setState({ selected: this.props.selected });
    }
    deleteLine = PONumber => event => {
      let { selected } = this.state;
      let index = selected.findIndex(e => e.PONumber === PONumber);
      selected.splice(index, 1);
      this.setState({
        selected,
        error: {
          message: 'A PO has been removed correctly',
          open: true,
          type: 'warning'
        }
      }, () => this.props.sendSelected(this.state.selected));
    }

    removeLine = (index, selected) => {
      selected.splice(index, 1);
      this.setState({
        error: {
          message: 'A line item has been removed correctly',
          open: true,
          type: 'warning'
        }
      });
    }

    handleClickOpen = () => this.setState({ open: true })

    handleClose = () => this.setState({ open: false })

    sendSelected = (array) => this.setState({ open: false, selected: array }, () => this.props.sendSelected(this.state.selected))
    recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

    render() {
      const { selected, columns } = this.state;
      return (
        <Card className="myCard">
          <CardBody>
            <ExpansionPanel elevation={0} defaultExpanded>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <legend><em className="fas fa-paperclip fa-1x mr-2"></em>Attached Orders</legend>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-12">
                    <Table
                      columns={columns}
                      data={selected}
                      itemsByPage={10}
                      options={
                        {
                          pagination: true,
                          search: false,
                          downloads: {
                            xlsx: false,
                            pdf: false
                          },
                          selectItems: false
                        }
                      }
                    />
                  </div>
                  <div className="col-md-1 col-md-offset-11" style={{ paddingRight: '2%' }}>
                    <center>
                      <Button variant="fab" mini color="primary" aria-label="Add" style={{}} onClick={this.handleClickOpen} >
                        <Icon className="icon" color="inherent" style={{ fontSize: 20 }}>
                            add
                        </Icon>
                      </Button>
                    </center>
                  </div>
                </div>
                <Dialog
                  fullScreen
                  open={this.state.open}
                  onClose={this.handleClose}
                  TransitionComponent={Transition}>
                  <AttachMore selected={selected} sendSelected={this.sendSelected}></AttachMore>
                </Dialog>
                <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardBody>
        </Card>

      );
    }
}


export default _List;

