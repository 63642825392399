import React, { Component } from 'react';
import Line from './lineComments'
import AddComment from './addComments'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            comments : []
        };
    }

    componentDidMount = () => {
        this.setState({
            comments: this.props.data,
        }, () => ({}) )
    }

    toggle = evt => {
        this.setState(prevState => ({
            modal: !prevState.modal
        })); 
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <Line comments = {this.state.comments}></Line>
                    </div>
                </div>
                {/* Boton para desplegr modal y agregar comentarios */}
                {/* <div className="row">
                    <div style={{position:"fixed", bottom:"334px", right:"0px"}} className="col-1">
                        <Fab color="primary" size="small" aria-label="add" onClick={this.toggle}>
                            <AddIcon />
                        </Fab>
                    </div>
                </div> */}
                
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}><b>Crear nuevo comentario</b></ModalHeader>
                    <ModalBody>
                        <AddComment type="0"  catalogs={this.props.catalogs}/* callback={this.callback} */ /* onError={this.onError} */ /* id={this.state.id} */ />
                    </ModalBody>
                </Modal> 
            </>

        );
    }
}

export default Comments;