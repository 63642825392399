import React, { Component } from 'react'
import 'react-daterange-picker/dist/css/react-calendar.css'
// import COST from '../models/FiltersModel'
import Datetime from 'react-datetime';
// import '../Filters/style';
import { Cost } from '../models/FiltersModel';

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            results: [],
            start: null,
            end: null,
        }
    }
    componentDidMount = () => {
    }

    componentWillReceiveProps = (nextProps)=>{
    }
    clear = () => {
        this.setState({
            range: '',
            rangeString: '',
            actionDelete: '',
        }, () => {
            this.props.refreshTable(Cost.data)
        })
    }

    toTimestamp = (strDate) => {
        var datum = Date.parse(strDate);
        return datum / 1000;
    }

    filterRange = () => {
        if(this.state.start && this.state.end){
            let _start = this.toTimestamp(this.state.start)
            let _end = this.toTimestamp(this.state.end)
            let results = []
            Cost.data.forEach((e) => {
                let date = e.invoiceDate.split('/')[1] + '/' + e.invoiceDate.split('/')[0] + '/' + e.invoiceDate.split('/')[2]
                let timestamp = Math.floor(new Date(date) / 1000)
                if (timestamp >= _start && timestamp <= _end) {
                    results.push(e)
                }
            })
            this.setState({ results }, () => {
                this.props.refreshTable(results)
            })
        }
        else{
            let results = Cost.data
            this.setState({
                results
            },()=>this.props.refreshTable(results))
        }
    }


    OnChangeInputData = id => event => {
        try{
            this.setState({ [id]: event.format("MM-DD-YYYY") }, () =>  this.filterRange())
        }
        catch(e){
            this.setState({[id]:''},()=> this.filterRange())
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-6">
                    <center><label className="color" style={{fontWeight:'bold'}}>Invoicing start date</label></center>
                        <Datetime type="Date" name="start" id="start" onChange={this.OnChangeInputData('start')} value={this.state.start} className=""></Datetime>
                    </div>
                    <div className="col-6">
                        <center><label className="color"  style={{fontWeight:'bold'}}>invoicing end date </label></center>
                        <Datetime type="Date" name="end" id="end" onChange={this.OnChangeInputData('end')} value={this.state.end} className=""></Datetime>
                    </div>
                    <center>
                        <div className="col-12">
                            <center>{this.state.range}</center>
                        </div>
                    </center>
                </div>
            </>
        )
    }
}

export default DataTable;