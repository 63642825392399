import React from "react";
import { Skeleton } from "@mui/material";

function CountCard(props) {
  const [cards, setCards] = React.useState([]);
  function prepareCards() {
    let _cards = [];
    props.config.forEach((conf) => {
      let card = null;
      props.data.forEach((d) => {
        if (conf.id === d[conf.key]) {
          card = (
            <div className={conf.classCard}>
              <div
                style={{
                  borderRadius: "1.5rem",
                  backgroundColor: conf.color,
                }}
                className={
                  "shadow card flex-row align-items-center align-items-stretch border-0 "
                }
              >
                {props.loading ? (
                  <Skeleton height={80} width="30%" />
                ) : (
                  <div className={conf.propertiesIcon}>{conf.icon}</div>
                )}
                {props.loading ? (
                  <Skeleton height={80} width="70%" className="margin_left" />
                ) : (
                  <div className={conf.propertiesTitle}>
                    <div className={conf.titleClass}>{d.CardName}</div>
                    <div className={conf.totalClass}>{d[conf.keyTotal]}</div>
                  </div>
                )}
              </div>
            </div>
          );
        }
      });
      if (card != null) {
        _cards.push(card);
      }
    });
    setCards(_cards);
  }
  React.useEffect(() => {
    const { data, config } = props;
    if (data && data.length > 0 && config && config.length > 0) {
      prepareCards();
    }
  }, [props.config, props.data]);

  return <div className="row">{cards}</div>;
}

export default CountCard;
