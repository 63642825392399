import React, { Component } from 'react';
import { Button as _Button, Input, Tab, Search, Grid, TransitionablePortal, Segment, Header } from 'semantic-ui-react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import Table from '../../Components/Table';
import { Button, Fab, ExpansionPanel, ExpansionPanelDetails, Checkbox } from '@material-ui/core/';
import { Card, CardBody } from 'reactstrap';
import { Services } from './Services';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import AlertNotification from './AlertNotification';
import AddIcon from '@material-ui/icons/Add';
import './style.scss';


const config = [
  { label: 'Id', id: 'CustomerId', type: 'simple' },
  { label: 'Customer name', id: 'CustomerName', type: 'simple' },
  { label: 'Customer code', id: 'CustomerCode', type: 'simple' },
  { label: 'Telephone', id: 'Telephone', type: 'simple' },
  { label: 'Tax ID', id: 'TaxId', type: 'simple' },
  { label: 'Country', id: 'Country', type: 'simple' }
];

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: true,
      findCustomer: false,
      findAlert: false,
      alertSelected: {},
      rows: [],
      openPortal: false,
      searchInput: '',
      customers: [],
      columns: [
        { label: 'ID', key: 'CustomerId', custom: (value) => <em onClick={() => this.setState({ customer: value, findCustomer: true }, () => { this.getCustomerUsers(); this.buildRows(); })}>  <label style={{ color: '#349ced', cursor: 'pointer' }}>{value.CustomerId}</label> </em> },
        { label: 'Customer name', key: 'CustomerName' },
        { label: 'Customer Code', key: 'CustomerCode' },
        { label: 'Interglobo Id', key: 'IntergloboId' },
        { label: 'Tax Id', key: 'TaxId' },
        { label: 'Telephone', key: 'Telephone' },
        { label: 'Country', key: 'Country' },
        { label: 'Associated User', key: 'AssociatedUsers' }
      ],
      customer: {
      },
      allUsers: [],
      searchUsers: {
        columns: [
          {
            id: 'Name',
            label: 'Name'
          },
          {
            id: 'LastName',
            label: 'Last Name'
          },
          {
            id: 'Email',
            label: 'Email'
          },
          {
            id: 'Checked',
            label: ''
          }
        ],
        data: [],
        allData: []
      },
      usersAdded: {
        columns: [
          {
            id: 'Name',
            label: 'Name'
          },
          {
            id: 'LastName',
            label: 'Last Name'
          },
          {
            id: 'Email',
            label: 'Email'
          },
          {
            id: 'Delete',
            label: ''
          }
        ],
        data: []
      },
      mailList: {
        columns: [
          {
            id: 'Email',
            label: 'Mail List'
          },
          {
            id: 'Delete',
            label: ''
          }
        ],
        data: []
      },
      alertsData: [],
      notificationsData: [],
      activeTab: 0
    };
  }

  componentDidMount = () => {
    this.getlist();
  }

  componentWillReceiveProps = (nextProps) => {

  }

  getlist = () => {
    axios.get(`${Services.ADMIN.path}admin/listCustomer`).then(success => {
      this.setState({
        customers: success.data.data
      });
    }).catch(error => {
    });
  }

  getCustomerUsers = () => {
    axios.get(`${Services.ADMIN.path}admin/v1/admin/infoCustomer/${this.state.customer.CustomerId}`).then(success => {
      let newSearch = this.state.searchUsers;
      newSearch.data = success.data.resp.array;
      newSearch.allData = success.data.resp.array;
      this.setState({
        allUsers: success.data.resp.array,
        searchUsers: newSearch
      }, () =>{this.getAlertsCustomer();});
    }).catch(error => {
    });
  }

  getAlertsCustomer = () => {
    axios.get(`${Services.ALERTS.path}alerts/AlertsCustomer/${this.state.customer.CustomerId}`).then(success => {
      this.setState({
        alertsData: success.data.data.alerts,
        notificationsData: success.data.data.notifications
      });
    }).catch(error => {
    });
  }

  initUserData = () => {
    let { activeTab, allUsers, alertSelected, usersAdded, searchUsers } = this.state;
    let str = alertSelected.UserIds;
    var usersIds = [];
    if(str){
      usersIds = str.split(',');
    }
    usersIds = usersIds.filter((item, index) => usersIds.indexOf(item) === index);
    usersIds = usersIds.filter((item) => (item !== null || item !== ''));
    let newUsersSearch = [];
    let newUsersAdded = [];
    allUsers.map(e =>{
      let findUserAdd = false;
      usersIds.map(f =>{
        if(f === e.UserId.toString()){
          findUserAdd = true;
          newUsersAdded.push(e);
        }
      });
      if(!findUserAdd){
        newUsersSearch.push(e);
      }
    });
    usersAdded.data = newUsersAdded;
    searchUsers.data = newUsersSearch;
    searchUsers.allData = newUsersSearch;
    this.setState({usersAdded: usersAdded, searchUsers: searchUsers});
  }

  initCopyData = () =>{
    let { activeTab, allUsers, alertSelected, mailList } = this.state;
    let str = alertSelected.Emails;
    var usersIds = [];
    if(str){
      usersIds = str.split(',');
    }
    usersIds = usersIds.filter((item, index) => usersIds.indexOf(item) === index);
    usersIds = usersIds.filter((item) => (item.length > 0));
    usersIds = usersIds.map(e =>{
      return(
        {Email: e}
      );
    });
    mailList.data = usersIds;
    this.setState({mailList: mailList});
  }

  searchAll = (search) => {
    let {searchUsers} = this.state;
    let newSearch = searchUsers;
    newSearch.data = newSearch.allData;
    if(search.value.length > 0){
      const newData = newSearch.allData.filter(function(item) {
        const nameData = item.Name ? item.Name.toUpperCase() : ''.toUpperCase();
        const lastData = item.LastName ? item.LastName.toUpperCase() : ''.toUpperCase();
        const emailData = item.Email ? item.Email.toUpperCase() : ''.toUpperCase();
        const textData = search.value.toUpperCase();
        return ((nameData.indexOf(textData) > -1) || (lastData.indexOf(textData) > -1) || (emailData.indexOf(textData) > -1));
      });
      newSearch.data = newData;
    }
    this.setState({searchUsers: newSearch, searchInput: search.value});
  }

  buildRows = () => {
    let { customer } = this.state;
    let rows = config.map((e) => {
      return (
        <div className="col-md-2 col-lg-2 col-sm-6" style={{ paddingTop: '1%' }}>
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span style={{ color: '#545350', fontWeight: 'bold' }}  >
                {e.label}
              </span>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-6">
              <span >{customer[e.id] ? customer[e.id] : ''}</span>
            </div>
          </div>
        </div>
      );
    });
    this.setState({
      rows
    });
  }

  addAllClick = () =>{
    let { searchUsers, usersAdded } = this.state;
    let newUsers = usersAdded.data;
    searchUsers.data.map(e =>{
      newUsers.push(e);
    });
    let newSearch = searchUsers;
    newSearch.data = [];
    newSearch.allData = [];
    let newAdd = usersAdded;
    newAdd.data = newUsers;
    this.setState({searchUsers: newSearch, usersAdded: newAdd, buttonDisabled: false});
  }

  deleteAllClick = () =>{
    let { searchUsers, usersAdded } = this.state;
    let newUsers = searchUsers.data;
    usersAdded.data.map(e =>{
      e.Checked = false;
      newUsers.push(e);
    });
    let newSearch = searchUsers;
    newSearch.data = newUsers;
    newSearch.allData = newUsers;
    let newAdd = usersAdded;
    newAdd.data = [];
    this.setState({searchUsers: newSearch, usersAdded: newAdd, buttonDisabled: false});
  }

  addClick = () =>{
    let { searchUsers, usersAdded } = this.state;
    let newUsersAdded = usersAdded.data;
    let newUsersSearch = searchUsers.data.filter(e =>{
      if(e.Checked){
        e.Checked = false;
        newUsersAdded.push(e);
      }
      else{
        return e;
      }
    });
    let newSearch = searchUsers;
    newSearch.data = newUsersSearch;
    newSearch.allData = newUsersSearch;
    let newAdd = usersAdded;
    newAdd.data = newUsersAdded;
    this.setState({searchUsers: newSearch, usersAdded: newAdd, buttonDisabled: false});
  }

  deleteClick = (e, index) =>{
    let { searchUsers, usersAdded } = this.state;
    let newUsersSearch = searchUsers.data;
    usersAdded.data.splice(index, 1);
    let newUsersAdded = usersAdded;
    e.Checked = false;
    newUsersSearch.push(e);
    let newSearch = searchUsers;
    newSearch.data = newUsersSearch;
    newSearch.allData = newUsersSearch;
    this.setState({searchUsers: newSearch, usersAdded: newUsersAdded, buttonDisabled: false});
  }

  deleteMail = (index, i) =>{
    let { mailList, indexSelected } = this.state;
    mailList.data.splice(indexSelected, 1);
    this.setState({mailList: mailList, openPortal: false, buttonDisabled: false});
  }

  changeTab = (result) =>{
    this.setState({activeTab: result.activeIndex});
  }

  saveAlerts = () =>{
    let { alertSelected, mailList, usersAdded } = this.state;
    var dataSave = alertSelected;
    dataSave.usersAdded = usersAdded.data;
    dataSave.emailAdded = mailList.data;
    axios.put(`${Services.ALERTS.path}alerts/AlertsCustomer`,dataSave).then(success => {
      this.setState({alertSelected: {}, findAlert: false});
      this.getAlertsCustomer();
    }).catch(error => {
    });
  }

  selectAlert = (alert) =>{
    if(alert){
      if(!alert.disabled){
        this.setState({alertSelected: {}, findAlert: false});
      }
      else{
        this.setState({alertSelected: alert, findAlert: true},() =>{
          this.initUserData();
          this.initCopyData();
        });
      }
    }
    else{
      this.setState({alertSelected: {}, findAlert: false});
    }
  }

  render() {
    let { alertsData, tabRef, notificationsData, searchUsers, usersAdded, searchInput, mailList, openPortal, customer, columns, customers, findCustomer, findAlert } = this.state;
    return (
      <ContentWrapper>
        <div>
          <div className="content-heading">
            <div className="row">
              <em className="fa-1x mr-2 far fa-bell"></em>
              Alerts and Notifications
            </div>
          </div>
        </div>
        <Card>
          <CardBody className="myCard">
            <div className="col-md-12">
              <strong style={{ marginLeft: '-12px' }}>Customers</strong>
            </div>
            <br></br>
            <div className="col-md-12">
              <Table
                columns={columns}
                data={customers}
                itemsByPage={10}
                options={
                  {
                    pagination: true,
                    search: true,
                    downloads: {
                      xlsx: false,
                      pdf: false
                    },
                  }
                }
              />
            </div>
          </CardBody>
        </Card>
        {
          findCustomer ?
            <div>
              <Card>
                <CardBody className="myCard">
                  <div className="col-md-12">
                    <strong style={{ marginLeft: '-12px' }}>Customer Information</strong>
                  </div>
                  <br></br>
                  <div className="row" style={{ width: '100%', marginLeft: '20px' }}>
                    <img src={customer.URLLogo ? customer.URLLogo : 'https://fsposeidon.blob.core.windows.net/files/user-default-grey.png'} width="117" height="117" className="circule" />
                    <div style={{ marginTop: '13px' }} className="col-md-10">
                      <div className="row">
                        {this.state.rows}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody className="myCard">
                  <Tab
                    loading={true}
                    menu={{ secondary: true, className: 'myWrapped' }}
                    onTabChange={(ev, result) => {this.changeTab(result);}}
                    menuPosition='right'
                    panes={[
                      { menuItem: 'Alerts', render: () => <AlertNotification data={alertsData} getAlertsCustomer={this.getAlertsCustomer} selectAlert={(alert) => {this.selectAlert(alert);}} customer={customer} type="1"/> },
                      { menuItem: 'Notifications', render: () => <AlertNotification data={notificationsData} customer={customer} selectAlert={(alert) => {this.selectAlert(alert);}} getAlertsCustomer={this.getAlertsCustomer} type="2"/> },
                    ]} />
                </CardBody>
              </Card>
              {
                findAlert ?
                  <div>
                    <Card>
                      <CardBody className="myCard">
                        <div className="col-md-12">
                          <strong style={{ marginLeft: '-12px' }}>Alerts Assignation</strong>
                        </div>
                        <br></br>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-5" style={{backgroundColor: '#F5F5F5'}}>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-6" style={{ marginTop: 15, fontWeight: 'bold', fontSize: 18}}>
                              Search User
                                  </div>
                                  <div className="col-6" style={{ textAlign: 'right', marginTop: 10, marginBottom: 10}}>
                                    <Search
                                      input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                                      placeholder='User Name'
                                      onSearchChange={(e, result) =>{this.searchAll(result);}}
                                      value={this.state.searchInput}
                                      open={false}
                                      name="search"
                                      id="search"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 scrollUsers">
                                <table className="table" id="tableAbsoulte">
                                  <tr>
                                    {
                                      searchUsers.columns.map((e, index) => {
                                        return(
                                          <th id={e.id} key={index}>{e.label}</th>
                                        );
                                      })
                                    }
                                  </tr>
                                  <tbody className="table-body">
                                    {
                                      searchUsers.data.map((e, index) =>{
                                        let td = searchUsers.columns.map((f, i) => {
                                          if(f.id === 'Checked'){
                                            return (
                                              <td key={i}>
                                                <Checkbox
                                                  checked={e.Checked}
                                                  onChange={() =>{
                                                    let newSearchUsers = searchUsers;
                                                    newSearchUsers.data[index].Checked = !newSearchUsers.data[index].Checked;
                                                    this.setState({searchUsers: newSearchUsers});
                                                  }}
                                                  value={e[f.id]}
                                                />
                                              </td>
                                            );
                                          }
                                          else{
                                            return (
                                              <td key={i}>{e[f.id]}</td>
                                            );
                                          }
                                        });
                                        return (
                                          <tr>{td}</tr>
                                        );
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-2" style={{textAlign: 'center', alignItems: 'center', display: 'grid'}}>
                              <div className="buttonTable">
                                <Button onClick={() => this.addAllClick()}><p className="addAll">Add All</p></Button>
                              </div>
                              <div className="buttonTable">
                                <Button onClick={() => this.addClick()}><span className="addCircle" style={{backgroundColor: 'transparent !important'}}><div className="addCircleInside">Add</div></span></Button>
                              </div>
                              <div className="buttonTable">
                                <Button onClick={() => this.deleteAllClick()}><p className="deleteAll">Delete All</p></Button>
                              </div>
                            </div>
                            <div className="col-5" style={{backgroundColor: '#F5F5F5'}}>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12" style={{ marginTop: 15, fontWeight: 'bold', fontSize: 18, marginBottom: 15}}>
                              User Added
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 scrollUsers">
                                <table className="table scrollUsers" id="tableAbsoulte">
                                  <tr>
                                    {
                                      usersAdded.columns.map((e, index) => {
                                        return(
                                          <th id={e.id} key={index}>{e.label}</th>
                                        );
                                      })
                                    }
                                  </tr>
                                  <tbody className="table-body">
                                    {
                                      usersAdded.data.map((e, index) =>{
                                        let td = usersAdded.columns.map((f, i) => {
                                          if(f.id === 'Delete'){
                                            return (
                                              <td key={i}>
                                                <em onClick={() => {this.deleteClick(e, index);}} className="fa-2x mr-2 far fa-trash-alt" style={{ color: '#FC3737' }}></em>
                                              </td>
                                            );
                                          }
                                          else{
                                            return (
                                              <td key={i}>{e[f.id]}</td>
                                            );
                                          }
                                        });
                                        return (
                                          <tr>{td}</tr>
                                        );
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody className="myCard">
                        <div className="col-md-12">
                          <strong style={{ marginLeft: '-12px' }}>Copy to</strong>
                        </div>
                        <br></br>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-5">
                        Email
                              <div className="row">
                                <Input
                                  placeholder='Email'
                                  name="email"
                                  className="emailInput"
                                  id="email"
                                  value={this.state.emailInput}
                                  onChange={(ev, result) => {this.setState({emailInput: result.value});}}
                                />
                                <Fab color='lightBlue' className='MuiFab-lightBlue customSmall' aria-label="add" onClick={() =>{
                                  let { emailInput } = this.state;
                                  if(emailInput){
                                    if(emailInput.length>0){
                                      mailList.data.push({Email: emailInput});
                                      this.setState({mailList: mailList, emailInput: '', buttonDisabled: false});
                                    }
                                  }
                                }}>
                                  <AddIcon fontSize="small"/>
                                </Fab>
                              </div>
                            </div>
                            <div className="col-2">
                            </div>
                            <div className="col-5">
                              <table className="table" id="tableAbsoulte">
                                <tr>
                                  {
                                    mailList.columns.map((e, index) => {
                                      return(
                                        <th id={e.id} key={index}>{e.label}</th>
                                      );
                                    })
                                  }
                                </tr>
                                <tbody className="table-body">
                                  {
                                    mailList.data.map((item, index1) =>{
                                      let td = mailList.columns.map((f, i) => {
                                        if(f.id === 'Delete'){
                                          return (
                                            <td style={{textAlign: 'right'}} key={i}>
                                              <em onClick={() => {this.setState({openPortal: true, indexSelected: index1, mailSelected: item});}} className="fa-2x mr-2 far fa-trash-alt" style={{ color: '#FC3737' }}></em>
                                              <TransitionablePortal open={openPortal} transition={{animation: 'drop', duration: 500}}>
                                                <Segment style={{ left: '70%', position: 'fixed', top: '50%', zIndex: 1000 }}>
                                                  <Header>Delete Mail</Header>
                                                  <p>Deleting the email account removes all assigned alerts and notifications.
                                            Are you sure you want to delete this email?</p>
                                                  <div className="row">
                                                    <div className="col-6">
                                                      <_Button content='Cancel' negative onClick={() => this.setState({openPortal: false})}/>
                                                    </div>
                                                    <div className="col-6" style={{textAlign: 'right'}}>
                                                      <_Button content='Accept' negative={false} style={{backgroundColor: '#2B98F0', color: 'white'}} onClick={() => {this.deleteMail(item, index1);}}/>
                                                    </div>
                                                  </div>
                                                </Segment>
                                              </TransitionablePortal>
                                            </td>
                                          );
                                        }
                                        else{
                                          return (
                                            <td style={{textAlign: 'left'}} key={i}>{item[f.id]}</td>
                                          );
                                        }
                                      });
                                      return (
                                        <tr>{td}</tr>
                                      );
                                    })
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div style={{textAlign: 'center', marginTop: 50, marginBottom: 50}}>
                          <Button disabled={this.state.buttonDisabled} variant="contained" className="saveButton" onClick={() => {this.saveAlerts();}}><p>Save</p></Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div> :<></>
              }
            </div> :
          <></>
        }
      </ContentWrapper>
    );
  }
}

export default Alerts;
