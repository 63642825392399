import React from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, Progress, Button } from 'reactstrap';
import PopUp from './../Popup';
import './styles.scss';

export const TRANSPORT_MODE_STATUS = {
  LCL: <em className="fa-2x mr-2 fas fa-dolly-flatbed " />,
  FCL: <em className="fa-2x mr-2 fas fa-ship " />,
  Air: <em className="fa-2x mr-2 fas fa-plane " />
};

const Text = ({label, element, keyVal}) => {
  return (
    <div className='mb-sm-1 w-100 d-flex flex-wrap flex-column flex-md-column flex-sm-column align-items-center card-items'>
      <div className='card-label'>{label}</div>
      <div className='card-value overflow-auto'>
        {element[keyVal]}
      </div>
    </div>
  );
};

const Status = ({label, element, keyVal}) => {
  return (
    <div className='mb-sm-1 w-100 d-flex flex-column flex-md-column flex-sm-column align-items-center card-items'>
      <div className='card-label'>{label}</div>
      <div className='card-value'>
        <Button style={{ fontSize: '11px' }} className="btn-oval" color="primary" onClick={() =>{}}>{element[keyVal]}</Button>
      </div>
    </div>    
  );
};

const LinkCard = ({label, element, keyVal, linkVal, link}) => {
  return (
    <div className='mb-sm-1 w-100 d-flex flex-column flex-md-column flex-sm-column align-items-center card-items'>
      <div className='card-label'>{label}</div>
      <div className='card-value'>
        <Link to={link + element[linkVal]} style={{ textDecoration: 'none' }}><span style={{ color: '#0097dc', 'fontWeight': 'bold' }}>{element[keyVal]}</span></Link>
      </div>
    </div>    
  );
};

const ImgCard = ({label, element, keyVal}) => {
  return (
    <div className='mt-2 mb-sm-1 w-100 d-flex flex-column flex-md-column flex-sm-column align-items-center'>
      <div className='card-label'>{label}</div>
      <div className='card-value mb-3'>
        {TRANSPORT_MODE_STATUS[element[keyVal]]}
      </div>
    </div>    
  );
};

const PopUpCard = ({label, element, keyVal, title}) => {
  return (
    <div className='mb-sm-1 w-100 d-flex flex-column flex-md-column flex-sm-column align-items-center card-items'>
      <div className='card-label'>{label}</div>
      <div className='card-value'>
        <PopUp title={title} content={element[keyVal]}>
          {element[keyVal]}
        </PopUp>        
      </div>
    </div>    
  );
};

const ProgressCard = ({label, element, keyVal}) => {
  return (
    <div className='mb-sm-1 w-100 d-flex flex-column flex-md-column flex-sm-column align-items-center progress-card'>
      <div className='card-label'>
        <span style={{ color: 'green' }}>{label}</span>
      </div>
      <div className='progress'>
        <Progress color={'success'} value={element[keyVal]} />        
      </div>
    </div>    
  );
};

function typesCreator(conf={}, element=''){
  switch (conf.type) {
  case 'text':
    return <Text label={conf.label ? conf.label: ''} element={element} keyVal={conf.id ? conf.id: ''}></Text>;
    
  case 'status':
    return <Status label={conf.label ? conf.label: ''} element={element} keyVal={conf.id ? conf.id: ''}></Status>;
    
  case 'link':
    return <LinkCard label={conf.label ? conf.label: ''} element={element} keyVal={conf.id ? conf.id: ''} linkVal={conf.linkVal ? conf.linkVal: ''} link={conf.link ? conf.link: ''}></LinkCard>;

  case 'img':
    return <ImgCard label={conf.label ? conf.label: ''} element={element} keyVal={conf.id ? conf.id: ''}></ImgCard>;

  case 'popup':
    return <PopUpCard label={conf.label ? conf.label: ''} element={element} keyVal={conf.id ? conf.id: ''} title={conf.title ? conf.title : ''}></PopUpCard>;

  case 'progress':
    return <ProgressCard label={conf.label ? conf.label: ''} element={element} keyVal={conf.id ? conf.id: ''}></ProgressCard>; 

  default:
    break;
  }
}


/**
 * Card Component with Items
 * @param {*} props 
 */

function CardItems(props){
  const [elements, setElements] = React.useState('');

  React.useEffect(() => {
    let ELEMENTS = [];
    for (let index = 0; index < props.config.length; index++) {
      const conf = props.config[index];
      ELEMENTS.push(typesCreator(conf, props.element));
    }
    setElements(ELEMENTS);
  }, [props.element, props.config]);

  return (
    <div className='col-12 col-sm-12 col-md-12'>
      <Card className="_clickCard myCard">
        <CardBody className={`
            d-flex d-sm-flex d-md-flex 
            flex-column flex-md-row flex-sm-column 
            justify-content-start
            flex-fill
          `}>
          {elements}
        </CardBody>
      </Card>
    </div>
  );
}

CardItems.propTypes = {
  element: propTypes.object,
  config: propTypes.array
};
CardItems.defaultProps = {
  element: {},
  config: []
};

export default CardItems;