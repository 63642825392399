import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import Popover from './../../../../Global/Popup';

import DialogComponent from './../../../../Global/Dialog';
import AssociatePO from './../AssociatePO/';
import DisassociatePO from './../DisassociatePO/';


export const columns = (getShipmentList, customer, rmShipmentModal, user) => [
  { label: 'Shipment No.', key: 'ShipmentNumber', minWidth: 80 , fixed: true, align: 'center', custom: (value) => (
    <Col md={12} xl={12}>
      <Row>
        <Col md={12}>
          <Link to={'/tracking/' + value.ShipmentNumber}>{value.ShipmentNumber}</Link>
        </Col>
      </Row>
    </Col>
  )},
  { label: 'Transport', key: 'TransportMode', minWidth: 80, align: 'center'},
  { label: 'Status', key: 'Status', minWidth: 80, align: 'center', custom: (value) => ( <Button className={value.StatusId === 13 ? 'btn-oval _gray_button': 'btn-oval'} color={'primary'} onClick={() =>{}}>{value.Status}</Button> ) },
  { label: 'Supplier', key: 'Supplier', minWidth: 190, align: 'center'},
  { label: 'PO No.', key: 'POs', minWidth: 80, align: 'center', custom: (value) => (
    <div>
      <Popover title="PO No." content={value.POs} >
        <p>{ value.POs }</p>
      </Popover>
    </div>
  ) },
  { label: 'From', key: 'Origin', minWidth: 80, align: 'center'},
  { label: 'To', key: 'Destination', minWidth: 80, align: 'center'},
  { label: 'ETD', key: 'ETD', minWidth: 80, align: 'center'},
  { label: '', key: '', minWidth: 80, align: 'center', custom: (value) => (
    <DialogComponent
      disabled={false}
      color={'primary'}
      icon={'settings_backup_restore'}
      actionText={'Rollback'}
      title='Rollback'
      message='Are you sure you want to rollback the Shipment? If you continue the Shipment will be undone'
      cancelText='Cancel'
      agreeText='Yes, I´m sure'
      agree={()=> rmShipmentModal(value)}
    /> 
  )},
  {
    label: '',
    minWidth: 120, 
    align: 'center',
    custom: (element) => (
      <AssociatePO restart={getShipmentList} _customer={customer} _user={user} shipment={element.ShipmentNumber}/>
    )
  },
  {
    label: '',
    minWidth: 190, 
    align: 'center',
    custom: (element) => (
      <DisassociatePO restart={getShipmentList} _user={user} shipment={element.ShipmentNumber}/>
    )
  }
];
