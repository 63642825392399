import React from 'react';
// import Row from 'reactstrap/lib/Row';
import { Comment } from 'semantic-ui-react';
// import { Row, Col } from 'reactstrap';
import './calen.scss'
// import Avatar from '../../../Components/img/user.jpg';
import AvatarUser from '../../../Components/img/hombre.png'
import AvatarDev from '../../../Components/img/programador.png'


// function scrollDiv() {

//   var div = document.getElementById('tudiv');
//   div.scrollTop = '9999';

// }
function CommentExampleMinimal({ comments, labels }) {
  const form_items = comments.map((item, key) => {
    // console.log('item-->', item);
    return (
      <Comment.Group>
        <Comment>
          <Comment.Avatar as='a' src={item.ByHelpDesk === "1" ? AvatarDev : AvatarUser} />
          <Comment.Content>
            <Comment.Author as='a'>{item.ByHelpDesk === "1" ? 'Help Desk' : labels.details.comments.from}</Comment.Author>
            <Comment.Metadata>
              <span>{item.CreationDate}</span>
            </Comment.Metadata>
            <Comment.Text>{item.Comment}</Comment.Text>
          </Comment.Content>
        </Comment>
      </Comment.Group>
    )
  });
  return (

    <React.Fragment>
      {/* <Row> */}
      {/* {
          dataTikets.CatStatusId !== 3 ?
            <Col xs={6}>
              <Form reply>
                <Form.TextArea onChange={handleChange} />
                <Button content={labels.details.comments.btn_send_comment} labelPosition='left' icon='comment alternate' primary
                  onClick={addComment}
                  disabled={buttonAddCom}
                />
              </Form>
            </Col>
            :
            ''
        } */}

      {form_items}
      {/* <span id="final"></span> */}
      {/* </Row> */}
    </React.Fragment >
  );
}

export default CommentExampleMinimal;