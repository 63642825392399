import React from 'react';
import propTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Dropdown, Transition } from 'semantic-ui-react';
import Axios from 'axios';
import moment from 'moment';
import { sendNewCommentEvent } from './../../../../../Global/Socket/';
import { Emoji } from './config';
import './styles.scss';

import 'emoji-mart/css/emoji-mart.css';
import { Picker as PickerD } from 'emoji-mart';

import { Services } from './../../../Services';
/*=============================================
=            Create New Comment Function      =
=============================================*/
function NewComment(props) {
  const [select, setSelect] = React.useState([]);
  const [commentType, setCommentType] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [chosenEmoji, setChosenEmoji] = React.useState(false);

  /**s
   * Get All Catalogs
   */
  const GetCatalogs = React.useCallback(async () => {
    // let response = await Axios.get(`${Services.CATALOGS.path}all/shipment-comments`);
    // if (response.data && response.data.data) {
    //   let array = [];
    //   for (let index = 0; index < response.data.data.COMMENTS[0].childs.length; index++) {
    //     const element = response.data.data.COMMENTS[0].childs[index];
    //     array.push({ key: `${element.ComTypeId}`, text: element.ComType, value: `${element.ComTypeId}` });
    //   }
    //   setSelect(array);
    // }
  }, []);



  /**
   * On change comment type
   * @param {*} value 
   */
  function OnChangeCommentType(value) {
    setCommentType(value);
  }

  /**
   * On Key press
   * @param {*} evt 
   */
  function OnKeyPressFn(evt) {
    if (evt.charCode === 13) {
      saveComment();
    }

  }
  /**
   * Handle action
   */
  function handleAction() {
    setVisible(true);
    setTimeout(() => {
      setVisible(false);
    }, 3000);
  }


  /**
   * Event handler for Emoji Click
   * @param {*} event 
   * @param {*} emojiObject 
   */
  function onEmojiClick(emoji) {
    // let newComment = `${document.getElementById('fakebox-input').value} ${emojiObject.emoji}`;
    let newComment = `${document.getElementById('fakebox-input').value} ${emoji.native}`;
    // setValue(newComment);
  };
  /**
   * Set visible or invisible emoji picker
   */
  function handleEmoji() {
    setChosenEmoji(!chosenEmoji);
  }

  /*=============================================
  =            Save Comment Function            =
  =============================================*/

  async function saveComment() {
    if (chosenEmoji) handleEmoji();
    let Comment = {};
    Comment.ModuleId = 1;
    Comment.ComTypeId = parseInt(commentType) ? parseInt(commentType) : 1;
    Comment.RowId = parseInt(props.POId) ? parseInt(props.POId) : '';
    Comment.Date = moment(new Date()).format('LLLL');
    Comment.UserId = parseInt(props._user) ? parseInt(props._user) : '';
    Comment.Comment = props.value;

    try {
      props.pushComments(Comment);
      // setValue('');
    } catch (error) {
      console.error('error creating comment', error);
      handleAction();
    }

  }


  React.useEffect(() => {
    GetCatalogs();
  }, [GetCatalogs]);



  return (
    <>
      <Row>
        <Col md={12} className='main_container_opt'>
          <div className='options_container'>
            <Dropdown upward floating inline options={select} defaultValue='1' onChange={(evt, data) => { OnChangeCommentType(data.value); }} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div id='fakebox'>
            <input type='text' id='fakebox-input' value={props.value} onChange={(evt) => props.onchenge(evt.target.value)} onKeyPress={OnKeyPressFn}></input>
            <div className='container-icons' onClick={handleEmoji}>
              <Emoji />
            </div>
            <button id='fakebox-send' title='Send' onClick={saveComment}></button>
          </div>
          {
            chosenEmoji ?
              <PickerD
                emoji=''
                showSkinTones={false}
                onSelect={onEmojiClick}
                set='google'
                style={{ position: 'absolute', bottom: '60px', right: '50px' }}
                title='Pick your emoji…'
                showPreview={false}
              />
              : ''
          }
        </Col>
      </Row>
    </>
  );
}

NewComment.propTypes = {
  POId: propTypes.number,
  _user: propTypes.string,
  _customer: propTypes.string,
  _rolId: propTypes.string,
  resetComments: propTypes.func
};

NewComment.defaultProps = {
  POId: 0,
  _user: '',
  _customer: '',
  _rolId: '',
  resetComments: () => { }
};

export default NewComment;