import React from 'react';
import { Button } from 'reactstrap';
import imgAddEP from '../img/icono_agregar.png'

const colors = {
	1: 'primary',
	2: 'primary',
	3: 'warning',
	4: 'success',
	5: 'success',
	6: 'primary',
};

function ButtonNewTicket({ projectId, etiquetas, onclick }) {
	// return TypeLoader(hidden)
	// console.log('hidden--->', hidden);
	// console.log('projectId--->', projectId);

	// let project = 2
	switch (projectId) {
		case 1:
			// console.log('entro al loader de Poseidon');
			return (
				<Button
					block
					color={colors[projectId]}
					// className='btn_succes'
					onClick={onclick}>
					<i className='fas fa-plus-circle mr-2' />
					{etiquetas.title}
				</Button>
			);
			break;
		case 2:
			// console.log('entro al loader de Roldan');
			return (
				<Button
					block
					color={colors[projectId]}
					// className='btn_succes'
					onClick={onclick}>
					<i className='fas fa-plus-circle mr-2' />
					{etiquetas.title}
				</Button>
			)

			break;
		case 3:
			// console.log('entro al loader de Sales Inteligence');
			return (
				<Button
					block
					color={colors[projectId]}
					// className='btn_succes'
					onClick={onclick}>
					<i className='fas fa-plus-circle mr-2' />
					{etiquetas.title}
				</Button>
			);
			break;
		case 4:
			// console.log('entro al loader de RR Magic Vision');
			return (
				<Button
					block
					color={colors[projectId]}
					// className='btn_succes'
					onClick={onclick}>
					<i className='fas fa-plus-circle mr-2' />
					{etiquetas.title}
				</Button>
			)
			break;
		case 5:
			// console.log('entro al loader de Magic Dome');
			return (
				<Button
					block
					color={colors[projectId]}
					// className='btn_succes'
					onClick={onclick}>
					<i className='fas fa-plus-circle mr-2' />
					{etiquetas.title}
				</Button>
			)
			break;
		case 6:
			// console.log('Euro');
			return (
				<div className="float-right" style={{ alignItems: "center", display: "inline-flex", cursor: "pointer" }} onClick={onclick}>
					<img src={imgAddEP} width="28" height="28" />
					<label style={{ marginLeft: "10px", fontWeight: "bold" }} className="euro-base-label">
						{etiquetas.title}
					</label>
				</div>
			);
			break;
		case 7:
			// console.log('Euro');
			return (
				<div className='float-right'  >
					<div style={{
						alignItems: "center",
						display: "inline-flex"
					}}>
						<i className='fas fa-plus-circle fa-2x buton-add mr-1' onClick={onclick} />
						<span style={{ fontWeight: "bold" }} className='tag-custom-value'>
							{etiquetas.title}
						</span>
					</div>
				</div>
			);
			break;
		default:
			return (
				<Button
					block
					color={colors[1]}
					onClick={onclick}>
					<i className='fas fa-plus-circle mr-2' />
					{etiquetas.title}
				</Button>
			)
			break;
	}
}

export default ButtonNewTicket
