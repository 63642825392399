import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import './style.scss';
import Table from '../../../Components/Table';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, CardText } from 'reactstrap';
import { MyResponsiveBarCanvas } from '../../../Components/Graphics/BarChart';
import Map from '../../../Components/Maps/map';
import mapJson from '../../../../models/mapJson';
import weeklyData from '../../../../models/weekly';

class WeeklyPlanning extends Component {
  constructor(props){
    super(props);
    this.state = {
      filters: [
        {
          name: 'Week',
          placeholder: '',
          value: '',
          values: [],
          clear: false
        },
        {
          name: 'PortOrigin',
          placeholder: 'Port of Origin',
          value: '',
          values: [],
          clear: true
        },
        {
          name: 'PortDestination',
          placeholder: 'Port of Destination',
          value: '',
          values: [],
          clear: true
        },
        {
          name: '3PL',
          placeholder: '3PL',
          value: '',
          values: [],
          clear: true
        }
      ],
      totalCards: [
        {
          id: 'Booking',
          title: 'Booking',
          value: '0',
          icon: 'fa-calendar-alt',
          firstColor: {
            backgroundColor: '#9e9e9e',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#e0e0e0',
            color: '#fff'
          }
        },
        {
          id: 'Departures',
          title: 'Est. Container Departures',
          value: '0',
          icon: 'fa-arrow-alt-circle-right',
          firstColor: {
            backgroundColor: '#2f80e7',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#7eb3f6',
            color: '#fff'
          }
        },
        {
          id: 'Arrivals',
          title: 'Est. Container Arrivals',
          value: '0',
          icon: 'fa-arrow-alt-circle-left',
          firstColor: {
            backgroundColor: '#57b65b',
            color: '#fff'
          },
          secondColor: {
            backgroundColor: '#83db86',
            color: '#fff'
          }
        }
      ],
      graphics: [
        {
          id: 'Week',
          title: 'Volume per Week',
          keys: ['Departures','Arrivals'],
          colors: ['#2B98F0','#50AE55'],
          data:[]
        },
        {
          id: 'POD',
          title: 'Top 10 POD by Volume (Containers)',
          keys: [],
          colors: false,
          data: []
        },
        {
          id: 'POL',
          title: 'Top 10 POL by Volume (Containers)',
          keys: [],
          colors: false,
          data:[]
        }
      ],
      dataDrawer: '',
      data: weeklyData,
      dataMap: mapJson.dataMap,
      portsCoords: [
        {
          id: 'VNHPH',
          name: 'Haiphong',
          position: {
            lat: 20.842735,
            lng: 106.77265,
          },
          capacity: 15,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'BRVIX',
          name: 'Vitoria',
          position: {
            lat: -20.322495,
            lng: -40.316275,
          },
          capacity: 30,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'ITSPE',
          name: 'La Spezia',
          position: {
            lat: 44.104715,
            lng: 9.837505,
          },
          capacity: 15,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'ESVLC',
          name: 'Valencia',
          position: {
            lat: 39.44231,
            lng: -0.31646595,
          },
          capacity: 20,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'ITGOA',
          name: 'Genova',
          position: {
            lat: 44.403545,
            lng: 8.909109,
          },
          capacity: 10,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'ESVGO',
          name: 'Vigo',
          position: {
            lat: 42.241505,
            lng: -8.7212355,
          },
          capacity: 20,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'PTLIS',
          name: 'Lisboa',
          position: {
            lat: 38.70339,
            lng: -9.1524275,
          },
          capacity: 30,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'INBOM',
          name: 'Mumbai',
          position: {
            lat: 18.941,
            lng: 72.855,
          },
          capacity: 20,
          type: 'Departure',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USBOS',
          name: 'Boston',
          position: {
            lat: 42.373,
            lng: -71.0435,
          },
          capacity: 15,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USSEA',
          name: 'Seattle',
          position: {
            lat: 47.621195,
            lng: -122.36435,
          },
          capacity: 15,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USPDX',
          name: 'Portland',
          position: {
            lat: 45.568965,
            lng: -122.73395,
          },
          capacity: 20,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USLGB',
          name: 'Long Beach',
          position: {
            lat: 33.75493,
            lng: -118.21435,
          },
          capacity: 15,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USHOU',
          name: 'Houston',
          position: {
            lat: 29.727785,
            lng: -95.270045,
          },
          capacity: 20,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USOAK',
          name: 'Oakland',
          position: {
            lat: 37.79784,
            lng: -122.2864,
          },
          capacity: 10,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USNYC',
          name: 'New York',
          position: {
            lat: 40.6759,
            lng: -74.0829,
          },
          capacity: 20,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USTIW',
          name: 'Tacoma',
          position: {
            lat: 47.274,
            lng: -122.404,
          },
          capacity: 20,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USBAL',
          name: 'Baltimore',
          position: {
            lat: 39.254,
            lng: -76.586805,
          },
          capacity: 20,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        },
        {
          id: 'USLAX',
          name: 'Los Angeles',
          position: {
            la: 33.74021,
            lng: -118.265,
          },
          capacity: 20,
          type: 'Arrival',
          icon: {
            url: Math.floor((Math.random() * 10) % 3) === 0 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (Math.floor((Math.random() * 10) % 3) === 1 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          }
        }
      ],
      activePage:1,
      limit:10,
      numberPage: 1,
      totalPages: 1,
      search: '',
      dataAux: [],
      dataOut:[],
      columns: [
        { label: 'Booking No', key: 'BookingNumber', custom: (value) => (
          <div className="row" style={{marginLeft: 0}}>{false
            ? <div style={{marginTop: -3, marginRight: 10}}><em className="fa-2x fas fa-arrow-alt-circle-left" style={{ color: '#57b65b' }}></em></div>
            : <div style={{marginTop: -3, marginRight: 10}}><em className="fa-2x fas fa-arrow-alt-circle-right" style={{ color: '#2f80e7' }}></em></div>}<Link to={'/weekly-planning/' + value.BookingNumber}>{value.BookingNumber}</Link></div>) },
        { label: 'Supplier', key: 'Supplier' },
        { label: 'Consignee', key: 'Consignee' },
        { label: 'Freight Forwarder', key: 'FreightForwarder' },
        { label: 'No. of Containers', key: 'ContainerNumber' },
        { label: 'Origin', key: 'Origin' },
        { label: 'Destination', key: 'Destination' },
        { label: 'ETD', key: 'ETD' },
        { label: 'ETA', key: 'ETA' },
        { label: 'POs', key: 'POs' }
      ]
    };
  }

  componentDidMount = () => {
    let { filters, portsCoords, graphics } = this.state;
    let data = weeklyData;
    let weekValues = [];
    let volumeWeekValues = [];
    for (var i = 41; i < 53; i++) {
      let obj = {
        value: 'Week' + i,
        label: 'Week ' + i
      };
      weekValues.push(obj);
      let obj2 = {
        id: 'Week' + i,
        label: 'Week ' + i,
        Departures: 0,
        Arrivals: 0
      };
      data.map(f => {
        if(Number(f.WETD) === i){
          obj2.Departures ++;
        }
        if(Number(f.WETA) === i){
          obj2.Arrivals ++;
        }
      });
      volumeWeekValues.push(obj2);
    }
    // console.log('volumeWeekValues',volumeWeekValues);
    let arrivalsPorts = [];
    let departuresPorts = [];
    let freightForwarderValues = [];
    portsCoords.map((e) => {
      if(e.type === 'Arrival'){
        let obj = {
          value: e.id,
          label: e.id
        };
        arrivalsPorts.push(obj);
      }
      if(e.type === 'Departure'){
        let obj = {
          value: e.id,
          label: e.id
        };
        departuresPorts.push(obj);
      }
    });
    data.map(e => {
      let findFreight = false;
      freightForwarderValues.map(f => {
        if(f.value === e.FreightForwarder){
          findFreight = true;
        }
      });
      if(!findFreight){
        let obj = {
          value: e.FreightForwarder,
          label: e.FreightForwarder
        };
        freightForwarderValues.push(obj);
      }
      return e;
    });

    filters.map(e => {
      if(e.name === 'Week'){
        e.values = weekValues;
        e.value = weekValues[0];
      }
      if(e.name === 'PortOrigin'){
        e.values = departuresPorts;
      }
      if(e.name === 'PortDestination'){
        e.values = arrivalsPorts;
      }
      if(e.name === '3PL'){
        e.values = freightForwarderValues;
      }
    });

    graphics.map(e => {
      if(e.id === 'Week'){
        e.data = volumeWeekValues;
      }
    });
    this.setState({ filters: filters, graphics: graphics, activePorts: portsCoords }, () => {
      this.changeFilter('Week');
    });
  }

  changeFilter = (type) => {
    let { data, filters, graphics, portsCoords, totalCards } = this.state;
    if(type === 'Week'){
      if(!filters[0].value){
        filters[0].value = filters[0].values[0];
      }
      let activePorts = [];
      let bookings = 0;
      let arrivals = 0;
      let departures = 0;
      let pod = [];
      let podKeys = [];
      let pol = [];
      let polKeys = [];
      portsCoords.map(e => {
        if(e.type === 'Arrival'){
          e.totalDeparture = 0;
          e.capacityDeparture = 0;
          e.capacityArrival = e.capacity;
          e.totalArrival = 0;
          e.bookings = 0;
          data.map(f => {
            if(`Week${f.WETA}` === filters[0].value.value && f.Destination === e.id){
              bookings++;
              arrivals = arrivals + Number(f.ContainerNumber);
              e.totalArrival = e.totalArrival + Number(f.ContainerNumber);
              e.bookings++;
            }
          });
          let porcent = (e.totalArrival * 100) / e.capacityArrival;
          podKeys.push(e.id);
          let objPOD = {
            id: e.id,
            label: e.id,
            color: porcent < 90 ? '#50AE55' : (porcent >= 100 ? '#F1453D' : '#FD9727'),
            [e.id]: e.totalArrival,
            value: e.totalArrival
          };
          pod.push(objPOD);
          e.icon = {
            url: porcent < 90 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (porcent >= 100 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          };
          if(e.totalArrival > 0){
            activePorts.push(e);
          }
        }
        if(e.type === 'Departure'){
          e.totalDeparture = 0;
          e.totalArrival = 0;
          e.capacityDeparture = e.capacity;
          e.capacityArrival = 0;
          e.bookings = 0;
          data.map(f => {
            if(`Week${f.WETD}` === filters[0].value.value && f.Origin === e.id){
              bookings++;
              departures = departures + + Number(f.ContainerNumber);
              e.totalDeparture = e.totalDeparture + Number(f.ContainerNumber);
              e.bookings++;
            }
          });
          let porcent = (e.totalDeparture * 100) / e.capacityDeparture;
          polKeys.push(e.id);
          let objPOL = {
            id: e.id,
            label: e.id,
            color: porcent < 90 ? '#50AE55' : (porcent >= 100 ? '#F1453D' : '#FD9727'),
            [e.id]: e.totalDeparture,
            value: e.totalDeparture
          };
          pol.push(objPOL);
          e.icon = {
            url: porcent < 90 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinGreen.png' : (porcent >= 100 ? 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinRed.png' : 'https://fsposeidon.blob.core.windows.net/poseidonpic/pinYellow.png')
          };
          if(e.totalDeparture > 0){
            activePorts.push(e);
          }
        }
      });
      totalCards.map(e => {
        if(e.id === 'Booking'){
          e.value = bookings;
        }
        if(e.id === 'Departures'){
          e.value = departures;
        }
        if(e.id === 'Arrivals'){
          e.value = arrivals;
        }
      });
      pol.sort(function(a, b){return b.value - a.value;});
      pod.sort(function(a, b){return b.value - a.value;});
      graphics.map(e => {
        if(e.id === 'POL'){
          e.data = pol;
          e.keys = polKeys;
        }
        if(e.id === 'POD'){
          e.data = pod;
          e.keys = podKeys;
        }
      });
      this.setState({totalCards: totalCards, graphics: graphics, activePorts: activePorts});
    }
    else if(type === 'PortOrigin'){
      let newData = [];
      let activePorts = [];
      let findPorts = [];
      if(filters[1].value){
        weeklyData.map(e => {
          let findPortOri = false;
          let findPortDes = false;
          if(e.Origin === filters[1].value.value || e.Destination === filters[1].value.value){
            newData.push(e);
            findPorts.map(f =>{
              if(f === e.Origin){
                findPortOri = true;
              }
              if(f === e.Destination){
                findPortDes = true;
              }
            });
            if(!findPortOri){
              findPorts.push(e.Origin);
            }
            if(!findPortDes){
              findPorts.push(e.Destination);
            }
          }
        });
        portsCoords.map(e => {
          if(findPorts.includes(e.id) && (e.totalDeparture > 0 || e.totalArrival >0)){
            activePorts.push(e);
          }
        });
      }
      else{
        activePorts = portsCoords;
        newData = weeklyData;
      }
      this.setState({activePorts: activePorts, data: newData});
    }
    else if(type === 'PortDestination'){
      let activePorts = [];
      let newData = [];
      let findPorts = [];
      if(filters[2].value){
        portsCoords.map(e => {
          if(e.id === filters[2].value.value && (e.totalDeparture > 0 || e.totalArrival >0)){
            activePorts.push(e);
          }
        });
        weeklyData.map(e => {
          let findPortOri = false;
          let findPortDes = false;
          if(e.Origin === filters[2].value.value || e.Destination === filters[2].value.value){
            newData.push(e);
            findPorts.map(f =>{
              if(f === e.Origin){
                findPortOri = true;
              }
              if(f === e.Destination){
                findPortDes = true;
              }
            });
            if(!findPortOri){
              findPorts.push(e.Origin);
            }
            if(!findPortDes){
              findPorts.push(e.Destination);
            }
          }
        });
        portsCoords.map(e => {
          if(findPorts.includes(e.id) && (e.totalDeparture > 0 || e.totalArrival >0)){
            activePorts.push(e);
          }
        });
      }
      else{
        activePorts = portsCoords;
        newData = weeklyData;
      }
      this.setState({activePorts: activePorts, data: newData});
    }
    else{
      let activePorts = [];
      let newData = [];
      let findPorts = [];
      if(filters[3].value){
        weeklyData.map(e => {
          let findPort = false;
          if(e.FreightForwarder === filters[3].value.value){
            newData.push(e);
            findPorts.map(f =>{
              if(f === e.Origin || f === e.Destination){
                findPort = true;
              }
            });
            if(!findPort){
              findPorts.push(e.Origin);
              findPorts.push(e.Destination);
            }
          }
        });
        portsCoords.map(e => {
          if(findPorts.includes(e.id)){
            activePorts.push(e);
          }
        });
        // console.log('findPorts',findPorts);
      }
      else{
        activePorts = portsCoords;
        newData = weeklyData;
      }
      this.setState({activePorts: activePorts, data: newData});
    }
  }

  toggleDrawerMap = data => evt => {
    this.setState({visible: true, dataDrawer: data });
  };

  backres = (backResponse) => {
    this.setState({
      data: backResponse,
      dataAux:backResponse,
      dataOut:backResponse,
    });
  }

  render() {
    let { activePorts, columns, dataDrawer, data, filters, graphics, totalCards, visible } = this.state;
    let polylineData = [];
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas fa-broadcast-tower fa-1x mr-2"></em>
            Control Tower
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            <Col xs={12} xl={12}>
              <div className="row">
                <div className="col-8">
                  <div className="row">
                    <div className="col-6">
                      <legend>Weekly Planning</legend>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Row>
                    {
                      filters.map((e, index) =>{
                        return (
                          <Col md={3} xl={3} key={index}>
                            <Select
                              className='_comboFilters'
                              name={e.name}
                              id={index}
                              placeholder={e.placeholder}
                              value={e.value}
                              options={e.values}
                              isClearable={e.clear}
                              onChange={(value) =>{
                                let newFilters = filters;
                                newFilters[index].value = value;
                                this.setState({filters: newFilters}, () =>{
                                  this.changeFilter(e.name);
                                });
                              }}
                            />
                          </Col>
                        );
                      })
                    }
                  </Row>
                </div>
                <div style={{height: 60}} />
                <div className="col-12">
                  <Row>
                    <Col md={3} xl={3} className="noLeftPadding">
                      {
                        totalCards.map((e, index) =>{
                          return (
                            <Col md={12} className="noLeftPadding">
                              <div key={index} className="card flex-row align-items-center align-items-stretch border-0">
                                <div className="col-4 d-flex align-items-center justify-content-center rounded-left" style={e.firstColor}>
                                  <em className={`fas fa-3x ${e.icon}`}></em>{' '}
                                </div>
                                <div className="col-8 rounded-right totalCardInfo" style={e.secondColor}>
                                  <div style={{ fontSize: '14px', textAlign:'center',marginTop:'4px'  }}>{e.title}</div>
                                  <div style={{ fontSize: '18px' }}>
                                    {' '}
                                    <center style={{ fontSize: '20px', marginTop:'6px' }}><strong>{e.value}</strong></center>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      }
                    </Col>
                    {
                      graphics.map((e,index) =>{
                        return(
                          <Col md={3} xl={3} key={index}>
                            <Card className="graphcardsize">
                              <CardBody className="graphcardbody">
                                <CardText style={{textAlign: 'center', marginBottom: '0px',fontFamily:'unset',fontWeight: 'bold'}}>{e.title}</CardText>
                                <MyResponsiveBarCanvas data={e.data} keys={e.keys} colors={e.colors}/>
                              </CardBody>
                            </Card>
                          </Col>
                        );
                      })
                    }
                  </Row>
                </div>
                <Col lg={12} md={12}>
                  <Map style={{maxHeight: '300px'}} marker={activePorts} visible={true} type={'drawer'} data={polylineData} dataDrawer={dataDrawer}/>
                </Col>
                <Col lg={12} md={12} style={{ paddingTop: '2%' }}>
                  <div className="col-12">
                    <Table
                      columns={columns}
                      data={data}
                      options={
                        {
                          pagination: true,
                          search: true,
                          downloads: {
                            xlsx: true,
                            pdf: false
                          },
                        }
                      }/>
                  </div>
                </Col>
              </div>
            </Col>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}
export default withRouter(WeeklyPlanning);
