import React, { Component } from 'react';
import restService from '../../../../../../services/restService';
import { Input } from 'reactstrap';
import Datetime from 'react-datetime';
import Fade from 'react-reveal/Fade';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {Card,CardBody} from 'reactstrap'
// const rest = new restService();
class Consignees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ExtraFields: {
                number: '',
                date: '',
                iwarehouse: '',
                dwarehouse: ''
            }
        }
    }

    handleChange = name => event => {
        let { ExtraFields } = this.state
        ExtraFields[name] = event.target.value
        this.setState({ ExtraFields })
    }

    handleChangeDate = name => event => {
        let ExtraFields = this.state
        try {
            ExtraFields[name] = event.format("YYYY-MM-DD")
            this.setState({ ExtraFields })
        }
        catch (ex) {
        
        }
    }

    render() {
        const { ExtraFields } = this.state
        return (
            <Card className="myCard">
                <CardBody>
                <ExpansionPanel elevation={0} defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <legend><em className="fas fa-th fa-1x mr-2"></em>Extra Fields</legend>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-3">
                            <Fade left opposite><label>Invoice Number</label></Fade>
                            <Input
                                type="text"
                                placeholder=''
                                value={ExtraFields.number}
                                name='number'
                                maxlength='50'
                                onChange={this.handleChange('number')} />
                        </div>
                        <div className="col-md-3">
                            <Fade left opposite><label>Invoice Date</label></Fade>
                            <Datetime
                                required
                                type="date"
                                dateFormat={'YYYY MM DD'}
                                inputProps={{ className: 'form-control' }}
                                value={ExtraFields.date}
                                onChange={this.handleChangeDate('date')} />
                        </div>
                        <div className="col-md-3">
                            <Fade left opposite><label>Origin Warehouse</label></Fade>
                            <Input
                                type="text"
                                placeholder=''
                                value={ExtraFields.iwarehouse}
                                name='iwarehouse'
                                maxlength='50'
                                onChange={this.handleChange('iwarehouse')} />
                        </div>
                        <div className="col-md-3">
                            <Fade left opposite><label>Destination Warehouse</label></Fade>
                            <Input
                                type="text"
                                placeholder=''
                                value={ExtraFields.dwarehouse}
                                name='dwarehouse'
                                maxlength='50'
                                onChange={this.handleChange('dwarehouse')} />
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
                </CardBody>
            </Card>
            
        )
    }
}

export default Consignees