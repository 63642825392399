import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Form from '../../../../../../models/FormData'

const config = [
    { label: 'Booking NO.', id: 'BookingNumber', type: 'simple' },
    { label: 'Booking Date', id: 'BookingDate', type: 'simple' },
    { label: 'Transport Mode', id: 'TransportMode', type: 'simple' },
    { label: 'Service', id: 'ServiceCode', type: 'simple' },
    { label: 'Freight Terms', id: 'FreightTerm', type: 'simple' },
    { label: 'Cargo Ready Date', id: 'CargoReadyDate', type: 'simple' },
    { label: 'Origin Port', id: 'OriginName', type: 'simple' },
    { label: 'Destination Port', id: 'DestinationName', type: 'simple' },
    { label: 'Final Delivery Description', id: 'FinalDeliveryDesc', type: 'simple' },
    { label: 'Incoterm', id: 'Incoterm', type: 'simple' },
    { label: 'Cargo Type', id: 'CargoType', type: 'simple' },
    { label: '3LP', id: 'ThreePL', type: 'simple' },
    { label: '3LP Booking Reference', id: 'ThreePLBookingRef', type: 'simple' },
    { label: 'Customer Reference 1', id: 'CustomerRef1', type: 'simple' },
    { label: 'Customer Reference 2', id: 'CustomerRef2', type: 'simple' },
]

const config2 = [
    { label: 'Pickup Location', id: 'PickupLocation', type: 'simple' },
    { label: 'Pickup Additonal Instruction', id: 'PickupAddionalInstructions', type: 'simple' },
    { label: 'Booking Instruction', id: 'BookingInstructions', type: 'simple' },
    { label: 'Cargo Description', id: 'CargoDescriptions', type: 'simple' }
]

class BookingDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            rows: [],
            rowss: [],
            CreateOrigin: '',
            Form: Form,
            CreateDestination: '',
            Booking: {},
        }
    }

    componentDidMount = () => {
        this.setState({
            data: this.props.data
        }, () => {
            this.buildRows()
            this.buildRows2()
            this.CreateOriginForm()
            this.CreateDestinationForm()
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data,
            Booking: nextProps.data
        }, () => {
            this.buildRows()
            this.buildRows2()
            this.CreateOriginForm()
            this.CreateDestinationForm()
        })
    }


    buildRows = () => {
        let { data } = this.state
        let rows = config.map((e) => {
            return (
                <div className="col-2" style={{ paddingTop: '1%' }}>
                    <div className="row">
                        <div className="col-12">
                            <span style={{ color: '#9e9e9e', fontWeight: 'bold' }}>{e.label}<br /></span>
                            <span  >{data[e.id] ? data[e.id] : ''}</span>
                        </div>
                        <div className="col-12">
                        </div>
                    </div>
                </div>
            )
        })
        this.setState({
            rows
        })
    }

    buildRows2 = () => {
        let { data } = this.state
        let rowss = config2.map((e) => {
            return (
                <div className="col-6" style={{ paddingTop: '1%' }}>
                    <div className="row">
                        <div className="col-12">
                            <span style={{ color: '#9e9e9e', fontWeight: 'bold' }} >
                                {e.label} <br />
                            </span>
                        </div>
                        <div className="col-12">
                            <span >{data[e.id] ? data[e.id] : ''}</span>
                        </div>

                    </div>
                </div>
            )
        })
        this.setState({
            rowss
        })
    }


    CreateOriginForm = () => {
        const { Form, Booking} = this.state
        let CreateOrigin = Form.CreateBookingAddOnsAtOrigin.map((e, i) => {
            if (e.type === 'check') return (
                <div className="col-md-2" key={e.id} style={{ paddingTop: '3%' }} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Booking[e.id]}
                                value={Booking[e.id]}
                                color="primary"
                            />
                        }
                        label={e.label}
                    />
                </div>
            )
            else return ''
        })
        this.setState({
            CreateOrigin
        })
    }

    CreateDestinationForm = () => {
        const { Form, Booking } = this.state
        let CreateDestination = Form.CreateBookingAddOnsAtDestination.map((e, i) => {
            if (e.type === 'check') return (
                <div className="col-md-2" key={e.id} style={{ paddingTop: '3%' }} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Booking[e.id]}
                                value={Booking[e.id]}
                                color="primary"
                            />
                        }
                        label={e.label}
                    />
                </div>
            )
            else return '';
        })
        this.setState({
            CreateDestination
        });
    }


    render = () => {
        const { CreateOrigin, CreateDestination } = this.state
        return (
            <Card>
                <CardBody>
                    <h4 className="mb-3 py-2">&nbsp;&nbsp;Booking Details</h4>
                    <div className="row">
                        <div className="col-1">
                            <em className="fas fa-list fa-4x mb-3 py-5"></em>
                        </div>
                        <div className="col-10">
                            <div className="row">
                                {this.state.rows}
                            </div>
                        </div>
                        <div className="col-2">
                        </div>
                        <div className="col-10">
                            <div className="row">
                                {this.state.rowss}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="row" style={{ paddingTop: '3%' }}>
                                <div className="col-md-12">
                                    <legend >Service Add-ons</legend>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <legend className="_legend">At Origin</legend>
                                                </div>
                                                <div className="col-md-12">
                                                    {CreateOrigin}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <legend className="_legend">At Destination</legend>
                                            </div>
                                            <div className="col-md-12">
                                                {CreateDestination}                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default BookingDetail;