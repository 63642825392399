import React from 'react';
import { Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';


const { Search } = Input;

function TableSearch(params) {

    return (
        <>
            <Search
                placeholder={params.placeholder ? params.placeholder : 'Search'}
                onSearch={(evt, data) => { params.handleChangeSearch(evt) }}
                allowClear={params.clear ? true : false}
                enterButton="Search"
                // onChange={(evt, data) => { params.handleChangeSearch(evt.target.value); }}
                onPressEnter={(evt, data) => { params.handleChangeSearch(evt.target.value); }}
                className='ant-search'
                // value={params.value}
                defaultValue={params.value}
            // size='middle'
            // value={(evt, data) => { console.log(evt, data); }}
            />
        </>
    )

}

export default TableSearch