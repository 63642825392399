import lod from 'lodash';

function formatDate (date, type) {
  let splitDate = '';

  if (type === 't1') splitDate = date.split('/');
  if (type === 't2') splitDate = date.split('-');

  let dateOut = '';
  let newDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
  try {
    dateOut = new Date(newDate);
  }catch(e){
  }
  return dateOut.getTime();
}

function formatValues (array, value, key) {
  let otherArray = [];
  if (key === 'date') {
    lod.forEach(array, (val, ind) => {
      let PoDate = formatDate(val.poDate, 't1');
      let DateStart = formatDate(value.dateStart, 't2');
      let DateEnd = formatDate(value.dateEnd, 't2');

      if (PoDate >= DateStart && PoDate <= DateEnd){
        otherArray.push(val);
      }
    });
  } else {
    lod.forEach(array, (val, ind) => {
      if (val[key].toLowerCase().indexOf(value) !== -1)
        otherArray.push(val);
    });
  }

  return otherArray;
}
export const FilterHelper = (criterials, data) => {
  let search = {};
  let keys = ['supplier', 'cruise', 'poNumber', 'buyer', 'division', 'company'];
  let newArray = [];

  if (criterials.date.dateStart && criterials.date.dateEnd) keys.push('date');

  for (let i= keys.length; i >= 0; i--) {
    if (criterials[keys[i]]) {
      if (keys[i] === 'date'){
        search[keys[i]] = criterials[keys[i]];
      } else {
        search[keys[i]] = criterials[keys[i]].toLowerCase();
      }

    }
  }

  lod.forEach(keys, (value, index) => {
    let dataAux = '';
    if (search[value]) {
      if (value === 'cruise') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }
      if(value === 'supplier') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value === 'poNumber') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value === 'buyer') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value === 'division') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value === 'company') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux, search[value], value);
      }

      if (value === 'date') {
        dataAux = newArray.length > 0 ? newArray: data;
        newArray = formatValues(dataAux , search[value], value);
      }
    }
  });

  return newArray;

};
