import React, { Component } from 'react';

import Table from '../../../../../../template/Table'

const TITLES = {
    1 : 'The rates quoted within this document must be valid until end of 2019',
    2 : '*Unit Cost to include: receiving at warehouse of domestic and international shipments (ocean, air, ground, parcel), warehouse handling, unloading and loading containers outbound to shipyard, labeling, palletization, consolidation, staging and grouping by department/deck, storage (30 days free storage), wrapping, stuffing, lashing, bracing',
    3 : '',
    4 : '',
    5 : '',
    6 : '',
    7 : ''
}


const COLUMNS = {
    1 : 'CONSOLIDATION, WAREHOUSE HANDLING & STORAGE',
    2 : 'PO MANAGEMENT AND ON-SITE PROJECT MANAGEMENT',
    3 : 'BROKERAGE, IMPORT & EXPORT FEES',
    4 : 'OCEAN CONTAINER MANAGEMENT AND ACCESSORIAL FEES',
    5 : 'OCEAN - PORT EVERGLADES TO FREEPORT - BALEARIA LCL SERVICE',
}

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id : '',
            columns: [
                { label : 'ID', key : 'ID'},
                { label : 'Description', key : 'Description'},
                { label : 'UnitCost', key : 'UnitCost'},
                { label : 'Currency', key : 'Currency'},
                { label : 'UnitOfMeasure', key : 'UnitOfMeasure'},
                { label : 'Group', key : 'Group'}
            ],
            data: []
        };
    }


    componentDidMount = () => {
        this.setState({
            data : this.props.results,
            id : this.props.id,
        },()=>{
            this.buildColumns()
        })
    }


    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data : nextProps.results,
            id : nextProps.id
        },()=>{
            this.buildColumns()
        })
    }

    buildColumns = ()=>{
        let {id} = this.state

        this.setState({
            columns :  [
                { label : 'ID', key : 'ID'},
                { label : COLUMNS[id] ? COLUMNS[id] : 'Description' ,isLarge:true, key : 'Description'},
                { label : 'UnitCost', key : 'UnitCost'},
                { label : 'Currency', key : 'Currency'},
                { label : 'Unit Of Measure', key : 'UnitOfMeasure'},
            ]
        })
    }



    render() {

        return (
            <div className="row">
                <div className="col-12">
                    <span style={{ fontWeight:'bold'}}>{TITLES[this.props.id] ? TITLES[this.props.id] : ''}</span>
                </div>
                <div className="col-12">
                <Table
                    columns={this.state.columns}
                    data={this.state.data}
                    large = {true}
                    options={{
                        // pagination:true,
                        large : true,
                        downloads: {
                            pdf: false,
                            xlsx: false
                        }
                    }}
                />
                </div>
            </div>
        );
    }
}

export default Results;


