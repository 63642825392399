import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import {withRouter} from 'react-router-dom';
import restService from '../../../../services/restService';
import ContentWrapper from '../../../template/Layout/ContentWrapper'
import Header from './components/Header';
import ConfirmationData from './components/ConfirmationData'
import Items from './components/Items'
import CargoReadiness from './components/CargoReadiness'

const rest = new restService();

class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        };
    }

    componentDidMount = () => {
        rest.EXEC({ _function: 'GetOneOrder', params: this.props.match.params.POId + '?type=confirmation' }, fetch).then(success => {
            if (success.data) {
                this.setState({
                    data: success.data
                })
            }
        }).catch(error => {})
    }

    render() {
        return (
            <ContentWrapper>
                <div>
                    <div className="content-heading">
                        <div className="row">
                            <em className="fas fa-th-list fa-1x mr-2"></em>
                            Cargo Readiness Confirmation
                        </div>
                    </div>
                    <Header data={this.state.data}></Header>
                    <ConfirmationData data={this.state.data}></ConfirmationData>
                    <Items data={this.state.data}></Items>
                    <CargoReadiness data={this.state.data} POId={this.props.match.params.POId}></CargoReadiness>
                </div>
            </ContentWrapper>
        );
    }
}
export default withRouter(FormExtended);