export const configOrder = [
  {
    id: 'POTransportMode',
    type: 'img',
    width: 20
  },
  {
    id: 'PONumber',
    label: 'PO#',
    type: 'link',
    linkVal: 'POId',
    link: '/po-detail-view/',
    width: 20
  },
  {
    id: 'SplitNo',
    label: 'Split No.',
    type: 'text',
    width: 20      
  },
  {
    id: 'POStatus',
    label: 'Status',
    type: 'status',
    width: 20        
  },
  {
    id: 'POProjectName',
    label: 'Project',
    type: 'text',
    width: 20         
  },
  {
    id: 'POSupplier',
    label: 'Supplier',
    type: 'text',
    width: 20      
  },
  {
    id: 'Division',
    label: 'PO Division',
    type: 'text',
    width: 20          
  },
  {
    id: 'POCreateDate',
    label: 'PO Creation Date',
    type: 'text',
    width: 20        
  },
  {
    id: 'RequestedExWorksDate',
    label: 'Requested Ex Works Date',
    type: 'text',
    width: 20          
  },
  {
    id: 'RequiredDestination',
    label: 'Required at Destination',
    type: 'text',
    width: 20        
  }                
];

export const configBooking = [
  {
    id: 'BOTransportMode',
    type: 'img',
  },
  {
    id: 'BookingNumber',
    label: 'Booking#',
    type: 'text'
  },
  {
    id: 'BOStatus',
    label: 'Status',
    type: 'status'
  },
  {
    id: 'BOSupplier',
    label: 'Supplier',
    type: 'text'         
  },
  {
    id: 'BOConsignee',
    label: 'Consignee',
    type: 'text'         
  },
  {
    id: 'BOOrigin',
    label: 'Origin',
    type: 'text'      
  },
  {
    id: 'BookingDate',
    label: 'Booking Date',
    type: 'text'          
  },
  {
    id: 'BOETD',
    label: 'ETD',
    type: 'text'        
  },
  {
    id: 'BOETA',
    label: 'ETA',
    type: 'text'          
  },
  {
    id: 'POs',
    label: 'POs',
    type: 'popup',
    title: 'POs'         
  }                
];

export const configShipment = [
  {
    id: 'TransportMode',
    type: 'img',
  },
  {
    id: 'ShipmentNumber',
    label: 'Shipment#',
    type: 'link',
    linkVal: 'ShipmentNumber',
    link: '/tracking/'
  },
  {
    id: 'Status',
    label: 'Status',
    type: 'status'        
  },
  {
    id: 'Supplier',
    label: 'Supplier',
    type: 'text'         
  },
  {
    id: 'POS',
    label: 'POs',
    type: 'popup',
    title: 'POs'
  },
  {
    id: 'Origin',
    label: 'From',
    type: 'text'      
  },
  {
    id: 'Destination',
    label: 'To',
    type: 'text'          
  },
  {
    id: 'ETD',
    label: 'ETD',
    type: 'text'        
  },
  {
    id: 'ETA',
    label: 'ETA',
    type: 'text'          
  },
  {
    id: 'Progress',
    label: 'On Time',
    type: 'progress'         
  }                
];
