export const Services = {
   "FILES": {
      "path": "https://magicdomeapifiles.azurewebsites.net/"
   },
   "REPORT": {
      "path": "https://magicdomeapireports.azurewebsites.net/"
   },
   "ADMIN": {
      "path": "https://magicdomeapiadmin.azurewebsites.net/"
   }
}