import React, { Component } from 'react';
// import RestService from '../../../../../../../../services/restService'
import { FilePond, File } from 'react-filepond';
import path from 'path'
import { Input, Button } from 'reactstrap';
import FormModel from './models/form'
import Fade from 'react-reveal/Fade';
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
import Axios from 'axios';

// const rest = new RestService()


class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Form: FormModel,
            files: [],
            Document: {},
            shipmentDataPlane : {},
            selects: {
                'DOC-CATEGORIES': [],
            },
            Inputs: '',
            disable: false
        };
    }

    componentDidMount = () => {
        this.getCategoriescatalog()
        this.setState({
            shipmentDataPlane: this.props.shipmentDataPlane
        }, () => {

            this.buildForm()
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            shipmentDataPlane: nextProps.shipmentDataPlane
        }, () => {
            this.buildForm()
        })
    }

    getCategoriescatalog = async () =>{
        const moduleId = 3
        let categories = await Axios.get(`https://poseidoncatalog.azurewebsites.net/categories/catalog?mId=${moduleId}`);

        if (categories.data){
            const response =  categories.data.data
            const selectOptions = {'DOC-CATEGORIES': response}

            this.setState({ selects: selectOptions }, () => this.buildForm());

      }
    }





    handleChange = name => event => {
        let { Document } = this.state
        Document[name] = event.target.value
        this.setState({ Document }, () => {
            this.buildForm()
        })
    }

    handleChangeSelect = (selected) => event => {
        let { Document, Form } = this.state;
        if (event && event.value) {

            Document[selected] = event.value;
            this.setState({ Document, Form }, () => {
                this.buildForm()
            })
        } else {

            Document[selected] = '';
            this.setState({ Document, Form }, () => {
                this.buildForm()
            })
        }
    };


    handleChangeDate = name => event => {

        let { Document } = this.state
        try {
            Document[name] = event.format("YYYY-MM-DD")
            this.setState({ Document }, () => {
                this.buildForm()
            })
        }
        catch (ex) {
            Document[name] = ""
            this.setState({ Document }
            )
        }
    }

    buildForm = () => {
        const { Document, selects, Form } = this.state

        let Inputs = Form.map((e, i) => {
            if (e.type === 'Input' && e.show) return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite> <span id={e.label} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ""}</label></span></Fade>
                    <Input
                        id={e.id}
                        required
                        type={e.datatype}
                        placeholder={e.placeHolder}
                        name={e.id}
                        value={Document[e.id]}
                        maxlength={e.maxlength}
                        minlength={e.minlength}
                        onChange={this.handleChange(e.id)}
                        max={e.max} />
                    <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
                </div>
            )
            else if (e.type === "Select" && e.show) return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ""} </label></span></Fade>
                    <Select
                        id={e.id}
                        required
                        type={e.datatype}
                        name={e.name}
                        value={Document[e.id]}
                        onChange={this.handleChangeSelect(e.id)}
                        options={selects[e.options]}
                    />
                    <Fade left opposite><label style={e.style} >{e.error}</label></Fade>
                </div>)
            else {
              return (<></>)
            }
        })
        this.setState({
            Inputs
        })
    }
    CreateNewComment = evt => {
        evt.preventDefault();
        let { Document, shipmentDataPlane } = this.state
        const formData = new FormData();
        if(Document.file && Document.Name && Document.CategoryId){
            formData.append('file', Document.file)
            formData.append('Name', Document.Name)
            formData.append('DocCategoryId', parseInt(Document.CategoryId))
            formData.append('Type', path.extname(Document.file.name))
            formData.append('RowId', shipmentDataPlane.ShipmentId)
            formData.append('ModuleId', 3)

            let url = 'https://poseidonfiles.azurewebsites.net/upload/';
            //let url = 'http://localhost:3014/upload/'
            this.setState({disable: true})
            fetch(`${url}`, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(success => {

                this.props.callback(success)
            })
            .catch(error => {
                this.props.onError(error)
            })
        }
    }

    onDrop = fileItems => {
        let { Document } = this.state
        Document.file = fileItems.length > 0 ? fileItems[0].file : ''
        this.setState({ Document }, () => this.buildForm())
    }


    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <FilePond
                            onupdatefiles={(fileItems) => {
                                this.setState({
                                    files: fileItems.map(fileItem => fileItem.file)
                                }, () => {
                                    this.onDrop(fileItems);
                                });
                            }}>
                            {this.state.files.map(file => (
                                <File key={file} src={file} origin="local" />
                            ))}
                        </FilePond>
                    </div>
                    {this.state.Inputs}
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr></hr>
                    </div>
                    <div className="col-md-6 col-lg-8 col-sm-12"></div>
                    <div className="col-md-3 col-lg-1 col-sm-6">
                        <Button disabled={this.state.disable} variant="contained" color="primary" className="button" onClick={this.CreateNewComment} size="lg">
                            Save and complete
                    </Button>
                    </div>
                </div>
            </>

        );
    }
}

export default Tracking;
