import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import './styles.scss';


/*=============================================
=            Loader Component                 =
=============================================*/

function Loader (){
  let _rows = [];
  for (let index = 0; index < 10; index++) {      
    _rows.push(<Skeleton height={28}></Skeleton>);
  }

  return (
    <div className="_skeleton_loader">
      <React.Fragment>
        <Skeleton height={65}/>
      {
        _rows
      }
      <Skeleton height={24} width={"20%"}></Skeleton>
      </React.Fragment>
    </div>
  )
}

export default Loader;