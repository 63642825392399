import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Input, Row, Col, Card, CardBody } from 'reactstrap';
import DataTable from './DataTable'
import JSON from './json'
import Graphics from './Graphics';

import { FilterHelper } from './helpers/HelperFilters';
import { SummaryModel, SummaryData, SummaryForm } from './models/SummaryModel';

import { Paper } from '@material-ui/core';

// ? React Select
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import Budget from './Graphics/Budget';
import Global from './Graphics/Global';
import Spend from './Graphics/Spend';
import IMAGENSUMM from '../cm-summary/Image64/file'



class Summary extends Component {
  state = {
    ModelData: SummaryModel,
    form: '',
    dataCost: SummaryData.data,
    data: SummaryData.data
  };

  /*=============================================
  =            Handle Change Input Data         =
  =============================================*/

  OnChangeInputData = id => evt => {
    let { ModelData, dataCost } = this.state;

    if (id === 'month') {
      if (evt) ModelData[id] = evt.value;
      else ModelData[id] = '';

    } else {
      let id = evt.target.id;
      let value = evt.target.value;

      ModelData[id] = value;
    }

    // ? valida si los campos estan vacios y envia data por defecto, 
    //? si no, se llama a la funcion helper para generar la nueva data #endregion
    this.setState({ ModelData }, () => {
      let { year, month, division, cruise, country } = this.state.ModelData;
      if (year || month || division || cruise || country) {
        this.setState({ data: FilterHelper(ModelData, dataCost) }, () => {
          this.formCreate();
        });
      } else {
        this.setState({ data: SummaryData.data }, () => {
          this.formCreate();
        });
      }
    });


  };

  /*=============================================
  =            Function for formCreate          =
  =============================================*/
  formCreate = () => {
    let { ModelData } = this.state;
    let form = SummaryForm.form.map((e, key) => {
      if (e.type === 'Input')
        return (
          <Col md={4} xl={4} key={key}>
            <Input
              type={e.type}
              name={e.name}
              id={e.id}
              placeholder={e.placeholder}
              onChange={this.OnChangeInputData(e.id)}
              value={ModelData[e.id]}
              className={e.class}
              maxLength={e.maxlenght}
            />
          </Col>
        );
      else if (e.type === 'Combo')
        return (
          <Col md={4} xl={4} key={key}>
            <Select
              className='_comboFilters'
              name={e.name}
              id={e.id}
              placeholder={e.label}
              value={ModelData[e.id]}
              options={e.data}
              onChange={this.OnChangeInputData(e.id)}
            />
          </Col>
        );
    });

    this.setState({ form });
  };

  /*=============================================
  =            Life cycle components            =
  =============================================*/

  componentDidMount = () => {
    this.formCreate();
  };

  /*=====  End of Life cycle components  ======*/

  render() {
    let { form } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas icon-chart fa-1x mr-2"></em>
            Cost Management
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            {/* <Paper elevation={0}> */}
            <Col xs={12} xl={12}>

              <div className="row">
                <div className="col-8">
                  <div className="row">
                    <div className="col-1">
                      <img className="imgggggg" src={IMAGENSUMM} />
                    </div>
                    <div className="col-6">
                      <legend>Summary</legend>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  {/* <Col md={12} xl={12}> */}
                  <center><h4 className="mb-3 py-2">Filter</h4></center>
                  <Row>
                    {form}
                  </Row>
                  {/* </Col> */}
                </div>
              </div>

            </Col>
            <Col md={12} xl={12}>

              <Graphics data={this.state.data}></Graphics>

            </Col>
            <Col md={12} xl={12}>

              <DataTable data={this.state.data}></DataTable>


            </Col>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default Summary;