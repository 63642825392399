import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CardBody, FormGroup } from 'reactstrap';
import { Col } from 'react-flexbox-grid';
// import Oauth from './../../../services/oauth/oauthService';
import Login from './Login';
import Errors from '../../../components/template/Errors';
import moment from 'moment';
// import imagenLogin from './../../../resources/login4k.jpg';
import imagenLogin from './../../../resources/Login_BG2.png';



// const oauth = new Oauth();
const BackgroundHead = {
  height: '100%',
  backgroundImage: 'url(' + imagenLogin + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: 'calc(100vw + 48px)',
  margin: -24,
  padding: 24,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {
        open: false,
        message: '',
        type: ''
      },
      login: {
        email: '',
        valid: false
      },
      nexStep: false
    };
  }
  /**
   * Verify authentication
   */
  handleLogin = () => {
    if (this.state.login.valid) {
      this.setState({ nexStep: true });
    } else {
      document.getElementById('input_email').className = 'invalidInput';
      document.getElementById('input_email').focus();
      document.getElementById('email_alert').removeAttribute('hidden');
    }
  }

  onError = error => {
    this.setState({
      error: error.error
    });
  };


  changeView = name => event => {
    this.setState({ nexStep: false });
  }

  validateForm = name => event => {
    let value = event.target.value;
    let { login } = this.state;
    const emailRegex = new RegExp(/^\S+@\S+\.\S+$/);

    if (emailRegex.test(value)) {
      login.valid = true;
      document.getElementById('input_email').className = 'validInput';
      document.getElementById('email_alert').setAttribute('hidden', 'hidden');
    } else {
      login.valid = false;
      document.getElementById('input_email').className = 'invalidInput';
      document.getElementById('email_alert').removeAttribute('hidden');
    }

    login.email = value;
    this.setState({ login });

  }

  FirstLoad = () => {
    return (
      <>
        <div style={{ height: '1em' }} className="col-md-12"></div>
        <div className="col-md-12 _containerLogin">
          <div className="col-md-12"></div>
          <input type="text" id="input_email" className="_input2" placeholder="Email Address" value={this.state.login.email} onChange={this.validateForm()}></input>
          <div className="_alert" id="email_alert" hidden>Please enter a valid email address</div>
          {/* <button className="_loginButton" onClick={this.handleLogin}>Next</button> */}
          {/* login 2*/}
          <button className="_loginButton2" onClick={this.handleLogin}>Next</button>  

          
        </div>
      </>
    );
  }

  componentDidMount = () => {
    try {
      let className = document.getElementById('body').className;
      className += ' _root_login';
      document.getElementById('body').className = className;
      if (document.getElementById('input_email').value === 'info@magic-log.com') {
        let { login } = this.state;
        login.valid = true;
        this.setState({ login });
      }
    } catch (error) {
      console.error('Error Login');
    }
  }



  recieveProps = (open) => this.setState({ error: { open: open, type: this.state.error.type } })

  render() {
    return (
      // <div className="limiter" style={BackgroundHead}>
      //   <div style={{ background: '#1d1d1d', height: '7em' }} className='col-md-12'>
      //     <div className="row">
      //       <div className="col-md-5">
      //         <img style={{ marginTop: '12px', marginLeft: '6em' }} className="img-fluid" src={'https://fsposeidon.blob.core.windows.net/magicdome/logoMD.png'} alt="App Logo" width="163" height="70" />
      //       </div>
      //       <div className="col-md-4">
      //         <div style={{ height: '4em' }}></div>
      //         <h4 style={{ letterSpacing: '1px' }}>Your company doesn’t count with MagicDome yet?</h4>
      //       </div>
      //       <div className="col-md-3">
      //         <button className="_loginButtonMore" onClick="" > Learn More</button>
      //       </div>
      //     </div>
      //   </div>
      //   <div className='login-container'>


      //     <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
      //     <Col xs={12} md={8} lg={5} className="">
      //     </Col>
      //     <Col xs={12} md={8} lg={1} className="ml-auto mr-auto">
      //       {/* <CardBody style={{ textAlign: 'center' }}>
      //       <div className="logo-container">
      //         <img className='img_logo' style={{ width: '100%' }} src={'https://fsposeidon.blob.core.windows.net/general/interglobo-logo-psdn-white-payoff.png'} alt='poseidon-logo'></img>
      //       </div>
      //     </CardBody> */}
      //       <CardBody style={{ background: '#42adec', borderRadius: '9px 9px 0px 0px', height: '3em', width: '27em' }}>
      //         <h4 style={{ color: '#FFFF' }}>Sign In to MagicDome</h4>
      //       </CardBody>
      //       <CardBody style={{ background: '#1d1d1d', height: '31em', width: '27em', borderRadius: '0px 0px 9px 9px', }}>
      //         <FormGroup>
      //           {this.state.nexStep ? <div className="goBack"><em className="fa-2x icon-arrow-left mr-2" onClick={this.changeView()}></em></div> : ''}
      //           {this.state.nexStep ? <Login onError={this.onError} email={this.state.login.email} props={this.props}></Login> : this.FirstLoad()}
      //         </FormGroup>
      //         <div style={{ marginBottom: '1em' }} className="col-md-12">
      //           <a href="/" style={{ color: '#42ADEC', fontSize: '12px', textDecoration: 'underline' }}> Forgot your Password? </a>
      //         </div>
      //         <div style={{ background: 'rgb(72 72 72)', marginTop: '-2px' }} class="ui divider"></div>

      //         <div style={{ marginBottom: '1em' }} className="col-md-12 n">
      //           <span href="/" style={{ color: '#6d6c6c', fontSize: '12px' }}> Need help? <a href="/" style={{ color: '#42ADEC', fontSize: '12px', textDecoration: 'underline' }}> Get in touch. </a> </span>
      //         </div>
      //         <div style={{ marginBottom: '2em' }} className="col-md-12 ">
      //           <span href="/" style={{ color: '#ffff', fontSize: '12px' }}> <a href="/" style={{ color: '#42ADEC', fontSize: '12px', textDecoration: 'underline' }}> Terms of Use Terms of Use  </a> | <a href="/" style={{ color: '#42ADEC', fontSize: '12px', textDecoration: 'underline' }}> Privacy Policy </a> </span>
      //         </div>
      //         <div className="col-md-12 ">
      //           <img className='' style={{ width: '50%' }} src={'https://fsposeidon.blob.core.windows.net/magicdome/Logo-MagicLog.png'} alt='magic-logo'></img>
      //         </div>
      //         <div style={{ marginBottom: '1em' }} className="col-md-12 n">
      //           <span href="/" style={{ color: '#6d6c6c', fontSize: '12px' }}> Copyright Copyright © 2020 MagicLog </span>
      //         </div>
      //       </CardBody>
      //       <CardBody>
      //         {/* <Col  className="_footerContent">
      //         <div className="col-md-12 footer-top"></div>
      //         <div className="col-md-12 page-footer">
      //           <div className="col-md-12 mobile">
      //             <a id="login-apple-store-link" > <img src="https://fsposeidon.blob.core.windows.net/general/app_store_image.jpg" className="apple_image" alt='ios-app'></img> </a>
      //             <a id="login-apple-store-link"> <img src="https://fsposeidon.blob.core.windows.net/general/android_download.png" className="android_image" alt='android-app'></img> </a>
      //           </div>
      //           <div className="col-md-12 copyright">Copyright © {moment(new Date()).format('YYYY')} <a className="em-a" href="https://www.interglobo.com/">Interglobo</a>. All rights reserved. </div>
      //           <div className="col-md-12 copyright-terms"> <a id="login-terms-link" className="em-a"> Terms and Conditions</a></div>
      //         </div>
      //         <div className="col-md-12 page-bootom"></div>
      //       </Col> */}
      //       </CardBody>
      //     </Col>
      //   </div>
      // </div>


      // login 2.0

      <div className="limiter" style={BackgroundHead}>
        <div  className='login-container'>
          <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>

          <Col style={{marginTop:'-2em'}}  className="">
            <CardBody style={{ textAlign:'center', marginBottom:'1em'}} >
              <img style={{ marginTop: '12px' }} className="img-fluid" src={'https://fsposeidon.blob.core.windows.net/magicdome/logoMD.png'} alt="App Logo" width="340" height="70" />
            </CardBody>
            <CardBody style={{ background: '#1b1d1b', height: '29em', width: '30em', borderRadius: '17px 17px 17px 17px', }}>
            <div style={{ textAlign:'center' }} className="col-md-12">
            <img style={{ marginTop: '12px'}} className="img-fluid" src={'https://fsposeidon.blob.core.windows.net/magicdome/Login_Title.png'} alt="App Logo" width="100" height="70" />
            </div>
              <FormGroup>
                {this.state.nexStep ? <div className="goBack"><em className="fa-2x icon-arrow-left mr-2" onClick={this.changeView()}></em></div> : ''}
                {this.state.nexStep ? <Login onError={this.onError} email={this.state.login.email} props={this.props}></Login> : this.FirstLoad()}
              </FormGroup>
              <div style={{ marginBottom: '2em', textAlign: "center" }} className="col-md-12">
                <a className="decorationHover" href="/" style={{ color: '#42ADEC', fontSize: '12px'}}> Forgot your Password? </a>
              </div>
              <div style={{ marginBottom: '2em', textAlign: "center" }} className="col-md-12 ">
                <span href="/" style={{ color: '#ffff', fontSize: '12px' }}>
                 <span href="/" style={{ color: '#6d6c6c', fontSize: '14px', }}> Need help?  </span>&nbsp;&nbsp; <a target="_blank1" className="decorationHover" href="https://www.magic-log.com/get-in-touch" style={{ color: '#42ADEC', fontSize: '14px'}}> Get in touch </a>&nbsp;&nbsp; | &nbsp;&nbsp;<a target="_blank1" className="decorationHover" href="https://www.magic-log.com/terms-of-use" style={{ color: '#42ADEC', fontSize: '14px' }}> Terms of Use </a>&nbsp;&nbsp; | &nbsp;&nbsp; <a target="_blank1" className="decorationHover" href="https://www.magic-log.com/privacy-policy" style={{ color: '#42ADEC', fontSize: '14px' }}> Privacy Policy </a> </span>
              </div>
              <div style={{ background: 'rgb(72 72 72 / 15%)', marginTop: '-2px' }} class="ui divider"></div>
              <div style={{ textAlign: 'center', marginTop:this.state.nexStep ? '' : '3em' }} className="col-md-12 ">
                <img className='' style={{ width: '35%' }} src={'https://fsposeidon.blob.core.windows.net/magicdome/Logo-MagicLog.png'} alt='magic-logo'></img>
              </div>
              <div style={{ textAlign: 'center' }} className="col-md-12 n">
                <span href="/" style={{ color: '#6d6c6c', fontSize: '12px' }}> Copyright © 2020 MagicLog </span>
              </div>
            </CardBody>
            <CardBody>
              {/* <Col  className="_footerContent">
              <div className="col-md-12 footer-top"></div>
              <div className="col-md-12 page-footer">
                <div className="col-md-12 mobile">
                  <a id="login-apple-store-link" > <img src="https://fsposeidon.blob.core.windows.net/general/app_store_image.jpg" className="apple_image" alt='ios-app'></img> </a>
                  <a id="login-apple-store-link"> <img src="https://fsposeidon.blob.core.windows.net/general/android_download.png" className="android_image" alt='android-app'></img> </a>
                </div>
                <div className="col-md-12 copyright">Copyright © {moment(new Date()).format('YYYY')} <a className="em-a" href="https://www.interglobo.com/">Interglobo</a>. All rights reserved. </div>
                <div className="col-md-12 copyright-terms"> <a id="login-terms-link" className="em-a"> Terms and Conditions</a></div>
              </div>
              <div className="col-md-12 page-bootom"></div>
            </Col> */}
            </CardBody>
          </Col>
        </div>
      </div>

    );
  }
}

export default withRouter(App);;
