import React from "react";
import { withRouter } from "react-router-dom";
import PreviewForm from "./previewForm";
import EditForm from "./editForm";

const FormCard = ({
  lng,
  edit,
  fields,
  values = {},
  updateEntityInfo,
}) => {
  if (!fields) return null;
  if (edit)
    return (
      <EditForm
        fields={fields.edit}
        values={values}
        lng={lng}
        updateEntityInfo={updateEntityInfo}
      />
    );

  return <PreviewForm fields={fields.previewForm} values={values} />;
};

export default withRouter(FormCard);
