import React from 'react';

function CardComponent(props){
  return (
    <div className="card" style={{width: '100'}}>
      {/* <img className="card-img-top" src="" alt="Card image cap"/> */}
      <div className="card-body">
        {props.children}
      </div>
    </div>
  );
};

export default CardComponent;