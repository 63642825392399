import React, { Component } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import Table from '../../../../../template/Table'
import Config from '../../models/Config'
class AttachedOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            rows: [],
            items: [],
            columns: [
                {
                    label: "",
                    key: 'PONumber'
                },
                {
                    label: "Creation Date",
                    key: 'POCreateDate'
                },
                {
                    label: "Promise Cargo Ready",
                    key: 'PromiseCargoReadyDate'
                },
                {
                    label: "QTTY Requested",
                    key: 'TotalQtyOrdered'
                },
                {
                    label: "QTTY Confirmed",
                    key: 'TotalQtyConfirmed'
                },
                {
                    label: "Country of Origin",
                    key: 'OriginCountry'
                },
            ],
        }
    }

    componentDidMount = () => {
        this.setState({
            data: this.props.data
        }, () => {
            let items = this.state.data && this.state.data.poList && Array.isArray(this.state.data.poList) && this.state.data.poList.length > 0 ? this.state.data.poList : []
            this.setState({
                items
            })
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        }, () => {
            let items = this.state.data && this.state.data.poList && Array.isArray(this.state.data.poList) && this.state.data.poList.length > 0 ? this.state.data.poList : []
            this.setState({
                items
            })
        })
    }

    render = () => {
        let { items } = this.state
        return (
            <Card>
                <CardBody>
                    <h4 className="mb-3 py-2">&nbsp;&nbsp;Attached Order</h4>
                    <div className="row">
                        <div className="col-1">
                            <em className="fas fa-clipboard-list fa-4x mb-3 py-2"></em>
                        </div>
                        <div className="col-10">
                            <Table data={items} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}
                            ></Table>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default AttachedOrder;