import React from 'react';
import DialogList from '../SecComponents/dialogList';

const purchaseReference = {
  message: 'Please indicate any of the references:',
  optionList: [
    'PO No.'
  ],
};

function PurchaseOptions(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <DialogList references={purchaseReference} />
    </div>
  );
}

export default PurchaseOptions;
