const Forms = [
  {
    type: "multipleSelect",
    options: "StatusDesc",
    label: "Status",
    id2: "StatusId",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "Status",
    id: "StatusId",
    datatype: "select",
    show: true,
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    values: [],
  },
  {
    type: "multipleSelect",
    options: "SupplierName",
    label: "Supplier",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "Supplier",
    id: "Supplier",
    id2: "SupplierCode",
    datatype: "select",
    show: true,
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    values: [],
  },
  {
    type: "multipleSelect",
    options: "ConsigneeName",
    label: "Consignee",
    class: "col-md-3 col-lg-3 col-sm-12",
    name: "Consignee",
    id2: "ConsigneeCode",
    id: "Consignee",
    datatype: "select",
    show: true,
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    values: [],
  },
  {
    type: "Datetime",
    label: "Creation Date",
    name: "POCreateDate",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "POCreateDate",
    id2: "POCreateDate",
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    format: "YYYY-MM-DD",
    values: [],
  },
  {
    type: "Datetime",
    label: "Requested Delivery Date ",
    name: "RequiredDestination",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "RequiredDestination",
    id2: "RequiredDestination",
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    format: "YYYY-MM-DD",
    values: [],
  },
  {
    type: "Datetime",
    label: "Comments Last Call ",
    name: "CommentsLastCall",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "CommentsLastCall",
    id2: "CommentsLastCall",
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    format: "YYYY-MM-DD",
    values: [],
  },
  {
    type: "Datetime",
    label: "Actual Cargo Ready",
    name: "ActualCargoReadyDate",
    class: "col-md-3 col-lg-3 col-sm-12",
    id: "ActualCargoReadyDate",
    id2: "ActualCargoReadyDate",
    style: { fontSize: "11px", fontWeight: "bold" },
    value: "",
    format: "YYYY-MM-DD",
    values: [],
  },
];

export default Forms;
