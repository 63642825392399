export const Search = (all, search) => {

   
    let MainKeys = Object.keys(all)
    let results = []
    MainKeys.map((MainIndex) => {
        let Group = all[MainIndex];
        Group.forEach((element) => {
            let Description = element.Description.toUpperCase().indexOf(search.toUpperCase())
            if (Description >= 0) {
                results.push(element)
            }
        });
        return MainIndex;
    });
    return GroupBy(results,'Group')
}

function GroupBy(data, byRow) {
    let By = {
        // 1:[],
        // 2:[],
        // 3:[],
        // 4:[],
        // 5:[],
        // 6:[],
        // 7:[],
    }
    data.forEach((e) => {
        let GroupId = e[byRow]
        if (GroupId && By[GroupId]) {
            By[GroupId].push(e)
        } else {
            By[GroupId] = []
            By[GroupId].push(e)
        }
    })
    return By
}