export const searchBy = (words,array)=>{
  let results = [];
  array.forEach((e) => {
    let BookingNumber = e.BookingNumber ? e.BookingNumber.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let id = e.id ? e.id.toUpperCase().indexOf(words.toUpperCase()) :-1;
    let PONumber = e.PONumber ? e.PONumber.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let ContainersNum = e.ContainersNum ? e.ContainersNum.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let From = e.From.Location && e.From.Location.country ? e.From.Location.country.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let To = e.To.Location && e.To.Location.country ? e.To.Location.country.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let Status = e.Status && e.Status.label ? e.Status.label.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let Cruise = e.Project && e.Project.name ? e.Project.name.toUpperCase().indexOf(words.toUpperCase()) : -1;
    let Supplier = e.Supplier && e.Supplier.name ? e.Supplier.name.toUpperCase().indexOf(words.toUpperCase()) : -1;

    if (BookingNumber >= 0) {
      results.push(e);
    }
    else if (id >= 0) {
      results.push(e);
    }
    else if (PONumber >= 0) {
      results.push(e);
    }
    else if (From >= 0) {
      results.push(e);
    }
    else if (To >= 0) {
      results.push(e);
    }
    else if (Status >= 0) {
      results.push(e);
    }
    else if (Cruise >= 0) {
      results.push(e);
    }
    else if (Supplier >= 0) {
      results.push(e);
    } else if (ContainersNum >= 0){
      results.push(e);
    }
  });
  return results;
};