/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Grid, IconButton } from "@mui/material";
import { Delete, LibraryAddOutlined } from "@mui/icons-material";
import { message } from "antd";
import { Button } from "reactstrap";

import {
  ItemDetailResumeFormDates,
  ItemDetailResumeForm,
} from "../../../../template";
import { Input, Select } from "../../Forms/Input";
import { useCatalogs } from "../hooks";
import { Fab } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

const InputRow = ({
  field,
  values,
  handleChange,
  handleCheckbox,
  handleOpen,
  lng,
  isOpen,
  index,
  selectOptions,
  findCatalog,
  findInCatalog,
  handleCatalogChange,
  handleDeleteOption,
  fieldsLength,
}) => {
  const selectField = {
    id: `selectrow-${index}`,
    label: "Field name",
    required: true,
    error: false,
  };
  const handleSelectChange = (id, value) => {
    handleCatalogChange(index, value);
  };

  const handleDelete = () => {
    handleDeleteOption(index);
  };

  return (
    <Grid container alignItems="center" gap={4}>
      <Grid xs={4}>
        <Select
          field={selectField}
          disableClearable={true}
          error={false}
          value={field.catalogSelected}
          options={selectOptions()}
          handleChange={handleSelectChange}
        />
      </Grid>
      <Grid xs={4}>
        <Input
          field={field}
          values={values}
          handleChange={handleChange}
          handleCheckbox={handleCheckbox}
          handleOpen={handleOpen}
          findCatalog={findCatalog}
          findInCatalog={findInCatalog}
          lng={lng}
          isOpen={isOpen}
        />
      </Grid>
      <Grid xs={1}>
        {fieldsLength > 1 && (
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export const ListItemMassiveUpdateModal = ({
  open,
  toggle,
  update,
  lng,
  poStatus,
}) => {
  const {
    incotermCatalog,
    uomCatalog,
    transportModeCatalog,
    uomCatalogD,
    uomCatalogO,
    uomCatalogQ,
    uomCatalogT,
    uomCatalogV,
    uomCatalogW,
  } = useCatalogs(lng);
  const [fields, setFields] = useState([]);
  const [fieldsSelected, setFieldsSelected] = useState([]);
  const [poInfo, setPoInfo] = useState({});
  const [isOpen, setIsOpen] = useState({});
  const [changeValues, setChangeValues] = useState(true);

  const handleChange = (id, value) => {
    let newPoInfo = { ...poInfo };
    newPoInfo[id] = value;
    setChangeValues(true);
    setPoInfo(newPoInfo);
  };

  const handleCheckbox = (id) => {
    let newPoInfo = { ...poInfo };
    newPoInfo[id] = Number(!Boolean(newPoInfo[id]));
    handleChange(id, newPoInfo[id]);
  };

  const findCatalog = (catalogName) => {
    switch (catalogName) {
      case "incotermCatalog":
        return incotermCatalog;
      case "uomCatalog":
        return uomCatalog;
      case "transportModeCatalog":
        return transportModeCatalog;
      case "uomCatalogO":
        return uomCatalogO;
      case "uomCatalogV":
        return uomCatalogV;
      case "uomCatalogT":
        return uomCatalogT;
      case "uomCatalogW":
        return uomCatalogW;
      case "uomCatalogQ":
        return uomCatalogQ;
      case "uomCatalogD":
        return uomCatalogD;
      default:
        return [];
    }
  };

  const findInCatalog = (values, id, catalogName) => {
    let catalog = findCatalog(catalogName);
    let value = catalog.find((item) => {
      return item.value === values[id] || item.label === values[id];
    });
    if (value && value.value !== poInfo[id]) {
      handleChange(id, value.value);
    }
    return value || "";
  };

  const handleOpen = (id, open) => {
    let newIsOpen = { ...isOpen };
    newIsOpen[id] = open;
    setIsOpen(newIsOpen);
  };

  const handleCatalogChange = (index, value) => {
    let newFieldSelected = fields.find((field) => field.id === value);
    let newFieldsSelected = [...fieldsSelected];
    let newPoInfo = { ...poInfo };
    newPoInfo[fieldsSelected[index].id] = null;
    setPoInfo(newPoInfo);
    newFieldSelected.catalogSelected = value;
    newFieldsSelected[index] = newFieldSelected;
    setFieldsSelected(newFieldsSelected);
    setChangeValues(true);
  };

  const handleDeleteOption = (index) => {
    let newFieldsSelected = [...fieldsSelected];
    newFieldsSelected.splice(index, 1);
    setFieldsSelected(newFieldsSelected);
  };

  const handleAddOption = () => {
    let newFieldsSelected = [...fieldsSelected];
    newFieldsSelected.push({
      id: `selectrow-${fieldsSelected.length}`,
      label: "Select a field and update its information:",
      required: true,
      error: false,
      type: "Input",
    });
    setFieldsSelected(newFieldsSelected);
    setChangeValues(true);
  };

  const selectOptions = () => {
    const fieldsInSelect = fields.filter(
      (field) =>
        !fieldsSelected.find((fieldSelected) => fieldSelected.id === field.id)
    );
    let options = fieldsInSelect.map((field) => {
      return { value: field.id, label: field.label };
    });
    return options;
  };

  useEffect(() => {
    setFields([]);
    setFieldsSelected([]);
    setPoInfo({});
    setChangeValues(true);
    initializate();
  }, [open]);

  useEffect(() => {
    initializate();
  }, []);

  const initializate = () => {
    const noEditableFields = [
      'QTYOrdered',
      // 'QTYConfirmed',
      'NetWeight',
      'RequestedExWorks',
      'RequiredCagoReady',
    ]
    let fields = [
      ...ItemDetailResumeForm.edit.filter((field) => {
        if (poStatus > 36) {
          return field.type !== "Label" && !noEditableFields.includes(field.id);
        }
        return field.type !== "Label";
      }),
      ...ItemDetailResumeFormDates.edit.filter(
        (field) => {
          if (poStatus > 36) {
            return field.type !== "Label" && !noEditableFields.includes(field.id);
          }
          return field.type !== "Label";
        }
      ),
    ];
    setFields(fields);
    let newPoInfo = { ...poInfo };
    newPoInfo[fields[0].id] = null;
    setPoInfo(newPoInfo);
    setFieldsSelected([
      {
        ...fields[0],
        catalogSelected: fields[0].id,
      },
    ]);
  }

  const handleUpdate = async () => {
    let isValid = true;
    let updatePO = {};
    fieldsSelected.forEach((field) => {
      let findInFields = fields.find((item) => item.id === field.id);
      if (findInFields && findInFields.required && !poInfo[field.id]) {
        isValid = false;
      } else {
        if (field.type === "Checkbox") {
          updatePO[field.id] = Number(Boolean(poInfo[field.id]));
        } else if (field.id.search('selectrow-') === -1) {
          updatePO[field.id] = poInfo[field.id] || null;
        }
      }
    });
    const updatePOIsEmpty = Object.keys(updatePO).length === 0;
    if (isValid && !updatePOIsEmpty) {
      try {
        await update(updatePO);
        message.success("Purchase order item successfully updated.");
      } catch (err) {
        console.log("error en update", err);
        message.error("Error updating purchase order item.");
      }
    } else {
      if (changeValues || updatePOIsEmpty) {
        message.error("Error updating purchase order item.");
        setChangeValues(false)
      }
    }
  };

  return (
    <Modal
      backdrop="static"
      isOpen={open}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "1000px", width: "100%" }}
      className="listItemModal"
    >
      <ModalHeader
        className="advancedSearchModal"
        style={{ background: 'linear-gradient(to right, #007ab9 0%, #00558c 100%)', color: "white" }}
      />
      <ModalBody>
        <Grid container spacing={2} style={{ margin: "1rem" }} rowGap={4}>
          <Grid xs={12} container style={{ gap: 8 }}>
            <LibraryAddOutlined />
            <h3>Massive update</h3>
          </Grid>
          <Grid container alignItems="center">
            <Grid xs={7}>
              <label>
                Select a field and update its information:
              </label>
            </Grid>
            <Grid xs={4}>
              {fields.length > fieldsSelected.length && (
                <div
                  className="float-right"
                  style={{
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                >
                  <Fab color="primary" size="small" aria-label="add" onClick={handleAddOption}>
                    <AddIcon />
                  </Fab>
                  <label
                    style={{ fontWeight: "bold" }}
                    className="euro-base-label ml-2"
                  >
                    Create new
                  </label>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container rowGap={4}>
            {fieldsSelected.map((field, index) => (
              <InputRow
                key={`inputRow-${field.id}-${index}`}
                field={field}
                values={poInfo}
                handleChange={handleChange}
                handleCheckbox={handleCheckbox}
                handleOpen={handleOpen}
                findCatalog={findCatalog}
                findInCatalog={findInCatalog}
                lng={lng}
                index={index}
                selectOptions={selectOptions}
                handleCatalogChange={handleCatalogChange}
                handleDeleteOption={handleDeleteOption}
                isOpen={isOpen}
                fieldsLength={fieldsSelected.length}
              />
            ))}
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooter className="modalFooterCenter">
        <center>
          <Button
            style={{
              marginRight: "20px",
              background: "grey",
              color: "#2b3b57",
              borderRadius: 20,
            }}
            color="primary"
            size="small"
            variant="contained"
            className="buttomSeaCargoCancel bgCancel"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            // color="primary"
            size="small"
            style={{
              background: "#5d9cec",
              color: "white",
              borderRadius: 20,
            }}
            className="buttomSeaCargo bgAccept"
            variant="contained"
            color="secondary"
            onClick={handleUpdate}
          >
            Update
          </Button>{" "}
        </center>
      </ModalFooter>
    </Modal>
  );
};
