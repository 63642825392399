import React, { Component } from 'react';
import RestService from '../../../../../services/restService'
import {Card,CardBody} from 'reactstrap'
import Results from './components/results'
import Downloader from './components/downloader'
import Map from './components/maps'
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import {Search} from './utils'

const rest = new RestService()

class EUGround extends Component {
    constructor(props) {
        super(props);
        this.state = {
            results : {},
            aux : {},
            tables : '',
            search : ''
        };
    }


    componentDidMount = () => {
        this.getResults()
    }


    componentWillReceiveProps = (nextProps) => {
        this.getResults()
    }


    getResults = () => {
        rest.EXEC({
            _function: 'GetPOManagementResults',
            params: '?groupBy=Group'
        }, fetch).then(success => {
            if (success.data) {
                this.setState({
                    results: success.data,
                    aux : success.data
                },()=>this.createTables())
            }
        })
    }

    createTables = ()=>{
        let {aux} = this.state
        let Keys = Object.keys(aux)
        let tables = Keys.map((index)=>{
            return(
                <div className="row">
                    <div className="col-12">
                        <hr></hr>
                    </div>
                    <div className="col-12">
                        <Results results={Array.isArray(aux[index])  ? aux[index] : []} id={index}></Results>
                    </div>
                </div>
            )
        })
        this.setState({
            tables
        })
    }


    onChange = name => event =>{
        let { results } = this.state
        this.setState({
            [name]:event.target.value
        },()=>{
            let aux = Search(results, this.state.search)
            this.setState({
                aux
            },()=>{
                this.createTables()
            })
        })
    }

    render() {

        return (
            <Card className="myCard">
            <CardBody>
                <div className="row">
                    <div className="col-12">
                        <Map data={[]}/>
                    </div>

                    <div className="col-12">
                        <hr/>
                    </div>
                    <div className="col-12">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>Search</InputGroupText>
                            </InputGroupAddon>
                            <Input 
                                placeholder="Description" 
                                id='search'
                                value = {this.state.search}
                                onChange={this.onChange('search')}
                            />
                        </InputGroup>
                    </div>
                </div>
                {this.state.tables}
                <div className="row">
                <div className='col-12'><hr></hr></div>
                <div className='col-11'></div>
                <div className="col-1">
                    <Downloader 
                        aux = {this.state.aux}
                    />
                </div>
                </div>
            </CardBody>
        </Card>
        );
    }
}

export default EUGround;


