import React, { useEffect, useCallback } from 'react';
import { Tab } from 'semantic-ui-react';
import OpenTickets from './Components/OpenTickets';
import ClosedTickets from './Components/ClosedTickets';
import { EN, ES } from './Etiquetas/Labels';
import { FormEnglish, FormEsp } from './Form';
import './theme/theme.scss';
import CreateNewTicket from './app/CreateTicket';
import Axios from 'axios';
import Loader from './loaders';
import { Services } from './Services'
import ContentWrapper from './Components/ContenWraper/ContentWrapper'
import './style.css'


function HelpDesk(props) {
  console.log("🚀 ~ HelpDesk ~ props:", props)
  const [Labels, setLabels] = React.useState(EN);
  const [Form, setForm] = React.useState(FormEnglish);
  const [ticketsOpen, setTicketsOpen] = React.useState([]);
  const [ticketsClosed, setTicketsClosed] = React.useState([]);
  const [ActiveLoader, setActiveLoader] = React.useState(false)
  const [language, setLanguaje] = React.useState(undefined)
  const [tz] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

  // const [xprops, setxProps] = React.useState({ _rolId: '1', _user: 74, UserName: 'Omar', Email: 'omar@magic-log.com' });


  const GetTikets = useCallback(async (id) => {
    let body = { id: id, _rolId: props._rolId, _user: props._user, timezone: tz }
    try {
      let Tikets = await Axios.post(`${Services.TICKETS.path}Ticket/getTicketsUser`, body);
      // console.log('TiketsOpen-->', Tikets);
      // let TiketsClosed = await Axios.post(`${Services.Tickets.path}Ticket/getTicketsClo`, body);
      setTicketsOpen(Tikets.data.data.Open);
      setTicketsClosed(Tikets.data.data.Closed)
      setActiveLoader(true)
    } catch (error) {
      console.log('The Error-->', error);
    }
  }, []);

  // const getProjects = useCallback(async (idProject) => {
  //   try {
  //     let Projets = await Axios.get(`${Services.TICKETS.path}project/getproject/${idProject}`);
  //     // console.log('this i the project--->', Projets.data.data[0].LanguageCode);
  //     setLabels(Projets.data.data[0].LanguageCode === 'ES' ? ES : EN)
  //     setForm(Projets.data.data[0].LanguageCode === 'ES' ? FormEsp : FormEnglish)
  //     setLanguaje(Projets.data.data[0].LanguageCode === 'ES' ? 'Español' : 'Ingles')
  //     GetTikets(idProject);
  //   } catch (error) {
  //     console.log('The Error-->', error);
  //   }
  // }, []);


  useEffect(() => {
    // getProjects(props.ProjectId)
    GetTikets(props.ProjectId);
    setLanguaje(props.lng === 'EN' ? 'Ingles' : 'Español')
    setLabels(props.lng === 'EN' ? EN : ES)
    setForm(props.lng === 'EN' ? FormEnglish : FormEsp)
    // setLabels(props._language === 'Ingles' ? Ingles : Español)
    // setForm(props._language === 'Ingles' ? FormEnglish : FormEsp)
    // GetTikets();
  }, [props]);


  return (
    <ContentWrapper>
      {/* <div style={{ background: '#f5f5f5' }}> */}
      <Loader hidden={ActiveLoader} projectId={props.ProjectId} />
      <div className="content-heading">
        <div className={props.style ? props.style.titleModule : ''}>
          <i className={Labels.header.icon} />
          <b>{Labels.header.title}</b>
        </div>
      </div>
      <div>
        {/* <Col xs={12} md={12}> */}
        {/* <CardHeader>
            <CardTitle>
              <CardBody>
                <i className={Labels.header.icon} />
                <b>{Labels.header.title}</b>
              </CardBody>
            </CardTitle>
          </CardHeader> */}
        {/* <CardBody> */}
        <CreateNewTicket GetTikets={GetTikets} PropsProject={props} labels={Labels} Form={Form} language={language} />
        {
          props.ProjectId !== 6 ?
            <Tab
              panes={[
                { menuItem: `${Labels.open_tickets.title} (${ticketsOpen.length})`, render: () => <OpenTickets tickets={ticketsOpen} language={language} idProject={props.ProjectId} styleProject={props.style} /> },
                { menuItem: `${Labels.closed_tickets.title} (${ticketsClosed.length})`, render: () => <ClosedTickets tickets={ticketsClosed} language={language} idProject={props.ProjectId} styleProject={props.style} /> },
              ]}
            />
            :
            <Tab
              menu={{
                secondary: true,
                className: "wrapped",
                pointing: true,
                style: { fontSize: "14px" },
              }}
              panes={[
                { menuItem: `${Labels.open_tickets.title} (${ticketsOpen.length})`, render: () => <OpenTickets tickets={ticketsOpen} language={language} idProject={props.ProjectId} styleProject={props.style} /> },
                { menuItem: `${Labels.closed_tickets.title} (${ticketsClosed.length})`, render: () => <ClosedTickets tickets={ticketsClosed} language={language} idProject={props.ProjectId} styleProject={props.style} /> },
              ]}
            />
        }
        {/* </CardBody> */}
        {/* </Col > */}
      </div>
      {/* </div> */}
    </ContentWrapper>
  );
}
export default HelpDesk;