import React, { Component, Suspense } from 'react';
import StepperEvent from '../../../../Components/StepperEvent';
// import dummy from '../../dummy'
// import Table from '../../../Components/Table';
import { Services } from '../../Services';
import { withRouter } from 'react-router-dom';
import {Card,CardBody,Col,Row} from 'reactstrap';
import axios from 'axios';
import dummy from '../../dummy'
import './styles.css';
const CardInfo = React.lazy(() => import('../../../../Components/CardsR'));


class Summary extends Component {
  state = {
    steps:[
      {
        label: 'Notificación de Orden de Salida',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Confirmación de Alistamiento',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Notificación de Salida de Carga',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Entrega a Transportista',
        value: '',
        id:4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      }
    ],
    FechaActualizacion:'',
    id: this.props.match.params.id,
    CardIndoHeaders: { title: 'Información del Almacén', subTitle: '', Icon: '', style: { color: '#25dfeae3' } },
    Info: [{ Referencia: 'CERSKJ8', Aduana: 'CNKJ876', Proyecto: 'CKJHSKDJH', Totales: 'No Aplica', Iniciales: '100cm', Anticipadas: '28-09-2019', Ordinarias: 'Transister', Temporales: '', TotalIVA: '$200,000', TotalAraceles: '$200,000', TotalTributos: '$400,000' }],
    config: [
      { title: 'No. de Despacho ', positionTitleLeft: true,  id: 'NoDespacho', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Referencia del Cliente',positionTitleLeft: true,  id: 'RefCliente', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Tipo de Documento',positionTitleLeft: true,  id: 'TipoDocumentos', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Doc. de Transporte', positionTitleLeft: true, id: 'DocTransporte', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Almacén', id: 'Almacen',positionTitleLeft: true,  className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Cantidades Solicitadas',positionTitleLeft: true,  id: 'CantidadSolicitadas', className: 'col-md-2 col-lg-2 col-sm-6' },
      { title: 'Cantidades Despachadas',positionTitleLeft: true,  id: 'CantidadDespachadas', className: 'col-md-2 col-lg-2 col-sm-6' },
      { title: 'Placas Vehículo',positionTitleLeft: true,  id: 'PlacaVehiculo', className: 'col-md-3 col-lg-2 col-sm-6' },
      { title: 'Destinatario',positionTitleLeft: true,  id: 'Destinatario', className: 'col-md-3 col-lg-1 col-sm-6' },
      { title: 'Observaciones Salida', id: 'Observaciones', positionTitleLeft: true,  className: 'col-md-2 col-lg-12 col-sm-6' },
    ],
  }
 
  componentDidMount = () => {
    this.getDataForId();
    this.setState({
      steps:this.props.steps,
      ReferenciaDeCliente: this.props.ReferenciaDeCliente,
      DocTransporte: this.props.DocTransporte,
      NoDespacho:this.props.NoDespacho,
      Estatus:this.props.Estatus,
      cliente:this.props.cliente,
      NIT:this.props.NIT,
      Grupo:this.props.Grupo,
      FechaActualizacion:this.props.FechaActualizacion,
    });
  }

  componentWillReceiveProps = (nextProps) => {
    this.getDataForId();
    this.setState({
      steps:nextProps.steps,
      ReferenciaDeCliente: nextProps.ReferenciaDeCliente,
      DocTransporte: nextProps.DocTransporte,
      NoDespacho:nextProps.NoDespacho,
      Estatus:nextProps.Estatus,
      cliente:nextProps.cliente,
      NIT:nextProps.NIT,
      Grupo:nextProps.Grupo,
      FechaActualizacion:nextProps.FechaActualizacion,
    });
  }
  
  getDataForId = () => {
    // axios.get(`${Services.Get_Storage_Despachos_ForId.path}/storage/dataStorageOffice/${this.state.id}`).then(success => {
      let success =dummy.detalleDespachos

    let Info = [];
      Info.push(success.data[0]);
      this.setState({
        Info,
        ReferenciaDeCliente: success.data[0].RefCliente ? success.data[0].RefCliente :'',
        DocTransporte: success.data[0].DocTransporte ? success.data[0].DocTransporte :'',
      });
    // }).catch(error => {
    //   console.warn(error); 
    // });
  }

  render() {
    let {cliente,NIT,Grupo,FechaActualizacion} = this.state;
    return (
      <>
      <Card className="myCard">
      <CardBody>
        <div className="row" style={{ marginLeft: 15 }}>
          <em style={{color:'#582C83'}} className="fa-3x mr-1 fas fa-sort-amount-up"></em>
           <Col md={12} xl={11}>
                  <Row>
                    <Col md={4} lg={6} sm={12}>
                    <strong style={{ color: '' }}>{cliente} ({NIT})  / {Grupo}</strong>
                    <br/>
              <strong style={{ color: '#0099ff' }}>{this.state.NoDespacho}</strong>
              <br />
              {this.state.ReferenciaDeCliente }
              <br />
              {this.state.DocTransporte}
                    </Col>   
                    <Col md={8} xl={6}>
                      <div style={{textAlign:'right'}} className='date_content'>
                      <strong style={{ fontSize:'13px'}}> <i>Última Actualización:</i>  {FechaActualizacion}</strong> 
                      </div>
                    </Col>
                  </Row>
                </Col>
        </div>
        <div className="col-12" /* style={{background: 'pink'}} */>
          <div className="row">
            <div className="col-4">
              {this.state.Estatus}
            </div>
          </div>
        </div>
        <div className="col-12">
          <StepperEvent steps={this.state.steps} />
          <br />
          {/* <br/> */}
        </div>

        </CardBody>
      </Card>
          <Suspense fallback={<div>Loading...</div>}> <CardInfo CardIndoHeaders={this.state.CardIndoHeaders} config={this.state.config} data={this.state.Info} cardInfon={true}></CardInfo></Suspense>              <br />
          {/* <Card className="myCard">
            <CardBody> */}
          {/* <div className="row">
                <div className="col-12">
                  <h4 >{title2}</h4>
                </div> */}
          {/* <div className="col-12">
                  <Table
                    columns={columns2}
                    data={data2}
                    options={{
                      large: false,
                      downloads: {
                        pdf: false,
                        xlsx: false
                      }
                    }} />
                </div> */}
          {/* </div>
            </CardBody>
          </Card> */}
      </>
    );
  }
}

export default withRouter(Summary);
