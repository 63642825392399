import React from 'react';
import ContentWrapper from '../../template/Layout/ContentWrapper';
// import restService from '../../../services/restService';
import { Tab } from 'semantic-ui-react';

import POMagement from './components/POManagement';
import OceanFreight from './components/OceanFreight';
import EUInland from './components/EUInland';
import EUGroundContainerTrans from './components/EUGroundContainerTrans';


/*=============================================
=            Tracking Component               =
=============================================*/

const Tracking = () => (
  <ContentWrapper>
    <div className="content-heading">
      <div className="row">
        <legend><em className="fas fa-globe fa-1x mr-2"></em>Rates</legend>
      </div>
    </div>
    <Tab 
      menu={{ secondary: true,className:'myWrapped'  }}
      menuPosition='right'
      panes={[
          
        {menuItem: 'Ocean Freight', render: () => <OceanFreight/>},
        {menuItem: 'EU Inland', render: () => <EUInland/>},
        {menuItem: 'EU Ground Container Trans.', render: () => <EUGroundContainerTrans/>},
        {menuItem: 'PO Management', render: () => <POMagement/>},
          
      ]}/>
  </ContentWrapper>
);

export default Tracking;


