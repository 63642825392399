import {
  FILTER_URL
} from './types';

export const SetFilterUrl = (data) => async dispatch => {
  try {
    dispatch({
      type: FILTER_URL,
      payload: data
    });
  }catch(error){
    console.error('The error =>', error);
  }
};