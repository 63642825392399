const formConfirmation = {
  purchaseOrder : [
    {
      type : 'viewDate',
      label : 'PO Creation Date',
      name : 'POCreateDate',
      id : 'POCreateDate'
    },
    {
      type : 'viewDate',
      label : 'Requested Ex Works Date',
      name : 'RequestedExWorksDate',
      id : 'RequestedExWorksDate'
    },
    {
      type : 'viewDate',
      label : 'Required at Destination',
      name : 'RequiredDestination',
      id : 'RequiredDestination'
    },
    {
      type : 'input',
      label : 'Confirmation Number',
      name : 'ConfirmationNumber',
      id : 'ConfirmationNumber'
    },
    {
      type : 'viewConf',
      label : 'Confirmation Date',
      name : 'ConfirmationDate',
      id : 'ConfirmationDate'
    },
    {
      type : 'date',
      label : 'Promised Cargo Date',
      name : 'PromiseCargoReadyDate',
      id : 'PromiseCargoReadyDate'
    },
    {
      type : 'view',
      label : 'Total Qty Ordered',
      name : 'TotalQtyOrdered',
      id : 'TotalQtyOrdered'
    },
    {
      type : 'viewConfirmed',
      label : 'Total Qty Confirmed',
      name : 'TotalQtyConfirmed',
      id : 'TotalQtyConfirmed'
    },        
  ],

  dataOrder: {
    orderDetail:{
      Address: '',
      AddressDetails: '',
      Contact: '',
      PONumber: '',
      SupplierName: ''
    },
    orderConfirmation: {
      ConfirmationDate: '',
      ConfirmationNumber: '',
      POCreateDate: '',
      PromiseCargoReadyDate:'',
      RequestedExWorksDate: '',
      RequiredDestination: '',
      TotalQtyConfirmed: '',
      TotalQtyOrdered:''
    },
    lineItems: [],
    supplierComment: ''
  }
};

export default formConfirmation;