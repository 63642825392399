export const ModelDetailTicket = () => {
  return {
    Model: {
      UserProject: { value: '' },
      RolUserProject: { value: 'null', type: 'text' },
      Email: { value: 'null' },
      Phone: { value: null, type: 'text' },
      TypeTicket: { value: 'null', type: 'text' },
      RequestModule: { value: 'null', type: 'text' },
      RequestTypeSubModule: { value: null },
      Severity: { value: null },
      ccto: { value: [] },
      CreateDate: { value: null, type: 'text', hidden: false, required: true, edited: false },
      UserFirstLevel: { value: null, type: 'text', hidden: false, required: true, edited: false },
      Description: { value: null, type: 'text-area', hidden: false, required: true, edited: false },
      Comments: { value: [] },
    },
  };
};