import { useCallback, useEffect, useState } from 'react';
import { entitiesIdResponse } from './mocks';
// import EntitiesRepository from '../../../../repositories/EntitiesRepository';

export const useEntitiesEP = (id, type) => {
  const [entitiesEP, setEntitiesEP] = useState([]);
  const [filterEntitiesEP, setFilterEntitiesEP] = useState([]);
  const [loading, setLoading] = useState(false);

  const getEntitiesEP = useCallback(async () => {
    setLoading(true);
    try {
      // const { data } = await EntitiesRepository.getEntitiesEP(id, type);
      const data = entitiesIdResponse;
      setEntitiesEP(data);
      setFilterEntitiesEP(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id, type]);

  const setEntityEPService = async(id, data) => {
    setLoading(true);
    try {
      // await EntitiesRepository.setEntityEP(id, data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      setLoading(false);
    }
  }

  const handleFilterEntitiesEP = (value) => {
    const normalizedValue = normalizeString(value);
    const filteredEntities = entitiesEP.filter((entity) => {
      const values = Object.values(entity).map((val) => normalizeString(val));
      const regex = new RegExp(normalizedValue, "gi");
      return values.some((value) => regex.test(value));
    });
    setFilterEntitiesEP(filteredEntities);
  }

  const normalizeString = (str = '') => {
    let value = str;
    if (!value) value = "";
    if (typeof value !== "string") {
      value = value.toString();
    }
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    getEntitiesEP();
  }, [id, type]);

  return { entitiesEP, loading, getEntitiesEP, handleFilterEntitiesEP, filterEntitiesEP, setEntityEPService };
}
