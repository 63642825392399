const adminForm = {
  CreateCustomer: [
    {
      type: 'Input',
      label: 'Customer Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'CustomerName',
      id: 'CustomerName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Telephone',
      name: 'Telephone',
      class: 'col-md-3',
      id: 'Telephone',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Tax ID',
      name: 'TaxId',
      class: 'col-md-3',
      id: 'TaxId',
      style: {},
      required: true
    },
    {
      type: 'Select',
      options: 'CountryId',
      label: 'Country',
      name: 'Country',
      class: 'col-md-3',
      id: 'Country',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Customer code',
      class: 'col-md-3',
      placeHolder: '',
      name: 'CustomerCode',
      id: 'CustomerCode',
      style: {},
    },
    {
      type: 'Input',
      label: 'Interglobo Id',
      class: 'col-md-3',
      placeHolder: '',
      name: 'IntergloboId',
      id: 'IntergloboId',
      required: false,
      style: {},
    },
    {
      type: 'check',
      label: 'Automatic Shipments',
      class: 'col-md-3',
      placeHolder: '',
      name: 'IntergloboId',
      id: 'AutomaticShipments',
      required: false,
      style: {},
    },
    // {
    //   type: 'multiCombo',
    //   label: 'multiCombo',
    //   class: 'col-md-6',
    //   placeHolder: 'My Dashboard',
    //   name: 'multiCombo',
    //   id: 'multiCombo',
    //   required: true,
    // }
  ],
  AddContact: [
    {
      type: 'Input',
      label: 'Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Last Name',
      name: 'Lastname',
      class: 'col-md-2',
      id: 'LastName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Position',
      name: 'Position',
      class: 'col-md-2',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Telephone',
      class: 'col-md-2',
      placeHolder: '',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },
  ],
  CreateUser: [
    {
      type: 'Input',
      label: 'Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Last Name',
      name: 'Lastname',
      class: 'col-md-3',
      id: 'LastName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Position',
      name: 'Position',
      class: 'col-md-3',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Password',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Password',
      id: 'Password',
      style: {},
      required: true
    },
    {
      type: 'Select',
      options: 'RoleId',
      label: 'Role',
      name: 'RoleId',
      class: 'col-md-3',
      id: 'RoleId',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Telephone',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
  ],
  editUser: [
    {
      type: 'Input',
      label: 'Name',
      class: 'col-md-3',
      placeHolder: '',
      name: 'name',
      id: 'Name',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Last Name',
      name: 'Lastname',
      class: 'col-md-3',
      id: 'LastName',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Position',
      name: 'Position',
      class: 'col-md-3',
      id: 'Position',
      style: {},
      required: false
    },
    {
      type: 'Input',
      label: 'Email',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Email',
      id: 'Email',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Password',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Password',
      id: 'Password',
      style: {},
      required: false
    },
    {
      type: 'Select',
      options: 'RoleId',
      label: 'Role',
      name: 'RoleId',
      class: 'col-md-3',
      id: 'RoleId',
      style: {},
      required: true
    },
    {
      type: 'Input',
      label: 'Telephone',
      class: 'col-md-3',
      placeHolder: '',
      name: 'Telephone',
      id: 'Telephone',
      style: {},
      required: false
    },
  ],
}
export default adminForm