import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Grid } from "@mui/material";

export const ListItemParcedModal = ({ open, toggle, update, item }) => {
  const getQtyPending = () => {
    let pending = item.QTYOrdered - item.QTYConfirmed;
    if (pending < 0) return 0;
    return pending;
  };

  return (
    <Modal
      backdrop="static"
      isOpen={open}
      toggle={toggle}
      size="lg"
      style={{ maxWidth: "1000px", width: "100%" }}
      className="listItemModal"
    >
      <ModalHeader
        className="advancedSearchModal"
        style={{ background: 'linear-gradient(to right, #007ab9 0%, #00558c 100%)', color: "white" }}
      />
      <ModalBody>
        <Grid
          container
          spacing={2}
          style={{ margin: "1rem", marginTop: ".5rem", marginBottom: "4rem" }}
          rowGap={2}
        >
          <Grid xs={12} container>
            <label className="label-euro bold fs14">
              Item partialization
            </label>
          </Grid>
          <Grid container alignItems="center" gap="2rem">
            <label className="label-euro bold">
              Line No.: <span className="label-euro normal">{item.LineNo}</span>
            </label>
            <label className="label-euro bold">
              Item No.: <span className="label-euro normal">{item.ItemNo}</span>
            </label>
            <label className="label-euro bold">
              Description: <span className="label-euro normal">{item.Description}</span>
            </label>
          </Grid>
          <Grid container alignItems="center" gap="2rem">
            <Grid>
              <label className="label-euro bold">
                QTY ordered
              </label>
              <label className="label-euro">{item.QTYOrdered || 0}</label>
            </Grid>
            <Grid>
              <label className="label-euro bold">
                QTY confirmed
              </label>
              <label className="label-euro">{item.QTYConfirmed || 0}</label>
            </Grid>
            <Grid>
              <label className="label-euro bold">
                QTY pending
              </label>
              <label className="label-euro">{getQtyPending() || 0}</label>
            </Grid>
          </Grid>
        </Grid>
      </ModalBody>
      <ModalFooter className="modalFooterCenter">
        <center>
          <Button
            style={{
              marginRight: "20px",
              color: "#2b3b57",
              borderRadius: 20,
            }}
            color="primary"
            size="small"
            variant="contained"
            className="buttomSeaCargoCancel bgCancel"
            onClick={toggle}
          >
            Cancel
          </Button>
          <Button
            // color="primary"
            size="small"
            style={{
              color: "#2b3b57",
              borderRadius: 20,
            }}
            className="buttomSeaCargo bgAccept"
            variant="contained"
            color="secondary"
            onClick={update}
          >
            Accept
          </Button>{" "}
        </center>
      </ModalFooter>
    </Modal>
  );
};
