
/*eslint-disable*/
import React, { Suspense, useEffect, lazy, useState } from "react";
import { withRouter } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
// import { Card, CardBody, Row, Col, CardHeader, CardFooter, Label, CardTitle } from "reactstrap";
// import NextToArrive from './NextToArrive/Index'
// import Arrived from './Arrived/Index'
import ContentWrapper from "../../template/Layout/ContentWrapper";
// import Axios from 'axios';
import ContainerImg from "../../../resources/ContainerIcon.png";
// import ClientesList from '../../../services/storageClient.js';
// import CreateProvider from './store/CreateProvider';

const NextToArrive = lazy(() => (import('./NextToArrive/Index')))
const Arrived = lazy(() => (import('./Arrived/Index')))

function Container(props) {
  // console.log('props--->', props);
  let [BanderaArrive, setBanderaArrived] = useState(false)
  let [BanderaNexto, setBanderaNexto] = useState(false)

  const [idContainerArrived, setIdContainerArrived] = useState(1)
  const [idContainerNexto, setIdContainerNexto] = useState(1)


  function getIdContainerArrived(id) {
    // console.log('entre aqui, este es el id-->', id);
    if (id) {
      setIdContainerArrived(id)
    }
    setBanderaArrived(!BanderaArrive)
  }

  function getIdContainerNextoArrived(id) {
    // console.log('entre aqui, este es el id-->', id);
    if (id) {
      setIdContainerNexto(id)
    }
    setBanderaNexto(!BanderaNexto)
  }


  return (
    < ContentWrapper >
      {/* <CreateProvider> */}
      <div className="content-heading"
      // style={{ display: Bandera ? 'none' : '' }}
      >
        <i className='icon--dashboard_container' />
        {/* <img
          src={ContainerImg}
          style={{ width: "1.5em", paddingRight: 5 }}
          alt="..."
        ></img> */}
        {
          // BanderaArrive || BanderaNexto ? <b>Container Detail</b> : <b>Container Management</b>
          <b>Container Management</b>
        }

      </div>
      <div
      // style={{ pointerEvents: BanderaArrive || BanderaNexto ? 'none' : '' }}
      >
        <Tab
          // activeIndex={1}
          defaultActiveIndex={0}
          menu={{ secondary: true }}
          panes={[
            {
              menuItem: 'Next to Arrive',
              render: () => <Suspense fallback={<div>Loading...</div>}><NextToArrive Bandera={BanderaNexto} idContainer={idContainerNexto} getIdContainer={getIdContainerNextoArrived} /></Suspense>
            },
            {
              menuItem: 'Arrived',
              render: () => <Suspense fallback={<div>Loading...</div>}><Arrived Bandera={BanderaArrive} idContainer={idContainerArrived} getIdContainer={getIdContainerArrived} _user={props._user} /></Suspense>,
            },
          ]}
        />
      </div>
      {/* </CreateProvider> */}
    </ContentWrapper >
  );
}

export default withRouter(Container);

