const Forms = {
    MAIN:[
        {
            type: 'Select',
            options: 'Warehouse',
            label: 'Warehouse',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'Warehouse',
            id: 'Warehouse',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'Vessel_Des',
            label: 'Vessel Description',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'Vessel_Des',
            id: 'Vessel_Des',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'PO_Single',
            label: 'PO Single',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'PO_Single',
            id: 'PO_Single',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'Supplier',
            label: 'Supplier',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'Supplier',
            id: 'Supplier',
            datatype: 'select',
            show: true,
            style : { fontSize : '11px',fontWeight:'bold'},

        },
        {
            type: 'Select',
            options: 'Shipment_Id',
            label: 'Shipment Id',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'Shipment_Id',
            id: 'Shipment_Id',
            datatype: 'select',
            style : { fontSize : '11px',fontWeight:'bold'},

            show: true
        },
        {
            type: 'Select',
            options: 'Trucker',
            label: 'Trucker',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'Trucker',
            id: 'Trucker',
            datatype: 'select',
            style : { fontSize : '11px',fontWeight:'bold'},

            show: true
        },
        {
            type: 'Select',
            options: 'Tracking',
            label: 'Tracking',
            class: 'col-md-3 col-lg-3 col-sm-12',
            name: 'Tracking',
            id: 'Tracking',
            datatype: 'select',
            style : { fontSize : '11px',fontWeight:'bold'},

            show: true
        },


       
    ],
    DATES:[
        {
            type: 'Datetime',
            label: 'Actual Cargo Ready Date',
            name: 'ActualCargoReadyDate',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'ActualCargoReadyDate',
            style : { fontSize : '11px',fontWeight:'bold'},
            required : true
        },
        {
            type: 'Datetime',
            label: 'Estimated Loading Date',
            name: 'EstimatedLoadingDate',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'EstimatedLoadingDate',
            style : { fontSize : '11px',fontWeight:'bold'},
            required : true
        },
        {
            type: 'Datetime',
            label: 'Actual Arrival Date Warehouse',
            name: 'ActualArrivalDateWarehouse',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'ActualArrivalDateWarehouse',
            style : { fontSize : '11px',fontWeight:'bold'},
            required : true
        },
        {
            type: 'Datetime',
            label: 'Actual Departure Date Warehouse',
            name: 'ActualDepartureDateWarehouse',
            class: 'col-md-2 col-lg-2 col-sm-12',
            id: 'ActualDepartureDateWarehouse',
            style : { fontSize : '11px',fontWeight:'bold'},
            required : true
        },
    ]
    
   
}

export default Forms