import React, { Component } from 'react';
import 'react-select/dist/react-select.css';
import 'react-datetime/css/react-datetime.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Button } from 'reactstrap';
// import Order from '../../../../models/Order'
import Required from '../../../../models/RequiresPOcreate'
import restService from '../../../../services/restService';
import Errors from '../../../template/Errors'
import Config from '../Data/Config'
import ConsigneeDetails from './components/consignee-details'
import HeaderDetails from './components/header-details'
import ViewOrder from './components/po-lines/viewOrder'
import Documents from './components/po-documents'
import { withRouter } from 'react-router-dom';

import swal from 'sweetalert';

const rest = new restService();
const map = Config.map

const TransportMode = Config.TransportMode
const Consignee = Config.Consignee
class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: {},
            selected: [],
            Required: Required,
            errors: "",
            items: [],
            color: "",
            Documemts: [],
            loader: '0',
            error: {
                open: false,
                message: '',
                type: 'error'
            }
        };
    }
    componentDidMount = () => {
        let { PONumber } = this.props.match.params
        rest.EXEC({ _function: 'GetOneOrder', params: PONumber + '?type=detail' }, fetch).then(success => {
            if (success.data){
                this.setState({ selected : [success.data] })
            } 
        }).catch(error => {
            
        })
    }

    recieveDetailsState = (newOrderState) => {
        let { order } = this.state
        Object.keys(newOrderState).forEach((e) => {
            order[e] = newOrderState[e]
        })
        this.setState({ order })
    }

    recieveProps = (object, open) => this.setState({ [object.stateField]: object.data, error: { open: open, type: this.state.error.type } }, () => this.setFieldToOrder(object['stateField'], object['id']))

    setFieldToOrder = (key, value) => {
        let { order, Required } = this.state
        order[map[key] ? map[key] : key] = value
        Required[map[key] ? map[key] : key] = value
        this.setState({ order }, () => this.setState({ Required }))
    }


    recieveDocuments = (Documents) => this.setState({ Documents })

    sendMail = () => { 
        let {PONumber} = this.props.match.params
        let order = this.state.selected[0]
        let _order = [{
            PONumber: order.PONumber,
            Consignee: Consignee[order.ConsigneeCode] ? Consignee[order.ConsigneeCode] : order.ConsigneeCode,
            CreationDate: order.POCreateDate,
            ExWorkDate: order.RequestedExWorksDate,
            RequiredAtDestination: order.RequiredDestination,
            TransportMode: TransportMode[order.TransportModeId],
            IncoTerm: order.Incoterm
        }]

        rest.EXEC({
            _function: 'confirmationPO', params: '', data: {
                PONumber: this.props.match.params.PONumber,
                to: [
                    {
                        "Email": "carlos.salazar@magic-log.com",
                        "Name": "Carlos Salazar",
                        "url": 'https://magic-dome.firebaseapp.com/poconfirmation/' + PONumber
                    },
                    {
                        "Email": "jakopo.trejo@magic-log.com",
                        "Name": "Jako",
                        "url": 'https://magic-dome.firebaseapp.com/poconfirmation/' + PONumber
                    },
                    {
                        "Email": "shunashi.cruz@magic-log.com",
                        "Name": "Shunashi Cruz",
                        "url": 'https://magic-dome.firebaseapp.com/poconfirmation/' + PONumber
                    },
                    {
                        "Email": "emmanuel.trejo@magic-log.com",
                        "Name": "estebi",
                        "url": 'https://magic-dome.firebaseapp.com/poconfirmation/' + PONumber
                    },
                    {
                        "Email": "beker863686@gmail.com",
                        "Name": "carlos rene mendoza",
                        "url": 'https://magic-dome.firebaseapp.com/poconfirmation/' + PONumber
                    },
                    
                ],
                dataOrder: _order
            }
        }, fetch).then(success => {
           
            
            swal("Good job!", "Your purchase order has been successfully created", "success")
                .then((value) => {
                    this.props.history.push('/order-list');
                });
        }).catch(error => {
            
        })

    }

    saveOrderActive = () => { 
        rest.EXEC({
            _function : 'PutOrder', params : this.props.match.params.PONumber , data : {}
        },fetch).then(success =>{
            this.sendMail()
        }).catch(error=>{
            
        })
    }


    render() {
        let { PONumber } = this.props.match.params
        const {selected} = this.state
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="row">
                        <em className="fas fa-dolly-flatbed fa-1x mr-2"></em>
                        PO Draft
                        </div>
                </div>
                <ConsigneeDetails selected = {selected} sendProps={this.recieveProps} ></ConsigneeDetails>
                <HeaderDetails selected = {selected} sendDetailsState = {this.recieveDetailsState} ></HeaderDetails>
                <ViewOrder selected = {selected} PONumber = {PONumber}></ViewOrder>
                <Documents selected = {selected} PONumber = {PONumber}></Documents>
                   
                <div className="row">
                    <div className="col-md-6 col-lg-8 col-sm-12"></div>
                    <div className="col-md-3 col-lg-2 col-sm-6">
                    </div>
                    <div className="col-md-3 col-lg-1 col-sm-6">
                        <Button variant="contained" color="primary" className="button" onClick={this.saveOrderActive} size="lg">
                            Save and complete
                        </Button>
                    </div>
                </div>
                <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
            </ContentWrapper>
        );
    }
}
export default withRouter(FormExtended);