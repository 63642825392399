import React from 'react';
import { Card, Button } from 'semantic-ui-react';

const defaultStyles = {
  fontSize: '11px',
};

function NoResultsFound(props) {

  const { botSteps, nextSteps } = props;
  const { mainMenu, rateService } = nextSteps;
  var { newSearch } = nextSteps;
  const { previousStep, triggerNextStep } = botSteps;
  if (previousStep.id === 'shipmentSearch' || previousStep.id === 'purchaseOrderSearch') {
    newSearch = 'menuPOShip';
  } else {
    newSearch = 'menuDocument'
  }
  const nextState = (value) => {
    triggerNextStep({ value: ['TEST1', 'TEST2'], trigger: value });
  };

  return (
    <>
      <Card fluid>
        <Card.Content>
          <Card.Description>
            No results have been found for:{' '}
            <strong>{previousStep.message}</strong>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className="row" style={{ width: '100%' }}>
            <div className="col-md-6">
              <Button
                fluid
                compact
                style={defaultStyles}
                onClick={() => nextState(newSearch)}
              >
                New search
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                fluid
                compact
                style={defaultStyles}
                onClick={() => nextState(mainMenu)}
              >
                Menu
              </Button>
            </div>
          </div>

          <div className="row" style={{ height: '10px' }} />

          <div className="row" style={{ width: '100%' }}>
            <div className="col-md-3" />
            <div className="col-md-6">
              <Button
                fluid
                compact
                style={defaultStyles}
                onClick={() => nextState(rateService)}
              >
                Exit
              </Button>
            </div>
            <div className="col-md-3" />
          </div>
        </Card.Content>
      </Card>
    </>
  );
}

export default NoResultsFound;
