import React, { useCallback, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import CardTotals from "../Dashboard/components/CardTotals";
import { IconMessages, CloseIcon } from "../../../resources/img_svg";
import { saveAs } from "file-saver";
// import YearsSelect from "../../Components/CatYears";
// import { TotalsCircle } from "../../Components/Loader/";
import Datetime from "react-datetime";
import Loader from "./../../Global/Loader/";

import { Popup, Segment, Sidebar, Dropdown, Search, Icon } from "semantic-ui-react";
import ContentWrapper from "../../template/Layout/ContentWrapper";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Collapse, Button } from "reactstrap";
import TableComponent from "../../Components/Table_2";
import { Services } from "./Services";
import Chart from "react-google-charts";
import Axios from "axios";
import moment from "moment";
import FormFiltros from "./model/form";
import { DatePicker, Button as ButtonAnt, Skeleton, Switch } from "antd";
import Comments from "../../poseidon/OrderVendor/order-list/components/Comments/";
import { MyCommandCell } from "../../Components/Table_2/myCommandCell";
import Swal from "sweetalert2";
import { poByStatus, posByCreationDate, listPO, catalogs } from "./mocks";

const { RangePicker } = DatePicker;
const IMG =
  "https://magiclog.blob.core.windows.net/poseidonpic/search_advanced.png";
const IMG_ATTENTION =
  "https://magiclog.blob.core.windows.net/poseidonpic/attentionOrder.png";
const CARD_TOTALS_SUMMARY = [
  {
    Tipo: 1,
    classNameImg: "fas icon-basket-loaded fa-2x",
    total: 20,
    title: "Total PO´s",
    propertiesIcon: "col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left",
    propertiesTitle: "col-8 py-3 bg-primary-dark  rounded-right endPosition",
  },
  {
    Tipo: 2,
    classNameImg: "fas fa-clock fa-2x",
    total: 140,
    title: "Pending to confirm on time",
    propertiesIcon: "col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left",
    propertiesTitle: "col-8 py-3 bg-purple-dark rounded-right endPosition",
  },
  {
    Tipo: 3,
    classNameImg: "fas fa-check-circle fa-2x",
    total: 45,
    title: "Pending to confirm late",
    propertiesIcon: "col-4 d-flex align-items-center bg-green-dark justify-content-center rounded-left",
    propertiesTitle: "col-8 py-3 bg-green-dark  rounded-right endPosition",
  },
  {
    Tipo: 50,
    classNameImg: "fas fa-exclamation-triangle fa-1x",
    total: 0,
    title: "Urgent PO´s",
    propertiesIcon: "col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left",
    propertiesTitle: "col-8 py-3  bg-primary-dark rounded-right endPosition",
  },
];

const mapColor = {
  12: "danger",
  13: "secondary",
};

const HEADERS = [
  "PO No.",
  "Status",
  "Supplier",
  "Consignee",
  "Creation Date",
  "Requested Delivery Date",
  "Comments Last Call",
  "Actual Cargo Ready",
  "Total QTY Ordered",
];
const KEYS = [
  "PONumber",
  "StatusDesc",
  "SupplierName",
  "ConsigneeName",
  "POCreateDate",
  "RequiredDestination",
  "CommentsLastCall",
  "ActualCargoReadyDate",
  "TotalQtyOrdered",
];

const CHART_FOR_CREATION_DATE = [
  ["x", "Po"],
  ["Jan", 0],
  ["Feb", 0],
  ["Mar", 0],
  ["Apr", 0],
  ["May", 0],
  ["Jun", 0],
  ["Jul", 0],
  ["Aug", 0],
  ["Sep", 0],
  ["Oct", 0],
  ["Nov", 0],
  ["Dec", 0],
];

const options = {
  // title: "Density of Precious Metals, in g/cm^3",

  bar: { groupWidth: "80%" },
  chartArea: {
    height: "70%",
    // width: "80%",
    width: "100%",
    left: "18%",
  },
  legend: { position: "none" },
};


const options2 = {
  chartArea: {
    height: "70%",
    width: "100%",
    left: "12%",
  },
  curveType: "function",
  legend: {
    position: "none",
  },
  vAxis: {
    viewWindow: { min: 0 },
    textStyle: { color: "#535353", fontSize: 11 }
  },
  hAxis: { textStyle: { color: "#535353", fontSize: 11 } },
  pointColor: "red",
  pointSize: 5,
  series: {
    0: {
      color: "#0060a8",
      lineWidth: 2,
    },
  }
}

const catYear = [{ key: 2024, text: '2024', value: '2024' }, { key: 2023, text: '2023', value: '2023' }, { key: 2022, text: '2022', value: '2022' }, { key: 2021, text: '2021', value: '2021' }]

const editField = "inEdit";
const editColums = [{ key: "ActualCargoReadyDate" }];

function VendorManagement(params) {
  const [dataPOs, setDataPOs] = useState([]);
  const [dataPOsFilters, setDataPOsFilters] = useState([]);
  const [visible, setVisible] = useState(false);
  const [fullLoader, setFullLoader] = useState(false);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loaderChartComponents, setLoaderChartComponents] = useState(true);
  const [requiresAttention, setRequiresAttention] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [excelInvoiceLoading, setExcelInvoiceLoading] = useState(false);
  const [numberPage, setNumberPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [viewWindowTotal, setViewWindowTotal] = useState(0);
  const [_customerSelect] = useState(params._customerSelect);
  const [_user] = useState(params._user);
  const [_rolId] = useState(params._rolId);
  const [yearSelected, setYearSelected] = useState(
    moment(new Date()).format("YYYY")
  );
  const [isSearchComments, setIsSearchComments] = useState(true);
  const [POId, setPOId] = useState(0);
  const [PONumber, setPONumber] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchVendor, setSearchVendor] = useState("");
  const [headers, setHeaders] = useState([
    {
      key: "",
      label: "",
      minWidth: "1em",
      class: "headerTextShipList",
      custom: (value) => (
        <>
          <div>
            <Popup
              size="mini"
              inverted
              content="Show messages"
              trigger={
                <div style={{ cursor: "pointer" }}>
                  <i onClick={() => selectedOrder(value)} className="mr-1 ml-1">
                    <IconMessages />
                  </i>
                </div>
              }
            />
          </div>
        </>
      ),
    },
    {
      key: "",
      label: "",
      minWidth: "1em",
      class: "headerTextShipList",
      custom: (value) => (
        <>
          {value.RequiresAttention === "1" ? (
            <div>
              <Popup
                className="_comments_icon"
                trigger={
                  <div>
                    <img
                      height="22px"
                      width="22px"
                      src={IMG_ATTENTION}
                      alt="img"
                    />
                  </div>
                }
                content="Orders that require attention"
                position="top center"
                size="mini"
                inverted
              ></Popup>
            </div>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      minWidth: "9em",
      key: "PONumber",
      label: "PO No.",
      custom: (value) => (
        <span className="pono-column">
          <Link to={"/vendor-detail/" + value.POId}>{value.PONumber}</Link>
        </span>
      ),
    },
    {
      minWidth: "11em",
      key: "StatusDesc",
      label: "Status",
      custom: (value) => (
        <Button
          className={
            value.StatusId === 13 ? "btn-oval _gray_button" : "btn-oval"
          }
          color={
            mapColor[value.StatusId] ? mapColor[value.StatusId] : "primary"
          }
          onClick={() => { }}
        >
          {value.StatusDesc}
        </Button>
        // <span className="status-column">{value.StatusDesc}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "SupplierName",
      label: "Supplier",
      custom: (value) => (
        <span className="columsTable">{value.SupplierName}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "ConsigneeName",
      label: "Consignee",
      custom: (value) => (
        <span className="columsTable">{value.ConsigneeName}</span>
      ),
    },
    {
      key: "POCreateDate",
      label: "Creation Date",
      minWidth: "11em",
      custom: (value) => (
        <span className="columsTable">{value.POCreateDate}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "RequiredDestination",
      label: "Requested Delivery Date",
      minWidth: "11em",
      custom: (value) => (
        <span className="columsTable">{value.RequiredDestination}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "",
      label: "Comments Last Call",
      custom: (value) => (
        <span className="columsTable">{value.CommentsLastCall}</span>
      ),
    },
    {
      minWidth: "11em",
      key: "ActualCargoReadyDate",
      label: "Actual Cargo Ready",
      custom: (value) =>
        value.inEdit ? (
          <DatePicker
            defaultValue={
              value.ActualCargoReadyDate
                ? moment(value.ActualCargoReadyDate, "YYYY/MM/DD")
                : ""
            }
            format={"YYYY-MM-DD"}
            style={{ width: "100%" }}
            onChange={(date, dateString) => {
              handleChangeTable(value, dateString);
            }}
          />
        ) : (
          <>
            <span className="columsTable">{value.ActualCargoReadyDate}</span>
          </>
        ),
    },
    {
      minWidth: "11em",
      key: "TotalQtyOrdered",
      label: "Total QTY Ordered",
      custom: (value) => (
        <span className="columsTable">{value.TotalQtyOrdered}</span>
      ),
    },
    {
      minWidth: "15em",
      key: "",
      label: "",
      custom: (value) => (
        <MyCommandCell
          dataItem={value}
          editField={editField}
          edit={Edit}
          cancel={Cancel}
          update={updateRow}
        />
      ),
    },
  ]);

  const [cardsSummary, setCardsSummary] = useState(CARD_TOTALS_SUMMARY);
  const [catalogCombo, setCatalogCombo] = useState([]);
  const [filtros, setFiltros] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState([]);
  const [advancedObj, setAdvancedObj] = useState([]);
  const [statusChart, setStatusChart] = useState([]);

  const [creationDateChart, setcreationDateChart] = useState([]);
  const [comments, setComments] = useState([]);
  const [value, setValue] = useState('');
  const [date, setDate] = useState(moment(new Date()).format("YYYY"));
  const [key, setKey] = useState(1);
  const [data2, setData2] = useState([...posByCreationDate()]);


  const Edit = useCallback(async (poSelected) => {
    let page = poSelected.Page;
    setNumberPage(page);
    try {
      setDataPOs(
        listPO.map((item) =>
          item.POId === poSelected.POId
            ? {
              ...item,
              inEdit: true,
            }
            : item
        )
      );
    } catch (error) {
      setFullLoader(true);
      console.log("error", error);
    }
  }, [numberPage, requiresAttention]);

  const Cancel = useCallback(async (poSelected) => {
    let page = poSelected.Page;
    setNumberPage(page);

    setDataPOs(listPO);
  }, []);

  function isKeyExists(obj, key) {
    return key in obj;
  }

  const handleChangeTable = useCallback(async (po, value) => {
    let page = po.Page;
    setNumberPage(page);
    try {
      let newItems = [];
      listPO.map((e) => {
        if (e.POId === po.POId) {
          e["inEdit"] = true;
          editColums.filter((item) => {
            if (isKeyExists(e, item.key)) {
              e[item.key] = value;
            }
          });
          newItems.push(e);
        } else {
          newItems.push(e);
        }
      });
      setDataPOs(newItems);
    } catch (error) {
      setFullLoader(true);
      console.log("error", error);
    }
  },
    [dataPOs]
  );

  const updateRow = useCallback(async (item) => {
    let page = item.Page;
    setNumberPage(page);
    try {
      Swal.fire({
        // position: 'top-end',
        type: "success",
        title: "Order Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      setDataPOs(listPO.map((item) => {
        item.inEdit = false
        return item
      }));

    } catch (error) {
      console.log("🚀 ~ file: index.js:472 ~ updateRow ~ error:", error);
    }
  }, []);


  const selectedOrder = (order) => {
    setVisible(!visible);
    setIsSearchComments(true);
    setComments(order.Comments)
    setPONumber(order.PONumber);
  };

  const handleChangeSelectMultiple = (id, value) => {
    for (let i = 0; i < advancedSearch.length; i++) {
      const e = advancedSearch[i];
      if (e.id === id) {
        e.values = value;
      }
    }
    setAdvancedSearch(advancedSearch);
    generateFilters();
    findAdvanced();
  };

  const resetAdvance = () => {
    advancedSearch.map((advData) => {
      if (advData.type == "Datetime") {
        advData.values = [];
        advData.value = "";
      } else {
        advData.values = [];
        advData.value = "";
      }
    });

    setAdvancedSearch(advancedSearch);
    generateFilters();
    findAdvanced();
  };

  const findAdvanced = () => {
    setDataPOs(dataPOsFilters)
    let obj = [];
    advancedSearch.map((e) => {
      if (e.values && e.values.length > 0) {
        let newPush = {
          id: e.id2,
          UseIN: true,
          isDate: e.type === "Datetime" ? true : false,
          value: e.values,
        };
        obj.push(newPush);
      } else {
        obj.push({
          id: e.id,
          UseIN: false,
          value: e.value,
        });
      }
    });
    let dataFiltrada = []
    console.log('filter-->', obj);
    obj.map(e => {
      if (e.UseIN) {
        dataFiltrada = dataPOs.filter((item, key) => item[e.id] === parseInt(e.value))
      }
    })
    // setDataPOs(dataFiltrada)
    // setAdvancedObj(obj);
    // setTotalPages(1);
    // setNumberPage(1);
  };

  const get_opportunities = useCallback(async () => {
    try {
      setTimeout(() => {
        setDataPOs(listPO)
        setDataPOsFilters(listPO)
        setCatalogCombo(catalogs);

        setLoadingCard(false);
        setFullLoader(true);

      }, 3000)
    } catch (error) {
      setFullLoader(true);
      console.log("error", error);
    }
  });


  const handleChangeDatePicKer = (name, event) => {
    try {
      if (event) {
        advancedSearch.map((search) => {
          if (search.id == name) {
            search.values = [
              {
                init: event[0].format("YYYY-MM-DD HH:mm:ss"),
                end: event[1].format("YYYY-MM-DD HH:mm:ss"),
              },
            ];
          }
        });
        setAdvancedSearch(advancedSearch);
        generateFilters();
        findAdvanced();
      } else {
        advancedSearch.map((search) => {
          if (search.id == name) {
            search.values = [];
          }
        });
        setAdvancedSearch(advancedSearch);
        generateFilters();
        findAdvanced();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const generateFilters = (newCat) => {
    let Inputs = advancedSearch.map((e, i) => {
      if (e.type === "Datetime")
        return (
          <div
            key={e.id}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.label} {e.undefined ? "!" : ""}
            </label>
            <RangePicker
              className="col-md-12"
              placeholder={["Start date", "End date"]}
              value={
                Array.isArray(e.values)
                  ? e.values.length > 0
                    ? [
                      moment(new Date(e.values[0].init)),
                      moment(new Date(e.values[0].end)),
                    ]
                    : []
                  : []
              }
              id={e.id}
              onChange={(evt) => handleChangeDatePicKer(e.id, evt)}
              size="middle"
              format={e.format}
            />
          </div>
        );
      else if (e.type === "multipleSelect")
        return (
          <div
            key={e.id}
            className={e.class}
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <label className="labelTitleAvancSearc">
              {e.label} {e.undefined ? "!" : ""}
            </label>
            <Dropdown
              className="NewClass"
              onChange={(evt, { value }) =>
                handleChangeSelectMultiple(e.id, value)
              }
              id={e.id}
              options={catalogCombo[e.options]}
              clearable
              search
              selection
              fluid
              value={e.values}
              multiple
              allowAdditions
            />
          </div>
        );
    });
    let NewInput = (
      <div className="col-12 row">
        <div className="col-12 divContenEnd ">
          <em
            class="fa-2x mr-2 fas fa-eraser"
            style={{
              color: "#2B3B57",
              marginTop: "7px",
              cursor: "pointer",
              textAlign: "end",
            }}
            title={"Remove filters"}
            onClick={() => resetAdvance()}
          />
        </div>

        {Inputs}
      </div>
    );
    setFiltros(NewInput);
  };

  const generateInvoiceExcel = async () => {
    setExcelInvoiceLoading(true);

    try {
      let data = listPO;
      let dateNow = new Date();

      let titleDownload = `PO list-${dateNow.getFullYear() +
        (dateNow.getMonth() + 1).toString().padStart(2, "0") +
        dateNow
          .getDate()
          .toString()
          .padStart(2, "0")}`;

      let info = {
        config: {},
      };

      let options = {
        headers: HEADERS,
        keys: KEYS,
        image: {
          name: "magicdome",
        },
        sheetName: "PO´s",
      };

      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        e.ActualCargoReadyDate = e.ActualCargoReadyDate
          ? e.ActualCargoReadyDate.replace(/-/g, "/")
          : "";
        e.POCreateDate = e.POCreateDate
          ? e.POCreateDate.replace(/-/g, "/")
          : "";
        e.CommentsLastCall = e.CommentsLastCall
          ? e.CommentsLastCall.replace(/-/g, "/")
          : "";
        e.RequiredDestination = e.RequiredDestination
          ? e.RequiredDestination.replace(/-/g, "/")
          : "";
      }

      let infoXmls = {
        info,
        data,
        options,
      };

      let XMLS_DOWNDLOAD = await Axios.post(
        `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`,
        infoXmls
      );

      if (XMLS_DOWNDLOAD.data.base64) {
        const fileName = titleDownload + ".xlsx";
        const linkSource = `data:application/xlsx;base64,${XMLS_DOWNDLOAD.data.base64}`;
        saveAs(linkSource, fileName);
        setExcelInvoiceLoading(false);
      } else console.warn("Error downloading file", XMLS_DOWNDLOAD);
    } catch (error) {
      setExcelInvoiceLoading(false);
      console.log("error", error);
    }
  };

  const resetTable = () => {
    console.log("resetTable");
  };

  function pushComment(comment) {
    console.log("🚀 ~ pushComment ~ comment:", comment)

    comments.push(comment)
    setValue('')
  }
  function OnChangeComment(val) {
    setValue(val);
  }

  function onChangeDate(evt, data) {
    setDate(data.value);
    setData2([...posByCreationDate()])
  }

  function buildFilters() {
    let newFormFiltros = [...FormFiltros];

    for (let i = 0; i < newFormFiltros.length; i++) {
      newFormFiltros[i].values = [];
    }
    setAdvancedSearch(newFormFiltros);
    generateFilters();
  }

  useEffect(() => {
    get_opportunities();
    buildFilters()
  }, [advancedObj, catalogCombo, yearSelected,]);


  return (
    <>
      <Loader hidden={fullLoader} />
      <ContentWrapper>
        <div className="content-heading">
          <i className="icon--po_suppliericon"></i>
          Vendor Management
        </div>
        <Row>
          {cardsSummary.map((item) => {
            return (
              <CardTotals
                image={<i style={{ color: "#ffff", fontSize: "50px", marginLeft: "-5px" }} className={item.classNameImg}></i>}
                xl={3}
                md={6}
                sm={6}
                loading={loadingCard}
                total={item.total}
                title={item.title}
                propertiesIcon={item.propertiesIcon}
                propertiesTitle={item.propertiesTitle}
              />
            );
          })}

          <Col xs={12} md={6} className="ml-auto mr-auto">
            <Card className="myCard card-chart minHeightCharts">
              <CardHeader>
                <CardTitle tag="h6">PO´s by Status</CardTitle>
              </CardHeader>
              <CardBody>
                {loadingCard ?
                  <Skeleton /> :
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="270px"
                    data={poByStatus}
                    options={options}
                  />
                }
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6} className="ml-auto mr-auto">
            <Card className="myCard card-chart minHeightCharts" key={key}>
              <CardHeader>
                <CardTitle tag="h6">PO´s by Creation Date
                  <Dropdown
                    className="catalog-year-container"
                    text={date}
                    scrolling
                    icon={'angle down'}
                    placeholder="Year"
                    onChange={onChangeDate}
                    defaultValue={date}
                    options={catYear}
                  />
                </CardTitle>
              </CardHeader>
              <CardBody>
                {loadingCard ?
                  <Skeleton /> :
                  <Chart
                    chartType="ScatterChart"
                    data={data2}
                    width="100%"
                    height="270px"
                    loader={<div>Loading Chart</div>}
                    options={options2}
                    rootProps={{ "data-testid": "1" }}
                  />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className={"myCard"}>
          {loadingCard ?
            <Skeleton /> :
            <>
              <div className="col-12" style={{ paddingTop: "1%" }}></div>
              <div class="row col-xs-6 col-md-12">
                <div style={{ marginTop: "1em" }} class="col-xs-6 col-md-3">
                  {/* {options.search ? ( */}
                  <Search
                    input={{
                      icon: "search",
                      iconPosition: "left",
                      fluid: true,
                    }}
                    style={{ fontSize: "12px" }}
                    placeholder="..."
                    value={searchVendor}
                    open={false}
                    name="search"
                    id="search"
                    onSearchChange={(evt, data) => setSearchVendor(data.value)}
                  />
                </div>
                <div
                  class="col-xs-3 col-md-4 row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <img
                    width={22}
                    height={22}
                    src={IMG}
                    alt="xml"
                    title={"Advance filter"}
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ cursor: "pointer" }}
                  ></img>
                  <div className="mr-2 ml-2">
                    <div>
                      {excelInvoiceLoading ? (
                        <Icon
                          loading={excelInvoiceLoading}
                          style={{ marginTop: "9px" }}
                          name="circle notch"
                          size="large"
                        />
                      ) : (
                        <i
                          className="fa-2x fas fa-file-excel"
                          style={{
                            color: "#66bb6a",
                            cursor: "pointer",
                            fontSize: "25px",
                            marginTop: "3px",
                          }}
                          title={"download_excel"}
                          onClick={() => generateInvoiceExcel()}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class="col-xs-3 col-md-3 divContenEnd row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                    padding: "0",
                  }}
                ></div>

                <div
                  class="col-xs-3 col-md-2 divContenCenter row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  <div className="col-12 row divContenCenter">
                    <label className="labelTitleAvancSearc">
                      Orders require attention
                    </label>
                  </div>
                  <div className="col-12 row divContenCenter">
                    <img width={22} height={22} src={IMG_ATTENTION} alt="xml"></img>
                    <div className="mr-2 ml-2">
                      <Switch
                        defaultChecked
                        checked={requiresAttention}
                        onChange={(evt) => { setRequiresAttention(evt) }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <Collapse isOpen={isOpen}>
                  <div className="row" style={{ marginBottom: "5px" }}>
                    {filtros}
                  </div>
                  <hr />
                </Collapse>
              </div>

              <Sidebar.Pushable as={Segment}>
                <Sidebar
                  direction="right"
                  animation="overlay"
                  icon="labeled"
                  visible={visible}
                  width="very wide"
                  id={"sidebarComentsHeight"}
                >
                  <Col xs={12} md={12} lg={12} className="d-flex row">
                    <Col xs={12} md={12} lg={12} className="pr-0 mt-1">
                      <div className="d-flex justify-content-between">
                        <div className="mt-2">PO No.# {PONumber}</div>
                        <div
                          className="mt-1"
                          onClick={() => {
                            setVisible(false);
                            setIsSearchComments(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <CloseIcon />
                        </div>
                      </div>
                    </Col>
                  </Col>
                  <Comments
                    _customer={_customerSelect}
                    POId={POId}
                    _user={_user}
                    _rolId={_rolId}
                    isSearchComments={isSearchComments}
                    resetTable={resetTable}

                    comments={comments}
                    pushComments={pushComment}
                    onchenge={OnChangeComment}
                    value={value}
                  />
                </Sidebar>
                <Sidebar.Pusher>
                  <Segment basic size="mini">
                    <TableComponent
                      columns={headers}
                      data={dataPOs}
                      totalPages={totalPages}
                      activePague={numberPage}
                      onPageChange={(evt, data) => {
                        // get_opports_function(data.activePage, requiresAttention);
                        setNumberPage(data.activePage);
                        // setPagRow(data.activePage)
                      }}
                    ></TableComponent>
                  </Segment>
                </Sidebar.Pusher>
              </Sidebar.Pushable>
            </>
          }

        </Card>
      </ContentWrapper>
    </>
  );
}

export default withRouter(VendorManagement);
