import React, { useState, useEffect } from "react";
import { Pagination, Search } from "semantic-ui-react";
import { Table as TableUI } from "antd";
import { Grid } from "@mui/material";

const Table = ({
  data = [],
  columns = [],
  loading = false,
  showSearch = false,
  placeholderSearch = "",
  handleClickRow = () => {},
  handleSearch = () => {},
  pageSize = 5,
}) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / pageSize) || 1);
    setPage(1);
  }, [data, pageSize]);

  useEffect(() => {
    setPageData(data.slice(0, pageSize));
  }, [data, pageSize]);

  const handleSearchChange = (_e, { value }) => {
    setSearch(value);
    setPage(1);
    handleSearch(value);
  };

  const handlePageChange = (e, { activePage }) => {
    setPage(activePage);
    setPageData(data.slice((activePage - 1) * pageSize, activePage * pageSize));
  };

  return (
    <Grid container>
      {showSearch && (
        <Grid item xs={4}>
          <Search
            className="marginLeftMinus"
            input={{ icon: "search", iconPosition: "left", fluid: true }}
            placeholder={placeholderSearch}
            value={search}
            open={false}
            name="search"
            onSearchChange={handleSearchChange}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TableUI
          className="tableMargin"
          pagination={false}
          loading={loading}
          columns={columns}
          size="small"
          dataSource={pageData}
          rowKey={(_record, index) => `table-row-${index}`}
          onRow={(record) => {
            return {
              onClick: () => {
                handleClickRow(record);
              },
            };
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="pagination-table">
          <Pagination
            defaultActivePage={1}
            totalPages={totalPages}
            activePage={page}
            onPageChange={handlePageChange}
            size="mini"
            siblingRange={0}
            ellipsisItem={null}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Table;
