import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import Table from '../../../../../template/Table'

class Packages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            rows: [],
            packages: [],
            columns: [
                { label: 'Count Of', key: 'CountPackages' },
                { label: 'Package Type', key: 'PackageType' },
                { label: 'Weight', key: 'Weights' },
                { abel: 'Unit a Weight', key: 'UnitWeightCode' },
                { label: 'Unit a Weight', key: 'Volumes' },
                { label: 'Dimensions', key: 'dimencion' },
                { label: 'Unit Of Dimensions', key: 'UnitVolumeCode' }
            ],
        }
    }

    componentDidMount = () => {
        this.setState({
            data: this.props.data
        }, () => {
            let packages = this.state.data && this.state.data.Packages && Array.isArray(this.state.data.Packages) && this.state.data.Packages.length > 0 ? this.state.data.Packages : []
            this.setState({
                packages
            })
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        }, () => {
            let packages = this.state.data && this.state.data.Packages && Array.isArray(this.state.data.Packages) && this.state.data.Packages.length > 0 ? this.state.data.Packages : []
            packages.forEach(function (element) {
                element.Volumes = element.Volume + "  " + element.UnitVolumeCode
                element.Weights = element.Weight + "  " + element.UnitWeightCode
                element.dimencion = element.Lenght + "  x  " + element.Weight + "  x  " + element.Height
            });
            this.setState({packages})
        })
    }
    render = () => {
        let { packages } = this.state
        return (
            <Card>
                <CardBody>
                    <h4 className="mb-3 py-2">Package information</h4>

                    <div className="row">
                        <div className="col-1">
                            <em className="fas fa-boxes fa-4x mb-3 py-2"></em>
                        </div>
                        <div className="col-10">
                            <Table data={packages} columns={this.state.columns} options={{ pagination: false, search: false, downloads: { xlsx: false, pdf: false } }}
                            ></Table>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Packages;