import React from 'react';
import { Card, Button, Grid } from 'semantic-ui-react';
import { Redirect } from 'react-router';
import Icons from './chatBotIcons';
import { useHistory } from "react-router-dom";


const defaultStyles = {
  fontSize: '13px',
};

function CardContent(props) {
  const [redirect, setRedirect] = React.useState(false);
  const [pathRedirect, setPathRedirect] = React.useState('');
  let history = useHistory();

  const { data, option, avilableOptions } = props;
  const { Icon, Header, SubHeader, Action, val1, val2, val3, val4, tabIndex } = avilableOptions;

  const defaultLabelButton = 'View Documents';


  const buttonAction = () => {
    const path = `${Action.path}${data[Action.id]}`;
    history.push({
      pathname: path,
      state: {  // location state
        tabIndex: tabIndex
      },
    }); 
  };



  // const actionButton = 'default';
  const actionButton = () => {

    buttonAction();

  };

  const CardContent = () => {
    return (
      <Card fluid>
        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Row columns={1} style={{ padding: '1rem 1em 0' }}>
              <Grid.Column width={10}>
                {Icons[option][Icon]}

                <b style={{ color: '#13B0F0', marginLeft: '5px' }}>
                  {data[Header]}
                </b>
              </Grid.Column>
              <Grid.Column width={6} textAlign={'justified'}>
                <b>{data[SubHeader]}</b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>

        <Card.Content style={defaultStyles}>
          <Grid divided="vertically">
            <Grid.Row columns={1} style={{ padding: '1rem 1em 0' }}>
              <Grid.Column width={8}>
                {val1.title}
                <br />
                <b>{data[val1.id]}</b>
              </Grid.Column>
              <Grid.Column width={8}>
                {val2.title}
                <br />
                <b>{data[val2.id]}</b>
              </Grid.Column>
              <Grid.Column width={8}>
                {data.cardTitle ? data[val3.title] : val3.title}
                <br />
                <b>{data[val3.id]}</b>
              </Grid.Column>
              <Grid.Column width={8}>
                {val4.title}
                <br />
                <b>{data[val4.id]}</b>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>

        <Card.Content extra>
          <div className="ui two buttons">
            <Button
              basic
              fluid
              compact
              color="grey"
              onClick={() => actionButton()}
              style={defaultStyles}
            >
              {avilableOptions.LabelButton
                ? avilableOptions.LabelButton
                : defaultLabelButton}
            </Button>
          </div>
        </Card.Content>
      </Card>
    );
  };

  return (
    <div style={{ width: '100%' }}>

     
      {CardContent()}
    </div>
  );
}

export default CardContent;
