import React from 'react';

import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'
import Footer from './Footer'
import ChatBot from '../../poseidon/Chatbot'

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#00558c',
        dark: '#00558c',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
});

const Base = props => (
  <MuiThemeProvider theme={theme}>
    <div className="wrapper">
        <Header />
        <Sidebar _userconfig={{...props}}/>
        <Offsidebar />
        <section className="section-container">
            {props.children}
        </section>
        <ChatBot/> 
        <Footer />
    </div>
  </MuiThemeProvider>
)

export default Base;
