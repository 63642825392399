import React, { Component } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
// import Order from '../../../../../models/Order'
import Form from '../../../../../../models/FormData'
import restService from '../../../../../../services/restService';
import './style.scss'
import Fade from 'react-reveal/Fade';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const rest = new restService();

class HeaderDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: {},
            selects: { INCOTERMS: [], PRODUCTS: [], UNITSMEASURE: [], CURRENCIES: [], TRANSPORTS: [], COUNTRIES: [], CUSTOMERS_DIVISIONS: [], CRUISES: [] },
            CreateOrderFormData: [],
            Form: Form,
            icon: 'fas fa-angle-down',
            selected: [],
            orderSelected: [],
            firtsOrderLoader: false
        }
    }
    componentDidMount = () => {
        let { selected } = this.props
        if (this.validArray(selected)) this.setState({
            orderSelected: selected,
        }, () => this.setFirstOrder())
        else this.getAllCatalogs()
    }
    componentWillReceiveProps = (nextProps) => {
        let { selected } = nextProps
        if (this.validArray(selected)) this.setState({
            orderSelected: selected,
        }, () => this.setFirstOrder())
    }

    setFirstOrder = () => {
        let firstOrder = this.state.orderSelected[0]
        let { firtsOrderLoader } = this.state
        if (!firtsOrderLoader) {
            let order = {
                PONumber: firstOrder.PONumber,
                POCreateDate: firstOrder.POCreateDate ? firstOrder.POCreateDate : '',
                RequestedExWorksDate: firstOrder.RequestedExWorksDate ? firstOrder.RequestedExWorksDate : '',
                RequiredDestination: firstOrder.RequiredDestination ? firstOrder.RequiredDestination : '',
                Incoterm: firstOrder.CodIncoterm ? firstOrder.CodIncoterm : '',
                OriginCountryId: firstOrder.OriginCountryId ? firstOrder.OriginCountryId : '',
                TransportModeId: firstOrder.TransportModeId ? firstOrder.TransportModeId : '',
                PODescription: firstOrder.PODescription ? firstOrder.PODescription : '',
                ProductTypeId: firstOrder.ProductTypeId ? firstOrder.ProductTypeId : '',
                AdditionalPOTerms: firstOrder.AdditionalPOTerms ? firstOrder.AdditionalPOTerms : '',
                OrderCurrencyId: firstOrder.OrderCurrencyId ? firstOrder.OrderCurrencyId : '',
                EntryCreatedDate: firstOrder.EntryCreatedDate ? firstOrder.EntryCreatedDate : '',
                LastUpdateDate: "",
                SupplierCode: "",
                ConsigneeCode: "",
                CruiseId: firstOrder.CruiseId ? firstOrder.CruiseId : '',
                OrderValue: firstOrder.OrderValue ? firstOrder.OrderValue : '',
                DeliveryCode: "",
                DivisionId: firstOrder.DivisionId ? firstOrder.DivisionId : ''
            }
            this.setState({
                order,
                firtsOrderLoader: true
            }, () => {
                this.props.sendDetailsState(this.state.order)
                this.getAllCatalogs()
            })
        }
    }

    validArray = (array) => Array.isArray(array) && array.length > 0 ? true : false

    getAllCatalogs = () => {
        rest.EXEC({ _function: 'GetAllCatalogs', params: '' }, fetch).then(data => {
            this.setState({ selects: data.data }, () => this.buildFormCreateOrder())
        }).catch(error => {
            
        })
    }
    handleChange = name => event => {
        let order = this.state.order
        order[name] = event.target.value
        this.setState({ order }, () => {
            this.buildFormCreateOrder()
            this.props.sendOrderField(name, this.state.order[name])
        })
    }
    handleChangeSelect = (selected) => event => this.setState({ selected }, () => {
        let { order } = this.state;
        if (event && event.value) {
            order[selected] = event.value;
            this.setState({ order }, () => {
                this.buildFormCreateOrder()
                this.props.sendOrderField(selected, this.state.order[selected])
            })
        } else {
            order[selected] = '';
            this.setState({ order }, () => {
                this.buildFormCreateOrder()
                this.props.sendOrderField(selected, '')
            })
        }
    });
    handleChangeDate = name => event => {
        let order = this.state.order
        try {
            order[name] = event.format("YYYY-MM-DD")
            this.setState({ order }, () => {
                this.buildFormCreateOrder()
                this.props.sendOrderField(name, this.state.order[name])
            })
        }
        catch (ex) {
            order[name] = ""
            this.setState({ order }
            )
        }
    }

    buildFormCreateOrder = () => {
        const { Form, order, selects } = this.state
        let CreateOrderFormData = Form.CreateOrder.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite> <span id={e.label}><label>{e.label} {e.required === true ? <span>*</span> : ""}</label></span></Fade>
                    <Input
                        id={e.label}
                        required
                        type={e.datatype}
                        placeholder={e.placeHolder}
                        name={e.id}
                        value={order[e.id]}
                        maxlength={e.maxlength}
                        minlength={e.minlength}
                        onChange={this.handleChange(e.id)}
                        max={e.max} />


                    <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>
            )
            else if (e.type === "Select") return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite>
                        <span id={e.label}>  <label >{e.label} {e.required === true ? <span>*</span> : ""} </label></span></Fade>
                    <Select
                        id={e.label}
                        required
                        type={e.datatype}
                        name={e.name}
                        value={order[e.id]}
                        onChange={this.handleChangeSelect(e.id)}
                        options={selects[e.options]}
                    />
                    <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>)
            else if (e.type === "Datetime") return (
                <div className={e.class} key={e.id} >
                    <Fade left opposite>
                        <span id={e.label}> <label  >{e.label} {e.required === true ? <span>*</span> : ""} </label></span></Fade>
                    <Datetime
                        required
                        type={e.datatype}
                        dateFormat={'YYYY MM DD'}
                        inputProps={{ className: 'form-control' }}
                        value={order[e.id]}
                        onChange={this.handleChangeDate(e.id)} />
                    <label>{order[e.id] >= e.max ? <label style={{ color: 'red' }}>maximum {e.maxlength} digits </label> : ""} </label>

                </div>
            )
            else if (e.type === 'textarea') return (
                <div className="col-md-6" key={e.id}>
                    <Fade left opposite>
                        <span id={e.label}> <label >{e.label} {e.required === true ? <span>*</span> : ""}</label></span></Fade>
                    <Input
                        required
                        type={e.datatype}
                        name={e.id}
                        value={order[e.id]}
                        onChange={this.handleChange(e.id)}
                    >
                    </Input>
                </div>
            ) 
            else return ''
        })
        this.setState({
            CreateOrderFormData
        })
    }
    render() {
        let { CreateOrderFormData } = this.state
        return (
            <ExpansionPanel elevation={0} defaultExpanded>
                <ExpansionPanelSummary id="mytext" expandIcon={<ExpandMoreIcon />}>
                    <legend ><em className="fas fa-file-alt fa-1x mr-2"></em>Order Details</legend>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="row">
                        {CreateOrderFormData}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}

export default HeaderDetails;
