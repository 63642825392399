import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import Filters from './components/filters';
import Results from './components/results';
import Maps from './components/maps';
import ByStatus from './components/byStatus';
import ByVessel from './components/byVessel';
import EventsAttention from './components/eventsRequiredAttention';
import ContentWrapper from '../../template/Layout/ContentWrapper';
import Axios from 'axios';
import {Services} from './Services';
class EUGround extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: '',
            results: [],
            totals: []
        };
    }


    componentDidMount = () => {
        this.getResults()
    }


    componentWillReceiveProps = (nextProps) => {
        this.getResults()
    }

    getResults = async () => {
      let success = await Axios.get(`${Services.WAREHOUSING.path}search/?${this.state.filters}`);
      if (success.data && success.data.data) {
          if (success.data && success.data.data.results) {
              this.setState({
                  results: success.data.data.results,
                  totals: success.data.data && success.data.data.totals ? success.data.data.totals : {}
              })
          }
      }
    }

    buildQuery = (filters) => {
        let FiltersQuery = ''
        let keys = Object.keys(filters)
        keys.map((e) => {
            if (filters[e]) FiltersQuery += e + '=' + filters[e] + '&'
        })
        this.setState({
            filters: FiltersQuery
        }, () => {
            this.getResults()
        })
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div className="row">
                        <legend><em className="fas fa-warehouse fa-1x mr-2"></em>Warehousing</legend>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-6 col-sm-12">
                        <Maps></Maps>
                    </div>
                    <div className="col-md-12 col-lg-6 col-sm-12">
                        <div className="row">
                            <div className="col-md-12 col-lg-6 col-sm-12">
                                <ByStatus totalsByStatus={this.state.totals.Status ? this.state.totals.Status : []}></ByStatus>
                            </div>
                            <div className="col-md-12 col-lg-6 col-sm-12">
                                <ByVessel totalsByStatus={this.state.totals.Vessel_Des ? this.state.totals.Vessel_Des : []}></ByVessel>
                            </div>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <EventsAttention></EventsAttention>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <br></br>
                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <Filters sendQuery={this.buildQuery}></Filters>
                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <Results results={this.state.results}></Results>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default EUGround;


