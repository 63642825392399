import React, { Component } from 'react';
import StepperEvent from '../../../../Components/StepperEvent';
import Table from '../../../../Components/Table';
import {Card,CardBody} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import TablesEvent from './tablesEvent';
import './styles.css';

class Events extends Component {
  state = {
    NoIngreso:this.props.NoIngreso,
    allTables:[],
    allSteps:[],
    steps : [
      {
        label: 'Notificación de Ingreso',
        value: '',
        id: 1,
        completed: false,
        order: 1,
        middleStep: ''
      },
      {
        label: 'Llegada a Almacén',
        value: '',
        id: 2,
        completed: false,
        order: 2,
        idStep: 'step2',
        middleStep: ''
      },
      {
        label: 'Inicio de Descargue',
        value: '',
        id: 3,
        completed: false,
        order: 3,
        idStep: 'step3',
        middleStep: ''
      },
      {
        label: 'Fin de Descargue',
        value: '',
        id: 4,
        completed: false,
        order: 4,
        idStep: 'step4',
        middleStep: ''
      },
      {
        label: 'Manifiesto (Público)',
        value: '',
        id: 6,
        completed: false,
        order: 5,
        idStep: 'step5',
        middleStep: ''
      },
      {
        label: 'Vencimiento de Manifiesto',
        value: '',
        id: 7,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      },
      {
        label: 'Disponibilidad de Inventario',
        value: '',
        id: 8,
        completed: false,
        order: 6,
        idStep: 'step6',
        middleStep: ''
      }
    ],
    columns:[
      { label: 'Descripción del Evento', key: 'EventDescription', idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Fecha del Evento', key: 'EventDate',idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Categoría de Responsable', key: 'CategoryResponsable',idTh: 'firstTh', idTd: 'firstTd' },
      { label: 'Origen', key: 'Origin' ,idTh: 'firstTh', idTd: 'firstTd'},
      { label: 'Fecha de Creación', key: 'CreateDate',idTh: 'firstTh', idTd: 'firstTd' },
    ],
    data:[],
  }

  componentDidMount = () => {
    this.setState({
      steps:this.props.steps,
      allSteps:this.props.allSteps,
      ReferenciaDeCliente: this.props.ReferenciaDeCliente,
      DocTransporte: this.props.DocTransporte,
      NoIngreso:this.props.NoIngreso,
      Estatus:this.props.Estatus,
      cliente:this.props.cliente,
      NIT:this.props.NIT,
      Grupo:this.props.Grupo

    },()=>{
      this.createTables();
    });
  }

  componentWillReceiveProps = (nextProps) => {   
    this.setState({
      steps:nextProps.steps,
      allSteps:nextProps.allSteps,
      ReferenciaDeCliente: nextProps.ReferenciaDeCliente,
      DocTransporte: nextProps.DocTransporte,
      NoIngreso:nextProps.NoIngreso,
      Estatus:nextProps.Estatus,
      cliente:nextProps.cliente,
      NIT:nextProps.NIT,
      Grupo:nextProps.Grupo,
    },()=>{this.createTables();});
  }

  createTables = () => {
    var allTables = [];
    this.state.allSteps.map((e) => {
      var findTable = false;
      allTables.map((f) => {
        if(f.eventType === e.EventType){
          f.data.push(e);
          findTable = true;
        }
        return f;
      });
      if(!findTable){
        var obj = {
          eventType: e.EventType,
          data: [e]
        };
        allTables.push(obj);
      }
      return e;
    });
    this.setState({allTables: allTables});
  }
  render() {
    let {cliente,NIT,Grupo} = this.state;
    return (
        <>
            <Card className="myCard">
              <CardBody>
                <div className="row" style={{ fontWeight :'none !important', marginLeft: 15 }}>
                  <em style={{ color: '#001489' }} className="fa-3x mr-1 fas fa-sort-amount-down"></em>
                  <div className="col">
                  <strong style={{ color: '' }}>{cliente} ({NIT})  / {Grupo}</strong>
                    <br/>
                    <strong style={{ color: '#0099ff' }}>{this.state.NoIngreso}</strong>
                    <br />
                    {/* { 'Referencia de Cliente: '} */}
                    {this.state.ReferenciaDeCliente }
                    <br />
                    {/* {'Doc. de Transporte: '} */}
                    {this.state.DocTransporte}
                  </div>
                </div>
                <div className="col-12" /* style={{background: 'pink'}} */>
                    <div className="row">
                      <div className="col-4">
                        {this.state.Estatus}
                      </div>
                    </div>
                  </div>
                <div className="col-12">
                  <StepperEvent steps={this.state.steps} />
                </div>
              </CardBody>
            </Card>
            <Card className="myCard">
              <CardBody>
                { this.state.allTables.length > 0 ? <TablesEvent columns={this.state.columns} allTables={this.state.allTables}/> : 
                  <Table
                    columns={this.state.columns}
                    data={this.state.data}
                    options={{
                      large:false,
                      downloads: {
                        pdf: false,
                        xlsx: false
                      }
                    }}/>}
              </CardBody>
            </Card>
        </>

    );
  }
}

export default withRouter(Events);

