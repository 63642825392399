import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Row, Col } from 'react-flexbox-grid';
// import FILES from './files'
import Fade from 'react-reveal/Fade';
import './stylle/style.scss';
import { Pagination } from 'semantic-ui-react';
import pdf from './purchasing.pdf';
import { saveAs } from 'file-saver';
import Axios from 'axios';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      rows: [],
      totalInvoices: 0,
      totalInvoicesAmount: 0,
      pages: 0,
      activePage: 0,
      tableData: [],
      columns: [],
      xlsx: <em className="fa-2x fas fa-file-excel mr-2"
        style={{ color: '#66bb6a', cursor: 'pointer' }}
        onClick={this.XLSX} />
    };
  }
  componentDidMount = () => {
    let { data ,columns} = this.props;
    this.setState({
      data
    }, () => {
      let pages = (data.length / 10) + 1;
      let tableData = this.partirArray(1, data, pages);
      this.setState({
        activePage: 1,
        pages: parseInt(pages),
        tableData,
        columns
      }, () => {
        this.buildTable();
      });
    });
  }

  partirArray = (page, all, pages) => {
    let array = [];
    if (page === 1)
      for (let i = 0; i < (all.length < 10 ? all.length : 10); i++)
        array.push(all[i]);
    else if (page !== 1 && page !== pages)
      for (let i = (page - 1) * 10; i < (page) * 10; i++)
        array.push(all[i]);
    else
      for (let i = (page - 1) * 10; i < all.length; i++)
        array.push(all[i]);
    return array;
  }


  handlePaginationChange = (e, { activePage }) => {
    let { data, pages } = this.state;
    let tableData = this.partirArray(activePage, data, pages);
    this.setState({ activePage, tableData }, () => { this.buildTable(tableData); });
  }

  download = id => event => {
    const linkSource = pdf;
    const fileName = 'REPORT_PURCHASING_' + new Date() + '.pdf';
    saveAs(linkSource, fileName);
  }

  /*
    downloadExcel = event => {
      fetch('https://siapireports.azurewebsites.net/reports/xlsx?from=purchase_order_list', {
        method: 'POST',
        body: JSON.stringify({
          features: this.state.data
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(data => data.json()).then(success => {
        if (success.base64) {
          const linkSource = `data:application/xlsx;base64,${success.base64}`;
          const fileName = 'REPORT_PUCHASE_ORDER_LIST' + new Date() + '.xlsx';
          saveAs(linkSource, fileName);
        }
      })
        .catch(error => { });
    }*/

  XLSX = () => {
    this.setState(
      {
        xlsx: (
          <div className="ball-clip-rotate">
            <div></div>
          </div>
        ),
      },
      () => {
        let { columns, data } = this.state;
        let headers = [];
        let keys = [];
        columns.map((e) => {
          headers.push(e.label);
          keys.push(e.key);
          return e;
        });
        let obj = {
          data: data,
          options: {
            headers: headers,
            keys: keys,
            image: {
              name: 'magicdome',
            },
          },
        };
        if (headers.length > 0) {
          Axios.post(
            'https://servicereportapi.azurewebsites.net/report/dynamic/xlsx',
            obj
          )
            .then((success) => {
              if (success.data.base64) {
                //const fileName = (Math.floor(Math.random() * 900000) + 100000)+Math.floor(Date.now() / 1000) +'.xlsx';

                let fileName;
                this.props.nameXlsx
                  ? (fileName = this.props.nameXlsx)
                  : (fileName =
                    Math.floor(Math.random() * 900000) +
                    100000 +
                    Math.floor(Date.now() / 1000) +
                    '.xlsx');

                const linkSource = `data:application/xlsx;base64,${success.data.base64}`;
                saveAs(linkSource, fileName);
              } else console.warn('Error downloading file', success);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#66bb6a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            })
            .catch((error) => {
              console.error('errorr', error);
              this.setState({
                xlsx: (
                  <em
                    className="fa-2x fas fa-file-excel mr-2"
                    style={{ color: '#66bb6a', cursor: 'pointer' }}
                    onClick={this.XLSX}
                  ></em>
                ),
              });
            });
        }
      }
    );
  }
  buildTable = (data) => {
    let { tableData } = this.state;
    let rows = tableData.map((e) => {
      return (
        <tr key={e.invoice}>
          <td><Fade left opposite> <a href="#">{e.poNumber}</a></Fade></td>
          <td><Fade left opposite>{e.poDate}</Fade></td>
          <td><Fade left opposite>{e.supplier}</Fade></td>
          <td><Fade left opposite>{e.company}</Fade></td>
          <td><Fade left opposite>{e.buyer}</Fade></td>
          <td><Fade left opposite>{e.division}</Fade></td>
          <td><Fade left opposite>{e.cruise}</Fade></td>
          <td><Fade left opposite>{e.projectCode}</Fade></td>
          <td><Fade left opposite>{e.accountNumber}</Fade></td>
          <td><Fade left opposite>{`$ ${parseInt(e.amount).toLocaleString('en-US')}.00`}</Fade></td>
          <td><Fade left opposite>{e.currency}</Fade></td>
          <td><Fade left opposite>{e.invoiceNumber}</Fade></td>
          <td><Fade left opposite>{`$ ${parseInt(e.invoiceAmount).toLocaleString('en-US')}.00`}</Fade></td>
          <td><Fade left opposite><center><em onClick={this.download(e.invoice)} style={{ color: '#bf360c' }} className="download fa-2x fas fa-file-pdf mr-2"></em></center></Fade></td>
        </tr>
      );
    });
    this.setState({
      rows
    }, () => this.buildHeaders());

  }
  buildHeaders = () => {
    let { data } = this.state;
    let totalInvoices = Array.isArray(data) ? data.length : 0;
    let totalInvoicesAmount = 0;
    data.forEach((e) => {
      totalInvoicesAmount += parseInt(e.amount);
    });

    totalInvoicesAmount = `$ ${totalInvoicesAmount.toLocaleString('en-US')}`;
    this.setState({
      totalInvoices,
      totalInvoicesAmount
    });
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.data
    }, () => {
      let pages = nextProps.data.length > 10 ? (nextProps.data.length / 10) + 1 : 1;
      let tableData = this.partirArray(1, nextProps.data, pages);
      this.setState({
        activePage: 1,
        pages: parseInt(pages),
        tableData
      }, () => {
        this.buildTable();
      });
    });
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            {/* <legend>Purchase Order List</legend> */}
          </div>
          <div className="col-12">
            <Row>
              <Col lg={4} sm={12} md={6} lgOffset={8} mdOffset={6}>
                <div className="row">
                  <div className="col-md-6 col-6 text-center">
                    <p>Total PO´s Amount</p>
                    <div className="h1">{`${this.state.totalInvoicesAmount}.00`}</div>
                  </div>
                  <div className="col-md-6 col-6 text-center">
                    <p>Total PO´s</p>
                    <div className="h1">{this.state.totalInvoices}</div>
                  </div>

                </div>
              </Col>
            </Row>
          </div>
          <div className="col-12">
            <Table striped hover responsive>
              <thead className="titleTable">
                <tr>
                  {this.state.columns.map((col) => {
                    return <th>{col.label}</th>
                  })}
                  <th> <center><em style={{ color: '#43a047' }} className="download fa-2x fas fa-download mr-2"></em></center></th>
                </tr>
              </thead>
              <tbody>
                {this.state.rows}
              </tbody>
            </Table>
          </div>
          <div className="col-12">
            <center><Pagination
              ellipsisItem={null}
              activePage={this.state.activePage}
              onPageChange={this.handlePaginationChange}
              totalPages={this.state.pages}>
            </Pagination></center>
          </div>

          <div className="col-12">
            <hr></hr>
          </div>
          <div className="col-12">
            <Row>
              <Col lg={1} sm={1} md={1} lgOffset={11} mdOffset={6}>
                <center>{this.state.xlsx} Download</center>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default DataTable;