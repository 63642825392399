import React from 'react';
import propTypes from 'prop-types';
import { Popover } from 'antd';
import 'antd/dist/antd.css';

/**
 * Popover Component
 * @param {*} props 
 */
function PopoverComponent(props){
  return (
    <Popover content={
      ( <div style={{width: props.width, maxHeight: props.maxHeight, overflowY: 'auto'}}>
        {props.children}
      </div>
      )
    } title={props.title} trigger="hover">
      <p>{ props.content  ? `${props.content.substring(0, props.maxShow)}${props.content.length > props.maxShow ? '...': ''}`: ''}</p>
    </Popover>    
  );
};

PopoverComponent.propTypes = {
  title: propTypes.string,
  content: propTypes.string,
  maxHeight: propTypes.number,
  maxShow: propTypes.number,
  width: propTypes.number
};

PopoverComponent.defaultProps = {
  title: '',
  content: '',
  maxShow: 20,
  maxHeight: 150,
  width: 140
};


export default PopoverComponent;