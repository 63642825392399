import React, { Component } from 'react';
// import ShipmentModel from '../../models/shipment.model'
// import shipmentDataPlaneModel from '../../models/shipment.plane.model'
import AddComment from './components/add-comment'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import Button from '@material-ui/core/Button';
// import RestService from '../../../../../../services/restService'
import Table from './../Table'
import { Card, CardBody,Button } from 'reactstrap';
import axios from 'axios';
// import { Services } from '../../Traceability/Services';
import './styles.scss'



// const rest = new RestService()

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      shipment: "",
      shipmentDataPlane: "",
      comments: [],
      disabledView: false,
      disabledDownload: false,
      data: [],
      columDescripcion:false,
      columns: this.props.storage ?
        [
          { label: 'Nombre del Documento', key: 'Name' },
          { label: 'Tipo de Documento', key: 'Type' },
          // { label: 'Categoría', key: 'Category' },
          { label: 'Fecha de Creación', key: 'CreationDate' },
          { label: 'Actualizado por', key: 'CreateBy' },
          // {
          //     label: 'Descarga', key: 'URL', custom: (value) => {
          //         return (
          //             <Button style={{color: 'rgb(93, 156, 236)'}} >
          //             {/* <a href={value} className="fas fa-download fa-2x " download="sample.PDF"> </a> */}
          //             {/* <a className="fas fa-download fa-2x dataIcon" onClick={() => window.open(value.URL)}></a> */}
          //             <a className="fas fa-download fa-2x dataIcon " href={value.URL}></a>
          //             </Button>
          //         )  
          //     }
          // },
          {
            label: 'Visualizar', key: 'DocumentKeyAsString', custom: (value) => {
              return (
                <Button style={{ color: 'rgb(101, 101, 101)',  }} disabled={this.disabledView}>
                  <button style={{color:'#5d9cec', borderColor:'transparent',background:'transparent' }}  className="fas fa-search fa-2x  dataIcon" onClick={() => value.origin && value.origin === 'expo' ? this.viewDocument(value.DocumentKeyAsString, value.Name, value.Type) : window.open(value.DocumentKeyAsString)}></button>
                </Button>
              )
            }
          },
        ] : this.props.columDescripcion === true ? 
        [
          { label: 'Nombre del Documento', key: 'Name' },
          { label: 'Tipo de Documento', key: 'Type' },
          { label: 'Descripción', key: 'Descripcion' },
          { label: 'Fecha de Creación', key: 'CreationDate' },
          { label: 'Actualizado por', key: 'CreateBy' },
          {
            label: 'Descarga', key: 'URL', custom: (value) => {
              return (
                <Button style={{ color: 'rgb(93, 156, 236)', borderColor:'transparent',background:'transparent' }} >
                  {/* <a href={value} className="fas fa-download fa-2x " download="sample.PDF"> </a> */}
                  {/* <a className="fas fa-download fa-2x dataIcon" onClick={() => window.open(value.URL)}></a> */}
                  <a className="fas fa-download fa-2x dataIcon " href={value.URL}> </a>
                </Button>
              )
            }
          },
          {
            label: 'Visualizar', key: 'DocumentKeyAsString', custom: (value) => {
              return (
                value.Type === 'xlsx' ? <></> :
                <Button style={{ color: 'rgb(101, 101, 101)',   }} disabled={this.disabledView}>
                  <button style={{color:'#5d9cec', borderColor:'transparent',background:'transparent' }}  className="fas fa-search fa-2x  dataIcon" onClick={() => value.origin && value.origin === 'expo' ? this.viewDocument(value.DocumentKeyAsString, value.Name, value.Type) : window.open(value.DocumentKeyAsString)}></button>
                </Button>
              )
            }
          },
        ]
        :
        [
          { label: 'Nombre del Documento', key: 'Name' },
          { label: 'Tipo de Documento', key: 'Type' },
          // { label: 'Categoría', key: 'Category' },
          { label: 'Fecha de Creación', key: 'CreationDate' },
          { label: 'Actualizado por', key: 'CreateBy' },
          {
            label: 'Descarga', key: 'URL', custom: (value) => {
              return (
                <Button style={{ color: 'rgb(93, 156, 236)',  }} >
                  {/* <a href={value} className="fas fa-download fa-2x " download="sample.PDF"> </a> */}
                  {/* <a className="fas fa-download fa-2x dataIcon" onClick={() => window.open(value.URL)}></a> */}
                  <a className="fas fa-download fa-2x dataIcon " href={value.URL}> </a>
                </Button>
              )
            }
          },
          {
            label: 'Visualizar', key: 'DocumentKeyAsString', custom: (value) => {
              return (
                <Button style={{ color: 'rgb(101, 101, 101)'  }} disabled={this.disabledView}>
                  <button style={{color:'#5d9cec',  borderColor:'transparent',background:'transparent' }}  className="fas fa-search fa-2x  dataIcon" onClick={() => value.origin && value.origin === 'expo' ? this.viewDocument(value.DocumentKeyAsString, value.Name, value.Type) : window.open(value.DocumentKeyAsString)}></button>
                </Button>
              )
            }
          },
        ],
    };
  }


  componentDidMount = () => {
    this.setState({
      data: this.props.dataDoc ? this.props.dataDoc : [],
      // columDescripcion:this.props.columDescripcion ? this.props.columDescripcion :false,
      shipment: this.props.shipment,
      shipmentDataPlane: this.props.shipmentDataPlane
    }, () => {
      // this.getComments()
    })
  }


  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.dataDoc ? nextProps.dataDoc : [],
      // columDescripcion:nextProps.columDescripcion ? nextProps.columDescripcion :false,
      shipment: nextProps.shipment,
      shipmentDataPlane: nextProps.shipmentDataPlane
    })
  }

  getComments = () => {
    let { shipment } = this.state
    let id = shipment.id;
    // let id = 7328908;
    // axios.get(`http://192.168.1.73:3017/documents/findDocumentsWPS/${id}`)
    axios.get(`https://poseidonbooking.azurewebsites.net/documents/findDocumentsWPS/${id}`)
      .then((response) => {
        this.setState({ data: response.data.data });
        this.getCommentsView();
      }).catch((error) => {
      });
  }

  getCommentsView = () => {
    let { shipmentDataPlane } = this.state
    let id = shipmentDataPlane.ShipmentId;
    // let id = 122;
    let moduleId = 3;
    // axios.get(`http://192.168.1.73:3017/documents/findDocumentsView/${moduleId}/${id}`)
    let url = `https://poseidonbooking.azurewebsites.net/documents/findDocumentsView/${moduleId}/${id}`;
    axios.get(url)
      .then((response) => {
        let newData = this.state.data;
        let viewData = response.data.data;
        viewData.map((e) => {
          newData.push(e);
          return e;
        })
        this.setState({ data: newData });
      }).catch((error) => {
      });
  }

  toggle = evt => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  callback = (success) => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }), () => this.getComments());
  }

  onError = (error) => {
    console.error(error);
    this.setState(prevState => ({
      modal: !prevState.modal
    }), () => this.getComments());
  }

  viewDocument = (id, name, Type) => {
    let data = {
      'id': id,
      'name': name
    };

    axios.post(`https://roldanapidocument.azurewebsites.net/document/DocumentsPreview/`, data).then(success => {
      var base64str = success.data.base64;
      let pdfWindow = window.open("")
      pdfWindow.document.write(Type === 'png'  || Type === 'jpg' ?
      (`<iframe width='100%'  height='100%'  src=${base64str} ></iframe> `)
        :
       (`<iframe width='100%' type="application/${Type}"  height='100%' src='data:application/${Type};base64, ` + encodeURI(base64str) + "'></iframe> "))
    }).catch(error => {
      console.warn(error);
    });
  }

  render() {
    return (
      <>
        <Card className="myCard">
          <CardBody>
            <div className="row">
              <div className="col-12">
                <Table
                  columns={this.state.columns}
                  data={this.state.data}
                  itemsByPage={10}
                  options={{
                    large: false,
                    downloads: {
                      pdf: false,
                      xlsx: false
                    },
                    pagination: true,
                    search: true,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-11">
              </div>
              {/* <div className="col-1">
                    <Fab color="primary" size="small" aria-label="add" onClick={this.toggle}>
                      <AddIcon />
                    </Fab>
                  </div> */}

            </div>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}>Create new document</ModalHeader>
          <ModalBody>
            <AddComment callback={this.callback} onError={this.onError} shipmentDataPlane={this.state.shipmentDataPlane} />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Tracking;
