import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from 'reactstrap';
import Datetime from 'react-datetime';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Form from '../../../../../../models/FormData'
import restService from '../../../../../../services/restService';
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
import Config from '../../models/Config'
import { Card, CardBody } from 'reactstrap'

const rest = new restService();
class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CreateBookingFormData: '',
            Form: Form,
            selected: [],
            Booking: {
                BookingNumber: '',
                BookingDate: '',
                TransportModeId: '',
                FreightTermId: '',
                CargoReadyDate: '',
                OriginPort: '',
                DestinationPort: '',
                OriginAirport: '',
                DestinationAirport: '',
                FinalDeliveryDesc: '',
                IncotermCode: '',
                CargoTypeId: '',
                ThreePLId: '',
                ThreePLBookingRef: '',
                CustomerRef1: '',
                CustomerRef2: '',
                PickupLocation: '',
                PickupAddionalInstructions: '',
                BookingInstructions: '',
                CargoDescriptions: '',
                Pickup: false,
                ExportClearance: false,
                CargoInsurance: false,
                ImportClearance: false,
                FinalDelivery: false,
                SecurityServices: false
            },
            selects: Config.selectsAdditional,
            CreateDestination: '',
            CreateOrigin: ''


        }
    }

    componentWillUnmount = () => {
        this.setState({
            Booking: {}
        })
    }


    componentDidMount = () => {
        this.setState({
            selected: this.props.selected
        }, () => this.getAllCatalogs())
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            selected: nextProps
        }, () => {
            this.setFirtsOrderData()
        })
    }

    setFirtsOrderData = () => {
        let { selected, Booking } = this.state
        if (selected.length > 0) {
            Booking['TransportModeId'] = selected[0].TransportModeId
            Booking['IncotermCode'] = selected[0].CodIncoterm
            this.changeCatalogsByTransportModeId(selected[0].TransportModeId)
        }

        this.setState({ Booking }, () => {
            this.props.sendProps(Booking)
            this.buildFormCreateOrder()
            this.CreateOriginForm()
            this.CreateDestinationForm()
        })
    }

    getAllCatalogs = () =>
        rest.EXEC({ _function: 'GetAllCatalogsBooking', params: '' }, fetch).then(data => {
            this.setState({ selects: data.data }, () => {
                this.setFirtsOrderData()
            })
        }).catch(error => {

        })


    handleChange = name => event => {
        let Booking = this.state.Booking
        Booking[name] = event.target.value
        this.setState({ Booking, Form }, () => {
            this.props.sendProps(Booking)
            this.buildFormCreateOrder()
        })
    }



    changeCatalogsByTransportModeId = (id) => {
        let { Form, Booking } = this.state
        if (id === 1 || id === 2) {
            Form.CreateBooking[5].options = 'PORTS-OCEAN'
            Form.CreateBooking[5].id = 'OriginPort'
            Form.CreateBooking[5].name = 'OriginPort'


            Form.CreateBooking[6].options = 'PORTS-OCEAN'
            Form.CreateBooking[6].id = 'DestinationPort'
            Form.CreateBooking[6].name = 'DestinationPort'

            Booking.OriginAirport = ''
            Booking.DestinationAirport = ''
        }
        else {
            Form.CreateBooking[5].options = 'PORTS-AIR'
            Form.CreateBooking[5].id = 'OriginAirport'
            Form.CreateBooking[5].name = 'OriginAirport'
            Form.CreateBooking[6].options = 'PORTS-AIR'
            Form.CreateBooking[6].id = 'DestinationAirport'
            Form.CreateBooking[6].name = 'DestinationAirport'

            Booking.OriginPort = ''
            Booking.DestinationPort = ''
        }
        this.setState({
            Form, Booking
        }, () => {
            this.props.sendProps(Booking)
            this.buildFormCreateOrder()
        })

    }
    handleChangeSelect = (selected) => event => this.setState({ selected }, () => {
        let { Booking } = this.state
        if (event && event.value) {
            if (selected === 'TransportModeId') this.changeCatalogsByTransportModeId(event.value)
            Booking[selected] = event.value;
            this.setState({ Booking }, () => {
                this.props.sendProps(Booking)
                this.buildFormCreateOrder()
            })
        } else {
            Booking[selected] = '';
            this.setState({ Booking }, () => {
                this.props.sendProps(Booking)
                this.buildFormCreateOrder()
            })
        }
    });

    handleChangeDate = name => event => {
        let Booking = this.state.Booking
        try {
            Booking[name] = event.format("YYYY-MM-DD")
            this.setState({ Booking }, () => {
                this.props.sendProps(Booking)
                this.buildFormCreateOrder()
            })
        }
        catch (ex) {
        }
    }
    handlePick = name => event => {
        let Booking = this.state.Booking
        Booking[name] = event.target.checked
        this.setState({ Booking }, () => {
            this.props.sendProps(Booking)
            this.CreateOriginForm()
            this.CreateDestinationForm()
        });
    };

    CreateOriginForm = () => {
        const { Form, Booking } = this.state
        let CreateOrigin = Form.CreateBookingAddOnsAtOrigin.map((e, i) => {
            if (e.type === 'check') return (
                <div className="col-md-2" key={e.id} style={{ paddingTop: '3%' }} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Booking[e.id]}
                                onChange={this.handlePick(e.id)}
                                value={Booking[e.id]}
                                color="primary"
                            />
                        }
                        label={e.label}
                    />
                </div>
            )
            else return ''
        })
        this.setState({
            CreateOrigin
        })
    }

    CreateDestinationForm = () => {
        const { Form, Booking } = this.state
        let CreateDestination = Form.CreateBookingAddOnsAtDestination.map((e, i) => {
            if (e.type === 'check') return (
                <div className="col-md-2" key={e.id} style={{ paddingTop: '3%' }} >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Booking[e.id]}
                                onChange={this.handlePick(e.id)}
                                value={Booking[e.id]}
                                color="primary"
                            />
                        }
                        label={e.label}
                    />
                </div>
            )
            else return ''
        })
        this.setState({
            CreateDestination
        })
    }

    buildFormCreateOrder = () => {
        const { Form, Booking, selects } = this.state
        let CreateBookingFormData = Form.CreateBooking.map((e, i) => {
            if (e.type === 'Input') return (
                <div className={e.class} key={e.id}>
                    <span id={e.label}> <label>{e.label} {e.required === true ? <span>*</span> : ""}</label></span>
                    <Input
                        type={e.datatype}
                        placeholder={e.placeHolder}
                        name={e.id}
                        value={Booking[e.id]}
                        onChange={this.handleChange(e.id)}
                        max={e.max} />
                </div>
            )
            else if (e.type === "Select") return (
                <div className={e.class} key={e.id}><span id={e.label}><label >{e.label} {e.required === true ? <span>*</span> : ""}</label></span>
                    <Select
                        type="select"
                        name={e.name}
                        value={Booking[e.id]}
                        onChange={this.handleChangeSelect(e.id)}
                        options={selects[e.options]}
                    />
                </div>)
            else if (e.type === "Datetime") return (
                <div className={e.class} key={e.id}>
                    <span id={e.label}> <label >{e.label} {e.required === true ? <span>*</span> : ""}</label></span>
                    <Datetime
                        id={e.label}
                        type="date"
                        dateFormat={'YYYY MM DD'}
                        inputProps={{ className: 'form-control' }}
                        value={Booking[e.id]}
                        onChange={this.handleChangeDate(e.id)} />
                </div>
            )
            else if (e.type === 'textarea') return (
                <div className="col-md-6" key={e.id}>
                    <span id={e.label}><label >{e.label} {e.required === true ? <span>*</span> : ""}</label></span>
                    <Input
                        required
                        type="textarea"
                        name={e.id}
                        id={e.id}
                        value={Booking[e.id]}
                        onChange={this.handleChange(e.id)}
                    >
                    </Input>
                </div>
            )
            else return ''
        })
        this.setState({
            CreateBookingFormData
        })
    }

    render() {
        const { CreateBookingFormData, CreateOrigin, CreateDestination } = this.state
        return (
            <Card className="myCard">
                <CardBody>
                    <ExpansionPanel elevation={0} defaultExpanded>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <legend><em className="fas fa-calendar-check fa-1x mr-2"></em>Booking Details</legend>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        {CreateBookingFormData}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row" style={{ paddingTop: '3%' }}>
                                        <div className="col-md-12">
                                            <legend >Service Add-ons</legend>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <legend className="_legend">At Origin</legend>
                                                        </div>
                                                        <div className="col-md-12">
                                                            {CreateOrigin}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <legend className="_legend">At Destination</legend>
                                                    </div>
                                                    <div className="col-md-12">
                                                        {CreateDestination}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </CardBody>
            </Card>

        );
    }
}
export default List;