import React, { Component } from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Consignees from '../../../order-create/components/Consignees'
import Deliveries from '../../../order-create/components/Deliveries'
import Suppliers from '../../../order-create/components/Suppliers'
// import Order from '../../../../../models/Order'
import Required from '../../../../../../models/RequiresPOcreate'

class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: {},
            selected: [],
            Required: Required,
            errors: "",
            items: [],
            color: "",
            Documemts: [],
            loader: '0',
            error: {
                open: false,
                message: '',
                type: 'error'
            }
        };
    }
    componentDidMount = () => {
        this.setState({
            selected: this.props.selected
        })
    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            selected: nextProps.selected
        })
    }

    render() {
        return (
            <ExpansionPanel elevation={0} defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div className="row" style={{ width: '100%' }}>
                        <div className="col-md-4">
                            <Suppliers sendProps={this.props.sendProps} selected={this.state.selected}></Suppliers>
                        </div>
                        <div className="col-md-4">
                            <Consignees sendProps={this.props.sendProps} selected={this.state.selected}></Consignees>
                        </div>
                        <div className="col-md-4">
                            <Deliveries sendProps={this.props.sendProps} selected={this.state.selected}></Deliveries>
                        </div>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}
export default FormExtended;