const rFactor = () => Math.round(Math.random() * 100);
export const _chart1 = {
  data: {
    labels: [
      'Logistics',
      'Purchasing'
            
    ],
    datasets: [{
      data: [300, 50],
      backgroundColor: [
                
        'rgba(255, 159, 64, 1)',
        'rgba(54, 162, 235, 1)',
        '#23b7e5',

      ],
      hoverBackgroundColor: [
                
        'rgba(255, 159, 64, 1)',
        'rgba(54, 162, 235, 1)', 
        '#23b7e5'
      ]
    }]
  },
  options: {
    legend: {
      display: false
    }
  }
};


export const _chart2 = {

  data: [{
    // "label": "Top Buyers PO",
    'color': '#1565c0',
    'data': [
      ['Engines',1000000],
      ['Tools',2500000],
      ['Machinery',1700000],
      ['Entertainment',4000000],
      ['Houseware',3200000],

    ]
  }],

  options: {
    series: {
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      // position: 'right' or 'left'
      tickColor: '#eee',
      min: 0,
      max: 10000000, 
    },
    shadowSize: 0
  }
};

export const _chart3 = {

  data: [{
    // "label": "Sales",
    'color': '#1565c0',
    'data': [
      ['Logistic Cost One',1000000],
      ['Tools',2500000],
      ['Machinery',1700000],
      ['Entertainment',4000000],
      ['Houseware',3200000],
    ]
  }],

  options: {
    series: {
      bars: {
        align: 'center',
        lineWidth: 0,
        show: true,
        barWidth: 0.6,
        fill: 0.9
      }
    },
    grid: {
      borderColor: '#eee',
      borderWidth: 1,
      hoverable: true,
      backgroundColor: '#fcfcfc'
    },
    tooltip: true,
    tooltipOpts: {
      content: (label, x, y) => x + ' : ' + y
    },
    xaxis: {
      tickColor: '#fcfcfc',
      mode: 'categories'
    },
    yaxis: {
      // position: 'right' or 'left'
      tickColor: '#eee',
      min: 0,
      max: 10000000, 
    },
    shadowSize: 0
  }
};

