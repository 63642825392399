import React, { Component, Suspense } from "react";
import _ from "lodash";
import Loader from "../../../Global/Loader";
import axios from "axios";
import { Services } from "../Services";
import { Icon } from "semantic-ui-react";
import { message } from "antd";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { Tab } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import ContainerWrapper from "../../../template/Layout/ContentWrapper";
import SumaryDetail from "./components/general";
// import Events from "./components/Events";
import Document from "./components/Documents";
import queryString from "query-string";

import "./styles/styles.scss";
import { getCommentsResponse, getMentionsResponse } from "../mocks/comments";
import { poDetailResponse } from "../mocks/poDetail";

const validateIdParams =  (id) => {
  return /^\d{1,9}$/.test(id);
}

const Comments = React.lazy(() => import("../../../Components/Comments"));
// const AllComments = React.lazy(() =>
//   import("../../../Components/Comments/AllComment")
// );
const IMGOrdenes =
  "https://magiclog.blob.core.windows.net/europartnerspic/Icono_Ordenes-de-compra.svg";

class PurchaseOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobil: false,
      clientesSelect: this.props.clients
        ? JSON.stringify(this.props.clients)
        : "[0]",
      scrollLine: true,
      tabActive: 0,
      rolId: this.props._rolId,
      userId: this.props._user,
      AllcommentsData: [],
      createFormsData: [],
      isEdit: false,
      createForms: [],
      poInfo: {},
      purchaseOrderId: this.props.match ? this.props.match.params.id : -1,
      info: {},
      allSteps: [],
      steps: [],
      newStepsAereoM: [
        {
          value: "",
          label: "Created",
          id: 1,
          completed: false,
          order: 1,
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Cargo ready",
          value: "",
          id: 5,
          completed: false,
          order: 2,
          idStep: "",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Planned",
          value: "",
          id: 6,
          completed: false,
          order: 3,
          middle: false,
          idStep: "",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Pick up",
          value: "",
          id: 8,
          completed: false,
          order: 4,
          middle: false,
          idStep: "",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },

        {
          label: "Booking",
          value: "",
          id: 9,
          completed: false,
          order: 5,
          idStep: "step5",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Departure",
          value: "",
          id: 12,
          completed: false,
          order: 6,
          idStep: "step6",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Arrival",
          value: "",
          id: 15,
          completed: false,
          order: 7,
          idStep: "step7",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Delivery",
          value: "",
          id: 21,
          completed: false,
          order: 8,
          idStep: "step8",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
      ],
      newStepsTerrestre: [
        {
          value: "",
          label: "Created",
          id: 1,
          completed: false,
          order: 1,
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Cargo ready",
          value: "",
          id: 5,
          completed: false,
          order: 2,
          idStep: "",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Planned",
          value: "",
          id: 6,
          completed: false,
          order: 3,
          middle: false,
          idStep: "",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Pick up",
          value: "",
          id: 8,
          completed: false,
          order: 4,
          middle: false,
          idStep: "",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },

        {
          label: "Booking",
          value: "",
          id: 9,
          completed: false,
          order: 5,
          idStep: "step5",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Departure",
          value: "",
          id: 12,
          completed: false,
          order: 6,
          idStep: "step6",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Arrival",
          value: "",
          id: 16,
          completed: false,
          order: 7,
          idStep: "step7",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
        {
          label: "Delivery",
          value: "",
          id: 21,
          completed: false,
          order: 8,
          idStep: "step8",
          middleStep: "",
          classValue: "subTitleCard",
          classTittle: "statusStepTittle",
        },
      ],
      infoEmbarque: {},
      loadingInfo: true,
      isEditing: false,
      tablaClientes: "",
      commentsData: [],
      users: [],
      catalogsData: [
        {
          ComTypeId: 1,
          ModuleId: 1,
          ComType: "Supplier Follow Up",
          value: 1,
          label: "Supplier Follow Up",
        },
        {
          ComTypeId: 2,
          ModuleId: 1,
          ComType: "Order Quality",
          value: 2,
          label: "Order Quality",
        },
        {
          ComTypeId: 3,
          ModuleId: 1,
          ComType: "Order Quantities",
          value: 3,
          label: "Order Quantities",
        },
        {
          ComTypeId: 10,
          ModuleId: 1,
          ComType: "Other",
          value: 10,
          label: "Other",
        },
      ],
    };
  }

  componentDidMount = async () => {
    this.validateParamId();
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    const isMobileDevice = regexp.test(details);
    this.setState({
      isMobil: isMobileDevice,
    });
    const isNotify = queryString.parse(this.props.location.search);
    let notifyActiveComment = false;
    if (isNotify.nofify) {
      notifyActiveComment = true;
    }
    await this.getPurchaseOrder(notifyActiveComment);
    this.getCommets(false);
  };

  validateParamId = () => {
    const { purchaseOrderId } = this.state;
    const idValidate = validateIdParams(purchaseOrderId);
    if (!idValidate) {
      message.error("Data entered is invalid");
      setTimeout(() => {
        this.props.history.push("/management");
      }, 2000);
      return;
    }
  };

  getCommets = async(NewComments) => {
    try {
      let { CustomerId, POId } = this.state.poInfo;
      // let moduleId = 10;
      // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // const response = await axios
      //   .get(
      //     `${Services.ORDEN_SERV.path}orderComents/get-comment?rowid=${POId}&moduleid=${moduleId}&general=true&zona=${timezone}&lang=${this.props.lng}`
      //   )
      // const comentario = response.data.Comment;
      // const AllcommentsData = response.data.CommentGeneral;
      const comentario = getCommentsResponse.Comment;
      const AllcommentsData = getCommentsResponse.CommentGeneral;
      this.setState({
        AllcommentsData: AllcommentsData,
        commentsData: AllcommentsData,
      });
      this.getUserMenciones(comentario, CustomerId, POId, this.state.poInfo);

      if (NewComments) {
        message.success(NewComments);
      }
    } catch (error) {
      console.log("getCommetsError", error);
    }
  };

  getUserMenciones = async(comentario, CustomerId, ShipmentId, item) => {
    try {
      // let { userId } = this.state;
      // const response = await axios
      // .get(
      //   `${Services.ORDEN_SERV.path}orderComents/getUserMension?CustomerId=${CustomerId}&user=${userId}`
      // )
      // const data = response.data.data;
      const data = getMentionsResponse;
      this.setState({
        commentsData: comentario,
        users: data,
        rowid: ShipmentId,
        itemCommenSelect: item,
        loadercomments: false,
      });
    } catch (error) {
      console.log("getUserMencionesError", error);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    const isNotify = queryString.parse(nextProps.location.search);
    let notifyActiveComment = false;
    const newProp = nextProps.match.params.id || this.props.match.params.id;

    this.setState(
      {
        newSelected: [],
        selectcheck: false,
        Form: nextProps.form,
        reset: true,
      },
      () => {
        if (isNotify.nofify) {
          notifyActiveComment = true;
          this.setState(
            {
              purchaseOrderId: newProp,
              loadingInfo: true,
            },
            () => {
              this.getPurchaseOrder(notifyActiveComment);
              this.getCommets(true);
            }
          );
        }

        // this.getRoles();
        // this.getUser();
      }
    );
  };
  getPurchaseOrder = async () => {
    try {
      const { tempInfo, purchaseOrderId, userId } = this.state;
      // const purchaseInfo = await PurchaseOrderRepository.getPO(
      //   purchaseOrderId,
      //   userId,
      //   this.props.lng
      // );
      // if (!purchaseInfo.data) throw new Error("No data");
      const purchaseInfo = poDetailResponse;
      this.setState(
        {
          // poInfo: purchaseInfo.data,
          poInfo: purchaseInfo,
          tempInfo,
          loadingInfo: false,
        },
        () => {
          this.getEvents();
        }
      );
    } catch (error) {
      console.log("getPurchaseOrderError", error);
      message.error("Data entered is invalid");
      setTimeout(() => {
        this.props.history.push("/management");
      }, 2000);
    }
  };

  actualiza = async (newPO) => {
    this.setState({ isEditing: false, loadingInfo: true });
    // const { purchaseOrderId, userId } = this.state;
    // this.setState({ isEditing: false, loadingInfo: true }, async () => {
    //   try {
    //     const updatePO = {
    //       ...newPO,
    //       POId: purchaseOrderId,
    //       UserId: userId,
    //     };
    //     await Promise.all([
    //       PurchaseOrderRepository.updatePO(updatePO),
    //       PurchaseOrderRepository.updatePOCustomerEntities(updatePO),
    //     ]);
    //     // modal de actualizar
    //     Swal.fire({
    //       icon: "success",
    //       confirmButtonColor: "#2b3b57",
    //       confirmButtonText: "Ok",
    //       html: `<div class="text-center">
    //       ${this.props.t("purchase_order_successfully_updated")}
    //       </div>`,
    //     });
    //     this.getPurchaseOrder();
    //   } catch (error) {
    //     console.log("updatePOError", error);
    //     Swal.fire({
    //       icon: "error",
    //       confirmButtonColor: "#2b3b57",
    //       confirmButtonText: "Ok",
    //       html: `<div class="text-center">
    //       ${this.props.t("purchase_order_error_updated")}
    //       </div>`,
    //     });
    //     this.setState({ loadingInfo: false });
    //   }
    // });
  };

  editar = () => {
    const { isEditing } = this.state;
    this.setState({ isEditing: !isEditing });
  };

  showLoad = () => {
    this.setState({ loadingInfo: true });
  };

  finishLoad = () => {
    this.setState({ loadingInfo: false });
  };

  getEvents = () => {
    // const { purchaseOrderId, newStepsAereoM, newStepsTerrestre, info } =
    //   this.state;
    // axios
    //   .get(
    //     `${Services.PURCHASE_ORDER.path}purchaseOrder/poEvents/${purchaseOrderId}?lang=${this.props.lng}`
    //   )
    //   .then((response) => {
    //     let update = false;
    //     for (let index = response.data.steps.length; index >= 0; index--) {
    //       const element = response.data.steps[index];
    //       if (element && element.completed) update = true;
    //       if (update) element.completed = true;
    //     }
    //     let dataEvents = response.data.data;
    //     let findNext = false;
    //     //Road 3
    //     let newSteps =
    //       info.TransportModeId === 3 ? newStepsTerrestre : newStepsAereoM;
    //     newSteps.map((f) => {
    //       dataEvents.map((e) => {
    //         if (e.EventDescriptionId === f.id) {
    //           findNext = true;
    //           f.value = e.EventDate;
    //           if (e.InTimeLine === "1") {
    //             f.completed = true;
    //           }
    //         } else {
    //           findNext = false;
    //         }
    //         return e;
    //       });
    //       return f;
    //     });
    //     newSteps.sort(function (a, b) {
    //       return b.order - a.order;
    //     });
    //     let findTrue = false;
    //     newSteps.map((e) => {
    //       if (e.completed) {
    //         findTrue = true;
    //       }
    //       if (findTrue) {
    //         e.completed = true;
    //       }
    //       return e;
    //     });
    //     newSteps.sort(function (a, b) {
    //       return a.order - b.order;
    //     });

    //     this.setState({
    //       steps: newSteps,
    //       allSteps: dataEvents,
    //       loadingInfo: false,
    //     });
    //   });
  };

  cancelar = () => {
    const { isEditing, tempInfo } = this.state;
    this.setState({
      isEditing: !isEditing,
      info: _.cloneDeep(tempInfo),
    });
  };

  tabChange = (evt, data) => {
    if (data.activeIndex > 0) {
      this.setState({ loadingInfo: false, isEditing: false });
    }
  };

  newMessage = async (data) => {
    // const { purchaseOrderId, userId } = this.state;
    // data.Rowid = purchaseOrderId;
    // data.UserId = userId;
    // data.ModuleId = 10;

    // if (data.Mentions.length > 0) {
    //   let uniq = [...new Set(data.Mentions)];
    //   data.Mentions = uniq;
    // }

    // axios
    //   .post(
    //     `${Services.PURCHASE_ORDER.path}purchaseOrderComments/add-comment`,
    //     data
    //   )
    //   .then(() => {
    //     this.getCommets(this.props.t("comment_successfully_saved"));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     message.error("Error al mandar el comentario");
    //   });
  };

  deleteMessage = async (idDelete) => {
    // axios
    //   .delete(
    //     `${Services.PURCHASE_ORDER.path}purchaseOrderComments/delete-comment/${idDelete}`
    //   )
    //   .then(() => {
    //     this.getCommets(this.props.t("comment_successfully_deleted"));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  render() {
    let {
      tabActive,
      loadingInfo,
      isMobil,
      rolId,
      userId,
      commentsData,
      users,
      AllcommentsData,
    } = this.state;

    if (loadingInfo) return <Loader />;

    return (
      <ContainerWrapper>
        <div className="content-heading">
          <legend className="legendEuro euro-icon-module-tittle ">
            <img
              alt="orders"
              style={{ width: "28px", margin: "-6px 6px 1px" }}
              src={IMGOrdenes} />
            Management
          </legend>
          <div className="euro-return-tittle">
            <Icon.Group size="large">
              <Icon
                name="angle left"
                className="_btnBack"
                size="big"
                style={{ fontSize: "28px" }}
                onClick={() => this.props.history.push("/management")}
              />
            </Icon.Group>
            <h3
              style={{
                fontWeight: "bold",
                fontSize: "14px",
              }}
              className="_btnBack"
              onClick={() => this.props.history.push("/management")}
            >
              Back
            </h3>
          </div>
        </div>

        <Tab
          menu={{
            secondary: true,
            className: "wrapped",
            pointing: true,
            style: { fontSize: "14px" },
          }}
          onTabChange={(_event, data) => {
            this.setState({ tabActive: data.activeIndex }, () => this.tabChange);
          }}
          activeIndex={tabActive}
          className={"label-euro"}
          menuPosition="right"
          panes={[
            {
              menuItem: "Summary",
              render: () => (
                <div className="row">
                  <Suspense fallback={<div>Loading...</div>}>
                    <SumaryDetail
                      rolId={this.props._rolId}
                      steps={this.state.steps}
                      userid={this.props._user}
                      cancelar={this.cancelar}
                      actualiza={this.cancelar}
                      poInfo={this.state.poInfo}
                      isMobil={isMobil}
                      getPO={this.getPurchaseOrder}
                    />
                  </Suspense>
                </div>
              ),
            },
            {
              menuItem: "Comments",
              render: () => (
                // <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <ContainerWrapper>
                    <Comments data={commentsData} catalogs={users} />
                  </ContainerWrapper>
                </Suspense>

                // </div>
              ),
            },
            {
              menuItem: "Documents",
              render: () => (
                <Suspense fallback={<div>Loading...</div>}>
                  <ContainerWrapper>
                    <Document
                      _rolId={this.props._rolId}
                      data={this.state.info}
                      id={this.state.info.ServiceOrderId}
                      _user={this.props._user}
                      isMobil={isMobil}
                    ></Document>{" "}
                  </ContainerWrapper>{" "}
                </Suspense>
              ),
            },
          ]}
        />
      </ContainerWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.header.clients,
});
const mapDispatchToProps = () => ({});
export default (
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseOrderDetail))
);
