import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
/**
 * MAIN STYLES
 */
import './styles/bootstrap.scss';
import './styles/app.scss';
import './styles.scss';
import './Vendor';
import './routeStyles.scss';
import './Globals';
import './styles.scss';
// import 'semantic-ui-css/semantic.min.css';

ReactDOM.render((
    <App/>
  // <Router history={browserHistory}>
  //   <Route path="/" component={App} />
  //   <Route path="/dashboard" component={Home} >
  //     <IndexRoute component={Dashboard}></IndexRoute>
  // !    <Route path="/order-create" component={OrderCreate} /> 
  // !    <Route path="/order-edit/:POId" component={PODraft} />
  // !    <Route path="/order-list" component={OrdersList}/>
  // !    <Route path="/cargo-readiness-confirmation/:POId" component={CargoReadinessConfirmation}/>
  // !    <Route path="/pending-activities" component={PendingActivitiesOrder}/>
  // !    <Route path="/po-confirmation/:POId" component={POConfirmationLogged}/>
  // !    <Route path="/po-detail-view/:id" component={POViewDetail}/>
  // *    <Route path="/order-create" component={OrderCreate}/>
  // !    <Route path="/booking-create" component={Booking}/>
  // !    <Route path="/booking-list" component={ListBooking}/>
  // !    <Route path="/shipment-sumary" component={Shipment}/>
  // !    <Route path="/tracking/:id" component={Tracking}/>
  // !    <Route path="/reporting" component={Reporting}></Route>
  // !    <Route path="/warehousing" component={Warehousing}></Route>

  // !    <Route path="/rates" component={Rates}></Route>
  // !    <Route path="/cost-management" component={Invoice}></Route>
  // !    <Route path="/cost-purchasing" component={Purchase}></Route>
  // !    <Route path="/cost-summary" component={ Summary }></Route>
  // !    <Route path="/customer-service" component={CustomerService}></Route>

  // !    <Route path="/search/:id" component={QuickSearch}/>

  // *    <Route path="*" component={NotFound} />
  //   </Route>
  // !  <Route path="/poconfirmation/:POId" component={POConfirmation}/>
  // !  <Route path="/booking-confirmation/:id" component={BookingConfirmation}/>
  //   <Route path="*" component={NotFound} />
  // </Router>
), document.getElementById('root'));
serviceWorker.unregister();