import React, { Component } from 'react';
import ContentWrapper from '../../../template/Layout/ContentWrapper';
import { Input, Row, Col, FormGroup, Card, CardBody } from 'reactstrap';
// DateTimePicker
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// ? styles
import './styles/main.scss';

import { FormModel, ModelData, Cost } from './models/FiltersModel';
import { FilterHelper } from './helpers/HelperFilter';

import DataTable from './DataTable';
import DATA from './json';
import Filters from './Filters';
import Graphics from './graphics/graphics';
import IMAGEN from './imagen64/file'


class CostManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ModelData: ModelData,
      form: '',
      dataCost: Cost,
      data: Cost.data,
      columns: [
        { key: "invoice", label: "Invoice" },
        { key: "invoiceDate", label: "Invoice date" },
        { key: "supplier", label: "Suppplier" },
        { key: "invoiceAmount", label: "Invoice Amount" },
        { key: "currency", label: "Currency" },
        { key: "cruise", label: "Cruise" },
        { key: "purchaseOrder", label: "Purchase Order" },
        { key: "poAmount", label: "PO Amount" }
      ]
    };
  }

  /*=============================================
  =            Handle Change Input Data         =
  =============================================*/

  OnChangeInputData = evt => {
    let { ModelData, dataCost } = this.state;
    let id = evt.target.id;
    let value = evt.target.value;

    ModelData[id] = value;

    this.setState({ ModelData }, () => {
      let { invoice, purchase, supplier, cruise } = this.state.ModelData;
      if (invoice || purchase || supplier || cruise) {
        this.setState({ data: FilterHelper(ModelData, dataCost) }, () => {
          this.formCreate();
        });
      } else {
        this.setState({ data: Cost.data }, () => {
          this.formCreate();
        });
      }

    });
  };

  /*=============================================
  =            Function for formCreate          =
  =============================================*/
  formCreate = () => {
    let { ModelData } = this.state;
    let form = FormModel.form.map((e, key) => {
      if (e.type === 'Input')
        return (
          <Col md={6} xl={6} key={key}>
            <Input
              type={e.type}
              name={e.name}
              id={e.id}
              placeholder={e.placeholder}
              onChange={this.OnChangeInputData}
              value={ModelData[e.id]}
              className={e.class}
            />
          </Col>
        );
      else if (e.type === 'Date')
        return (
          <Col md={6} xl={6} key={key}>
            <Datetime type={e.type} name={e.name} id={e.id} value={ModelData['date'][e.id]} className={e.class}></Datetime>
          </Col>
        );

    });

    this.setState({ form });
  };


  /*=============================================
  =            LifeCycle            =
  =============================================*/
  componentDidMount = () => {
    this.formCreate();
  }

  /*=====  End of LifeCycle  ======*/
  refreshTable = (results) => {
    this.setState({
      data: results
    })
  }


  render() {
    let { form } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div className="row">
            <em className="fas icon-chart fa-1x mr-2"></em>
            Cost Management
          </div>
        </div>
        <Card className="myCard">
          <CardBody>
            <Col md={12} xl={12}>
              <Row>
                <Col md={12} xl={12}>
                  <div className="row">
                    <img className="imggggg" src={IMAGEN} />
                    <span className="Invoicing" >Invoicing</span>
                    <br></br>
                  </div>
                </Col>
                <Col md={8} xl={8}>
                  <Graphics data={this.state.data}></Graphics>
                </Col>
                <Col md={4} xl={4}>
                  <center><h4 className="mb-3 py-2">Filters</h4></center>
                  <Row>
                    {form}
                    <Col md={12} xl={12}>
                      <Filters refreshTable={this.refreshTable} data={this.state.data}></Filters>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <br></br><br></br>
            <DataTable columns={this.state.columns} data={this.state.data}></DataTable>
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export default CostManagement;