import React, { Component } from "react";
import "./global.css";
import "./App.scss";
import $ from "jquery";
import "./Vendor";
import "./Globals";
import "./resources/fontello/css/myicon.css";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import { Provider } from "react-redux";
import store from "./store";
import "antd/dist/antd.css";

/**
 * Middlewares
 */

import WrapperRoute from "./components/Middlewares/WrapperRoute";

/**
 * Module Component
 */
import LoginComponent from "./components/poseidon/Login/";

/**
 * PO MODULE
 */
import OrderCreate from "./components/poseidon/Order/order-create";
import PODraft from "./components/poseidon/Order/order-po-draft";
import OrdersList from "./components/poseidon/Order/order-list";
import POViewDetail from "./components/poseidon/Order/order-po-detail-view";
import POConfirmation from "./components/poseidon/Order/order-po-confirmation";
import POConfirmationLogged from "./components/poseidon/Order/order-po-confirmation/confirmationFormLogged";
import PendingActivitiesOrder from "./components/poseidon/Order/order-pending-activities";
import CargoReadinessConfirmation from "./components/poseidon/Order/order-cargo-readiness-confirmation";
import BookingConfirmation from "./components/poseidon/Booking/booking-confirmation/";
import Admin from "./components/poseidon/Admin";
import Alerts from "./components/poseidon/Alerts";
import MultiUpdate from "./components/poseidon/Order/Multiple-Update";
import Template from "./components/poseidon/Template";
// import MyAnalitics from './components/poseidon/Reporting&Analytics/MyAnalitics';
import ContainerComponent from "./components/poseidon/ContainerManagement";
import AssociationEntites from "./components/poseidon/AssociationEntities";
import Managment from "./components/poseidon/POManagment";
import ManagmentDetail from "./components/poseidon/POManagment/info";

/**
 * BOOKING MODULE
 */
import Booking from "./components/poseidon/Booking/booking-create";
import ListBooking from "./components/poseidon/Booking/booking-list";
/**
 * DASHBOARD MODULE
 */
import Dashboard from "./components/poseidon/Dashboard";
/**
 * TRACKING MODULE
 */
// import Tracking from './components/poseidon/TrackTracking';
import Tracking from "./components/poseidon/Shipment/tracking-summary";
import Shipment from "./components/poseidon/Shipment/shipment-sumary";
import ContainerDetail from "./components/poseidon/Shipment/tracking-summary/components/container";
/**
 * COST MANEGEMENT MODULE
 */

import Purchase from "./components/poseidon/CostManagement/cm-purchase";
import Summary from "./components/poseidon/CostManagement/cm-summary";
import Invoice from "./components/poseidon/CostManagement/cm-invoice";
/**
 * HOME MODULE
 */
// import Home from './components/poseidon/Home/';
/**
 * REPORTING & ANALITICS MODULE
 */
import Reporting from "./components/poseidon/Reporting&Analytics";
import CreateReport from "./components/poseidon/Reporting&Analytics/create";
import EditReport from "./components/poseidon/Reporting&Analytics/create";
/**
 * RATES MODULE
 */
import Rates from "./components/poseidon/Rates";
/**
 * CONTROL TOWER WEEKLY MODULE
 */
import WeeklyPlanning from "./components/poseidon/ControlTower/WeeklyPlanning";
import WeeklyPlanningDetail from "./components/poseidon/ControlTower/WeeklyPlanning/bookingDetail";
/**
 * CONTROL TOWER CALENDAR MODULE
 */
import CalendarView from "./components/poseidon/ControlTower/CalendarView";
/**
 * CONTROL TOWER CAPACITY SET UP
 */
import CapacitySetUp from "./components/poseidon/ControlTower/CapacitySetUp";

/**
 * WAREHOUSING
 */
import Warehousing from "./components/poseidon/Warehousing";
/**
 * CUSTOMER SERVICE
 */
// import ServiceDesk from './components/poseidon/CustomerService';
/**
 * SOME MODULES
 */
import NotFound from "./components/template/pages/notFound";

import QuickSearch from "./components/poseidon/QuickSearch";
import Procedures from "./components/poseidon/Procedures";
import QuoteService from "./components/poseidon/QuoteService";

import ServiceDesk from "./components/poseidon/servicedeskmodule";
import TicketDetails from "./components/poseidon/servicedeskmodule/app/TicketDetails";
import Almacen from "./components/poseidon/Storage";
import StorageEntriesDetail from "./components/poseidon/Storage/Entries";
import StorageOfficesDetail from "./components/poseidon/Storage/Offices";
import traceabilityCustomsComponent from "./components/poseidon/Customs/";
import CustomViewDetail from "./components/poseidon/Customs/components/CustomImports/Import-detail-view";
import DtaViewDetail from "./components/poseidon/Customs/components/CustomDta/Dta-detail-view";
import ExportViewDetail from "./components/poseidon/Customs/components/CustomsExports/Exports-detail-view";
import inventayDetail from "./components/poseidon/Storage/inventayDetail";
import Gerencial from "./components/poseidon/Reporting&Analytics/MyAnalitics/gerencial";

/* Vendor Management */
import VendorManagement from "./components/poseidon/VendorManagement";
import vendorDetail from "./components/poseidon/VendorManagement/vendor_detail";

// ? RSS Feeds
import RSSFedds from "./components/poseidon/RSSFeeds";

$.ajaxPrefilter((o) => (o.async = true));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history} basename={"/"}>
          <Switch>
            <Route exact path="/" component={LoginComponent} />
            <Route
              exact
              path="/dashboard"
              render={() => (
                <WrapperRoute
                  name={"dashboard"}
                  component={Dashboard}
                ></WrapperRoute>
              )}
            />

            {/* // ? Order create router */}
            <Route
              exact
              path="/order-create"
              render={() => (
                <WrapperRoute
                  name={"order_create"}
                  component={OrderCreate}
                ></WrapperRoute>
              )}
            />

            <Route
              exact
              path="/order-list"
              render={() => (
                <WrapperRoute
                  name={"order_list"}
                  component={OrdersList}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/pending-activities"
              render={() => (
                <WrapperRoute
                  name={"pending_activities"}
                  component={PendingActivitiesOrder}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/booking-create"
              render={() => (
                <WrapperRoute
                  name={"booking_create"}
                  component={Booking}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/booking-list"
              render={() => (
                <WrapperRoute
                  name={"booking_list"}
                  component={ListBooking}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/shipment/summary/"
              render={() => (
                <WrapperRoute
                  name={"shipment_summary"}
                  component={Shipment}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/reporting"
              render={() => (
                <WrapperRoute
                  name={"reporting"}
                  component={Reporting}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/reporting/create"
              render={() => (
                <WrapperRoute
                  name={"createReport"}
                  component={CreateReport}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/warehousing"
              render={() => (
                <WrapperRoute
                  name={"warehousing"}
                  component={Warehousing}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/rates"
              render={() => (
                <WrapperRoute name={"rates"} component={Rates}></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/cost-management"
              render={() => (
                <WrapperRoute
                  name={"invoice"}
                  component={Invoice}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/cost-purchasing"
              render={() => (
                <WrapperRoute
                  name={"purchase"}
                  component={Purchase}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/cost-summary"
              render={() => (
                <WrapperRoute
                  name={"summary"}
                  component={Summary}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/ServicesDesk"
              render={() => (
                <WrapperRoute
                  name={"customer_service"}
                  component={ServiceDesk}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/admin"
              render={() => (
                <WrapperRoute name={"admin"} component={Admin}></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/alerts"
              render={() => (
                <WrapperRoute name={"alerts"} component={Alerts}></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/templates"
              render={() => (
                <WrapperRoute
                  name={"templates"}
                  component={Template}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/procedures"
              render={() => (
                <WrapperRoute
                  name={"procedures"}
                  component={Procedures}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/weekly-planning"
              render={() => (
                <WrapperRoute
                  name={"weeklyPlanning"}
                  component={WeeklyPlanning}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/calendar-view"
              render={() => (
                <WrapperRoute
                  name={"calendarView"}
                  component={CalendarView}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/capacity-set-up"
              render={() => (
                <WrapperRoute
                  name={"capacity_set_up"}
                  component={CapacitySetUp}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/visual-analytics"
              render={() => (
                <WrapperRoute
                  name={"misAnaliticos"}
                  component={Gerencial}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/multiple-update"
              render={() => (
                <WrapperRoute
                  name={"multiple_update"}
                  component={MultiUpdate}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/quote-service"
              render={() => (
                <WrapperRoute
                  name={"quote_service"}
                  component={QuoteService}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/Service-Desk"
              render={() => (
                <WrapperRoute name={""} component={ServiceDesk}></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/Container"
              render={() => (
                <WrapperRoute
                  name={""}
                  component={ContainerComponent}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/storage"
              render={() => (
                <WrapperRoute
                  name={"Almacen"}
                  component={Almacen}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/customs"
              render={() => (
                <WrapperRoute
                  name={"Aduana"}
                  component={traceabilityCustomsComponent}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/inventaryDetail"
              render={() => (
                <WrapperRoute
                  name={"Inventarios"}
                  component={inventayDetail}
                ></WrapperRoute>
              )}
            ></Route>

            <Route
              exact
              path="/entity-association"
              render={() => (
                <WrapperRoute
                  name={"AssociationEntities"}
                  component={AssociationEntites}
                ></WrapperRoute>
              )}
            ></Route>

            <Route
              exact
              path="/management"
              render={() => (
                <WrapperRoute
                  name={"PO Managment"}
                  component={Managment}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/rss-feeds"
              render={() => (
                <WrapperRoute
                  name={"RSSFeeds"}
                  component={RSSFedds}
                ></WrapperRoute>
              )}
            />

            {/* Routes with params */}
            <Route
              exact
              path="/management/:id"
              render={() => (
                <WrapperRoute
                  name={"PO Managment Detail"}
                  component={ManagmentDetail}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/order-edit/:POId"
              render={() => (
                <WrapperRoute
                  name={"order_edit"}
                  component={PODraft}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/po-confirmation/:POId"
              render={() => (
                <WrapperRoute
                  name={"po_confirmation"}
                  component={POConfirmationLogged}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/tracking/:id"
              render={() => (
                <WrapperRoute
                  name={"tracking"}
                  component={Tracking}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/poconfirmation/:POId"
              render={() => (
                <WrapperRoute
                  name={"po_confirmation"}
                  component={POConfirmation}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/search/:id"
              render={() => (
                <WrapperRoute
                  name={"quick_search"}
                  component={QuickSearch}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/booking-confirmation/:id"
              render={() => (
                <WrapperRoute
                  name={"booking_confirmation"}
                  component={BookingConfirmation}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/po-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"po_detail"}
                  component={POViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/container-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"containerDetail"}
                  component={ContainerDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/cargo-readiness-confirmation/:POId"
              render={() => (
                <WrapperRoute
                  name={"cargo_readiness"}
                  component={CargoReadinessConfirmation}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/weekly-planning/:id"
              render={() => (
                <WrapperRoute
                  name={"weeklyPlanningDetail"}
                  component={WeeklyPlanningDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/reporting/edit/:id"
              render={() => (
                <WrapperRoute
                  name={"editReport"}
                  component={EditReport}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/Detail/:id"
              render={() => (
                <WrapperRoute
                  name={"shipmentsDetail"}
                  component={TicketDetails}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/storage-entries-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"storageEntriesDetail"}
                  component={StorageEntriesDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/storage-offices-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"storageOfficesDetail"}
                  component={StorageOfficesDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/custom-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"customDetail"}
                  component={CustomViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/dta-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"dtaDetail"}
                  component={DtaViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/exports-detail-view/:id"
              render={() => (
                <WrapperRoute
                  name={"exportDetail"}
                  component={ExportViewDetail}
                ></WrapperRoute>
              )}
            />
            <Route
              exact
              path="/vendor-management"
              render={() => (
                <WrapperRoute
                  name={"vendor-management"}
                  component={VendorManagement}
                ></WrapperRoute>
              )}
            ></Route>
            <Route
              exact
              path="/vendor-detail/:id"
              render={() => (
                <WrapperRoute
                  name={"vendorDetail"}
                  component={vendorDetail}
                ></WrapperRoute>
              )}
            />

            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}
export default App;
