import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import EasyPieChart from 'easy-pie-chart';
import './styles/styles.scss';
import IMAGENBUDG from "./file"

class Budget extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalBudget: 0,
            totalTotal: 0,
            totalPOAmount: 0,
            totalLogisticsCost: 0,
            purchasing: 0,
            logistics: 0,
            performanceTotal: 0,
            performancePurchasing: 0,
            performanceLogistics: 0,
            chart1 : '',
            chart2 : '',
            chart3 : ''
        }
    }

    componentDidMount = () => {
        let orderFillment = {
            animate: {
                duration: 800,
                enabled: true
            },
            barColor: '#23b7e5',
            trackColor: 'rgba(200,200,200,0.4)',
            scaleColor: false,
            lineWidth: 10,
            lineCap: 'round',
            size: 145
        };
        let chart1 = new EasyPieChart(this.refs.easypie, orderFillment);
        let chart2 = new EasyPieChart(this.refs.easypie2, orderFillment);
        let chart3 =  new EasyPieChart(this.refs.easypie3, orderFillment);
        document.getElementById('body').className = 'nothing';
        this.setState({
            data: this.props.data,
            chart1,
            chart2,
            chart3
        }, () => {
            this.build()
        })
    }



    build = () => {
        let { data } = this.state

        let totalBudget = 0
        let totalTotal = 0
        let totalPOAmount = 0
        let totalLogisticsCost = 0
        let purchasing = 0
        let logistics = 0
        let performanceTotal = 0
        let performancePurchasing = 0
        let performanceLogistics = 0


        data.forEach((e) => {
            totalBudget += parseInt(e.budget)
            totalTotal += parseInt(e.total)
            totalPOAmount += parseInt(e.poAmount)
            totalLogisticsCost += parseInt(e.logisticCost)
        })

        purchasing = totalBudget * 0.70
        logistics = totalBudget * 0.30


        performanceTotal = parseInt((totalTotal / totalBudget) * 100)
        performancePurchasing = parseInt((totalPOAmount / purchasing) * 100)
        performanceLogistics = parseInt((totalLogisticsCost / logistics) * 100)



        this.setState({

            totalBudget,
            totalTotal,
            totalPOAmount,
            totalLogisticsCost,
            purchasing,
            logistics,
            performanceTotal,
            performancePurchasing,
            performanceLogistics
        }, () => {
            this.state.chart1.update(performanceTotal)
            this.state.chart2.update(performancePurchasing)
            this.state.chart3.update(performanceLogistics)
        })




    }

    componentWillReceiveProps = (nextProps) => {
        this.setState({
            data: nextProps.data
        }, () => this.build())
    }


    render() {
        return (
            <Card>
                <CardBody>
                    <div className="row _budgetPerformance">
                        <img className="imggggg" src={IMAGENBUDG} alt="img" />
                        <span><h4 className="mb-2 py-2">Budget Performance</h4></span>
                    </div>

                    <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col-3">
                            <center><span style={{ fontWeight: 'bold' }}>Total</span></center>
                        </div>
                        <div className="col-3">
                            <center><span style={{ fontWeight: 'bold' }}>Purchasing</span></center>

                        </div>
                        <div className="col-3">
                            <center><span style={{ fontWeight: 'bold' }}>Logistics</span></center>
                        </div>
                        {/* Budget totals */}
                        <div className="col-3">
                            <center><span style={{ fontWeight: 'bold' }}>Budget</span></center>
                        </div>

                        <div className="col-3">
                            <center><span> $ {this.state.totalBudget ? this.state.totalBudget.toLocaleString('en-US') : 0}</span></center>
                        </div>

                        <div className="col-3">
                            <center><span> $ {this.state.purchasing ? this.state.purchasing.toLocaleString('en-US') : 0}</span></center>
                        </div>

                        <div className="col-3">
                            <center><span> $ {this.state.logistics ? this.state.logistics.toLocaleString('en-US') : 0} </span></center>
                        </div>
                        {/* Spend totals */}

                        <div className="col-3">
                            <center><span style={{ fontWeight: 'bold' }}>Actual Spending</span></center>
                        </div>

                        <div className="col-3">
                            <center><span> $ {this.state.totalTotal ? this.state.totalTotal.toLocaleString('en-US') : 0}</span></center>
                        </div>

                        <div className="col-3">
                            <center><span> $ {this.state.totalPOAmount ? this.state.totalPOAmount.toLocaleString('en-US') : 0}</span></center>
                        </div>

                        <div className="col-3">
                            <center><span> $ {this.state.totalLogisticsCost ? this.state.totalLogisticsCost.toLocaleString('en-US') : 0}</span></center>
                        </div>

                        {/* Spend totals */}
                        <div className="col-3" style={{ paddingTop: '6%' }}>
                            <center><span style={{ fontWeight: 'bold' }}>Performance</span></center>
                        </div>
                        <div className="col-3">
                            <center>
                                <div className="text-center py-2">
                                    <div ref="easypie" data-percent="0" className="easypie-chart easypie-chart-lg">
                                        <span>{this.state.performanceTotal}%</span>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="col-3">
                            <center>
                                <div className="text-center py-2">
                                    <div ref="easypie2" data-percent="0" className="easypie-chart easypie-chart-lg">
                                        <span>{this.state.performancePurchasing}%</span>
                                    </div>
                                </div>
                            </center>
                        </div>
                        <div className="col-3">
                            <center>
                                <div className="text-center py-2">
                                    <div ref="easypie3" data-percent="0" className="easypie-chart easypie-chart-lg">
                                        <span>{this.state.performanceLogistics}%</span>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default Budget