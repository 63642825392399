import React from 'react'
import Data from '../../../Data/Config'
import { Card, CardBody } from 'reactstrap'


/*=============================================
=             Confirmation Data Component     =
=============================================*/

function ConfirmationDataComponent(props){
  let data = props.data;
  let rows = Data.config.map((e) => {
    return (
        <div className={e.className} style={{ paddingTop: '1%' }}>
            <div className="row">
                <div className="col-12">
                    <span style={{ color: '#9e9e9e', fontWeight: 'bold' }} >
                        {e.label}
                    </span>
                </div>
                <div className="col-12">
                    <span>{data[e.id] ? data[e.id] : ''}</span>
                </div>

            </div>
        </div>
    )
  });
  return (
      <Card>
        <CardBody>
            <h4 className="mb-3 py-2"><em className="fas fa-dolly-flatbed mr-2"></em>Purchase Order Confirmation</h4>
            <div className="row">
                {rows}
            </div>
        </CardBody>
      </Card>
  )
}

export default ConfirmationDataComponent;