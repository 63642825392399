import React, { Component } from 'react';
import restService from '../../../../../../services/restService';
import { Button, Card, CardBody } from 'reactstrap';
import swal from 'sweetalert';
import Table from '../../../../../template/Table'
import Errors from '../../../../../template/Errors'
const rest = new restService();
class FormExtended extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            data: [],
            mainMessage: 'Do you want to atach the following PO(s) to a new booking? ',
            selected: [],
            newSelected: [],
            stringSelected: '',
            columns: [
                { label: "PO No", key: 'PONumber' },
                { label: "Supplier", key: 'SupplierName' },
                { label: "Consignee", key: 'ConsigneeName' },
                { label: "Creation Date", key: 'POCreateDate' },
                { label: "Promise Cargo Ready", key: 'PromiseCargoReadyDate' },
                { label: "Status", key: 'StatusDesc' },
            ],
            error: {
                open: false,
                message: '',
                type: 'error'
            }
        };
    }

    componentDidMount = () => {
        this.setState({
            selected: this.props.selected
        }, () => this.getOrdersList())

    }
    componentWillReceiveProps = (nextProps) => {
        this.setState({
            selected: nextProps.selected
        }, () => this.getOrdersList())
    }


    attachOrders = () => {
        const { selected, newSelected, mainMessage, stringSelected } = this.state
        newSelected.forEach((e) => {
            selected.push(e)
        })
        swal({
            title: mainMessage,
            text: stringSelected,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((yeah) => {
            if (yeah) {
                this.props.sendSelected(selected)
                this.setState({
                    error: {
                        message: "A PO has been add correctly",
                        open: true,
                        type: 'warning'
                    }
                })
            }
        });
    }
    continueWithOutAttachOrders = () => {
        let { selected } = this.state
        this.props.sendSelected(selected)
    }
    recieve = array => {
        let stringSelected = ''
        this.setState({
            newSelected: array
        }, () => {
            this.state.newSelected.forEach((e) => stringSelected += ` ${e.PONumber},`)
            this.setState({
                stringSelected: stringSelected.substring(0, stringSelected.length - 1)
            })
        })
    }
    getOrdersList = () => rest.EXEC({
        _function: 'GetOrdersList',
        params: '?type=bookingCreate'
    }, fetch)
        .then(success => {
            this.setState({ orders: success.data }, () => {
                this.QuitSelected()
            })
        })
        .catch(error => { })

    QuitSelected = () => {
        let { orders, selected } = this.state
        let data = []
        orders.forEach((order) => {
            let here = 0
            selected.forEach((select) => {
                if (order.PONumber === select.PONumber)
                    here = 1
            })
            if (here === 0)
                data.push(order)
        })
        this.setState({
            data
        })
    }


    render() {
        const { columns, data } = this.state
        return (
            <Card>
                <CardBody>
                    <div className="row">
                        <div className="col-12">
                            <Table
                                columns={columns}
                                data={data}
                                itemsByPage={10}
                                options={
                                    {
                                        pagination: true,
                                        search: false,
                                        downloads: {
                                            xlsx: false,
                                            pdf: false
                                        },
                                        selectItems: true
                                    }
                                }
                                onSelect={this.recieve}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-8 col-sm-12"></div>
                        <div className="col-md-3 col-lg-2 col-sm-6">
                            <Button variant="contained" color="danger" className="button" size="lg" onClick={this.continueWithOutAttachOrders}>
                                Cancel
                                    </Button>
                        </div>
                        <div className="col-md-3 col-lg-2 col-sm-6">
                            <Button variant="contained" color="primary" className="button" onClick={this.attachOrders} size="lg">
                                Attach PO(s) to Booking
                                    </Button>
                        </div>
                    </div>
                    <Errors open={this.state.error.open} type={this.state.error.type} message={this.state.error.message} setProps={this.recieveProps} ></Errors>
                </CardBody>
            </Card>
        );
    }
}
export default FormExtended