import { useState, useEffect, useCallback } from "react";
import { poDetailCatalogsResponse } from "../../../../mocks/poDetail";
// import RepositoryFactory from "../../../../../../../repositories/RepositoryFactory";

export const useCatalogs = (lang) => {
  // const PurchaseOrderClient = RepositoryFactory.get("PurchaseOrder");
  const [incotermCatalog, setIncotermCatalog] = useState([]);
  const [uomCatalog, setUOMCatalog] = useState([]);
  const [transportModeCatalog, setTransportModeCatalog] = useState([]);
  const [uomCatalogO, setUOMCatalogO] = useState([]);
  const [uomCatalogV, setUOMCatalogV] = useState([]);
  const [uomCatalogT, setUOMCatalogT] = useState([]);
  const [uomCatalogW, setUOMCatalogW] = useState([]);
  const [uomCatalogQ, setUOMCatalogQ] = useState([]);
  const [uomCatalogD, setUOMCatalogD] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getCatalogs = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      // const response = await PurchaseOrderClient.getCatalogs(lang);
      // const { incoterms, uom, transportModes, uomO, uomV, uomW, uomQ, uomD, uomT} = response.data;
      const { incoterms, uom, transportModes, uomO, uomV, uomW, uomQ, uomD, uomT } = poDetailCatalogsResponse
      setIncotermCatalog(incoterms);
      setUOMCatalog(uom);
      setTransportModeCatalog(transportModes);
      setUOMCatalogO(uomO || []);
      setUOMCatalogV(uomV || []);
      setUOMCatalogT(uomT || []);
      setUOMCatalogW(uomW || []);
      setUOMCatalogQ(uomQ || []);
      setUOMCatalogD(uomD || []);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setError(error);
      setLoading(false);
    }
  }, [lang]);

  useEffect(() => {
    getCatalogs();
  }, [getCatalogs, lang]);

  return {
    incotermCatalog,
    uomCatalog,
    uomCatalogD,
    uomCatalogO,
    uomCatalogQ,
    uomCatalogT,
    uomCatalogV,
    uomCatalogW,
    transportModeCatalog,
    loading,
    error,
  };
};
