import React from 'react';
import { Button, Col, Row} from 'reactstrap';
import {Popup} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Popover from './../../../Global/Popup/';
import {IMAGE, IconChat, IconNewComment} from './components/img';
import './styles.scss';

const mapColor = {
  12: 'danger',
  13: 'secondary'
};

/*=============================================
=            order List Comments              =
=============================================*/

export const columns = [
  {label: '', minWidth: 120, launch: 'trigger', custom: (value, triggerFn) => (
    <Col md={12} xl={12}>
      <Row>
        <Col md={8}>
          <React.Fragment>
            <Popup
              className="_comments_icon"
              mouseEnterDelay={1500}
              trigger={<div className='container_icon_comment' onClick={() => {triggerFn({POId: value.POId, PONumber: value.PONumber});}}>
                {
                  value.NewComments ? 
                    <IconNewComment></IconNewComment>
                    :
                    <IconChat></IconChat>
                }
              </div>}
              content='Comments'
              position='top center'
              size='mini'
              inverted
            >
            </Popup>
          </React.Fragment>
        </Col>        
        <Col md={4}>
          {value.RequiresAttention === '1' ?
            <React.Fragment>
              <Popup
                className="_comments_icon"
                trigger={<div><img height="22px" width="22px" src={IMAGE} alt="img"/></div>}
                content='Orders that require attention'
                position='top center'
                size='mini'
                inverted
              >
              </Popup>
            </React.Fragment>
            : ''}
        </Col>
      </Row>
    </Col>    
  )},
  { label: 'PO No', key: 'PONumber', minWidth: 80, custom: (value) => (
    <Col md={12} xl={12}>
      <Row>
        <Col md={12}>
          <Link to={'/po-detail-view/' + value.POId}>{value.PONumber}</Link>
        </Col>
      </Row>
    </Col>    
  )},
  { label: 'Split No', key: 'SplitNo', minWidth: 80},
  { label: 'Status', key: 'StatusDesc', minWidth: 80, custom: (value) => ( <Button className={value.StatusId === 13 ? 'btn-oval _gray_button': 'btn-oval'} color={mapColor[value.StatusId] ? mapColor[value.StatusId] : 'primary'} onClick={() =>{}}>{value.StatusDesc}</Button> ) },
  { label: 'Supplier', key: 'SupplierName', minWidth: 180},
  { label: 'Consignee', key: 'ConsigneeName', minWidth: 180},
  { label: 'Ship to', key: 'DeliveryName', minWidth: 180},
  { label: 'Distributor PO', key: 'DistributorPO', minWidth: 100},
  { label: 'Customer Container ID', key: 'CustomerContainerID', minWidth: 150},
  { label: 'Container No.', key: 'ContainerNumber', minWidth: 150, custom: (value) => (
    <div>
      <Popover title="Container No." content={value.ContainerNumber} >
        <p>{ value.ContainerNumber }</p>
      </Popover>
    </div>
  )},
  { label: 'Shipment Number.', key: 'ShipmentNumber', minWidth: 100},
  { label: 'Est. Arrival at Warehouse', key: 'EstimatedArrivalConsolWH', minWidth: 100},
  { label: 'Project', key: 'ProjectName', minWidth: 100},
  { label: 'PO Creation', key: 'POCreateDate', minWidth: 100},
  { label: 'Promise Cargo Ready', key: 'PromiseCargoReadyDate', minWidth: 100},
  { label: 'Actual Cargo Ready', key: 'ActualCargoReadyDate', minWidth: 100},
  { label: 'Actual Cargo Pickup', key: 'ActualCargoPickup', minWidth: 100},
  { label: 'Requested Ex Works Date', key: 'RequestedExWorksDate', minWidth: 100},
  { label: 'Required at Destination', key: 'RequiredDestination', minWidth: 150},
  { label: 'Total Qty Requested', key: 'TotalQtyOrdered', minWidth: 100},
  { label: 'Qty Confirmed', key: 'TotalQtyConfirmed', minWidth: 100},
  { label: 'Transport', key: 'TransportMode', minWidth: 100},
  { label: 'Last Contact Date', key: 'LastContactDate', minWidth: 100}
];

export const CloseIcon = () => (
  <svg height="24px" id="Layer_1_close" version="1.1" viewBox="0 0 90 90" width="24px"  xmlns="http://www.w3.org/2000/svg">
    <circle className="st73" cx="45" cy="45" r="45"/><g><g><rect className="st7" height="50.9116898" transform="matrix(0.7071068 -0.7071068 0.7071068 0.7071068 -19.3467178 45.2928925)" width="7.999836" x="41.0000801" y="20.5441551"/></g></g><g><g><rect className="st7" height="7.999836" transform="matrix(0.7071678 -0.7070457 0.7070457 0.7071678 -19.3466549 45.2873383)" width="50.9116898" x="19.5441551" y="42.0000801"/></g></g>
  </svg>
);

export const TrashIcon = () => (
  <svg enable-background="new 0 0 500 500" height="18px" id="Layer_1_trash" version="1.1" viewBox="0 0 500 500" width="18px">
    <path clip-rule="evenodd" d="M170,43h23v46h-23V43z" fill="#EF4823" fill-rule="evenodd"/><path clip-rule="evenodd" d="M308,43h23v46h-23V43z" fill="#EF4823" fill-rule="evenodd"/>
    <path clip-rule="evenodd" d="M170,20h161v23H170V20z" fill="#EF4823" fill-rule="evenodd"/><path clip-rule="evenodd" d="M78,66h345v69H78V66z" fill="#EF4823" fill-rule="evenodd"/>
    <path clip-rule="evenodd" d="M101,89v391h299V89H101z M193,411h-23V135h23V411z M262,411h-23  V135h23V411z M331,411h-23V135h23V411z" fill="#EF4823" fill-rule="evenodd"/>
  </svg>
);

