import React, { Component } from 'react';
import Authentication from './../../../../../../services/Authentication';
import restService from '../../../../../../services/restService';
import { Button, Card, CardBody, Col, Row} from 'reactstrap';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import Table from '../../../../../template/Table';
import {Services} from './../../../Services';
import Axios from 'axios';
import Loader from './../../../../../Global/TableLoader';
import Skeleton from '@material-ui/lab/Skeleton';

import './styles.scss';
const auth = new Authentication();
const rest = new restService();


class FormExtended extends Component {
    state = {
      orders: [],
      id: '',
      mainMessage: 'Do you want to atach the following PO(s) to a new booking? ',
      selected: [],
      disabled: true,
      stringSelected: '',
      columns: [
        { label: 'PO No', key: 'PONumber' },
        { label: 'Split No.', key: 'SplitNo'},
        { label: 'Supplier', key: 'SupplierName' },
        { label: 'Consignee', key: 'ConsigneeName' },
        { label: 'Customer Container ID', key: 'CustomerContainerID'},
        { label: 'Creation Date', key: 'POCreateDate' },
        { label: 'Promise Cargo Ready', key: 'PromiseCargoReadyDate' },
        { label: 'Status', key: 'StatusDesc' },
      ],
      loading: true
    }

    componentDidMount = () => {
      let {id} = this.state;
      id  = auth.getAuthentication('customerSelect');
      this.setState({id});
      this.getOrdersList();
    }
    
    /*=============================================
    =            Handle Consol Shipment ID        =
    =============================================*/
    
    handleConsoleShipment = () => {
      const {selected, id} = this.state;
      Swal.fire({
        title: 'Please insert the Shipment No. to consolidate:',
        input: 'text',
        type: 'question',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonColor: '#20c554',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
        preConfirm: (shipmentNo) => {
          // return Axios.get(`http://localhost:3017/booking/verify/shipment/${shipmentNo}`)
          return Axios.get(`${Services.BOOKING.path}booking/verify/shipment/${shipmentNo}`)
            .then(response => {
              if (response.data.success){
                return {resp: response.data, shipmentNo: shipmentNo};
              } else {
                Swal.showValidationMessage(`The inserted Shipment No. ${shipmentNo} was not found 
                as an Existing Shipment. Please check and try again.`);
              }
            }).catch(() => {
              Swal.showValidationMessage(`The inserted Shipment No. ${shipmentNo} was not found 
            as an Existing Shipment. Please check and try again.`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.value && result.value.resp.success) {
          Swal.fire({
            title: '',
            text: `The Shipment No. ${result.value.shipmentNo} was found as an Existing 
            Shipment, Do you want to consolidate the selected POs?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#20c554',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            preConfirm: () => {
              // return Axios.post('http://localhost:3017/booking/procedure', {
              return Axios.post(`${Services.BOOKING.path}/booking/procedure`, {
                WPSShipmentId: result.value.shipmentNo,
                idCustomer: id,
                POs: selected,
                type: result.value.resp.procedure
              }).then(response => {
                if (response.data && response.data.founded === true){
                  return {shipmentNo: result.value.shipmentNo, success: true, founded: true};  
                } else {
                  return {shipmentNo: result.value.shipmentNo, success: true, founded: false};  
                }
              }).catch(() => {
                return {shipmentNo: result.value.shipmentNo, success: false};
              });
              
            }
          }).then((result) => {
            if (result.value && result.value.founded === true){
              Swal.fire(
                '',
                `The Shipment No. ${result.value.shipmentNo} is already created in
                  Poseidon could not be created again. Please
                  retry with other Shipment or contact your system administrator.`,
                'error'
              );
              // this.getOrdersList();
            } else if (result.value && result.value.founded === false){
              Swal.fire(
                '',
                `Consolidation Completed for Shipment No. ${result.value.shipmentNo}.`,
                'success'
              );
              this.getOrdersList();
            } else if (result.value && result.value.success === false){
              Swal.fire({
                title: '',
                text: `Consolidation Error for Shipment No. ${result.value.shipmentNo}.
                Please retry the operation. If this condition persists, 
                contact your system administrator.`,
                type: 'error',
                confirmButtonColor: '#20c554',
              });
            }
          });
        }
      });
    };

    attachOrders = () => {
      const { selected, mainMessage, stringSelected } = this.state;
      swal({
        title: mainMessage,
        text: stringSelected,
        icon: 'warning',
        buttons: true,
      }).then((yeah) => {
        if (yeah) this.props.sendSelected(selected);
      });
    }

    continueWithOutAttachOrders = () => this.props.sendSelected([])
    
    /*=============================================
    =     Handle check selected inside the table  =
    =============================================*/
    
    recieve = array => {
      let {disabled} = this.state;
      if (array.length > 0) disabled = false;
      else disabled = true;
      let stringSelected = '';
      this.setState({
        selected: array,
        disabled
      }, () => {
        this.state.selected.forEach((e) => stringSelected += ` ${e.PONumber},`);
        this.setState({
          stringSelected: stringSelected.substring(0, stringSelected.length - 1)
        });
      });
    }
    
    /*=============================================
    =            Get OrderList                    =
    =============================================*/

    getOrdersList = () => {
      const id = auth.getAuthentication('customerSelect');
      rest.EXEC({
        _function: 'GetOrdersList',
        params: `?type=bookingCreate&id=${id}`
      }, fetch).then(success => this.setState({ orders: success.data, loading: false })).catch(() => { this.setState({loading: false});});}

    render() {
      const { orders, columns, disabled, loading} = this.state;
      return (
        <Card className="myCard">
          <CardBody>
            <div className="row">
              <div className="col-12">
                {
                  loading ? 
                    <Loader/>:
                    <Table
                      columns={columns}
                      data={orders}
                      itemsByPage={10}
                      options={
                        {
                          pagination: true,
                          search: true,
                          downloads: {
                            xlsx: false,
                            pdf: false
                          },
                          selectItems: true
                        }
                      }
                      onSelect={this.recieve}
                    />
                }
              </div>
            </div>
            <Row>
              <Col md={2} lg={4} sm={12}></Col>
              <Col md={3} lg={3} sm={4}>
                {loading ? 
                  <Skeleton variant="rect" width={'30%'} height={40} />
                  :
                  <Button variant="contained" disabled={disabled} className="button btn_shipment" size="lg" onClick={this.handleConsoleShipment}>
                    Consol to Existing Shipment
                  </Button>
                }
              </Col>
              <Col md={3} lg={3} sm={4}>
                {loading ?
                  <Skeleton variant="rect" width={'30%'} height={40} />
                  :
                  <Button variant="contained" color="success" className="button" size="lg" onClick={this.continueWithOutAttachOrders}>
                    Continue without attaching PO(s)
                  </Button>                        
                }
              </Col>
              <Col md={3} lg={2} sm={4}>
                {loading ? 
                  <Skeleton variant="rect" width={'30%'} height={40} />
                  :
                  <Button variant="contained" color="primary" className="button" onClick={this.attachOrders} size="lg">
                    Attach PO(s) to Booking
                  </Button>
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    }
}
export default FormExtended;