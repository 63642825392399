export const Services = {
   "CATALOGS": {
      "path": "https://magicdome-api-catalogs.devsandbox.magicanalytics.app/"
   },
   "ORDER": {
      "path": "https://magicdome-api-order.devsandbox.magicanalytics.app/"
   },
   "EVENTS": {
      "path": "https://magicdome-api-events.devsandbox.magicanalytics.app/"
   },
   "DIRECTORY": {
      "path": "https://magicdome-api-directory.devsandbox.magicanalytics.app/"
   },
   "COMMENTS": {
      "path": "https://magicdome-api-comments.devsandbox.magicanalytics.app/"
   },
   "REPORT": {
      "path": "https://magicdome-api-reports.devsandbox.magicanalytics.app/"
   },
   "SHIPMENTS": {
      "path": "https://magicdome-api-shipments.devsandbox.magicanalytics.app/"
   },
   "FILES": {
      "path": "https://magicdome-api-files.devsandbox.magicanalytics.app/"
   }
}