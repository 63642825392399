import {SET_NEW_GROUP_LIST} from './../../actions/types';

const initialState = {
  group_list: sessionStorage.getItem('group_list') ? JSON.parse(sessionStorage.getItem('group_list')): [],
  clients: sessionStorage.getItem('clients') ? JSON.parse(sessionStorage.getItem('clients')): [],
  nit: sessionStorage.getItem('nit') ? JSON.parse(sessionStorage.getItem('nit')): [],
};

export default (state = initialState, action) => {
  switch(action.type){
  case SET_NEW_GROUP_LIST:
    return {
      group_list: action.payload.group_list,
      clients: action.payload.clients,
      nit: action.payload.nit
    };
  default:
    return state;
  }
};