const adminForm = {
  AddTemplates: [
    {
      type: "Input",
      label: "Customer",
      class: "col-md-3",
      placeHolder: "",
      name: "Customer",
      id: "Customer",
      style: {},
      required: true,
    },
    {
      type: "Input",
      label: "Primary Color",
      class: "col-md-3",
      placeHolder: "",
      name: "PrimaryColor",
      id: "PrimaryColor",
      style: {},
      required: true,
    },
    {
      type: "Input",
      label: "Secondary Color",
      name: "SecondaryColor",
      class: "col-md-3",
      id: "SecondaryColor",
      style: {},
      required: true,
    },
    {
      type: "Input",
      label: "Hover Color",
      name: "HoverColor",
      class: "col-md-3",
      id: "HoverColor",
      style: {},
      required: true,
    },
  ],
};
export default adminForm;
