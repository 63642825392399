const data = [
    {
        country : "Italy",
        poCount :"84",
        poAmount:"785400"
    },
    {
        country : "China",
        poCount :"51",
        poAmount:"275950"
    },
    {
        country : "Spain",
        poCount :"20",
        poAmount:"120431"
    },{
        country : "India",
        poCount :"12",
        poAmount:"64368"
    }
]

export default data;