import React from 'react';
import propTypes from 'prop-types';
import { Form, Icon, Popup, TextArea } from 'semantic-ui-react';
import { Col } from 'reactstrap';
import './styles.scss';

const severityColors = {
  'Critical Impact': 'red',
  'Moderate Impact': 'orange',
  'Minimal Impact': 'yellow'
}

function errorValidate(item) {
  let content = false;
  content = item['valid'] ? false : true;
  // console.log('validacion', content);
  return content;
}

function handleRequired(Model, id) {
  let required = false;
  if (Model && Model[id] && Model[id].required) {
    required = true;
  }
  return required;
}

function FormCreator({ dataForm, onChange, Catalog, Model, type = 'Model', placeholders, inptIconHandler, style }) {
  const FORM_ITEMS = dataForm.map((item, key) => {
    if (item.type === 'combo')
      return (
        <div key={key} className={item.col + ' ' + item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup className={'tooltip-inner'} content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Form size='tiny'>
            <Form.Select
              id={item.id}
              name={item.name}
              size='small'
              clearable
              onChange={(evt, data) => { onChange(evt, data, type); }}
              fluid
              search
              required={handleRequired(Model, item.id)}
              // defaultValue={Model && Model[item.id].value ? Model[item.id].value: false }
              value={Model && Model[item.id].value ? Model[item.id].value : ''}
              options={Catalog && Catalog[item.id] ? Catalog[item.id] : []}
              placeholder={placeholders ? placeholders[item.id] : item.placeholder}
              disabled={item.disabled}
              error={Model && Model[item.id].valid ? false : true}
            />
          </Form>
        </div>
      );
    else if (item.type === 'text' || item.type === 'password')
      return (
        <div key={key} className={item.col + ' ' + item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className='input__label'>
            {item.description ? <Popup content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Form size='tiny'>
            <Form.Input
              id={item.id}
              required={handleRequired(Model, item.id)}
              icon={item.iconAction ? <Icon id={`icon-password-${item.id}`} name={item.iconAction} onClick={() => inptIconHandler(item.id)} link></Icon> : item.icon ? <Icon id={`icon-password-${item.id}`} style={{ cursor: 'pointer' }} name={item.icon}></Icon> : ''}
              name={item.name}
              type={item.type}
              size='small'
              onChange={(evt, data) => { onChange(evt, data, type); }}
              fluid
              placeholder={placeholders ? placeholders[item.id] : item.placeholder || (Model && Model[item.id] ? Model[item.id].placeholder : '')}
              disabled={item.disabled}
              // error={Model && Model[item.id].valid ? false : true}
              // error={{ content: 'Please enter your first name' }}
              error={errorValidate(Model && Model[item.id] ? Model[item.id] : undefined)}
              value={Model && Model[item.id] && Model[item.id].value ? Model[item.id].value : ''}
            />
          </Form>
        </div>
      );
    else if (item.type === 'text-area')
      return (
        <div key={key} className={item.col + ' ' + item.class}>
          {/* <Form> */}
          <label className='input__label'>
            {item.description ? <Popup content={item.description} trigger={<Icon name='info circle' size='small' color='green' />} /> : ''}
            {`${item.label}`}{handleRequired(Model, item.id) ? <em style={{ color: 'red' }}>*</em> : ''}
          </label>
          <Form>
            <TextArea
              id={item.id}
              placeholder={placeholders ? placeholders[item.id] : item.placeholder || (Model && Model[item.id] ? Model[item.id].placeholder : '')}
              onChange={(evt, data) => { onChange(evt, data, type); }}
            />
          </Form>
        </div>
      );
    else if (item.type === 'tag')
      return (
        // <Col key={key} md={item.colm} xl={item.colm} className={item.class} className='d-flex align-items-center'>
        //   {
        //     Model[item.id].value ?
        //       <div>
        //         <Label as='a' color={severityColors[Model[item.id].value]} tag>
        //           {Model && Model[item.id] && Model[item.id].value ? Model[item.id].value : ''}
        //         </Label>
        //       </div> : ''
        //   }
        // </Col>
        <div key={key} className={item.col + ' ' + item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          {
            Model[item.id].value ?
              <div>
                <label className='input__label'>{item.label}</label>
                <u style={{ color: `${severityColors[Model[item.id].value]}` }}><p className='_value_'>{Model && Model[item.id].label ? Model[item.id].label : Model && Model[item.id].value ? Model[item.id].value : ''}</p></u>
              </div>
              : ''
          }
        </div>
      );
    else if (item.type === 'info')
      return (
        <div key={key} className={item.col + ' ' + item.class} hidden={Model && Model[item.id].hidden === true ? true : false}>
          <label className={style ? style.tagSubTitle : 'input__label'}>{item.label}</label>
          <p className={style ? style.tagValue : '_value_'}>{Model && Model[item.id].label ? Model[item.id].label : Model && Model[item.id].value ? Model[item.id].value : ''}</p>
        </div>
      );
    else return '';
  });
  return (
    <React.Fragment>
      {FORM_ITEMS}
    </React.Fragment>
  );
};

FormCreator.propTypes = {
  dataForm: propTypes.array,
  Catalog: propTypes.object,
  config: propTypes.object,
  inptIconHandler: propTypes.func,
  Model: propTypes.object,
  onChange: propTypes.func,
  placeholders: propTypes.object,
  type: propTypes.string,
};

FormCreator.defaultProps = {
  dataForm: [],
  Catalog: {},
  config: undefined,
  inptIconHandler: () => { },
  Model: {},
  onChange: () => { },
  placeholder: undefined,
  // type: '---'
};

export default FormCreator;