import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { Row, Table, Col, CardBody } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import { Pagination } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import { saveAs } from "file-saver";
import Search from '../Search';
import TableHeader from './Components/Header';
import TableBody from './Components/TableBody';
import Axios from "axios";
import { Services } from '../../Services';
import { Sort } from './handlers';
import './styles.scss';
const moment = require('moment');

const colors = {
  1: '#487629',
  2: '#487629',
  3: '#487629',
  4: '#487629',
  5: '#487629',
  6: 'rgb(102, 187, 106)',
  7: 'rgb(102, 187, 106)',
};

/*=============================================
=            Login Handler For Table          =
=============================================*/

function init({ search_text, total_pages, active_page, order_by, order_direction, rows, rows_search, selected_items }) {
  return {
    search_text,
    total_pages,
    active_page,
    order_by,
    order_direction,
    rows,
    rows_search,
    selected_items
  };
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_rows':
      return {
        ...state,
        rows: action.payload
      };
    case 'search':
      return {
        ...state,
        search_text: action.payload.search_text
      };
    case 'set_selected':
      return {
        ...state,
        selected_items: [...action.payload]
      };
    case 'set_order':
      return {
        ...state,
        order_by: action.payload.order_by,
        order_direction: action.payload.order_direction
      };
    case 'reset_pager':
      return {
        ...state,
        active_page: action.payload.active_page,
        total_pages: action.payload.total_pages
      };
    case 'active_page':
      return {
        ...state,
        active_page: action.payload
      };
    case 'reset_state':
      return init(action.payload);
    default:
      return state;
  }
}

/*=====  End of Login Handler For Table  ======*/

function calculatePages(total, itemsPerPage) {
  const total_pages = total / itemsPerPage;
  return total_pages % 1 === 0 ? total_pages : Math.ceil(total_pages);
}

/*=============================================
=            Main Component                  =
=============================================*/
function TableMainComponent(props) {
  // console.log('props', props);
  const [loaderExcel, setLoaderExcel] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    rows: [...props.data],
    rows_search: [...props.data],
    search_text: '',
    total_pages: calculatePages(props.data.length, props.itemsPerPage),
    active_page: 1,
    order_by: '',
    order_direction: 'desc',
    selected_items: []
  }, init);

  function onSelecteItem(evt, item_no) {
    // console.log('this are the selected items --->', state.selected_items);
    const selectedIndex = state.selected_items.indexOf(item_no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected_items, item_no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected_items.slice(1));
    } else if (selectedIndex === state.selected_items.length - 1) {
      newSelected = newSelected.concat(state.selected_items.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.selected_items.slice(0, selectedIndex),
        state.selected_items.slice(selectedIndex + 1),
      );
    }
    dispatch({ type: 'set_selected', payload: newSelected });
    props.handleSelection(newSelected);
  }

  function isSelected(line_no) {
    return state.selected_items.indexOf(line_no) !== -1;
  }

  function handleChangePage(evt, { activePage }) {
    dispatch({ type: 'active_page', payload: activePage });
  }
  function resetPager(totalPages, activePage) {
    dispatch({ type: 'reset_pager', payload: { active_page: activePage, total_pages: calculatePages(totalPages, props.itemsPerPage) } });
  }

  function sortData(key, direction) {
    dispatch({ type: 'set_order', payload: { order_by: key, order_direction: direction } });
    dispatch({ type: 'set_rows', payload: Sort([...state.rows], key, direction) });
  }
  // ! maybe deprecated funcion
  function handleItemsPerPage() {
    // return totalItems > props.itemsPerPage ? 1: 0;
    return 1;
  }
  function handleOnSearch(data) {
    if (state.order_by) {
      dispatch({ type: 'set_rows', payload: [...Sort([...data], state.order_by, state.order_direction)] });
      resetPager(data.length, handleItemsPerPage());
    } else {
      dispatch({ type: 'set_rows', payload: [...data] });
      resetPager(data.length, handleItemsPerPage());
    }
  }

  const generateExcel = useCallback(async () => {
    console.log(props.headers);
    let headers = props.headers.headers
    let keys = props.headers.keys
    setLoaderExcel(true)
    // console.log('data-->', state.rows.length);
    const dataxls = {
      data: state.rows,
      options: {
        keys,
        headers,
        image: {
          name: props.nameProject,
        }
      },
    }

    const url = `${Services.REPORT_SERVICE.path}/report/dynamic/xlsx`;
    try {
      if (state.rows.length >= 1) {
        const getXlsxFile = await Axios.post(url, dataxls);
        const fileName = `Tickets-${moment(new Date()).format('DDMMYYYY')}.xlsx`
        const linkSource = "data:application/xlsx;base64," + getXlsxFile.data.base64;
        saveAs(linkSource, fileName);
        setLoaderExcel(false)
      } else {
        setLoaderExcel(false)
      }

    } catch (error) {
      console.log('The Error-->', error);
    }
  })

  React.useEffect(() => {
    dispatch({
      type: 'reset_state',
      payload: {
        rows: [...props.data],
        rows_search: [...props.data],
        search_text: '',
        total_pages: calculatePages(props.data.length, props.itemsPerPage),
        active_page: 1,
        order_by: '',
        order_direction: 'desc',
        selected_items: []
      }
    });
  }, [props.data, props.itemsPerPage]);

  return (
    <>
      <Row>
        <CardBody>
          <div className='row'>
            <div className='col-xs-10 col-md-6 col-lg-3'>
              {/* <div style={{ alignItems: "center", display: "inline-flex" }}> */}

              {props.options.search ?
                <Search data={state.rows_search} handler={handleOnSearch} configSearch={props.configSearch} width={'100%'}></Search>
                : ''}
              {/* </div> */}
            </div>
            <div className='col-xs-2 col-md-6 col-lg-9 d-flex align-items-center'>
              {
                props.options && props.options.xlsx ?
                  <i style={{ color: colors[props.options.project], cursor: 'pointer' }} onClick={generateExcel} disabled={loaderExcel} className={loaderExcel ? `fas fa-sync fa-spin fa-2x` : `fas fa-file-excel fa-2x`} title='Descargar Excel' />
                  : ''
              }
            </div>
          </div>
        </CardBody>
      </Row >
      <div className="col-12" style={{ paddingTop: '1%' }}></div>
      <div className={`${props.fixed ? 'zui-scroller' : 'col-12'}`}>
        <Table id="_table_overflow" responsive className={`zui-table ${props.type === 'GENERIC' ? 'table-height table table-sticky table-bordered' : ''}`}>
          <thead style={{ textAlign: 'center', }}>
            <tr style={{ cursor: 'pointer' }}>
              <TableHeader
                columns={props.columns}
                selectItems={props.options && props.options.selectItems}
                handleSort={sortData}
                sortKey={state.order_by}
                direction={state.order_direction}
                style={props.class}
              />
            </tr>
          </thead>
          <tbody className="table-body">
            <TableBody
              rows={state.rows}
              columns={props.columns}
              selectItems={props.options && props.options.selectItems}
              keyCheck={props.keyCheck}
              onSelectItem={onSelecteItem}
              isSelected={isSelected}
              page={state.active_page - 1}
              rowsPerPage={props.itemsPerPage}
              cellType={props.cellType}
              style={props.class}
            />
          </tbody>
        </Table>
      </div>
      {
        props.options && props.options.pagination ?
          <div className="col-12">
            <hr />
          </div> : ''
      }
      <div className="col-12" style={{ textAlign: 'center' }}>
        {
          props.options && props.options.pagination ?
            <Pagination
              ellipsisItem={null}
              activePage={state.active_page}
              onPageChange={handleChangePage}
              totalPages={state.total_pages}
              size={"mini"}
            >
            </Pagination>
            : ''
        }
      </div>
      {
        props.options && props.options.pagination ?
          <div className="col-12">
            <hr />
          </div> : ''
      }
    </>
  );
}

TableMainComponent.propTypes = {
  cellType: propTypes.oneOf(['tick-rows', 'thin-rows']),
  data: propTypes.array,
  fixed: propTypes.bool,
  type: propTypes.string,
  itemsPerPage: propTypes.number,
  keyCheck: propTypes.string,
  handleSelection: propTypes.func
};
TableMainComponent.defaultProps = {
  cellType: 'thick-rows',
  data: [],
  fixed: false,
  type: '',
  itemsPerPage: 10,
  keyCheck: '',
  handleSelection: () => { }
};

export default TableMainComponent;
