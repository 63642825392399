import React, { Component } from 'react';
import { Icon, Label, Search, Grid } from 'semantic-ui-react';
import './styles/cards.scss';
import ShipmentCard from './card';
import {searchBy} from './search';
import { Pagination} from 'semantic-ui-react';
import { Row, Card, CardBody, Col} from 'reactstrap';
import Modal from '../../../../../template/Modal/AdvancedSearch';
import IMG from '../../../../../../resources/filter.png';
import {FaCardExcel} from './../xlsxDownload/';
import { Services } from '../../../Services';


const headers = ['Shipment No.', 'Transport Mode', 'Status', 'Supplier', 'Consignee', 'Project', 'PO', 'Container', 'From', 'To', 'ETD', 'ETA', 'ATD', 'ATA'];
const keys = [ 'ShipmentNumber', 'TransportMode', 'Status', 'Supplier', 'Consignee', 'Project', 'PONumber', 'Containers', 'From', 'To', 'ETD', 'ETA', 'ATD', 'ATA'];

const FormatValues = (data) =>{
  if(Array.isArray(data)){
    let Shipments = [];
    for (let index = 0; index < data.length; index++) {
      const shipment = data[index];
      Shipments.push({
        ShipmentNumber: shipment.id ? shipment.id: '',
        TransportMode: shipment.TransportMode && shipment.TransportMode.id ? shipment.TransportMode.id: '',
        Status: shipment.Status && shipment.Status.label ? shipment.Status.label: '',
        Supplier: shipment.Supplier && shipment.Supplier.name ? shipment.Supplier.name: '',
        Consignee: shipment.Consignee && shipment.Consignee.name ? shipment.Consignee.name: '',
        Project: shipment.Project && shipment.Project.name ? shipment.Project.name: '',
        PONumber: shipment.PONumber ? shipment.PONumber: '',
        Containers: shipment.ContainersNum ? shipment.ContainersNum: '',
        From: shipment.From && shipment.From.Location && shipment.From.Location.country ? shipment.From.Location.country: '',
        To: shipment.To && shipment.To.Location && shipment.To.Location.country ? shipment.To.Location.country: '',
        ETD: shipment.ETD ? shipment.ETD: '',
        ETA: shipment.ETA ? shipment.ETA: '',
        ATD: shipment.ATD ? shipment.ATD: '',
        ATA: shipment.ATA ? shipment.ATA: ''
      });
    }
    return Shipments;
  } else {
    return [];
  }
};
class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cards: '',
      search: '',
      toggleDrawer: this.props.toggleDrawer,
      resetCards: this.props.reset,
      labelStatus: this.props.labelStatus,
      dataSearch: this.props.dataSearch,
      activePage : 0,
      totalPages : 0,
      reportValues: []
    };
  };

    componentDidMount = () => {
      this.setState({
        data: this.props.data,
        dataSearch: this.props.data,
        allMyData : this.props.data
      }, () => this.initPages());

    }
    componentWillReceiveProps = (nextProps) => {
      if (nextProps.data) this.setState({ data: nextProps.data, dataSearch: nextProps.data,allMyData : nextProps.data }, () => { this.initPages(); });
      if (nextProps.labelStatus)
        this.setState({ labelStatus: nextProps.labelStatus }, () => {
          this.buildCards();
          try {
            let selectedFileDoom = document.getElementById(nextProps.labelStatus);
            selectedFileDoom.classList.add('_cardSelected');
          } catch (e) {
          }
        });
    }


    initPages = ()=>{        
      let pages = parseInt(Math.ceil(this.state.dataSearch.length / 5));
      if (pages ===  0) pages=1;
      this.setState({
        activePage : 1,
        totalPages : pages
      },()=>{
        this.buildPageResults();
      });
    }

    buildPageResults = ()=>{
      let {activePage,totalPages} = this.state;
      let data = this.setResultsByPage(activePage,this.state.dataSearch,totalPages,5);
      this.setState({
        data,
      },()=>{
        this.buildCards();
      });

    }

    setResultsByPage = (page, all, pages, itemsByPage) => {
      let array = [];
      if (page === 1)
        for (let i = 0; i < (all.length < itemsByPage ? all.length : itemsByPage); i++)
          array.push(all[i]);
      else if (page !== 1 && page !== pages)
        for (let i = (page - 1) * itemsByPage; i < (page) * itemsByPage; i++)
          array.push(all[i]);
      else
        for (let i = (page - 1) * itemsByPage; i < all.length; i++)
          array.push(all[i]);
      return array;
    }

    buildCards = () => {
      let { data } = this.state;
      let reportValues = [];
      let tags = data.map((e) => {
        // console.log('value ===> shipment', e);
        reportValues.push({
          ShipmentNumber: e.id,
          TransportMode: e.TransportMode.name,
          Status: e.Status.label,
          Supplier: e.Supplier.name,
          Consignee: e.Consignee.name,
          Project: e.Project.name,
          PONumber: e.PONumber,
          Containers: e.ContainersNum,
          From: e.From.Location.country,
          To: e.From.Location.country,
          ETD: e.ETD,
          ETA: e.ETA,
          ATD: e.ATD,
          ATA: e.ATA
        });
        return (
          <div className="col-12" key={e.id} onClick={this.state.toggleDrawer(e)}>
            <ShipmentCard element={e} ></ShipmentCard>
          </div>
        );
      });
      this.setState({
        cards: '',
        reportValues
      }, () => this.setState({ cards: tags }));
    }

    handleChange = name => event => {
      let { allMyData } = this.state;
      this.setState({
        [name]: event.target.value
      }, () => {
        if (this.state.search === '') {
          this.setState({
            dataSearch : allMyData
          }, () => this.initPages());
        }
        else {
          let results = searchBy(this.state.search,allMyData);
          this.setState({
            dataSearch: results
          }, () => this.initPages());
        }
      });
    }

    changePage = page => {
      this.setState({
        activePage:page
      },()=>{
        this.buildPageResults();
      });
    }

    handlAdvancedSearch = (search ) =>{
      this.props.backRes(search);
      this.setState({isOpen: false});
    }

    toggleModal = () => {
      this.setState({ isOpen: !this.state.isOpen });
    };

    handleSearchChange = (e, i) => {
      this.setState({ search: i.value });
    };

    render() {
      let { resetCards, labelStatus,  activePage, totalPages, search, dataSearch} = this.state;
      return (
        <React.Fragment>
          <Row>
            <div className="col-12">
              <Card className="myCard">
                <CardBody>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12" >
                      <Grid>
                        <Grid.Row columns='three'>
                          <Grid.Column style={{marginLeft: '15px'}} width={6} >
                            <Search
                              input={{ icon: 'search', iconPosition: 'left', fluid: true }}
                              placeholder="..."
                              // onSearchChange={this.handleSearchChange}
                              value={search}
                              open={false}
                              name="search"
                              id="search"
                              onSearchChange={this.handleChange('search')}
                                            
                            />
                          </Grid.Column>
                          <Grid.Column style={{display: 'flex', alignItems: 'center',  margin: '0', padding: '0'}} width={4}>
                            <img width={22} height={22} src={IMG} alt='iconSearch' onClick={this.toggleModal} style={{cursor:'pointer'}}></img>
                          </Grid.Column>
                          <Grid.Column width={5}>
                            <FaCardExcel data={FormatValues(dataSearch)} url={`${Services.REPORT.path}reports/dinamic`} headers={headers} keys={keys}/>
                          </Grid.Column>
                        </Grid.Row>   
                      </Grid>
                    </div>
                  </div>
                  {labelStatus !== 'closed' ?
                    <div className="col-md-3 col-lg-3 col-sm-12" >
                      <br></br>
                      <Label as='a'>
                        {labelStatus}
                        <Icon name='delete' onClick={resetCards} />
                      </Label>
                    </div>
                    : ''}
                  <div className="col-md-12 col-lg-12 col-sm-12" ></div>
                </CardBody>
              </Card>
            </div>
            {this.state.cards}
            <Col md={12}>
              <center>
                <Pagination
                  className='pagination-table'
                  activePage = {activePage}
                  ellipsisItem={null}
                  totalPages={totalPages}
                  onPageChange = {(e, { activePage }) =>this.changePage(activePage)}/>
              </center>
            </Col>

            <Modal
              show={this.state.isOpen}
              onClose={this.toggleModal}
              name="ShipmentList"
              modalName="ui Modal body shipment list" 
              customer={this.props.customer}
              triggerParentupdate={this.handlAdvancedSearch}
              centered={true}
            />          
          </Row>
        </React.Fragment>        
      );
    }
}
export default Cards;


