import React from 'react';
import DialogList from '../SecComponents/dialogList';

const shipmentReference = {
  message: 'Please indicate any of the references:',
  optionList: [
    'HBL.',
    'Shipment Np.',
    'Copntainer No.'
  ],
};

function ShipomentOptions(props) {
  return (
    <div className="row" style={{ width: '100%' }}>
      <DialogList references={shipmentReference} />
    </div>
  );
}

export default ShipomentOptions;
