import React, { Component } from 'react';
import { Input,Button } from 'reactstrap';
import FormModel from './form'
import Fade from 'react-reveal/Fade';
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Form: FormModel,
            Comment : {},
            selects : {
                'COMMENTS': [],
                'COMMENT-TYPES': [],
            },
            Inputs : ''
        };
    }

    componentDidMount = () => {
        this.setState({
            id : this.props.id,
            selects : this.props.catalogs
        },()=>{

            this.buildForm()
        })
    }

    componentWillReceiveProps = (nextProps)=>{
        this.setState({
            id : nextProps.id,
            selects : this.props.catalogs
        },()=>{
            this.getCatalogs()
        })
    }

    getCatalogs = ()=>{
        this.setState({
            selects : this.props.catalogs
        },()=>this.buildForm())
    }

    handleChange = name => event => {
        let {Comment} = this.state
        Comment[name] = event.target.value
        this.setState({ Comment }, () => {
            this.buildForm()
        })
    }

    handleChangeSelect = (selected) => event =>  {
        let { Comment, Form,selects} = this.state;
        let ComTypeId = Form.find(e => e.id === 'ComTypeId')
        if (event && event.value) {
            if(selected === 'ModuleId'){
                let found = selects['COMMENTS'].find(e => e.value === event.value)
                if(found){
                    selects['COMMENT-TYPES'] = found.childs
                    ComTypeId.show = true
                }
            }
            Comment[selected] = event.value;
            this.setState({ Comment,Form }, () => {
                this.buildForm()
            })
        } else {
            if(selected === 'ModuleId'){
                selects['COMMENT-TYPES'] = []
                ComTypeId.show = false
            }
            Comment[selected] = '';
            this.setState({ Comment,Form }, () => {
                this.buildForm()
            })
        }
    };

    handleChangeDate = name => event => {
        let {Comment} = this.state
        try {
            Comment[name] = event.format("YYYY-MM-DD")
            document.getElementsByClassName("form-control")[0].setAttribute('class', 'form-control');
            this.setState({ Comment }, () => {
                this.buildForm()
            })
        }
        catch (ex) {
            Comment[name] = ""
            this.setState({ Comment }
            )
        }
    }

    buildForm = () => {
        const { Comment,selects,Form} = this.state
        let newComment = Comment;
        let newSelects = selects;
        newComment.ModuleId = Number(this.props.type) + 1;
        newSelects['COMMENT-TYPES'] = selects
        this.setState({ selects: newSelects, Comment: newComment });
        let Inputs = Form.map((e, i) => {
            if (e.type === 'Input' && e.show) return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite> <span id={e.label} style={e.style}><label>{e.label} {e.required === true ? <span>*</span> : ""}</label></span></Fade>
                    <Input
                        id={e.id}
                        required
                        type={e.datatype}
                        placeholder={e.placeHolder}
                        name={e.id}
                        value={Comment[e.id]}
                        maxlength={e.maxlength}
                        minlength={e.minlength}
                        onChange={this.handleChange(e.id)}
                        max={e.max} />
                    <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
                </div>
            )
            else if (e.type === "Select" && e.show) return (
                <div className={e.class} key={e.id}>
                    <Fade left opposite><span id={e.label} style={e.style}>  <label >{e.label} {e.required === true ? <span>*</span> : ""} </label></span></Fade>
                    <Select
                        id={e.id}
                        required
                        type={e.datatype}
                        name={e.name}
                        value={Comment[e.id]}
                        onChange={this.handleChangeSelect(e.id)}
                        options={selects[e.options]}
                    />
                  <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
                </div>)
            else if (e.type === "Datetime" && e.show) return (
                <div className={e.class} key={e.id} >
                    <Fade left opposite>
                        <span id={e.label} style={e.style}> <label  >{e.label} {e.required === true ? <span>*</span> : ""} </label></span></Fade>
                    <Datetime
                        required
                        type={e.datatype}
                        dateFormat={'YYYY MM DD'}
                        inputProps={{ className: 'form-control', id: e.id }}
                        value={Comment[e.id]}
                        onChange={this.handleChangeDate(e.id)} />
                        <Fade left opposite><label  style={e.style} >{e.error}</label></Fade>
                </div>
            )
            else if (e.type === 'textarea') return (
                <div className={e.class} key={e.id}>
                    <label >{e.label}</label>
                    <Input
                        required
                        type="textarea"
                        name={e.id}
                        id={e.id}
                        value={Comment[e.id]}
                        onChange={this.handleChange(e.id)}
                    >
                    </Input>
                </div>
            )
            else{
              return(<></>)
            }
        })
        this.setState({
            Inputs
        })
    }
    CreateNewComment = evt =>{

    }

    render() {
        return (
            <>
            <div className ="row">
                {this.state.Inputs}
            </div>
            <div className="row">
                <div className="col-12">
                    <hr></hr>
                </div>
                <div className="col-md-6 col-lg-8 col-sm-12"></div>
                <div className="col-md-3 col-lg-1 col-sm-6">
                    <Button variant="contained" color="primary" className="button" onClick={this.CreateNewComment} size="lg">
                        Save and complete
                    </Button>
                </div>
            </div>
        </>
        );
    }
}

export default Tracking;
