export const Services = {
   "CATALOGS": {
      "path": "https://magicdomeapicatalog.azurewebsites.net/"
   },
   "ORDER": {
      "path": "https://magicdomeapiorder.azurewebsites.net/"
   },
   "EVENTS": {
      "path": "https://magicdomeapievents.azurewebsites.net/"
   },
   "DIRECTORY": {
      "path": "https://magicdomeapidirectory.azurewebsites.net/"
   },
   "COMMENTS": {
      "path": "https://magicdomeapicomments.azurewebsites.net/"
   },
   "REPORT": {
      "path": "https://magicdomeapireports.azurewebsites.net/"
   },
   "SHIPMENTS": {
      "path": "https://magicdomeapishipments.azurewebsites.net/"
   },
   "FILES": {
      "path": "https://magicdomeapifiles.azurewebsites.net/"
   }
}