const Forms = {
    MAIN:[
        {
            type: 'Select',
            options: 'CountryOfOrigin',
            label: 'Country Of Origin',
            class: 'col-md-12',
            name: 'CountryOfOrigin',
            id: 'CountryOfOrigin',
            datatype: 'select',
            show: true
        },
        {
            type: 'Select',
            options: 'ConsolidationLocation',
            label: 'Consolidation Location',
            class: 'col-md-12',
            name: 'ConsolidationLocation',
            id: 'ConsolidationLocation',
            datatype: 'select',
            show: true
        },
       
    ]
   
}

export default Forms