import React, { Component } from "react";
import { FilePond, File, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import path from "path";
import { Button } from "reactstrap";
import FormModel from "./models/form";
import Fade from "react-reveal/Fade";
import { Autocomplete, TextField } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { Services } from "./../../../../../Services";
import { DocumentsCategoriesResponse } from "../../../../../mocks/poDetail";
// import RepositoryFactory from "../../../../../../../../repositories/RepositoryFactory";
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
// const rest = new RestService()
const filesAccepted = [
  "image/jpg",
  "image/png",
  "image/jpeg",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "text/xml",
];

const useStyles = (theme) => ({
  textfield: {
    "& .MuiInputBase-input.MuiAutocomplete-input": {
      fontSize: 12,
      color: "#2B3B57",
    },
  },
  datefield: {
    "& .MuiInput-input.MuiInputBase-input": {
      fontSize: 12,
      color: "#2B3B57",
    },
  },
  searchLabel: {
    fontSize: 12,
    color: "#2B3B57",
  },
  euroDateField: {
    "& .MuiCalendarPicker-root": {
      fontSize: 12,
      color: "#2B3B57",
    },
  },
});

// const DocumentsRepository = RepositoryFactory.get("Documents");
const maxSizeFile = 10000000;

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Form: FormModel,
      files: [],
      Document: {},
      id: 0,
      selects: {
        "DOC-CATEGORIES": [],
      },
      Inputs: "",
      disable: false,
    };
  }

  componentDidMount = () => {
    this.getCategoriescatalog();
    this.setState(
      {
        id: this.props.id,
      },
      () => {
        this.buildForm();
      }
    );
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState(
      {
        id: nextProps.id,
      },
      () => {
        this.buildForm();
      }
    );
  };

  getCategoriescatalog = async () => {
    // let categories = await DocumentsRepository.getCategories(10, this.props.lng);

    // if (categories) {
      // const response = categories.data;
      const response = DocumentsCategoriesResponse;
      console.log("response", response);
      const selectOptions = { "DOC-CATEGORIES": response };

      this.setState({ selects: selectOptions }, () => this.buildForm());
    // }
  };

  handleChange = (name) => (event) => {
    let { Document } = this.state;
    Document[name] = event.target.value;
    this.setState({ Document }, () => {
      this.buildForm();
    });
  };

  handleChangeSelect = (selected) => (_event, data) => {
    let { Document, Form } = this.state;

    if (data && data.value) {
      Document[selected] = data.value;
      this.setState({ Document, Form }, () => {
        this.buildForm();
      });
    } else {
      Document[selected] = "";
      this.setState({ Document, Form }, () => {
        this.buildForm();
      });
    }
  };

  handleChangeDate = (name) => (event) => {
    let { Document } = this.state;
    try {
      Document[name] = event.format("YYYY-MM-DD");
      this.setState({ Document }, () => {
        this.buildForm();
      });
    } catch (ex) {
      Document[name] = "";
      this.setState({ Document });
    }
  };

  buildForm = () => {
    const { Document, selects, Form } = this.state;

    const { classes } = this.props;
    let Inputs = Form.map((e, i) => {
      if (e.type === "Input" && e.show)
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              {" "}
              <span id={e.label} style={e.style}>
                <label>
                  {e.label}{" "}
                  {e.required === true ? <span>*</span> : ""}
                </label>
              </span>
            </Fade>

            <TextField
              // label={e.placeHolder}
              style={{ marginTop: "3px" }}
              id={e.id}
              title="Please complete this field."
              variant="standard"
              placeholder={e.placeHolder}
              type={e.datatype}
              name={e.id}
              value={Document[e.id]}
              maxlength={e.maxlength}
              minlength={e.minlength}
              InputProps={{
                classes: {
                  input: "input-euro",
                },
              }}
              onChange={this.handleChange(e.id)}
              max={e.max}
            />
            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else if (e.type === "Select" && e.show)
        return (
          <div className={e.class} key={e.id}>
            <Fade left opposite>
              <span id={e.label} style={e.style}>
                {" "}
                <label>
                  {e.label}{" "}
                  {e.required === true ? <span>*</span> : ""}{" "}
                </label>
              </span>
            </Fade>
            <Autocomplete
              closeText={false}
              openText={false}
              style={{ marginTop: "6px" }}
              id="size-small-standard"
              className="input-euro"
              size="small"
              // freeSolo
              ListboxProps={{
                sx: { fontSize: 12 },
              }}
              sx={{
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: 12,
                },
              }}
              options={selects[e.options] ? selects[e.options] : []}
              getOptionLabel={(option) => option.text}
              onChange={this.handleChangeSelect(e.id)}
              value={Document[e.id]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  className={classes.textfield}
                />
              )}
            />

            <Fade left opposite>
              <label style={e.style}>{e.error}</label>
            </Fade>
          </div>
        );
      else return "";
    });
    this.setState({
      Inputs,
    });
  };
  CreateNewComment = (evt) => {
    evt.preventDefault();
    this.props.callback();
    // let { Document, id } = this.state;
    // const formData = new FormData();
    // console.log("Document", Document);
    // if (Document.file && Document.Name && Document.CategoryId) {
    //   formData.append("file", Document.file);
    //   formData.append("Name", Document.Name);
    //   formData.append("DocCategoryId", parseInt(Document.CategoryId));
    //   formData.append("Type", path.extname(Document.file.name));
    //   formData.append("RowId", id);
    //   formData.append("ModuleId", 10);
    //   formData.append("UserId", this.props._user);
    //   let url = `${Services.ORDEN_SERV.path}orderDocument/upload/`;
    //   //let url = 'http://localhost:3014/upload/'
    //   this.setState({ disable: true });
    //   fetch(`${url}`, {
    //     method: "POST",
    //     body: formData,
    //   })
    //     .then((response) => response.json())
    //     .then((success) => {
    //       this.props.callback(success);
    //     })
    //     .catch((error) => {
    //       this.props.onError(error);
    //     });
    // }
  };

  onDrop = (fileItems) => {
    let { Document } = this.state;
    Document.file = fileItems.length > 0 ? fileItems[0].file : "";
    this.setState({ Document }, () => this.buildForm());
  };
  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <FilePond
              labelIdle="Select files or drag them here"
              acceptedFileTypes={filesAccepted}
              labelInvalidField="Invalid file format"
              labelFileTypeNotAllowed="Invalid file format"
              fileValidateTypeLabelExpectedTypes="Only images, .xml, .pdf or .docx are allowed"
              allowMultiple={false}
              allowFileSizeValidation={true}
              maxFileSize={"10MB"}
              labelMaxFileSizeExceeded="Only images, .xml, .pdf or .docx are allowed"
              labelMaxFileSize="File size exceeds the 10MB limit"
              fileValidateTypeDetectType={(source, type) => {
                this.setState({ disable: filesAccepted.indexOf(type) < 0 });
                console.log("type", type);
                return new Promise((resolve, reject) => {
                  // Do custom type detection here and return with promise
                  if (filesAccepted.indexOf(type) >= 0) {
                    resolve(type);
                  } else {
                    reject(type);
                  }
                });
              }}
              onupdatefiles={(fileItems) => {
                this.setState({ disable: true });
                if (
                  fileItems.length > 0 &&
                  fileItems[0].file.size <= maxSizeFile
                ) {
                  this.setState({ disable: false });
                }
                this.setState(
                  {
                    files: fileItems.map((fileItem) => fileItem.file),
                  },
                  () => {
                    this.onDrop(fileItems);
                  }
                );
              }}
            >
              {this.state.files.map((file) => (
                <File key={file} src={file} origin="local" />
              ))}
            </FilePond>
          </div>
        </div>
        <div className="row" style={{ marginTop: "5vh" }}>
          {this.state.Inputs}
        </div>
        <div className="row">
          <div className="col-12">
            <hr></hr>
          </div>
          <div className="col-12 flex-end">
            <Button
              disabled={this.state.disable}
              variant="contained"
              color="primary"
              className="button button-add-document"
              onClick={this.CreateNewComment}
              size="lg"
            >
              Save
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(Tracking);
