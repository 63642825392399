import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {red, blue} from '@material-ui/core/colors';
import { createMuiTheme, ThemeProvider, makeStyles} from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import propTypes from 'prop-types';



const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    secondary:{
      main: red[400]
    }
  },
});

const useStyles = makeStyles(theme => ({
  agreeButton:{
    // color: '#ffff !important'
    textTransform: 'none'
  },
  cancelButton: {
    textTransform: 'none'
  },
  actionButton: {
    textTransform: 'capitalize',
    margin: theme.spacing(1),
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize:'14px'
  },
  actionButtonMult: {
    textTransform: 'capitalize',
    margin: theme.spacing(1),
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize:'16px'
  }

}));


export default function AlertDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (evt) => {
    evt.stopPropagation();
    setOpen(true);
  };
  function onAgree(){
    handleClose();
    props.agree();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Button  disabled={props.disabled}
          size="small"
          variant="contained" 
          color={props.color} 
          className={ props.multi  ? classes.actionButtonMult : classes.actionButton}  
          onClick={handleClickOpen} 
          disableElevation 
          startIcon={props.multi ? <></> :<Icon>{props.icon}</Icon>}
        >
          {props.actionText}
        </Button>
      </ThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // PaperProps={{elevation: 0}}
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={theme}>
            <Button className={classes.agreeButton} onClick={handleClose} color="secondary" hidden={props.hiddenActions}>
              {props.cancelText}
            </Button>
          </ThemeProvider>

          <ThemeProvider theme={theme}>
            <Button className={classes.cancelButton} onClick={onAgree} color="primary" autoFocus hidden={props.hiddenActions}>
              {props.agreeText}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

AlertDialog.propTypes = {
  actionText: propTypes.string,
  agree: propTypes.func,
  agreeText: propTypes.string,
  cancelText: propTypes.string,
  color: propTypes.string,
  disabled: propTypes.bool,
  hiddenActions: propTypes.bool,
  message: propTypes.string,
  title: propTypes.string,

};

AlertDialog.defaultProps = {
  actionText: 'Action',
  agree: () => {},
  agreeText: '',
  cancelText: '',
  color: 'primary',
  disabled: true,
  hiddenActions: false,
  message: '',
  title: ''
};