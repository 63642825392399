import React from 'react';
import {Card,CardBody} from 'reactstrap';
import Table from '../../../../template/Table';

const rules = {
  'S1': [1],
  'S2': [1,2],
  'S3': [1,2,3],
  'S4': [1,2,3,4]
};

let mainColumns = [];
let columns = [
  // { label : 'Note', key : 'Note'},
  { label : 'Status', key : 'Status',custom  :(value)=>{
    if(value.Status && rules[value.Status]){
      let status = rules[value.Status].map(()=>{
        return(
          <em className="fa-1x fas fa-circle mr-2" style={{color:'green'}}></em>
        );
      });
      return <div style={{textAlign:'left'}}><em className="fa-2x far fa-comments mr-2"></em> {status}</div>;
    }
    else return '';
     
  }},
  { label:'ID' , key: 'IdMagazzino_Softnet' },
  { label:'Warehouse' , key: 'Warehouse' },
  { label:'Vessel' , key: 'Vessel_Imo' },
  { label:'Vessel Desc' , key: 'Vessel_Des' },
  { label:'Supplier' , key: 'Supplier' },
  { label:'Supplier_Piva' , key: 'Supplier_Piva' },
  { label:'Trucker' , key: 'Trucker' },
  { label:'Tracking' , key: 'Tracking' },
  { label:'PO_Group' , key: 'PO_Group' },
  { label:'PO Single' , key: 'PO_Single' },
  { label:'Invoice Date' , key: 'Invoice_Date' },
  { label:'Invoice Number' , key: 'Invoice_Number' },
  { label:'Amount' , key: 'Invoice_Date_Supplier' },
  { label:'Invoice Number Supplier' ,isLarge:true, key: 'Invoice_Number_Supplier' },
  { label:'Change Supplier' , key: 'Change_Supplier' },
  { label:'Amount Supplier' , key: 'Amount_Supplier' },
  { label:'Currency' , key: 'Currency' },
  { label:'DesImballo' , key: 'DesImballo' },
  { label:'Colli' , key: 'Colli' },
  { label:'Peso' , key: 'Peso' },
  { label:'Misura_L' , key: 'Misura_L' },
  { label:'Misura_W' , key: 'Misura_W' },
  { label:'Misura_H' , key: 'Misura_H' },
  { label:'Volume' , key: 'Volume' },
  { label:'Estimated Loading Date',isLarge:true, key: 'EstimatedLoadingDate' },
  { label:'Merchandise Readiness Date',isLarge:true,  key: 'Merchandise_Readiness_Date' },
  { label:'Actual Arrival Date Warehouse',isLarge:true,  key: 'ActualArrivalDateWarehouse' },
  { label:'Actual Departure Date Warehouse',isLarge:true,  key: 'ActualDepartureDateWarehouse' },
  { label:'Actual CargoReady Date',isLarge:true , key: 'ActualCargoReadyDate' },
  // { label:'Status' , key: 'Status' },
];


/*=============================================
=            Results Taable Component         =
=============================================*/

function ResultsComponent(props){
  let data = props.results;

  return (
    <Card className="myCard">
      <CardBody>
        <div className="row">
          <div className="col-12">
            <Table
              columns={columns}
              data={data}
              mainColumns = {mainColumns}
              bordered = {true}
              type = {'GENERIC'}

              options={{
                large:true,
                // pagination:true,
                downloads: {
                  pdf: false,
                  xlsx: true
                }
              }}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
export default ResultsComponent;


