const Forms = [
    {
        type: 'Input',
        label: 'name',
        class: 'col-md-6',
        placeHolder: '',
        name: 'Name',
        id: 'Name',
        style : {},
        required : true,
        show : true
    },
    {
        type: 'Select',
        options: 'DOC-CATEGORIES',
        label: 'category',
        class: 'col-md-6',
        name: 'CategoryId',
        id: 'CategoryId',
        datatype : 'select',
        show : true

    },

   
]

export default Forms