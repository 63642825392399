import { 
  SET_NEW_ORDERS, 
  SET_NEW_MESSAGES, 
  SET_CUSTOMER_COMMENTS, 
  SET_INTERGLOBO_COMMENTS,
  SET_ORDERS_AND_ATTENTION
} from './types';

/**
 *  Set New Orders
 * @param {*} data 
 */
export const SetNewOrders =  (data) =>  async dispatch => {
  try{
    dispatch({
      type: SET_NEW_ORDERS,
      payload: data
    });
  }catch(err){
    console.error(err);
  }
};
/**
 * Set NewMessages
 * @param {*} data 
 */
export const SetNewMessages =  (data) =>  async dispatch => {
  try{
    dispatch({
      type: SET_NEW_MESSAGES,
      payload: data
    });
  }catch(err){
    console.error(err);
  }
};
/**
 * Set New Interglobo Comments
 * @param {*} data 
 */
export const SetIntergloboComments = (data) => async dispatch => {
  try{
    dispatch({
      type: SET_INTERGLOBO_COMMENTS,
      payload: data
    });
  }catch(err){
    console.error('error SetRequireAttention1');
  }
};
/**
 * Set New Customer Comments
 * @param {*} data 
 */
export const SetCustomerComments = (data) => async dispatch => {
  try{
    dispatch({
      type: SET_CUSTOMER_COMMENTS,
      payload: data
    });
  }catch(err){
    console.error('error SetRequireAttention2');
  }
};
/**
 * Set New Orders and Orders that require attention
 */
export const SetOrdersAndAttention = (data) => async dispatch => {
  try{
    dispatch({
      type: SET_ORDERS_AND_ATTENTION,
      payload: data
    });
  }catch(error){
    // console.log(error);
    console.error('error trying update orders&attention');
  }
};
