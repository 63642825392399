import React from 'react';
import { Card, CardBody } from 'reactstrap';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

/*=============================================
=            By Vessel Component              =
=============================================*/

function ByVesselComponent(props){
  let data = [];
  data = props.totalsByStatus;

  return (
    <Card className="myCard">
      <CardBody>
        <div className="row">
          <div className="col-12">
            <center><h4 className="mb-3 py-2">Total Movements By Vessel</h4></center>
          </div>
          <div className="col-12">
            <center>
              <BarChart
                  width={255}
                  height={150}
                  data={data}
              >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="total" fill="#8bc34a" />
              </BarChart>
            </center>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ByVesselComponent;


