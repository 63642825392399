import { useState, useEffect, useCallback } from "react";
import { poDetailItemEventsDetailResponse, poDetailItemEventsResponse, poDetailItemsResponse } from "../../../../mocks/poDetail";
// import RepositoryFactory from "../../../../../../../repositories/RepositoryFactory";

export const useGetPOItems = (poId, user, lang) => {
  // const PurchaseOrderClient = RepositoryFactory.get("PurchaseOrder");
  const [allPOItems, setAllPOItems] = useState([]);
  const [poItems, setPoItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSearch = useCallback(
    (value) => {
      if (!value) {
        setPoItems(allPOItems);
        return;
      }
      const filteredItems = allPOItems.filter((item) => {
        let lineNo = ''
        let itemNo = ''
        let POItemsId = ''
        let description = ''
        let status = ''
        let lineId = ''
        if (item.LineNo) {
          lineNo = item.LineNo.toString().toLowerCase();
        }
        if (item.ItemNo) {
          itemNo = item.ItemNo.toString().toLowerCase();
        }
        if (item.POItemsId) {
          POItemsId = item.POItemsId.toString().toLowerCase();
        }
        if (item.Description) {
          description = item.Description.toString().toLowerCase();
        }
        if (item.Status) {
          status = item.Status.toString().toLowerCase();
        }
        if (item.LineID) {
          lineId = item.LineID.toString().toLowerCase();
        }
        return (
          lineNo.includes(value.toLowerCase()) ||
          itemNo.includes(value.toLowerCase()) ||
          POItemsId.includes(value.toLowerCase()) ||
          description.includes(value.toLowerCase()) ||
          status.includes(value.toLowerCase()) ||
          lineId.includes(value.toLowerCase())
        );
      });
      setPoItems(filteredItems);
    },
    [allPOItems]
  );

  const handleUpdateStatus = async (POId, POItemsId, Status) => {
    // try {
    //   await PurchaseOrderClient.updatePOItemStatus({
    //     POItemsId,
    //     Status,
    //     Lang: lang,
    //     POId,
    //     UserId: user,
    //   });
    //   await getPOItems();
    // } catch (error) {
    //   console.log("error on handleUpdateStatus", error);
    // }
  };

  const handleExpand = useCallback(
    (index, value) => {
      const newPoItems = [...poItems];
      newPoItems[index].expanded = value || !newPoItems[index].expanded;
      setPoItems(newPoItems);
    },
    [poItems]
  );

  const handleEditItem = (index, value) => {
    const newPoItems = [...poItems];
    newPoItems[index].edit = value;
    setPoItems(newPoItems);
  };

  const getPOItemsSteps = async (id) => {
    try {
      // const response = await PurchaseOrderClient.getPOItemsEvents(id, lang);
      // return response.data;
      return poDetailItemEventsResponse;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const getPOItemsDetail = async (id) => {
    try {
      // const response = await PurchaseOrderClient.getPOEventsDetail(id, lang);
      // return response.data;
      return poDetailItemEventsDetailResponse;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const updatePOItem = async (item) => {
    // try {
    //   const body = {
    //     ...item,
    //     UserId: user,
    //   };
    //   setLoading(true);
    //   await PurchaseOrderClient.updatePOItem(body);
    //   getPOItems();
    // } catch (error) {
    //   console.log("error", error);
    //   throw new Error('error al actualizar item');
    // } finally {
    //   setLoading(false);
    // }
  };

  const parcedPOItem = async (POItemsId) => {
    // try {
    //   setLoading(true);
    //   await PurchaseOrderClient.parcedPOItems({
    //     POId: poId,
    //     POItemsId,
    //     UserId: user,
    //   });
    //   getPOItems();
    // } catch (error) {
    //   console.log("error", error);
    // } finally {
    //   setLoading(false);
    // }
  };

  const massiveUpdate = async (values) => {
    // try {
    //   const body = {
    //     ...values,
    //     UserId: user,
    //   };
    //   setLoading(true);
    //   await PurchaseOrderClient.massivePOItemsUpdate(body);
    //   getPOItems();
    // } catch (_error) {
    //   throw new Error('error al actualizar item');
    // } finally {
    //   setLoading(false);
    // }
  };

  const getPOItems = useCallback(async () => {
    setLoading(true);
    setError(false);
    try {
      if (!poId) return;
      // const response = await PurchaseOrderClient.getPOItems(poId, user, lang);
      // let poItems = response.data;
      let poItems = poDetailItemsResponse;
      await Promise.all(
        poItems.map(async (item) => {
          item.expanded = false;
          item.Steps = await getPOItemsSteps(item.POItemsId);
          item.eventDetail = await getPOItemsDetail(item.POItemsId);
          item.edit = false;
        })
      );
      setAllPOItems(poItems);
      setPoItems(poItems);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setError(error);
      setLoading(false);
    }
  }, [poId, user, lang]);

  useEffect(() => {
    getPOItems();
  }, [poId, getPOItems]);

  return {
    poItems,
    loading,
    error,
    handleExpand,
    handleEditItem,
    updatePOItem,
    handleSearch,
    handleUpdateStatus,
    massiveUpdate,
    parcedPOItem,
  };
};
